import React, { useState,useEffect } from "react";
import { Modal, Button, Form, Select, Input, Spin } from "antd";
import { API_TXALERT2  } from "../../../config/config";
import axios from "axios";
import Swal from "sweetalert2";
import MonoText from "../../../helper/MonoText";
import ArrangeAlertResponses from "../../../helper/ArrangeAlertResponses";
import _ from "lodash";
import CatchError from "../../../helper/CatchError";

const DATA_URL = API_TXALERT2+"/alert/";
const API_ALERT_UPDATE_STATUS_URL = API_TXALERT2+"/alert/action/";
const DATA_ALERT_REASONS_URL = API_TXALERT2 + `/alert/responses`;


const Txalert_ChangeAlertStatus = (props) => {
    const { t, row, outcome,  doFetch } = props;
    const [reason, setReason] = useState({ outcome: "", status_code: "" });
    const [isDuplicateRequest, setIsDuplicateRequest] = useState(false);
    const [isValidAlertID, setIsValidAlertID] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isUnmatchedCase, setIsUnmatchedCase] = useState(false); //eslint-disable-line
    const [isLoading, setIsLoading] = useState(true);
    const [reasonList, setReasonList] = useState([]);  //all reasons from api call
    const codesDuplicateRequest = [940];
    const codesUnmatchedCaseGeneralOther = [900];
    const codesUnmatchedCaseUnableToLocate = [902];
    const [reasons, setReasons] = useState([]); //grouped by status
   
    const [form] = Form.useForm();

    const showModal = () => {
        
        setIsLoading(true);
        getReasons(row.alert_provider_id,row.alert_type_id);        
        setIsModalVisible(true);
    };

    useEffect(() => {
    
       if( reasonList.length !== 0) {        
        setReasons(_.groupBy(reasonList, "outcome"));  
        formValInit();
       }
    }, [reasonList]); // eslint-disable-line react-hooks/exhaustive-deps


    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const formValInit = () => {
        form.setFieldsValue({ outcome: "", status_code: "" });
        setReason({ outcome: "", status_code: ""});
        setIsDuplicateRequest(false);
        setIsValidAlertID(false);
        setIsUnmatchedCase(false);
        setIsLoading(false);
       
    };

    const getReasons = (provider_id,alert_type_id) => {
        
        axios
            .get(DATA_ALERT_REASONS_URL+'?active=1&admin_only=0&alert_type_id='+alert_type_id+'&alert_provider_id='+provider_id, { headers: { Authorization: "Bearer " + localStorage.getItem("txalert_token") } })
            .then(({ data }) => {
                if(data.list && data.list.length !== 0)
                {
                    setReasonList(ArrangeAlertResponses(data.list));               
                }
            })
            .catch((error) => {
                console.log("Alert Reasons", error);
            });
    };

    const handleOk = () => {
        const authuser = window.atob(localStorage.getItem("authuser")).split(":");
      
        let data = {
           // outcome: reason.outcome,
           // status_code: reason.status_code,
           alert_id:row.alert_id,
           response_id:reason.response_id,       
            userid: parseInt(authuser[0]),
            username: authuser[1],
            alert_outcome_action_date: new Date(),
            alert_outcome_amount: row.transaction_amount,
            alert_outcome_currency: row.transaction_currency,
        };
       
        if (isDuplicateRequest) {
            data = { ...data,  duplicate_of_alert_id: parseInt(reason.duplicate_alertid) };
        }

        const options = {
            method: "POST",
            headers: {"content-type": "application/json", Authuser: localStorage.getItem("authuser") , Authorization: "Bearer " + localStorage.getItem("txalert_token")},
            data: JSON.stringify(data),
            url:  API_ALERT_UPDATE_STATUS_URL+row.alert_id,
        };

        axios(options)
            .then(({ data }) => {
                if (data.status === "OK") {
                    Swal.fire({
                        icon: "success",
                        title: t("contents.alerts.update_status_successful"),
                        showConfirmButton: false,
                        timer: 2000,
                        onClose: () => {                            
                                doFetch();                           
                        },
                    });
                } else {
                   CatchError(data);
                }
            })
            .catch((error) => {
                CatchError(error);
            });
        setIsModalVisible(false);
    };


   

    const isAlertIdExist = async (id) => {
        const apiUrl = DATA_URL + id;
        if (!id) {
            return false;
        }
        try {
            return await axios
                .get( apiUrl, { headers: { Authorization: "Bearer " + localStorage.getItem("txalert_token") } })
                .then(({ data }) => !!data.alert_id)
                .catch((err) => {
                    return false;
                });
        } catch (error) {
            return false;
        }
    };

    const formValChange = (chgV, allV) => {
       
        let state = null;
        
        if (chgV.outcome) {
            state = { outcome: chgV.outcome,  status_code: "", description: "", duplicate_alertid: "" };
          
            setIsDuplicateRequest(false);
            setIsUnmatchedCase(false);
        } else if (chgV.status_code) {
            const selected_reason = reasons[reason.outcome].find((obj) => obj.status_code === chgV.status_code) || "";
            
           state = { ...reason, status_code: chgV.status_code, description: selected_reason.description, duplicate_alertid: "",response_id:selected_reason.response_id };
            setIsDuplicateRequest(codesDuplicateRequest.includes(chgV.status_code));

            /* === if UnmatchedCase === */
            if (codesUnmatchedCaseGeneralOther.includes(chgV.status_code)) {
                state = { ...state, status_code: "900" };
                setIsUnmatchedCase(true);
            } else if (codesUnmatchedCaseUnableToLocate.includes(chgV.status_code)) {
                state = { ...state, status_code: "902" };
                setIsUnmatchedCase(true);
            } else {
                setIsUnmatchedCase(false);
            }
        } else if (chgV.duplicate_alertid) {
            if (Number.isInteger(parseInt(chgV.duplicate_alertid)) && parseInt(chgV.duplicate_alertid) > 0) {
                state = { ...reason, duplicate_alertid: chgV.duplicate_alertid };
            } else {
                state = { ...reason };
            }
        } else if (chgV.duplicate_alertid === "") {
            state = { ...reason, duplicate_alertid: "" };
        } else {
            state = { ...reason };
        }
        
        form.setFieldsValue(state);
        setReason(state);
    };

    const setNormalizeText = (text) => {
        let arr = text.replace("–", "-").replace('&ndash;','-').split("-");
        const checklast = arr[arr.length - 1].toLowerCase();
        if (checklast.search("refunded") >= 0 || checklast.search("settled") >= 0) {
            arr.pop();
            return arr.join("-");
        } else {
            return text;
        }
    };

    const getReasonOptions = () =>
        reasons[reason.outcome]
            .map((obj) => (
                <Select.Option key={obj.status_code} value={obj.status_code} className="text-capital">
                    {getTranslationDescription(setNormalizeText(obj.description))}
                </Select.Option>
            ));

    const getButtonName = (outcome,can_update) => {
        // rdr matched should not display action required button
        if (!outcome) {
            return t("tables.actions.action_required");
        }
        switch (outcome.toLowerCase()) {
            case "error":
                return t("tables.actions.action_required");
            case "matched":
                return can_update?t("tables.actions.action_required"):null; 
            case "wip":
                return t("tables.actions.wip");
            default:                
                return can_update?t("tables.actions.update"):null;
           
        }
    };

    const getTranslation = (reason) => {
        try {
            return t(`outcomes.${reason.toLowerCase()}`);
        } catch (error) {
            return reason;
        }
    };

    const getTranslationDescription = (description) => {
        try {
            // get translation
            return t(`reason_descriptions.${MonoText(description.replace("&amp;", ""))}`);
        } catch (error) {
            return description;
        }
    };

    const isOkButtonProps = () =>
        !reason.outcome || !reason.status_code || (isDuplicateRequest && !!reason.duplicate_alertid === false) || (isDuplicateRequest && !isValidAlertID);

    // const isAdditionalProps = () => reason.outcome !== "CB_PROCEEDED";
//can_update only exist when the alert detail is viewed.
    return (
        <>
            {
            (typeof row.can_update === 'undefined' && row?.alert_type)?(
            <Button size="small" onClick={showModal}> 
                {getButtonName(outcome,(row.alert_type?.match(" rdr")?false:true))}
            </Button>
            ):(
            (row.can_update === true)?(
            <Button size="small" onClick={showModal}> 
                {getButtonName(outcome,row.can_update)}
            </Button>
            ):null)}
            <Modal
                title={`${t("contents.alerts.update_status")} (${t("contents.alerts.alert_id")} #${row.alert_id})`}
                visible={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                okButtonProps={{
                    disabled: isOkButtonProps(),
                }}
                width={720}
            >
                {isLoading ? (
                    <div className="d-flex justify-content-center align-items-center alertupdatestatus-box--spinner">
                        <Spin size="large" />
                    </div>
                ) : (
                    <Form layout="vertical" form={form} onValuesChange={formValChange}>
                       {row.can_update && outcome.toLowerCase() !== 'matched' && outcome ?( <div>{t("contents.alerts.update_warning")}</div>):''}
                        <Form.Item label={t("contents.alerts.status")} name="outcome">
                            <Select className="text-capital">
                                {Object.keys(reasons).map((reason) => (
                                    <Select.Option key={reason} value={reason} className="text-capital">
                                        {getTranslation(reason)}
                                    </Select.Option>
                                ))}                              
                            </Select>
                        </Form.Item>                      
                        <Form.Item label={t("contents.alerts.reason")} name="status_code">
                            <Select className="text-capital" disabled={!reason.outcome}>
                                {reason.outcome ? getReasonOptions() : null}
                            </Select>
                        </Form.Item>
                        {isDuplicateRequest ? (
                            <Form.Item
                                label={t("contents.alerts.duplicate_alertid")}
                                name="duplicate_alertid"
                                rules={[
                                    { required: true, message: t("contents.alerts.required_messages.duplicate_alertid_required") },
                                    ({ getFieldValue }) => ({
                                     
                                        async validator(rule, value) {
                                            if (!value || (await isAlertIdExist(value))) {
                                                if (row.id === parseInt(value)) {
                                                    setIsValidAlertID(false);
                                                    return Promise.reject("Duplicate Alert ID couldn't be the same as Alert ID");
                                                }
                                                setIsValidAlertID(true);
                                                return Promise.resolve();
                                            }
                                            setIsValidAlertID(false);
                                            return Promise.reject(t("contents.alerts.required_messages.duplicate_alertid_not_valid"));
                                        },
                                    }),
                                ]}
                            >
                                <Input type="number" min="1" className="no-arrow no-scroll" />
                            </Form.Item>
                        ) : null}
                    </Form>
                )}
            </Modal>
        </>
    );
};

export default Txalert_ChangeAlertStatus;