import React, { useState, useEffect } from "react";
import { API_TXALERT2 } from "../../../config/config";
import { Button,Tabs, Row, Col, PageHeader, Card } from "antd";
import axios from "axios";
import TxalertEnrollmentHistoryTbl from "../Components/EnrollmentHistoryTbl";
import TxalertEnrollmentTbl from "../Components/EnrollmentTbl";
import TxalertBinCaidModal from "./BinCaid";
import GroupAccess from "../../../helper/GroupAccess";
import { EditOutlined } from "@ant-design/icons";
import TxalertUpdateEnrollmentModal from "../Admin/UpdateEnrollment";
import CatchError from "../../../helper/CatchError";
const Txalert_BinCaidDetail = (props) => {
    const bincaid_id = props.match.params.id;
    const [details, setDetails] = useState(null);
    const [loading, setLoading] = useState(true);
    const { t } = props;
    const [id, setId] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const [enrollUrl, setEnrollUrl] = useState('');
    const [title, setTitle] = useState(''); //todisplay in enroll window
    const [isEnrollOpen, setIsEnrollOpen] = useState(false);
    const [refreshcount, setRefreshCount] = useState(0);



    const showModal = (selectedid) => {
        setId(selectedid);
        setIsOpen(true);

    };

    useEffect(() => {
        if(loading === true){
            getDetails();
            setLoading(false);
        }
    }, [loading]); // eslint-disable-line

    const getDetails = () => {
        const apiUrl = API_TXALERT2 + `/bincaid/${bincaid_id}`;

        axios
            .get(apiUrl, { headers: { Authorization: "Bearer " + localStorage.getItem("txalert_token") } })
            .then(({ data }) => {
                setDetails(data);
            })
            .catch((error) => {
                console.log(error.response);
                CatchError(error);
            });


    };

    const showEnrollModal = (id) =>{
        setEnrollUrl(API_TXALERT2 + '/bincaid/'+id+'/enrollment');
        setTitle('Bin:'+details.bin+' Caid:'+details.caid);
        setIsEnrollOpen(true);
      };

     const updateDetails = () => {
      setLoading(true);
    };


    const showInfo = (labelSpan = 10, valueSpan = 14) => details ? (
        <Card className="details-info--card" loading={loading}>

            <Row gutter={[0, 8]} key="bincaid_id">
                <Col key="label-bincaid_id" span={labelSpan}>
                    <span className="text">
                        <b>{t(`contents.bincaids.form.id`)}</b>
                    </span>
                </Col>
                <Col key="value-bincaid_id" span={valueSpan}>
                    <span className="text">{details.bincaid_id}</span>
                </Col>
            </Row>
            <Row gutter={[0, 8]} key="bin">
                <Col key="label-bin" span={labelSpan}>
                    <span className="text">
                        <b>{t(`contents.bincaids.form.bin`)}</b>
                    </span>
                </Col>
                <Col key="value-bin" span={valueSpan}>
                    <span className="text">{details.bin}</span>
                </Col>
            </Row>
            <Row gutter={[0, 8]} key="caid">
                <Col key="label-caid" span={labelSpan}>
                    <span className="text">
                        <b>{t(`contents.bincaids.form.caid`)}</b>
                    </span>
                </Col>
                <Col key="value-caid" span={valueSpan}>
                    <span className="text">{details.caid}</span>
                </Col>
            </Row>
            <Row gutter={[0, 8]} key="merchant_name">
                <Col key="label-merchant_name" span={labelSpan}>
                    <span className="text">
                        <b>{t(`contents.bincaids.form.merchant`)}</b>
                    </span>
                </Col>
                <Col key="value-merchant_name" span={valueSpan}>
                    <span className="text"><a href={`/merchantdetails/${details.merchant_id}`} target="_blank" rel="noopener noreferrer">{details.merchant_name}</a></span>
                </Col>
            </Row>
            <Row gutter={[0, 8]} key="vrol_profile">
                <Col key="label-vrol_profile" span={labelSpan}>
                    <span className="text">
                        <b>{t(`contents.bincaids.form.vrol_profile`)}</b>
                    </span>
                </Col>
                <Col key="value-vrol_profile" span={valueSpan}>
                    <span className="text">{details.vrol_profile}</span>
                </Col>
            </Row>
            <Row gutter={[0, 8]} key="mcc">
                <Col key="label-mcc" span={labelSpan}>
                    <span className="text">
                        <b>{t(`contents.bincaids.form.mcc`)}</b>
                    </span>
                </Col>
                <Col key="value-mcc" span={valueSpan}>
                    <span className="text">{details.mcc}</span>
                </Col>
            </Row>
            <Row gutter={[0, 8]} key="reference_no">
                <Col key="label-reference_no" span={labelSpan}>
                    <span className="text">
                        <b>{t(`contents.bincaids.form.reference_no`)}</b>
                    </span>
                </Col>
                <Col key="value-reference_no" span={valueSpan}>
                    <span className="text">{details.reference_no}</span>
                </Col>
            </Row>
            <Row gutter={[0, 8]} key="comment">
                <Col key="label-comment" span={labelSpan}>
                    <span className="text">
                        <b>{t(`contents.bincaids.form.comment`)}</b>
                    </span>
                </Col>
                <Col key="value-comment" span={valueSpan}>
                    <span className="text">{details.comment}</span>
                </Col>
            </Row>
            <Row gutter={[0, 8]} key="active">
                <Col key="label-active`" span={labelSpan}>
                    <span className="text">
                        <b>{t(`contents.bincaids.form.active`)}</b>
                    </span>
                </Col>
                <Col key="value-active" span={valueSpan}>
                    <span className="text">{details.status === 1 ? 'Yes' : 'No'}</span>
                </Col>
            </Row>
            {GroupAccess("SYSADMIN")?
                     <Row>
                <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 4 }} xl={{ span: 4 }}>
                    <Button type="primary" onClick={() => showModal(bincaid_id)} >
                        <EditOutlined /> {t("tables.actions.edit")}
                    </Button>
                    
                    <TxalertBinCaidModal t={t}  bincaidid={id} setId={setId} isOpen={isOpen} setIsOpen={setIsOpen} fetchBincaidList={updateDetails} />
                      </Col>
               
            </Row>:''}
        </Card>
    ) : null;

    const refresh = () => {
        //key value is changed to rerender the componnent
        setRefreshCount(refreshcount+1);
      
    };


    const showEnrollments = () => details ? (
        <div>
             <Button type="primary" onClick={() => showEnrollModal(bincaid_id)} >
                        <EditOutlined /> {t("contents.txalert_admin.form.updateenrollment")}
                    </Button>
                    <TxalertUpdateEnrollmentModal t={t} type="bincaid"  isEnrollOpen={isEnrollOpen} setIsEnrollOpen={setIsEnrollOpen} enrollurl={enrollUrl} title={title} fetchList={refresh} id={bincaid_id} />
                
            <TxalertEnrollmentTbl id={details.bincaid_id} t={t} url={API_TXALERT2 + "/bincaid/"+details.bincaid_id+"/enrollment"} rowkey="bincaid_provider_enrollment_id" key={'enrolltbl'+refreshcount}/>

            <TxalertEnrollmentHistoryTbl id={details.bincaid_id} t={t} url={API_TXALERT2 + "/bincaid/"+details.bincaid_id+"/enrollment_history"} rowkey="bincaid_enrollment_history_id" key={'enrollhistory'+refreshcount}/>
        </div>

    ) : null;



    return (
        <>
            <PageHeader className="site-page-header" title={t("contents.bincaids.title")} subTitle={`(${bincaid_id})`}></PageHeader>

            <Tabs defaultActiveKey="1" type="card">

                <Tabs.TabPane tab={t("contents.bincaids.details")} key='bincaid_detail'>
                {details ? showInfo() : ''}
                </Tabs.TabPane>
                <Tabs.TabPane tab={t("contents.bincaids.tabs.enrollment")} key='enrollments'>
                {bincaid_id === undefined ? '' :showEnrollments()}
                </Tabs.TabPane>
            </Tabs>
        </>
    );
};

export default Txalert_BinCaidDetail;
