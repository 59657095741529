import React, { useState, useEffect } from "react";
import axios from "axios";
import { Select} from "antd";
import { API_TXALERT} from "../config/config";

const { Option } = Select;
const DATA_ALERT_PROVIDER_URL = API_TXALERT + "/provider";


const ProviderList = (props) => {

    const [data, setData] = useState([]);

    const fetchdata = async() => {
        try{
        
            const response = await axios.post(
                DATA_ALERT_PROVIDER_URL,
                {},
                { headers: { Authorization: "Bearer " + localStorage.getItem("access_token"), Authuser: localStorage.getItem("authuser") } }
            );           
            setData(response.data);
        } catch (error) {
          return ( { data: false });
        }      

    };

    useEffect(() => {
        if (data.length === 0) {
            fetchdata();
        }
    }, [data]);



    const getProvider = () => {       
        
            return data.map((item) => (               
                    <Option key={item.dataIndex} value={item.alertproviderid}>
                        {item.title }
                    </Option>                    
            ))
        
    };
    
    return (
        
        <Select 
            showSearch
            mode="multiple"
            showArrow="true"
            maxTagCount="responsive"
            optionFilterProp="children"
            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
            filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
            {...props}
            >    {data
            ? getProvider()
            : null}</Select>
    );
};

export default ProviderList;