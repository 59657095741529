import React from "react";
import { Card, List } from 'antd';

export default function OnBoardingDetails({typeforms}) {

    typeforms = typeforms.filter(e => e.link);

    return (
        <Card>
            <h3>Status: On Boarding</h3>
            <p>Please complete the forms below to finish setting up your account.</p>
            <List
                bordered
                dataSource={typeforms}
                renderItem={e => {
                    let status = e.status === "filled";
                    return (
                        <List.Item
                            actions={[status ? <p style={{color: "green"}}>Completed</p> : null]}
                        >
                            <TypeformLinks key={e.typeform} typeform={e}></TypeformLinks>
                        </List.Item>
                    );
                }}
            >
            </List>
        </Card>
    )
}

function TypeformLinks({typeform}) {

    let name;

    if (typeform.typeform === "threeDS")
        name = "3DS";
    else if (typeform.typeform === 'cbm')
        name = typeform.typeform.toUpperCase();
    else {
        let _name = typeform.typeform.split('_');
        _name = _name.map(e => {
            return e.substring(0, 1).toUpperCase() + e.substring(1);
        });
        name = _name.join(' ');
    }

    return (
        <List.Item.Meta
            title={name + ":"}
            description={<a href={typeform.link} target={"_blank"} rel="noopener noreferrer">{ typeform.link }</a>}
        />
    );
}