import React, { useState, useEffect } from "react";
import { PageHeader, Card, Form, Select, Row, Col, Input, InputNumber, Button, Checkbox } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { TXSHIELD_SECURE_BASE_URL, API_DATA_SOURCE, API_SITE } from "../../config/config";
import axios from "axios";
import moment from "moment";

const { Option } = Select;

const VirtualTerminal = (props) => {
    const { t } = props;
    const [siteList, setSiteList] = useState(null);
    const [currencyList, setCurrencyList] = useState(null);
    const [formData, setFormData] = useState();
    const [cartsData, setCartsData] = useState([]);

    const initFormData = {
        sid: null,
        currency: "AUD",
        addfee: false,
        amount_shipping: 0.0,
        amount_tax: 0.0,
        amount_coupon: 0.0,
        ref1: null,
        ref2: null,
        ref3: null,
        ref4: null,
        wid: null,
        tid: null,
        affiliateid: null,
        tx_action: null,
    };

    const [form] = Form.useForm();

    const formLayout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };

    const verticalItemLayout = {
        labelAlign: "left",
        labelCol: { span: 24, offset: 0 },
        wrapperCol: { span: 24, offset: 0 },
    };

    useEffect(() => {
        if (!siteList) {
            getSiteList();
        }
        if (!currencyList) {
            getCurrencyList();
        }
        formValInit();
    }, []); // eslint-disable-line

    const formValInit = () => {
        setFormData(initFormData);
        form.setFieldsValue(initFormData);
    };

    const formValChange = (chgV, allV) => {
        setFormData(allV);
    };

    const getSiteList = async () => {
        const apiUrl = API_SITE + "/list";
        await axios
            .post(apiUrl, {}, { headers: { Authorization: "Bearer " + localStorage.getItem("access_token") } })
            .then(({ data }) => {
                setSiteList(data.list);
            })
            .catch((error) => console.log(error));
    };

    const onChangeSiteDropdown = (value) => {
    };

    const getCurrencyList = async () => {
        const apiUrl = API_DATA_SOURCE + `/defaultcurrency`;
        await axios
            .post(apiUrl, {}, { headers: { Authorization: "Bearer " + localStorage.getItem("access_token") } })
            .then(({ data }) => {
                setCurrencyList(data);
            })
            .catch((error) => console.log(error));
    };

    const onChangeCurrencyDropdown = (value) => {
    };

    const onFinish = async (values) => {
        let carts = [
            {
                item_name: formData.item_name,
                item_no: formData.item_no,
                item_desc: formData.item_desc,
                item_quantity: formData.item_quantity,
                item_amount_unit: formData.item_amount_unit,
            },
        ];

        let finalCarts = carts;

        if (values.carts) {
            finalCarts = [...carts, ...values.carts];
        }

        await setCartsData(finalCarts);

        document.getElementById("actionForm").submit();

        form.resetFields();
        form.setFieldsValue(initFormData);
        window.scroll({ top: 0, left: 0, behavior: "smooth" });
    };

    const onClickAddFeeCheckbox = () => {
        if (document.getElementById("addfee").value === "0" || document.getElementById("addfee").value === "") {
            document.getElementById("addfee").value = "true";
        } else {
            document.getElementById("addfee").removeAttribute("value");
        }
    };

    const submitButtonClickHandler = () => {
        window.scroll({ top: 0, left: 0, behavior: "smooth" });
    };

    return (
        <>
            <PageHeader title={t('contents.virtual_terminal.title')}></PageHeader>
            <Form onFinish={onFinish} onValuesChange={formValChange} form={form} {...formLayout} name="process_details">
                <Card title={t('contents.virtual_terminal.processor_details')} className="custom-form--card">
                    <Row gutter={[36]}>
                        <Col span={12}>
                            <Form.Item name="sid" label={t('contents.virtual_terminal.form.sid')} rules={[{ required: true, message: t('required_field') }]}>
                                <Select
                                    onChange={onChangeSiteDropdown}
                                    showSearch
                                    style={{ width: "100%" }}
                                    placeholder={t('contents.virtual_terminal.form.please_select_site')}
                                    optionFilterProp="children"
                                    filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                >
                                    {siteList
                                        ? siteList.map((item) => (
                                              <Option key={item.sid} value={item.sid}>
                                                  {item.name + ` (sid: ` + item.sid + `)`}
                                              </Option>
                                          ))
                                        : null}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                </Card>
                <Card title={t('contents.virtual_terminal.checkout_details')} className="custom-form--card">
                    <Row gutter={[36]}>
                        <Col span={12}>
                            <Form.Item name="currency" label={t('contents.virtual_terminal.form.currency')}>
                                <Select
                                    onChange={onChangeCurrencyDropdown}
                                    showSearch
                                    style={{ width: "100%" }}
                                    placeholder={t('contents.virtual_terminal.form.please_select_currency')}
                                    optionFilterProp="children"
                                    filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                >
                                    {currencyList
                                        ? Object.keys(currencyList).map((key) => (
                                              <Option key={key} value={key}>
                                                  {currencyList[key]}
                                              </Option>
                                          ))
                                        : null}
                                </Select>
                            </Form.Item>
                            <Form.Item name="addfee" label={t('contents.virtual_terminal.form.add_processing_fees')} onClick={onClickAddFeeCheckbox}>
                                <Checkbox valuePropName="checked" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name="amount_shipping" label={t('contents.virtual_terminal.form.shipping_cost')} rules={[{ required: true, message: t('required_field') }]}>
                                <InputNumber defaultValue={initFormData.amount_shipping} step="0.01" stringMode style={{ width: "100%" }} />
                            </Form.Item>
                            <Form.Item name="amount_tax" label={t('contents.virtual_terminal.form.sales_tax')} rules={[{ required: true, message: t('required_field') }]}>
                                <InputNumber defaultValue={initFormData.amount_tax} step="0.01" stringMode style={{ width: "100%" }} />
                            </Form.Item>
                            <Form.Item name="amount_coupon" label={t('contents.virtual_terminal.form.coupon_value')} rules={[{ required: true, message: t('required_field') }]}>
                                <InputNumber defaultValue={initFormData.amount_coupon} step="0.01" stringMode style={{ width: "100%" }} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[36]}>
                        <Col span={1}>
                            <Form.Item {...verticalItemLayout} label=" ">
                                1
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item {...verticalItemLayout} name="item_name" label={t('contents.virtual_terminal.form.item_name')} rules={[{ required: true, message: t('required_field') }]}>
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={3}>
                            <Form.Item {...verticalItemLayout} name="item_no" label={t('contents.virtual_terminal.form.item_no')}>
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={9}>
                            <Form.Item {...verticalItemLayout} name="item_desc" label={t('contents.virtual_terminal.form.description')}>
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={3}>
                            <Form.Item {...verticalItemLayout} name="item_quantity" label={t('contents.virtual_terminal.form.quantity')} rules={[{ required: true, message: t('required_field') }]}>
                                <InputNumber step="1" min={0} stringMode style={{ width: "100%" }} />
                            </Form.Item>
                        </Col>
                        <Col span={3}>
                            <Form.Item {...verticalItemLayout} name="item_amount_unit" label={t('contents.virtual_terminal.form.amount_unit')} rules={[{ required: true, message: t('required_field') }]}>
                                <InputNumber placeholder="0.00" step="0.01" min={0} stringMode style={{ width: "100%" }} />
                            </Form.Item>
                        </Col>
                        <Col span={1}>
                            <Form.Item {...verticalItemLayout} label=" ">
                                &nbsp;
                            </Form.Item>
                        </Col>
                    </Row>
                    <Form.List name="carts">
                        {(carts, { add, remove }) => (
                            <>
                                {carts.map(({ key, name, ...restField }, index) => (
                                    <Row gutter={[36]} key={key}>
                                        <Col span={1}>
                                            <Form.Item {...restField} {...verticalItemLayout} label=" ">
                                                {index + 2}
                                            </Form.Item>
                                        </Col>
                                        <Col span={4}>
                                            <Form.Item
                                                {...restField}
                                                {...verticalItemLayout}
                                                name={[name, "item_name"]}
                                                label={t('contents.virtual_terminal.form.item_name')}
                                                rules={[{ required: true, message: t('required_field') }]}
                                            >
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                        <Col span={3}>
                                            <Form.Item {...restField} {...verticalItemLayout} name={[name, "item_no"]} label={t('contents.virtual_terminal.form.item_no')}>
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                        <Col span={9}>
                                            <Form.Item {...restField} {...verticalItemLayout} name={[name, "item_desc"]} label={t('contents.virtual_terminal.form.description')}>
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                        <Col span={3}>
                                            <Form.Item
                                                {...restField}
                                                {...verticalItemLayout}
                                                name={[name, "item_quantity"]}
                                                label={t('contents.virtual_terminal.form.quantity')}
                                                rules={[{ required: true, message: t('required_field') }]}
                                            >
                                                <InputNumber step="1" min={0} stringMode style={{ width: "100%" }} />
                                            </Form.Item>
                                        </Col>
                                        <Col span={3}>
                                            <Form.Item
                                                {...restField}
                                                {...verticalItemLayout}
                                                name={[name, "item_amount_unit"]}
                                                label={t('contents.virtual_terminal.form.amount_unit')}
                                                rules={[{ required: true, message: t('required_field') }]}
                                            >
                                                <InputNumber placeholder="0.00" step="0.01" min={0} stringMode style={{ width: "100%" }} />
                                            </Form.Item>
                                        </Col>
                                        <Col span={1}>
                                            <Form.Item {...verticalItemLayout} label=" ">
                                                <MinusCircleOutlined onClick={() => remove(name)} />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                ))}
                                <Row gutter={[36]}>
                                    <Col span={24} className="text-center">
                                        <Form.Item {...verticalItemLayout}>
                                            <Button type="dashed" onClick={() => add()} icon={<PlusOutlined />}>
                                                {t('contents.virtual_terminal.form.add_cart_item')}
                                            </Button>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </>
                        )}
                    </Form.List>
                </Card>
                <Card title={t('contents.virtual_terminal.additional_details')} className="custom-form--card">
                    <Row gutter={[36]}>
                        <Col span={12}>
                            <Form.Item name="ref1" label={t('contents.virtual_terminal.form.ref_1')}>
                                <Input />
                            </Form.Item>
                            <Form.Item name="ref2" label={t('contents.virtual_terminal.form.ref_2')}>
                                <Input />
                            </Form.Item>
                            <Form.Item name="ref3" label={t('contents.virtual_terminal.form.ref_3')}>
                                <Input />
                            </Form.Item>
                            <Form.Item name="ref4" label={t('contents.virtual_terminal.form.ref_4')}>
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name="wid" label={t('contents.virtual_terminal.form.wid')}>
                                <Input />
                            </Form.Item>
                            <Form.Item name="tid" label={t('contents.virtual_terminal.form.tid')}>
                                <Input />
                            </Form.Item>
                            <Form.Item name="affiliateid" label={t('contents.virtual_terminal.form.affiliate_id')}>
                                <Input />
                            </Form.Item>
                            <Form.Item name="tx_action" label={t('contents.virtual_terminal.form.tx_action')}>
                                <Input placeholder="PAYMENT" />
                            </Form.Item>
                        </Col>
                    </Row>
                </Card>
                <Button type="primary" htmlType="submit" onClick={() => submitButtonClickHandler()}>
                    {t('contents.virtual_terminal.form.process')}
                </Button>
            </Form>
            <form id="actionForm" style={{ display: "none" }} target="_blank" method="POST" action={TXSHIELD_SECURE_BASE_URL + `/index.php?uas=admDashboard`}>
                <input type="hidden" id="nohash" name="nohash" value="true" />
                <input type="hidden" id="timestamp" name="timestamp" value={moment()} />
                <input type="hidden" id="sid" name="sid" value={formData ? (formData.sid ? formData.sid : "") : ""} />
                <input type="hidden" id="currency" name="currency" value={formData ? (formData.currency ? formData.currency : "") : ""} />
                <input type="hidden" id="addfee" name="addfee" />
                <input
                    type="hidden"
                    id="amount_shipping"
                    name="amount_shipping"
                    value={formData ? (formData.amount_shipping ? formData.amount_shipping : "") : ""}
                />
                <input type="hidden" id="amount_tax" name="amount_tax" value={formData ? (formData.amount_tax ? formData.amount_tax : "") : ""} />
                <input type="hidden" id="amount_coupon" name="amount_coupon" value={formData ? (formData.amount_coupon ? formData.amount_coupon : "") : ""} />
                {cartsData
                    ? cartsData.map((item, index) => (
                          <>
                              <input type="hidden" name="item_name[]" value={item.item_name} />
                              <input type="hidden" name="item_no[]" value={item.item_no} />
                              <input type="hidden" name="item_desc[]" value={item.item_desc} />
                              <input type="hidden" name="item_quantity[]" value={item.item_quantity} />
                              <input type="hidden" name="item_amount_unit[]" value={item.item_amount_unit} />
                          </>
                      ))
                    : null}
                <input type="hidden" id="ref1" name="ref1" value={formData ? (formData.ref1 ? formData.ref1 : "") : ""} />
                <input type="hidden" id="ref2" name="ref2" value={formData ? (formData.ref2 ? formData.ref2 : "") : ""} />
                <input type="hidden" id="ref3" name="ref3" value={formData ? (formData.ref3 ? formData.ref3 : "") : ""} />
                <input type="hidden" id="ref4" name="ref4" value={formData ? (formData.ref4 ? formData.ref4 : "") : ""} />
                <input type="hidden" id="wid" name="wid" value={formData ? (formData.wid ? formData.wid : "") : ""} />
                <input type="hidden" id="tid" name="tid" value={formData ? (formData.tid ? formData.tid : "") : ""} />
                <input type="hidden" id="affiliateid" name="affiliateid" value={formData ? (formData.affiliateid ? formData.affiliateid : "") : ""} />
                <input type="hidden" id="tx_action" name="tx_action" value={formData ? (formData.tx_action ? formData.tx_action : "PAYMENT") : "PAYMENT"} />
            </form>
        </>
    );
};

export default VirtualTerminal;
