import React from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { API } from "../config/config";
import { Typography, Button, Tabs, Input } from "antd";
import { QrcodeOutlined, MailOutlined } from "@ant-design/icons";

const { Title, Paragraph } = Typography;
const { TabPane } = Tabs;

const OptionVerify = (props) => {
    const { t, email, access_token, handleChange } = props;

    const Tab1 = () => (
        <span>
            <QrcodeOutlined />
            Using Authentication App
        </span>
    );

    const Tab2 = () => (
        <span>
            <MailOutlined />
            Using Email
        </span>
    );

    const onSaveOptionVerify = (option) => {
        axios
            .post(API + "/updateverify", { option: option }, { headers: { Authorization: "Bearer " + access_token }})
            .then(({ data }) => {
                if (data.status === "OK") {
                    if (option === "email") {
                        handleChange({ verify_option: "email" });
                    } else {
                        handleChange({ verify_option: "otp", "2fa_qrcode": data["2fa_qrcode"], "2fa_secret": data["secret_key"] });
                    }
                }
            })
            .catch((error) => {
                Swal.fire({
                    icon: "error",
                    title: t("login.dialogs.catch"),
                    text: error.response ? error.response.data.error_msg : "",
                    showConfirmButton: true,
                });
            });
    };

    return (
        <>
            <div className="d-flex align-items-center" style={{ height: "100vh" }}>
                <div style={{ margin: "0 auto", textAlign: "center" }}>
                    <Title level={3} style={{ marginBottom: "0.5rem" }}>
                        Two Factor Authentication Setup
                    </Title>
                    <Paragraph type="secondary" className="text-cener" style={{ fontSize: "1rem", maxWidth: "460px" }}>
                        You need to setup a two-factor <br />
                        authentication (2FA) for your account
                    </Paragraph>
                    <Tabs defaultActiveKey="1" centered style={{ width: "400px" }}>
                        <TabPane tab={Tab1()} key="1">
                            <div style={{ paddingTop: "1rem" }}>
                                <Button type="primary" onClick={() => onSaveOptionVerify("otp")} className="login-form-button" size="large" shape="round">
                                    {t("login.continue")}
                                </Button>
                            </div>
                        </TabPane>
                        <TabPane tab={Tab2()} key="2">
                            <p style={{ textAlign: "left" }}>If you wish to receive auth code to your email, choose this option and submit when done.</p>
                            <div style={{ marginBottom: "1rem" }}>
                                <Input size="large" defaultValue={email} style={{ backgroundColor: "#eee" }} readOnly={true} />
                            </div>
                            <Button type="primary" onClick={() => onSaveOptionVerify("email")} className="login-form-button" size="large" shape="round">
                                {t("login.submit")}
                            </Button>
                        </TabPane>
                    </Tabs>
                </div>
            </div>
        </>
    );
};

export default OptionVerify;
