import React, { useState, useEffect } from "react";
import { Card, Form, Select, Row, Col } from "antd";
import { API_RETAILER, API_SITE } from "../../../config/config";
import axios from "axios";

const { Option } = Select;

const RetailerSiteSelector = (props) => {
    const { t, mid, setRetailerDetails, setSiteDetails, setIsLoading } = props;    
    const [retailerList, setRetailerList] = useState(null);
    const [siteList, setSiteList] = useState(null);
    const [form] = Form.useForm();  

    useEffect(() => {
        if(!retailerList) {
            getRetailerList();
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const getRetailerList = async () => {
        const api_url = API_RETAILER + `/list`;
        await axios
            .post(api_url, { mid: [mid] }, { headers: { Authorization: "Bearer " + localStorage.getItem("access_token") } })
            .then(({ data }) => {
                setRetailerList(data.list);
            })
            .catch((error) => console.log(error));
    };

    const onChangeRetailer = async (rid) => {
        setRetailerDetails(null);
        setSiteList(null);
        setSiteDetails(null);
        getRetailerDetails(rid);
        form.setFieldsValue({ rid: rid, sid: null });
    };

    const getRetailerDetails = async (rid) => {        
        const api_url = API_RETAILER + `/${rid}/details`;
        await axios
            .post(api_url, {}, { headers: { Authorization: "Bearer " + localStorage.getItem("access_token") } })
            .then(({ data }) => {
                setRetailerDetails(data);
                getSiteList(rid);
            })
            .catch((error) => console.log(error));
    };

    const getSiteList = async (rid) => {        
        const api_url = API_SITE + `/list`;
        await axios
            .post(api_url, { mid: [mid], rid: [rid] }, { headers: { Authorization: "Bearer " + localStorage.getItem("access_token") } })
            .then(({ data }) => {
                setSiteList(data.list);
            })
            .catch((error) => console.log(error));
    };

    const onChangeSite = (sid) => {
        setSiteDetails(null);
        getSiteDetails(sid);
    };

    const getSiteDetails = async (sid) => {
        setIsLoading(true);
        const api_url = API_SITE + `/${sid}/details`;
        await axios
            .post(api_url, {}, { headers: { Authorization: "Bearer " + localStorage.getItem("access_token") } })
            .then(async ({ data }) => {
                await setSiteDetails(data);
                setIsLoading(false);
            })
            .catch((error) => console.log(error));
    };

    return (
        <Card className="custom-form--card">
            <Form form={form} name="retailer_site_selector">
                <Row gutter={[36]}>
                    <Col span={12}>
                        <Form.Item name="rid" className="mb-0">
                            <Select
                                onChange={onChangeRetailer}
                                showSearch
                                style={{ width: '100%' }}
                                placeholder={t('contents.merchants.form.please_select_retailer')}
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                { retailerList ? retailerList.map((item) => (
                                    <Option key={item.rid} value={item.rid}>{item.name + ` (rid: ${item.rid})`}</Option>
                                )) : null }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name="sid" className="mb-0">
                            <Select
                                onChange={onChangeSite}
                                showSearch
                                style={{ width: '100%' }}
                                placeholder={t('contents.merchants.form.please_select_site')}
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                { siteList ? siteList.map((item) => (
                                    <Option key={item.sid} value={item.sid}>{item.name + ` (sid: ${item.sid})`}</Option>
                                )) : null }
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Card>
    );
};

export default RetailerSiteSelector;
