import { useState } from "react";
const MergeColumns = (cols) => {
    const [columns, setColumns] = useState(cols);
    return columns.map((col, index) => ({
        ...col,
        onHeaderCell: (column) => ({
            width: column.width,
            onResize: (_, { size }) => {
                const newColumns = [...columns];
                newColumns[index] = { ...newColumns[index], width: size.width };
                setColumns(newColumns);
            },
        }),
    }))
};

export default MergeColumns;