import React, { useEffect, useState } from "react";
import {Tag, Table} from "antd";
import {CaretUpOutlined,CaretDownOutlined} from "@ant-design/icons";
import htmlParser from "html-react-parser";

const BreakdownByProvider = (props) => {
    const { t, statsData, detailsBoxTitle } = props; // eslint-disable-line
    const [dataList,setDataList] = useState([]);
    const [groupStatsDataByRid,setGroupStatsDataByRid] = useState([]);
    const [visibleColsByRid, setVisibleColsByRid] = useState([]);

    const [groupStatsDataBySid,setGroupStatsDataBySid] = useState([]);
    const [visibleColsBySid, setVisibleColsBySid] = useState([]);

    useEffect(() => {
        if(statsData.provider !== false && statsData.provider !== null) {      
            const list = [];
            statsData.provider.forEach(function(item, index, object) {
                if(item.hasOwnProperty('sitename')){
                    let res = object.filter((r) =>
                        r.sitename.indexOf(item.sitename) !== -1
                    )
                    if(res.length === 1 || (res.length > 1 && item.total !== 0 && item.providerinitialname !== null)){
                        list.push(item);
                    }
                } else if(item.hasOwnProperty('retailername')){
                    let res = object.filter((r) =>
                        r.retailername.indexOf(item.retailername) !== -1
                    )
                    if(res.length === 1 || (res.length > 1 && item.total !== 0 && item.providerinitialname !== null)){
                        list.push(item);
                    }
                } else{
                    let res = object.filter((r) =>
                        r.merchantname.indexOf(item.merchantname) !== -1
                    )
                    if(res.length === 1 || (res.length > 1 && item.total !== 0 && item.providerinitialname !== null)){
                        list.push(item);
                    }
                }
            });
            setDataList(list);
        }
    }, [statsData]);

    useEffect(() => {
        if(dataList !== null){
            const data = [];
            if(dataList.some(item => item.hasOwnProperty('sitename'))){
                if (Object.keys(dataList).length) {
                    setVisibleColsBySid(Object.keys(dataList[0]));
                }
    
                Object.keys(dataList).forEach((item) => {
                    const k = htmlParser(dataList[item]?.merchantname + ' - ' + dataList[item]?.retailername);
                    const d = {
                        key: k,
                        sitename: dataList[item].sitename,
                        providerinitialname: dataList[item]?.providerinitialname,
                        total: dataList[item].total
                    };
                    if(dataList.some(item => item.hasOwnProperty('reasoncode'))){
                        d['reasoncode'] = dataList[item].reasoncode;
                    }
                    if (k in data) {
                        data[k].total = data[k].total + parseInt(d.total);
                        data[k].innerrow.push(d);
                    } else {
                        data[k] = [];
                        data[k].key = k;
                        data[k].sitename = dataList[item].sitename;
                        data[k].providerinitialname = dataList[item].providerinitialname;
                        data[k].total = parseInt(d.total);
                        if(dataList.some(item => item.hasOwnProperty('reasoncode'))){
                            data[k].reasoncode = dataList[item].reasoncode;
                        }
                        data[k].innerrow = [d];
                    }
                });
                setGroupStatsDataBySid(Object.values(data));
            }
            if(dataList.some(item => item.hasOwnProperty('retailername'))){
                if (Object.keys(dataList).length) {
                    setVisibleColsByRid(Object.keys(dataList[0]));
                }
    
                Object.keys(dataList).forEach((item) => {
                    const k = htmlParser(dataList[item]?.merchantname);
                    const d = {
                        key: k,
                        retailername: dataList[item].retailername,
                        providerinitialname: dataList[item]?.providerinitialname,
                        total: dataList[item].total
                    };
                    if(dataList.some(item => item.hasOwnProperty('reasoncode'))){
                        d['reasoncode'] = dataList[item].reasoncode;
                    }
                    if (k in data) {
                        data[k].total = data[k].total + parseInt(d.total);
                        data[k].innerrow.push(d);
                    } else {
                        data[k] = [];
                        data[k].key = k;
                        data[k].retailername = dataList[item].retailername;
                        data[k].providerinitialname = dataList[item].providerinitialname;
                        data[k].total = parseInt(d.total);
                        if(dataList.some(item => item.hasOwnProperty('reasoncode'))){
                            data[k].reasoncode = dataList[item].reasoncode;
                        }
                        data[k].innerrow = [d];
                    }
                });
                setGroupStatsDataByRid(Object.values(data));
            }
        }
    }, [dataList]);

    const columnsByRid = [
        {
          title: t("contents.alerts.merchantname"),
          dataIndex: 'key',
          key: 'key',
          width: '70%',
        },
        {
          title: "Total Count",
          dataIndex: 'total',
          key: 'total',
          width: '30%',
        },
      ];

    const columnsBySid = [
        {
          title: t("contents.alerts.merchantname") +" - "+ t("contents.alerts.retailername"),
          dataIndex: 'key',
          key: 'key',
          width: '70%',
        },
        {
          title: "Total Count",
          dataIndex: 'total',
          key: 'total',
          width: '30%',
        },
      ];

    const customExpandIcon = (props) => {
        if (props.expanded) {
            return <button style={{ color: 'black',backgroundColor: 'transparent',
            border: 'none',textDecoration: 'underline',cursor: 'pointer'}} onClick={e => {
                props.onExpand(props.record, e);
            }}><CaretUpOutlined /></button>
        } else {
            return <button style={{ color: 'black',backgroundColor: 'transparent',
            border: 'none',textDecoration: 'underline',cursor: 'pointer'}} onClick={e => {
                props.onExpand(props.record, e);
            }}><CaretDownOutlined /></button>
        }
    };

    const hiddenColumnByRid = (col) => {
        return (visibleColsByRid.indexOf(col) === -1);
    }

    const innerTableByRid = (innerData) => (
        <table style={{ tableLayout: "auto", width: "100%" }}>
            <thead className="ant-table-thead">
                {innerData !== false && innerData !== null && innerData.some(item => item.hasOwnProperty('reasoncode')) ?                                 
                    <tr>
                        <th className="ant-table-cell" width="15%" hidden = {hiddenColumnByRid('retailername')}>
                            {t("contents.alerts.retailername")}
                        </th>
                        <th className="ant-table-cell" width="15%" hidden = {hiddenColumnByRid('providerinitialname')}>
                            {t("contents.alerts.providername")}
                        </th>
                        <th className="ant-table-cell" width="15%">
                            {t("contents.alerts.reasoncode")}
                        </th>
                        <th className="ant-table-cell" width="15%">
                            <div className="text-center">{t("contents.alerts.count")}</div>
                        </th>
                    </tr>
                    :
                    <tr>
                        <th className="ant-table-cell" width="20%" hidden = {hiddenColumnByRid('retailername')}>
                            {t("contents.alerts.retailername")}
                        </th>
                        <th className="ant-table-cell" width="20%" hidden = {hiddenColumnByRid('providerinitialname')}>
                            {t("contents.alerts.providername")}
                        </th>
                        <th className="ant-table-cell" width="20%">
                            <div className="text-center">{t("contents.alerts.count")}</div>
                        </th>
                    </tr>
                }
            </thead>
            <tbody className="ant-table-tbody">
                {innerData
                    ? Object.keys(innerData).map((item) => (
                            <tr className="ant-table-row ant-table-row-level-0" key={item}>
                                <td className="ant-table-cell" align="left" hidden = {hiddenColumnByRid('retailername')}>
                                    {(innerData[item]?.retailername ? htmlParser(innerData[item]?.retailername) : <Tag color="error">Empty</Tag>)}
                                </td>
                                <td className="ant-table-cell" align="left" hidden = {hiddenColumnByRid('providerinitialname')}>
                                    {(innerData[item]?.providerinitialname ? innerData[item]?.providerinitialname : <Tag color="error">Empty</Tag>)}
                                </td>
                                {innerData.some(item => item.hasOwnProperty('reasoncode')) ? 
                                    <td className="ant-table-cell" align="left">
                                        {innerData[item]?.reasoncode!=='' ? innerData[item]?.reasoncode : <Tag color="error">Empty</Tag>}
                                    </td>: null
                                }
                                <td className="ant-table-cell"  align="center">{innerData[item]?.total}</td>
                            </tr>
                        ))
                    : null}
            </tbody>
        </table>
    );

    const hiddenColumnBySid = (col) => {
        return (visibleColsBySid.indexOf(col) === -1);
    }

    const innerTableBySid = (innerData) => (
        <table style={{ tableLayout: "auto", width: "100%" }}>
            <thead className="ant-table-thead">
                {innerData !== false && innerData !== null && innerData.some(item => item.hasOwnProperty('reasoncode')) ?                                 
                    <tr>
                        <th className="ant-table-cell" width="15%" hidden = {hiddenColumnBySid('sitename')}>
                            {t("contents.alerts.sitename")}
                        </th>
                        <th className="ant-table-cell" width="15%" hidden = {hiddenColumnBySid('providerinitialname')}>
                            {t("contents.alerts.providername")}
                        </th>
                        <th className="ant-table-cell" width="15%">
                            {t("contents.alerts.reasoncode")}
                        </th>
                        <th className="ant-table-cell" width="15%">
                            <div className="text-center">{t("contents.alerts.count")}</div>
                        </th>
                    </tr>
                    :
                    <tr>
                        <th className="ant-table-cell" width="20%" hidden = {hiddenColumnBySid('sitename')}>
                            {t("contents.alerts.sitename")}
                        </th>
                        <th className="ant-table-cell" width="20%" hidden = {hiddenColumnBySid('providerinitialname')}>
                            {t("contents.alerts.providername")}
                        </th>
                        <th className="ant-table-cell" width="20%">
                            <div className="text-center">{t("contents.alerts.count")}</div>
                        </th>
                    </tr>
                }
            </thead>
            <tbody className="ant-table-tbody">
                {innerData
                    ? Object.keys(innerData).map((item) => (
                            <tr className="ant-table-row ant-table-row-level-0" key={item}>
                                <td className="ant-table-cell" align="left" hidden = {hiddenColumnBySid('sitename')}>
                                    {(innerData[item]?.sitename ? htmlParser(innerData[item]?.sitename) : <Tag color="error">Empty</Tag>)}
                                </td>
                                <td className="ant-table-cell" align="left" hidden = {hiddenColumnBySid('providerinitialname')}>
                                    {(innerData[item]?.providerinitialname ? innerData[item]?.providerinitialname : <Tag color="error">Empty</Tag>)}
                                </td>
                                {innerData.some(item => item.hasOwnProperty('reasoncode')) ? 
                                    <td className="ant-table-cell" align="left">
                                        {innerData[item]?.reasoncode!=='' ? innerData[item]?.reasoncode : <Tag color="error">Empty</Tag>}
                                    </td>: null
                                }
                                <td className="ant-table-cell"  align="center">{innerData[item]?.total}</td>
                            </tr>
                        ))
                    : null}
            </tbody>
        </table>
    );

    const getHeaderTable = () => (
        <div className="custom-table--header d-flex justify-content-between align-items-center">
            <h2 className="table-title">{t(`contents.alerts.${detailsBoxTitle}`)}</h2>
        </div>
    );

    const getDetails = () => {
        return (<div className="table-v3">
        {dataList !== false && dataList !== null  && dataList.some(item => item.hasOwnProperty('sitename')) ? 
            <div className="ant-table ant-table-small">
              <div className="ant-table-container">
                  <div className="ant-table-content">
                      <Table style={{ tableLayout: "auto", width: "100%" }}
                          columns={columnsBySid}
                          title={() => getHeaderTable()}
                          dataSource={groupStatsDataBySid}
                          expandIcon={(props) => customExpandIcon(props)}
                          expandRowByClick={true}
                          expandedRowRender={(record) => innerTableBySid(record.innerrow)}
                          pagination={false} 
                      />
                  </div>
              </div>
          </div>
        :    
        dataList !== false && dataList !== null && dataList.some(item => item.hasOwnProperty('retailername')) ? 
            <div className="ant-table ant-table-small">
              <div className="ant-table-container">
                  <div className="ant-table-content">
                      <Table style={{ tableLayout: "auto", width: "100%" }}
                          columns={columnsByRid}
                          title={() => getHeaderTable()}
                          dataSource={groupStatsDataByRid}
                          expandIcon={(props) => customExpandIcon(props)}
                          expandRowByClick={true}
                          expandedRowRender={(record) => innerTableByRid(record.innerrow)}
                          pagination={false} 
                      />
                  </div>
              </div>
          </div>
        : 
        <div className="ant-table ant-table-small">
            <div className="ant-table-title">
                <div className="custom-table--header d-flex justify-content-between align-items-center">
                    <h2 className="table-title">{t(`contents.alerts.${detailsBoxTitle}`)}</h2>
                </div>
            </div>
            <div className="ant-table-container">
                <div className="ant-table-content">
                    <table style={{ tableLayout: "auto", width: "100%", paddingLeft: "10px" }}>
                        <thead className="ant-table-thead">
                            {dataList !== false && dataList !== null && dataList.some(item => item.hasOwnProperty('reasoncode')) ?                                 
                            <tr>
                                <th className="ant-table-cell" width="30%">
                                    {t("contents.alerts.merchantname")}
                                </th>
                                <th className="ant-table-cell" width="30%">
                                    {t("contents.alerts.providername")}
                                </th>
                                <th className="ant-table-cell" width="20%">
                                    {t("contents.alerts.reasoncode")}
                                </th>
                                <th className="ant-table-cell" width="20%">
                                    <div className="text-center">{t("contents.alerts.count")}</div>
                                </th>
                            </tr>
                            :
                            <tr>
                                <th className="ant-table-cell" width="40%">
                                    {t("contents.alerts.merchantname")}
                                </th>
                                <th className="ant-table-cell" width="40%">
                                    {t("contents.alerts.providername")}
                                </th>
                                <th className="ant-table-cell" width="20%">
                                    <div className="text-center">{t("contents.alerts.count")}</div>
                                </th>
                            </tr>
                            }
                        </thead>
                        <tbody className="ant-table-tbody">
                            {statsData
                                ? Object.keys(dataList).map((item) => (
                                    <tr className="ant-table-row ant-table-row-level-0" key={item}>
                                        <td className="ant-table-cell" align="left">
                                            {dataList[item]?.merchantname!=='' ? htmlParser(dataList[item]?.merchantname) : <Tag color="error">Empty</Tag>}
                                        </td>
                                        <td className="ant-table-cell" align="left">
                                            {dataList[item]?.providerinitialname!=='' ? dataList[item]?.providerinitialname : <Tag color="error">Empty</Tag>}
                                        </td>
                                        {dataList !== false && dataList !== null && dataList.some(item => item.hasOwnProperty('reasoncode')) ? 
                                            <td className="ant-table-cell" align="left">
                                                {dataList[item]?.reasoncode!=='' ? dataList[item]?.reasoncode : <Tag color="error">Empty</Tag>}
                                            </td>: null
                                        }
                                        <td className="ant-table-cell"  align="center">{dataList[item]?.total}</td>
                                    </tr>
                                  ))
                                : null}
                        </tbody>
                    </table>
                </div>
            </div>
            </div>
        }
    </div>);
    };

    return getDetails();
};

export default BreakdownByProvider;
