
import React, { useState, useEffect } from "react";
import {  Button,Modal} from "antd";
import { API_TXALERT2 } from "../../../config/config";
import axios from "axios";
import Swal from "sweetalert2";
import GroupAccess from "../../../helper/GroupAccess";
import CatchError from "../../../helper/CatchError";

const Txalert_ERMatchProcess = (props) => {
    const { t, id,setId,isOpen,setIsOpen, fetchList } = props;
    const [details, setDetails] = useState(null);
    const [loading, setLoading] = useState(false);
    

    useEffect(() => {

        if(isOpen){
            if (typeof id != 'undefined') {            
                getDetails();
            }
        }
       }, [isOpen]); // eslint-disable-line
       
       
       const getDetails = async () => {
       
        const api_url = API_TXALERT2 + `/express_resolve_tx_matches/${id}`;
        await axios
            .get(api_url, { headers: { Authorization: localStorage.getItem("txalert_token") } })
            .then(({ data }) => {                
                let recdata = data;
                var trx_info = recdata.transaction_info === null? null:JSON.parse(recdata.transaction_info);
               
                var refundedamount = Object.keys(trx_info).length === 0 ? 0: trx_info.refundedamount?trx_info.refundedamount:0;
                setDetails({...recdata, refundedamount:refundedamount});
             })
            .catch((error) => console.log(error));
       };
       
       
const handleCancel = () => {             
    setLoading(false);
    setId('');
    setIsOpen(false);
    
};

const handleOk = () => {
    setLoading(true);
    onFinish();
};

const onFinish = () => {
    return new Promise((resolve, reject) => {
        axios
            .post(
                API_TXALERT2 +'/express_resolve_tx_matches/'+id+'/refund',
                {},
                { headers: { "content-type": "application/json", Authorization: "Bearer " + localStorage.getItem("txalert_token") } }
            )
            .then(({ data }) => {
                if (data.status === "OK") {
                    resolve();
                    Swal.fire({
                        icon: "success",
                        title: "Refund successful.",
                        showConfirmButton: false,
                        timer: 2000,
                        onClose: () => {
                            fetchList();
                        },
                    });
                } else {
                    reject();
                    CatchError(data);
                }
            })
            .catch((error) => {
                reject();
                CatchError(error);
            });
    });
}



return(
   
    <>
        <Modal
            title={t("contents.possibletransactionmatches.confirm.q_1")}
            visible={isOpen}
            width={600}            
            onCancel={handleCancel}
            onOk={handleOk}
            bodyStyle={{ paddingTop: '0px' }}
            footer={[
                <Button key="back" onClick={() => handleCancel()}>
                    {t("contents.txalert_admin.form.cancel")}
                </Button>,
                 GroupAccess("SYSADMIN") ? 
                <Button key="submit" type="primary" loading={loading} onClick={() => handleOk()}>
                  { t("header.menu.logout.yes")}
                </Button>:'',
            ]}
        >
            {details?(
        <div>
                <p>
                    {t("contents.possibletransactionmatches.confirm.notice_1")}
                    <br />
                    {t("contents.possibletransactionmatches.confirm.notice_11")} <strong>${details.transaction_amount - details.refundedamount }</strong>{" "}
                    {t("contents.possibletransactionmatches.confirm.notice_12")} <strong>{details.gateway_tx_id}</strong>.
                </p>
                <p>
                    {t("contents.possibletransactionmatches.confirm.notice_21")} <strong>{details.alertid}</strong>{" "}
                    {t("contents.possibletransactionmatches.confirm.notice_22")} <br />
                    {t("contents.possibletransactionmatches.confirm.outcome")} <br />
                    {t("contents.possibletransactionmatches.confirm.refunded")} <br />
                    {t("contents.possibletransactionmatches.confirm.reason")}
                </p>
            </div>):''}
        </Modal>
    </>
);
};

export default Txalert_ERMatchProcess;