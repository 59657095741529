const AssociationReferences = (assocName) => {
    if (!JSON.parse(localStorage.getItem("loggedUser")).associations) {
        return false;
    }
    const associations = JSON.parse(localStorage.getItem("loggedUser")).associations;

    if (Array.isArray(associations) && associations.length > 0) {
        try {
            const association = associations.filter((association) => association.type === assocName);
            if (association.length > 0) {
                if (association[0].refs) {
                    return association[0].refs.split(",");
                } else return false;
            } else return false;
        } catch (error) {
            return false;
        }
    } else return false;
};
export default AssociationReferences;
