import { API } from "../config/config";
import axios from "axios";

const UserSettings = () => {
    
    const ENDPOINT = "/updateprofilesettings/";

    const updateSettings = (settings) => {

        const request = {
            dashboard_settings: settings
        };

        axios
            .post(API + ENDPOINT, request, { headers: { Authorization: "Bearer " + localStorage.getItem("access_token") } })
            .then(() => { console.log('User Settings Updated') })
            .catch(() => { console.log('User Settings Update Failed') });
    };

    return [updateSettings];
}

export default UserSettings;