const FormatAmount = (amount, currency = 'USD') => {
    if (!amount) {
        return amount;
    }

    const checkLocale = () => {
        const key = localStorage.getItem("lang");
        switch (key) {
            case "jp":
                return "ja-JP";
            case "cn":
                return "zh-CN";
            case "tw":
                    return "zh-TW";
            default:
                return "en-US";
        }
    };
    
    try {
        return new Intl.NumberFormat(checkLocale(), {
            style: 'currency',
            currency: currency
            }).format(amount);
    } catch (e) {
        // This is where you handle the error
        return  amount;
    }
    
      
};

export default FormatAmount;