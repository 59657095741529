import React, { useState, useEffect } from "react";
import DataFetchHookV3 from "../../helper/DataFetchHookV3";
import { Table, PageHeader, Card, Form, Row, Button, Col, Input, Select } from "antd";
import { API_TXALERT } from "../../config/config";
import { SearchOutlined } from "@ant-design/icons";
import ExportModalV3 from "../../helper/ExportModalV3";
import GroupAccess from "./../../helper/GroupAccess";
import axios from "axios";
import moment from "moment";
import MerchantList from "./../../components/MerchantList";
import RetailerList from "./../../components/RetailerList";
import SiteList from "./../../components/SiteList";
import ResellerList from "./../../components/ResellerList";
import ProviderList from "./../../components/ProviderList";
import CustomPaginationV2 from "../../helper/Table/CustomPaginationV2";
import SortOrder from "../../helper/Table/SortOrder";
import ColumnResizable from "../../helper/Table/ColumnResizable";
import htmlParser from "html-react-parser";

const DATA_URL = API_TXALERT + "/descriptor";
const PROVIDER_DATA_URL = API_TXALERT + "/provider"
const DATA_URL_EXPORT = API_TXALERT + "/descriptor/reports";

const { Option } = Select;

const DescriptorList = (props) => {
    // eslint-disable-next-line
    const [{ data, totalResult, isLoading, requestData, isUpdated }, doFetch, setRequestData] = DataFetchHookV3(DATA_URL, "POST", {
        size: 20,
        page: 1,
        order: "DESC"
    });


    const [midSelect, setMidSelect] = useState('');
    const [ridSelect, setRidSelect] = useState('');

    const [sortedInfo, setSortedInfo] = useState({});
    const { t } = props;
    const [formData, setFormData] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    const [providerList, setProviderList] = useState(false);
    const initFormData = {
        descriptor: "",
        display: null,
        merchant: [],
        retailer: [],
        site: [],
        reseller: [],
        provider: [],
        bank_mid: "",
        status: []
    };

    const [form] = Form.useForm();

    useEffect(() => {
        formValInit();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const onChangeRetailer = async (value) => {
        await setRidSelect(value)
    }

    const onChangeMerchant = async (value) => {
        await setMidSelect(value)
    }

    const handleChange = (pagination, filters, sorter, { currentDataSource }) => {
        setSortedInfo(sorter);
    };

    const clearAll = () => {
        setRidSelect('');
        setMidSelect('');
        setFormData(initFormData);
        setCurrentPage(1);
        setSortedInfo(null);
        setRequestData({ ...requestData, ...initFormData, startDate: null, endDate: null, page: 1 });
        form.setFieldsValue(initFormData);
    };

    const formValInit = () => {
        setFormData(initFormData);
        form.setFieldsValue(initFormData);


    };

    const formValChange = (chgV, allV) => {
        setFormData(allV);
    };

    const onFinish = () => {
        let searchData = {};
        if (formData) {
            searchData = {
                descriptor: formData.descriptor.trim(),
                mid: formData.merchant,
                rid: formData.retailer,
                sid: formData.site,
                providerid: formData.provider,
                bank_mid: formData.bank_mid.trim(),
                reseller: formData.reseller,
                status: formData.status
            };

        }

        setCurrentPage(1);
        setRequestData({ ...requestData, ...searchData, page: 1 });
    };

    useEffect(() => {
        if (!providerList) {
            axios
                .post(
                    PROVIDER_DATA_URL,
                    {},
                    { headers: { Authorization: "Bearer " + localStorage.getItem("access_token"), Authuser: localStorage.getItem("authuser") } }
                )
                .then(async ({ data }) => {
                    await setProviderList(data);
                    const fData = data.map((d) => {
                        return {
                            ...d,
                            ellipsis: true,
                            width: 100,
                        }
                    });

                    const temp = [...columns, ...fData];
                    setColumns(temp);
                })
                .catch(() => false);
        }
    }, []); // eslint-disable-line

    const exportHeadersOptional = [];
    const exportHeaders = [
        { label: "Bank MID", key: "ad.bank_mid" },
        { label: "Descriptor", key: "ad.descriptor" },
        { label: "ID", key: "ad.descriptor_id" },
        { label: "Merchant Name", key: "m.name" },
        { label: "Site Name", key: "rs.name" }
    ];

    const [columns, setColumns] = useState([
        {
            dataIndex: "descriptor_id",
            key: "descriptor_id",
            title: t("tables.fields.id"),
            width: 100,
            ellipsis: true,
            sorter: {
                compare: (a, b) => a.descriptor_id - b.descriptor_id,
                multiple: 1,
            },

            render: (id, record) => (
                <a href={`/alertdescriptor/${id}`} target="_blank" rel="noopener noreferrer">
                    {id}{" "}
                </a>
            ),
            sortOrder: SortOrder(sortedInfo, "descriptor_id"),
            hidden: false
        },
        {
            dataIndex: "descriptor",
            key: "descriptor",
            title: t("tables.fields.descriptor"),
            ellipsis: true,
            width: 120,
            render: (descriptor) => htmlParser(descriptor),
            sorter: {
                compare: (a, b) => a.descriptor.localeCompare(b.descriptor),
                multiple: 3,
            },
            sortOrder: SortOrder(sortedInfo, "descriptor"),
            hidden: false
        },
        {
            dataIndex: "bank_mid",
            key: "bank_mid",
            title: t("tables.fields.bank_mid"),
            width: 100,
            hidden: false
        },
        {
            dataIndex: "merchant",
            key: "merchant",
            title: t("tables.fields.merchant"),
            width: 100,
            ellipsis: true,
            render: (merchant) => htmlParser(merchant ?? 'no merchant set'),
            sorter: {
                compare: (a, b) => a.merchant.localeCompare(b.merchant),
                multiple: 3,
            },
            sortOrder: SortOrder(sortedInfo, "merchant"),
            hidden: false
        },
        {

            key: "site_name",
            title: t("tables.fields.sitename"),
            width: 100,
            ellipsis: true,
            sorter: {
                compare: (a, b) => a.site_name.localeCompare(b.site_name),
                multiple: 3,
            },
            render: (row) => {
                if (row.site_name !== null) {
                    let site_name = htmlParser(row.site_name);
                    return site_name + '(sid:' + row.site_id + ')';
                }
                else {
                    return "";
                }


            },
            sortOrder: SortOrder(sortedInfo, "site_name"),
            hidden: GroupAccess("SYSADMIN") ? false : true
        },

    ].filter(item => !item.hidden));

    const AdvancedSearchForm = () => (
        <div className="search-wrap">
            <Card title={t("tables.actions.search")} size="small">
                <Form form={form} size="large" name="advanced_search" className="ant-advanced-search-form" onValuesChange={formValChange} onFinish={onFinish}>
                    <Row gutter={[16, 16]}>
                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                            <Form.Item name="descriptor">
                                <Input placeholder={t("tables.fields.descriptor")} />
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }} xl={{ span: 5 }}>

                            <Form.Item name="bank_mid">
                                <Input placeholder={t("tables.fields.bank_mid")} />
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }} xl={{ span: 5 }}>

                            <Form.Item name="provider">
                                <ProviderList placeholder={t("tables.fields.providername")} />
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }} xl={{ span: 5 }}>

                            <Form.Item name="status">
                                <Select placeholder={t("contents.merchants.form.status")}
                                    showSearch
                                    mode="multiple"
                                    showArrow="true">
                                    <Option value="enrolled">ENROLLED</Option>
                                    <Option value="new">NEW</Option>
                                    <Option value="requested">REQUESTED</Option>
                                    <Option value="pending">PENDING</Option>
                                    <Option value="disabled">DISABLED</Option>
                                    <Option value="other">OTHER</Option>
                                </Select>

                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[16, 16]}>
                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }} xl={{ span: 5 }}>
                            <Form.Item name="merchant">
                                <MerchantList placeholder={t("contents.merchants.title")} onChange={onChangeMerchant}
                                    style={{ width: "100%" }} />
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                            <Form.Item name="retailer">
                                <RetailerList placeholder={t("contents.retailers.title")} mid={midSelect} onChange={onChangeRetailer}
                                    style={{ width: "100%" }} />
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                            <Form.Item name="site">
                                <SiteList placeholder={t("contents.sites.title")} mid={midSelect} rid={ridSelect}
                                    style={{ width: "100%" }} />
                            </Form.Item>
                        </Col>
                        {GroupAccess("SYSADMIN") ? (
                            <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                                <Form.Item name="reseller">
                                    <ResellerList placeholder={t("contents.resellers.title")}
                                        style={{ width: "100%" }} />
                                </Form.Item>
                            </Col>
                        ) : (
                            ""
                        )}
                    </Row>
                    <Row gutter={[16, 16]}>
                        <Col flex="auto"></Col>
                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6, offset: 12 }} lg={{ span: 4, offset: 0 }} xl={{ span: 3, offset: 4 }}>
                            <Form.Item>
                                <Button type="secondary" onClick={() => clearAll()} block>
                                    {t("tables.actions.clear_all")}
                                </Button>
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 4 }} xl={{ span: 4 }}>
                            <Form.Item>
                                <Button type="primary" htmlType="submit" block>
                                    <SearchOutlined /> {t("tables.actions.search")}
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Card>
        </div>
    );

    const getDataSource = (datalist) => {
        if (datalist.length > 0 && providerList){
            for (let row in datalist){
                for (let provider in providerList){
                    const data = datalist[row][providerList[provider]['dataIndex']];
                    if (data){
                        const status = data.split(" ");
                        if (status.length > 1){
                            status[1] = moment(status[1], "YYYY-MM-DD").format('ll');
                            datalist[row][providerList[provider]['dataIndex']] = status.join(' ');
                        }
                    }
                }
            }
        }
        return (datalist && Array.isArray(datalist) ? datalist : []);
    }

    const getHeaderTable = () => (
        <div className="custom-table--header d-flex justify-content-between align-items-center">
            <h3 className="table-title">{t("contents.descriptors.title")}</h3>
            <ExportModalV3
                t={t}
                keyname="export_all"
                title={t("tables.actions.export")}
                data={requestData}
                data_url={DATA_URL_EXPORT}
                headers={exportHeaders}
                fieldHeadersOptional={exportHeadersOptional}
                filename={`alertdescriptors_${moment().format("YYMMDD_HHmmss_SSSS")}.csv`}
                style={{ marginLeft: 8 }}
            />
        </div>
    );

    const mergeColumns = columns.map((col, index) => ({
        ...col,
        onHeaderCell: (column) => ({
            width: column.width,
            onResize: (_, { size }) => {
                const newColumns = [...columns];
                newColumns[index] = { ...newColumns[index], width: size.width };
                setColumns(newColumns);
            },
        }),
    }));

    return (
        <>
            <PageHeader title={t("contents.descriptors.title")}></PageHeader>
            {AdvancedSearchForm()}
            <Table
                rowKey="descriptor_id"
                size="small"
                title={() => getHeaderTable()}
                loading={isLoading}
                className="table-v3 custom-pagination"
                components={{
                    header: {
                        cell: ColumnResizable,
                    },
                }}
                columns={mergeColumns}
                dataSource={data.datalist && Array.isArray(data.datalist) ? getDataSource(data.datalist) : []}
                onChange={handleChange}
                pagination={CustomPaginationV2({ totalResult, currentPage, requestData, setRequestData, setCurrentPage })}
                scroll={{ x: 1500 }}
            />
        </>
    );
};

export default DescriptorList;
