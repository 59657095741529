import React, { useState } from "react";
import { Typography, Button, Input, Tooltip,Form,Checkbox } from "antd";
import { CopyOutlined } from "@ant-design/icons";
import OtpInput from "react-otp-input";

const { Title, Paragraph } = Typography;

const OtpVerify = (props) => {
    const { t, otp, iscookieEnabled, handleCheckboxChange, handleChange, onSubmit, option, qrcode, secret, firstlogin_succeed } = props;

    const [showSecret, setShowSecret] = useState(false);
    const [isCopied, setIsCopied] = useState(false);

    return (
        <>
            <div className="d-flex align-items-center" style={{ height: "100vh" }}>
                <div style={{ margin: "0 auto", textAlign: "center" }}>
                    <>
                        <Title level={3}>{t("login.verification.verificationcode")}</Title>
                        {option === "otp" && !firstlogin_succeed && qrcode ? (
                            <>
                                <div style={{ marginBottom: "0.5rem" }}>
                                    <p>{t("login.verification.scan_qrcode_below")}</p>
                                    <img alt="Scan the QR code" src={qrcode} width="200px" />
                                </div>
                                {secret ? (
                                    <div className="text-cener" style={{ marginBottom: "8px" }}>
                                        <Button
                                            type="link"
                                            onClick={() => {
                                                setShowSecret(!showSecret);
                                            }}
                                        >
                                            {t("login.verification.cantscan_click")}
                                        </Button>
                                        {showSecret ? (
                                            <Input.Group compact>
                                                <Input style={{ width: "calc(100% - 32px)" }} defaultValue={secret} readOnly />
                                                <Tooltip
                                                    title={isCopied ? t("login.verification.copied") : t("login.verification.copy_secretkey")}
                                                    onVisibleChange={(visible) =>
                                                        !visible
                                                            ? setTimeout(() => {
                                                                  setIsCopied(false);
                                                              }, 150)
                                                            : null
                                                    }
                                                >
                                                    <Button
                                                        icon={<CopyOutlined />}
                                                        onClick={() => {
                                                            setIsCopied(true);
                                                            navigator.clipboard.writeText(secret);
                                                        }}
                                                    />
                                                </Tooltip>
                                            </Input.Group>
                                        ) : null}
                                    </div>
                                ) : null}
                            </>
                        ) : null}

                        <Paragraph type="secondary" className="text-cener" style={{ maxWidth: "460px" }}>
                            {option === "email" ? t("login.verification.emailcode") : t("login.verification.entercode")}
                        </Paragraph>

                        <OtpInput
                            value={otp}
                            onChange={handleChange}
                            numInputs={6}
                            isInputNum={true}
                            shouldAutoFocus={true}
                            separator={<span style={{ margin: "0 .15rem" }}>-</span>}
                            containerStyle={{ marginBottom: "1rem", justifyContent: "center" }}
                            inputStyle={{ width: "36px", height: "48px", fontSize: "1.5rem" }}
                        />
                        {iscookieEnabled ? (
                        <Form.Item style={{ flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                                <div style={{ paddingTop: "6px" }}>
                                    <Checkbox
                                        name="rememberme"
                                        onChange={handleCheckboxChange}
                                        defaultChecked={ false }
                                    >
                                        Remember Me?
                                    </Checkbox>
                                </div>
                        </Form.Item>
                        ) : null}
                        <Button
                            type="primary"
                            onClick={() => onSubmit()}
                            className="login-form-button"
                            size="large"
                            shape="round"
                            disabled={!otp || otp.length !== 6}
                        >
                            {t("login.verification.verify")}
                        </Button>
                    </>
                </div>
            </div>
        </>
    );
};

export default OtpVerify;
