import React, { useState, useEffect } from "react";
import { Table, PageHeader, Card, Form, Row, Col, Button, Space, Input } from "antd";
import { SearchOutlined, FileSearchOutlined ,PlusOutlined} from "@ant-design/icons";
import CustomPaginationV2 from "../../../helper/Table/CustomPaginationV2";
import { API_TXALERT2 } from "../../../config/config";
import ColumnResizable from "../../../helper/Table/ColumnResizable";
import MergeColumns from "../../../helper/Table/MergeColumns";
import DataFetchHookTxAlert from "../../../helper/DataFetchHookTxAlert";
import TextSorter from "../../../helper/Table/TextSorter";
import TxalertEmailTemplate from "./EmailTemplate";
import TxalertThemeDropdown from "../Components/ThemeDropdown";

const DATA_URL = API_TXALERT2 + "/email_templates";

const Txalert_EmailTemplates = (props) => {
    const [{ data, totalResult, isLoading, isError, errorMsg, requestData, isUpdated }, setUrl, setRequestData] = DataFetchHookTxAlert(DATA_URL,"GET", localStorage.getItem("txalert_token")); // eslint-disable-line
    const [formData, setFormData] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    const [id, setId] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const { t } = props;

    const initFormData = {
        template_id: "",
        template_code: "",
        theme_id: undefined,
    };

    const [form] = Form.useForm();

    useEffect(() => {

        formValInit();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const getEmailTemplatesList = () => {

        let searchData ={};
        setRequestData({ ...requestData, ...searchData,  page: 1 });

    };

    const showModal = (selectedid) =>{
        setId(selectedid);
        setIsOpen(true);
    };

    const formValInit = () => {
        setFormData(initFormData);
        form.setFieldsValue(initFormData);
    };

    const clearAll = () => {
        setCurrentPage(1);
        setFormData(initFormData);
        setRequestData({});
        form.setFieldsValue(initFormData);
    };

    const formValChange = (chgV, allV) => {
        setFormData(allV);
    };

    const onFinish = () => {
        let searchData = {};
        if (formData) {

            searchData = {
                template_id: formData.template_id.trim(),
                template_code: formData.template_code.trim(),
                theme_id: formData.theme_id,
            };

        }

        Object.keys(searchData).forEach((item) => !searchData[item] && delete searchData[item]);

        setCurrentPage(1);
        setRequestData({ ...requestData, ...searchData, page: 1 });
    };

    const columns = [
        {
            dataIndex: "id",
            key: "id",
            title: t("tables.fields.id"),
            width: 50,
            sorter: {
                compare: (a, b) => a.id - b.id
            }
        },
        {
            dataIndex: "template_id",
            key: "template_id",
            title: t("tables.fields.template_id"),
            width: 200,
            sorter: {
                compare: (a, b) => TextSorter(a.template_id,b.template_id)
            }
        },
        {
            dataIndex: "template_code",
            key: "template_code",
            title: t("tables.fields.template_code"),
            width: 150,
            sorter: {
                compare: (a, b) => TextSorter(a.template_code,b.template_code)
            }
        },
        {
            dataIndex: "theme_name",
            key: "theme_name",
            title: t("tables.fields.theme_name"),
            width: 120,
            sorter: {
                compare: (a, b) => TextSorter(a.theme_name,b.theme_name)
            }
        },
        {
            dataIndex: "active",
            key: "active",
            align: "left",
            title: t("tables.fields.active"),
            width: 100,
            render: (text, record) => (
                record.active === 1?'Active':'InActive'
            ) ,
            sorter: {
                compare: (a, b) => a.active-b.active
            }
        },
        {
            dataIndex: "action",
            title: t("tables.fields.action"),
            align: "left",
            width: 80,
            render: (text, record) => (
                <Space size="middle">
                    <Button type="link" onClick={() => showModal(record.id)} >
                        <FileSearchOutlined style={{ color: "rgba(0, 0, 0, 0.85)", fontSize: "18px" }} />
                    </Button>
                </Space>
            ),
        }
    ];

    const getDataSource = (datalist) => (datalist && Array.isArray(datalist) ? datalist : []);

    const AdvancedSearchForm = () => (
        <div className="search-wrap">
            <Card title={t("tables.actions.search")} size="small">
                <Form form={form} size="large" name="advanced_search" className="ant-advanced-search-form" onValuesChange={formValChange} onFinish={onFinish}>
                    <Row gutter={[16, 16]}>
                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }} xl={{ span: 9 }}>
                            <Form.Item name="template_id">
                                <Input placeholder={t("tables.fields.template_id")} />
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }} xl={{ span: 9 }}>
                            <Form.Item name="template_code">
                                <Input placeholder={t("tables.fields.template_code")} />
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }} xl={{ span: 6 }}>

                            <Form.Item name="theme_id">
                                <TxalertThemeDropdown placeholder={t("tables.fields.theme_name")} />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={[16, 16]}>
                        <Col flex="auto"></Col>
                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6, offset: 12 }} lg={{ span: 4, offset: 0 }} xl={{ span: 3, offset: 4 }}>
                            <Form.Item>
                                <Button type="secondary" onClick={() => clearAll()} block>
                                    {t("tables.actions.clear_all")}
                                </Button>
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 4 }} xl={{ span: 4 }}>
                            <Form.Item>
                                <Button type="primary" htmlType="submit" block>
                                    <SearchOutlined /> {t("tables.actions.search")}
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Card>
        </div>
    );

    const getHeaderTable = () => (
        <div className="custom-table--header d-flex justify-content-between align-items-center">
            <h3 className="table-title">{t("contents.txalert_admin.emailtemplatelist")}</h3>
            <Button type="link" onClick={() => showModal()} style={{ marginLeft: "auto" }}>
                <PlusOutlined /> {t("contents.txalert_admin.form.new_email_template")}
            </Button>

        </div>
    );

    return (
        <div>
            <PageHeader title={t("contents.txalert_admin.emailtemplatelist")}></PageHeader>
            {AdvancedSearchForm()}
            <Table
                size="small"
                loading={isLoading}
                components={{
                    header: {
                        cell: ColumnResizable,
                    },
                }}

                columns={MergeColumns(columns)}
                title={() => getHeaderTable()}
                rowKey="id"
                className="table-v3 custom-pagination custom-pagination-v3"
                dataSource={getDataSource(data.datalist)}
                pagination={CustomPaginationV2({ totalResult, currentPage, requestData, setRequestData, setCurrentPage })}

            />
            <TxalertEmailTemplate t={t} id={id} setId={setId} isOpen={isOpen} setIsOpen={setIsOpen} fetchList={getEmailTemplatesList} />
        </div>
    );
};


export default Txalert_EmailTemplates;
