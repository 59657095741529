import React, { useState, useEffect } from "react";
import { API_TXALERT2 } from "../../../config/config";
import { Button, Tabs, Row, Col, PageHeader, Card } from "antd";
import axios from "axios";
import Swal from "sweetalert2";
import TxalertAlertProvider from "./AlertProvider";
import GroupAccess from "../../../helper/GroupAccess";
import { EditOutlined } from "@ant-design/icons";
import TxalertDescriptorTbl from "../Components/DescriptorTbl";
import TxalertBinCaidTbl from "../Components/BinCaidTbl";

import CatchError from "../../../helper/CatchError";

const Txalert_AlertProviderDetail = (props) => {
    const provider_id = props.match.params.id;
    const [details, setDetails] = useState(null);
    const [loading, setLoading] = useState(true);
    const { t } = props;
    const [id, setId] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const [logins, setLogins] = useState([]);

   

    const showModal = (selectedid) => {
        setId(selectedid);
        setIsOpen(true);

    };

    useEffect(() => {
        if (loading === true) {
            getDetails();
            getLogins();
            setLoading(false);
        }
    }, [loading]); // eslint-disable-line

    const getDetails = () => {
        const apiUrl = API_TXALERT2 + `/alert_provider/${provider_id}`;

        axios
            .get(apiUrl, { headers: { Authorization: "Bearer " + localStorage.getItem("txalert_token") } })
            .then(({ data }) => {
                setDetails(data);
            })
            .catch((error) => {
                console.log(error.response);
                CatchError(error);
            });


    };

    useEffect(() => {
        if (details) {

            getLogins();
        }
    }, [details]); // eslint-disable-line

    const updateDetails = () => {
        setLoading(true);
    };

    const getLogins = async () => {
        if (!details) return [];
        const apiUrl = API_TXALERT2 + `/alert_provider/login?alert_provider_id=${provider_id}`;

        return await axios
            .get(apiUrl, { headers: { Authorization: "Bearer " + localStorage.getItem("txalert_token") } })
            .then(({ data }) => {
                setLogins(data.list);
            })
            .catch((error) => {
                console.log(error.response);
                Swal.fire({
                    icon: "error",
                    title: error.response ? error.response.data.error_msg.msg : t("login.dialogs.catch"),
                    showConfirmButton: true,
                });
            });



    }

    const showInfo = (labelSpan = 10, valueSpan = 14) => details ? (
        <Card className="details-info--card" loading={loading}>

            <Row gutter={[0, 8]} key="provider_id">
                <Col key="label-provider_id" span={labelSpan}>
                    <span className="text">
                        <b>{t(`contents.txalert_admin.form.alert_provider_id`)}</b>
                    </span>
                </Col>
                <Col key="value-provider_id" span={valueSpan}>
                    <span className="text">{details.alert_provider_id}</span>
                </Col>
            </Row>
            <Row gutter={[0, 8]} key="provider_name">
                <Col key="label-provider_name" span={labelSpan}>
                    <span className="text">
                        <b>{t(`contents.txalert_admin.form.provider_name`)}</b>
                    </span>
                </Col>
                <Col key="value-provider_name" span={valueSpan}>
                    <span className="text">{details.provider_name}</span>
                </Col>
            </Row>
            <Row gutter={[0, 8]} key="provider_class_file">
                <Col key="label-provider_class_file" span={labelSpan}>
                    <span className="text">
                        <b>{t(`contents.txalert_admin.form.class_file`)}</b>
                    </span>
                </Col>
                <Col key="value-provider_class_file" span={valueSpan}>
                    <span className="text">{details.provider_class_file}</span>
                </Col>
            </Row>
            <Row gutter={[0, 8]} key="provider_email">
                <Col key="label-merchant_alert_api_url" span={labelSpan}>
                    <span className="text">
                        <b>{t(`contents.txalert_admin.form.email`)}</b>
                    </span>
                </Col>
                <Col key="value-merchant_alert_api_url" span={valueSpan}>
                    <span className="text">{details.provider_email}</span>
                </Col>
            </Row>
            <Row gutter={[0, 8]} key="response_expiry">
                <Col key="label-response_expiry" span={labelSpan}>
                    <span className="text">
                        <b>{t(`contents.txalert_admin.form.response_expiry`)}</b>
                    </span>
                </Col>
                <Col key="value-response_expiry" span={valueSpan}>
                    <span className="text">{details.response_expiry}</span>
                </Col>
            </Row>

            <Row gutter={[0, 8]} key="standard_alerts">
                <Col key="label-standard_alerts" span={labelSpan}>
                    <span className="text">
                        <b>{t(`contents.txalert_admin.form.standard_alerts`)}</b>
                    </span>
                </Col>
                <Col key="value-standard_alerts" span={valueSpan}>
                    <span className="text">{details.standard_alerts === 1 ? 'Yes' : 'No'}</span>
                </Col>
            </Row>
            <Row gutter={[0, 8]} key="rdr_alerts">
                <Col key="label-rdr_alerts" span={labelSpan}>
                    <span className="text">
                        <b>{t(`contents.txalert_admin.form.rdr_alerts`)}</b>
                    </span>
                </Col>
                <Col key="value-rdr_alerts" span={valueSpan}>
                    <span className="text">{details.rdr_alerts === 1 ? 'Yes' : 'No'}</span>
                </Col>
            </Row>
            {GroupAccess("SYSADMIN") ?
                <Row>
                    <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 4 }} xl={{ span: 4 }}>
                        <Button type="primary" onClick={() => showModal(provider_id)} >
                            <EditOutlined /> {t("tables.actions.edit")}
                        </Button>
                        <TxalertAlertProvider t={t} id={id} setId={setId} isOpen={isOpen} setIsOpen={setIsOpen} fetchList={updateDetails} />

                    </Col>

                </Row> : ''}
        </Card>
    ) : null;




    const showDescriptors = () => details && details.standard_alerts === 1 ? (
        <Tabs.TabPane tab={t("contents.txalert_admin.tabs.descriptors")} key='descriptors'>
            <TxalertDescriptorTbl alert_provider_id={details.alert_provider_id} t={t} />
        </Tabs.TabPane>

    ) : null;


    const showBincaids = () => details && details.rdr_alerts === 1 ? (
        <Tabs.TabPane tab={t("contents.txalert_admin.tabs.bincaids")} key='bincaids'>
            <TxalertBinCaidTbl alert_provider_id={details.alert_provider_id} t={t} />
        </Tabs.TabPane>
    ) : null;

    return (
        <>
            <PageHeader className="site-page-header" title={t("contents.txalert_admin.alert_provider")} subTitle={`${details?.provider_name}(${provider_id})`}></PageHeader>

            <Tabs defaultActiveKey="1" type="card">

                <Tabs.TabPane tab={t("contents.txalert_admin.tabs.details")} key='provider_detail'>
                    {details ? showInfo() : ''}
                </Tabs.TabPane>
                
                    
                        {logins.length > 0 ?<Tabs.TabPane tab={t("contents.txalert_admin.alert_provider_logins")} key='provider_logins'>{
                        logins.map((d) => (
                            <Card className="details-info--card">

                                <Row gutter={[0, 8]} >
                                    <Col span="10">
                                        <span className="text">
                                            <b>{t(`contents.txalert_admin.form.username`)}</b>
                                        </span>
                                    </Col>
                                    <Col span="14">
                                        <span className="text">{d.username}</span>
                                    </Col>
                                </Row>
                                <Row gutter={[0, 8]} >
                                    <Col span="10">
                                        <span className="text">
                                            <b>{t(`contents.txalert_admin.form.url`)}</b>
                                        </span>
                                    </Col>
                                    <Col span="14">
                                        <span className="text">{d.url}</span>
                                    </Col>
                                </Row>
                                <Row gutter={[0, 8]} >
                                    <Col span="10">
                                        <span className="text">
                                            <b>{t(`contents.txalert_admin.form.path`)}</b>
                                        </span>
                                    </Col>
                                    <Col span="14">
                                        <span className="text">{d.path}</span>
                                    </Col>
                                </Row></Card>
                        ))}
                        </Tabs.TabPane>:''
                    }
                

                {provider_id === undefined ? '' : showDescriptors()}


                {provider_id === undefined ? '' : showBincaids()}

            </Tabs>
        </>
    );
};

export default Txalert_AlertProviderDetail;
