import React, { useState, useEffect } from "react";
import { API_TXALERT2 } from "../../../config/config";
import { Button, Tabs, Row, Col, PageHeader, Card } from "antd";
import axios from "axios";
import TxalertMerchant from "./Merchant";
import GroupAccess from "../../../helper/GroupAccess";
import { EditOutlined } from "@ant-design/icons";
import TxalertDescriptorTbl from "../Components/DescriptorTbl";
import TxalertBinCaidTbl from "../Components/BinCaidTbl";
import TxalertERGatewayLoginTbl from "../Components/ERGatewayLoginTbl";
import CatchError from "../../../helper/CatchError";
const Txalert_MerchantDetail = (props) => {
    const merchant_id = props.match.params.id;
    const [details, setDetails] = useState(null);
    const [loading, setLoading] = useState(true);
    const { t } = props;
    const [id, setId] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    

    const showModal = (selectedid) => {
        setId(selectedid);
        setIsOpen(true);

    };

    useEffect(() => {
        if (loading === true) {
            getDetails();
            setLoading(false);
        }
    }, [loading]); // eslint-disable-line

    const getDetails = () => {
        const apiUrl = API_TXALERT2 + `/merchant/${merchant_id}`;

        axios
            .get(apiUrl, { headers: { Authorization: "Bearer " + localStorage.getItem("txalert_token") } })
            .then(({ data }) => {
                setDetails(data);
            })
            .catch((error) => {
                console.log(error.response);
                CatchError(error);
            });


    };


    const updateDetails = () => {
        setLoading(true);
    };


    const showInfo = (labelSpan = 10, valueSpan = 14) => details ? (
        <Card className="details-info--card" loading={loading}>

            <Row gutter={[0, 8]} key="merchant_id">
                <Col key="label-merchant_id" span={labelSpan}>
                    <span className="text">
                        <b>{t(`contents.txalert_admin.form.merchant_id`)}</b>
                    </span>
                </Col>
                <Col key="value-merchant_id" span={valueSpan}>
                    <span className="text">{details.merchant_id}</span>
                </Col>
            </Row>
            <Row gutter={[0, 8]} key="name">
                <Col key="label-name" span={labelSpan}>
                    <span className="text">
                        <b>{t(`contents.txalert_admin.form.merchant_name`)}</b>
                    </span>
                </Col>
                <Col key="value-name" span={valueSpan}>
                    <span className="text">{details.name}</span>
                </Col>
            </Row>
            <Row gutter={[0, 8]} key="created_date">
                <Col key="label-created_date" span={labelSpan}>
                    <span className="text">
                        <b>{t(`contents.txalert_admin.form.created_date`)}</b>
                    </span>
                </Col>
                <Col key="value-created_date" span={valueSpan}>
                    <span className="text">{details.created_date}</span>
                </Col>
            </Row>
            <Row gutter={[0, 8]} key="alert_api_url">
                <Col key="label-merchant_alert_api_url" span={labelSpan}>
                    <span className="text">
                        <b>{t(`contents.txalert_admin.form.alert_api_url`)}</b>
                    </span>
                </Col>
                <Col key="value-merchant_alert_api_url" span={valueSpan}>
                    <span className="text">{details.alert_api_url}</span>
                </Col>
            </Row>
            <Row gutter={[0, 8]} key="api_user_name">
                <Col key="label-api_user_name" span={labelSpan}>
                    <span className="text">
                        <b>{t(`contents.txalert_admin.form.api_user_name`)}</b>
                    </span>
                </Col>
                <Col key="value-api_user_name" span={valueSpan}>
                    <span className="text">{details.api_user_name}</span>
                </Col>
            </Row>
            <Row gutter={[0, 8]} key="api_password">
                <Col key="label-api_password" span={labelSpan}>
                    <span className="text">
                        <b>{t(`contents.txalert_admin.form.api_password`)}</b>
                    </span>
                </Col>
                <Col key="value-api_password" span={valueSpan}>
                    <span className="text">{details.api_password}</span>
                </Col>
            </Row>
            <Row gutter={[0, 8]} key="default_dispute_action">
                <Col key="label-default_dispute_action" span={labelSpan}>
                    <span className="text">
                        <b>{t(`contents.txalert_admin.form.default_dispute_action`)}</b>
                    </span>
                </Col>
                <Col key="value-default_dispute_action" span={valueSpan}>
                    <span className="text">{details.default_dispute_action}</span>
                </Col>
            </Row>
            <Row gutter={[0, 8]} key="alert_email">
                <Col key="label-alert_email" span={labelSpan}>
                    <span className="text">
                        <b>{t(`contents.txalert_admin.form.alert_email`)}</b>
                    </span>
                </Col>
                <Col key="value-alert_email" span={valueSpan}>
                    <span className="text">{details.alert_email}</span>
                </Col>
            </Row>
            <Row gutter={[0, 8]} key="alert_trigger_email">
                <Col key="label-alert_trigger_email" span={labelSpan}>
                    <span className="text">
                        <b>{t(`contents.txalert_admin.form.alert_trigger_email`)}</b>
                    </span>
                </Col>
                <Col key="value-alert_trigger_email" span={valueSpan}>
                    <span className="text">{details.alert_trigger_email}</span>
                </Col>
            </Row>
            <Row gutter={[0, 8]} key="theme">
                <Col key="label-theme" span={labelSpan}>
                    <span className="text">
                        <b>{t(`contents.txalert_admin.form.theme`)}</b>
                    </span>
                </Col>
                <Col key="value-theme" span={valueSpan}>
                    <span className="text">{details.theme_name}</span>
                </Col>
            </Row>
            <Row gutter={[0, 8]} key="max_refund_amount">
                <Col key="label-max_refund_amount" span={labelSpan}>
                    <span className="text">
                        <b>{t(`contents.txalert_admin.form.max_refund_amount`)}</b>
                    </span>
                </Col>
                <Col key="value-max_refund_amount" span={valueSpan}>
                    <span className="text">{details.max_refund_amount}</span>
                </Col>
            </Row>

            <Row gutter={[0, 8]} key="eligible_for_refund">
                <Col key="label-eligible_for_refund" span={labelSpan}>
                    <span className="text">
                        <b>{t(`contents.txalert_admin.form.eligible_for_refund`)}</b>
                    </span>
                </Col>
                <Col key="value-eligible_for_refund" span={valueSpan}>
                    <span className="text">{details.eligible_for_refund === 1 ? 'Yes' : 'No'}</span>
                </Col>
            </Row>
            <Row gutter={[0, 8]} key="status">
                <Col key="label-status" span={labelSpan}>
                    <span className="text">
                        <b>{t(`contents.txalert_admin.form.status`)}</b>
                    </span>
                </Col>
                <Col key="value-status" span={valueSpan}>
                    <span className="text">{details.status}</span>
                </Col>
            </Row>
            {GroupAccess("SYSADMIN") ?
                <Row>
                    <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 4 }} xl={{ span: 4 }}>
                        <Button type="primary" onClick={() => showModal(merchant_id)} >
                            <EditOutlined /> {t("tables.actions.edit")}
                        </Button>
                        <TxalertMerchant t={t} mid={id} setMid={setId} isOpen={isOpen} setIsOpen={setIsOpen} fetchMerchantList={updateDetails} />

                    </Col>

                </Row> : ''}
        </Card>
    ) : null;




    const showDescriptors = () => details ? (
        <div>
            <TxalertDescriptorTbl merchant_id={details.merchant_id} t={t} />
        </div>

    ) : null;


    const showBincaids = () => details ? (
        <div>
            <TxalertBinCaidTbl merchant_id={details.merchant_id} t={t} />
        </div>

    ) : null;

    const showERLogin = () => details ? (
        <Tabs.TabPane tab={t("contents.txalert_admin.tabs.ergateways")} key='erlogin'>
            <TxalertERGatewayLoginTbl merchant_id={details.merchant_id} t={t} />
        </Tabs.TabPane>

    ) : null;

    return (
        <>
            <PageHeader className="site-page-header" title={t("contents.merchants.merchant")} subTitle={`${details?.name}(${merchant_id})`}></PageHeader>

            <Tabs defaultActiveKey="1" type="card">

                <Tabs.TabPane tab={t("contents.merchants.details")} key='merchant_detail'>
                    {details ? showInfo() : ''}
                </Tabs.TabPane>
                <Tabs.TabPane tab={t("contents.txalert_admin.tabs.descriptors")} key='descriptors'>
                    {merchant_id === undefined ? '' : showDescriptors()}
                </Tabs.TabPane>
                <Tabs.TabPane tab={t("contents.txalert_admin.tabs.bincaids")} key='bincaids'>
                    {merchant_id === undefined ? '' : showBincaids()}
                </Tabs.TabPane>
                if(details & & details.default_dispute_action){
                showERLogin()
                }
            </Tabs>
        </>
    );
};

export default Txalert_MerchantDetail;
