import React, { useState, useEffect } from "react";
import DataFetchHookTxAlert from "../../../helper/DataFetchHookTxAlert";
import { Table, PageHeader, Card, Form, Select, Row, Button, Tooltip, Col, Input} from "antd";
import { SearchOutlined } from "@ant-design/icons";
import CustomEmptyTextProps from "../../../helper/Table/CustomEmptyTextProps";
import moment from "moment";
import { API_TXALERT2 } from "../../../config/config";
import Icon from "@ant-design/icons";
import FormatAmount from "../../../helper/FormatAmount";
//import CustomPaginationV3 from "../../../helper/Table/CustomPaginationV3";
import CustomDateRangeV3 from "../../../helper/Table/CustomDateRangeV3";
// import SortOrder from "../../../helper/Table/SortOrder"; // commented due to unused var
import ColumnResizable from "../../../helper/Table/ColumnResizable";
import TxalertMerchantListDropdown from "../Components/MerchantListDropdown";
import TxalertProviderListDropdown from "../Components/ProviderlistDropdown";
import TxalertAlertTypeDropdown from "../Components/AlertTypeDropdown";
import CustomPaginationV2 from "../../../helper/Table/CustomPaginationV2";
import TxalertChangeAlertStatus from "./ChangeAlertStatus";
import TextSorter from "../../../helper/Table/TextSorter";
import ExportModalV3 from "../../../helper/ExportModalV3";

const { Option } = Select;

const DATA_URL = API_TXALERT2 + "/alert";
const DATA_URL_EXPORT = API_TXALERT2 + "/alert/reports";

const STATUS_ACTION_REQUIRED = "Action Required";
const STATUS_UPDATE = "Update";
const STATUS_WIP = "WIP";

const MAX_DATERANGE = 3;
const DEFAULT_DATE_RANGE = 7;
const DEFAULT_DATE_PERIOD = "days";
const START_DATE = moment().subtract(DEFAULT_DATE_RANGE, DEFAULT_DATE_PERIOD);
const END_DATE = moment();

const Txalert_AlertList = (props) => {
    //eslint-disable-next-line
    const [{ data, totalResult, isLoading, requestData, isUpdated }, doFetch, setRequestData] = DataFetchHookTxAlert(DATA_URL,"GET", localStorage.getItem("txalert_token"), {
        date_from: START_DATE.format('YYYY-MM-DD'),
        date_to: END_DATE.format('YYYY-MM-DD'),
        page: 1,
        size: 20,
        order: "DESC",
        col_order:"alert_id",
        action: ["ACTION_REQUIRED", "WIP"]
    }); // eslint-disable-line
    
    const [dates, setDates] = useState([START_DATE, END_DATE]);
    const [formData, setFormData] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    const { t, theme, socket } = props; //eslint-disable-line

    const [isDateExceeded, setIsDateExceeded] = useState(false);
    const [sortedInfo, setSortedInfo] = useState({}); //eslint-disable-line

    const initFormData = {
        dateRange: [START_DATE, END_DATE],
        action: [],
        outcome: [],
        keyword: "",
        field: null,
        order: "DESC",
        merchant: []
       
    };

    const [form] = Form.useForm();

   
    useEffect(() => {
        formValInit();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const handleChange = (pagination, filters, sorter, { currentDataSource }) => {
        //multiple field sorting will not work.
            setSortedInfo(sorter);
            setRequestData({ ...requestData,  order: sorter.order==='ascend'?'ASC':'DESC', col_order: sorter.field?sorter.field:'alert_id', page: 1, size:pagination.pageSize });
       };
       
   
    const formValInit = () => {
        setFormData({ ...initFormData, action: ["ACTION_REQUIRED", "WIP"] });
        form.setFieldsValue({ ...initFormData, action: ["ACTION_REQUIRED", "WIP"] });
    };


    const clearAll = () => {
        setCurrentPage(1);
        setDates([START_DATE, END_DATE]);
        setFormData(initFormData);
        setRequestData(initFormData);
        form.setFieldsValue(initFormData);
    };

    const formValChange = (chgV, allV) => {
        if (["providername", "alert_type"].includes(chgV?.field)) {
            form.setFieldsValue({ keyword: undefined });
        }
      
        setFormData(allV);
    };

    const onFinish = () => {
       // console.log(dates);
        let startDate = null;
        let endDate = null;
        let searchData = {};
        if (formData) {
            if (dates) {
                startDate = dates[0] ? dates[0] : null;
                endDate = dates[1] ? dates[1] : null;
            }
           
            let searchkeyword = formData.keyword ;
            if (formData.field === "providername") {
                searchkeyword = formData.keyword.label;  //to get the provider name instead of id
            }
            searchkeyword = searchkeyword? searchkeyword.toString().trim() : null //convert to string, if dropdown
            searchData = {
             
                [formData.field]: searchkeyword,
                action: formData.action,
                outcome: formData.outcome,
                order: formData.order,
                merchant_id: formData.merchant,
                date_from:startDate.format('YYYY-MM-DD'),
                date_to:endDate.format('YYYY-MM-DD')
            };
           
        }

        setCurrentPage(1);
        setRequestData(searchData);
    };

    const customLocale = {
        emptyText: CustomEmptyTextProps(
            <>
                {t("tables.emptytext") + " "}
                <span style={{ cursor: "pointer", color: theme }} onClick={clearAll}>
                    {t("tables.actions.clear_filters")}
                </span>
            </>
        ),
    };

    const customLoading = {
        emptyText: CustomEmptyTextProps(
            <>
                <span>{t("tables.loading")}</span>
            </>,
            isLoading
        ),
    };

    const CautionSvg = () => (
        <svg height="1rem" viewBox="0 0 128 128" width="1rem" xmlns="http://www.w3.org/2000/svg">
            <g>
                <path
                    d="m57.362 26.54-37.262 64.535a7.666 7.666 0 0 0 6.639 11.5h74.518a7.666 7.666 0 0 0 6.639-11.5l-37.258-64.535a7.665 7.665 0 0 0 -13.276 0z"
                    fill="#ffb400"
                />
                <g fill="#fcf4d9">
                    <rect height="29.377" rx="4.333" width="9.638" x="59.181" y="46.444" />
                    <circle cx="64" cy="87.428" r="4.819" />
                </g>
            </g>
        </svg>
    );

    const CautionIcon = (props) => <Icon component={CautionSvg} {...props} />;

    const [columns, setColumns] = useState([
        {
            dataIndex: "alert_id",
            key: "alert_id",
            title: t("tables.fields.alertid"),
            fixed: "left",
            width: 95,
            onCell: (record) => ({ className: getRowClass(record) }),
            render: (id, record) => (
                <a href={`/alertdetail/${id}`} target="_blank" rel="noopener noreferrer">
                    {record.er_enabled && record.er_action_text === 'match' ? (
                        <Tooltip placement="topLeft" title={t("contents.alerts.tooltip.potential_transaction_matches")}>
                            {id} <CautionIcon style={{ fontSize: "1rem" }} />
                        </Tooltip>
                    ) : (
                        id
                    )}
                </a>
            ),
            sorter: {
                compare: (a, b) => a.alert_id - b.alert_id
            }
        },
        {
            dataIndex: "txalert_received_alert_date",
            key: "txalert_received_alert_date",
            title: t("tables.fields.alertdate"),
            width: 150,
            render: (alertdate) => moment(alertdate).format("YYYY-MM-DD HH:mm:ss"),
            sorter: {
                compare: (a, b) => moment(a.txalert_received_alert_date).diff(moment(b.txalert_received_alert_date))
                
            }
        },
        {
            dataIndex: "alert_data_descriptor",
            key: "alert_data_descriptor",
            title: t("tables.fields.alert_descriptor"),
            width: 150,
            ellipsis: true,
            sorter: {
                compare: (a, b) => TextSorter(a.alert_data_descriptor,b.alert_data_descriptor)
            }
        },
        {
            dataIndex: "provider_case_id",
            key: "provider_case_id",
            title: t("tables.fields.provider_caseid"),
            width: 130,
            ellipsis: true,
            sorter: {
                compare: (a, b) => TextSorter(a.provider_case_id,b.provider_case_id)
            }
        },
        {
            dataIndex: "transaction_date",
            key: "transaction_date",
            title: t("tables.fields.transaction_date"),
            ellipsis: true,
            width: 150,
            render: (transaction_date) => moment(transaction_date).format("YYYY-MM-DD HH:mm:ss"),
            sorter: {
                compare: (a, b) => moment(a.transaction_date).diff(moment(b.transaction_date))
            }
        },
        {
            dataIndex: "transaction_amount",
            key: "transaction_amount",
            title: t("tables.fields.amount"),
            align: "left",
            width: 80,
            ellipsis: true,
            render: (transaction_amount, record) => FormatAmount(transaction_amount, record.transaction_currency),
            sorter: {
                compare: (a, b) => a.transaction_amount - b.transaction_amount
            }
        },
        {
            dataIndex: "card_number",
            key: "card_number",
            title: t("tables.fields.card_no"),
            ellipsis: true,
            width: 120,
            sorter: {
                compare: (a, b) => TextSorter(a.card_number,b.card_number)
            }
        },
        {
            dataIndex: "alert_type",
            key: "alert_type",
            title: t("tables.fields.alert_type"),
            ellipsis: true,
            width: 130,
            sorter: {
                compare: (a, b) => TextSorter(a.alert_type,b.alert_type)
            }
        },
        {
            dataIndex: "outcome",
            key: "outcome",
            title: t("tables.fields.outcome"),
            ellipsis: true,
            align: "left",
            fixed: "right",
            width: 120,
            render: (outcome, record) => (outcome ? getOutcomeRender(outcome) : null),
            sorter: {
                compare: (a, b) => TextSorter(a.outcome,b.outcome)
            }
        },
        {
            dataIndex: "expirydatetime",
            key: "expirydatetime",
            title: t("tables.fields.expiry_date"),
            align: "left",
            fixed: "right",
            width: 120,
            render: (expirydatetime, record) => (
                record.alert_type === "dispute rdr" ? <span>N/A</span> :
                    <span>
                        {expirydatetime}
                    </span>
            ),
            sorter: {
                compare: (a, b) => moment(a.expirydatetime).diff(moment(b.expirydatetime))
            }
        },
    ]);

    const getStatusColor = (status) => {
        switch (status.toLowerCase()) {
            case "resolved":
                return "#52c41a";
            case "processing":
                return "#2f54eb";
            case "other":
                return "#fa8c16";
            case "declined":
                return "#f5222d";
            case "error":
                return "#f5222d";
            default:
                return "default";
        }
    };

    const getOutcomeRender = (outcome) => {
        try {
            if (outcome === "wip") {
                return <span>WIP</span>;
            }
            if (outcome[0].toUpperCase() !== outcome[0].toLowerCase()) {
                return (
                    <span style={{ color: getStatusColor(outcome) }} className="text-capital">
                        {t(`outcomes.${outcome.toLowerCase()}`)}
                    </span>
                );
            } else {
                return <span>{outcome}</span>;
            }
        } catch (error) {
            return <span>{outcome}</span>;
        }
    };

    const getAction = (outcome, is_duplicate, record) => {
        
        if (outcome && outcome.toLowerCase() === "resolved") {
            return null;
        }
        if (is_duplicate && is_duplicate.toLowerCase() !== "no" && outcome === "") {
            return <Button size="small" onClick={() => window.open(`/alert/${is_duplicate}`, "_blank")}>{`${t("outcomes.duplicate")} ${is_duplicate}`}</Button>;
        }
        
       
        return (
            <TxalertChangeAlertStatus
                t={t}
                outcome={outcome}
                doFetch={() => doFetch(DATA_URL + `?` + moment().unix())}
                row={record}
            />
        );
    };

    const getLocale = () => {
        if (isLoading) {
            return customLoading;
        } else if (data.datalist) {
            return customLocale;
        } else {
            return {};
        }
    };

 
    useEffect(() => {
        if (!isLoading) {
       
            let temp = columns;
                    let act = [{
                        dataIndex: "action",
                        key: "action",
                        title: t("tables.fields.action"),
                        align: "left",
                        fixed: "right",
                        width: 120,
                        render: (text, record) => getAction(record.outcome, record.is_duplicate, record),
                    },];
                    temp = [...temp, ...act];
                    setColumns(temp);
    
            
        }
    }, []); // eslint-disable-line



    const AdvancedSearchForm = () => (
        <div className="search-wrap">
            <Card title={t("tables.actions.search")} size="small">
                <Form form={form} size="large" name="advanced_search" className="ant-advanced-search-form" onValuesChange={formValChange} onFinish={onFinish}>
                    <Row gutter={[16, 16]}>
                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                            <Form.Item name="dateRange"
                                rules={[
                                    {
                                        validator: () => {
                                            if(isDateExceeded){
                                                return Promise.reject(t('max_3_months'));
                                            }else if((dates[0] && !dates[1]) || (!dates[0] && dates[1])){
                                                return Promise.reject(t('min_1_day'));
                                            }else{
                                                return Promise.resolve();
                                            }
                                        }
                                    }
                                ]}
                            >
                                <CustomDateRangeV3 t={t} setIsDateExceeded={setIsDateExceeded} state={{ dates, setDates }} unit="months" limit={MAX_DATERANGE} />
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                            <Form.Item name="action">
                                <Select mode="multiple" showArrow="true" maxTagCount="responsive" allowClear style={{ width: "100%" }} placeholder="Action">
                                    <Option value="ACTION_REQUIRED">{STATUS_ACTION_REQUIRED}</Option>
                                    <Option value="UPDATE">{STATUS_UPDATE}</Option>
                                    <Option value="WIP">{STATUS_WIP}</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                            <Form.Item name="outcome">
                                <Select mode="multiple" showArrow="true" maxTagCount="responsive" allowClear style={{ width: "100%" }} placeholder={t("tables.fields.outcome")}>
                                    <Option value="wip">WIP</Option>
                                    <Option value="resolved">Resolved</Option>
                                    <Option value="declined">Declined</Option>
                                    <Option value="error">Error</Option>
                                    <Option value="other">Other</Option>
                                    <Option value="matched">Matched</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                        </Col>
                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                            <Form.Item name="merchant">
                                <TxalertMerchantListDropdown mode="multiple" placeholder="Merchants" showInactiveOption={true}/>
                            </Form.Item>
                        </Col>
                       
                     
                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                            <Form.Item name="field">
                                <Select
                                    showSearch
                                    optionFilterProp="children"
                                    style={{ width: "100%" }}
                                    placeholder={t("tables.fields.field")}>
                                    <Option value="alert_id">{t("contents.alerts.alert_id")}</Option>
                                    <Option value="descriptor">{t("tables.fields.alert_descriptor")}</Option>
                                    <Option value="transaction_amount">{t("tables.fields.amount")}</Option>
                                    <Option value="card_number">{t("tables.fields.card_no")}</Option>
                                    <Option value="alert_type">{t("tables.fields.alert_type")}</Option>
                                    <Option value="arn">{t("tables.fields.arn")}</Option>
                                    <Option value="providername">{t("tables.fields.providername")}</Option>
                                    <Option value="provider_caseid">{t("tables.fields.provider_caseid")}</Option>
                                    <Option value="reasoncode">{t("tables.fields.reasoncode")}</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                            {form.getFieldValue("field") === "providername" ? (
                                <Form.Item name="keyword">
                                    <TxalertProviderListDropdown placeholder={t("tables.fields.keyword")} labelInValue={true}/>
                                </Form.Item>
                            ) : form.getFieldValue("field") === "alert_type" ? (
                                <Form.Item name="keyword">
                                  <TxalertAlertTypeDropdown placeholder={t("tables.fields.keyword")} />
                                </Form.Item>
                            ) : (
                                <Form.Item name="keyword">
                                    <Input placeholder={t("tables.fields.keyword")} />
                                </Form.Item>
                            )}
                        </Col>
                        <Col
                            className="gutter-row"
                            xs={{ span: 24 }}
                            sm={{ span: 12 }}
                            md={{ span: 6, offset: 12 }}
                            lg={{ span: 6, offset: 12 }}
                            xl={{ span: 3, offset: 17 }}
                        >
                            <Form.Item>
                                <Button type="secondary" onClick={() => clearAll()} block>
                                    {t("tables.actions.clear_all")}
                                </Button>
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 4 }}>
                            <Form.Item>
                                <Button type="primary" htmlType="submit" block>
                                    <SearchOutlined /> {t("tables.actions.search")}
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Card>
        </div>
    );

    const getDataSource = (datalist) => (datalist && Array.isArray(datalist) ? datalist : []);

    const getHeaderTable = () => (
        <div className="custom-table--header d-flex justify-content-between align-items-center">
            <h3 className="table-title">{t("contents.alerts.title")}</h3>
            <ExportModalV3
                t={t}
                keyname="export_all"
                title={t("tables.actions.export")}
                export_option={true}
                data={requestData}
                data_url={DATA_URL_EXPORT}
                headers={exportHeaders}
                fieldHeadersOptional={exportHeadersOptional}
                txalert_token = {true}
                filename={`alert_${moment().format("YYMMDD_HHmmss_SSSS")}.csv`}
                style={{ marginLeft: 8 }}
            />
        </div> 
    );

    const exportHeadersOptional = [
        "provider_name",
        "provider_case_id",
        "arn",
        "authcode",
        "t.alert_type",
        "partner_id",
        "m.name",
        "issuername",
        "source",
        "dv.descriptor",
        "outcome_updated_on",
        "merchant_updated_on",
        "belong_to_merchant",
        "chargeback_proceeded",
        "is_duplicate",
        // "u.username",
        "a.reason_code",
        "ar.description",
        // "mcct.tier",
        "a.dispute_condition_code"
    ];
    const exportHeaders = [
        { label: "ID", key: "a.alert_id" },
        { label: "Alert Date", key: "txalert_received_alert_date" },
        { label: "Descriptor", key: "alert_data_descriptor" },
        { label: "Transaction Date", key: "transaction_date" },
        { label: "Currency", key: "transaction_currency" },
        { label: "Amount", key: "transaction_amount" },
        { label: "Card No.", key: "card_number" },
        { label: "Outcome", key: "a.outcome" },
        { label: "Provider", key: "provider_name" },
        { label: "Provider Reference", key: "provider_case_id" },
        { label: "ARN", key: "arn" },
        { label: "Auth Code", key: "authcode" },
        { label: "Alert Type", key: "t.alert_type" },
        { label: "Partner ID", key: "partner_id" },
        { label: "Merchant", key: "m.name" },
        { label: "Issuername", key: "issuername" },
        { label: "Source", key: "source" },
        { label: "Matched Descriptor", key: "dv.descriptor" },
        { label: "Outcome Update On", key: "outcome_updated_on" },
        { label: "Merchant Update On", key: "merchant_updated_on" },
        { label: "Belongs to Merchant", key: "belong_to_merchant" },
        { label: "Chargeback Occurred", key: "chargeback_proceeded" },
        { label: "Duplicate", key: "is_duplicate" },
        // { label: "Updated By", key: "u.username" },
        { label: "Reason Code", key: "a.reason_code" },
        { label: "Alert reason", key: "ar.description" },
        // { label: "Tier", key: "mcct.tier" },
        { label: "Condition Code", key: "a.dispute_condition_code" }
    ];

    const getRowClass = (record) => {
        const diff = moment().diff(moment(record.expirydatetime), "hours");

        if (diff > 24 && (record.outcome === null || record.outcome === "" || record.outcome.toLowerCase() === "matched" || record.outcome.toLowerCase() === "wip")) {
            return "expiringalert";
        }
    };

    const mergeColumns = columns.map((col, index) => ({
        ...col,
        onHeaderCell: (column) => ({
            width: column.width,
            onResize: (_, { size }) => {
                const newColumns = [...columns];
                newColumns[index] = { ...newColumns[index], width: size.width };
                setColumns(newColumns);
            },
        }),
    }));

    return (
        <>
            <PageHeader title={t("contents.alerts.title")}></PageHeader>
            {AdvancedSearchForm()}
            <Table
                size="small"
                locale={getLocale()}
                loading={isLoading}
                title={() => getHeaderTable()}
                rowKey="alert_id"
                className="table-v3 custom-pagination custom-pagination-v3"
                components={{
                    header: {
                        cell: ColumnResizable,
                    },
                }}
                columns={mergeColumns}
                scroll={{ x: 1500 }}
                dataSource={getDataSource(data.datalist)}
                onChange={handleChange}
                sortDirections={["ascend", "descend", "ascend"]}
                rowClassName={(record) => getRowClass(record)}
            /*    pagination={CustomPaginationV3(
                    { totalResult, currentPage, requestData, setRequestData, setCurrentPage, latestTotal, setLatestTotal },
                    isUpdated
                )}*/
                pagination={CustomPaginationV2({ totalResult, currentPage, requestData, setRequestData, setCurrentPage })}
            />
        </>
    );
};

export default Txalert_AlertList;