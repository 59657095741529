import { useEffect } from "react";
import UserSettings from "../UserSettings";
import Multilingual from "../Multilingual";

import { useLocation } from "react-router-dom";

const CustomPaginationV3 = ({ totalResult, currentPage, requestData, setRequestData, setCurrentPage, latestTotal, setLatestTotal }, updated) => {
    const [t] = Multilingual();
    const location = useLocation();
    const { pathname } = location;
    const keys = pathname.substring(1).split("/");
    const pageName = keys[0];
    const [updateSettings] = UserSettings();
    let initDefaultPageSize = 20;
    let loggedUserSettings = localStorage.getItem("loggedUserSettings") ? JSON.parse(localStorage.getItem("loggedUserSettings")) : null;
    let loggedPageSize = 0;
    if (loggedUserSettings) {
        if (loggedUserSettings.defaultTablePageSize) {
            if (loggedUserSettings.defaultTablePageSize[pageName]) {
                initDefaultPageSize = loggedUserSettings.defaultTablePageSize[pageName];
                loggedPageSize = loggedUserSettings.defaultTablePageSize[pageName];
            }
        }
    }

    useEffect(() => {
        if (totalResult) {
            setLatestTotal(getTotal());
        }
    }, [updated]); // eslint-disable-line react-hooks/exhaustive-deps

    const onPaginationChange = (page, pageSize) => {
        let isExist = true;
        if (!loggedUserSettings) {
            loggedUserSettings = {};

            if (!loggedUserSettings.defaultTablePageSize) {
                loggedUserSettings.defaultTablePageSize = {};

                if (loggedUserSettings.defaultTablePageSize[pageName]) {
                    loggedUserSettings.defaultTablePageSize[pageName] = pageSize;
                }
            }
        }

        if (isExist) {
            loggedUserSettings.defaultTablePageSize = { ...loggedUserSettings.defaultTablePageSize, [pageName]: pageSize };
        }

        if(loggedPageSize !== pageSize){
            updateSettings(loggedUserSettings);
        }
        localStorage.setItem("loggedUserSettings", JSON.stringify(loggedUserSettings));


        setCurrentPage(page);
        setRequestData({ ...requestData, page: page, size: pageSize });
    };

    const getTotal = () => {
        let total = totalResult;

        if (total) {
            if (requestData.size * 10 > total) {
                return total + (requestData.page - 1) * requestData.size;
            }
            if (requestData.page * requestData.size >= total) {
                return total + requestData.size * (requestData.page - total / requestData.size + 1);
            }
        }

        return total;
    };

    const getShowTotal = (total, range) => {
        let totalText = total;
        if (total) {
            if (requestData.size * 10 > total) {
                totalText = `${total}`;
            } else if (requestData.size * 10 === total) {
                totalText = `${total}+`;
            } else if (range[1] <= requestData.size * 10) {
                totalText = `${requestData.size * 10}+`;
            } else if (range[1] > requestData.size * 10) {
                totalText = `${total - parseInt(total % (requestData.size * 10))}+`;
            }
        }
        return t('tables.show_total', { from: range[0], to: range[1], total: totalText })
        // return `${range[0]}-${range[1]} of ${totalText} items`;
    };

    return totalResult ? {
        current: currentPage,
        total: latestTotal,
        position: ["topRight", "bottomRight"],
        defaultPageSize: initDefaultPageSize,
        showSizeChanger: true,
        pageSizeOptions: ["10", "20", "50", "100"],
        showTotal: (total, range) => getShowTotal(total, range),
        onChange: (page, pageSize) => onPaginationChange(page, pageSize),
    } : false;
};

export default CustomPaginationV3;
