import React, { useState } from "react";
import { Table} from "antd";
import CustomPaginationV2 from "../../../helper/Table/CustomPaginationV2";
import ColumnResizable from "../../../helper/Table/ColumnResizable";
import MergeColumns from "../../../helper/Table/MergeColumns";
import DataFetchHookTxAlert from "../../../helper/DataFetchHookTxAlert";
import { API_TXALERT2 } from "../../../config/config";
import TextSorter from "../../../helper/Table/TextSorter";
import StatusText from "../../../helper/Table/StatusText";
const Txalert_ERGatewayLoginTbl = (props) => {
    let apiurl = API_TXALERT2;
    if(props.merchant_id){
        apiurl = apiurl + '/express_resolve_gateway/login?merchant_id='+props.merchant_id;
    }
    

    const [{ data, totalResult, isLoading, isError, errorMsg, requestData, isUpdated }, setUrl, setRequestData] = DataFetchHookTxAlert(apiurl,"GET", localStorage.getItem("txalert_token")); // eslint-disable-line
    const [currentPage, setCurrentPage] = useState(1);
    const { t } = props;


    const columns = [
        {
            dataIndex: "gateway_name",
            key: "gateway_name",
            align: "left",
            title: t("tables.fields.gateway_name"),
            width: 150,         
            sorter: {
                compare: (a, b) => TextSorter(a.gateway_name,b.gateway_name)
            },
            render: (text, record, index) => (
                text +'('+ record.username+')'
            )
        },
        {
            dataIndex: "api_url",
            key: "api_url",
            align: "left",
            title: t("tables.fields.apiurl"),
            width: 150,           
            sorter: {
                compare: (a, b) => TextSorter(a.api_url,b.api_url)
            }
        },        
        {
            dataIndex: "currency",
            key: "currency",
            align: "left",
            title: t("tables.fields.currency"),
            width: 150,           
            sorter: {
                compare: (a, b) => TextSorter(a.currency,b.currency)
            }
        },
        {
            dataIndex: "active",
            key: "active",
            title: t("tables.fields.active"),
            width: 50,
            render: (active) => StatusText(active === 1 ? "active" : "inactive"),
            sorter: {
                compare: (a, b) => a.active - b.active
            }
        }     
    ];

    const getDataSource = (datalist) => (datalist && Array.isArray(datalist) ? datalist : []);
    const getHeaderTable = () => (
        <div className="custom-table--header d-flex justify-content-between align-items-center">
            <h3 className="table-title">{t("contents.descriptors.tabs.enrollment")}</h3>
        </div>
    );
   
    return (
        <div>            
            <Table
                size="small"
                loading={isLoading}
                components={{
                    header: {
                        cell: ColumnResizable,
                    },
                }}
                title={() => getHeaderTable()}
                columns={MergeColumns(columns)}
                rowKey="express_resolve_gateway_login_id"
                className="table-v3 custom-pagination custom-pagination-v3"
                dataSource={getDataSource(data.datalist)}
                pagination={CustomPaginationV2({ totalResult, currentPage, requestData, setRequestData, setCurrentPage })}

            />
        </div>
    );
};


export default Txalert_ERGatewayLoginTbl;
