import React, { useEffect } from "react";
import { Card, Row, Col, Form, Input} from "antd";
import FormatAmount from "../../../helper/FormatAmount";


const PaymentTab = (props) => {
    const { t, siteDetails, isLoading } = props; // eslint-disable-line
    const [form] = Form.useForm();
    const initFormData = {
        stop_send_email: siteDetails.payment.details.stop_send_email,
      //  cbreportemail: siteDetails.payment.details.cbreportemail, //not used in the system. 
        ftid: siteDetails.payment.details.ftid,
        status: siteDetails.payment.details.status,
        skip_ccv: siteDetails.payment.details.skip_ccv === 0 ? 'No' : 'Yes',
        bin_currency: siteDetails.payment.details.bin_currency,
        currency: siteDetails.payment.details.currency,
        allow_rebilling: siteDetails.payment.details.allow_rebilling === 0 ? 'No' : 'Yes',
        allow_refund: "" + siteDetails.payment.details.allow_refund + "",
        allow_refund_time: siteDetails.payment.details.allow_refund_time,
        proceedeveniffail: siteDetails.payment.details.proceedeveniffail === 0 ? 'No' : 'Yes',
        margin_percentage: siteDetails.payment.details.margin_percentage,
        first_transaction_alert: siteDetails.payment.details.first_transaction_alert === 0 ? 'No' : 'Yes',
        notify_email: siteDetails.payment.details.notify_email,
        shippingntracking: siteDetails.payment.details.shippingntracking === 0 ? 'No' : 'Yes',
        category: siteDetails.category,
        fraudtemplate: siteDetails.payment.fraudtemplate,
        alert_on_next_approved_tranaction: siteDetails.payment.details.alert_on_next_approved_tranaction === 0 ? 'No' : 'Yes',
    };

    const itemLayout = {
        labelAlign: "left",
        labelCol: { span: 10, offset: 0 },
    };


    useEffect(() => {  
        formValInit();

 }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const formValInit = () => {
        form.setFieldsValue(initFormData);
    };



    const getPaymentDetails = () => (
        <Card className="custom-form--card" loading={isLoading}>
            <h3>{t("contents.merchants.settings")}</h3>
            <hr />
            <Form form={form} {...itemLayout} name="payment">
                <Row gutter={[36, 16]}>
                    <Col span={12}>
                        <Form.Item name="" label={t("contents.merchants.form.stop_send_email")}>
                            <span className="ant-form-text">
                                {siteDetails.payment.details.stop_send_email === 0 ? 'No' : 'Yes'}
                            </span>
                        </Form.Item>                      
                        <Form.Item label={t("contents.merchants.form.available_balance_for_withdraw")} className="fixed-label">
                            <span className="ant-form-text">
                                {FormatAmount(siteDetails.payment.balance, siteDetails.payment.details.currency)} {siteDetails.payment.details.currency}
                            </span>
                        </Form.Item>
                        <Form.Item name="status" label={t("contents.merchants.form.status")}>
                            <Input readOnly bordered={false} />
                        </Form.Item>
                        <Form.Item name="category" label={t("contents.merchants.form.category")}>
                            <Input readOnly bordered={false} />
                        </Form.Item>
                        <Form.Item name="fraudtemplate" label={t("contents.merchants.form.fraud_template")}>
                            <Input readOnly bordered={false} />
                        </Form.Item>
                        <Form.Item name="skip_ccv" label={t("contents.merchants.form.skip_ccv")}>
                            <Input readOnly bordered={false} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name="allow_rebilling" label={t("contents.merchants.form.allow_rebilling")}>
                            <Input readOnly bordered={false} />
                        </Form.Item>
                        <Form.Item name="proceedeveniffail" label={t("contents.merchants.form.proceedeveniffail")}>
                            <Input readOnly bordered={false} />
                        </Form.Item>
                        <Form.Item name="margin_percentage" label={t("contents.merchants.form.margin_percentage")} className="fixed-label">
                            <Input readOnly bordered={false} />
                        </Form.Item>
                        <Form.Item
                            name="alert_on_next_approved_tranaction"
                            label={t("contents.merchants.form.alert_on_next_approved_tranaction")}
                            className="fixed-label"
                        >
                            <Input readOnly bordered={false} />
                        </Form.Item>
                        <Form.Item name="to_address" label={t("contents.merchants.form.to_address")}>
                            <Input readOnly bordered={false} />
                        </Form.Item>
                        <Form.Item name="allow_refund" label={t("contents.merchants.form.allow_refund")}>
                            <Input readOnly bordered={false} />
                        </Form.Item>
                        <Form.Item name="allow_refund_time" label={t("contents.merchants.form.allow_refund_time")}>
                            <Input readOnly bordered={false} />
                        </Form.Item>

                        <Form.Item name="shippingntracking" label={t("contents.merchants.form.shippingntracking")} className="fixed-label">
                            <Input readOnly bordered={false} />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
           
        </Card>
    );

    return getPaymentDetails();
};

export default PaymentTab;
