import React, { useState, useEffect } from "react";
import { Form, Input, Button,Modal} from "antd";
import { API_TXALERT2 } from "../../../config/config";
import axios from "axios";
import Swal from "sweetalert2";
import GroupAccess from "../../../helper/GroupAccess";
import CatchError from "../../../helper/CatchError";

const Txalert_MCCTier = (props) => {
    const { t, id,setId,isOpen,setIsOpen, fetchList } = props;
    const [details, setDetails] = useState(null);
    const [loading, setLoading] = useState(false);
    const [componentDisabled, setComponentDisabled] = useState(GroupAccess("SYSADMIN") ? false : true); //eslint-disable-line
    
   
    const layout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };

    const initData = {
        mcc: "",
        mcc_description: "",
        tier: ""
    };

    const handleOk = () => {
        setLoading(true);
        onFinish();
    };

   

    const [form] = Form.useForm();

    const handleCancel = () => {             
        setLoading(false);
        setId('');
        form.setFieldsValue(initData);
        setIsOpen(false);
        
    };

    const formValChange = (chgV, allV) => {
       
               setDetails({ ...allV});
       
    };

    useEffect(() => {
     
        if(isOpen){
            if (typeof id != 'undefined') {
            
                getDetails();
            }else{
                setDetails(initData);
            }
        }
    }, [isOpen]); // eslint-disable-line

   
    const getDetails = async () => {

        const api_url = API_TXALERT2 + `/mcc_tier/${id}`;
        await axios
            .get(api_url, { headers: { Authorization: localStorage.getItem("txalert_token") } })
            .then(({ data }) => {
                let recdata = data;

                setDetails(recdata);
                form.setFieldsValue({ ...recdata});
            })
            .catch((error) => console.log(error));
    };

    const onFinish = () => {
        let apiUrl = API_TXALERT2 ;
   
       if(id === 'undefined' || typeof id === 'undefined' || id === ''){
        apiUrl = apiUrl + "/mcc_tier";
       }else{
        apiUrl = apiUrl + "/mcc_tier/"+id;
       }
         axios
             .post(apiUrl, details,{ headers: { Authorization: "Bearer " + localStorage.getItem("txalert_token") }})
             .then(({ data }) => {
                 if (data.status === "OK") {
                     Swal.fire({
                         icon: "success",
                         title: data.msg,
                         showConfirmButton: false,
                         timer: 2000,
                         onClose: () => {
                             fetchList();
                         },
                     });
                     handleCancel();
                 } else {
                     Swal.fire({
                         icon: "error",
                         title: t("login.dialogs.catch"),
                         text: data.error_msg ? data.error_msg.msg : "",
                         showConfirmButton: true,
                     });
                     CatchError(data);
                 }
             })
             .catch((error) => {
                console.log(error.response);
                 setLoading(false);
                 CatchError(error);
             });
     };
    const isOkButtonProps = () => !details || !details.mcc || !details.tier || loading;
    return(
        <>
            <Modal
                title={id !== undefined?t("contents.txalert_admin.form.view_mcc")+ ' id:'+id:t("contents.txalert_admin.form.add_mcc")}
                visible={isOpen}
                width={600}
                onCancel={handleCancel}
                onOk={handleOk}
                bodyStyle={{ paddingTop: '0px' }}
                footer={[
                    <Button key="back" onClick={() => handleCancel()}>
                        {t("contents.txalert_admin.form.cancel")}
                    </Button>,
                     GroupAccess("SYSADMIN") ? 
                    <Button key="submit" type="primary" loading={loading} disabled={isOkButtonProps()} onClick={() => handleOk()}>
                        {loading ? t("contents.txalert_admin.form.submitting") : t("contents.txalert_admin.form.submit")}
                    </Button>:'',
                ]}
            >
                <Form {...layout} form={form} name="view_mcc" onValuesChange={formValChange}  >
                    <fieldset disabled={componentDisabled}>
                        <Form.Item name="mcc" label={t("contents.txalert_admin.form.mcc")} rules={[{ required: true }]} style={{ marginBottom: ".5rem" }} >
                            <Input />
                        </Form.Item>

                        <Form.Item name="mcc_description" label={t("contents.txalert_admin.form.mcc_description")} style={{ marginBottom: ".5rem" }} >
                            <Input />
                        </Form.Item>

                        <Form.Item name="tier" label={t("contents.txalert_admin.form.tier")} rules={[{ required: true }]} style={{ marginBottom: ".5rem" }} >
                            <Input />
                        </Form.Item>
                        
                      
                    </fieldset>
                </Form>
            </Modal>
 
        </>
    );
};

export default Txalert_MCCTier;
