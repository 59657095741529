import React, { useState,useEffect } from "react";
import { Table,PageHeader } from "antd";
import CustomPaginationV2 from "../../../helper/Table/CustomPaginationV2";
import ColumnResizable from "../../../helper/Table/ColumnResizable";
import DataFetchHookTxAlert from "../../../helper/DataFetchHookTxAlert";
import { API_TXALERT2 } from "../../../config/config";
import axios from "axios";
import TextSorter from "../../../helper/Table/TextSorter";

const PROVIDER_DATA_URL = API_TXALERT2 + "/alert_provider"

//can be used for merchant descriptors and provider descriptors

const Txalert_BinCaidTbl = (props) => {
    let apiurl =  API_TXALERT2 + "/bincaid" ;
    if(props.merchant_id){
        apiurl = apiurl + '?merchant_id='+props.merchant_id;
    }
    if(props.alert_provider_id)
    {
        apiurl = apiurl + '?alert_provider_id='+props.alert_provider_id;
    }
    const [{ data, totalResult, isLoading, isError, errorMsg, requestData, isUpdated }, setUrl, setRequestData] = DataFetchHookTxAlert(apiurl,"GET", localStorage.getItem("txalert_token")); // eslint-disable-line
    const [currentPage, setCurrentPage] = useState(1);
    const { t } = props;    
    const [providerList, setProviderList] = useState(false);
  
    
    useEffect(() => {
        //if showing for specific alert provider, only show that provider column 
        if (!providerList) {
          axios
              .get(
                  PROVIDER_DATA_URL+'?rdr_alerts=1',
                  { headers: { Authorization: "Bearer " + localStorage.getItem("txalert_token") } }
              )
              .then( ({ data }) => {
                   setProviderList(data.list);
                  const fData = data.list.map((d) => {
                      return {
                          dataIndex: d.provider_name +'_status',
                          key: d.provider_name,
                          title: d.provider_name,
                          ellipsis: true,
                          width: 100,
                          sorter: {
                            compare: (a, b) => TextSorter(a[d.provider_name +'_status'],b[d.provider_name +'_status'])
                        }
                         
                      }
                  });
               
                     
                      const temp = [...columns, ...fData];
                  
                  setColumns(temp);
              })
              .catch(() => false);
      }
  }, []); // eslint-disable-line
  const [columns, setColumns] = useState([
    {
        dataIndex: "bincaid_id",
        key: "bincaid_id",
        title: t("tables.fields.id"),
        width: 50,
        render: (id, record) => (
            <a href={`/bincaid/${id}`} target="_blank" rel="noopener noreferrer">
                {id}{" "}
            </a>
          
        ),
        sorter: {
            compare: (a, b) => TextSorter(a.bincaid_id, b.bincaid_id),
        },
    },
    {
        dataIndex: "bin",
        key: "bin",
        title: t("tables.fields.bin"),
        width: 100,
        sorter: {
            compare: (a, b) => TextSorter(a.bin,b.bin)
        },
    },
    {
        dataIndex: "caid",
        key: "caid",
        title: t("tables.fields.caid"),
        width: 100,
        sorter: {
            compare: (a, b) => TextSorter(a.caid,b.caid)
        },
    },
    {
        dataIndex: "mcc",
        key: "mcc",
        title: t("tables.fields.mcc"),
        width: 80,
        sorter: {
            compare: (a, b) => TextSorter(a.mcc,b.mcc)  // a.mcc.localeCompare(b.mcc),          
        },
    },
    {
        dataIndex: "merchant_name",
        key: "merchant_name",
        title: t("tables.fields.merchant_name"),
        align: "left",
        width: 150, 
        sorter: {
            compare: (a, b) => TextSorter(a.merchant_name,b.merchant_name),
        },
    },
    {
        dataIndex: "status",
        key: "status",
        align: "left",
        title: t("tables.fields.status"),
        width: 100,        
        render: (text, record) => (
            record.status === 1?'Active':'InActive'
        )    ,
        sorter: {
            compare: (a, b) => a.status - b.status,
        },
    }
     
  ].filter(item => !item.hidden));

  const getDataSource = (datalist) => (datalist && Array.isArray(datalist) ? datalist : []);

  const getHeaderTable = () => (
      <div className="custom-table--header d-flex justify-content-between align-items-center">
          <h3 className="table-title">{t("contents.bincaids.title")}</h3>
          
      </div>
  );

  const mergeColumns = columns.map((col, index) => ({
      ...col,
      onHeaderCell: (column) => ({
          width: column.width,
          onResize: (_, { size }) => {
              const newColumns = [...columns];
              newColumns[index] = { ...newColumns[index], width: size.width };
              setColumns(newColumns);
          },
      }),
  }));

  const handleChange = (pagination, filters, sorter, { currentDataSource }) => {
    //multiple field sorting will not work.
      
       setRequestData({ ...requestData,  order: sorter.order==='ascend'?'ASC':'DESC', col_order: sorter.field?sorter.field:'descriptor_id', page: 1 });
   };
   
  return (
      <div>
          <PageHeader title={t("contents.bincaids.title")}></PageHeader>
          <Table
              rowKey="bincaid_id"
              size="small"
              title={() => getHeaderTable()}
              loading={isLoading}
              className="table-v3 custom-pagination"
              components={{
                  header: {
                      cell: ColumnResizable,
                  },
              }}
              columns={mergeColumns}
              dataSource={data.datalist && Array.isArray(data.datalist) ? getDataSource(data.datalist) : []}
              onChange={handleChange}
              pagination={CustomPaginationV2({ totalResult, currentPage, requestData, setRequestData, setCurrentPage })}
              scroll={{ x: 1500 }}
          />
    </div>
  );
};


export default Txalert_BinCaidTbl;
