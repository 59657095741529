import React, { useState, useEffect } from "react";
import { Modal, Button } from "antd";
import moment from "moment";

const SessionExpirationModal = (props) => {
    const { t, isModalVisible, handleCancel, updateSession } = props;

    const [countdown, setConutdown] = useState(60);
    const [countdownInterval, setCountdownInterval] = useState(null);

    useEffect(() => {
        if (isModalVisible) {
            doCountDown();
        }
    }, [isModalVisible]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleOk = () => {
        clearInterval(countdownInterval);
        updateSession();
        setConutdown(60);
    };

    const checkSessionUpdated = () => {
    if(localStorage.loggedUser){
        const { expiry } = JSON.parse(localStorage.loggedUser);
        if (moment(expiry).diff(moment()) > 0) {
            handleOk();
        }
    }
    };

    const doCountDown = () => {
        let currentNumber = countdown;
        setCountdownInterval(
            setInterval(() => {
                currentNumber = currentNumber - 1;
                setConutdown(currentNumber);
                checkSessionUpdated();
                if (currentNumber === 0) {
                    console.log("Timeout");
                    localStorage.setItem("sessionExpired", true);
                    clearInterval(countdownInterval);
                    handleCancel();
                }
            }, 1000)
        );
    };

    return (
        <Modal
            title={t("login.session.title")}
            visible={isModalVisible}
            width={480}
            closable={false}
            maskClosable={false}
            onOk={handleOk}
            footer={[
                <Button key="submit" type="primary" onClick={() => handleOk()}>
                    {t("tables.actions.ok")}
                </Button>,
            ]}
        >
            {t("login.session.desc_1")} <strong>{countdown}</strong> {t("login.session.desc_2")} {t("login.session.desc_3")}
        </Modal>
    );
};

export default SessionExpirationModal;
