const MenuAccess = (menuItems) => {
    if(localStorage.loggedUser) {
        const usermenuitems = JSON.parse(localStorage.getItem("loggedUser")).menu_items;
        if (usermenuitems == null) {
            // null or undefined
            return false;
        }
        if (!usermenuitems.hasOwnProperty(menuItems)) {
            return false;
        }
        return usermenuitems[menuItems];
    } else {
        return false;
    }
};
export default MenuAccess;
