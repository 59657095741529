//TxAlert
import React, { useState, useEffect } from "react";
import { Table, PageHeader, Card, Form, Select, Row, Col, Button, Space, Input } from "antd";
import { SearchOutlined, FileSearchOutlined ,PlusOutlined,EditOutlined} from "@ant-design/icons";
import moment from "moment";
import CustomPaginationV2 from "../../../helper/Table/CustomPaginationV2";
import { API_TXALERT2 } from "../../../config/config";
import ColumnResizable from "../../../helper/Table/ColumnResizable";
import DataFetchHookTxAlert from "../../../helper/DataFetchHookTxAlert";
import TxalertBinCaidModal from "./BinCaid";
import TxalertMerchantListComp from "../Components/MerchantListDropdown";
import TxalertEnrollmentStatusListComp from "../Components/EnrollmentStatusDropdown";
import axios from "axios";
import TxalertUpdateEnrollmentModal from "../Admin/UpdateEnrollment";
import TextSorter from "../../../helper/Table/TextSorter";
import ExportModalV3 from "../../../helper/ExportModalV3";
import TxalertBinCaidImport from "./BinCaidImport";


const BINCAID_DATA_URL = API_TXALERT2 + "/bincaid";
const PROVIDER_DATA_URL = API_TXALERT2 + "/alert_provider";
const DATA_URL_EXPORT = API_TXALERT2 + "/bincaid/reports";

const { Option } = Select;

const Txalert_BinCaidList = (props) => {
    const [{ data, totalResult, isLoading, isError, errorMsg, requestData, isUpdated }, setUrl, setRequestData] = DataFetchHookTxAlert(BINCAID_DATA_URL,"GET", localStorage.getItem("txalert_token")); // eslint-disable-line
    const [formData, setFormData] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    const [id, setId] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const [providerList, setProviderList] = useState(false); //eslint-disable-line
    const [enrollUrl, setEnrollUrl] = useState('');
    const [title, setTitle] = useState(''); //todisplay in enroll window
    const [isEnrollOpen, setIsEnrollOpen] = useState(false);
    const [isUploadOpen, setIsUploadOpen] = useState(false);

    const { t } = props;

    const initFormData = {
        keyword: "",
        field: undefined,
        merchant:[],
      //  provider:[],
        status:[]
        //  order: null,
        
    };

    const [form] = Form.useForm();

   

    useEffect(() => {  
      //run only once when comp is loaded
                axios
                    .get(
                        PROVIDER_DATA_URL +'?rdr_alerts=1',
                        { headers: { Authorization: "Bearer " + localStorage.getItem("txalert_token") } }
                    )
                    .then(({ data }) => {
                         setProviderList(data.list);
                        const fData = data.list.map((d) => {
                            return {
                                dataIndex: d.provider_name +'_status',
                                key: d.provider_name,
                                title: d.provider_name,
                                ellipsis: true,
                                width: 100,
                                sorter: {
                                    compare: (a, b) => {
                                        a = a[d.provider_name +'_status'] || '';
                                        b = b[d.provider_name +'_status'] || '';
                                        return a.localeCompare(b);
                                    }
                                }
                            }
                        });
                        //remove the lastcol(action col) and add provider status columns
                        //add action col as the last one
                      let last_col = columns.pop();
                       
                        const temp = [...columns, ...fData,last_col];
                        setColumns(temp);
                    })
                    .catch(() => false);
      
            formValInit();
                }
    , []); // eslint-disable-line react-hooks/exhaustive-deps

   
    const showUploadModal = (selectedid) =>{
     
        setIsUploadOpen(true);
        
      };
  
    const showModal = (id) =>{
        
        setId(id);
        
        setIsOpen(true);
        
      };
    
      const showEnrollModal = (id,title) =>{
        setEnrollUrl(BINCAID_DATA_URL+'/'+id+'/enrollment');
        setId(id);
        setTitle(title);
        setIsEnrollOpen(true);
      };

    const formValInit = () => {
        setFormData(initFormData);
        form.setFieldsValue(initFormData);
        
    };

    const clearAll = () => {
        setCurrentPage(1);
        setFormData(initFormData);
        setRequestData(initFormData);
        form.setFieldsValue(initFormData);
    };

    const formValChange = (chgV, allV) => {
        setFormData(allV);
    };

    

    const onFinish = () => {
        let searchData = {};
        if (formData) {
            searchData = {
                [formData.field]: formData.keyword ? formData.keyword.toString().trim() : null,
                merchant_id: formData.merchant,
                enrollment_status:formData.status,
                alert_provider_id:formData.provider
            };
        }
   
        // Reset Page Index to 1
        setCurrentPage(1);
        setRequestData(searchData);
    };

    const [columns, setColumns] = useState([
        {
            dataIndex: "bincaid_id",
            key: "bincaid_id",
            title: t("tables.fields.id"),
            width: 50,
            render: (id, record) => (
                <a href={`/bincaid/${id}`} target="_blank" rel="noopener noreferrer">
                    {id}{" "}
                </a>
              
            ),
            sorter: {
                compare: (a, b) => a.bincaid_id - b.bincaid_id,
            }
        },
        {
            dataIndex: "bin",
            key: "bin",
            title: t("tables.fields.bin"),
            width: 100,
            sorter: {
                compare: (a, b) => TextSorter(a.bin,b.bin)
            }
        },
        {
            dataIndex: "caid",
            key: "caid",
            title: t("tables.fields.caid"),
            width: 100,
            sorter: {
                compare: (a, b) => TextSorter(a.caid,b.caid)
            }
        },
        {
            dataIndex: "mcc",
            key: "mcc",
            title: t("tables.fields.mcc"),
            width: 80,
            sorter: {
                compare: (a, b) => TextSorter(a.mcc,b.mcc)              
            }
        },
        {
            dataIndex: "merchant_name",
            key: "merchant_name",
            title: t("tables.fields.merchant_name"),
            align: "left",
            width: 150, 
            sorter: {
                compare: (a, b) => TextSorter(a.merchant_name,b.merchant_name)
            }
        },
        {
            dataIndex: "status",
            key: "status",
            align: "left",
            title: t("tables.fields.status"),
            width: 100,        
            render: (text, record) => (
                record.status === 1?'Active':'InActive'
            )    ,
            sorter: {
                compare: (a, b) => a.status-b.status
                /* {
                    a = a.satus || '';
                    b = b.status || '';
                    return a.localeCompare(b);
                },*/
            },
//           sortOrder: SortOrder(sortedInfo, "status"),
        }, {
                dataIndex: "action",
                title: t("tables.fields.action"),
                align: "left",
                width: 100,
                render: (id, record) => (
                    <Space size="middle">
                        <Button type="link" onClick={() => showModal(record.bincaid_id)} >
                          <FileSearchOutlined style={{ color: "rgba(0, 0, 0, 0.85)", fontSize: "18px" }} />
                        </Button>
                        <Button type="link" onClick={() => showEnrollModal(record.bincaid_id,'Bin:'+record.bin+' Caid:'+record.caid)} >
                        <EditOutlined style={{ color: "rgba(0, 0, 0, 0.85)", fontSize: "18px" }} />
                        </Button>
                    </Space>
                ),
            
        }
    ].filter(item => !item.hidden));
    const getBincaidList = () => {
      
        let searchData ={};
        setRequestData({ ...requestData, ...searchData,  page: 1 });      
    };

    const mergeColumns = columns.map((col, index) => ({
        ...col,
        onHeaderCell: (column) => ({
            width: column.width,
            onResize: (_, { size }) => {
                const newColumns = [...columns];
                newColumns[index] = { ...newColumns[index], width: size.width };
                setColumns(newColumns);
            },
        }),
    }));
    const getDataSource = (datalist) => (datalist && Array.isArray(datalist) ? datalist : []);

    const AdvancedSearchForm = () => (
        <div className="search-wrap">
            <Card title={t("tables.actions.search")} size="small">
                <Form form={form} size="large" name="advanced_search" className="ant-advanced-search-form" onValuesChange={formValChange} onFinish={onFinish}>
                    <Row gutter={[16, 16]}>
                        
                       <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }} xl={{ span: 6 }}>
                                <Form.Item name="merchant">
                                 <TxalertMerchantListComp  placeholder={t("contents.merchants.title")}
                                    style={{ width: "100%" }}  mode="multiple"/>
                                </Form.Item>
                       </Col>
                   {/*    <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }} xl={{ span: 6 }}>
                                <Form.Item name="provider">
                                 <TxalertProviderListComp rdr_alerts="1" mode="multiple" placeholder={t("contents.alerts.providername")}
                                    style={{ width: "100%" }} />
                                </Form.Item>
    </Col> */}
                       <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }} xl={{ span: 6 }}>
                                <Form.Item name="status">
                                 <TxalertEnrollmentStatusListComp mode="multiple" placeholder={t("contents.txalert_admin.enrollmentstatus")}
                                    style={{ width: "100%" }}/>
                                </Form.Item>
                       </Col>
                       </Row>
                       <Row>
                    <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }} xl={{ span: 6 }}>
                            <Form.Item name="field">
                                <Select 
                                    showSearch
                                    style={{ width: "100%" }} 
                                    placeholder={t("tables.fields.field")}
                                    >
                                    <Option value="bin">{t("tables.fields.bin")}</Option>
                                    <Option value="caid">{t("tables.fields.caid")}</Option>
                                    <Option value="mcc">{t("tables.fields.mcc")}</Option>
                                   </Select>
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" xs={{ span: 12 }} sm={{ span: 12 }} md={{ span: 6}} lg={{ span: 6 }} xl={{ span: 8 }}>
                            <Form.Item name="keyword">
                                <Input placeholder={t("tables.fields.keyword")} />
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" xs={{ span: 12 }} sm={{ span: 12 }} md={{ span: 6, offset: 3 }} lg={{ span: 6 }} xl={{ span: 3, offset: 3 }}>
                            <Form.Item>
                                <Button type="secondary" onClick={() => clearAll()} block>
                                    {t("tables.actions.clear_all")}
                                </Button>
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 4 }}>
                            <Form.Item>
                                <Button type="primary" htmlType="submit" block>
                                    <SearchOutlined /> {t("tables.actions.search")}
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Card>
        </div>
    );

    const getHeaderTable = () => (
        <div className="custom-table--header d-flex justify-content-between align-items-center">
            <h3 className="table-title">{t("contents.bincaids.title")}</h3>
            <Button type="link" onClick={() => showModal()} style={{ marginLeft: "auto" }}>
                <PlusOutlined /> {t("contents.bincaids.form.new_bincaid")}
            </Button>
            <ExportModalV3
                t={t}
                keyname="export_all"
                title={t("tables.actions.export")}
                data={requestData}
                data_url={DATA_URL_EXPORT}
                headers={exportHeaders}
                fieldHeadersOptional={exportHeadersOptional}
                txalert_token = {true}
                filename={`bincaid_${moment().format("YYMMDD_HHmmss_SSSS")}.csv`}
                style={{ marginLeft: 8 }}
            />
           <Button type="link" onClick={() => showUploadModal()} style={{ marginLeft:8 }}>
                <PlusOutlined /> {t("contents.txalert_admin.import_bincaid")}
            </Button>
        </div>
    );

    const exportHeadersOptional = [];
    const exportHeaders = [
        { label: "ID", key: "bc.bincaid_id" },
        { label: "BIN", key: "bin" },
        { label: "CAID", key: "caid" },
        { label: "MCC", key: "bc.mcc" },
        { label: "Merchant Name", key: "m.name" },
        { label: "BIN/CAID Status", key: "CASE WHEN bc.status = 1 THEN 'Active' ELSE 'Inactive' END" }
    ];

    return (
        <div>
            <PageHeader title={t("contents.bincaids.title")}></PageHeader>
            {AdvancedSearchForm()}
            <Table
                size="small"
                loading={isLoading}
                components={{
                    header: {
                        cell: ColumnResizable,
                    },
                }}
                
                columns={mergeColumns}
                title={() => getHeaderTable()}
                rowKey="bincaid_id"
                className="table-v3 custom-pagination custom-pagination-v3"
                dataSource={getDataSource(data.datalist)}
              pagination={CustomPaginationV2({ totalResult, currentPage, requestData, setRequestData, setCurrentPage })}

            />
            <TxalertBinCaidModal t={t} bincaidid={id} setId={setId} isOpen={isOpen} setIsOpen={setIsOpen} fetchBincaidList={getBincaidList} />
            <TxalertUpdateEnrollmentModal t={t} type="bincaid"  isEnrollOpen={isEnrollOpen} setIsEnrollOpen={setIsEnrollOpen} enrollurl={enrollUrl} title={title} fetchList={getBincaidList} id={id} />
            <TxalertBinCaidImport t={t} isUploadOpen={isUploadOpen} setIsUploadOpen={setIsUploadOpen} fetchList={getBincaidList}/>

        </div>
    );
};

export default Txalert_BinCaidList;
