import React from "react";
import { Row, Col } from "antd";

const showCardInfo = (fields, labelSpan = 10, valueSpan = 14) => (
    <div className="details-info--text">
        {fields.map(({ name, label, value }) => (
            <Row gutter={[0, 8]} key={name}>
                <Col key={`label-${name}`} span={labelSpan}>
                    <span className="text">
                        <b>{label}</b>
                    </span>
                </Col>
                <Col key={`value-${name}`} span={valueSpan}>
                    <span className="text">{value}</span>
                </Col>
            </Row>
        ))}
    </div>
);

export default showCardInfo;