import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Link } from 'react-router-dom';
import { Row, Col, Card, Typography, Badge, Checkbox } from "antd";
import UserSettings from "../../helper/UserSettings";

const { Title } = Typography;

const AlertsTask = (props) => {
    const { t, data, alertWidgetUpdate } = props;
    const [widget, setWidget] = useState(null);
    const [updateSettings] = UserSettings();

    let loggedUserSettings = localStorage.getItem("loggedUserSettings") ? JSON.parse(localStorage.getItem("loggedUserSettings")) : null;
    let loggedUserSettingsHideWidget = null;
    if (loggedUserSettings) {
        if (loggedUserSettings.hideAlertWidget) {
            loggedUserSettingsHideWidget=loggedUserSettings.hideAlertWidget;
        }
    }

    useEffect(() => {
    }, [widget]); // eslint-disable-line react-hooks/exhaustive-deps

    const alerts_tasks = [
        {
            title: t("contents.dashboard.expiring"),
            keyword: "expiry_datetime",
        },
        {
            title: t("contents.dashboard.action_required"),
            keyword: "action_required",
        },
        {
            title: t("contents.dashboard.wip"),
            keyword: "wip",
        },
    ];

    const style_expiring = {
        display: "block",
        fontSize: "1.75rem",
        fontWeight: "300",
        color: "#FF0001",
    };

    const style_action_required = {
        display: "block",
        fontSize: "1.75rem",
        fontWeight: "300",
        color: "#FFA611",
    };

    const style_wip = {
        display: "block",
        fontSize: "1.75rem",
        fontWeight: "300",
        color: "#550e78",
    };

    const onCheckbox = (v) => {
        if (!loggedUserSettings) {
            loggedUserSettings = {};

            if (!loggedUserSettings.hideAlertWidget) {
                loggedUserSettings.hideAlertWidget = [];
                loggedUserSettings.hideAlertWidget.push(v);
                loggedUserSettingsHideWidget=loggedUserSettings.hideAlertWidget;
            }
        }else{
            if (!loggedUserSettings.hideAlertWidget) {
                loggedUserSettings.hideAlertWidget = [];
                loggedUserSettings.hideAlertWidget.push(v);
                loggedUserSettingsHideWidget=loggedUserSettings.hideAlertWidget;
            }else{
                if(!loggedUserSettings.hideAlertWidget.includes(v)){
                    loggedUserSettings.hideAlertWidget.push(v);
                    loggedUserSettingsHideWidget=loggedUserSettings.hideAlertWidget;
                }
            }
        }
        setWidget(loadWidget());
        updateSettings(loggedUserSettings);
        localStorage.setItem("loggedUserSettings",JSON.stringify(loggedUserSettings));
        if(!loggedUserSettings.hideAlertWidget || loggedUserSettings.hideAlertWidget === null || (loggedUserSettings.hideAlertWidget && loggedUserSettings.hideAlertWidget.length === 0)){
            alertWidgetUpdate(true);
        } else {
            alertWidgetUpdate(false);
        }   
    };

    const loadWidget = () => {
        return alerts_tasks.map((alerts_task) => {
            if(loggedUserSettingsHideWidget !== null && loggedUserSettingsHideWidget.includes(alerts_task.keyword)){
                return null;
            } else{
                return <Col key={alerts_task.keyword} flex="auto">
                        <Card size="small" style={{ marginBottom: "0.75rem"}} >
                            <Row justify="space-between">
                                <Col>
                                    <Title style={{ minWidth: "80px" }} level={5}>
                                        {alerts_task.title}
                                    </Title>
                                </Col>
                                <Col>
                                    {/* <Badge count={t("contents.dashboard.today")} style={{ backgroundColor: "#108ee9", marginRight: '5px' }} /> */}
                                    <Checkbox onChange={() => onCheckbox(alerts_task.keyword)} >Hide</Checkbox>
                                </Col>
                            </Row>
                            <Row justify="space-between">
                                <Col>
                                    <Badge count={t("contents.dashboard.today")} style={{ backgroundColor: "#108ee9" }} />
                                </Col>
                            </Row>
                            <Link to={{ pathname: "/alerts", state: alerts_task.keyword }}>
                                <Row justify="space-between" align="middle">
                                    <Col flex="auto">
                                    <span style={
                                        alerts_task.keyword === "expiry_datetime"
                                            ? style_expiring : alerts_task.keyword === "action_required"
                                                ? style_action_required : style_wip }>
                                        {data ? (data.today[alerts_task.keyword] ? data.today[alerts_task.keyword] : 0) : 0}
                                    </span>
                                    </Col>
                                </Row>
                            </Link>
                        </Card>
                    </Col>;
            }
        })
    };

    return (
        <Router forceRefresh={true}>
            <Row gutter={[16, 16]}>
                {loadWidget()}
            </Row>
        </Router>
    );
};

export default AlertsTask;
