import React, { useState, useEffect } from "react";
import { PageHeader, Row, Col, Card, DatePicker, Collapse, Checkbox } from "antd";
import { API } from "../../config/config";
import { REALTIME_DASHBOARD } from "../../config/config";
import PermissionsRequired from "../../helper/PermissionsRequired";
import axios from "axios";
import moment from "moment";
import AlertsLineChart from "./AlertsLineChart";
import AlertsPieChart from "./AlertsPieChart";
import Analytics from "./Analytics";
import AlertsTask from "./AlertsTask";
// import moment from "moment";
import UserSettings from "../../helper/UserSettings";
import MenuAccess from "../../helper/MenuAccess";
import {  DISABLE_ALERTS, DISABLE_PAYMENTS } from "../../config/config";


const { RangePicker } = DatePicker;
const DATA_URL = "/alertschart/";
const DATA_URL_ANALYTICS = "/analytics/";
const DATA_URL_ALERTS_TASK = "/alertstask/";

const DEFAULT_DATE_RANGE = 7;
const DEFAULT_DATE_PERIOD = "days";
const START_DATE = moment().subtract(DEFAULT_DATE_RANGE, DEFAULT_DATE_PERIOD);
const END_DATE = moment();
const dateFormat = "YYYY/MM/DD";

const Dashboard = (props) => {
    const { t, socket } = props;
    const [data, setData] = useState(null);
    const [receive, setReceive] = useState(null);
    const [alertsTask, setAlertsTask] = useState(null);
    const [analytics, setAnalytics] = useState(null);
    const monthNames = ["january", "february", "march", "april", "may", "june", "july", "august", "september", "october", "november", "december"];
    const dateToGetMonth = new Date();
    const currentMonthName = monthNames[dateToGetMonth.getMonth()];
    // const [unix, setUnix] = useState(null);

    // if (REALTIME_DASHBOARD) {
    //     setInterval(() => {
    //         if (unix !== parseInt(moment().unix() / 50)) {
    //             setUnix(parseInt(moment().unix() / 50));
    //         }
    //     }, 50000);
    // }
    const [dates, setDates] = useState([START_DATE, END_DATE]);
    const [isOpen, setIsOpen] = useState(false);
    const [alertWidget, setAlertWidget] = useState(null);
    const [transactionWidget, setTransactionWidget] = useState(null);
    const [updateSettings] = UserSettings();
    const [checkShowAllAlertWidget, setCheckShowAllAlertWidget] = useState(false);
    const [checkShowAllTransactionWidget, setCheckShowAllTransactionWidget] = useState(false);
    
    function handleCheckFromAlertWidget(data) {
        setCheckShowAllAlertWidget(data);
    }

    function handleCheckFromTransactionWidget(data) {
        setCheckShowAllTransactionWidget(data);
    }

    

    useEffect(() => {
        if (!data) {
            // getAlertsTask();
            // getAnalytics();
            getAggregates();
        }
        if(!analytics){
            getAnalytics();
        }
        if(!alertsTask){
            getAlertsTask();
        }
        loadAlertWidgetShowAll();
        loadTransactionWidgetShowAll();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    // useEffect(() => {
    //     if (unix) {
    //         getAnalytics();
    //         getAggregates();
    //     }
    // }, [unix]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (receive) {
            getAggregates();
        }
    }, [receive]); // eslint-disable-line react-hooks/exhaustive-deps

    if (REALTIME_DASHBOARD && socket) {
        socket.on("requestAlertUpdate", (receive) => {
            setReceive(receive);
        });
    }

    const getAggregates = () => {
        const apiUrl = DATA_URL;
        axios
            .get(API + apiUrl +"?date_to="+dates[1].format('YYYY-MM-DD')+"&date_from="+dates[0].format('YYYY-MM-DD'),
                { headers: { Authorization: "Bearer " + localStorage.getItem("access_token") } })
            .then(({ data }) => {
                setData(data);
            })
            .catch((error) => error);
    };

    const getAnalytics = () => {
        const apiUrl = DATA_URL_ANALYTICS;
        axios
            .get(API + apiUrl, { headers: { Authorization: "Bearer " + localStorage.getItem("access_token") } })
            .then(({ data }) => {
                setAnalytics(data);
                setTransactionWidget(loadTransactionWidget(data));
            })
            .catch((error) => error);
    };

    const getAlertsTask = () => {
        const apiUrl = DATA_URL_ALERTS_TASK;
        axios
            .get(API + apiUrl, { headers: { Authorization: "Bearer " + localStorage.getItem("access_token") } })
            .then(({ data }) => {
                setAlertsTask(data);
                setAlertWidget(loadAlertWidget(data));
            })
            .catch((error) => error);
    };

    const checkTranslation = (datas) => {
        return datas
            ? datas.map((data) => {
                  try {
                      if (data.type.toLowerCase() && data.type[0].toUpperCase() !== data.type[0].toLowerCase()) {
                          return { ...data, type: t(`${data.type}`).charAt(0).toUpperCase() + t(`${data.type}`).slice(1) };
                      }
                  } catch (error) {
                      return data;
                  }
                  return data;
              })
            : [];
    };

    const onDatesChange = (val) => {
        setDates(val);
    };


    useEffect(() => {
      if (!isOpen && dates && dates[0] && dates[1]) {
        getAggregates();
      }
    }, [dates, isOpen]);// eslint-disable-line 

    const onOpenChange = (open) => {
        setIsOpen(open);
    };

    const onShowAllAlertWidget = (e) => {
        if(e.target.checked){
            let loggedUserSettings = localStorage.getItem("loggedUserSettings") ? JSON.parse(localStorage.getItem("loggedUserSettings")) : null;
            if (loggedUserSettings) {
                if (loggedUserSettings.hideAlertWidget) {
                    loggedUserSettings.hideAlertWidget = [];
                    updateSettings(loggedUserSettings);
                    localStorage.setItem("loggedUserSettings",JSON.stringify(loggedUserSettings));
                    setAlertWidget(loadAlertWidget(alertsTask));
                    loadAlertWidgetShowAll();
                }
            }
        }
    };

    const onShowAllTransactionWidget = (e) => {
        if(e.target.checked){
            let loggedUserSettings = localStorage.getItem("loggedUserSettings") ? JSON.parse(localStorage.getItem("loggedUserSettings")) : null;
            if (loggedUserSettings) {
                if (loggedUserSettings.hideTransactionWidget) {
                    loggedUserSettings.hideTransactionWidget = [];
                    updateSettings(loggedUserSettings);
                    localStorage.setItem("loggedUserSettings",JSON.stringify(loggedUserSettings));
                    setTransactionWidget(loadTransactionWidget(analytics));
                    loadTransactionWidgetShowAll();
                }
            }
        }
    };

    const loadAlertWidget = (alertsTask) => {
        return <AlertsTask t={t} data={alertsTask ? alertsTask.alerts_task : null} alertWidgetUpdate={handleCheckFromAlertWidget} />;
    };

    const loadTransactionWidget = (analytics) => {
        return <Analytics t={t} data={analytics ? analytics.analytics : null} transactionWidgetUpdate={handleCheckFromTransactionWidget} /> 
    };

    const loadAlertWidgetShowAll = () => {
        let loggedUserSettings = localStorage.getItem("loggedUserSettings") ? JSON.parse(localStorage.getItem("loggedUserSettings")) : null;
        if(loggedUserSettings){
            if(!loggedUserSettings.hideAlertWidget || loggedUserSettings.hideAlertWidget === null || (loggedUserSettings.hideAlertWidget && loggedUserSettings.hideAlertWidget.length === 0)){
                setCheckShowAllAlertWidget(true);
            } else {
                setCheckShowAllAlertWidget(false);
            } 
        }  else
        {
            setCheckShowAllAlertWidget(false);
        }
    };

    const loadTransactionWidgetShowAll = () => {
        let loggedUserSettings = localStorage.getItem("loggedUserSettings") ? JSON.parse(localStorage.getItem("loggedUserSettings")) : null;
        if(loggedUserSettings){
        if(!loggedUserSettings.hideTransactionWidget || loggedUserSettings.hideTransactionWidget === null || (loggedUserSettings.hideTransactionWidget && loggedUserSettings.hideTransactionWidget.length === 0)){
            setCheckShowAllTransactionWidget(true);
        } else {
            setCheckShowAllTransactionWidget(false);
        }   
       }else{
        setCheckShowAllTransactionWidget(false);
       }
    };

    return (
        <div>
            <PageHeader title={t("contents.dashboard.title")}></PageHeader>

            {MenuAccess('alerts') && !DISABLE_ALERTS? (
                <Collapse style={{ marginBottom: "1.5rem" }} defaultActiveKey={['1']}>
                    <Collapse.Panel header={t("contents.dashboard.alert_info")} key="1"
                        extra={[
                            <div key="show_all_alert_widget"  onClick={(e) => e.stopPropagation()}>
                                <Checkbox
                                    onChange={onShowAllAlertWidget}
                                    checked={checkShowAllAlertWidget}
                                >Show all</Checkbox>
                            </div>
                        ]}
                    >
                        {!alertWidget ? setAlertWidget(loadAlertWidget(alertsTask)) : alertWidget}
                    </Collapse.Panel>
                </Collapse>
                ) : null
            }

            <div>
                {MenuAccess('payments') && !DISABLE_PAYMENTS ? (
                    <Collapse style={{ marginBottom: "1.5rem" }} defaultActiveKey={['t1']}>
                        <Collapse.Panel header={t("contents.dashboard.transaction_info")} key="t1"
                            extra={[
                                <div key="show_all_transaction_widget"  onClick={(e) => e.stopPropagation()}>
                                    <Checkbox
                                        onChange={onShowAllTransactionWidget}
                                        checked={checkShowAllTransactionWidget}
                                    >Show all</Checkbox>
                                </div>
                            ]}
                        >
                            {/* <Analytics t={t} data={analytics ? analytics.analytics : null} />  */}
                            {!transactionWidget ? setTransactionWidget(loadTransactionWidget(analytics)) : transactionWidget}
                        </Collapse.Panel>
                    </Collapse>
                    ) : null
                }

                {PermissionsRequired("SYSADMIN|ALERTSTATS") && MenuAccess('alerts') && !DISABLE_ALERTS && data ? (
                    <Row gutter={[16, 16]}>
                        <Col sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 9 }}>
                            <Card size="small" title={`${t(`contents.months.${currentMonthName}`)}'s ${t("contents.dashboard.alerts")} `} style={{ minHeight: "506px" }}>
                                {data.alerts ? <AlertsPieChart t={t} data={data.alerts ? checkTranslation(data.alerts.piechart) : []} /> : null}
                                
                            </Card>
                        </Col>
                        <Col sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }} xl={{ span: 15 }}>
                            <Card size="small" title={t("contents.dashboard.alerts_chart2")} key="AlertsLineChart"
                                extra={[
                                    <RangePicker key="DateRange"
                                        value={dates}
                                        format={dateFormat}
                                        onOpenChange={onOpenChange}
                                        onChange={(val) => onDatesChange(val)}
                                    />
                                ]}
                            >

                                {data.alerts ? <AlertsLineChart t={t} data={data.alerts.linechart ? data.alerts.linechart : []} /> : null}
                            </Card>
                        </Col>
                    </Row>
                    ) : null
                }
            </div>
        </div>
    );
};

export default Dashboard;
