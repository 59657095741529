import React, { useState, useEffect } from "react";
import { Card, Form, Select, Row, Col, Input, InputNumber, Button , Checkbox } from "antd";
import { API, API_DATA_SOURCE, API_SITE } from "../../config/config";
import axios from "axios";
import Swal from "sweetalert2";

const { Option } = Select;

const NmiACHPayment = (props) => {
    const { t } = props;
    const [siteList, setSiteList] = useState(null);
    const [countryList, setCountryList] = useState(null);
    const [isTaxExempt, setIsTaxExempt] = useState(false);
    const [isSameBilling, setIsSameBilling] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);
    const [isProcessSuccess, setIsProcessSuccess] = useState(false);
    const [isProcessFailed, setIsProcessFailed] = useState(false);
    const [processResponse, setProcessResponse] = useState(null);
    
    const initFormData = {
        sid: null,
        routing_no: null,
        account_no: null,
        item_amount_unit: null,
        ach_type: null,
        total: null,
        currency: 'USD',
        firstname: null,
        lastname: null,
        country: 'US',
        address1: null,
        address2: null,
        city: null,
        state: null,
        zip: null,
        phone: null,
        email: null,
        orderid: null,
        ponumber: null,
        orderdescription: null,
        shipping: null,
        tax: null,
        taxExempt: false,
        sameBilling: false,
        shipping_firstname: null,
        shipping_lastname: null,
        shipping_country: 'US',
        shipping_address1: null,
        shipping_address2: null,
        shipping_city: null,
        shipping_state: null,
        shipping_zip: null,
        addinfo: null
    };

    const [form] = Form.useForm();

    const verticalItemLayout = {
        labelAlign: "left",
        labelCol: { span: 24, offset: 0 },
        wrapperCol: { span: 24, offset: 0 }
    };

    useEffect(() => {
        if(!siteList) {
            getSiteList();
        }
        if(!countryList) {
            getCountryList();
        }
        formValInit();
    }, []); // eslint-disable-line

    const formValInit = () => {
        form.setFieldsValue(initFormData);
    };

    const formValChange = (chgV, allV) => {

        let formVal = allV;
        if(formVal.taxExempt) {
            formVal = {...formVal, tax: null};
            form.setFieldsValue(formVal);
            setIsTaxExempt(true);
        } else {
            setIsTaxExempt(false);
        }

        let amount = formVal.amount ? parseFloat(formVal.amount) : 0.00;
        let surcharge = formVal.surcharge ? parseFloat(formVal.surcharge) : 0.00;
        let total = parseFloat(amount) + parseFloat(surcharge);

        formVal = {...formVal, total: parseFloat(total).toFixed(2)};
        form.setFieldsValue(formVal);

        if(formVal.sameBilling) {
            setIsSameBilling(true);
        } else {
            formVal = {
                ...formVal, 
                shipping_firstname: null,
                shipping_lastname: null,
                shipping_country: 'US',
                shipping_address1: null,
                shipping_address2: null,
                shipping_city: null,
                shipping_state: null,
                shipping_zip: null,
            };            
            form.setFieldsValue(formVal);
            setIsSameBilling(false);
        }
    };

    const getSiteList = async () => {
        const apiUrl = API_SITE + "/list";
        await axios
            .post(apiUrl, { type: 'check' }, { headers: { Authorization: "Bearer " + localStorage.getItem("access_token") } })
            .then(({ data }) => {
                setSiteList(data.list);
            })
            .catch((error) => console.log(error));
    };

    const onChangeSiteDropdown = (value) => {
    }

    const getCountryList = async () => {        
        const api_url = API_DATA_SOURCE + `/defaultcountry`;
        await axios
            .post(api_url, {}, { headers: { Authorization: "Bearer " + localStorage.getItem("access_token") } })
            .then(({ data }) => {
                setCountryList(data);
            })
            .catch((error) => console.log(error));
    };

    const onChangeCountryDropdown = (value) => {
    }

    const onFinish = async values => {

        let postData = {
            cash_discount: 0,
            type: 'sale',
            card_type: 'ach',
            currency: 'USD',
            tx_action: 'PREAUTH',
            item_quantity: [
                1
            ]
        }

        postData = { ...postData, ...values };
        postData = {
            ...postData, 
            item_amount_unit: [
                parseFloat(values.item_amount_unit)
            ]
        };

        setIsProcessing(true);
        axios
            .post(API + "/cliqachpayment", postData, { headers: { Authorization: "Bearer " + localStorage.getItem("access_token") }})
            .then(({ data }) => {
                setProcessResponse(data);
                if(data.status === 'OK') {
                    setIsProcessSuccess(true);
                } else if(data.status === 'EXC') {
                    setIsProcessFailed(true);
                }
                setIsProcessing(false);
            })
            .catch((error) => {
                Swal.fire({
                    icon: "error",
                    title: error,
                    showConfirmButton: true,
                });
            });
    }

    const onFinishFailed = ({ values, errorFields, outOfDate }) => {
        window.scroll({top: 0, left: 0, behavior: 'smooth' });
    }

    const backToVirtualTerminal = () => {

        setIsSameBilling(false);
        setIsTaxExempt(false);
        setIsProcessSuccess(false);
        setIsProcessFailed(false);
        setProcessResponse(null);

        form.resetFields();
        form.setFieldsValue(initFormData);
    }

    return (
        <>
            { processResponse && isProcessSuccess ? 
                <Card title={t('contents.virtual_terminal.payment_successful')} style={{ marginTop: 50 }}>
                    <div>
                        <p>{t('contents.virtual_terminal.transaction_reference')}: {processResponse.parent_txid}</p>
                        <p>{t('contents.virtual_terminal.total_amount')}:  {processResponse.amount} {processResponse.currency}</p>
                        <p style={{ marginTop: 15 }}><Button type="primary" onClick={() => backToVirtualTerminal()}>{t('contents.virtual_terminal.back_to_virtual_terminal_button')}</Button></p>
                    </div>
                </Card> 
            : null }
            { processResponse && isProcessFailed ? 
                <Card title={t('contents.virtual_terminal.payment_failed')} style={{ marginTop: 50 }}>
                    {processResponse.error_info ? <p>{processResponse.error_info}</p> : null }
                    {processResponse.error_msg ? <p>{processResponse.error_msg}</p> : null }
                    <p style={{ marginTop: 15 }}><Button type="primary" onClick={() => backToVirtualTerminal()}>{t('contents.virtual_terminal.back_to_virtual_terminal_button')}</Button></p>
                </Card> 
            : null }
            <Form className={processResponse ? "hide" : null} onFinish={onFinish} onFinishFailed={onFinishFailed} onValuesChange={formValChange} form={form} name="process_details" style={{ marginTop: 50 }}>
                <Row gutter={[36]}>
                    <Col span={6}>
                        <Form.Item name="sid" label="SID" rules={[{ required: true, message: t('required_field') }]}>
                            <Select
                                onChange={onChangeSiteDropdown}
                                showSearch
                                style={{ width: '100%' }}
                                placeholder={t('please_select')}
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {siteList ? siteList.map((item) => (
                                    <Option key={item.sid} value={item.sid}>{item.name + ` (sid: ` + item.sid + `)`}</Option>
                                )) : null}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[36]}>
                    <Col span={12}>
                        <h3>{t('contents.virtual_terminal.billing_information')}</h3>
                        <Card className="custom-form--card">
                            <Row gutter={[36]}>
                                <Col span={24}>
                                    <img alt="ACH Cheque Example" src='../../../themes/example/ACH_cheque_Example.png' style={{ marginBottom: 5, width: '100%' }}/>
                                </Col>
                                <Col span={8}>
                                    <Form.Item {...verticalItemLayout} name="routing_no" label={t('contents.virtual_terminal.form.routing_number')} rules={[{ required: true, message: t('required_field') }]}>
                                        <Input/>
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item {...verticalItemLayout} name="account_no" label={t('contents.virtual_terminal.form.account_number')} rules={[{ required: true, message: t('required_field') }]}>
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item {...verticalItemLayout} name="item_amount_unit" label={t('contents.virtual_terminal.form.amount_only_usd')} rules={[{ required: true, message: t('required_field') }]}>
                                        <InputNumber addonBefore="$" placeholder="0.00" step="0.01" min={0} stringMode/>
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item {...verticalItemLayout} name="ach_type" label={t('contents.virtual_terminal.form.account_type')} rules={[{ required: true, message: t('required_field') }]}>
                                        <Select style={{ width: '100%' }} placeholder={t('please_select')}>
                                            <Option value="savings">Savings</Option>
                                            <Option value="checking">Checking</Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item {...verticalItemLayout} name="firstname" label={t('contents.virtual_terminal.form.firstname')} rules={[{ required: true, message: t('required_field') }]}>
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item {...verticalItemLayout} name="lastname" label={t('contents.virtual_terminal.form.lastname')} rules={[{ required: true, message: t('required_field') }]}>
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item {...verticalItemLayout} name="country" label={t('contents.virtual_terminal.form.country')} rules={[{ required: true, message: t('required_field') }]}>
                                        <Select
                                            onChange={onChangeCountryDropdown}
                                            showSearch
                                            style={{ width: '100%' }}
                                            placeholder={t('please_select')}
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                        >
                                            {countryList ? Object.keys(countryList).map(key => (
                                                <Option key={key} value={key}>{countryList[key]}</Option>
                                            )) : null}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item {...verticalItemLayout} name="address1" label={t('contents.virtual_terminal.form.address1')}>
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item {...verticalItemLayout} name="address2" label={t('contents.virtual_terminal.form.address2')}>
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item {...verticalItemLayout} name="city" label={t('contents.virtual_terminal.form.city')}>
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item {...verticalItemLayout} name="state" label={t('contents.virtual_terminal.form.state_province')}>
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item {...verticalItemLayout} name="zip" label={t('contents.virtual_terminal.form.zip')}>
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item {...verticalItemLayout} name="phone" label={t('contents.virtual_terminal.form.phone')}>
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item {...verticalItemLayout} name="email" label={t('contents.virtual_terminal.form.email')}>
                                        <Input />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Card>
                        <h3>{t('contents.virtual_terminal.payment_related_info')}</h3>
                        <Card className="custom-form--card">
                            <Row gutter={[36]}>
                                <Col span={24}>
                                    <Form.Item {...verticalItemLayout} name="addinfo">
                                        <Input.TextArea rows={8} />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                    <Col span={12}>
                        <h3>{t('contents.virtual_terminal.order_information')}</h3>
                        <Card className="custom-form--card">
                            <Row gutter={[36]}>
                                <Col span={12}>
                                    <Form.Item {...verticalItemLayout} name="orderid" label={t('contents.virtual_terminal.form.orderid')} rules={[{ required: true, message: t('required_field') }]}>
                                        <Input/>
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item {...verticalItemLayout} name="ponumber" label={t('contents.virtual_terminal.form.ponumber')}>
                                        <Input/>
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item {...verticalItemLayout} name="orderdescription" label={t('contents.virtual_terminal.form.orderdesc')}>
                                        <Input.TextArea rows={4} />
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item {...verticalItemLayout} name="shipping" label={t('contents.virtual_terminal.form.shipping')}>
                                        <InputNumber addonBefore="$" placeholder="0.00" step="0.01" min={0} stringMode/>
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item {...verticalItemLayout} name="tax" label={t('contents.virtual_terminal.form.sales_tax')}>
                                        <InputNumber addonBefore="$" placeholder="0.00" step="0.01" min={0} stringMode disabled={isTaxExempt}/>
                                    </Form.Item>
                                </Col>
                                <Col span={8} className="gutter-row">
                                    <Form.Item name="taxExempt" valuePropName="checked" style={{ paddingTop: 32 }}>
                                        <Checkbox>{t('contents.virtual_terminal.form.tax_exempt')}</Checkbox>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Card>
                        <h3>{t('contents.virtual_terminal.shipping_address')}</h3>
                        <Card className="custom-form--card">
                            <Row gutter={[36]}>
                                <Col span={24} className="gutter-row">
                                    <Form.Item name="sameBilling" valuePropName="checked">
                                        <Checkbox>{t('contents.virtual_terminal.form.sameBilling')}</Checkbox>
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item {...verticalItemLayout} name="shipping_firstname" label={t('contents.virtual_terminal.form.firstname')}>
                                        <Input disabled={isSameBilling}/>
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item {...verticalItemLayout} name="shipping_lastname" label={t('contents.virtual_terminal.form.lastname')}>
                                        <Input disabled={isSameBilling} />
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item {...verticalItemLayout} name="shipping_country" label={t('contents.virtual_terminal.form.country')} rules={[{ required: !isSameBilling, message: t('required_field') }]}>
                                        <Select
                                            disabled={isSameBilling}
                                            onChange={onChangeCountryDropdown}
                                            showSearch
                                            style={{ width: '100%' }}
                                            placeholder={t('please_select')}
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                            }
                                        >
                                            {countryList ? Object.keys(countryList).map(key => (
                                                <Option key={key} value={key}>{countryList[key]}</Option>
                                            )) : null}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item {...verticalItemLayout} name="shipping_address1" label={t('contents.virtual_terminal.form.address1')}>
                                        <Input disabled={isSameBilling} />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item {...verticalItemLayout} name="shipping_address2" label={t('contents.virtual_terminal.form.address2')}>
                                        <Input disabled={isSameBilling} />
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item {...verticalItemLayout} name="shipping_city" label={t('contents.virtual_terminal.form.city')}>
                                        <Input disabled={isSameBilling} />
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item {...verticalItemLayout} name="shipping_state" label={t('contents.virtual_terminal.form.state_province')}>
                                        <Input disabled={isSameBilling} />
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item {...verticalItemLayout} name="shipping_zip" label={t('contents.virtual_terminal.form.zip')}>
                                        <Input disabled={isSameBilling} />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>
                <Button loading={isProcessing} type="primary" htmlType="submit">
                    {t('contents.virtual_terminal.process_button')}
                </Button>
            </Form>
        </>
    );
};

export default NmiACHPayment;