import React, { useState } from "react";
import { CBM_URL } from "../../config/config";
import { Modal, Button, Form, Input, Select } from "antd";
import axios from "axios";
import Swal from "sweetalert2";
import { PlusOutlined } from "@ant-design/icons";

const DATA_URL = "/api/v1/merchantProvider/create/";

const CreateMerchantProvider = (props) => {
    const { t, merchants, providers, fetchMerchantProviders } = props;
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);

    const layout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };

    const [form] = Form.useForm();

    const initData = {
        mid: null,
        pid: null,
        password1: "",
        password2: "",
        password3: "",
        url: "",
    };

    const handleOk = () => {
        setLoading(true);
        onFinish();
    };

    const showModal = () => {
        form.setFieldsValue(initData);
        setIsModalVisible(true);
    };

    const handleCancel = () => {
        setLoading(false);
        setIsModalVisible(false);
    };

    const formValChange = (chgV, allV) => {
        setData(allV);
    };

    const onFinish = () => {
        const apiUrl = CBM_URL + DATA_URL;

        axios
            .post(apiUrl, data,{ headers: { Authorization: "Bearer " + localStorage.getItem("cbm_token") }})
            .then(({ data }) => {
                if (data.status === "ok") {
                    Swal.fire({
                        icon: "success",
                        title: "Merchant Provider Created",
                        showConfirmButton: false,
                        timer: 2000,
                        onClose: () => {
                            fetchMerchantProviders();
                        },
                    });
                    handleCancel();
                } else {
                    Swal.fire({
                        icon: "error",
                        title: t("login.dialogs.catch"),
                        text: data.error_msg ? data.error_msg : t("login.dialogs.catch"),
                        showConfirmButton: true,
                    });
                }
            })
            .catch((error) => {
               // console.log(error.response);
                setLoading(false);
                Swal.fire({
                    icon: "error",
                    title: t("login.dialogs.catch"),
                    text: error.response ? error.response.data.error_msg.msg : "",
                    showConfirmButton: true,
                });
            });
    };

    const isOkButtonProps = () => !data || !data.mid || !data.pid || loading;

    return (
        <>
            <Button type="link" onClick={showModal} style={{ marginLeft: "auto" }}>
                <PlusOutlined /> {t("contents.chargebacks.new_merchant_provider")}
            </Button>
            <Modal
                title={t("contents.chargebacks.create_merchant_provider")}
                width={600}
                visible={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={[
                    <Button key="back" onClick={() => handleCancel()}>
                        {t("contents.chargebacks.form.cancel")}
                    </Button>,
                    <Button key="submit" type="primary" loading={loading} disabled={isOkButtonProps()} onClick={() => handleOk()}>
                        {loading ? t("contents.chargebacks.form.submitting") : t("contents.chargebacks.form.submit")}
                    </Button>,
                ]}
            >
                <Form {...layout} form={form} name="new_chargeback" onValuesChange={formValChange}>
                    <Form.Item name="mid" label={t("contents.chargebacks.form.merchant")} rules={[{ required: true }]} style={{ marginBottom: ".5rem" }}>
                        <Select>
                            {merchants
                                ? merchants.map((merchant) => (
                                      <Select.Option key={merchant.mid} value={merchant.mid}>
                                          {merchant.name}
                                      </Select.Option>
                                  ))
                                : null}
                        </Select>
                    </Form.Item>
                    <Form.Item name="pid" label={t("contents.chargebacks.form.provider")} rules={[{ required: true }]} style={{ marginBottom: ".5rem" }}>
                        <Select>
                            {providers
                                ? providers.map((provider) => (
                                      <Select.Option key={provider.pid} value={provider.pid}>
                                          {provider.name}
                                      </Select.Option>
                                  ))
                                : null}
                        </Select>
                    </Form.Item>
                    <Form.Item name="password1" label={t("contents.chargebacks.form.password1")} style={{ marginBottom: ".5rem" }}>
                        <Input />
                    </Form.Item>
                    <Form.Item name="password2" label={t("contents.chargebacks.form.password2")} style={{ marginBottom: ".5rem" }}>
                        <Input />
                    </Form.Item>
                    <Form.Item name="password3" label={t("contents.chargebacks.form.password3")} style={{ marginBottom: ".5rem" }}>
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="url"
                        label={t("contents.chargebacks.form.url")}
                        rules={[{ type: "url", warningOnly: true }]}
                        style={{ marginBottom: ".5rem" }}
                    >
                        <Input />
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};

export default CreateMerchantProvider;
