import { useLocation } from "react-router-dom";

const DefaultPageSize = () => {
    const location = useLocation();
    const { pathname } = location;
    const keys = pathname.substring(1).split("/");
    const pageName = keys[0];
    let initDefaultPageSize = 20;
    let loggedUserSettings = localStorage.getItem("loggedUserSettings") ? JSON.parse(localStorage.getItem("loggedUserSettings")) : '{}';

    if (loggedUserSettings) {
        if (loggedUserSettings.defaultTablePageSize) {
            if (loggedUserSettings.defaultTablePageSize[pageName]) {
                initDefaultPageSize = loggedUserSettings.defaultTablePageSize[pageName];
            }
        }
    }
    return initDefaultPageSize;
};

export default DefaultPageSize;
