import React from "react";
import { Empty } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

const CustomEmptyTextProps = (emptyText, isLoading = false) => (
    <div className="ant-empty ant-empty-normal">
        <div className="ant-empty-image">
            <Empty image={isLoading ? <LoadingOutlined style={{ fontSize: '32px' }} /> : Empty.PRESENTED_IMAGE_SIMPLE} description={emptyText} />
        </div>
    </div>
);

export default CustomEmptyTextProps;
