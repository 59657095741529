const PermissionsRequired = (strgroups) => {
    if(localStorage.loggedUser) {
        if (!JSON.parse(localStorage.getItem("loggedUser")).access) {
            return true;
        }
        const useraccess = JSON.parse(localStorage.getItem("loggedUser")).access;
        const grouplist = strgroups.split("|");
    
        return useraccess.map((access) => grouplist.includes(access)).reduce((a, b) => a || b);
    } else {
        return null;
    }
};
export default PermissionsRequired;
