import React from "react";
import { Form, Input, Button, Select } from "antd";
import Countries from "./../../../helper/Countries";

const { Option } = Select;

const ContactForm = (props) => {
    const { t, form, layout, tailLayout, onSubmit, onValuesChange } = props;

    return (
        <Form {...layout} form={form} onValuesChange={onValuesChange} onFinish={onSubmit} style={{ padding: "15px 0" }}>
            <Form.Item name="phone" label={t('contents.profile.form.phone')}>
                <Input />
            </Form.Item>
            <Form.Item name="mobile" label={t('contents.profile.form.mobile')}>
                <Input />
            </Form.Item>
            <Form.Item name="imc_yahoo" label="Yahoo">
                <Input />
            </Form.Item>
            <Form.Item name="imc_skype" label="Skype">
                <Input />
            </Form.Item>
            <Form.Item name="imc_other" label={t('contents.profile.form.other')}>
                <Input />
            </Form.Item>
            <Form.Item name="address" label={t('contents.profile.form.address')}>
                <Input />
            </Form.Item>
            <Form.Item name="city" label={t('contents.profile.form.city')}>
                <Input />
            </Form.Item>
            <Form.Item name="state" label={t('contents.profile.form.state')}>
                <Input />
            </Form.Item>
            <Form.Item name="postcode" label={t('contents.profile.form.postcode')}>
                <Input />
            </Form.Item>
            <Form.Item name="country" label={t('contents.profile.form.country')}>
                <Select>
                    {Object.keys(Countries).map((key) => (
                        <Option key={key} value={key}>
                            {Countries[key]}
                        </Option>
                    ))}
                </Select>
            </Form.Item>
            <Form.Item {...tailLayout}>
                <Button type="primary" htmlType="submit">
                    {t("contents.profile.form.submit")}
                </Button>
            </Form.Item>
        </Form>
    );
};

export default ContactForm;
