import React, { useState, useEffect } from "react";
import DataFetchHookTxAlert from "../../../helper/DataFetchHookTxAlert";
import { Table, PageHeader, Card, Form, Row, Button, Col, Input, Space } from "antd";
import { API_TXALERT2 } from "../../../config/config";
import { SearchOutlined,PlusOutlined, FileSearchOutlined,EditOutlined } from "@ant-design/icons";
import TxalertMerchantListComp from "../Components/MerchantListDropdown";
import axios from "axios";
import moment from "moment";
import CustomPaginationV2 from "../../../helper/Table/CustomPaginationV2";
import ColumnResizable from "../../../helper/Table/ColumnResizable";
import TxalertProviderListComp from "../Components/ProviderlistDropdown";
import TxalertEnrollmentStatusDropdown from "../Components/EnrollmentStatusDropdown";
import TxalertDescriptorModal from "./Descriptor";
import TxalertUpdateEnrollmentModal from "../Admin/UpdateEnrollment";
import TextSorter from "../../../helper/Table/TextSorter";
import ExportModalV3 from "../../../helper/ExportModalV3";
import TxalertDescriptorImport from "./DescriptorImport";

const DATA_URL = API_TXALERT2 + "/descriptor?include_variations=0";
const PROVIDER_DATA_URL = API_TXALERT2 + "/alert_provider";
const DATA_URL_EXPORT = API_TXALERT2 + "/descriptor/reports";


const Txalert_DescriptorList = (props) => {
    // eslint-disable-next-line    

    const [{ data, totalResult, isLoading, isError, errorMsg, requestData, isUpdated }, setUrl, setRequestData] = DataFetchHookTxAlert(DATA_URL, "GET", localStorage.getItem("txalert_token")); // eslint-disable-line

    const { t } = props;
    const [formData, setFormData] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    const [providerList, setProviderList] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [id, setId] = useState('');

    const [enrollUrl, setEnrollUrl] = useState('');
    const [title, setTitle] = useState(''); //todisplay in enroll window
    const [isEnrollOpen, setIsEnrollOpen] = useState(false);
    const [isUploadOpen, setIsUploadOpen] = useState(false);

    const initFormData = {
        descriptor: "",
        display: null,
        merchant: undefined,  //later will need to allow multiple  merchant: []
        provider: undefined,
        mid:'',
        status:undefined
    };

    const [form] = Form.useForm();

    useEffect(() => {      
        formValInit();
   
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const handleChange = (pagination, filters, sorter, { currentDataSource }) => {
            setRequestData({ ...requestData,  order: sorter.order==='ascend'?'ASC':'DESC', col_order: sorter.field?sorter.field:'descriptor_id', page: 1 , size:pagination.pageSize});
    };

    const clearAll = () => {      

        setFormData(initFormData);
        setCurrentPage(1);
        setRequestData(initFormData);
        form.setFieldsValue(initFormData);
    };

    const formValInit = () => {
        setFormData(initFormData);
        form.setFieldsValue(initFormData);
    };

    const showModal = (selectedid) =>{
        setId(selectedid);
        setIsOpen(true);        
      };
    
      const showEnrollModal = (id,title) =>{
        setEnrollUrl(API_TXALERT2 + '/descriptor/'+id+'/enrollment');
        setId(id);
        setTitle(title);
        setIsEnrollOpen(true);
      };

      const showUploadModal = (selectedid) =>{
     
        setIsUploadOpen(true);
        
      };

    const formValChange = (chgV, allV) => {
        setFormData(allV);
    };

    const onFinish = () => {
        let searchData = {};
        if (formData) {

            searchData = {
                descriptor_text: formData.descriptor.trim(),
                merchant_id: formData.merchant,            
                alert_provider_id: formData.provider,
                enrollment_status: formData.status
            };

        }
       
        Object.keys(searchData).forEach((item) => !searchData[item] && delete searchData[item]);
        
        setCurrentPage(1);
        setRequestData({ ...requestData, ...searchData, page: 1 });
        // console.log("Submit", formData);
    };

    const getList = () => {
        
        let searchData ={};
        setRequestData({ ...requestData, ...searchData,  page: 1 });
      
    };

    useEffect(() => {
          //this is for table column creation
          if (!providerList) {
            axios
                .get(
                    PROVIDER_DATA_URL+'?standard_alerts=1',
                    { headers: { Authorization: "Bearer " + localStorage.getItem("txalert_token") } }
                )
                .then( ({ data }) => {
                     setProviderList(data.list);
                    const fData = data.list.map((d) => {
                        return {
                            dataIndex: d.provider_name +'_status',
                            key: d.provider_name,
                            title: d.provider_name,
                            ellipsis: true,
                            width: 50,
                            sorter: {
                                compare: (a, b) => {
                                    a = a[d.provider_name +'_status'] || '';
                                    b = b[d.provider_name +'_status'] || '';
                                    return a.localeCompare(b);
                                }
                            }
                        }
                    });
                   
                   // const temp = [...columns, ...fData];
                   //remove the lastcol(action col) and add provider status columns
                        //add action col as the last one
                        let last_col = columns.pop();
                       
                        const temp = [...columns, ...fData,last_col];
                    
                    setColumns(temp);
                })
                .catch(() => false);
        }
    }, []); //eslint-disable-line 

    const [columns, setColumns] = useState([
        {
            dataIndex: "descriptor_id",
            key: "descriptor_id",
            title: t("tables.fields.id"),
            fixed:"left",
            width: 30,
            ellipsis: true,
            sorter: {
                compare: (a, b) => a.descriptor_id - b.descriptor_id
            },

            render: (id, record) => (
                <a href={`/descriptor/${id}`} target="_blank" rel="noopener noreferrer">
                    {id}{" "}
                </a>
            )
        },
        {
            dataIndex: "descriptor_text",
            key: "descriptor_text",
            title: t("tables.fields.descriptor"),
            ellipsis: true,
            fixed:"left",
            width: 100,
            sorter: {
                compare: (a, b) => TextSorter(a.descriptor_text,b.descriptor_text)
            }
        },
        {
            dataIndex: "merchant_name",
            key: "merchant_name",
            title: t("tables.fields.merchant"),
            width: 50,
            ellipsis: true,
            sorter: {
                compare: (a, b) => TextSorter(a.merchant_name,b.merchant_name)
            }
        },
        {
            dataIndex: "descriptor_status",
            key: "descriptor_status",
            title: t("tables.fields.status"),
            width: 50,
            ellipsis: true,
            sorter: {
                compare: (a, b) => TextSorter(a.descriptor_status,b.descriptor_status)
            },
            render: (id, record) => (
                <span>
                    {record.descriptor_status === 0? "In Active":"Active"}
                </span>
            )
        }, {
            dataIndex: "action",
            title: t("tables.fields.action"),
            align: "left",
            fixed:"right",
            width: 100,
            render: (id, record) => (
                <Space size="middle">
                    <Button type="link" onClick={() => showModal(record.descriptor_id)} >
                      <FileSearchOutlined style={{ color: "rgba(0, 0, 0, 0.85)", fontSize: "18px" }} />
                    </Button>
                    <Button type="link" onClick={() => showEnrollModal(record.descriptor_id,'Descriptor:'+record.descriptor)} >
                    <EditOutlined style={{ color: "rgba(0, 0, 0, 0.85)", fontSize: "18px" }} />
                    </Button>
                </Space>
            ),
        
    }
       
    ].filter(item => !item.hidden));

    const AdvancedSearchForm = () => (
        <div className="search-wrap">
            <Card title={t("tables.actions.search")} size="small">
                <Form form={form} size="large" name="advanced_search" className="ant-advanced-search-form" onValuesChange={formValChange} onFinish={onFinish}>
                    <Row gutter={[16, 16]}>
                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                            <Form.Item name="descriptor">
                                <Input placeholder={t("tables.fields.descriptor")} />
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                            <Form.Item name="mid">
                                <Input placeholder={t("tables.fields.mid")} />
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }} xl={{ span: 5 }}>

                            <Form.Item name="status">
                               <TxalertEnrollmentStatusDropdown mode="multiple"  keyname="enrollment_status_id" valname="status"  placeholder={t("tables.fields.status")}
                                    style={{ width: "100%" }} />

                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[16, 16]}>
                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 10 }}>
                            <Form.Item name="merchant">
                                <TxalertMerchantListComp mode="multiple"  placeholder={t("contents.merchants.title")}
                                    style={{ width: "100%" }} />
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }} xl={{ span: 5 }}>

                            <Form.Item name="provider">
                                <TxalertProviderListComp mode="multiple" placeholder={t("tables.fields.providername")} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[16, 16]}>
                        <Col flex="auto"></Col>
                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6, offset: 12 }} lg={{ span: 4, offset: 0 }} xl={{ span: 3, offset: 4 }}>
                            <Form.Item>
                                <Button type="secondary" onClick={() => clearAll()} block>
                                    {t("tables.actions.clear_all")}
                                </Button>
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 4 }} xl={{ span: 4 }}>
                            <Form.Item>
                                <Button type="primary" htmlType="submit" block>
                                    <SearchOutlined /> {t("tables.actions.search")}
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Card>
        </div>
    );

    const getDataSource = (datalist) => (datalist && Array.isArray(datalist) ? datalist : []);

    const getHeaderTable = () => (
        <div className="custom-table--header d-flex justify-content-between align-items-center">
            <h3 className="table-title">{t("contents.descriptors.title")}</h3>
            <Button type="link" onClick={() => showModal()} style={{ marginLeft: "auto" }}>
                <PlusOutlined /> {t("contents.txalert_admin.form.new_descriptor")}
            </Button>
            <ExportModalV3
                t={t}
                keyname="export_all"
                title={t("tables.actions.export")}
                data={requestData}
                data_url={DATA_URL_EXPORT}
                headers={exportHeaders}
                fieldHeadersOptional={exportHeadersOptional}
                txalert_token = {true}
                filename={`alertdescriptors_${moment().format("YYMMDD_HHmmss_SSSS")}.csv`}
                style={{ marginLeft: 8 }}
            />
            <Button type="link" onClick={() => showUploadModal()} style={{ marginLeft:8 }}>
                <PlusOutlined /> {t("contents.txalert_admin.import_descriptor")}
            </Button>
        </div>
    );

    const mergeColumns = columns.map((col, index) => ({
        ...col,
        onHeaderCell: (column) => ({
            width: column.width,
            onResize: (_, { size }) => {
                const newColumns = [...columns];
                newColumns[index] = { ...newColumns[index], width: size.width };
                setColumns(newColumns);
            },
        }),
    }));


    const exportHeadersOptional = [];
    const exportHeaders = [
        { label: "ID", key: "dv.descriptor_variation_id" },
        { label: "Descriptor", key: "dv.descriptor" },
        { label: "Merchant Name", key: "m.name" },
        { label: "Descriptor Status", key: "CASE WHEN d.status = 1 THEN 'Active' ELSE 'Inactive' END" }
    ];


    return (
        <div>
            <PageHeader title={t("contents.descriptors.title")}></PageHeader>
            {AdvancedSearchForm()}
            <Table
                rowKey="descriptor_variation_id"
                size="small"
                title={() => getHeaderTable()}
                loading={isLoading}
                className="table-v3 custom-pagination"
                components={{
                    header: {
                        cell: ColumnResizable,
                    },
                }}
                columns={mergeColumns}
                onChange={handleChange}
                sortDirections={["ascend", "descend", "ascend"]}
                dataSource={data.datalist && Array.isArray(data.datalist) ? getDataSource(data.datalist) : []}
                pagination={CustomPaginationV2({ totalResult, currentPage, requestData, setRequestData, setCurrentPage })}
                scroll={{ x: 1500 }}
            />
            <TxalertDescriptorModal t={t} id={id} setId={setId} isOpen={isOpen} setIsOpen={setIsOpen} fetchList={getList}/>
            <TxalertUpdateEnrollmentModal t={t} type="descriptor"  isEnrollOpen={isEnrollOpen} setIsEnrollOpen={setIsEnrollOpen} enrollurl={enrollUrl} title={title} fetchList={getList} id={id} />
            <TxalertDescriptorImport t={t} isUploadOpen={isUploadOpen} setIsUploadOpen={setIsUploadOpen} fetchList={getList}/>
        </div>
    );
};

export default Txalert_DescriptorList;
