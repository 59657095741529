import React, { useState, useEffect } from "react";
import { OI_URL } from "../../config/config";
import { Modal, Button, Form, Input, Select, Alert } from "antd";
import axios from "axios";
import Swal from "sweetalert2";
import TxFindAccessToken from "../../helper/TxFindAccessToken";

const { Option } = Select;

const AddBinCaidModal = (props) => {
    const { t, fetchBinCaids, setIsAddBinCaidModalVisible, isAddBinCaidModalVisible } = props;
    const [formData, setFormData] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [form] = Form.useForm();
    const initFormData = {
        bin: "",
        caid: "",
        mcc: "",
        status: "",
        referenceNumber: null
    };

    const layout = {
        labelCol: { span: 4 },
        wrapperCol: { span: 18 },
    };

    useEffect(() => {
        formValInit();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const formValInit = () => {
        setFormData(initFormData);
    };

    const handleOk = () => {
        onFinish();
        setIsSubmitting(true);
    };

    const formValChange = (chgV, allV) => {
        setFormData(allV);
        form.setFieldsValue(allV);
    };

    const onFinish = async () => {
        const apiUrl = OI_URL + `/api/v1/txshield/bincaid`;
        try {
            const { data } = await axios.post(apiUrl, formData, { headers: { Authorization: "Bearer " + await TxFindAccessToken() } });
            if (data.status === "ok") {
                Swal.fire({
                    icon: "success",
                    title: t("contents.oi.bincaid_added"),
                    showConfirmButton: false,
                    timer: 2000,
                    onClose: () => {
                        fetchBinCaids();
                        setIsSubmitting(false);
                        form.resetFields();
                        handleCancel();
                    },
                });
            } else {
                Swal.fire({
                    icon: "error",
                    title: t("login.dialogs.catch"),
                    text: data.msg ? data.msg : "",
                    showConfirmButton: true,
                });
                setIsSubmitting(false);
            }
        } catch (error) {
            Swal.fire({
                icon: "error",
                title: t("login.dialogs.catch"),
                text: error.response ? error.response.data.msg : "",
                showConfirmButton: true,
            });
            setIsSubmitting(false);
        }
    };

    const isOkButtonProps = () => !formData || !formData.bin || !formData.caid || !formData.mcc || !formData.status;

    const handleCancel = () => {
        setIsAddBinCaidModalVisible(false);
    };

    return (
        <>
            <Modal
                title={t("contents.oi.new_bincaid")}
                width={600}
                visible={isAddBinCaidModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={[
                    <Button key="back" onClick={() => handleCancel()}>
                        {t("contents.chargebacks.form.cancel")}
                    </Button>,
                    <Button key="submit" type="primary" loading={isSubmitting} disabled={isOkButtonProps()} onClick={() => handleOk()}>
                        {isSubmitting ? t("contents.chargebacks.form.submitting") : t("contents.chargebacks.form.submit")}
                    </Button>,
                ]}
            >
                <Form form={form} {...layout} name="new_bincaid" onValuesChange={formValChange}>
                    <Alert
                        style={{ marginBottom: 25 }}
                        message={t("contents.oi.form.addbincaidwarning")}
                        type="warning"
                        showIcon
                    />
                    <Form.Item name="bin" label={t("contents.oi.form.bin")} rules={[{ required: true }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item name="caid" label={t("contents.oi.form.caid")} rules={[{ required: true }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item name="mcc" label="MCC" rules={[{ required: true }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item name="status" label={t("contents.oi.form.status")} rules={[{ required: true }]}>
                        <Select placeholder={t("contents.merchants.form.please_select")}>
                            <Option value="requested">REQUESTED</Option>
                            <Option value="pending">PENDING</Option>
                            <Option value="enrolled">ENROLLED</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item name="referenceNumber" label={t("contents.oi.form.referenceNumber")}>
                        <Input />
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};

export default AddBinCaidModal;