import React, { useEffect, useState } from "react";
import { Card, Row, Col, Table, Form, Input, Select, PageHeader, Button } from "antd";
import { PlusOutlined, FileSearchOutlined } from "@ant-design/icons";
import { OI_URL } from "../../config/config";
import axios from "axios";
import moment from "moment";
import Swal from "sweetalert2";
import AddBinCaidModal from "./AddBinCaidModal";
import EditBinCaidModal from "./EditBinCaidModal";
import TxFindAccessToken from "../../helper/TxFindAccessToken";

const { Option } = Select;

const Descriptor = (props) => {
    const { t } = props; // eslint-disable-line
    const descriptorId = props.match.params.id;
    const [descriptor, setDescriptor] = useState(null);
    const [merchantList, setMerchantList] = useState(null);
    const [bincaidList, setBincaidList] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isBinCaidLoading, setIsBinCaidLoading] = useState(false);
    const [isUpdating, setIsUpdating] = useState(false);
    const [isAddBinCaidModalVisible, setIsAddBinCaidModalVisible] = useState(false);
    const [isEditBinCaidModalVisible, setIsEditBinCaidModalVisible] = useState(false);
    const [bincaidData, setBincaidData] = useState(null);
 
    const itemLayout = {
        labelAlign: "left",
        labelCol: { span: 10, offset: 0 },
    };

    const [form] = Form.useForm();

    useEffect(() => {
        setIsBinCaidLoading(false);
        if(!merchantList) {
            getMerchantList();
        }
        if(descriptorId) {
            getDescriptorDetails();
        }        
        if(!bincaidList) {
            getBincaidList();
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const getDescriptorDetails = async () => {
        setIsLoading(true);
        const apiUrl = OI_URL + `/api/v1/txshield/descriptor/${descriptorId}/details`;
        axios
            .post(apiUrl, "", { headers: { Authorization: "Bearer " + await TxFindAccessToken() } })
            .then(({ data }) => {
                setDescriptor(data);
                form.setFieldsValue(data);
                setIsLoading(false);
            })
            .catch((error) => {
                Swal.fire({
                    icon: "error",
                    title: t("login.dialogs.catch"),
                    text: error.response ? error.response.data.msg : "",
                    showConfirmButton: true,
                });
            });
    };

    const getMerchantList = async () => {
        setIsLoading(true);
        const apiUrl = OI_URL + `/api/v1/txshield/merchants`;
        axios
            .post(apiUrl, "", { headers: { Authorization: "Bearer " + await TxFindAccessToken() } })
            .then(({ data }) => {
                setMerchantList(data.list);
                setIsLoading(false);
            })
            .catch((error) => {
                Swal.fire({
                    icon: "error",
                    title: t("login.dialogs.catch"),
                    text: error.response ? error.response.data.msg : "",
                    showConfirmButton: true,
                });
            });
    };

    const getBincaidList = async () => {
        setIsBinCaidLoading(true);
        const apiUrl = OI_URL + `/api/v1/txshield/descriptor/${descriptorId}/bincaidlist`;
        axios
            .post(apiUrl, "", { headers: { Authorization: "Bearer " + await TxFindAccessToken() } })
            .then(({ data }) => {
                setBincaidList(data);
                setIsBinCaidLoading(false);
            })
            .catch((error) => {
                Swal.fire({
                    icon: "error",
                    title: t("login.dialogs.catch"),
                    text: error.response ? error.response.data.msg : "",
                    showConfirmButton: true,
                });
            });
    };

    const handleOk = () => {
        setIsUpdating(true);
        onFinish();
    };

    const onFinish = async () => {
        const apiUrl = OI_URL + `/api/v1/txshield/descriptor/${descriptorId}/edit`;

        axios
            .post(apiUrl, descriptor, { headers: { Authorization: "Bearer " + await TxFindAccessToken() } })
            .then(({ data }) => {
                setIsUpdating(false);
                if (data.status === "ok") {
                    Swal.fire({
                        icon: "success",
                        title: t("contents.oi.descriptor_updated"),
                        showConfirmButton: false,
                        timer: 2000,
                        onClose: () => {
                            setDescriptor(null);
                            getDescriptorDetails();
                        },
                    });
                } else {
                    Swal.fire({
                        icon: "error",
                        title: t("login.dialogs.catch"),
                        text: data.msg ? data.msg : "",
                        showConfirmButton: true,
                    });
                }
            })
            .catch((error) => {
                setIsUpdating(false);
                Swal.fire({
                    icon: "error",
                    title: t("login.dialogs.catch"),
                    text: error.response ? error.response.data.msg : "",
                    showConfirmButton: true,
                });
            });
    };

    const formValChange = (chgV, allV) => {
        setDescriptor({ ...descriptor, ...allV });
    };

    const columns = [
        {
            dataIndex: "bin",
            key: "bin",
            title: "BIN"
        },
        {
            dataIndex: "caid",
            key: "caid",
            title: "CAID"
        },
        {
            dataIndex: "mcc",
            key: "mcc",
            title: "MCC"
        },
        {
            dataIndex: "status",
            key: "status",
            title: t("tables.fields.status"),
            render: (text, record) => (
                <span>{record.status.toUpperCase()}</span>
            )
        },
        {
            dataIndex: "requestedDate",
            key: "requestedDate",
            title: t("tables.fields.requestedDate"),
            render: (requestedDate) => moment(requestedDate).format("YYYY-MM-DD HH:mm:ss")
        },
        {
            dataIndex: "updatedDate",
            key: "updatedDate",
            title: t("tables.fields.updatedDate"),
            render: (updatedDate) => moment(updatedDate).format("YYYY-MM-DD HH:mm:ss")
        },
        {
            dataIndex: "referenceNumber",
            key: "referenceNumber",
            title: t("tables.fields.referenceNumber"),
            render: (text, record) => (
                <span>{record.referenceNumber ? record.referenceNumber : '-'}</span>
            )
        },
        {
            dataIndex: "action",
            title: t("tables.fields.action"),
            align: "left",
            fixed: "right",
            width: 120,
            render: (text, record) => (
                <Button type="link" onClick={() => showEditBinCaidModal(record)}>
                    <FileSearchOutlined style={{ color: "rgba(0, 0, 0, 0.85)", fontSize: "18px" }} />
                </Button>
            )
        }
    ];

    const getFormatDate = (date) => <span className="ant-form-text">{Date.parse(date) ? moment(date).format("YYYY-MM-DD HH:mm:ss") : ""}</span>;

    const showAddBinCaidModal = () => {
        setIsAddBinCaidModalVisible(true);
    };

    const showEditBinCaidModal = (data) => {
        setBincaidData(data);
        setIsEditBinCaidModalVisible(true);
    };

    const fetchBinCaid = () => getBincaidList();

    const DescriptorDetails = () => (
        <>
            <PageHeader className="site-page-header" title={t("contents.oi.descriptor_details") + " (" + descriptorId + ")"}></PageHeader>
            <Card loading={isLoading && !descriptor} className="custom-form--card" title="Details">
                <Form form={form} {...itemLayout} name="descriptor" onValuesChange={formValChange}>
                    <Row gutter={[36, 36]} style={{ marginBottom: "1rem" }}>
                        <Col span={12}>
                            <Form.Item name="title" label={t("contents.oi.form.title")} rules={[{ required: true }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item name="merchantId" label={t("contents.oi.form.merchant")} rules={[{ required: true }]}>
                                <Select placeholder={t("contents.merchants.form.please_select")}>
                                    {merchantList
                                        ? merchantList.map((item) => (
                                                <Option key={item.merchantId} value={item.merchantId}>
                                                    {item.name}
                                                </Option>
                                            ))
                                        : null}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name="active" label={t("contents.oi.form.status")}>
                                <Select placeholder={t("contents.merchants.form.please_select")}>
                                    <Option value={true}>Active</Option>
                                    <Option value={false}>Inactive</Option>
                                </Select>
                            </Form.Item>
                            <Form.Item name="title" label={t("contents.oi.form.datecreated")}>
                                <span className="ant-form-text">{getFormatDate(descriptor ? descriptor.dateCreated : null )}</span>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Button key="submit" type="primary" onClick={() => handleOk()} disabled={isUpdating}>
                                {isUpdating ? t("contents.oi.form.updating") : t("contents.oi.form.update")}
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </Card>
            <AddBinCaidModal t={t} fetchBinCaid={fetchBinCaid} setIsAddBinCaidModalVisible={setIsAddBinCaidModalVisible} isAddBinCaidModalVisible={isAddBinCaidModalVisible} descriptorId={descriptorId} />
            {bincaidData ? <EditBinCaidModal t={t} fetchBinCaid={fetchBinCaid} setBincaidData={setBincaidData} setIsEditBinCaidModalVisible={setIsEditBinCaidModalVisible} isEditBinCaidModalVisible={isEditBinCaidModalVisible} bincaidData={bincaidData}/> : null}
            <Card loading={isBinCaidLoading} className="custom-form--card" title="" extra={<Button onClick={showAddBinCaidModal} type="secondary" style={{ marginLeft: "auto" }}><PlusOutlined /> {t("contents.oi.new_bincaid")}</Button>}>
                <Table
                    rowKey="caid"
                    size="small"
                    bordered={false}
                    columns={columns}
                    dataSource={bincaidList ? bincaidList : []}
                    pagination={false}
                />
            </Card>
        </>
    );

    return DescriptorDetails();
};

export default Descriptor;