import React, { useState, useEffect } from "react";
import { PageHeader, Card, Form, Row, Col, Button, Typography, Tabs } from "antd";
import DraggableModal from "../../helper/DraggableModal";
import GroupAccess from "../../helper/GroupAccess";
import DocumentUpload from "./DocumentUpload";
import DocumentList from "./DocumentList";
import AlertCredits from "../Alerts/AlertCredits";
import { CBM_URL } from "../../config/config";
import CCMask from "../../helper/CCMask";
import FormatAmount from "../../helper/FormatAmount";
import axios from "axios";
import moment from "moment";
import Swal from "sweetalert2";


const { Paragraph } = Typography;
const { TabPane } = Tabs;

const Chargeback = (props) => {
    const [chargeback, setChargeback] = useState(null);
    const [upload, setUpload] = useState({ mid: null, pid: null, chargebackid: null, merchant_transaction_id: null });
    const [loading, setLoading] = useState(true);
    const [modal, setModal] = useState({ title: "", content: "" });
    const [isReady, setIsReady] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isDocumentUpload, setIsDocumentUpload] = useState(false);
    const [merchant, setMerchant] = useState([]);
    const { t } = props;

    const formItemLayout = {
        labelCol: { span: 9 },
        wrapperCol: { span: 15 },
    };

    useEffect(() => {
        if (!isReady) {
            setIsReady(true);
            const [mid] = window.atob(props.match.params.id).split(",");  //removed [pid,chargebackid] due to unused var
          
            getMerchantDetail(mid);
           
        }
    }, []); // eslint-disable-line

    useEffect(() => {        
        if (merchant) {            
            doFetch();
            }
        
    }, [merchant]); // eslint-disable-line
    const showModal = (params) => {
        setModal(params);
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const getMerchantDetail = async (mid) => {
        if (!mid) {
            return [];
        }
         await axios
            .get(CBM_URL + `/api/v1/merchant/${mid}/details`,{ headers: { Authorization: "Bearer " + localStorage.getItem("cbm_token") }})
          //  .then(({ data }) => data)
           // .catch((error) => false);
            .then(({ data }) => {
               
                if(data.status === 'ok')
                {
                    setMerchant(data.details);
                    
                }else{
                    console.log(data.msg);
                    
                }
            })
            .catch((error) => {
                console.log(error.response.data.msg);
                
            });
            
    };



    const getChargeback = async (mid, pid, chargebackid) => {
        const apiUrl = `/api/v1/chargebacks/${chargebackid}/details`;

        await axios
            .get(CBM_URL + apiUrl, { headers: { Authorization: "Bearer " + localStorage.getItem("cbm_token") }})
            .then(({ data }) => {
              
                setChargeback(data.details);
                setLoading(false);
            })
            .catch((error) => {
                console.log(error.response);
                setLoading(false);
                Swal.fire({
                    icon: "error",
                    title: error.response ? error.response.data.error_msg.msg : t("login.dialogs.catch"),
                    showConfirmButton: true,
                });
            });
    };

   

    const doFetch = () => {
        const [mid, pid, chargebackid] = window.atob(props.match.params.id).split(",");
            
        getChargeback(mid, pid, chargebackid);
        
        setUpload({ ...upload, mid, pid, chargebackid });
    };

    const IsJsonString = (str) => {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    };

    const getButtonModal = (params, buttonText = "") => (
        <div className="ant-form--button-modal">
            <Button size="small" onClick={() => showModal(params)}>
                {buttonText ? buttonText : t("tables.actions.view")}
            </Button>
        </div>
    );

    const getPlainText = (text) => <div className="ant-form--text">{text}</div>;

  

    const getFormatDate = (date) => <div className="ant-form--text">{Date.parse(date) ? moment(date).format("YYYY-MM-DD HH:mm:ss") : ""}</div>;

    const getChargebackDetailsForm = () => {
        let fields = [
            {
                label: t("contents.chargebacks.form.chargeback_date"),
                name: "arn",
                value: getPlainText(chargeback.chargeback_date),
            },
            {
                label: t("contents.chargebacks.form.merchant_transaction_id"),
                name: "merchant_transaction_id",
                value: getPlainText(chargeback.merchant_transaction_id),
            },
            {
                label: t("contents.chargebacks.form.merchant_id"),
                name: "merchant_id",
                value: getPlainText(chargeback.merchant_id),
            },
            {
                label: t("contents.chargebacks.form.card_number"),
                name: "card_number",
                value: getPlainText(CCMask(chargeback.card_number)),
            },
            {
                label: t("contents.chargebacks.form.transaction_date"),
                name: "payment_transaction_date",
                value: getFormatDate(chargeback.payment_transaction_date),
            },
            {
                label: t("contents.chargebacks.form.descriptor"),
                name: "descriptor",
                value: getPlainText(chargeback.descriptor),
            },
            {
                label: t("contents.chargebacks.form.amount"),
                name: "amount",
                value: getPlainText(FormatAmount(chargeback.amount, chargeback.currency)),
            },
            {
                label: t("contents.chargebacks.form.currency"),
                name: "currency",
                value: getPlainText(chargeback.currency),
            },
            {
                label: t("contents.chargebacks.form.arn"),
                name: "arn",
                value: getPlainText(chargeback.arn),
            }
        ];
        if (GroupAccess("SYSADMIN")) {
            fields = [
                ...fields,
                {
                    label: t("contents.chargebacks.form.merchant"),
                    name: "merchant",
                    value: getPlainText(chargeback.merchant),
                },
                {
                    label: t("contents.chargebacks.form.provider"),
                    name: "provider",
                    value: getPlainText(chargeback.provider),
                },
            ];
        }
        fields = [
            ...fields,
            {
                label: t("contents.chargebacks.form.alert_id"),
                name: "alert_id",
                value: getPlainText(chargeback.alert_id),
            },
            {
                label: t("contents.chargebacks.form.response_message"),
                name: "chargeback_reason",
                value: getPlainText(chargeback.chargeback_reason),
            },
            {
                label: t("contents.chargebacks.form.created_date"),
                name: "created_date",
                value: getFormatDate(chargeback.created_date),
            },
            {
                label: t("contents.chargebacks.form.chargebackReference"),
                name: "chargebackReference",
                value: getPlainText(chargeback.chargebackReference),
            },
            {
                label: t("contents.chargebacks.form.base64_cbdata"),
                name: "base64_cbdata",
                value: chargeback.base64_cbdata ? getButtonModal({ title: t("contents.chargebacks.form.base64_cbdata"), content: atob(chargeback.base64_cbdata) }) : null,
            },
        ];

        return showCardInfo(fields);
    };

    const getModalComponent = () => (
        <DraggableModal
            name={modal.title}
            visible={isModalVisible}
            onOk={handleOk}
            onCancel={handleCancel}
            width={800}
            footer={[
                <Button key="submit" type="primary" onClick={handleOk}>
                    {t("tables.actions.ok")}
                </Button>,
            ]}
            context={
                <Typography>
                    <Paragraph className="well">
                        {IsJsonString(modal.content) ? JSON.stringify(JSON.parse(modal.content), null, "\t") : modal.content}
                    </Paragraph>
                </Typography>
            }
        />
    );

    const showCardInfo = (fields) => (
        <Card
            
            style={{ marginBottom: "1rem" }}
            title={t("contents.chargebacks.chargeback_details")}
        >
            <Form size="small" {...formItemLayout}>
                <Row gutter={24}>
                    {fields.map((field,idx) => (
                        <Col key={idx} xs={24} md={12} offset={field.offset}>
                            <Form.Item className="ant-form--item" label={<label className="ant-form--label">{field.label}</label>} tooltip={field.tooltip}>
                                {field.value}
                            </Form.Item>
                        </Col>
                    ))}
                </Row>
            </Form>
        </Card>
    );

    return (
        <div>
            <PageHeader
                className="site-page-header"
                title={`${t("contents.chargebacks.chargeback")} #${chargeback ? chargeback.merchant_transaction_id : ""}`}
            ></PageHeader>
            {!loading && chargeback ? (
                <>
                    {getModalComponent()}
                    {getChargebackDetailsForm()}

                    <Tabs type="card">
                        <TabPane tab={t("contents.chargebacks.chargeback_documents")} key="chargebackDocuments">
                            <PageHeader
                                className="sub-header"
                                title={t("contents.chargebacks.chargeback_documents")}
                                extra={
                                    <Button
                                        type="primary"
                                        onClick={() => {
                                            setUpload({ ...upload, merchant_transaction_id: chargeback.merchant_transaction_id });
                                            setIsDocumentUpload(true);
                                        }}
                                    >
                                        {t("tables.actions.document_upload")}
                                    </Button>
                                }
                            ></PageHeader>
                            <DocumentList 
                            t={t} 
                            documents={[]} 
                            merchant={merchant} 
                            upload={upload} 
                            isUseModal={false}/>
                            <DocumentUpload
                                t={t}
                                upload={upload}
                                isModalVisible={isDocumentUpload}
                                setIsModalVisible={setIsDocumentUpload}
                                merchant={merchant}
                                fetchChargeback={doFetch}
                            />
                        </TabPane>
                        {chargeback.alert_id ? (
                            <TabPane tab={t("contents.alerts.alert_credits")} key="alertCredits">
                                <AlertCredits t={t} alertid={chargeback.alert_id} />
                            </TabPane>
                        ) : null}
                    </Tabs>
                </>
            ) : null}
        </div>
    );
};

export default Chargeback;
