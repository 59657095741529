import React, { useState, useEffect } from "react";
import { PageHeader, Tabs } from "antd";
import { API_3DS_TX, THREEDS_URL } from "../../config/config";
import axios from "axios";
import TxDetails from "./Details/TxDetails";
import ThreeDSDetails from "./Details/ThreeDSDetails";
import TxHistory from "./Details/TxHistory";
import { useLocation } from 'react-router-dom';

const { TabPane } = Tabs;

const Transaction = (props) => {
    const [data, setData] = useState({});
    const [currency, setCurrency] = useState(null);
    const [loading, setLoading] = useState(true);
    const txid = props.match.params.id;
    const { t } = props;

    const location = useLocation();
    const threeds_shield = location.pathname.includes('3ds_transaction');

    useEffect(() => {
        getTransactionDetail();
    }, []); // eslint-disable-line

    const getTransactionDetail = async () => {        
        const api_url = (threeds_shield) ? API_3DS_TX + `/transaction/${txid}/details` : THREEDS_URL + `/api/v1/3ds/transaction/${txid}/details`;
        const token = (threeds_shield) ? localStorage.getItem("access_token") : localStorage.getItem("threeds_token");
        setLoading(true);

        await axios
            .post(api_url, {}, { headers: { Authorization: "Bearer " + token } })
            .then(({ data }) => {
                setData(data);
                setCurrency(data ? data.details ? data.details.currency_code : null : null);
                setLoading(false);
            })
            .catch((error) => console.log(error));
    };

    return (
        <>
            <PageHeader className="site-page-header" title={t("contents.3dsecure.details") + " (" + txid + ")" }> </PageHeader>
            <Tabs type="card" defaultActiveKey="tx_details" className="custom-tabs-card">
                <TabPane tab={t('contents.transactions.tabs.tx_details')} key="tx_details">
                    <TxDetails t={t} loading={loading} data={data ? data : null} />
                </TabPane>
                <TabPane tab={t('contents.3dsecure.tabs.3DS_details')} key="threeds_details">
                    <ThreeDSDetails t={t} loading={loading} data={data ? data : null} />
                </TabPane>
            </Tabs>
            <TxHistory t={t} loading={loading} data={data ? data.tx : []} currency={currency} />
        </>
    );
};

export default Transaction;