import React, { useEffect, useState } from "react";
import {Tag, Table} from "antd";
import {CaretUpOutlined,CaretDownOutlined} from "@ant-design/icons";

const BreakdownByCondition = (props) => {
    const { t, statsData, detailsBoxTitle } = props; // eslint-disable-line
    const [groupStatsData,setGroupStatsData] = useState([]);
    const [visibleCols, setVisibleCols] = useState([]);

    useEffect(() => {
        const data = [];

        if (Object.keys(statsData.conditioncode).length) {
            setVisibleCols(Object.keys(statsData.conditioncode[0]));
        }

        Object.keys(statsData.conditioncode).forEach((item) => {
            const k = parseFloat(statsData.conditioncode[item]?.dispute_condition_code)>0 ? statsData.conditioncode[item]?.dispute_condition_code : 0;
            const d = {
                key: item,
                condition_code: statsData.conditioncode[item].dispute_condition_code,
                alert_type: statsData.conditioncode[item]?.alert_type,
                issuer: statsData.conditioncode[item]?.issuer,
                providername: statsData.conditioncode[item]?.providername,
                card_type: statsData.conditioncode[item]?.card_type,
                count: statsData.conditioncode[item].count
            };
            if (k in data) {
                data[k].total = data[k].total + parseInt(d.count);
                data[k].innerrow.push(d);
            } else {
                data[k] = [];
                data[k].key = item;
                data[k].condition_code = statsData.conditioncode[item].dispute_condition_code;
                data[k].reason = statsData.conditioncode[item].reason;
                data[k].alert_type = statsData.conditioncode[item]?.alert_type;
                data[k].providername = statsData.conditioncode[item]?.providername;
                data[k].card_type = statsData.conditioncode[item]?.card_type;
                data[k].issuer = statsData.conditioncode[item]?.issuer;
                data[k].count = statsData.conditioncode[item].count;
                data[k].parent = true;
                data[k].total = parseInt(d.count);
                data[k].innerrow = [d];
            }
        });
        setGroupStatsData(Object.values(data));
    }, [statsData]);

    const columns = [
        {
          title: t("contents.alerts.dispute_condition_code"),
          key: 'condition_code',
          width: '70%',
          render: (record) => (record.condition_code>0 ? record.condition_code+ (record.reason!=='' ? ` (`+record.reason+`)` : ``) : <Tag color="error">Empty</Tag>)
        },
        {
          title: "Total Count",
          dataIndex: 'total',
          key: 'total',
          width: '30%',
        },
      ];

   /* const expandedColumns = [
        {
          title: t("contents.alerts.alert_type"),
          dataIndex: 'alert_type',
          key: 'alert_type',
          width: '40%',
        },
        {
          title: t("contents.alerts.issuername"),
          dataIndex: 'issuer',
          key: 'issuer',
          width: '40%',
        },
        {
          title: t("contents.alerts.count"),
          dataIndex: 'count',
          key: 'count',
          width: '20%',
        },
      ]; */

    const customExpandIcon = (props) => {
        if (props.expanded) {
            return <button style={{ color: 'black',backgroundColor: 'transparent',
            border: 'none',textDecoration: 'underline',cursor: 'pointer'}} onClick={e => {
                props.onExpand(props.record, e);
            }}><CaretUpOutlined /></button>
        } else {
            return <button style={{ color: 'black',backgroundColor: 'transparent',
            border: 'none',textDecoration: 'underline',cursor: 'pointer'}} onClick={e => {
                props.onExpand(props.record, e);
            }}><CaretDownOutlined /></button>
        }
    };

    const hiddenColumn = (col) => {
        return (visibleCols.indexOf(col) === -1);
    }

    const innerTable = (innerData) => (
        <table style={{ tableLayout: "auto", width: "100%" }}>
            <thead className="ant-table-thead">
                <tr>
                    <th className="ant-table-cell" width="20%" hidden = {hiddenColumn('alert_type')}>
                        {t("contents.alerts.alert_type")}
                    </th>
                    <th className="ant-table-cell" width="20%" hidden = {hiddenColumn('issuer')}>
                        {t("contents.alerts.issuername")}
                    </th>
                    <th className="ant-table-cell" width="20%" hidden = {hiddenColumn('providername')}>
                    {t("contents.alerts.providername")}
                    </th>
                    <th className="ant-table-cell" width="20%" hidden = {hiddenColumn('card_type')}>
                    {t("contents.alerts.cardtype")}
                    </th>
                    <th className="ant-table-cell" width="20%">
                        <div className="text-center">{t("contents.alerts.count")}</div>
                    </th>
                </tr>
            </thead>
            <tbody className="ant-table-tbody">
                {innerData
                    ? Object.keys(innerData).map((item) => (
                        // <tr><td>Key: {JSON.stringify(item)}</td><td>Item: {JSON.stringify(innerData[item])}</td></tr>
                            <tr className="ant-table-row ant-table-row-level-0" key={item}>
                                <td className="ant-table-cell" align="left" hidden = {hiddenColumn('alert_type')}>
                                    {(innerData[item]?.alert_type ? innerData[item]?.alert_type : <Tag color="error">Empty</Tag>)}
                                </td>
                                <td className="ant-table-cell" align="left" hidden = {hiddenColumn('issuer')}>
                                    {(innerData[item]?.issuer ? innerData[item]?.issuer : <Tag color="error">Empty</Tag>)}
                                </td>
                                <td className="ant-table-cell" align="left" hidden = {hiddenColumn('providername')}>
                                    {(innerData[item]?.providername ? innerData[item]?.providername : <Tag color="error">Empty</Tag>)}
                                </td>
                                <td className="ant-table-cell" align="left" hidden = {hiddenColumn('card_type')}>
                                    {(innerData[item]?.card_type ? innerData[item]?.card_type : <Tag color="error">Empty</Tag>)}
                                </td>
                                <td className="ant-table-cell"  align="center">{innerData[item]?.count}</td>
                            </tr>
                        ))
                    : null}
            </tbody>
        </table>
    );

    const getHeaderTable = () => (
        <div className="custom-table--header d-flex justify-content-between align-items-center">
            <h3 className="table-title">{t(`contents.alerts.`+(['reason','conditioncode'].indexOf(detailsBoxTitle)!== -1 ? 'dispute_condition_code' : detailsBoxTitle))}</h3>
        </div>
    );

    const getDetails = () => (
        <div className="table-v3">
            <div className="ant-table ant-table-small">
                {/* <div className="ant-table-title">
                    <div className="custom-table--header d-flex justify-content-between align-items-center">
                        <h3 className="table-title">{t(`contents.alerts.`+(['reason','conditioncode'].indexOf(detailsBoxTitle)!=-1 ? 'dispute_condition_code' : detailsBoxTitle))}</h3>
                    </div>
                </div> */}
                <div className="ant-table-container">
                    <div className="ant-table-content">
                        <Table style={{ tableLayout: "auto", width: "100%" }}
                            columns={columns}
                            title={() => getHeaderTable()}
                            dataSource={groupStatsData}
                            expandIcon={(props) => customExpandIcon(props)}
                            expandRowByClick={true}
                            expandedRowRender={(record) => innerTable(record.innerrow)}
                            pagination={false} 
                        />
                    </div>
                </div>
            </div>
        </div>
    );

    return getDetails();
};

export default BreakdownByCondition;
