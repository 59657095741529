import { OI_API_ACCESS_TOKEN } from "../config/config";
// import JWT from "jsonwebtoken";
// import moment from "moment";
// import md5 from "md5";
import DecodeToken from "./DecodeToken";

const TxFindAccessToken = async () => {
    const token = OI_API_ACCESS_TOKEN ;
    const decoded_token = await DecodeToken(token);
    // let payload = {
    //     sign: md5(secret_key),
    //     exp: moment().add(30, 'seconds').valueOf()
    // };
    // let accessToken = JWT.sign(payload, token);
    return decoded_token;
}

export default TxFindAccessToken;