import React, { useState, useEffect } from "react";
import { Table, PageHeader, Card, Form, Select, Row, Col, Button, Space, Input } from "antd";
import { SearchOutlined, FileSearchOutlined ,PlusOutlined} from "@ant-design/icons";
import CustomPaginationV2 from "../../../helper/Table/CustomPaginationV2";
import { API_TXALERT2 } from "../../../config/config";
import ColumnResizable from "../../../helper/Table/ColumnResizable";
import MergeColumns from "../../../helper/Table/MergeColumns";
import DataFetchHookTxAlert from "../../../helper/DataFetchHookTxAlert";
import TxalertERProviderLogin from "./ERProviderLogin";
import TextSorter from "../../../helper/Table/TextSorter";
import TxalertMerchantListDropdown from "../Components/MerchantListDropdown";
import ExportModalV3 from "../../../helper/ExportModalV3";
import moment from "moment";

const DATA_URL = API_TXALERT2 + "/express_resolve_gateway/login";
const DATA_URL_EXPORT = API_TXALERT2 + "/express_resolve_gateway/login/reports";
const { Option } = Select;

const Txalert_ERProviderLoginList = (props) => {
    const [{ data, totalResult, isLoading, isError, errorMsg, requestData, isUpdated }, setUrl, setRequestData] = DataFetchHookTxAlert(DATA_URL,"GET", localStorage.getItem("txalert_token")); // eslint-disable-line
    const [formData, setFormData] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    const [id, setId] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const { t } = props;

    const initFormData = {
        keyword: "",
        field: null,
        //  order: null,
        merchant_id:null
    };

    const [form] = Form.useForm();

    useEffect(() => {
        
        formValInit();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const getStatusList = () => {
        
        let searchData ={};
        setRequestData({ ...requestData, ...searchData,  page: 1 });
      
    };

    const showModal = (selectedid) =>{
        setId(selectedid);
        setIsOpen(true);
        
      };
    

    const formValInit = () => {
        setFormData(initFormData);
        form.setFieldsValue(initFormData);
    };

    const clearAll = () => {
        setCurrentPage(1);
        setFormData(initFormData);
        setRequestData({});
        form.setFieldsValue(initFormData);
    };

    const formValChange = (chgV, allV) => {
        setFormData(allV);
    };

    

    const onFinish = () => {
        let searchData = {};
        if (formData) {
            let searchkeyword = formData.keyword ;
            searchkeyword = searchkeyword? searchkeyword.toString().trim() : null //convert to string, if dropdown
            searchData = {
                [formData.field]: searchkeyword,
            };
            if(formData.merchant_id)
            {
                searchData = {...searchData,merchant_id:formData.merchant_id};
            }
        }
        
        // Reset Page Index to 1
        setCurrentPage(1);
        setRequestData({ ...requestData, ...searchData, page: 1 });
    };

    const columns = [
        {
            dataIndex: "express_resolve_gateway_login_id",
            key: "express_resolve_gateway_login_id",
            title: t("tables.fields.id"),
            width: 50,
            sorter: {
                compare: (a, b) => a.express_resolve_gateway_login_id - b.express_resolve_gateway_login_id
            }
        },
        {
            dataIndex: "gateway_name",
            key: "gateway_name",
            title: t("tables.fields.gateway_name"),
            width: 150,
            sorter: {
                compare: (a, b) => TextSorter(a.gateway_name,b.gateway_name)
            }
        },
        {
            dataIndex: "merchant_name",
            key: "merchant_name",
            title: t("tables.fields.merchant_name"),
            width: 150,
            sorter: {
                compare: (a, b) => TextSorter(a.merchant_name,b.merchant_name)
            }
        },
        {
            dataIndex: "username",
            key: "username",
            title: t("tables.fields.username"),
            width: 150,
            sorter: {
                compare: (a, b) => TextSorter(a.username,b.username)
            }
        },
        {
            dataIndex: "api_url",
            key: "api_url",
            title: t("tables.fields.url"),
            width: 150,
            sorter: {
                compare: (a, b) => TextSorter(a.api_url,b.api_url)
            }
        },
        {
            dataIndex: "currency",
            key: "currency",
            title: t("tables.fields.currency"),
            width: 150,
            sorter: {
                compare: (a, b) => TextSorter(a.currency,b.currency)
                
            }
        },        
        {
            dataIndex: "active",
            key: "active",
            align: "left",
            title: t("tables.fields.active"),
            width: 150,
            render: (text, record) => (
                record.active === 1?'Active':'InActive'
            ) ,
            sorter: {
                compare: (a, b) => a.status-b.active
            }
        },
        {
            dataIndex: "action",
            title: t("tables.fields.action"),
            align: "left",
            width: 100,
            render: (text, record) => (
                <Space size="middle">
                    <Button type="link" onClick={() => showModal(record.express_resolve_gateway_login_id)} >
                        <FileSearchOutlined style={{ color: "rgba(0, 0, 0, 0.85)", fontSize: "18px" }} />
                    </Button>
                </Space>
            ),
        }
    ];

    const getDataSource = (datalist) => (datalist && Array.isArray(datalist) ? datalist : []);

    const AdvancedSearchForm = () => (
        <div className="search-wrap">
            <Card title={t("tables.actions.search")} size="small">
                <Form form={form} size="large" name="advanced_search" className="ant-advanced-search-form" onValuesChange={formValChange} onFinish={onFinish}>
                    <Row gutter={[16, 16]}>
                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 6 }} xl={{ span: 5 }}>
                            <Form.Item name="field">
                                <Select style={{ width: "100%" }} placeholder={t("tables.fields.field")}>
                                    <Option value="gateway_name">{t("tables.fields.gateway_name")}</Option>
                                </Select>
                            </Form.Item>
                            
                        </Col>
                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12}} lg={{ span: 6 }} xl={{ span: 8 }}>
                            <Form.Item name="keyword">
                                <Input placeholder={t("tables.fields.keyword")} />
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                            <Form.Item name="merchant_id">
                                <TxalertMerchantListDropdown placeholder="Merchants" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[16, 24]}>
                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6, offset: 12 }} lg={{ span: 6, offset: 12  }} xl={{ span: 3, offset: 17 }}>
                            <Form.Item>
                                <Button type="secondary" onClick={() => clearAll()} block>
                                    {t("tables.actions.clear_all")}
                                </Button>
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 4 }}>
                            <Form.Item>
                                <Button type="primary" htmlType="submit" block>
                                    <SearchOutlined /> {t("tables.actions.search")}
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Card>
        </div>
    );

    const getHeaderTable = () => (
        <div className="custom-table--header d-flex justify-content-between align-items-center">
            <h3 className="table-title">{t("contents.txalert_admin.er_logins")}</h3>
            <Button type="link" onClick={() => showModal()} style={{ marginLeft: "auto" }}>
                <PlusOutlined /> {t("contents.txalert_admin.form.new_er_login")}
            </Button>
            <ExportModalV3
                t={t}
                keyname="export_all"
                title={t("tables.actions.export")}
                data={requestData}
                data_url={DATA_URL_EXPORT}
                headers={exportHeaders}
                fieldHeadersOptional={exportHeadersOptional}
                txalert_token = {true}
                filename={`erproviderlogin_${moment().format("YYMMDD_HHmmss_SSSS")}.csv`}
                style={{ marginLeft: 8 }}
            />
        </div>
    );

    const exportHeadersOptional = [];
    const exportHeaders = [
        { label: "ID", key: "express_resolve_gateway_logins.express_resolve_gateway_login_id" },
        { label: "Gateway Name", key: "gw.gateway_name" },
        { label: "Merchant name", key: "mc.name" },
        { label: "Username", key: "express_resolve_gateway_logins.username" },
        { label: "URL", key: "express_resolve_gateway_logins.api_url" },
        { label: "Currency", key: "express_resolve_gateway_logins.currency" },
        { label: "Active", key: "express_resolve_gateway_logins.active" }
    ];

    return (
        <div>
            <PageHeader title={t("contents.txalert_admin.er_logins")}></PageHeader>
            {AdvancedSearchForm()}
            <Table
                size="small"
                loading={isLoading}
                components={{
                    header: {
                        cell: ColumnResizable,
                    },
                }}                
                columns={MergeColumns(columns)}
                title={() => getHeaderTable()}
                rowKey="express_resolve_gateway_login_id"
                className="table-v3 custom-pagination custom-pagination-v3"
                dataSource={getDataSource(data.datalist)}
                pagination={CustomPaginationV2({ totalResult, currentPage, requestData, setRequestData, setCurrentPage })}

            />
            <TxalertERProviderLogin t={t} id={id} setId={setId} isOpen={isOpen} setIsOpen={setIsOpen} fetchList={getStatusList} />
        </div>
    );
};


export default Txalert_ERProviderLoginList;
