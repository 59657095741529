import React from "react";
import { Card } from "antd";

const TransactionLog = (props) => {
    const { t, data } = props;

    const getList = () => (
        <article className="ant-typography" style={{maxHeight: 400, overflowY: "auto"}}><div className="ant-typography well">
            {data}
        </div></article>
    );

    const getMessage = () => (
        <div className="details-info--text">
            <p>{t("contents.transactions.form.no_transaction_log")}</p>
        </div>
    );

    return (
        <Card className="details-info--card">
            <h4 className="details-info--title">{t("contents.transactions.form.transaction_log")}</h4>
            {data ? getList() : getMessage()}
        </Card>
    );
};

export default TransactionLog;
