const AssociationsChecker = (assoc) => {
    if (!JSON.parse(localStorage.getItem("loggedUser")).associations) {
        return false;
    }
    const associations = JSON.parse(localStorage.getItem("loggedUser")).associations;
    const access = JSON.parse(localStorage.getItem("loggedUser")).access;
    
    if (Array.isArray(access) && access.length > 0 && access.includes("SYSADMIN") ) {
        return true;
    }
    if (Array.isArray(associations) && associations.length > 0) {
        const arr_assoc = assoc.split("|");

        return associations.map((association) => arr_assoc.includes(association.type)).reduce((a, b) => a || b);
    } else {
        return false;
    }
};
export default AssociationsChecker;
