import React, { useEffect } from "react";
import SiteDetailsTab from "./SiteDetailsTab";
import PaymentTab from "./PaymentTab";
import ThreeDSTab from "./ThreeDSTab";
import AlertsTab from "./AlertsTab";
import { Tabs } from "antd";
import MenuAccess from "../../../helper/MenuAccess";
import GroupAccess from "../../../helper/GroupAccess";
const { TabPane } = Tabs;

const SiteDetails = (props) => {
    const { t, siteDetails, isLoading, setIsLoading } = props;

    useEffect(() => {
        setIsLoading(true);
        setTimeout(() => {
            setIsLoading(false);
        }, 500);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps



    return (
        <>
            <Tabs defaultActiveKey="details" type="card" className="custom-tabs-card">
                <TabPane tab={t("contents.merchants.details")} key="details">
                    <SiteDetailsTab t={t} siteDetails={siteDetails}  isLoading={isLoading}/>
                </TabPane>
                {MenuAccess('payments') && GroupAccess("SYSADMIN") ?(
                <TabPane tab={t("contents.merchants.payment")} key="payment">
                    <PaymentTab t={t} siteDetails={siteDetails}  isLoading={isLoading}/>
                </TabPane>):null}
                {MenuAccess('threeDS')?(
                <TabPane tab={t("contents.merchants.3ds")} key="3ds">
                    <ThreeDSTab t={t} siteDetails={siteDetails}  isLoading={isLoading}/>
                </TabPane>):null}
                {MenuAccess('alerts')?(
                <TabPane tab={t("contents.merchants.alerts")} key="alerts">
                    <AlertsTab t={t}  siteDetails={siteDetails} isLoading={isLoading}/>
                </TabPane>):null}
            </Tabs>
        </>
    );
};

export default SiteDetails;
