import React, { useState, useEffect} from "react";
import { Table, PageHeader, Card, Form, Select, Row, Col, Button, Space, Input } from "antd";
import { SearchOutlined, FileSearchOutlined ,PlusOutlined} from "@ant-design/icons";
import CustomPaginationV2 from "../../../helper/Table/CustomPaginationV2";
import { API_TXALERT2 } from "../../../config/config";
import ColumnResizable from "../../../helper/Table/ColumnResizable";
import MergeColumns from "../../../helper/Table/MergeColumns";
import DataFetchHookTxAlert from "../../../helper/DataFetchHookTxAlert";
import TxalertAlertResponse from "./AlertResponse";
import TextSorter from "../../../helper/Table/TextSorter";
import ExportModalV3 from "../../../helper/ExportModalV3";
import moment from "moment";
import TxalertProviderListDropdown from "../Components/ProviderlistDropdown";
import TxalertAlertTypeDropdown from "../Components/AlertTypeDropdown";

const DATA_URL = API_TXALERT2 + "/alert/responses";
const DATA_URL_EXPORT = API_TXALERT2 + "/alert/responses/reports";
const { Option } = Select;

const Txalert_AlertResponses = (props) => {
    const [{ data, totalResult, isLoading, isError, errorMsg, requestData, isUpdated }, setUrl, setRequestData] = DataFetchHookTxAlert(DATA_URL,"GET", localStorage.getItem("txalert_token")); // eslint-disable-line
    const [formData, setFormData] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    const [id, setId] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const { t } = props;

    const initFormData = {
        keyword: "",
        field: null,
        //  order: null,
        
    };

    const [form] = Form.useForm();

    useEffect(() => {
      
        formValInit();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const getList = () => {
        
        let searchData ={};
        setRequestData({ ...requestData, ...searchData,  page: 1 });
      
    };

    const showModal = (selectedid) =>{
        setId(selectedid);
        setIsOpen(true);
        
      };
    

    const formValInit = () => {
        setFormData(initFormData);
        form.setFieldsValue(initFormData);
    };

    const clearAll = () => {
        setCurrentPage(1);
        setFormData(initFormData);
        setRequestData({});
        form.setFieldsValue(initFormData);
    };

    const formValChange = (chgV, allV) => {
        if (["providername", "alert_type_id"].includes(chgV?.field)) {
            form.setFieldsValue({ keyword: undefined });
        }
        setFormData(allV);
    };

    

    const onFinish = () => {
        let searchData = {};
        
        if (formData) {
            let searchkeyword = formData.keyword ;
            if (formData.field === "providername") {
                searchkeyword = formData.keyword.label;  //to get the provider name instead of id
            }
            searchkeyword = searchkeyword? searchkeyword.toString().trim() : null //convert to string, if dropdown
            searchData = {
                [formData.field]: searchkeyword,
            };
        }
        
        // Reset Page Index to 1
        setCurrentPage(1);
        setRequestData({ ...requestData, ...searchData, page: 1 });
    };

    const columns = [
        {
            dataIndex: "response_id",
            key: "response_id",
            title: t("tables.fields.id"),
            width: 50,
            sorter: {
                compare: (a, b) => a.response_id - b.response_id
            }
        },
        {
            dataIndex: "alert_type",
            key: "alert_type",
            title: t("tables.fields.alert_type"),
            width: 50,
            sorter: {
                compare: (a, b) => TextSorter(a.alert_type-b.alert_type)
            }
        },
        {
            dataIndex: "provider_name",
            key: "provider_name",
            title: t("tables.fields.provider"),
            width: 80,
            sorter: {
                compare: (a, b) => TextSorter(a.provider_name,b.provider_name)
            }
        },
        {
            dataIndex: "status_code",
            key: "status_code",
            title: t("tables.fields.status_code"),
            width: 80,
            sorter: {
                compare: (a, b) => TextSorter(a.status_code,b.status_code)
            }
        },
        {
            dataIndex: "outcome",
            key: "outcome",
            title: t("tables.fields.outcome"),
            width: 80,
            sorter: {
                compare: (a, b) => TextSorter(a.outcome,b.outcome)
            }
        },
        {
            dataIndex: "description",
            key: "description",
            align: "left",
            title: t("tables.fields.description"),
            width: 300,
          
            sorter: {
                compare: (a, b) => TextSorter(a.description,b.description)
            }
        },
        {
            dataIndex: "active",
            key: "active",
            align: "left",
            title: t("tables.fields.active"),
            width: 80,
            render: (text, record) => (
                record.active === 1?'Active':'InActive'
            ) ,
            sorter: {
                compare: (a, b) => a.active-b.active
            }
        },
        {
            dataIndex: "admin_only",
            key: "admin_only",
            align: "left",
            title: t("tables.fields.admin_only"),
            width: 50,
            render: (text, record) => (
                record.admin_only === 1?'Yes':'No'
            ) ,
            sorter: {
                compare: (a, b) => a.admin_only-b.admin_only
            }
        },
        {
            dataIndex: "action",
            title: t("tables.fields.action"),
            align: "left",
            width: 100,
            render: (text, record) => (
                <Space size="middle">
                    <Button type="link" onClick={() => showModal(record.response_id)} >
                        <FileSearchOutlined style={{ color: "rgba(0, 0, 0, 0.85)", fontSize: "18px" }} />
                    </Button>
                </Space>
            ),
        }
    ];

    const getDataSource = (datalist) => (datalist && Array.isArray(datalist) ? datalist : []);

    const AdvancedSearchForm = () => (
        <div className="search-wrap">
            <Card title={t("tables.actions.search")} size="small">
                <Form form={form} size="large" name="advanced_search" className="ant-advanced-search-form" onValuesChange={formValChange} onFinish={onFinish}>
                    <Row gutter={[16, 16]}>
                    <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 6 }} xl={{ span: 5 }}>
                            <Form.Item name="field">
                                <Select style={{ width: "100%" }} placeholder={t("tables.fields.field")}>
                                    <Option value="provider_name">{t("tables.fields.providername")}</Option>
                                    <Option value="alert_type_id">{t("tables.fields.alert_type")}</Option>
                                    <Option value="outcome">{t("tables.fields.outcome")}</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12}} lg={{ span: 6 }} xl={{ span: 8 }}>
                        {form.getFieldValue("field") === "providername" ? (
                                <Form.Item name="keyword">
                                    <TxalertProviderListDropdown placeholder={t("tables.fields.keyword")} labelInValue={true}/>
                                </Form.Item>
                            ) : form.getFieldValue("field") === "alert_type_id" ? (
                                <Form.Item name="keyword">
                                  <TxalertAlertTypeDropdown placeholder={t("tables.fields.keyword")} />
                                </Form.Item>
                            ) : (
                                <Form.Item name="keyword">
                                    <Input placeholder={t("tables.fields.keyword")} />
                                </Form.Item>
                            )}
                        </Col>
                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6, offset: 12 }} lg={{ span: 6 }} xl={{ span: 3, offset: 4 }}>
                            <Form.Item>
                                <Button type="secondary" onClick={() => clearAll()} block>
                                    {t("tables.actions.clear_all")}
                                </Button>
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 4 }}>
                            <Form.Item>
                                <Button type="primary" htmlType="submit" block>
                                    <SearchOutlined /> {t("tables.actions.search")}
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Card>
        </div>
    );

    const getHeaderTable = () => (
        <div className="custom-table--header d-flex justify-content-between align-items-center">
            <h3 className="table-title">{t("contents.txalert_admin.alertresponses")}</h3>
            <Button type="link" onClick={() => showModal()} style={{ marginLeft: "auto" }}>
                <PlusOutlined /> {t("contents.txalert_admin.form.new_alert_response")}
            </Button>
            <ExportModalV3
                t={t}
                keyname="export_all"
                title={t("tables.actions.export")}
                data={requestData}
                data_url={DATA_URL_EXPORT}
                headers={exportHeaders}
                fieldHeadersOptional={exportHeadersOptional}
                txalert_token = {true}
                filename={`alertresponse_${moment().format("YYMMDD_HHmmss_SSSS")}.csv`}
                style={{ marginLeft: 8 }}
            />
        </div>
    );

    const exportHeadersOptional = [];
    const exportHeaders = [
        { label: "ID", key: "ar.response_id" },
        { label: "Alert Type", key: "a.alert_type" },
        { label: "Provider", key: "ap.provider_name" },
        { label: "Status Code", key: "ar.status_code" },
        { label: "Outcome", key: "ar.outcome" },
        { label: "Active", key: "ar.active" },
        { label: "Admin Only", key: "ar.admin_only" },
    ];

    return (
        <div>
            <PageHeader title={t("contents.txalert_admin.alertresponses")}></PageHeader>
            {AdvancedSearchForm()}
            <Table
                size="small"
                loading={isLoading}
                components={{
                    header: {
                        cell: ColumnResizable,
                    },
                }}
                
                columns={MergeColumns(columns)}
                title={() => getHeaderTable()}
                rowKey="response_id"
                className="table-v3 custom-pagination custom-pagination-v3"
                dataSource={getDataSource(data.datalist)}
                pagination={CustomPaginationV2({ totalResult, currentPage, requestData, setRequestData, setCurrentPage })}

            />
            <TxalertAlertResponse t={t} id={id} setId={setId} isOpen={isOpen} setIsOpen={setIsOpen} fetchList={getList} />
        </div>
    );
};


export default Txalert_AlertResponses;
