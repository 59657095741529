import en from "./Languages/en";
import cn from "./Languages/cn";
import jp from "./Languages/jp";
import tw from "./Languages/tw";

const Languages = {
    ...en,
    ...cn,
    ...jp,
    ...tw,
};

export default Languages;
