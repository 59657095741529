import React from "react";
import { Chart, Line, Point, Tooltip, Axis } from "bizcharts";

const AlertsLineChart = (props) => {
    const { t, data } = props;

    return (
        <div>          
            <Chart
                padding={[40, 20, 50, 70]}
                autoFit
                height={420}
                data={data}
                scale={{ total: { min: 0, tickCount: 3, alias: t("contents.dashboard.total") } }}
            >                
                <Line position="date*total" />
                <Point position="date*total" />
                <Tooltip showCrosshairs lock triggerOn="click" />
                <Axis
                    name="total"
                    title={{
                        position: "center",
                    }}
                />
            </Chart>
        </div>
    );
};

export default AlertsLineChart;
