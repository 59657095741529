const ArrangeReasons = (data) => {
    const sequence = ["resolved", "declined", "wip", "other", "shipper_contacted", "missed", "previously_credited", "previously_refunded", "unresolved_dispute", "notfound", "account_suspended", "partially_stopped", "stopped", "duplicate", "previously_received_chargeback", "account_closed", "descriptor_does_not_belong_to", "refund_failed", "cancelled"];

    const getArrangeReasons = (reasons) => {
        let new_array = [];
        if( Array.isArray(reasons) && reasons.length !== 0) {
            sequence.forEach((status) => {
                new_array = [...new_array, ...reasons.filter((reason) => reason.status.toLowerCase() === status)];
            });
        }
        return new_array;
    };

    return getArrangeReasons(data);
};

export default ArrangeReasons;
