import React, { useState, useEffect } from "react";
import { OI_URL } from "../../config/config";
import { Modal, Button, Form, Input, Select } from "antd";
import axios from "axios";
import Swal from "sweetalert2";
import { PlusOutlined } from "@ant-design/icons";
import TxFindAccessToken from "../../helper/TxFindAccessToken";

const { Option } = Select;

const CreateDescriptor = (props) => {
    const { t, fetchDescriptors } = props;
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [merchantList, setMerchantList] = useState(null);
    const [isModalVisible, setIsModalVisible] = useState(false);

    useEffect(() => {
        if(!merchantList) {
            getMerchantList();
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const getMerchantList = async () => {
        const apiUrl = OI_URL + `/api/v1/txshield/merchants`;
        axios
            .post(apiUrl, "", { headers: { Authorization: "Bearer " + await TxFindAccessToken() } })
            .then(({ data }) => {
                setMerchantList(data.list);
            })
            .catch((error) => {
                Swal.fire({
                    icon: "error",
                    title: t("login.dialogs.catch"),
                    text: error.response ? error.response.data.msg : "",
                    showConfirmButton: true,
                });
            });
    };

    const layout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };

    const [form] = Form.useForm();

    const initData = {
        title: "",
        merchantId: ""
    };

    const handleOk = () => {
        setLoading(true);
        onFinish();
    };

    const showModal = () => {
        form.setFieldsValue(initData);
        setIsModalVisible(true);
    };

    const handleCancel = () => {
        setLoading(false);
        setIsModalVisible(false);
    };

    const formValChange = (chgV, allV) => {
        setData(allV);
    };

    const onFinish = async () => {
        const apiUrl = OI_URL + "/api/v1/txshield/descriptor/create/";

        axios
            .post(apiUrl, data, { headers: { Authorization: "Bearer " + await TxFindAccessToken() } })
            .then(({ data }) => {
                if (data.status === "ok") {
                    Swal.fire({
                        icon: "success",
                        title: t("contents.oi.descriptor_created"),
                        showConfirmButton: false,
                        timer: 2000,
                        onClose: () => {
                            fetchDescriptors();
                        },
                    });
                    handleCancel();
                } else {
                    Swal.fire({
                        icon: "error",
                        title: t("login.dialogs.catch"),
                        text: data.msg ? data.msg : "",
                        showConfirmButton: true,
                    });
                }
            })
            .catch((error) => {
                setLoading(false);
                Swal.fire({
                    icon: "error",
                    title: t("login.dialogs.catch"),
                    text: error.response ? error.response.data.msg : "",
                    showConfirmButton: true,
                });
            });
    };

    const isOkButtonProps = () => !data || !data.title || loading;

    return (
        <>
            <Button type="link" onClick={showModal} style={{ marginLeft: "auto" }}>
                <PlusOutlined /> {t("contents.oi.new_descriptor")}
            </Button>
            <Modal
                title={t("contents.oi.create_descriptor")}
                width={600}
                visible={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={[
                    <Button key="back" onClick={() => handleCancel()}>
                        {t("contents.chargebacks.form.cancel")}
                    </Button>,
                    <Button key="submit" type="primary" loading={loading} disabled={isOkButtonProps()} onClick={() => handleOk()}>
                        {loading ? t("contents.chargebacks.form.submitting") : t("contents.chargebacks.form.submit")}
                    </Button>,
                ]}
            >
                <Form {...layout} form={form} name="new_descriptor" onValuesChange={formValChange}>
                    <Form.Item name="title" label={t("contents.oi.form.title")} rules={[{ required: true }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item name="merchantId" label={t("contents.oi.form.merchant")} rules={[{ required: true }]}>
                        <Select placeholder={t("contents.merchants.form.please_select")}>
                            {merchantList
                                ? merchantList.map((item) => (
                                        <Option key={item.merchantId} value={item.merchantId}>
                                            {item.name}
                                        </Option>
                                    ))
                                : null}
                        </Select>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};

export default CreateDescriptor;