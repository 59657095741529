import React, { useEffect } from "react";
import { Card, Row, Col, Form, Input,Table,Tabs } from "antd";
import ColumnResizable from "../../../helper/Table/ColumnResizable";
import MergeColumns from "../../../helper/Table/MergeColumns";

const AlertsTab = (props) => {
    const { t, siteDetails, isLoading } = props;
    const [form] = Form.useForm();
    const initFormData = {
        alertapiurl: siteDetails.alert.settings.alertapiurl,
        descriptorid: siteDetails.alert.settings.descriptorid,
        username: siteDetails.alert.settings.username,
        token: siteDetails.alert.settings.password,
        default_dispute_action: siteDetails.alert.settings.default_dispute_action,
        max_refund_amount: siteDetails.alert.settings.max_refund_amount,
        alert_triggeremail: siteDetails.alert.settings.alert_triggeremail,
        alert_email: siteDetails.alert.settings.alert_email,
        shieldrdr_review_email: siteDetails.alert.settings.shieldrdr_review_email,
        statusupdate_url: siteDetails.alert.settings.statusupdate_url
    }
    const { TabPane } = Tabs;
    const itemLayout = {
        labelAlign: "left",
        labelCol: { span: 10, offset: 0 },
    };


 

    const binCaidColumn = [
        {
            dataIndex: "bin",
            key: "bin",
            title: t("tables.fields.bin"),
            width: 50,
        },
        {
            dataIndex: "caid",
            key: "caid",
            title: t("tables.fields.caid"),
            width: 100,
        },
        {
            dataIndex: "descriptor",
            key: "descriptor",
            title: t("tables.fields.descriptor"),
            width: 200,
        },
        {
            dataIndex: "status",
            key: "Status",
            title: t("tables.fields.status"),
            width: 50,
        }
    ];

    const descriptorColumn = [
        {
            dataIndex: "descriptor",
            key: "descriptor",
            title: t("tables.fields.descriptor"),
            width:200
        },
        {
            dataIndex: "enrollment_status",
            key: "enrollment_status",
            title: t("tables.fields.status"),
            width:200
        }
       
    ];

    useEffect(() => {
        formValInit();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const formValInit = () => {
        form.setFieldsValue(initFormData);
    };

    const getAlertsDetails = () => (
        <Card className="custom-form--card" loading={isLoading}>
            <h3>{t("contents.merchants.settings")}</h3>
            <hr />
            <Form form={form} {...itemLayout} name="alerts">
                <Row gutter={[36, 16]}>
                    <Col span={16}>
                        <Form.Item name="alertapiurl" label={t("contents.merchants.form.alertapiurl")}>
                            <Input readOnly bordered={false}/>
                        </Form.Item>
                        <Form.Item name="descriptorid" label={t("contents.merchants.form.descriptorid")}>
                            <Input readOnly bordered={false}/>
                        </Form.Item>
                        <Form.Item name="username" label={t("contents.merchants.form.username")}>
                            <Input readOnly bordered={false}/>
                        </Form.Item>
                        <Form.Item name="token" label={t("contents.merchants.form.token")}>
                            <Input.TextArea readOnly rows={3} />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item name="default_dispute_action" label={t("contents.merchants.form.default_dispute_action")} className="fixed-label">
                            <Input readOnly bordered={false}/>
                        </Form.Item>
                        <Form.Item name="alert_triggeremail" label={t("contents.merchants.form.alert_triggeremail")} className="fixed-label">
                                <span className="ant-form-text">{siteDetails.payment.details.currency ? siteDetails.payment.details.currency:null}</span>
                        </Form.Item>
                        <Form.Item name="alert_email" label={t("contents.merchants.form.alert_email")} className="fixed-label">
                            <Input readOnly bordered={false}/>
                        </Form.Item>
                        <Form.Item name="shieldrdr_review_email" label={t("contents.merchants.form.shieldrdr_review_email")} className="fixed-label">
                            <Input readOnly bordered={false}/>
                        </Form.Item>
                        <Form.Item name="statusupdate_url" label={t("contents.merchants.form.statusupdate_url")} className="fixed-label">
                            <Input readOnly bordered={false}/>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
            <h3>{t("contents.merchants.bincaid")}</h3>
            <hr />
            <Tabs defaultActiveKey="details" type="card" className="custom-tabs-card">
                <TabPane tab={t("contents.merchants.bincaid")} key="details">
                    <Table rowKey="bcdid" size="small" 
                    columns={MergeColumns(binCaidColumn)}
                    bordered={false} 
                    components={{
                    header: {
                        cell: ColumnResizable,
                    },
                }}  dataSource={siteDetails.alert.binCaidList ? siteDetails.alert.binCaidList : []} pagination={false} />
                </TabPane>
                <TabPane tab={t("contents.merchants.descriptor")} key="desc_details">
                    <Table rowKey="descriptor_id" size="small" 
                    bordered={false} components={{
                    header: {
                        cell: ColumnResizable,
                    },
                }} columns={MergeColumns(descriptorColumn)} 
                dataSource={siteDetails.alert.descriptorList ? siteDetails.alert.descriptorList : []} 
                pagination={false} />
                </TabPane>
            </Tabs>
                    </Card>
    );

    return getAlertsDetails();
};

export default AlertsTab;
