import React, { useState, useEffect } from "react";
import { Form, Input, Button,Modal, InputNumber} from "antd";
import { API_TXALERT2 } from "../../../config/config";
import axios from "axios";
import Swal from "sweetalert2";
import GroupAccess from "../../../helper/GroupAccess";
import CatchError from "../../../helper/CatchError";


const Txalert_AcquiringBank = (props) => {
    const { t, id,setId,isOpen,setIsOpen, fetchList } = props;
    const [details, setDetails] = useState(null);
    const [loading, setLoading] = useState(false);
    const [componentDisabled, setComponentDisabled] = useState(GroupAccess("SYSADMIN") ? false : true); // eslint-disable-line
    
   
    const layout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };

    const initData = {
        name: "",
        state: 0,
        country: 0,
    };

    const handleOk = () => {
        setLoading(true);
        onFinish();
    };

   

    const [form] = Form.useForm();

    const handleCancel = () => {             
        setLoading(false);
        setId('');
        form.setFieldsValue(initData);
        setIsOpen(false);
        
    };

    const formValChange = (chgV, allV) => {     
        
            
        if(chgV.state)
       {        
        setDetails({ ...allV, state: parseInt(chgV.state)});
       }else if(chgV.country)
       {
        setDetails({ ...allV, country: parseInt(chgV.country)});
       }
       else{
        setDetails(allV);
       }       
      
    };

    useEffect(() => {
     
        if(isOpen){
            if (typeof id != 'undefined') {
            
                getDetails();
            }else{
                setDetails(initData);
            }
        }
    }, [isOpen]); // eslint-disable-line

   
    const getDetails = async () => {

        const api_url = API_TXALERT2 + `/acquiring_bank/${id}`;
        await axios
            .get(api_url, { headers: { Authorization: localStorage.getItem("txalert_token") } })
            .then(({ data }) => {
                let recdata = data;
                setDetails({ ...recdata, country: parseInt(recdata.country),state:parseInt(recdata.state)});              
                form.setFieldsValue({ ...recdata, country: parseInt(recdata.country),state:parseInt(recdata.state)});
            })
            .catch((error) => console.log(error));
    };

    const onFinish = () => {
        let apiUrl = API_TXALERT2 ;
      
       if(id === 'undefined' || typeof id === 'undefined' || id === ''){
        apiUrl = apiUrl + "/acquiring_bank";
       }else{
        apiUrl = apiUrl + "/acquiring_bank/"+id;
       }
      
         axios
             .post(apiUrl, details,{ headers: { Authorization: "Bearer " + localStorage.getItem("txalert_token") }})
             .then(({ data }) => {
                 if (data.status === "OK") {
                     Swal.fire({
                         icon: "success",
                         title: data.msg,
                         showConfirmButton: false,
                         timer: 2000,
                         onClose: () => {
                             fetchList();
                         },
                     });
                     handleCancel();
                 } else {
                    
                     CatchError(data);
                 }
             })
             .catch((error) => {
                console.log(error.response);
                 setLoading(false);
                 CatchError(error);
             });
     };
    const isOkButtonProps = () => !details || !details.name || loading;
    return(
        <>
            <Modal
                title={id !== undefined?t("contents.txalert_admin.form.view_acquiring_bank")+ ' id:'+id:t("contents.txalert_admin.form.add_acquiring_bank")}
                visible={isOpen}
                width={600}
                onCancel={handleCancel}
                onOk={handleOk}
                bodyStyle={{ paddingTop: '0px' }}
                footer={[
                    <Button key="back" onClick={() => handleCancel()}>
                        {t("contents.txalert_admin.form.cancel")}
                    </Button>,
                     GroupAccess("SYSADMIN") ? 
                    <Button key="submit" type="primary" loading={loading} disabled={isOkButtonProps()} onClick={() => handleOk()}>
                        {loading ? t("contents.txalert_admin.form.submitting") : t("contents.txalert_admin.form.submit")}
                    </Button>:'',
                ]}
            >
                <Form {...layout} form={form} name="detail" onValuesChange={formValChange}  >
                    <fieldset disabled={componentDisabled}>
                        <Form.Item name="name" label={t("contents.txalert_admin.form.bank_name")} rules={[{ required: true }]} style={{ marginBottom: ".5rem" }} >
                            <Input />
                        </Form.Item>
                        <Form.Item name="state" label={t("contents.txalert_admin.form.state")}  style={{ marginBottom: ".5rem" }} >
                            <InputNumber />
                        </Form.Item>
                        <Form.Item name="country" label={t("contents.txalert_admin.form.country")} style={{ marginBottom: ".5rem" }} >
                            <InputNumber />
                        </Form.Item>
                        
                    </fieldset>
                </Form>
            </Modal>
 
        </>
    );
};

export default Txalert_AcquiringBank;
