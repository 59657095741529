import React, { useState, useEffect } from "react";
import DataFetchHookCB from "../../helper/DataFetchHookCB";
import GroupAccess from "../../helper/GroupAccess";
import CCMask from "../../helper/CCMask";
import SingleChargeback from "./SingleChargeback";
import ImportChargeback from "./ImportChargeback";
import DocumentUpload from "./DocumentUpload";
import DocumentList from "./DocumentList";
import axios from "axios";
import moment from "moment";
import Icon from "@ant-design/icons";
import { Table, PageHeader, Button, Tooltip, Card, Form, Row, Col, Select, Input } from "antd";
import { UploadOutlined, EyeOutlined, RetweetOutlined, SearchOutlined } from "@ant-design/icons";
import { CBM_URL } from "../../config/config";
import ExportModalCB from "../../helper/ExportModalCB";
import CustomPaginationV2 from "../../helper/Table/CustomPaginationV2";
import CustomDateRangeV3 from "../../helper/Table/CustomDateRangeV3";
import FormatAmount from "../../helper/FormatAmount";
//import SortOrder from "../../helper/Table/SortOrder";
import ColumnResizable from "../../helper/Table/ColumnResizable";
import MergeColumns from "../../helper/Table/MergeColumns";
import Swal from "sweetalert2";

const { Option } = Select;

const DATA_URL = CBM_URL + "/api/v1/chargebacks";
const DATA_URL_EXPORT = CBM_URL + "/api/v1/chargebacks/reports";


const MAX_DATERANGE = 3;
const DEFAULT_DATE_RANGE = 7;
const DEFAULT_DATE_PERIOD = "days";
const START_DATE = moment().subtract(DEFAULT_DATE_RANGE, DEFAULT_DATE_PERIOD);
const END_DATE = moment();

const ChargebackList = (props) => {
    const [{ data, totalResult, requestData, isLoading, errorMsg  }, , setRequestData] = DataFetchHookCB(DATA_URL, "POST", localStorage.getItem("cbm_token"),{  // commented ({isError,isUpdated},setMethod,setToken) due to unused var
        startDate: START_DATE,
        endDate: END_DATE,
        page: 1,
        size: 20,
        order: "DESC",
        orderVar: "created_date"
    }); // eslint-disable-line
   
    
    
    const [formData, setFormData] = useState();
    const [sortedInfo, setSortedInfo] = useState({});// eslint-disable-line
    const [upload, setUpload] = useState({ mid: null, pid: null, chargebackid: null, merchant_transaction_id: null });
    const [merchants, setMerchants] = useState(null);
    const [providers, setProviders] = useState(null);
    const [documents] = useState(null);
    //const [access, setAccess] = useState({ mid: null, pid: null, jwt_token: null });
    const [isReady, setIsReady] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [dates, setDates] = useState([START_DATE, END_DATE]);
    const [isDocumentUpload, setIsDocumentUpload] = useState(false);
    const [isDocumentList, setIsDocumentList] = useState(false);
    const [merchant,setMerchant] = useState(null); // eslint-disable-line
    const [isDateExceeded, setIsDateExceeded] = useState(false); // eslint-disable-line
    const { t } = props;

    const initFormData = {
        keyword: "",
        dateRange: [START_DATE, END_DATE],
        field: null,
        // order: null,
    };

    const [form] = Form.useForm();


    useEffect(() => {
        if(isLoading === false)
        {             
            if(errorMsg)
            {
                Swal.fire({
                    icon: "error",
                    title: errorMsg,
                    showConfirmButton: true,
                });
            }
        if (!isReady) {
            setIsReady(true);
         //   formValInit();
         if(!merchants){
            getMerchants();
         }
         if(!providers){
            getProviders();
         }
        }
    }
    }, [isLoading]); // eslint-disable-line

    const handleChange = (pagination, filters, sorter) => { // removed { currentDataSource } due to unused var
       
        setSortedInfo(sorter);
    };

   const getMerchants = (mids) => {
        const apiUrl = "/api/v1/merchants";
        const requestData = mids ? { mids: mids,isactive:1 } : {isactive:1};
        axios
            .post(CBM_URL + apiUrl, requestData,{ headers: { Authorization: "Bearer " + localStorage.getItem("cbm_token") }})
            .then(({ data }) => {
              
                if (data.list) {
                    setMerchants(data.list);
                }
            })
            .catch((error) => error);
    };

    const getProviders = (pids) => {
        const apiUrl = "/api/v1/providers";
        const requestData = pids ? { pids: pids,isactive:1 } : {isactive:1};
        axios
            .post(CBM_URL + apiUrl, requestData,{ headers: { Authorization: "Bearer " + localStorage.getItem("cbm_token") }})
            .then(({ data }) => {
               
                if (data.list) {
                    setProviders(data.list);
                }
            })
            .catch((error) => error);
    }; 

   
    const getAction = (mid, pid, chargebackid, merchant_transaction_id) => {
        let btnStyle = { height: "18px", padding: "0 4px" };
        let iconStyle = { color: "rgba(0, 0, 0, 0.85)", fontSize: "18px" };
        return (
            <div className="d-flex justify-content-center">
                <Button
                    type="link"
                    style={btnStyle}
                    onClick={() => {
                        setUpload({ mid, pid, chargebackid, merchant_transaction_id });
                       // getDocuments(mid, pid, chargebackid);
                        setIsDocumentList(true);
                    }}
                >
                    <EyeOutlined style={iconStyle} />
                </Button>
                <Button
                    type="link"
                    style={btnStyle}
                    onClick={() => {
                        setUpload({ mid, pid, chargebackid, merchant_transaction_id });
                        setIsDocumentUpload(true);
                    }}
                >
                    <UploadOutlined style={iconStyle} />
                </Button>
            </div>
        );
    };

    const CautionSvg = () => (
        <svg height="1rem" viewBox="0 0 128 128" width="1rem" xmlns="http://www.w3.org/2000/svg">
            <g>
                <path
                    d="m57.362 26.54-37.262 64.535a7.666 7.666 0 0 0 6.639 11.5h74.518a7.666 7.666 0 0 0 6.639-11.5l-37.258-64.535a7.665 7.665 0 0 0 -13.276 0z"
                    fill="#ffb400"
                />
                <g fill="#fcf4d9">
                    <rect height="29.377" rx="4.333" width="9.638" x="59.181" y="46.444" />
                    <circle cx="64" cy="87.428" r="4.819" />
                </g>
            </g>
        </svg>
    );

    const CautionIcon = (props) => <Icon component={CautionSvg} {...props} />;

    let columns = [
        {
            dataIndex: "created_date",
            key: "created_date",
            title: t("tables.fields.created_date"),
            width: 180,
            ellipsis: true,
            render: (created_date) => moment(created_date).format("YYYY-MM-DD HH:mm:ss"),
            sorter: {
                compare: (a, b) => moment(a.created_date).diff(moment(b.created_date)),
                //    multiple: 6,
            },
         //   sortOrder: SortOrder(sortedInfo, "created_date"),
        },
        {
            dataIndex: "merchant_transaction_id",
            key: "merchant_transaction_id",
            title: t("tables.fields.merchant_transaction_id"),
            width: 220,
            ellipsis: true,
            sorter: {
                compare: (a, b) => a.merchant_transaction_id - b.merchant_transaction_id,
                //    multiple: 1,
            },
         //   sortOrder: SortOrder(sortedInfo, "merchant_transaction_id"),
            render: (merchant_transaction_id, record) => (
                <a href={`/chargeback/${window.btoa(`${record.mid},${record.pid},${record.chargebackid}`)}`} target="_blank" rel="noopener noreferrer">
                    {record.alert_id || record.chargebackReference ? (
                        <Tooltip
                            placement="topLeft"
                            title={
                                record.alert_id ? (
                                    <>
                                        <span>{t("contents.chargebacks.tooltip.alert_matched")}</span>:&nbsp;
                                        <a href={`/alert/${record.alert_id}`} target="_blank" rel="noopener noreferrer">
                                            {record.alert_id}
                                        </a>
                                    </>
                                ) : (
                                    t("contents.chargebacks.tooltip.transaction_matched")
                                )
                            }
                        >
                            {merchant_transaction_id}{" "}
                            {record.alert_id ? <CautionIcon style={{ fontSize: "1rem" }} /> : <RetweetOutlined style={{ fontSize: "0.8rem", color: "#666" }} />}
                        </Tooltip>
                    ) : (
                        merchant_transaction_id
                    )}
                </a>
            ),
        },
        {
            dataIndex: "card_number",
            key: "card_number",
            title: t("tables.fields.card_number"),
            width: 170,
            ellipsis: true,
            sorter: {
                compare: (a, b) => a.card_number.localeCompare(b.card_number),
                //    multiple: 2,
            },
       //     sortOrder: SortOrder(sortedInfo, "card_number"),
            render: (card_number, record) => CCMask(card_number),
        },
        {
            dataIndex: "currency",
            key: "currency",
            title: t("tables.fields.currency"),
            width: 100,
            sorter: {
                compare: (a, b) => a.currency.localeCompare(b.currency),
                //    multiple: 3,
            },
      //      sortOrder: SortOrder(sortedInfo, "currency"),
        },
        {
            dataIndex: "amount",
            key: "amount",
            title: t("tables.fields.amount"),
            width: 100,
            ellipsis: true,
            render: (amount, record) => FormatAmount(amount, record.currency),
            sorter: {
                compare: (a, b) => a.amount - b.amount,
                //    multiple: 3,
            },
     //       sortOrder: SortOrder(sortedInfo, "amount"),
        },
    ];

    if (GroupAccess("SYSADMIN")) {
        columns = [
            ...columns,
            {
                dataIndex: "merchant",
                key: "merchant",
                title: t("tables.fields.merchant"),
                width: 160,
                ellipsis: true,
                sorter: {
                    compare: (a, b) => a.merchant.localeCompare(b.merchant),
                    //    multiple: 4,
                },
        //        sortOrder: SortOrder(sortedInfo, "merchant"),
            },
            {
                dataIndex: "provider",
                key: "provider",
                title: t("tables.fields.provider"),
                width: 160,
                sorter: {
                    compare: (a, b) => a.provider.localeCompare(b.provider),
                    //    multiple: 5,
                },
        //       sortOrder: SortOrder(sortedInfo, "provider"),
            },
        ];
    }

    columns = [
        ...columns,
        {
            dataIndex: "chargeback_reason",
            key: "chargeback_reason",
            title: t("tables.fields.response_message"),
            width: 200,
        },
        {
            dataIndex: "action",
            key: "action",
            title: t("tables.fields.action"),
            align: "left",
            fixed: "right",
            width: 100,
            render: (action, record) => getAction(record.mid, record.pid, record.chargebackid, record.merchant_transaction_id),
        },
    ];

    const exportHeadersOptional = [];
    const exportHeaders = [
        { label: "ID", key: "mncb.chargebackid" },
        { label: "Merchant Transaction ID", key: "mncb.merchant_transaction_id" },
        { label: "Transaction Date", key: "mncb.payment_transaction_date" },
        { label: "Amount", key: "mncb.amount" },
        { label: "Currency", key: "mncb.currency" },
        { label: "ARN", key: "mncb.arn" },
        { label: "Card Number", key: "mncb.card_number" },
        { label: "Descriptor", key: "mncb.descriptor" },
        { label: "Merchant", key: "m.name" },
        { label: "Provider", key: "p.name" },
        { label: "Merchant ID", key: "mncb.merchant_id" },
        { label: "Alert ID", key: "mncb.alert_id" },
        { label: "Reference", key: "mncb.chargebackReference" },
        { label: "Created Date", key: "mncb.created_date" },
    ];

   /* const formValInit = async () => {
        setFormData(initFormData);
        form.setFieldsValue(initFormData);
        setRequestData({...requestData, startDate: START_DATE,
                    endDate: END_DATE,
                    page: 1,
                    size: 20,
                    order: "DESC",
                    orderVar: "created_date" })
                    await doFetch(DATA_URL);
    };  */

    const clearAll = () => {
        setFormData(initFormData);
        setRequestData({ ...requestData, ...initFormData, startDate: null, endDate: null, page: 1 });
        form.setFieldsValue(initFormData);
    };

    const formValChange = (chgV, allV) => {
        setFormData(allV);
    };

    const onFinish = () => {
        let startDate = null;
        let endDate = null;
        let searchData = {};
        if (formData) {
            if (dates) {
                startDate = dates[0] ? dates[0] : null;
                endDate = dates[1] ? dates[1] : null;
            }
            searchData = {
                field: formData.field,
                keyword: formData.keyword ? formData.keyword.trim() : null,
                //  order: formData.order,
            };
        }
        // Reset Page Index to 1
        setCurrentPage(1);
        setRequestData({ ...requestData, ...searchData, startDate: startDate, endDate: endDate, page: 1 });
    };

    const AdvancedSearchForm = () => (
        <div className="search-wrap">
            <Card title={t("tables.actions.search")} size="small">
                <Form form={form} size="large" name="advanced_search" className="ant-advanced-search-form" onValuesChange={formValChange} onFinish={onFinish}>
                    <Row gutter={[16, 16]}>
                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }} xl={{ span: 6 }}>
                            <Form.Item name="dateRange">
                              
                                <CustomDateRangeV3 t={t} setIsDateExceeded={setIsDateExceeded} state={{ dates, setDates }} unit="months" limit={MAX_DATERANGE} />
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }} xl={{ span: 8 }}>
                            <Form.Item name="field">
                                <Select style={{ width: "100%" }} placeholder={t("tables.fields.field")}>
                                    <Option value="mncb.merchant_transaction_id">{t("tables.fields.merchant_transaction_id")}</Option>
                                    <Option value="mncb.card_number">{t("tables.fields.card_number")}</Option>
                                    <Option value="mncb.merchant_id">{t("contents.chargebacks.form.merchant_id")}</Option>
                                    <Option value="mncb.descriptor">{t("contents.chargebacks.form.descriptor")}</Option>
                                    <Option value="mncb.amount">{t("tables.fields.amount")}</Option>
                                    <Option value="mncb.arn">{t("contents.chargebacks.form.arn")}</Option>
                                    <Option value="m.name">{t("tables.fields.merchant")}</Option>
                                    <Option value="p.name">{t("tables.fields.provider")}</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }} xl={{ span: 10 }}>
                            <Form.Item name="keyword">
                                <Input placeholder={t("tables.fields.keyword")} />
                            </Form.Item>
                        </Col>
                        {/*
                        <Col className="gutter-row" span={6}>
                            <Form.Item name="order">
                                <Select style={{ width: "100%" }} placeholder="Order">
                                    <Option value="ASC">ASC</Option>
                                    <Option value="DESC">DESC</Option>
                                </Select>
                            </Form.Item>
                        </Col> */}
                        <Col
                            className="gutter-row"
                            xs={{ span: 24 }}
                            sm={{ span: 12 }}
                            md={{ span: 6 }}
                            lg={{ span: 4, offset: 16 }}
                            xl={{ span: 3, offset: 17 }}
                        >
                            <Form.Item>
                                <Button type="secondary" onClick={() => clearAll()} block>
                                    {t("tables.actions.clear_all")}
                                </Button>
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 4 }} xl={{ span: 4 }}>
                            <Form.Item>
                                <Button type="primary" htmlType="submit" block>
                                    <SearchOutlined /> {t("tables.actions.search")}
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Card>
        </div>
    );

   /* const fetchChargeback = () => {
        if (GroupAccess("SYSADMIN")) {
            return doFetch(DATA_URL + `?` + moment().unix());
        } else if (access.mid && access.pid) {
            return doFetch(DATA_URL + `/merchant/${access.mid}/provider/${access.pid}/list?${moment().unix()}`);
        }
    }; */

    const getDataSource = (datalist) => (datalist && Array.isArray(datalist) ? datalist : []);

    const getHeaderTable = () => (
        <div className="custom-table--header d-flex justify-content-between align-items-center">
            <h3 className="table-title">{t("contents.chargebacks.title")}</h3>
            <SingleChargeback t={t} merchants={merchants} providers={providers} fetchChargeback={[]} />
            <ImportChargeback t={t} merchants={merchants} providers={providers} fetchChargeback={[]} />
            <ExportModalCB
                t={t}
                keyname="export_all"
                title={t("tables.actions.export")}
                data={requestData}
                data_url={DATA_URL_EXPORT}
                headers={exportHeaders}
                fieldHeadersOptional={exportHeadersOptional}
                filename={`txcbm_chargebacks_${moment().format("YYMMDD_HHmmss_SSSS")}.csv`}
                style={{ marginLeft: 8 }}
            />
        </div>
    );

    return (
        <div>
            <PageHeader title={t("contents.chargebacks.title")}></PageHeader>
            {AdvancedSearchForm()}
            <DocumentList
                t={t}
                upload={upload}
                documents={documents && Array.isArray(documents) ? documents : []}
                isModalVisible={isDocumentList}
                setIsModalVisible={setIsDocumentList}
                isUseModal={true}
                merchant={merchant}
            />
            <DocumentUpload
                t={t}
                upload={upload}
                isModalVisible={isDocumentUpload}
                setIsModalVisible={setIsDocumentUpload}
                merchant={merchant}
            />
            <Table
                size="small"
                loading={isLoading}
                components={{
                    header: {
                        cell: ColumnResizable,
                    },
                }}
                onChange={handleChange}
                columns={MergeColumns(columns)}
                title={() => getHeaderTable()}
                rowKey="chargebackid"
                scroll={{ x: 1100 }}
                className="table-v3 custom-pagination"
                dataSource={data.datalist && Array.isArray(data.datalist) ? getDataSource(data.datalist) : []}
                sortDirections={["ascend", "descend", "ascend"]}
                pagination={CustomPaginationV2({ totalResult, currentPage, requestData, setRequestData, setCurrentPage })}
            />
        </div>
    );
};

export default ChargebackList;
