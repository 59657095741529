
import React, { useState, useEffect } from "react";
import { Form, Input, Switch, Button,Modal } from "antd";
import { API_TXALERT2 } from "../../../config/config";
import axios from "axios";
import Swal from "sweetalert2";
import GroupAccess from "../../../helper/GroupAccess";
import CatchError from "../../../helper/CatchError";
import TxalertThemeDropdown from "../Components/ThemeDropdown";
const Txalert_EmailTemplate = (props) => {
    const { t, id,setId,isOpen,setIsOpen, fetchList } = props;
    const [details, setDetails] = useState(null);
    const [loading, setLoading] = useState(false);
    const [componentDisabled, setComponentDisabled] = useState(GroupAccess("SYSADMIN") ? false : true); //eslint-disable-line


    const layout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };

    const initData = {
        template_id: "",
        template_code: "",
        theme_id: "",
        active: "",
    };

    const handleOk = () => {
        setLoading(true);
        onFinish();
    };

    const [form] = Form.useForm();

    const handleCancel = () => {
        setLoading(false);
        setId('');
        form.setFieldsValue(initData);
        setIsOpen(false);

    };

    const formValChange = (chgV, allV) => {
        setDetails(allV);
    };

    useEffect(() => {

        if(isOpen){
            if (typeof id != 'undefined') {

                getDetails();
            }else{
                setDetails(initData);
            }
        }
    }, [isOpen]); // eslint-disable-line


    const getDetails = async () => {

        const api_url = API_TXALERT2 + `/email_templates/${id}`;
        await axios
            .get(api_url, { headers: { Authorization: localStorage.getItem("txalert_token") } })
            .then(({ data }) => {
                let recdata = data;
                setDetails(recdata);
                form.setFieldsValue({ ...recdata, activecheck: recdata.active === 1, admincheck:recdata.txalert_admin_only === 1});
            })
            .catch((error) => console.log(error));
    };

    const onFinish = () => {
        let apiUrl = API_TXALERT2 ;

        if(id === 'undefined' || typeof id === 'undefined' || id === ''){
            apiUrl = apiUrl + "/email_templates";
        }else{
            apiUrl = apiUrl + "/email_templates/"+id;
        }
        axios
            .post(apiUrl, details,{ headers: { Authorization: "Bearer " + localStorage.getItem("txalert_token") }})
            .then(({ data }) => {
                if (data.status === "OK") {
                    Swal.fire({
                        icon: "success",
                        title: data.msg,
                        showConfirmButton: false,
                        timer: 2000,
                        onClose: () => {
                            fetchList();
                        },
                    });
                    handleCancel();
                } else {

                    CatchError(data);
                }
            })
            .catch((error) => {
                console.log(error.response);
                setLoading(false);
                CatchError(error);
            });
    };
    const isOkButtonProps = () => !details || !details.template_id || loading;
    return(
        <>
            <Modal
                title={id !== 'undefined'?t("contents.txalert_admin.form.view_email_template")+ ' id:'+id:t("contents.txalert_admin.form.add_email_template")}
                visible={isOpen}
                width={600}
                onCancel={handleCancel}
                onOk={handleOk}
                bodyStyle={{ paddingTop: '0px' }}
                footer={[
                    <Button key="back" onClick={() => handleCancel()}>
                        {t("contents.txalert_admin.form.cancel")}
                    </Button>,
                    GroupAccess("SYSADMIN") ?
                        <Button key="submit" type="primary" loading={loading} disabled={isOkButtonProps()} onClick={() => handleOk()}>
                            {loading ? t("contents.txalert_admin.form.submitting") : t("contents.txalert_admin.form.submit")}
                        </Button>:'',
                ]}
            >
                <Form {...layout} form={form} name="merchant_detail" onValuesChange={formValChange}  >
                    <fieldset disabled={componentDisabled}>
                        <Form.Item name="template_id" label={t("contents.txalert_admin.form.template_id")} rules={[{ required: true }]} style={{ marginBottom: ".5rem" }} >
                            <Input />
                        </Form.Item>
                        <Form.Item name="template_code" label={t("contents.txalert_admin.form.template_code")} rules={[{ required: true }]} style={{ marginBottom: ".5rem" }} >
                            <Input />
                        </Form.Item>
                        <Form.Item name="theme_id" label={t("contents.txalert_admin.form.theme_id")} rules={[{ required: true }]} style={{ marginBottom: ".5rem" }} >
                            <TxalertThemeDropdown />
                        </Form.Item>
                        <Form.Item
                            key="active"
                            name="active"
                            label={t("contents.txalert_admin.form.isactive")}
                            valuePropName="checked"
                            style={{ marginBottom: ".5rem" }}
                        >
                            <Switch />
                        </Form.Item>

                    </fieldset>
                </Form>
            </Modal>

        </>
    );
};

export default Txalert_EmailTemplate;
