
import React, { useState } from "react";
import { Table } from "antd";
import moment from "moment";
import CustomPaginationV2 from "../../../helper/Table/CustomPaginationV2";
import { API_TXALERT2 } from "../../../config/config";
import ColumnResizable from "../../../helper/Table/ColumnResizable";
import MergeColumns from "../../../helper/Table/MergeColumns";
import DataFetchHookTxAlert from "../../../helper/DataFetchHookTxAlert";

const DATA_URL = API_TXALERT2 + "/credit_request" ;

const Txalert_AlertCreditsTbl = (props) => {
    const [{ data, totalResult, isLoading, isError, errorMsg, requestData, isUpdated }, setUrl, setRequestData] = DataFetchHookTxAlert(DATA_URL+'?alert_id='+props.id,"GET", localStorage.getItem("txalert_token")); // eslint-disable-line

    const [currentPage, setCurrentPage] = useState(1);
    const { t } = props;

  
const columns = [
   {
      dataIndex: "datecreated",
      key: "datecreated",
      title: t("tables.fields.datecreated"),
      width: 150,
      ellipsis: true,
      render: (datecreated) => getFormatDate(datecreated),
  },
  {
      dataIndex: "reason",
      key: "reason",
      title: t("tables.fields.reason"),
      width: 80,
      ellipsis: true,
  },
  {
      dataIndex: "status",
      key: "status",
      title: t("tables.fields.status"),
      width: 80,
      ellipsis: true,
  },
  {
      dataIndex: "credit_requested_on",
      key: "credit_requested_on",
      title: t("tables.fields.requested_date"),
      width: 150,
      ellipsis: true,
      render: (credit_requested_on) => getFormatDate(credit_requested_on),
  },
  {
      dataIndex: "credit_received_on",
      key: "credit_received_on",
      title: t("tables.fields.received_date"),
      width: 150,
      ellipsis: true,
      render: (credit_received_on) => getFormatDate(credit_received_on),
  },
  {
      dataIndex: "comment",
      key: "comment",
      title: t("tables.fields.comment"),
      width: 180,
      ellipsis: true,
  },
  {
      dataIndex: "credit_status_reason",
      key: "credit_status_reason",
      title: t("tables.fields.status_reason"),
      width: 100,
  }
 ];
 const getFormatDate = (date) => (Date.parse(date) ? moment(date).format("DD-MM-YYYY HH:mm:ss") : "");

 const getDataSource = (datalist) => (datalist && Array.isArray(datalist) ? datalist : []);

 return (
     <div>            
         <Table
             size="small"
             loading={isLoading}
             components={{
                 header: {
                     cell: ColumnResizable,
                 },
             }}
             columns={MergeColumns(columns)}
             rowKey="alert_credit_id"
             className="table-v3 custom-pagination custom-pagination-v3"
             dataSource={getDataSource(data.datalist)}
             pagination={CustomPaginationV2({ totalResult, currentPage, requestData, setRequestData, setCurrentPage })}

         />
     </div>
 );

}
export default Txalert_AlertCreditsTbl