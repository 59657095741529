
import React, { useState, useEffect} from "react";
import { Form, Button,Modal, message, Upload,Progress, Checkbox,Input } from "antd";
import { UploadOutlined } from '@ant-design/icons';

import { API_TXALERT2 } from "../../../config/config";
import { fileUpload} from "../../../helper/FileUploadFunctions";


//const chunkSize = 1048576 * 2;//its 2MB, increase the number measure in mb

const Txalert_IssuerBinImport = (props) => {
    const { t,isUploadOpen,setIsUploadOpen, fetchList } = props; //eslint-disable-line
    const [loading, setLoading] = useState(false);
    const importUrl = API_TXALERT2 +'/issuer_bin/importcsv';
    const [fileToBeUpload, setFileToBeUpload] = useState(null); 
    const [fileList, setFileList] = useState([]);
    const [progress,setProgress] = useState(0);
    const [buttonText, setButtonText] = useState('Cancel');
   
    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 16 },
  };

  const initData = {
    email: JSON.parse(localStorage.getItem("loggedUser")).email,
    delimiter: ";",
    headerrow: 0
};

    const [form] = Form.useForm();
   

    useEffect(() => {
      if(isUploadOpen === true){
      form.setFieldsValue(initData);
      }
  }, [isUploadOpen]);// eslint-disable-line

    const handleOk = () => {
        setLoading(true);
        //setUpFile(file);
        fileUpload({
            file: fileToBeUpload, // Assuming you have a file selected in the list
            onSuccess: () => {
              // Handle success if needed
              setFileToBeUpload(null);
              setLoading(false);
              message.success(`${fileToBeUpload.name} file uploaded successfully`);
              setButtonText('OK');
            },
            onError: (error) => {
              // Handle error if needed
              setLoading(false);
              message.error(`${fileToBeUpload.name} file upload failed.${error}`);
            },
            onProgress:({percent}) =>{
              setProgress(parseFloat(percent.toFixed(2)));
            }           
          }, importUrl,form.getFieldsValue(true));
    };

   
    const handleCancel = () => {  
      removeFile(0);      
        setLoading(false);       
        setIsUploadOpen(false);
        setProgress(0);
        
    };

  const prepareUploadfile = (file) => {
    const isCSV = file.type === 'text/csv';
        if (!isCSV) {
          message.error(`${file.name} is not a csv file`);
          return false;
        }
            
      setFileList([file]);    
      setFileToBeUpload(file);
      setProgress(0);
    };


      
    function removeFile(index) {
      // Remove the selected file from the list
      fileList.splice(index, 1);

  }

    const uploaderProps = {    
        accept: "text/csv",
        maxCount: 1,
        customRequest: fileUpload,
        beforeUpload: file => {
          
          prepareUploadfile(file);
          return false;
        },
        fileList:fileList,
        progress: {
          strokeColor: {
            '0%': '#108ee9',
            '100%': '#87d068',
          },
          strokeWidth: 3,
          format: percent => percent && `${parseFloat(percent.toFixed(2))}%`,
        }
      };

    return (
        <>
         <Modal
                title={t("contents.txalert_admin.import_issuerbin")}
                visible={isUploadOpen}
                width={600}
                onCancel={handleCancel}
                onOk={handleOk}
                bodyStyle={{ paddingTop: '0px' }}
                footer={[
                    <Button key="back" onClick={() => handleCancel()}>
                        {buttonText}
                    </Button>,
                    
                    <Button key="submit" type="primary" loading={loading} disabled={!fileToBeUpload} onClick={() => handleOk()}>
                        {loading ? t("contents.txalert_admin.form.submitting") : t("contents.txalert_admin.form.submit")}
                    </Button>,
                ]}
            >
              <Form {...layout} form={form}   >
                    <fieldset >
                      <span>Columns in csv:<br/>bin,brand,bank_name,type,level,country_name,country_iso_2,country_iso_3,iso_number,www,phone</span><br/>
                        <Form.Item name="delimiter" label={t("contents.txalert_admin.form.delimiter")} rules={[{ required: true }]} style={{ marginBottom: ".5rem" }} >
                            <Input />
                        </Form.Item>
                        <Form.Item name="email" label={t("contents.txalert_admin.form.email")} rules={[{ required: true }]} style={{ marginBottom: ".5rem" }} >
                            <Input />
                        </Form.Item>
                        <Form.Item name="headerrow" label={t("contents.txalert_admin.form.header_row_present")} style={{ marginBottom: ".5rem" }} valuePropName="checked">
                            <Checkbox />
                        </Form.Item>
                       
                                                                    
                    </fieldset>
                </Form>
                  
                  <Upload {...uploaderProps}> <Button icon={<UploadOutlined />}>Choose Import CSV File</Button></Upload>
                  {progress > 0 ? <Progress percent={progress} /> : null}
                  <span className="d-none d-md-inline-block">Once the file is uploaded, an email will be sent to the email provided.</span>
            </Modal>
        </>
    );
}
export default Txalert_IssuerBinImport;