
import React, { useState, useEffect } from "react";
import { Form, Input, Button, Modal, DatePicker } from "antd";
import { API_TXALERT2 } from "../../../config/config";
import axios from "axios";
import Swal from "sweetalert2";
import GroupAccess from "../../../helper/GroupAccess";
import TxalertCreditStatusReasonDropdown from "../Components/CreditStatusReasonDropdown";
import TxalertCreditStatusDropdown from "../Components/CreditStatusDropdown";
import TextArea from "antd/lib/input/TextArea";
import moment from "moment";
import CatchError from "../../../helper/CatchError";

const Txalert_AlertCreditRequest = (props) => {
    const { t, id, setId, isOpen, setIsOpen, fetchList } = props;
    const [details, setDetails] = useState(null);
    const [loading, setLoading] = useState(false);
    const [componentDisabled, setComponentDisabled] = useState(GroupAccess("SYSADMIN") ? false : true); // eslint-disable-line
    const [showReqDate, setShowReqDate] = useState(false);
    const [showRecvdDate, setShowRecvdDate] = useState(false);

    const layout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };

    const initData = {
        alert_id:null,
        credit_requested_on:null,
        status_update_date:null,
        credit_status: null,
        credit_received_on: null,
        credit_status_reason:"",
        statement_id: "",
        provider_statement_id: "",
        credit_reason: null,
        invoice_number: "",
        comment: "",
    };

    const handleOk = () => {
        setLoading(true);
        onFinish();
    };

  

    const [form] = Form.useForm();

    const handleCancel = () => {
        setLoading(false);
        setId('');
        form.setFieldsValue(initData);
        setIsOpen(false);

    };

    const formValChange = (chgV, allV) => {

        setDetails(allV);
         //if credit_status = 'new','pending','other','ineligible' no date is shown
        //if credit_status = 'requested' show requested date
        //if credit_status = 'approved','declined' show received date
        
        if(chgV.credit_status)
        {
            
            if(['REQUESTED'].some((arrVal) => chgV.credit_status.label === arrVal))
            {
                setShowReqDate(true);
                setShowRecvdDate(false);
            }else if(['DECLINED','APPROVED'].some((arrVal) => chgV.credit_status.label === arrVal)){
                setShowReqDate(false);
                setShowRecvdDate(true);
            }else{
                setShowReqDate(false);
                setShowRecvdDate(false);
            }
       
        }
    };

    useEffect(() => {

        if (isOpen) {
            if (typeof id !== 'undefined') {

                getDetails();
            } else {
                setDetails(initData);
            }
        }
    }, [isOpen]); // eslint-disable-line


    const getDetails = async () => {

        const api_url = API_TXALERT2 + `/credit_request/${id}`;
        await axios
            .get(api_url, { headers: { Authorization: localStorage.getItem("txalert_token") } })
            .then(({ data }) => {
                let recdata = data;                
                if(['REQUESTED'].some((arrVal) => recdata.status === arrVal))
                {
                    
                    setShowReqDate(true);
                    setShowRecvdDate(false);
                }else if(['DECLINED','APPROVED'].some((arrVal) => recdata.status === arrVal)){
                    setShowReqDate(false);
                    setShowRecvdDate(true);
                }else{
                    setShowReqDate(false);
                    setShowRecvdDate(false);
                }
                setDetails(recdata);
                form.setFieldsValue({...recdata,  credit_status:{value:recdata.credit_status,label:recdata.status}});
            })
            .catch((error) => console.log(error));
    };

    const onFinish = () => {
        let apiUrl = API_TXALERT2;
        let postparams = {...details};
        if (id === 'undefined' || typeof id === 'undefined' || id === '') {
            apiUrl = apiUrl + "/credit_request";
            
        } else {
            apiUrl = apiUrl + "/credit_request/" + id;
            //remove status_update_date from array
            const {status_update_date, ...rest} = details;
            postparams =  rest;
        }
        
        
        postparams.alert_id = parseInt(postparams.alert_id);
        postparams.credit_reason = postparams.credit_reason?parseInt(postparams.credit_reason):null;
        postparams.credit_status = postparams.credit_status?parseInt(postparams.credit_status.value):null;
      
        axios
            .post(apiUrl, postparams, { headers: { Authorization: "Bearer " + localStorage.getItem("txalert_token") } })
            .then(({ data }) => {
                if (data.status === "OK") {
                    Swal.fire({
                        icon: "success",
                        title: data.msg,
                        showConfirmButton: false,
                        timer: 2000,
                        onClose: () => {
                            fetchList();
                        },
                    });
                    handleCancel();
                } else {

                   CatchError(data);
                }
            })
            .catch((error) => {
                console.log(error.response);
                setLoading(false);
                CatchError(error);
            });
    };
    const getDateFormat = date => {
        var d = moment(date);
        return date && d.isValid() ? d : '';
      };


    const isOkButtonProps = () => !details || loading;
    return (
       
        <>
            <Modal
                title={id !== undefined ? t("contents.txalert_admin.form.view_credit_request") + ' id:' + id : t("contents.txalert_admin.form.add_credit_request")}
                visible={isOpen}
                width={600}
                onCancel={handleCancel}
                onOk={handleOk}
                bodyStyle={{ paddingTop: '0px' }}
                footer={[
                    <Button key="back" onClick={() => handleCancel()}>
                        {t("contents.txalert_admin.form.cancel")}
                    </Button>,
                    GroupAccess("SYSADMIN") ?
                        <Button key="submit" type="primary" loading={loading} disabled={isOkButtonProps()} onClick={() => handleOk()}>
                            {loading ? t("contents.txalert_admin.form.submitting") : t("contents.txalert_admin.form.submit")}
                        </Button> : '',
                ]}
            >
                <Form {...layout} form={form} name="merchant_detail" onValuesChange={formValChange}  >
                    <fieldset disabled={componentDisabled}>
                        <Form.Item key="alert_id" name="alert_id" label={t("contents.alerts.alert_id")} disabled style={{ marginBottom: ".5rem" }} >
                            <Input />
                        </Form.Item>
                        <Form.Item key="credit_reason"  name="credit_reason" label={t("contents.txalert_admin.form.credit_reason")} style={{ marginBottom: ".5rem" }} >
                          <TxalertCreditStatusReasonDropdown />
                        </Form.Item>
                        <Form.Item key="credit_status" name="credit_status" label={t("contents.txalert_admin.form.credit_status")} rules={[{ required: true }]} style={{ marginBottom: ".5rem" }} >
                            <TxalertCreditStatusDropdown  labelInValue={true}/>
                        </Form.Item>
                        <Form.Item key="credit_status_reason" name="credit_status_reason" label={t("contents.txalert_admin.form.status_reason")} style={{ marginBottom: ".5rem" }} >
                           <Input />
                        </Form.Item>
                       
                        <Form.Item key="credit_received_on" name="credit_received_on"  hidden={!showRecvdDate} label={t("contents.txalert_admin.form.received_date")}  style={{ marginBottom: ".5rem" }} valuePropName="value" getValueProps={(value) => {
                            return { value: getDateFormat(value) };
                          }} >
                            <DatePicker format="DD-MM-YYYY"/>
                        </Form.Item>
                       
                        <Form.Item key="credit_requested_on" name="credit_requested_on" hidden={!showReqDate} label={t("contents.txalert_admin.form.requested_date")}  style={{ marginBottom: ".5rem" }} valuePropName="value" getValueProps={(value) => {
                            return { value: getDateFormat(value) };
                          }}>
                            <DatePicker format="DD-MM-YYYY"/>
                        </Form.Item>
                          {id !== undefined ?(
                        <Form.Item key="status_update_date" name="status_update_date"  label={t("contents.txalert_admin.form.status_update_date")}  style={{ marginBottom: ".5rem" }} valuePropName="value" getValueProps={(value) => {
                            return { value: getDateFormat(value) };
                          }} >
                            <DatePicker disabled open={false}/>
                        </Form.Item>) :''}
                        <Form.Item  key="statement_id" name="statement_id" label={t("contents.txalert_admin.form.statement_id")}  style={{ marginBottom: ".5rem" }} >
                            <Input />
                        </Form.Item>
                        <Form.Item key="provider_statement_id" name="provider_statement_id" label={t("contents.txalert_admin.form.provider_statement_id")} style={{ marginBottom: ".5rem" }} >
                            <Input />
                        </Form.Item>
                        <Form.Item key="invoice_number" name="invoice_number" label={t("contents.txalert_admin.form.invoice_number")} style={{ marginBottom: ".5rem" }} >
                            <Input />
                        </Form.Item>
                        

                        <Form.Item name="comment" label={t("contents.txalert_admin.form.comment")} style={{ marginBottom: ".5rem" }} >
                            <TextArea />
                        </Form.Item>
                    </fieldset>
                </Form>
            </Modal>

        </>
    );
};

export default Txalert_AlertCreditRequest;
