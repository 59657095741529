const GroupAccess = (strgroups) => {
    if(localStorage.loggedUser) {
        const usergroups = JSON.parse(localStorage.getItem("loggedUser")).groups;
        const grouplist = strgroups.toUpperCase().split("|");

        return usergroups.map((usergroup) => grouplist.includes(usergroup.toUpperCase())).reduce((a, b) => a || b);
    } else {
        return null;
    }
};
export default GroupAccess;
