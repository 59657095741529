import React, { useState } from "react";
import { Table } from "antd";
import CustomPaginationV2 from "../../../helper/Table/CustomPaginationV2";
import ColumnResizable from "../../../helper/Table/ColumnResizable";
import MergeColumns from "../../../helper/Table/MergeColumns";
import DataFetchHookTxAlert from "../../../helper/DataFetchHookTxAlert";
import moment from "moment";

const Txalert_EnrollmentHistoryTbl = (props) => {
    const [{ data, totalResult, isLoading, isError, errorMsg, requestData, isUpdated }, setUrl, setRequestData] = DataFetchHookTxAlert(props.url,"GET", localStorage.getItem("txalert_token")); // eslint-disable-line
    const [currentPage, setCurrentPage] = useState(1);
    const { t } = props;

  
    

    const columns = [
        {
            dataIndex: "provider_name",
            key: "provider_name",
            title: t("contents.descriptors.tabs.table.providername"),
            width: 130
        },
        {
            dataIndex: "status",
            key: "status",
            title: t("contents.descriptors.tabs.table.status"),
            width: 110
        },        
        {
            dataIndex: "effective_date",
            key: "effective_date",
            title: t("contents.descriptors.tabs.table.effective_date"),
            width: 120,
            render: (effective_date) => (effective_date == null ? '' : moment(effective_date).format("YYYY-MM-DD HH:mm:ss"))
        },        
        {
            dataIndex: "update_date",
            key: "update_date",
            title: t("contents.descriptors.tabs.table.update_date"),
            width: 120,
            render: (update_date) => (update_date == null ? '' : moment(update_date).format("YYYY-MM-DD HH:mm:ss"))
        }
    ];

    const getDataSource = (datalist) => (datalist && Array.isArray(datalist) ? datalist : []);
    const getHeaderTable = () => (
        <div className="custom-table--header d-flex justify-content-between align-items-center">
            <h3 className="table-title">{t("contents.descriptors.tabs.enrollment_history")}</h3>
        </div>
    );
   
    return (
        <div>            
            <Table
                size="small"
                loading={isLoading}
                components={{
                    header: {
                        cell: ColumnResizable,
                    },
                }}
                title={() => getHeaderTable()}
                columns={MergeColumns(columns)}
                rowKey={props.rowkey}
                className="table-v3 custom-pagination custom-pagination-v3"
                dataSource={getDataSource(data.datalist)}
                pagination={CustomPaginationV2({ totalResult, currentPage, requestData, setRequestData, setCurrentPage })}

            />
        </div>
    );
};


export default Txalert_EnrollmentHistoryTbl;
