import React, { useState, useEffect} from "react";
import { Table, PageHeader, Card, Form, Select, Row, Col, Button, Space, Input } from "antd";
import { SearchOutlined, FileSearchOutlined, PlusOutlined } from "@ant-design/icons";
import CustomPaginationV2 from "../../../helper/Table/CustomPaginationV2";
import { API_TXALERT2 } from "../../../config/config";
import ColumnResizable from "../../../helper/Table/ColumnResizable";
import MergeColumns from "../../../helper/Table/MergeColumns";
import DataFetchHookTxAlert from "../../../helper/DataFetchHookTxAlert";
import TxalertAlertCreditRequest from "./AlertCreditRequest";
import TxalertProviderListDropdown from "../Components/ProviderlistDropdown"
import CustomDateRangeV3 from "../../../helper/Table/CustomDateRangeV3";
import TxalertMerchantListDropdown from "../Components/MerchantListDropdown";
import TxalertCreditStatusDropdown from "../Components/CreditStatusDropdown";
import moment from "moment";
import TextSorter from "../../../helper/Table/TextSorter";

const DATA_URL = API_TXALERT2 + "/credit_request";
const { Option } = Select;
const MAX_DATERANGE = 3;
const DEFAULT_DATE_RANGE = 7;
const DEFAULT_DATE_PERIOD = "days";
const START_DATE = moment().subtract(DEFAULT_DATE_RANGE, DEFAULT_DATE_PERIOD);
const END_DATE = moment();


const Txalert_AlertCreditRequests = (props) => {
    // eslint-disable-next-line
    const [{ data, totalResult, isLoading, isError, errorMsg, requestData, isUpdated }, setUrl, setRequestData] = DataFetchHookTxAlert(DATA_URL, "GET", localStorage.getItem("txalert_token"), {
        date_from: START_DATE.format('YYYY-MM-DD'),
        date_to: END_DATE.format('YYYY-MM-DD'),
        page: 1,
        size: 20,
        order: "DESC",
        col_order: "alert_credit_id"
    }); // eslint-disable-line
    const [formData, setFormData] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    const [id, setId] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const { t } = props;
    const [dates, setDates] = useState([START_DATE, END_DATE]);
    const [isDateExceeded, setIsDateExceeded] = useState(false);



    const initFormData = {
        dateRange: [START_DATE, END_DATE],
        keyword: "",
        field: null,
        merchant: undefined,
        provider: undefined,
        status: undefined

    };

    const [form] = Form.useForm();

    useEffect(() => {       
            formValInit();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const getCreditList = () => {

        let searchData = {};
        setRequestData({ ...requestData, ...searchData, page: 1 });

    };

    const showModal = (selectedid) => {
       
        setId(selectedid);
        setIsOpen(true);

    };

    const handleChange = (pagination, filters, sorter, { currentDataSource }) => {
            setRequestData({ ...requestData,  order: sorter.order==='ascend'?'ASC':'DESC', col_order: sorter.field?sorter.field:'alert_id', page: 1, size:pagination.pageSize });
       };
       

    const formValInit = () => {
        setFormData(initFormData);
        form.setFieldsValue(initFormData);
    };

    const clearAll = () => {
        setCurrentPage(1);
        setDates([START_DATE, END_DATE]);
        setFormData(initFormData);
        setRequestData({date_from: START_DATE.format('YYYY-MM-DD'),date_to: END_DATE.format('YYYY-MM-DD')});
        form.setFieldsValue(initFormData);
    };

    const formValChange = (chgV, allV) => {
       if(chgV.status)
       {
        setFormData({...allV, [`status`]:chgV.status?allV.status.label:null});
       }else{
        setFormData(allV);
       }
    };



    const onFinish = () => {
      
        let startDate = null;
        let endDate = null;
        let searchData = {};
        let searchkeyword = formData.keyword;
        if (formData) {
            if (dates) {
                startDate = dates[0] ? dates[0] : null;
                endDate = dates[1] ? dates[1] : null;
            }
            searchkeyword = searchkeyword ? searchkeyword.toString().trim() : null //convert to string, if dropdown
            searchData = {  
                date_from: startDate.format('YYYY-MM-DD'),
                date_to: endDate.format('YYYY-MM-DD')
            };
            if(formData.status)
            {
            let selected_status = formData.status.map((statusrec) => (statusrec.label));  //egt only the labels for search
            searchData= {...searchData,status:selected_status};
            }
            if(formData.field)
            {
                searchData= {...searchData,[formData.field]:searchkeyword};
            }

            if(formData.merchant)
            {
                searchData= {...searchData,merchant_id:formData.merchant};
            }

            if(formData.provider)
            {
                searchData= {...searchData,alert_provider_id:formData.provider};
            }
        }

        // Reset Page Index to 1
        setCurrentPage(1);
        setRequestData(searchData);
    };

    const columns = [
        {
            dataIndex: "datecreated",
            key: "datecreated",
            title: t("tables.fields.credit_created_date"),
            width: 150,
            sorter: {
                compare: (a, b) => TextSorter(a.datecreated,b.datecreated)
            },
        },
        {
            dataIndex: "alert_id",
            key: "alert_id",
            title: t("tables.fields.alertid"),
            width: 150,
            sorter: {
                compare: (a, b) => a.alert_id-b.alert_id
            },
            render: (id, record) => (
                <a href={`/alertdetail/${id}`} target="_blank" rel="noopener noreferrer">
                    {id}
                </a>
            )
        },
        {
            dataIndex: "provider_name",
            key: "provider_name",
            title: t("tables.fields.providername"),
            width: 150,
            sorter: {
                compare: (a, b) => TextSorter(a.provider_name,b.provider_name)
            },
        },
        {
            dataIndex: "provider_case_id",
            key: "provider_case_id",
            title: t("tables.fields.provider_caseid"),
            width: 150,
            sorter: {
                compare: (a, b) => TextSorter(a.provider_case_id,b.provider_case_id)
            },
        },        
        {
            dataIndex: "status",
            key: "status",
            title: t("tables.fields.status"),
            width: 150,
            sorter: {
                compare: (a, b) => TextSorter(a.status,b.status),
            },
        },
        {
            dataIndex: "action",
            title: t("tables.fields.action"),
            align: "left",
            width: 100,
            render: (text, record) => (
                <Space size="middle">
                    <Button type="link" onClick={() => showModal(record.alert_credit_id)} >
                        <FileSearchOutlined style={{ color: "rgba(0, 0, 0, 0.85)", fontSize: "18px" }} />
                    </Button>
                </Space>
            ),
        }
    ];

    const getDataSource = (datalist) => (datalist && Array.isArray(datalist) ? datalist : []);


    const AdvancedSearchForm = () => (
        <div className="search-wrap">
            <Card title={t("tables.actions.search")} size="small">
                <Form form={form} size="large" name="advanced_search" className="ant-advanced-search-form" onValuesChange={formValChange} onFinish={onFinish}>
                    <Row gutter={[16, 16]}>
                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                            <Form.Item name="dateRange"
                                rules={[
                                    {
                                        validator: () => {
                                            if (isDateExceeded) {
                                                return Promise.reject(t('max_3_months'));
                                            } else if ((dates[0] && !dates[1]) || (!dates[0] && dates[1])) {
                                                return Promise.reject(t('min_1_day'));
                                            } else {
                                                return Promise.resolve();
                                            }
                                        }
                                    }
                                ]}
                            >
                                <CustomDateRangeV3 t={t} setIsDateExceeded={setIsDateExceeded} state={{ dates, setDates }} unit="months" limit={MAX_DATERANGE} />
                            </Form.Item>
                        </Col>

                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                            <Form.Item name="field">
                                <Select
                                    showSearch
                                    optionFilterProp="children"
                                    style={{ width: "100%" }}
                                    placeholder={t("tables.fields.field")}>
                                    <Option value="alert_id">{t("contents.alerts.alert_id")}</Option>
                                    <Option value="statement_id">{t("contents.txalert_admin.form.statement_id")}</Option>
                                    <Option value="provider_caseid">{t("tables.fields.provider_caseid")}</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 6 }} xl={{ span: 5 }}>
                            <Form.Item name="keyword">
                                <Input placeholder={t("tables.fields.keyword")} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[16, 16]}>
                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                            <Form.Item name="merchant">
                                <TxalertMerchantListDropdown mode="multiple" placeholder="Merchants" showInactiveOption={true} />
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                            <Form.Item name="provider">
                                <TxalertProviderListDropdown mode="multiple" placeholder="Providers" />
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                            <Form.Item name="status">
                                <TxalertCreditStatusDropdown mode="multiple" placeholder="Status" labelInValue={true} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[16, 24]}>
                        <Col  className="gutter-row"
                            xs={{ span: 24 }}
                            sm={{ span: 12 }}
                            md={{ span: 6, offset: 12 }}
                            lg={{ span: 6, offset: 12 }}
                            xl={{ span: 3, offset: 17 }}>
                            <Form.Item>
                                <Button type="secondary" onClick={() => clearAll()} block>
                                    {t("tables.actions.clear_all")}
                                </Button>
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 4 }}>
                            <Form.Item>
                                <Button type="primary" htmlType="submit" block>
                                    <SearchOutlined /> {t("tables.actions.search")}
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Card>
        </div>
    );

    const getHeaderTable = () => (
        <div className="custom-table--header d-flex justify-content-between align-items-center">
            <h3 className="table-title">{t("contents.txalert_admin.credit_requests")}</h3>
            <Button type="link" onClick={() => showModal()} style={{ marginLeft: "auto" }}>
                <PlusOutlined /> {t("contents.txalert_admin.form.new_credit_request")}
            </Button>

        </div>
    );

    return (
        <div>
            <PageHeader title={t("contents.txalert_admin.credit_requests")}></PageHeader>
            {AdvancedSearchForm()}
            <Table
                size="small"
                loading={isLoading}
                components={{
                    header: {
                        cell: ColumnResizable,
                    },
                }}
                onChange={handleChange}
                sortDirections={["ascend", "descend", "ascend"]}
                columns={MergeColumns(columns)}
                title={() => getHeaderTable()}
                rowKey="alert_credit_id"
                className="table-v3 custom-pagination custom-pagination-v3"
                dataSource={getDataSource(data.datalist)}
                pagination={CustomPaginationV2({ totalResult, currentPage, requestData, setRequestData, setCurrentPage })}

            />
            <TxalertAlertCreditRequest t={t} id={id} setId={setId} isOpen={isOpen} setIsOpen={setIsOpen} fetchList={getCreditList} />
        </div>
    );
};


export default Txalert_AlertCreditRequests;
