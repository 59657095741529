import React, { useState, useEffect } from "react";
import DataFetchHookCB from "../../helper/DataFetchHookCB";
import PermissionsRequired from "../../helper/PermissionsRequired";
import CreateProvider from "./CreateProvider";
import ProviderModal from "./Provider";
import moment from "moment";
import { Table, PageHeader, Button, Card, Form, Row, Col, Select, Input } from "antd";
import { SearchOutlined, FileSearchOutlined } from "@ant-design/icons";
import { CBM_URL } from "../../config/config";
import CustomPaginationV2 from "../../helper/Table/CustomPaginationV2";
import SortOrder from "../../helper/Table/SortOrder";
import Swal from "sweetalert2";

const { Option } = Select;

const DATA_URL = CBM_URL + "/api/v1/providers";

const ChargebackProviderList = (props) => {
    const [{ data, totalResult, requestData, isLoading, isError, errorMsg }, doFetch, setRequestData, setMethod, setToken] = DataFetchHookCB(DATA_URL,"POST",localStorage.getItem("cbm_token"),{page:1,size:20,order:'ASC',orderVar:'name'}); // eslint-disable-line
    const [formData, setFormData] = useState();
    const [provider, setProvider] = useState(null);
    const [sortedInfo, setSortedInfo] = useState({});
    const [currentPage, setCurrentPage] = useState(1);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const { t } = props;

    const initFormData = {
        keyword: "",
        field: "name",
        //  order: null,
    };

    const [form] = Form.useForm();

    useEffect(() => {     
        if(isLoading === false)
        {             
            if(errorMsg)
            {
                Swal.fire({
                    icon: "error",
                    title: errorMsg,
                    showConfirmButton: true,
                });
            }
            formValInit();
        }
    }, [isLoading]);  // eslint-disable-line

    const showProvider = (pid) => {
        setProvider(pid);
        setIsModalVisible(true);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const handleChange = (pagination, filters, sorter, { currentDataSource }) => {
        setSortedInfo(sorter);
    };

    const formValInit = () => {
        setFormData(initFormData);
        form.setFieldsValue(initFormData);
    };

    const clearAll = () => {
        setFormData(initFormData);
        setRequestData({ ...requestData, ...initFormData, page: 1 });
        form.setFieldsValue(initFormData);
    };

    const formValChange = (chgV, allV) => {
        setFormData(allV);
    };

    const onFinish = () => {
        let searchData = {};
        if (formData) {
            searchData = {
                field: formData.field,
                keyword: formData.keyword ? formData.keyword.trim() : null,
                //  order: formData.order,
            };
        }
        // Reset Page Index to 1
        setCurrentPage(1);
        setRequestData({ ...requestData, ...searchData, page: 1 });
    };

    const AdvancedSearchForm = () => (
        <div className="search-wrap">
            <Card title={t("tables.actions.search")} size="small">
                <Form form={form} size="large" name="advanced_search" className="ant-advanced-search-form" onValuesChange={formValChange} onFinish={onFinish}>
                    <Row gutter={[16, 16]}>
                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 6 }} xl={{ span: 5 }}>
                            <Form.Item name="field">
                                <Select style={{ width: "100%" }} placeholder={t("tables.fields.field")}>
                                    <Option value="name">{t("tables.fields.name")}</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 6 }} xl={{ span: 8 }}>
                            <Form.Item name="keyword">
                                <Input placeholder={t("tables.fields.keyword")} />
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6, offset: 12 }} lg={{ span: 6 }} xl={{ span: 3, offset: 4 }}>
                            <Form.Item>
                                <Button type="secondary" onClick={() => clearAll()} block>
                                    {t("tables.actions.clear_all")}
                                </Button>
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 4}}>
                            <Form.Item>
                                <Button type="primary" htmlType="submit" block>
                                    <SearchOutlined /> {t("tables.actions.search")}
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Card>
        </div>
    );

    const columns = [
        {
            dataIndex: "name",
            key: "name",
            title: t("tables.fields.providername"),
            sorter: {
                compare: (a, b) => a.name.localeCompare(b.name),
                multiple: 1,
            },
            sortOrder: SortOrder(sortedInfo, "name"),
        },
        {
            dataIndex: "classfile",
            key: "classfile",
            title: t("tables.fields.classfile"),
            sorter: {
                compare: (a, b) => a.name.localeCompare(b.classfile),
                multiple: 1,
            },
            sortOrder: SortOrder(sortedInfo, "classfile"),
        },
        {
            dataIndex: "apiurl",
            key: "apiurl",
            title: t("tables.fields.apiurl"),
            sorter: {
                compare: (a, b) => a.name.localeCompare(b.apiurl),
                multiple: 1,
            },
            sortOrder: SortOrder(sortedInfo, "apiurl"),
        },
        {
            dataIndex: "autoimport",
            key: "autoimport",
            title: t("tables.fields.autoimport"),
            render: (autoimport) => (autoimport === 1?'Yes':'No'),
            sorter: {
                compare: (a, b) => a.autoimport -b.autoimport,
                multiple: 1,
            },
           sortOrder: SortOrder(sortedInfo, "autoimport"),
        },
        {
            dataIndex: "isactive",
            key: "isactive",
            title: t("tables.fields.active"),
            render: (isactive) => (isactive === 1?'Yes':'No'),
            sorter: {
                compare: (a, b) => a.isactive -b.isactive,
                multiple: 1,
            },
            sortOrder: SortOrder(sortedInfo, "isactive"),
        },
        {
            dataIndex: "action",
            title: t("tables.fields.action"),
            align: "left",
            fixed: "right",
            width: 120,
            render: (text, record) => (
                <Button type="link" size="small" onClick={() => showProvider(record.pid)} style={{ marginLeft: "auto" }}>
                    <FileSearchOutlined style={{ color: "rgba(0, 0, 0, 0.85)", fontSize: "18px" }} />
                </Button>
            ),
        },
    ];

    const fetchProviders = () => doFetch(DATA_URL + `?` + moment().unix());

    const getDataSource = (datalist) => (datalist && Array.isArray(datalist) ? datalist : []);

    const getHeaderTable = () => (
        <div className="custom-table--header d-flex justify-content-between align-items-center">
            <h3 className="table-title">{t("contents.chargebacks.providers")}</h3>
            <CreateProvider t={t} fetchProviders={fetchProviders} />
        </div>
    );

    return PermissionsRequired("SYSADMIN") ? (
        <div>
            <PageHeader title={t("contents.chargebacks.providers")}></PageHeader>
            {AdvancedSearchForm()}
            <ProviderModal t={t} pid={provider} isModalVisible={isModalVisible} handleCancel={handleCancel} fetchProviders={fetchProviders} />
            <Table
                size="small"
                loading={isLoading}
                columns={columns}
                title={() => getHeaderTable()}
                rowKey="pid"
                className="table-v3 custom-pagination"
                dataSource={getDataSource(data.datalist)}
                onChange={handleChange}
                pagination={CustomPaginationV2({ totalResult, currentPage, requestData, setRequestData, setCurrentPage })}
            />
        </div>
    ) : null;
};

export default ChargebackProviderList;
