import React, { useState } from "react";
import { Modal, Button, Tooltip } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { API } from "../../config/config";
import axios from "axios";
import Swal from "sweetalert2";

const { confirm } = Modal;

const DATA_URL = "/alert_repost/";

const RepostAlert = (props) => {
    const { t, row, posturl, doFetch } = props;
    const [loading, setLoading] = useState(false);

    const showRepostConfirm = (question, yes, no) => {
        let data = {
            alert_id: row.id,
        };

        const options = {
            method: "POST",
            headers: { "content-type": "application/json", Authorization: "Bearer " + localStorage.getItem("access_token") },
            data: JSON.stringify(data),
            url: API + DATA_URL,
        };

        confirm({
            title: question,
            icon: <ExclamationCircleOutlined />,
            okText: yes,
            cancelText: no,
            onOk() {
                setLoading(true);
                axios(options)
                    .then(({ data }) => {
                        if (data.status === "OK") {
                            Swal.fire({
                                icon: "success",
                                title: "Repost Alert Successful",
                                text: data.msg,
                                showConfirmButton: false,
                                timer: 2000,
                                onClose: () => {
                                    doFetch();
                                },
                            });
                        } else {
                            Swal.fire({
                                icon: "error",
                                title: "Repost Alert Failure",
                                text: data.msg,
                                showConfirmButton: true,
                            });
                        }
                        setLoading(false);
                    })
                    .catch((error) => {
                        setLoading(false);
                        Swal.fire({
                            icon: "error",
                            title: error,
                            showConfirmButton: true,
                        });
                    });
            },
            onCancel() {
                return false;
            },
        });
    };

    return (
        <Tooltip placement="topLeft" title={posturl ? posturl : null}>
            <Button
                key="repostAlert"
                loading={loading}
                style={{ marginRight: "0.5rem" }}
                onClick={() => showRepostConfirm("Are you sure to repost alert?", t("header.menu.logout.yes"), t("header.menu.logout.no"))}
            >
                {t("contents.alerts.repost_alert")}
            </Button>
        </Tooltip>
    );
};

export default RepostAlert;
