import React from "react";
import { Form, Input, Checkbox, Button, Select } from "antd";
import GroupAccess from "./../../../helper/GroupAccess"

const { Option } = Select;

const User_Themes = {
    0: 'Standard',
    1: 'Compact'
}

const InformationForm = (props) => {
    const { data, form, layout, tailLayout, onSubmit, onValuesChange, onCheckboxChange } = props;
    const { t } = props;

    return (
        <Form {...layout} form={form} layout="horizontal" onValuesChange={onValuesChange} size="middle" onFinish={onSubmit} style={{ padding: "15px 0" }}>
            <Form.Item name="username" label={t('contents.profile.form.username')}>
                <Input readOnly />
            </Form.Item>
            <Form.Item name="firstname" label={t('contents.profile.form.first_name')} rules={[{ required: true, message: t('contents.profile.form.required_messages.inform_fname_required') }]}>
                <Input />
            </Form.Item>
            <Form.Item name="lastname" label={t('contents.profile.form.last_name')} rules={[{ required: true, message: t('contents.profile.form.required_messages.inform_lname_required') }]}>
                <Input />
            </Form.Item>
            <Form.Item name="email" label={t('contents.profile.form.email')} rules={[{ required: true, message: t('contents.profile.form.required_messages.inform_email_required') }]}>
                <Input />
            </Form.Item>
            {GroupAccess("SYSADMIN") ? (
                <Form.Item name="login_ip" label={t('contents.profile.form.client_ip_restrictions')}>
                    <Input />
                </Form.Item>
            ) : null}
            <Form.Item name="company" label={t('contents.profile.form.company')}>
                <Input />
            </Form.Item>
            <Form.Item name="theme_compact" label={t('contents.profile.form.user_theme')}>
                <Select>
                    {Object.keys(User_Themes).map((key) => (
                        <Option key={key} value={parseInt(key)}>
                            {User_Themes[key]}
                        </Option>
                    ))}
                </Select>
            </Form.Item>
            <Form.Item label={t('contents.profile.form.position')}>
                <Checkbox name="primary_contact" onChange={(event) => onCheckboxChange(event)} defaultChecked={+data.primary_contact}>{t('contents.profile.form.primary_contact')}</Checkbox>
                <br />
                <Checkbox name="financial_contact" onChange={(event) => onCheckboxChange(event)} defaultChecked={+data.financial_contact}>{t('contents.profile.form.financial_contact')}</Checkbox>
                <br />
                <Checkbox name="technical_contact" onChange={(event) => onCheckboxChange(event)} defaultChecked={+data.technical_contact}>{t('contents.profile.form.technical_contact')}</Checkbox>
                <br />
                <Checkbox name="support_contact" onChange={(event) => onCheckboxChange(event)} defaultChecked={+data.support_contact}>{t('contents.profile.form.support_contact')}</Checkbox>
            </Form.Item>
            <Form.Item name="comment" label={t('contents.profile.form.comment')}>
                <Input.TextArea />
            </Form.Item>
            <Form.Item {...tailLayout}>
                <Button type="primary" htmlType="submit">
                    {t('contents.profile.form.submit')}
                </Button>
            </Form.Item>
        </Form>
    );
};

export default InformationForm;
