import { API_BASE_URL } from "../config/config";
import axios from "axios";
import GroupAccess from "./GroupAccess";

const ThreeDSToken = async (uid) => {
    //if user is sysadmin user use the threeds_token
    if (GroupAccess("SYSADMIN")) {
        return localStorage.getItem("threeds_token");
    } else {
        //ask txshield to get the token for the user from cbm

        let options = { headers: { Authorization: "Bearer " + localStorage.getItem("access_token") } };

        const threedstoken = await axios
            .get(API_BASE_URL + "/v3/user/" + uid + "/getThreeDSToken", options)
            .then(({ data }) => {

                if (data.token) return data.token;
                else console.log(data);
            })
            .catch((error) => {
                console.log(error);
                return false;
            });
        if (threedstoken) {
            localStorage.setItem("threeds_token", threedstoken);
        }
        return threedstoken;
    }

};
export default ThreeDSToken;