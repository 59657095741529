import React, { useState, useEffect, useCallback } from "react";
import { OI_URL } from "../../config/config";
import DataFetchHookOI from "../../helper/DataFetchHookOI";
import PermissionsRequired from "../../helper/PermissionsRequired";
import CreateDescriptor from "./CreateDescriptor";
import { Table, PageHeader, Button, Card, Form, Select, Row, Col, Input } from "antd";
import { SearchOutlined, FileSearchOutlined } from "@ant-design/icons";
import AssociationsChecker from "../../helper/AssociationsChecker";
import CustomPaginationV2 from "../../helper/Table/CustomPaginationV2";
import SortOrder from "../../helper/Table/SortOrder";
import StatusText from "../../helper/Table/StatusText";
import axios from "axios";
import TxFindAccessToken from "../../helper/TxFindAccessToken";

const DATA_URL = "/api/v1/txshield/descriptors/";
const MERCHANT_DATA_URL = "/api/v1/txshield/merchants/";
const { Option } = Select;

const DescriptorList = (props) => {

    const [{ data, totalResult, isLoading, requestData }, , setRequestData] = DataFetchHookOI(DATA_URL, "POST", null, {

        page: 1,
        size: 20,
        order: "ASC",
    });
    const [formData, setFormData] = useState({ hideColumn: [], associatedMerchantIds: [] });
    const [form] = Form.useForm();
    const [currentPage, setCurrentPage] = useState(1);
    const [sortedInfo, setSortedInfo] = useState({});
    const [merchantList, setMerchantList] = useState([]);
    const { t } = props;

    const initFormData = {
        keyword: null,
        field: "title",
        operator: ["LIKE"],
        order: "ASC",
        associatedMerchantIds: [],
        status: null,
    };

    useEffect(() => {
        formValInit(); 
        getMerchantList(); 
        onSearch(); 
    }, []); // eslint-disable-line react-hooks/exhaustive-deps


    const getMerchantList = useCallback(async () => {
        try {
            const response = await axios.post(OI_URL + MERCHANT_DATA_URL, {}, { headers: { Authorization: "Bearer " + await TxFindAccessToken() } });
            setMerchantList(response.data.list || []);
        } catch (error) {
            console.error("Failed to fetch merchants", error);
        }
    }, []);

    const handleChange = (pagination, filters, sorter) => {
        setSortedInfo(sorter);
    };

    const formValInit = () => {
        setFormData(initFormData);
        form.setFieldsValue(initFormData);
    };

    const formValChange = (changedValues, allValues) => {
        setFormData(allValues);
    };

    const clearAll = () => {
        form.resetFields(); 
        setFormData(initFormData);
        
        setRequestData({
            ...requestData,
            ...initFormData,
            startDate: null,
            endDate: null,
            page: 1
        });
    
        onSearch();
        
    };
    
    const onSearch = () => {
        let searchData = {};
        if (formData) {
            searchData = {
                value: [formData.keyword ? formData.keyword.trim() : null],
                field: formData.field,
                operator: ["LIKE"],
                associatedMerchantIds: formData.associatedMerchantIds,
                status: formData.status,
            };
        }

        setCurrentPage(1);
        setRequestData({ ...requestData, ...searchData, startDate: null, endDate: null, page: 1 });
    };

    const columns = [
        {
            dataIndex: "title",
            key: "title",
            title: t("tables.fields.descriptor_title"),
            sorter: {
                compare: (a, b) => a.title.localeCompare(b.title),
            },
            sortOrder: SortOrder(sortedInfo, "title"),
        },
        {
            dataIndex: "merchantName",
            key: "merchantName",
            title: t("tables.fields.merchantname"),
            sorter: {
                compare: (a, b) => a.merchantName.localeCompare(b.merchantName),
            },
            sortOrder: SortOrder(sortedInfo, "merchantName"),
        },
        {
            dataIndex: "active",
            key: "active",
            align: "left",
            title: t("tables.fields.status"),
            render: (active) => StatusText(active ? "active" : "inactive"),
            sorter: {
                compare: (a, b) => a.active.localeCompare(b.active),
            },
            sortOrder: SortOrder(sortedInfo, "active"),
        },
        {
            dataIndex: "action",
            title: t("tables.fields.action"),
            align: "left",
            fixed: "right",
            width: 120,
            render: (text, record) => (
                <Button type="link" href={`/txfind_descriptor/${record.descriptorId}`} target="_blank">
                    <FileSearchOutlined style={{ color: "rgba(0, 0, 0, 0.85)", fontSize: "18px" }} />
                </Button>
            ),
        },
    ];

    const AdvancedSearchForm = () => (
        <div className="search-wrap">
            <Card title={t("tables.actions.search")} size="small">
                <Form form={form} size="large" name="advanced_search" className="ant-advanced-search-form" onValuesChange={formValChange} onFinish={onSearch}>
                    <Row gutter={[16, 16]}>
                        <Col xs={24} sm={12} md={8}>
                            <Form.Item name="field">
                                <Select showSearch style={{ width: "100%" }} placeholder={t("tables.fields.field")}>
                                    <Option value="title">{t("tables.fields.descriptor_title")}</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={8}>
                            <Form.Item name="keyword">
                                <Input placeholder={t("tables.fields.keyword")} />
                            </Form.Item>
                        </Col>
                        {merchantList.length > 0 && (
                            <Col xs={24} sm={12} md={8}>
                                <Form.Item name="associatedMerchantIds">
                                    <Select
                                        showSearch
                                        mode="multiple"
                                        maxTagCount="responsive"
                                        style={{ width: "100%" }}
                                        optionFilterProp="children"
                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                        placeholder={t("contents.oi.advanced_search.merchant")}
                                    >
                                        {merchantList.map((item) => (
                                            <Option key={item.merchantId} value={item.merchantId}>
                                                {item.name}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                        )}
                        <Col xs={24} sm={12} md={6} lg={4}>
                            <Form.Item>
                                <Button type="secondary" onClick={clearAll} block>
                                    {t("tables.actions.clear_all")}
                                </Button>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={6} lg={4}>
                            <Form.Item>
                                <Button type="primary" htmlType="submit" block>
                                    <SearchOutlined /> {t("tables.actions.search")}
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Card>
        </div>
    );

    const getHeaderTable = () => (
        <div className="custom-table--header d-flex justify-content-between align-items-center">
            <h3 className="table-title">{t("contents.oi.descriptors")}</h3>
            <CreateDescriptor t={t} fetchDescriptors={onSearch} />
        </div>
    );

    return PermissionsRequired("SYSADMIN") || AssociationsChecker("OI") ? (
        <div>
            <PageHeader title={t("contents.oi.descriptors")}></PageHeader>
            {AdvancedSearchForm()}
            <Table
                loading={isLoading}
                columns={columns}
                title={() => getHeaderTable()}
                rowKey="descriptorId"
                className="table-v3 custom-pagination"
                size="small"
                dataSource={data.datalist || []}
                onChange={handleChange}
                pagination={CustomPaginationV2({ totalResult, currentPage, requestData, setRequestData, setCurrentPage })}
            />
        </div>
    ) : null;
};

export default DescriptorList;
