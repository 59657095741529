import React, { useState} from "react";
import { Table, Button, Space} from "antd";
import CustomPaginationV2 from "../../../helper/Table/CustomPaginationV2";
import { API_TXALERT2 } from "../../../config/config";
import ColumnResizable from "../../../helper/Table/ColumnResizable";
import MergeColumns from "../../../helper/Table/MergeColumns";
import DataFetchHookTxAlert from "../../../helper/DataFetchHookTxAlert";
import TextSorter from "../../../helper/Table/TextSorter";
import { FileSearchOutlined } from "@ant-design/icons";
import StatusText from "../../../helper/Table/StatusText";
import TxalertDescriptorVariation from "../Descriptors/DescriptorVariation";

const DATA_URL = API_TXALERT2 + "/descriptor/" ;
const Txalert_DescriptorVariationTbl = (props) => {
    const [{ data, totalResult, isLoading, isError, errorMsg, requestData, isUpdated }, setUrl, setRequestData] = DataFetchHookTxAlert(DATA_URL+props.id+ "/variations","GET", localStorage.getItem("txalert_token")); // eslint-disable-line
    const [currentPage, setCurrentPage] = useState(1);
    const { t } = props;
    const [descriptorId, setDescriptorId] = useState('');
    const [descriptorVariationId, setDescriptorVariationId] = useState('');
    const [isOpen, setIsOpen] = useState(false);

    const fetchVariationList = () => {

        let searchData ={};
        setRequestData({ ...requestData, ...searchData,  page: 1 });

    };

    const showModal = ( descriptorVariationId, descriptorId) =>{
        setDescriptorVariationId(descriptorVariationId);
        setDescriptorId(descriptorId);
        setIsOpen(true);

    };
    

    const columns = [
        {
            dataIndex: "descriptor_text",
            key: "descriptor_text",
            title: t("tables.fields.descriptor_text"),
            sorter: {
                compare: (a, b) => TextSorter(a.descriptor_text,b.descriptor_text)
            },
        },
        {
            dataIndex: "active",
            key: "active",
            align: "left",
            title: t("tables.fields.active"),
            width: 150,
            render: (active) => StatusText(active === 1 ? "active" : "inactive"),
            sorter: {
                compare: (a, b) => TextSorter(a.active,b.active)}
        },
        {
            dataIndex: "action",
            title: t("tables.fields.action"),
            align: "left",
            width: 100,
            render: (text, record) => (
                <Space size="middle">
                    <Button type="link" onClick={() => showModal(record.descriptor_variation_id, record.descriptor_id)}>
                        <FileSearchOutlined style={{ color: "rgba(0, 0, 0, 0.85)", fontSize: "18px" }} />
                    </Button>
                </Space>
            ),
        }
    ];

    const getDataSource = (datalist) => (datalist && Array.isArray(datalist) ? datalist : []);
    const getHeaderTable = () => (
        <div className="custom-table--header d-flex justify-content-between align-items-center">
            <h3 className="table-title">{t("contents.descriptors.tabs.variations")}</h3>
        </div>
    );
   
    return (
        <div>            
            <Table
                size="small"
                loading={isLoading}
                components={{
                    header: {
                        cell: ColumnResizable,
                    },
                }}
                title={() => getHeaderTable()}
                columns={MergeColumns(columns)}
                rowKey="descriptor_variation_id"
                className="table-v3 custom-pagination custom-pagination-v3"
                dataSource={getDataSource(data.datalist)}
                pagination={CustomPaginationV2({ totalResult, currentPage, requestData, setRequestData, setCurrentPage })}

            />
            <TxalertDescriptorVariation t={t}
                                         descriptorVariationId={descriptorVariationId} setDescriptorVariationId={setDescriptorVariationId}
                                         isOpen={isOpen} setIsOpen={setIsOpen}
                                         descriptorId={descriptorId} setDescriptorId={setDescriptorId}
                                         fetchVariationList={fetchVariationList} />
        </div>
    );
};


export default Txalert_DescriptorVariationTbl;
