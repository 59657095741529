import React, { useState,useEffect } from "react";
import { Table,PageHeader } from "antd";
import CustomPaginationV2 from "../../../helper/Table/CustomPaginationV2";
import ColumnResizable from "../../../helper/Table/ColumnResizable";
import DataFetchHookTxAlert from "../../../helper/DataFetchHookTxAlert";
import { API_TXALERT2 } from "../../../config/config";
import axios from "axios";
import TextSorter from "../../../helper/Table/TextSorter";

const PROVIDER_DATA_URL = API_TXALERT2 + "/alert_provider"

//can be used for merchant descriptors and provider descriptors

const Txalert_DescriptorTbl = (props) => {
    let apiurl =  API_TXALERT2 + "/descriptor?include_variations=0" ;
    if(props.merchant_id){
        apiurl = apiurl + '&merchant_id='+props.merchant_id;
    }
    if(props.alert_provider_id)
    {
        apiurl = apiurl + '&alert_provider_id='+props.alert_provider_id;
    }
    const [{ data, totalResult, isLoading, isError, errorMsg, requestData, isUpdated }, setUrl, setRequestData] = DataFetchHookTxAlert(apiurl,"GET", localStorage.getItem("txalert_token")); // eslint-disable-line
    const [currentPage, setCurrentPage] = useState(1);
    const { t } = props;    
    const [providerList, setProviderList] = useState(false);
  
    
    useEffect(() => {
        //if showing for specific alert provider, only show that provider column 
        if (!providerList) {
          axios
              .get(
                  PROVIDER_DATA_URL+'?standard_alerts=1',
                  { headers: { Authorization: "Bearer " + localStorage.getItem("txalert_token") } }
              )
              .then( ({ data }) => {
                   setProviderList(data.list);
                  const fData = data.list.map((d) => {
                      return {
                          dataIndex: d.provider_name +'_status',
                          key: d.provider_name,
                          title: d.provider_name,
                          ellipsis: true,
                          width: 100,
                          sorter: {
                              compare: (a, b) => TextSorter( a[d.provider_name +'_status'], b[d.provider_name +'_status']) 
                          }
                      }
                  });
               
                     
                      const temp = [...columns, ...fData];
                  
                  setColumns(temp);
              })
              .catch(() => false);
      }
  }, []); // eslint-disable-line
  const [columns, setColumns] = useState([
      {
          dataIndex: "descriptor_id",
          key: "descriptor_id",
          title: t("tables.fields.id"),
          fixed:"left",
          width: 30,
          ellipsis: true,
          sorter: {
              compare: (a, b) => a.descriptor_id - b.descriptor_id
          },

          render: (id, record) => (
              <a href={`/descriptor/${id}`} target="_blank" rel="noopener noreferrer">
                  {id}{" "}
              </a>
          ),
       
          hidden: false
      },
      {
          dataIndex: "descriptor_text",
          key: "descriptor_text",
          title: t("tables.fields.descriptor"),
          ellipsis: true,
          width: 100,
          sorter: {
              compare: (a, b) => TextSorter(a.descriptor_text,b.descriptor_text)
          },
          hidden: false
      },
      {
          dataIndex: "merchant_name",
          key: "merchant_name",
          title: t("tables.fields.merchant"),
          width: 50,
          ellipsis: true,
          sorter: {
              compare: (a, b) => TextSorter(a.merchant_name,b.merchant_name)
          },
          hidden: props.merchant_id?true:false
      },
      {
          dataIndex: "status",
          key: "status",
          title: t("tables.fields.status"),
          width: 50,
          ellipsis: true,
          sorter: {
              compare: (a, b) => TextSorter(a.status,b.status)
          },
          render: (id, record) => (
              <span>
                  {record.status === 0? "In Active":record.status}
              </span>
          ),
        //  sortOrder: SortOrder(sortedInfo, "status"),
          hidden: false
      }
     
  ].filter(item => !item.hidden));

  const getDataSource = (datalist) => (datalist && Array.isArray(datalist) ? datalist : []);

  const getHeaderTable = () => (
      <div className="custom-table--header d-flex justify-content-between align-items-center">
          <h3 className="table-title">{t("contents.descriptors.title")}</h3>
          
      </div>
  );

  const mergeColumns = columns.map((col, index) => ({
      ...col,
      onHeaderCell: (column) => ({
          width: column.width,
          onResize: (_, { size }) => {
              const newColumns = [...columns];
              newColumns[index] = { ...newColumns[index], width: size.width };
              setColumns(newColumns);
          },
      }),
  }));

  const handleChange = (pagination, filters, sorter, { currentDataSource }) => {
    //multiple field sorting will not work.
      
       setRequestData({ ...requestData,  order: sorter.order==='ascend'?'ASC':'DESC', col_order: sorter.field?sorter.field:'descriptor_id', page: 1 });
   };
   
  return (
      <div>
          <PageHeader title={t("contents.descriptors.title")}></PageHeader>
          <Table
              rowKey="descriptor_variation_id"
              size="small"
              title={() => getHeaderTable()}
              loading={isLoading}
              className="table-v3 custom-pagination"
              components={{
                  header: {
                      cell: ColumnResizable,
                  },
              }}
              columns={mergeColumns}
              dataSource={data.datalist && Array.isArray(data.datalist) ? getDataSource(data.datalist) : []}
              onChange={handleChange}
              pagination={CustomPaginationV2({ totalResult, currentPage, requestData, setRequestData, setCurrentPage })}
              scroll={{ x: 1500 }}
          />
    </div>
  );
};


export default Txalert_DescriptorTbl;
