import React, { useState, useEffect } from "react";
import { Table, PageHeader, Card, Form, Select, Row, Col, Button, Input } from "antd";
import { SearchOutlined} from "@ant-design/icons";
import CustomPaginationV2 from "../../../helper/Table/CustomPaginationV2";
import { API_TXALERT2 } from "../../../config/config";
import ColumnResizable from "../../../helper/Table/ColumnResizable";
import MergeColumns from "../../../helper/Table/MergeColumns";
import DataFetchHookTxAlert from "../../../helper/DataFetchHookTxAlert";
import TextSorter from "../../../helper/Table/TextSorter";
import moment from "moment";
import CustomDateRangeV3 from "../../../helper/Table/CustomDateRangeV3";
const DATA_URL = API_TXALERT2 + "/background_process_log";
const { Option } = Select;


const MAX_DATERANGE = 3;
const DEFAULT_DATE_RANGE = 7;
const DEFAULT_DATE_PERIOD = "days";
const START_DATE = moment().subtract(DEFAULT_DATE_RANGE, DEFAULT_DATE_PERIOD);
const END_DATE = moment();

const Txalert_BackgroundProcessLogs = (props) => {
    //eslint-disable-next-line
    const [{ data, totalResult, isLoading, isError, errorMsg, requestData, isUpdated }, setUrl, setRequestData] = DataFetchHookTxAlert(DATA_URL,"GET", localStorage.getItem("txalert_token"),{
        date_from: START_DATE.format('YYYY-MM-DD'),
        date_to: END_DATE.format('YYYY-MM-DD'),
        page: 1,
        size: 20,
        order: "DESC",
        col_order:"back_process_log_id"
    }); // eslint-disable-line
    const [formData, setFormData] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    const { t } = props;
    const [isDateExceeded, setIsDateExceeded] = useState(false);
    const [dates, setDates] = useState([START_DATE, END_DATE]);

    const initFormData = {
        keyword: "",
        field: undefined,
        //  order: null,
        
    };

    const [form] = Form.useForm();

    useEffect(() => {
        formValInit();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps



    const formValInit = () => {
        setFormData(initFormData);
        form.setFieldsValue(initFormData);

       
    };

    const clearAll = () => {
        setCurrentPage(1);
        setFormData(initFormData);
        setRequestData({});
        form.setFieldsValue(initFormData);
    };

    const formValChange = (chgV, allV) => {
        setFormData(allV);
    };


    const onFinish = () => {

        let startDate = null;
        let endDate = null;
        let searchData = {};
        if (formData) {
            if (dates) {
                startDate = dates[0] ? dates[0] : null;
                endDate = dates[1] ? dates[1] : null;
            }
           
       
        if (formData) {
            let searchkeyword = formData.keyword ;
            searchkeyword = searchkeyword? searchkeyword.toString().trim() : null //convert to string, if dropdown
            searchData = {
                [formData.field]: searchkeyword,
                date_from:startDate.format('YYYY-MM-DD'),
                date_to:endDate.format('YYYY-MM-DD')
            };
        }
    }
        // Reset Page Index to 1
        setCurrentPage(1);
        setRequestData({ ...requestData, ...searchData, page: 1 });
    };

    const columns = [
        {
            dataIndex: "process_date",
            key: "process_date",
            title: t("tables.fields.date"),
            width: 50,
            render: (process_date) => moment(process_date).format("YYYY-MM-DD HH:mm:ss"),
            sorter: {
                compare: (a, b) => moment(a.process_date).diff(moment(b.process_date))
            }
        },
        {
            dataIndex: "process_file_name",
            key: "process_file_name",
            title: t("tables.fields.process_file_name"),
            width: 50,
            sorter: {
                compare: (a, b) => TextSorter(a.process_file_name, b.process_file_name)
            },
           
        },
        {
            dataIndex: "process_name",
            key: "process_name",
            title: t("tables.fields.process_name"),
            width: 50,
            sorter: {
                compare: (a, b) => TextSorter(a.process_name, b.process_name)
            }
        },
        {
            dataIndex: "status",
            key: "status",
            align: "left",
            title: t("tables.fields.status"),
            width: 150,
            sorter: {
                compare: (a, b) => TextSorter(a.status,b.status)
            }
        },
        {
            dataIndex: "message",
            key: "message",
            align: "left",
            title: t("tables.fields.message"),
            width: 150,
            sorter: {
                compare: (a, b) => TextSorter(a.message,b.message)
            }
        },
    ];

    const getDataSource = (datalist) => (datalist && Array.isArray(datalist) ? datalist : []);

    const AdvancedSearchForm = () => (
        <div className="search-wrap">
            <Card title={t("tables.actions.search")} size="small">
                <Form form={form} size="large" name="advanced_search" className="ant-advanced-search-form" onValuesChange={formValChange} onFinish={onFinish}>
                    <Row gutter={[16, 16]}>
                    <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                            <Form.Item name="dateRange"
                                rules={[
                                    {
                                        validator: () => {
                                            if(isDateExceeded){
                                                return Promise.reject(t('max_3_months'));
                                            }else if((dates[0] && !dates[1]) || (!dates[0] && dates[1])){
                                                return Promise.reject(t('min_1_day'));
                                            }else{
                                                return Promise.resolve();
                                            }
                                        }
                                    }
                                ]}
                            >
                                <CustomDateRangeV3 t={t} setIsDateExceeded={setIsDateExceeded} state={{ dates, setDates }} unit="months" limit={MAX_DATERANGE} />
                            </Form.Item>
                        </Col>
                    <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 6 }} xl={{ span: 5 }}>
                            <Form.Item name="field">
                                <Select style={{ width: "100%" }} placeholder={t("tables.fields.field")}>
                                    <Option value="process_name">{t("tables.fields.process_name")}</Option>
                                    <Option value="process_file_name">{t("tables.fields.process_file_name")}</Option>
                                    <Option value="status">{t("tables.fields.status")}</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12}} lg={{ span: 6 }} xl={{ span: 8 }}>
                            <Form.Item name="keyword">
                                <Input placeholder={t("tables.fields.keyword")} />
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6, offset: 12 }} lg={{ span: 6 }} xl={{ span: 3, offset: 4 }}>
                            <Form.Item>
                                <Button type="secondary" onClick={() => clearAll()} block>
                                    {t("tables.actions.clear_all")}
                                </Button>
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 4 }}>
                            <Form.Item>
                                <Button type="primary" htmlType="submit" block>
                                    <SearchOutlined /> {t("tables.actions.search")}
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Card>
        </div>
    );

    
    const getHeaderTable = () => (
        <div className="custom-table--header d-flex justify-content-between align-items-center">
            <h3 className="table-title">{t("contents.txalert_admin.backgroundprocesslog")}</h3>          
        </div>
    );

    return (
        <div>
            <PageHeader title={t("contents.txalert_admin.backgroundprocesslog")}></PageHeader>
            {AdvancedSearchForm()}
            <Table
                size="small"
                loading={isLoading}
                components={{
                    header: {
                        cell: ColumnResizable,
                    },
                }}
                
                columns={MergeColumns(columns)}
                title={() => getHeaderTable()}
                rowKey="back_process_log_id"
                className="table-v3 custom-pagination custom-pagination-v3"
                dataSource={getDataSource(data.datalist)}
              pagination={CustomPaginationV2({ totalResult, currentPage, requestData, setRequestData, setCurrentPage })}

            />
        </div>
    );
};

export default Txalert_BackgroundProcessLogs;
