module.exports = {
    TXSHIELD_SECURE_BASE_URL: "https://txshield.payshield.ai/secure",
    API: "https://txshield.payshield.ai/api/v2/index.php",
    API_TX: "https://txshield.payshield.ai/api/v3/txprocess",
    API_3DS_TX: "https://txshield.payshield.ai/api/v3/3ds",
    API_TXALERT: "https://txshield.payshield.ai/api/v3/txalerts",
    API_TXFRAUD: "https://txshield.payshield.ai/api/v3/txfraud",
    API_GENERAL: "",
    API_MERCHANT: "https://txshield.payshield.ai/api/v3/merchant",
    API_RETAILER: "https://txshield.payshield.ai/api/v3/retailer",
    API_SITE: "https://txshield.payshield.ai/api/v3/site",
    API_DATA_SOURCE: "https://txshield.payshield.ai/api/v3/datasource",
    API_BASE_URL: "",
    SOCKET_URL: "",
    OI_URL: "",
    OI_API_ACCESS_TOKEN: "",
    CBM_URL: "",
    CBM_TOKEN: "",
    THREEDS_URL: "",
    THREEDS_TOKEN: "",
    REALTIME_DASHBOARD: false,
    THEME: "colawallex",
    SKIP_2FA: false,
    DEV_MODE: false,
    SESSION_TIMEOUT: 15,
    DISABLE_OI: true,
    DISABLE_CBM: true,
    DISABLE_ALERTS: false,
    DISABLE_3DS: true,
    DISABLE_THREEDS_MICROSERVICE: true,
    DISABLE_PAYMENTS: true,
    DISABLE_MATCH_REFUND: false,
    WHITELABEL_DISPLAY_NAME: "COLAWALLEX USA INC",
    API_TXALERT2:"",
    TXALERT2_TOKEN:"",
    DISABLE_TXALERT2: true,
    TOKEN_ENCODING_ENABLED: "true",
    DISPLAY_PAYMENT_LINK: false,
}
