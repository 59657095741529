import React from "react";
import { Table, Card } from "antd";

const TrackingDetails = (props) => {
    const { t, data } = props; // eslint-disable-line

    const columns = [
        {
            dataIndex: "updatetime",
            key: "updatetime",
            title: t('tables.fields.date'),
        },
        {
            dataIndex: "trackingnumber",
            key: "trackingnumber",
            title: t('tables.fields.trackingnumber'),
        },
        {
            dataIndex: "status",
            key: "status",
            title: t('tables.fields.trackstatus'),
        },
        {
            dataIndex: "comm_to",
            key: "comm_to",
            title: t('tables.fields.comm_to'),
        },
        {
            dataIndex: "comm_status",
            key: "comm_status",
            title: t('tables.fields.comm_status'),
        },
    ];

    const getList = () => (
        <div className="details-info--table--wrap">
            <Table size="small" columns={columns} rowKey="trackdetailid" pagination={false} dataSource={data} />
        </div>
    );

    const getMessage = () => (
        <div className="details-info--text">
            <p>{t("contents.transactions.form.no_tracking_details")}</p>
        </div>
    );

    return (
        <Card className="details-info--card">
            <h4 className="details-info--title">{t("contents.transactions.form.tracking_details")}</h4>
            {Array.isArray(data) ? getList() : getMessage()}
        </Card>
    );
};

export default TrackingDetails;
