import React, { useState, useEffect } from "react";
import { Row, Col, Card, Typography, Badge, Checkbox } from "antd";
import MenuAccess from "./../../helper/MenuAccess";
import UserSettings from "../../helper/UserSettings";
import GroupAccess from "./../../helper/GroupAccess";

const { Title, Paragraph } = Typography;

const Analytics = (props) => {
    const { t, data, transactionWidgetUpdate } = props;
    const [widget, setWidget] = useState(null);
    const [updateSettings] = UserSettings();

    let loggedUserSettings = localStorage.getItem("loggedUserSettings") ? JSON.parse(localStorage.getItem("loggedUserSettings")) : null;
    let loggedUserSettingsHideWidget = null;
    if (loggedUserSettings) {
        if (loggedUserSettings.hideTransactionWidget) {
            loggedUserSettingsHideWidget=loggedUserSettings.hideTransactionWidget;
        }
    }

    useEffect(() => {
    }, [widget]); // eslint-disable-line react-hooks/exhaustive-deps

    const analytics = [
        {
            title: t("contents.dashboard.transactions"),
            keyword: "transaction_count",
            desc: t("contents.dashboard.transaction_count"),
            is_currency: false,
        },
        {
            title: t("contents.dashboard.transactions"),
            keyword: "total_revenue_collected",
            desc: t("contents.dashboard.total_revenue_collected"),
            is_currency: true,
        },
        {
            title: t("contents.dashboard.transactions"),
            keyword: "avg_ticket_value_of_transaction",
            desc: t("contents.dashboard.avg_ticket_value_of_transaction"),
            is_currency: true,
        },
        // {
        //     title: t("contents.dashboard.fraudchecks"),
        //     keyword: "avg_ticket_value_of_fraudcheck",
        //     desc: t("contents.dashboard.avg_ticket_value_of_fraudcheck"),
        //     is_currency: true,
        // },
        {
            title: t("contents.dashboard.3dsecure"),
            keyword: "total_counts_of_3dsecure",
            desc: t("contents.dashboard.total_counts_of_3dsecure"),
            is_currency: false,
        },
        {
            title: t("contents.dashboard.fraudchecks"),
            keyword: "total_counts_of_fraudcheck",
            desc: t("contents.dashboard.total_counts_of_fraudcheck"),
            is_currency: false,
        },
    ];

    const onCheckbox = (v) => {
        if (!loggedUserSettings) {
            loggedUserSettings = {};

            if (!loggedUserSettings.hideTransactionWidget) {
                loggedUserSettings.hideTransactionWidget = [];
                loggedUserSettings.hideTransactionWidget.push(v);
                loggedUserSettingsHideWidget=loggedUserSettings.hideTransactionWidget;
            }
        }else{
            if (!loggedUserSettings.hideTransactionWidget) {
                loggedUserSettings.hideTransactionWidget = [];
                loggedUserSettings.hideTransactionWidget.push(v);
                loggedUserSettingsHideWidget=loggedUserSettings.hideTransactionWidget;
            }else{
                if(!loggedUserSettings.hideTransactionWidget.includes(v)){
                    loggedUserSettings.hideTransactionWidget.push(v);
                    loggedUserSettingsHideWidget=loggedUserSettings.hideTransactionWidget;
                }
            }
        }
        setWidget(loadWidget());
        updateSettings(loggedUserSettings);
        localStorage.setItem("loggedUserSettings",JSON.stringify(loggedUserSettings));
        if(!loggedUserSettings.hideTransactionWidget || loggedUserSettings.hideTransactionWidget === null || (loggedUserSettings.hideTransactionWidget && loggedUserSettings.hideTransactionWidget.length === 0)){
            transactionWidgetUpdate(true);
        } else {
            transactionWidgetUpdate(false);
        }   
    };

    const loadWidget = () => {
        return analytics.map((analytic) => {
            if((loggedUserSettingsHideWidget !== null && loggedUserSettingsHideWidget.includes(analytic.keyword)) 
                || (analytic.keyword === 'total_counts_of_fraudcheck' && !MenuAccess('fraudchecks'))
                || (analytic.keyword === 'total_counts_of_3dsecure' && !MenuAccess('threeDS'))
                || (analytic.keyword === 'total_revenue_collected' && !GroupAccess("SYSADMIN")) ){
                return null;
            } else{
                return <Col key={analytic.keyword} flex="auto">
                    <Card size="small" style={{ marginBottom: "0.75rem" }}>
                        <Row justify="space-between">
                            <Col>
                                <Title style={{ minWidth: "80px" }} level={5}>
                                    {analytic.title}
                                </Title>
                            </Col>
                            <Col>
                                {/* <Badge count={t("contents.dashboard.today")} style={{ backgroundColor: "#108ee9", marginRight: '1px', fontSize: '10px' }} /> */}
                                <Checkbox onChange={() => onCheckbox(analytic.keyword)}>Hide</Checkbox>
                            </Col>
                        </Row>
                        <Row justify="space-between">
                            <Col>
                                <Badge count={t("contents.dashboard.today")} style={{ backgroundColor: "#108ee9" }} />
                            </Col>
                        </Row>
                        <Row justify="space-between" align="middle">
                            <Col flex="auto">
                                <span style={{ display: "block", fontSize: "1.75rem", fontWeight: "300" }}>
                                    {data ? (data.today[analytic.keyword] ? data.today[analytic.keyword] : 0) : 0}
                                </span>
                            </Col>
                            {analytic.is_currency ? (
                                <Col>
                                    <span style={{ fontSize: "1rem", fontWeight: "300" }}>$</span>
                                </Col>
                            ) : null}
                        </Row>
                        <Paragraph style={{ fontSize: "0.8rem", marginBottom: "0.5em" }}>{analytic.desc}</Paragraph>
                    </Card>
                </Col>;
            }
        })
    };

    return (
        <Row gutter={[16, 16]}>
            {loadWidget()}
        </Row>
    );
};

export default Analytics;
