import React from "react";
import {
	Chart,
	Tooltip,
	Interval,
    Slider,
    Axis,
    Legend
} from "bizcharts";

const Last7DaysPerHourChart = (props) => {
    const { t, data } = props;

    const getDataRange = (data, field) => {
        const values = data.reduce((pre, item) => {
            if (item[field]) {
                pre.push(item[field]);
            }
            return pre;
        }, []);

        const minValue = Math.min(...values);
        const maxValue = Math.max(...values);
        return [minValue, maxValue]
    }

    const scale = {        
        total: { 
            min: 0,
            max: getDataRange(data, 'total')[1] > 15 ? getDataRange(data, 'total')[1] : 15
        },
        status: {
            formatter: v => {
                return {
                    pass: 'Pass',
                    fail: 'Fail'
                }[v]
            },
        },
        dateAndHour: {
            sync: true
        }
    }

    const initialSlider = [0, 0.2];

    return (
        <>
            { data.length ? (
            <Chart 
                scale={scale} 
                height={294} 
                padding="auto" 
                data={data} 
                autoFit
                onSliderChange={console.log}
                onGetG2Instance={(c) => {
                    const sliderController = c.getController("slider");
                    const xScale = c.getXScale();
                    const xValues = xScale.values;

                    handleSliderChange(initialSlider)

                    sliderController.slider.component.on(
                        "sliderchange",
                        handleSliderChange
                    );

                    function handleSliderChange([start, end]) {
                        const { minText, maxText } = sliderController.getMinMaxText(
                            start,
                            end
                        );
                        const sIdx = xValues.indexOf(minText);
                        const eIdx = xValues.indexOf(maxText);
                        c.views.forEach((view) => {
                            view.filter(xScale.field, (val) => {
                                const idx = xValues.indexOf(val);
                                return idx >= sIdx && idx <= eIdx;
                            });
                        });
                    }
                }}
            >
                <Interval
                    adjust={[
                        {
                            type: 'stack',
                        },
                    ]}				 
                    color={['status', (xVal) => {
                        if (xVal === 'pass') {
                            return '#62DAAB';
                        }
                        return '#f44336';
                    }]}
                    position="dateAndHour*total"
                />
                <Tooltip shared />
                <Slider 
                    start={initialSlider[0]} 
                    end={initialSlider[1]} 
                    textStyle={{opacity: 1, fontSize: 11, fontWeight: 600}}
                />
                <Axis name="dateAndHour" label={{ rotate: 0.5, autoRotate: true, style: {textAlign: 'start', fontSize: 11 } }} />            
                <Legend position="right-top" />
            </Chart>
            ) : <div className="text-center" style={{marginTop: 15, marginBottom: 22}}>{t('contents._3dsecure_stats.no_data')}</div>}
        </>
    );
};

export default Last7DaysPerHourChart;
