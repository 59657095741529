import React, { useState } from "react";
import ColumnSearchProps from "../../helper/Table/ColumnSearchProps";
import ColumnFilterProps from "../../helper/Table/ColumnFilterProps";
import FilterSorterList from "../../helper/Table/FilterSorterList";
import AssociationsChecker from "../../helper/AssociationsChecker";
import StatusText from "../../helper/Table/StatusText";
import { Table, PageHeader, Button } from "antd";
import { API } from "../../config/config";
import axios from "axios";
import _ from "lodash";

const DATA_URL = "/descriptor/";

const Descriptor = (props) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [descriptor, setDescriptor] = useState();
    const [methods, setMethods] = useState([]);
    const [filteredInfo, setFilteredInfo] = useState({});
    const [sortedInfo, setSortedInfo] = useState({});
    const { t } = props;

    const handleChange = (pagination, filters, sorter, { currentDataSource }) => {
        setFilteredInfo(filters);
        setSortedInfo(sorter);
    };

    const clearFilters = () => {
        setFilteredInfo(null);
    };

    const clearAll = () => {
        setFilteredInfo(null);
        setSortedInfo(null);
    };

    const getFilteredValue = (dataIndex) => (filteredInfo ? filteredInfo[dataIndex] || null : null);

    const getSortOrder = (dataIndex) => {
        if (sortedInfo) {
            if (Array.isArray(sortedInfo)) {
                let sortedFound = sortedInfo.find((column) => column.columnKey === dataIndex);
                return sortedFound ? sortedFound.order : null;
            } else {
                return (sortedInfo.columnKey === dataIndex && sortedInfo.order) || null;
            }
        }
        return null;
    };

    let columns = [
        {
            dataIndex: "mxsid",
            key: "mxsid",
            title: t("tables.fields.id"),
            sorter: {
                compare: (a, b) => a.mxsid - b.mxsid,
                multiple: 1,
            },
            sortOrder: getSortOrder("mxsid"),
        },
        {
            dataIndex: "sitename",
            key: "sitename",
            title: t("tables.fields.site_name"),
            filteredValue: getFilteredValue("sitename"),
            ...ColumnSearchProps("sitename", !!getFilteredValue("sitename"), t),
            sorter: {
                compare: (a, b) => a.sitename.localeCompare(b.sitename),
                multiple: 2,
            },
            sortOrder: getSortOrder("sitename"),
        },
        {
            dataIndex: "merchantname",
            key: "merchantname",
            title: t("tables.fields.merchant_name"),
            filteredValue: getFilteredValue("merchantname"),
            ...ColumnSearchProps("merchantname", !!getFilteredValue("merchantname"), t),
            sorter: {
                compare: (a, b) => a.merchantname.localeCompare(b.merchantname),
                multiple: 3,
            },
            sortOrder: getSortOrder("merchantname"),
        },
        {
            dataIndex: "pay_method",
            key: "pay_method",
            title: t("tables.fields.payment_methods"),
            sorter: {
                compare: (a, b) => a.pay_method.localeCompare(b.pay_method),
                multiple: 4,
            },
            sortOrder: getSortOrder("pay_method"),
            filters: Object.keys(methods).map((key) => ({ text: key, value: key })),
            filteredValue: getFilteredValue("pay_method"),
            ...ColumnFilterProps("pay_method"),
        },
        {
            dataIndex: "status",
            key: "status",
            align: "left",
            title: t("tables.fields.status"),
            render: (status) => StatusText(status),
            sorter: {
                compare: (a, b) => a.status.localeCompare(b.status),
                multiple: 5,
            },
            sortOrder: getSortOrder("status"),
        },
    ];

    const getDescriptor = async (id) => {
        const apiUrl = DATA_URL + id;
        axios
            .get(API + apiUrl, { headers: { Authorization: "Bearer " + localStorage.getItem("access_token") } })
            .then(({ data }) => {
                setData(data);
                setLoading(false);
                setMethods(_.groupBy(data, "pay_method"));
                setDescriptor(data[0] ? data[0]["descriptor"] : "");
            })
            .catch((error) => error);
    };

    if (typeof props.match.params.id != "undefined" && loading) {
        getDescriptor(props.match.params.id);
    }

    return (
        <div>
            <PageHeader className="site-page-header" onBack={() => props.history.goBack()} title={descriptor}></PageHeader>
            {FilterSorterList(filteredInfo, sortedInfo, null, t)}
            <div className="d-flex align-items-center" style={{ marginBottom: 16 }}>
                <Button style={{ marginRight: 8 }} onClick={clearFilters}>
                    {t("tables.actions.clear_filters")}
                </Button>
                <Button onClick={clearAll}>{t("tables.actions.clear_filters_sorters")}</Button>
            </div>
            <Table
                columns={AssociationsChecker("Retailer|Site") ? columns.filter((column) => column.dataIndex !== "merchantname") : columns}
                rowKey="mxsid"
                
                dataSource={data}
                onChange={handleChange}
            />
        </div>
    );
};

export default Descriptor;