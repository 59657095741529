import React, { useState, useEffect} from "react";
import { Form, Button,Modal, message, Upload,Progress, Checkbox,Input,Row,Col,Typography,Space } from "antd";
import { UploadOutlined } from '@ant-design/icons';

import { API_TXALERT2 } from "../../../config/config";
import TxalertMerchantListDropdown from "../Components/MerchantListDropdown";
import TxalertAlertProviderLoginDropdown from "../Components/AlertProviderLoginDropdown";
import { fileUpload } from "../../../helper/FileUploadFunctions.js";
const { Text} = Typography;


const Txalert_BinCaidImport = (props) => {
    const { t,isUploadOpen,setIsUploadOpen, fetchList } = props;
    const [loading, setLoading] = useState(false);
    const importUrl = API_TXALERT2 +'/bincaid/importcsv';
    const [fileToBeUpload, setFileToBeUpload] = useState(null); 
    const [fileList, setFileList] = useState([]);
    const [progress,setProgress] = useState(0);
    const [buttonText, setButtonText] = useState('Cancel');
   
    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 16 },
  };

  const initData = {
    email: JSON.parse(localStorage.getItem("loggedUser")).email,
    delimiter: ",",
    headerrow: 1
};

    const [form] = Form.useForm();
   

    useEffect(() => {
      if(isUploadOpen === true){
      form.setFieldsValue(initData);
      }
  }, [isUploadOpen]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleOk = () => {
        setLoading(true);
        //setUpFile(file);

        fileUpload({
            file: fileToBeUpload, // Assuming you have a file selected in the list
            onSuccess: () => {
              // Handle success if needed
              setFileToBeUpload(null);
              setLoading(false);
              message.success(`${fileToBeUpload.name} file uploaded successfully`);
              setButtonText('OK');
            },
            onError: (error) => {
              // Handle error if needed
              setLoading(false);
              message.error(`${fileToBeUpload.name} file upload failed.${error}`);
            },
            onProgress:({percent}) =>{
              setProgress(parseFloat(percent.toFixed(2)));
            }           
          }, importUrl,form.getFieldsValue(true));
    };

   
    const handleCancel = () => {  
      removeFile(0);      
        setLoading(false);       
        setIsUploadOpen(false);
        setProgress(0);
        fetchList();
    };

    function removeFile(index) {
      // Remove the selected file from the list
      fileList.splice(index, 1);

  }

  const prepareUploadfile = (file) => {
    const isCSV = file.type === 'text/csv';
        if (!isCSV) {
          message.error(`${file.name} is not a csv file`);
          return false;
        }
            
      setFileList([file]);    
      setFileToBeUpload(file);
      setProgress(0);
    };



    const uploaderProps = {    
        accept: "text/csv",
        maxCount: 1,
        customRequest: fileUpload,
        beforeUpload: file => {
          
          prepareUploadfile(file);
          return false;
        },
        fileList:fileList,
        progress: {
          strokeColor: {
            '0%': '#108ee9',
            '100%': '#87d068',
          },
          strokeWidth: 3,
          format: percent => percent && `${parseFloat(percent.toFixed(2))}%`,
        }
      };

    return (
        <>
         <Modal
                title={t("contents.txalert_admin.import_bincaid")}
                visible={isUploadOpen}
                width={800}
                onCancel={handleCancel}
                onOk={handleOk}
                bodyStyle={{ paddingTop: '0px' }}
                footer={[
                    <Button key="back" onClick={() => handleCancel()}>
                        {buttonText}
                    </Button>,                    
                    <Button key="submit" type="primary" loading={loading} disabled={!fileToBeUpload} onClick={() => handleOk()}>
                        {loading ? t("contents.txalert_admin.form.submitting") : t("contents.txalert_admin.form.submit")}
                    </Button>,
                ]}
            >
              <Form {...layout} form={form}   >
                    <fieldset >
                    <Space direction="vertical">
                    <Text>
                      An enrollment record will be created for the provider chosen from the list below with status in the csv.
                      Upload a CSV file with bincaid details. Below the header row, the row with column data.
                      </Text>
<Text code>Columns expected in csv are BIN,CAID,MCC,VROL Profile,Requested Date,Updated Date,Status,Status Reason,Reference Number,Ruleset,Comment
                     </Text>
<Text type="danger">Existing records wont get updated. Use only to create new records</Text>
<Text type="danger">*Do not use comma in the data columns</Text>
</Space>
                       <Row>
                        <Col span={12}>
                        <Form.Item name="merchant_id" label={t("contents.txalert_admin.form.merchant")} rules={[{ required: true }]} style={{ marginBottom: ".5rem" }} >
                            <TxalertMerchantListDropdown showInactiveOption={false}/>
                        </Form.Item>
                        </Col>
                        </Row>
                        <Row>
                        <Col span={12}>                        
                        <Form.Item name="alert_provider_login_id" label={t("contents.txalert_admin.form.provider")} rules={[{ required: true }]} style={{ marginBottom: ".5rem" }} >
                          <TxalertAlertProviderLoginDropdown placeholder={t("contents.txalert_admin.form.provider_name")} rdr_alerts={1} />
                        </Form.Item>
                        
                        </Col>
                        </Row>
                        <Row>
                          <Col span={6}>
                        <Form.Item name="delimiter" label={t("contents.txalert_admin.form.delimiter")} rules={[{ required: true }]} style={{ marginBottom: ".5rem" }} labelCol={{span:12}} wrapperCol={{span:3}} >
                            <Input maxLength={1} size="small" />
                        </Form.Item>
                        </Col><Col flex="auto">
                        <Form.Item name="headerrow" label={t("contents.txalert_admin.form.header_row_present")} style={{ marginBottom: ".5rem"}} valuePropName="checked" labelCol={{span:12}} wrapperCol={{span:3}} >
                            <Checkbox />
                        </Form.Item>
                        </Col>
                        </Row> 
                        <Row>
                        <Col span={12}>     
                        <Form.Item name="email" label={t("contents.txalert_admin.form.email")} rules={[{ required: true }]} style={{ marginBottom: ".5rem"}} >
                            <Input />
                        </Form.Item>
                        </Col>
                        </Row>
                    </fieldset>

                </Form>
                  
                  <Upload {...uploaderProps}> <Button icon={<UploadOutlined />}>Choose Import CSV File</Button></Upload>
                  {progress > 0 ? <Progress percent={progress} /> : null}
                  <span className="d-none d-md-inline-block">Once the file is uploaded, an email will be sent to the email provided.</span>
            </Modal>
        </>
    );
}
export default Txalert_BinCaidImport;