import React, { useState } from "react";
import { Table, PageHeader, Button, Space } from "antd";
import {  FileSearchOutlined ,PlusOutlined} from "@ant-design/icons";
import CustomPaginationV2 from "../../../helper/Table/CustomPaginationV2";
import { API_TXALERT2 } from "../../../config/config";
import ColumnResizable from "../../../helper/Table/ColumnResizable";
import MergeColumns from "../../../helper/Table/MergeColumns";
import DataFetchHookTxAlert from "../../../helper/DataFetchHookTxAlert";
import TxalertTheme from "./Theme";
import TextSorter from "../../../helper/Table/TextSorter";

const DATA_URL = API_TXALERT2 + "/theme";


const Txalert_Themes = (props) => {
    const [{ data, totalResult, isLoading, isError, errorMsg, requestData, isUpdated }, setUrl, setRequestData] = DataFetchHookTxAlert(DATA_URL,"GET", localStorage.getItem("txalert_token")); // eslint-disable-line
    const [currentPage, setCurrentPage] = useState(1);
    const [id, setId] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const { t } = props;

    const getThemeList = () => {
        
        let searchData ={};
        setRequestData({ ...requestData, ...searchData,  page: 1 });
      
    };

    const showModal = (selectedid) =>{
        setId(selectedid);
        setIsOpen(true);
        
      };
    

    

    const columns = [
        {
            dataIndex: "themeid",
            key: "themeid",
            title: t("tables.fields.id"),
            width: 50,
            sorter: {
                compare: (a, b) => a.themeid - b.themeid
            }
        },
        {
            dataIndex: "theme_name",
            key: "theme_name",
            title: t("tables.fields.theme_name"),
            width: 150,
            sorter: {
                compare: (a, b) => TextSorter(a.theme_name,b.theme_name)
            }
        },
        {
            dataIndex: "from_email_address",
            key: "from_email_address",
            title: t("tables.fields.from_email_address"),
            width: 150,
            sorter: {
                compare: (a, b) => TextSorter(a.from_email_address,b.from_email_address)
            }
        },
        {
            dataIndex: "from_name",
            key: "from_name",
            title: t("tables.fields.from_name"),
            width: 150,
            sorter: {
                compare: (a, b) => TextSorter(a.from_name,b.from_name)
            }
        },
        {
            dataIndex: "action",
            title: t("tables.fields.action"),
            align: "left",
            width: 100,
            render: (text, record) => (
                <Space size="middle">
                    <Button type="link" onClick={() => showModal(record.themeid)} >
                        <FileSearchOutlined style={{ color: "rgba(0, 0, 0, 0.85)", fontSize: "18px" }} />
                    </Button>
                </Space>
            ),
        }
    ];

    const getDataSource = (datalist) => (datalist && Array.isArray(datalist) ? datalist : []);

 

    const getHeaderTable = () => (
        <div className="custom-table--header d-flex justify-content-between align-items-center">
            <h3 className="table-title">{t("contents.txalert_admin.themelist")}</h3>
            <Button type="link" onClick={() => showModal()} style={{ marginLeft: "auto" }}>
                <PlusOutlined /> {t("contents.txalert_admin.form.new_theme")}
            </Button>
            
        </div>
    );

    return (
        <div>
            <PageHeader title={t("contents.txalert_admin.themelist")}></PageHeader>
            <Table
                size="small"
                loading={isLoading}
                components={{
                    header: {
                        cell: ColumnResizable,
                    },
                }}
                
                columns={MergeColumns(columns)}
                title={() => getHeaderTable()}
                rowKey="themeid"
                className="table-v3 custom-pagination custom-pagination-v3"
                dataSource={getDataSource(data.datalist)}
                pagination={CustomPaginationV2({ totalResult, currentPage, requestData, setRequestData, setCurrentPage })}

            />
            <TxalertTheme t={t} id={id} setId={setId} isOpen={isOpen} setIsOpen={setIsOpen} fetchList={getThemeList} />
        </div>
    );
};


export default Txalert_Themes;
