import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { API, THEME } from "../config/config";
import Multilingual from "./../helper/Multilingual";

import axios from "axios";
import Swal from "sweetalert2";

import { Form, Input, Button, Row, Col, ConfigProvider } from "antd";
import { UserOutlined, MailOutlined } from "@ant-design/icons";

const API_RESETPASSWORD = "/resetpassword/";

const ForgotPassword = (props) => {
    const [t, locale, getDropdownSelectLanguage] = Multilingual();
    const [data, setData] = useState({ username: "", email: "" });

    const layout = {
        layout: "vertical",
    };
    const tailLayout = {};

    const formValChange = (chgV, allV) => {
        setData(allV);
    };

    useEffect(() => {
       
        const favicon = document.getElementById("favicon");
        favicon.href = `../themes/${THEME ? THEME : "default"}/favicon.ico`;
        document.title = t("login.forgotpassword");
    },[]);// eslint-disable-line react-hooks/exhaustive-deps


    const onFinish = (values) => {
        axios
            .post(API + "/forgotpassword", { username: values.username, email: values.email, reseturl: window.location.origin + API_RESETPASSWORD })
            .then(({ data }) => {
                Swal.fire({
                    icon: "success",
                    title: `${t("login.dialogs.reset_password")} ${values.email}.`,
                    showConfirmButton: false,
                    timer: 2000,
                    onClose: () => {
                        props.history.goBack();
                    },
                });
            })
            .catch((error) => {
                Swal.fire({
                    icon: "error",
                    title: t("login.dialogs.catch"),
                    text: error.response ? error.response.data.error_msg : "",
                    showConfirmButton: true,
                });
            });
    };

    if (localStorage.getItem("loggedUser")) {
        const loggedUser = JSON.parse(localStorage.getItem("loggedUser"));

        if (loggedUser) {
            props.history.push("/dashboard");
        }
    }

    const getSelectLanguage = () => {
        return (
            <Row type="flex" gutter={[8, 0]} justify="end" style={{ marginBottom: "1rem" }}>
                <Col>{getDropdownSelectLanguage()}</Col>
            </Row>
        );
    };

    return (
        <ConfigProvider locale={locale}>
            <div className={`login-warp ${locale.locale}`}>
                <div className="login-warp--box">
                    <img
                        src={`../themes/${THEME ? THEME : "default"}/payshield-origin.png`}
                        width={"100%"}
                        style={{ display: "block", maxWidth: "300px", margin: "0 auto 1rem auto" }}
                        alt="payshield dashboard"
                    />
                    {getSelectLanguage()}
                    <Form {...layout} onValuesChange={formValChange} onFinish={() => onFinish(data)}>
                        <Form.Item name="username" rules={[{ required: true, message: t("login.required.username") }]}>
                            <Input size="large" placeholder={t("login.username")} prefix={<UserOutlined style={{ color: "gray" }} />} />
                        </Form.Item>
                        <Form.Item name="email" rules={[{ type: "email", required: true, message: t("login.required.email") }]}>
                            <Input size="large" placeholder={t("login.email")} prefix={<MailOutlined style={{ color: "gray" }} />} />
                        </Form.Item>
                        <Form.Item {...tailLayout} className="text-center" style={{ marginBottom: ".5rem" }}>
                            <Button type="primary" htmlType="submit" className="login-form-button" size="large" shape="round">
                                {t("login.submit")}
                            </Button>
                        </Form.Item>
                        <Form.Item className="text-center">
                            <Link to={"/"}>{t("login.backtologin")}</Link>
                        </Form.Item>
                    </Form>
                </div>
            </div>
        </ConfigProvider>
    );
};

export default ForgotPassword;
