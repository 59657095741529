import React, { useState, useEffect, useCallback } from "react";
import { OI_URL } from "../../config/config";
import DataFetchHookOI from "../../helper/DataFetchHookOI";
import PermissionsRequired from "../../helper/PermissionsRequired";
import AddBinCaidModal from "./AddBinCaidModal";
import EditBinCaidModal from "./EditBinCaidModal";
import { Table, PageHeader, Button, Card, Form, Select, Row, Col, Input } from "antd";
import { SearchOutlined, FileSearchOutlined, PlusOutlined } from "@ant-design/icons";
import AssociationsChecker from "../../helper/AssociationsChecker";
import CustomPaginationV2 from "../../helper/Table/CustomPaginationV2";
import SortOrder from "../../helper/Table/SortOrder";
import moment from "moment";

const { Option } = Select;
const DATA_URL = "/api/v1/txshield/bincaidList";

const BinCaidList = (props) => {
    const [{ data, totalResult, isLoading, requestData }, doFetch, setRequestData] = DataFetchHookOI(DATA_URL, "GET", null, {
        page: 1,
        size: 20,
        order: "ASC",
    });
    const [formData, setFormData] = useState({ hideColumn: [], associatedMerchantIds: [] }); // eslint-disable-line
    const [form] = Form.useForm();
    const [currentPage, setCurrentPage] = useState(1);
    const [sortedInfo, setSortedInfo] = useState({});
    const [isEditModalVisible, setIsEditModalVisible] = useState(false);
    const [isAddModalVisible, setIsAddModalVisible] = useState(false);
    const [selectedBinCaid, setSelectedBinCaid] = useState(null);
    const oiAssocication = localStorage.getItem("oiAssocication") ? localStorage.getItem("oiAssocication").split(",") : [];
    const { t } = props;

    const initFormData = {
        field: "bin",
        keyword: null,
        mcc: null,
        status: null,
        order: "ASC",
        associatedMerchantIds: oiAssocication,
    };

    const formValInit = useCallback(() => {
        setFormData(initFormData);
        form.setFieldsValue(initFormData);
    }, [initFormData, form]); 

    useEffect(() => {
        formValInit();
    }, [formValInit]);

    const showEditBinCaid = (record) => {
        setSelectedBinCaid(record);
        setIsEditModalVisible(true);
    };

    const handleChange = (pagination, filters, sorter) => {
        setSortedInfo(sorter);
    };

    const formValChange = (chgV, allV) => {
        setFormData(allV);
    };

    const clearAll = () => {
        setFormData(initFormData);
        setRequestData({ ...requestData, ...initFormData, page: 1 });
        form.setFieldsValue(initFormData);
        doFetch(OI_URL + DATA_URL + `?${moment().unix()}`, "GET");
    };

    const columns = [
        {
            dataIndex: "bin",
            key: "bin",
            title: t("tables.fields.bin"),
            sorter: {
                compare: (a, b) => a.bin.localeCompare(b.bin)
            },
            sortOrder: SortOrder(sortedInfo, "bin"),
        },
        {
            dataIndex: "caid",
            key: "caid",
            title: t("tables.fields.caid"),
        },
        {
            dataIndex: "mcc",
            key: "mcc",
            title: t("tables.fields.mcc"), 
        },
        {
            dataIndex: "status",
            key: "status",
            title: t("tables.fields.status"),
        },
        {
            dataIndex: "action",
            title: t("tables.fields.action"),
            align: "left",
            fixed: "right",
            width: 120,
            render: (text, record) => (
                <Button type="link" onClick={() => showEditBinCaid(record)} style={{ marginLeft: "auto" }}>
                    <FileSearchOutlined style={{ fontSize: "18px", color: "rgba(0, 0, 0, 0.85)" }} />
                </Button>
            ),
        },
    ];

    const onFinish = (values) => {
        const searchData = {
            keyword: values.keyword ? values.keyword.trim() : null,
            field: values.field,
            mcc: values.mcc,
            status: values.status,
        };
    
        setCurrentPage(1);
        setRequestData({ ...requestData, ...searchData, page: 1 });
        
        const queryParams = new URLSearchParams();
        if (searchData.field && searchData.keyword) {
            queryParams.append('field', searchData.field);
            queryParams.append('keyword', searchData.keyword);
        }
        if (searchData.mcc) queryParams.append('mcc', searchData.mcc);
        if (searchData.status) queryParams.append('status', searchData.status);
        queryParams.append('timestamp', moment().unix().toString());
    
        doFetch(OI_URL + DATA_URL + `?${queryParams.toString()}`, "GET");
    };

    const AdvancedSearchForm = () => (
        <div className="search-wrap">
            <Card title={t('tables.actions.search')} size="small">
                <Form form={form} size="large" name="advanced_search" className="ant-advanced-search-form" onValuesChange={formValChange} onFinish={onFinish}>
                    <Row gutter={[16, 16]}>
                        <Col className="gutter-row" xs={24} sm={12} md={6} lg={6} xl={4}>
                            <Form.Item name="field">
                                <Select 
                                    showSearch
                                    style={{ width: "100%" }} 
                                    placeholder={t('tables.fields.field')}>
                                    <Option value="bin">{t("tables.fields.bin")}</Option>
                                    <Option value="caid">{t("tables.fields.caid")}</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" xs={24} sm={12} md={6} lg={6} xl={4}>
                            <Form.Item name="keyword">
                                <Input placeholder={t('tables.fields.keyword')} />
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" xs={24} sm={12} md={6} lg={6} xl={4}>
                            <Form.Item name="mcc">
                                <Select
                                    showSearch
                                    style={{ width: "100%" }}
                                    placeholder={t('tables.fields.mcc')}>
                                    <Option value="9505">9505</Option>
                                    <Option value="9701">9701</Option>
                                    <Option value="9705">9705</Option>
                                    {/* Add more MCC options as needed */}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" xs={24} sm={12} md={6} lg={6} xl={4}>
                            <Form.Item name="status">
                                <Select
                                    showSearch
                                    style={{ width: "100%" }}
                                    placeholder={t('tables.fields.status')}>
                                    <Option value="requested">Requested</Option>
                                    <Option value="pending">Pending</Option>
                                    <Option value="enrolled">Enrolled</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" xs={24} sm={12} md={6} lg={6} xl={4}>
                            <Form.Item>
                                <Button type="secondary" onClick={clearAll} block>
                                    {t('tables.actions.clear_all')}
                                </Button>
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" xs={24} sm={12} md={6} lg={6} xl={4}>
                            <Form.Item>
                                <Button type="primary" htmlType="submit" block>
                                    <SearchOutlined /> {t('tables.actions.search')}
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Card>
        </div>
    );

    const fetchBinCaids = () => {
        doFetch(OI_URL + DATA_URL + `?${moment().unix()}`);
    };

    const getDataSource = (datalist) => {
        return datalist && Array.isArray(datalist) ? datalist : [];
    };

    const getHeaderTable = () => (
        <div className="custom-table--header d-flex justify-content-between align-items-center">
            <h3 className="table-title">{t("contents.oi.bincaids")}</h3>
            <Button type="primary" onClick={() => setIsAddModalVisible(true)}>
                <PlusOutlined /> {t("contents.oi.new_bincaid")}
            </Button>
        </div>
    );

    return PermissionsRequired("SYSADMIN") || AssociationsChecker("OI") ? (
        <div>
            <PageHeader title={t("contents.oi.bincaids")}></PageHeader>
            {AdvancedSearchForm()}
            <AddBinCaidModal 
                t={t} 
                isAddBinCaidModalVisible={isAddModalVisible} 
                setIsAddBinCaidModalVisible={setIsAddModalVisible} 
                fetchBinCaids={fetchBinCaids} 
            />
            <EditBinCaidModal 
                t={t} 
                binCaid={selectedBinCaid}
                isEditModalVisible={isEditModalVisible}
                setIsEditModalVisible={setIsEditModalVisible}
                fetchBinCaids={fetchBinCaids}
            />
            <Table
                loading={isLoading}
                columns={columns}
                title={() => getHeaderTable()}
                rowKey="bincaidId"
                className="table-v3 custom-pagination"
                size="small"
                dataSource={getDataSource(data.datalist)}
                onChange={handleChange}
                pagination={CustomPaginationV2({ totalResult, currentPage, requestData, setRequestData, setCurrentPage })}
                locale={{ emptyText: "No records found" }}
            />
        </div>
    ) : null;
};

export default BinCaidList;