import axios from "axios";
import { TOKEN_ENCODING_ENABLED } from "../config/config";

const DecodeToken = async (token) => {
    if (!TOKEN_ENCODING_ENABLED || TOKEN_ENCODING_ENABLED === '' || String(TOKEN_ENCODING_ENABLED).toLowerCase() !== 'true') {
        return token;
    }

    return await axios.post('/decode',{encdata: token}).then((res)=>{
        return res.data;
    }).catch((err)=>{
        console.log(err);
        return token;
    });
}

export default DecodeToken