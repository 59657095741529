import { useState, useEffect } from "react";
import axios from "axios";
import { API } from "../config/config";

const DataFetchHook = (initialurl) => {
    const [data, setData] = useState({ datalist: [] });
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [url, setUrl] = useState(initialurl);

    useEffect(() => {
        const fetchdata = async () => {
            setIsLoading(true);
            setIsError(false);
            try {
                const result = await axios(API + url, { headers: { Authorization: "Bearer " + localStorage.getItem("access_token"), Authuser: localStorage.getItem("authuser") } });
                setData({ datalist: result.data });
            } catch (err) {
                console.log(err);
                setIsError(true);
            }
            setIsLoading(false);
        };
        fetchdata();
    }, [url]);

    return [{ data, isLoading, isError }, setUrl, setData];
};
export default DataFetchHook;
