import React, { useState } from "react";
import DataFetchHookOI from "../../helper/DataFetchHookOI";
import DraggableModal from "../../helper/DraggableModal";
import PermissionsRequired from "../../helper/PermissionsRequired";
import moment from "moment";
import { Table, PageHeader, Button, Card, Form, Select, Row, Col, Input, Typography } from "antd";
import { SearchOutlined, FileSearchOutlined } from "@ant-design/icons";
import AssociationsChecker from "../../helper/AssociationsChecker";
import CustomPaginationV2 from "../../helper/Table/CustomPaginationV2";
import CustomDateRangeV3 from "../../helper/Table/CustomDateRangeV3";
import SortOrder from "../../helper/Table/SortOrder";
import ColumnResizable from "../../helper/Table/ColumnResizable";
import MergeColumns from "../../helper/Table/MergeColumns";

const { Paragraph } = Typography;
const { Option } = Select;

const DATA_URL = "/api/v1/txshield/notifications/list";
const MAX_DATERANGE = 3;
const START_DATE = moment().subtract(MAX_DATERANGE, "months");
const END_DATE = moment();

const CENotificationsListing = (props) => {
    const [{ data, totalResult, isLoading, requestData }, doFetch, setRequestData] = DataFetchHookOI(DATA_URL);
    const [formData, setFormData] = useState({});
    const [form] = Form.useForm();
    const [sortedInfo, setSortedInfo] = useState({});
    const [dates, setDates] = useState([START_DATE, END_DATE]);
    const [currentPage, setCurrentPage] = useState(1);
    const [modal, setModal] = useState({ title: "", content: "" });
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isDateExceeded, setIsDateExceeded] = useState(false);
    const { t } = props;

    const initFormData = {
        keyword: null,
        field: "caseId",
        dateRange: [START_DATE, END_DATE],
        status: null,
        source: null
    };

    const handleChange = (pagination, filters, sorter) => {
        setSortedInfo(sorter);
    };

    const formValInit = () => {
        setFormData(initFormData);
        form.setFieldsValue(initFormData);
    };

    const formValChange = (chgV, allV) => {
        setFormData(allV);
    };

    const clearAll = () => {
        setFormData(initFormData);
        setRequestData({ ...requestData, ...initFormData, startDate: null, endDate: null, page: 1 });
        form.setFieldsValue(initFormData);
    };

    const columns = [
        {
            dataIndex: "caseId",
            key: "caseId",
            title: t("tables.fields.caseid"),
            width: 250,
            sorter: {
                compare: (a, b) => a.caseId.localeCompare(b.caseId)
            },
            sortOrder: SortOrder(sortedInfo, "caseId")
        },
        {
            dataIndex: "source",
            key: "source",
            title: "Source",
            width: 150,
            sorter: {
                compare: (a, b) => a.source.localeCompare(b.source)
            },
            sortOrder: SortOrder(sortedInfo, "source")
        },
        {
            dataIndex: "receivedOn",
            key: "receivedOn",
            title: "receivedOn",
            width: 180,
            render: (receivedOn) => moment(receivedOn).format("YYYY-MM-DD HH:mm:ss"),
            sorter: {
                compare: (a, b) => moment(a.receivedOn).diff(moment(b.receivedOn))
            },
            sortOrder: SortOrder(sortedInfo, "receivedOn")
        },
        {
            dataIndex: "status",
            key: "status",
            title: t("tables.fields.status"),
            width: 120,
            sorter: {
                compare: (a, b) => a.status.localeCompare(b.status)
            },
            sortOrder: SortOrder(sortedInfo, "status")
        },
        {
            dataIndex: "requestData",
            key: "requestData",
            title: "Received Data",
            width: 130,
            render: (requestData) => requestData ? getButtonModal({ 
                title: t("contents.oi.request_data"), 
                content: requestData 
            }) : ""
        },
        {
            dataIndex: "responseData",
            key: "responseData",
            title: "Return Data",
            width: 130,
            render: (responseData) => responseData ? getButtonModal({ 
                title: t("contents.oi.response_data"), 
                content: responseData 
            }) : ""
        }
    ];

    const showModal = (params) => {
        setModal(params);
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const getButtonModal = (params) => (
        <Button type="link" size="small" onClick={() => showModal(params)}>
            <FileSearchOutlined style={{ fontSize: "18px", color: "rgba(0, 0, 0, 0.85)" }} />
        </Button>
    );

    const IsJsonString = (str) => {
        try {
            return JSON.parse(str);
        } catch (e) {
            return false;
        }
    };

    const IsObject = (object) => {
        if (typeof object === "object") {
            return JSON.stringify(object);
        }
        return false;
    };

    const getDataSource = (datalist) =>
        datalist && Array.isArray(datalist)
            ? datalist.map((data) => ({
                  ...data,
                  key: data.caseId
              }))
            : [];

    const onFinish = () => {
        let startDate = null;
        let endDate = null;
        let searchData = {};
        if (formData) {
            if (dates) {
                startDate = dates[0] ? dates[0] : null;
                endDate = dates[1] ? dates[1] : null;
            }
            searchData = {
                keyword: formData.keyword ? formData.keyword.trim() : null,
                field: formData.field,
                status: formData.status,
                source: formData.source
            };
        }

        setCurrentPage(1);
        setRequestData({ ...requestData, ...searchData, startDate: startDate, endDate: endDate, page: 1 });
    };

    const getModalComponent = () => (
        <DraggableModal
            name={modal.title}
            visible={isModalVisible}
            onOk={handleOk}
            onCancel={handleCancel}
            width={800}
            footer={[
                <Button key="submit" type="primary" onClick={handleOk}>
                    {t("tables.actions.ok")}
                </Button>,
            ]}
            context={
                <Typography>
                    <Paragraph className="well">
                        {IsObject(modal.content)
                            ? JSON.stringify(modal.content, null, "\t")
                            : IsJsonString(modal.content)
                            ? JSON.stringify(JSON.parse(modal.content), null, "\t")
                            : modal.content}
                    </Paragraph>
                </Typography>
            }
        />
    );

    const AdvancedSearchForm = () => (
        <div className="search-wrap">
            <Card title={t("tables.actions.search")} size="small">
                <Form form={form} size="large" name="advanced_search" className="ant-advanced-search-form" onValuesChange={formValChange} onFinish={onFinish}>
                    <Row gutter={[16, 16]}>
                        <Col className="gutter-row" xs={24} md={12} lg={6}>
                            <Form.Item 
                                name="dateRange" 
                                rules={[
                                    {
                                        validator: () => {
                                            if (isDateExceeded) {
                                                return Promise.reject(t('max_3_months'));
                                            } else if ((dates[0] && !dates[1]) || (!dates[0] && dates[1])) {
                                                return Promise.reject(t('min_1_day'));
                                            }
                                            return Promise.resolve();
                                        },
                                    },
                                ]}
                            >
                                <CustomDateRangeV3 
                                    t={t} 
                                    setIsDateExceeded={setIsDateExceeded} 
                                    state={{ dates, setDates }} 
                                    unit="months" 
                                    limit={MAX_DATERANGE} 
                                />
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" xs={24} md={12} lg={6}>
                            <Form.Item name="field">
                                <Select 
                                    showSearch
                                    style={{ width: "100%" }} 
                                    placeholder={t("tables.fields.field")}
                                >
                                    <Option value="caseId">{t("tables.fields.caseid")}</Option>
                                    <Option value="source">{"Source"}</Option>
                                    <Option value="status">{t("tables.fields.status")}</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" xs={24} md={12} lg={6}>
                            <Form.Item name="keyword">
                                <Input placeholder={t("tables.fields.keyword")} />
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" xs={24} md={12} lg={6}>
                            <Form.Item name="status">
                                <Select 
                                    showSearch
                                    style={{ width: "100%" }} 
                                    placeholder={t("tables.fields.status")}
                                >
                                    <Option value="received">Received</Option>
                                    <Option value="processed">Processed</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" xs={24} sm={12} md={6} lg={4} offset={16}>
                            <Form.Item>
                                <Button type="secondary" onClick={() => clearAll()} block>
                                    {t("tables.actions.clear_all")}
                                </Button>
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" xs={24} sm={12} md={6} lg={4}>
                            <Form.Item>
                                <Button type="primary" htmlType="submit" block>
                                    <SearchOutlined /> {t("tables.actions.search")}
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Card>
        </div>
    );

    return PermissionsRequired("SYSADMIN") || AssociationsChecker("OI") ? (
        <div>
            <PageHeader title={"CE Lookup"}></PageHeader>
            {getModalComponent()}
            {AdvancedSearchForm()}
            <Table
                loading={isLoading}
                components={{
                    header: {
                        cell: ColumnResizable,
                    },
                }}
                columns={MergeColumns(columns)}
                rowKey="caseId"
                scroll={{ x: 1200 }}
                className="table-v3 custom-pagination"
                size="small"
                dataSource={getDataSource(data.datalist)}
                onChange={handleChange}
                pagination={CustomPaginationV2({ 
                    totalResult, 
                    currentPage, 
                    requestData, 
                    setRequestData, 
                    setCurrentPage 
                })}
            />
        </div>
    ) : null;
};

export default CENotificationsListing;