import React, { useState, useEffect } from "react";
import { DonutChart } from "bizcharts";
import FormatNumber from "../../helper/FormatNumber";
const colorPalette = ['#5B8FF9', '#5AD8A6', '#5D7092', '#F6BD16', '#6F5EF9', '#FF6B3B', '#625DF5', '#6DC8EC', '#FF9D4D', '#269A99'];


const renderLegendTable = (data, colorMap) => {
    return (
      <table style={{ width: '100%', textAlign: 'left', borderCollapse: 'collapse', marginTop: 20 , border: '1px solid #f0f0f0'}}>
       
        <tbody>
          {data.map((item, index) => (
            <tr key={index} style={{ border: '1px solid #f0f0f0'}}>
              <td style={{ padding: '4px 8px' }}>
                <span style={{ backgroundColor: colorMap[item.type], display: 'inline-block', width: 12, height: 12, borderRadius: '50%' }}></span>
              </td>
              <td style={{ padding: '4px 8px'}}>{item.type}</td>
              <td style={{ padding: '4px 8px'}}>{item.value}</td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };
  
  const AlertsPieChart = (props) => {
    const { t, data } = props;
   
    const [colorMap, setColorMap] = useState({});

  useEffect(() => {
    const newColorMap = {};
    let colorIndex = 0;

    data.forEach((d) => {
      if (!newColorMap[d.type]) {
        newColorMap[d.type] = colorPalette[colorIndex % colorPalette.length];
        colorIndex++;
      }
    });

    setColorMap(newColorMap);
  }, [data]);
       return (
        <div>
        <DonutChart
            data={data ? data : []}
            forceFit
            height={250}
            radius={0.8}
            padding="auto"
            angleField="value"
            colorField="type"
            animation={false}
            color={(item)=>  colorMap[item.type]}           
            legend={{
                layout:"vertical",
                position: "bottom",
                visible:false,
                           
            }}
			statistic={{
				title: {
					customHtml: () => data.length ? t("contents.dashboard.total") : t("contents.dashboard.total") + " 0"
                },
                content: {
					formatter: (angleField, colorField) => {
                        let summary = 0;
                        colorField.map((item) => (summary += item.value))
                        return summary > 0 ? String(FormatNumber(summary)).replace('.00','') : '';
                    }
                }
			}}
        />
         {renderLegendTable(data, colorMap)}
        </div>
    );
};

export default AlertsPieChart;
