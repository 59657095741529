import axios from "axios";


export async function fileUpload({ file, onSuccess, onError, onProgress }, url, formValues) {
  let counter = 1;
  let chunkSize = 1048576 * 2; //2MB
  let chunkCount = 0;
  let beginingOfTheChunk = 0;
  let endOfTheChunk = chunkSize;
  let uploadstatus = '';
  let upfile = file;
  let _totalCount = 0;


  //uploads file. if there are more pieces, it will loop
  const uploadChunk = async (chunk, onSuccess, onError, onProgress, counter, importurl, formValues) => {
    let formdata = new FormData();


    if (chunkCount === 1) {
      formdata.append('file', chunk);
      for (const key in formValues) {
        if (Object.prototype.hasOwnProperty.call(formValues, key)) {
          formdata.append(key, formValues[key]);
        }
      }
    } else {
      formdata.append('chunk', chunk);
      formdata.append('offset', counter);
      formdata.append('totalChunks', chunkCount);
      //if last chunk sent the form data too
      if (counter === chunkCount) {
        for (const key in formValues) {
          if (Object.prototype.hasOwnProperty.call(formValues, key)) {
            formdata.append(key, formValues[key]);
          }
        }
      }
    }

    formdata.append('id', upfile.uid);
    formdata.append('name', upfile.name); //get name without extension
    formdata.append('type', upfile.type);
    formdata.append('upload', true);

    try {

      const response = await axios.post(importurl,
        formdata,
        {
          headers: { "Content-Type": "multipart/form-data", Authorization: localStorage.getItem("txalert_token") },
        }
      );
      const data = response.data;
      if (data['status'] === 'OK') {

        beginingOfTheChunk = endOfTheChunk;
        endOfTheChunk = endOfTheChunk + chunkSize;

        let percentage = (counter / chunkCount) * 100;
        onProgress({ percent: parseFloat(percentage.toFixed(2)) });

        if (counter >= chunkCount) {
          onSuccess(null, upfile);
        }

      } else {
        console.log("Error Occurred:", data);
        uploadstatus = 'failed';
        onError({ data });
      }
    } catch (error) {
      uploadstatus = 'failed';
      console.log("error", error);
      onError({ error });
    }
  };


//---------------------------processing-------------------------------
  if (upfile.size <= chunkSize) {
    _totalCount = 1;
  }
  else {
    _totalCount =
      upfile.size % chunkSize === 0
        ? upfile.size / chunkSize
        : Math.floor(upfile.size / chunkSize) + 1;
  }

  chunkCount = _totalCount;



  if (chunkCount === 1) {
    uploadChunk(file, onSuccess, onError, onProgress, counter, url, formValues);
  } else {
    for (let i = counter; i <= chunkCount; i++) {
      if (uploadstatus !== 'failed') {

        var chunk = file.slice(beginingOfTheChunk, endOfTheChunk);
        await uploadChunk(chunk, onSuccess, onError, onProgress, i, url, formValues);

        counter += 1;
      } else {
        onError()
      }
    }

  }






};