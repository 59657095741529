import React, { useState, useEffect } from "react";
import { Form, Input, Switch, Button,Modal } from "antd";
import { API_TXALERT2 } from "../../../config/config";
import axios from "axios";
import Swal from "sweetalert2";
import GroupAccess from "../../../helper/GroupAccess";
import TxalertProviderListDropdown from "../Components/ProviderlistDropdown";
import CatchError from "../../../helper/CatchError";

const Txalert_AlertProviderLogin = (props) => {
    const { t, id,setId,isOpen,setIsOpen, fetchList } = props;
    const [details, setDetails] = useState(null);
    const [loading, setLoading] = useState(false);
    const [componentDisabled, setComponentDisabled] = useState(GroupAccess("SYSADMIN") ? false : true);// eslint-disable-line
    
   
    const layout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };

    const initData = {
        alert_provider_id: "",
        provider_name: "",
        url: "",
        path: "",
        username:"",
        authentication1:"",
        authentication2:"",
        authentication3:""
    };

    const handleOk = () => {
        setLoading(true);
        onFinish();
    };

   

    const [form] = Form.useForm();

    const handleCancel = () => {             
        setLoading(false);
        setId('');
        form.setFieldsValue(initData);
        setIsOpen(false);
        
    };

    const formValChange = (chgV, allV) => {
              // setDetails(allV);
              setDetails({ ...allV, active: chgV.active=== true?1:0});

    };

    useEffect(() => {
     
        if(isOpen){
            if (typeof id != 'undefined') {
            
                getDetails();
            }else{
                setDetails(initData);
            }
        }
    }, [isOpen]); // eslint-disable-line

   
    const getDetails = async () => {

        const api_url = API_TXALERT2 + `/alert_provider/login/${id}`;
        console.log(api_url);
        await axios
            .get(api_url, { headers: { Authorization: localStorage.getItem("txalert_token") } })
            .then(({ data }) => {
                let recdata = data;
                setDetails(recdata);
                form.setFieldsValue(recdata);
            })
            .catch((error) => console.log(error));
    };

    const onFinish = () => {
        let apiUrl = API_TXALERT2 ;
      
       if(id === 'undefined' || typeof id === 'undefined' || id === ''){
        apiUrl = apiUrl + "/alert_provider/login";
       }else{
        apiUrl = apiUrl + "/alert_provider/login/"+id;
       }
       //remove null entries
      
       Object.keys(details).forEach((item) => (details[item] === null) && delete details[item]);


         axios
             .post(apiUrl, details,{ headers: { Authorization: "Bearer " + localStorage.getItem("txalert_token") }})
             .then(({ data }) => {
                 if (data.status === "OK") {
                     Swal.fire({
                         icon: "success",
                         title: data.msg,
                         showConfirmButton: false,
                         timer: 2000,
                         onClose: () => {
                             fetchList();
                         },
                     });
                     handleCancel();
                 } else {
                    
                    CatchError(data);
                 }
             })
             .catch((error) => {                
                 setLoading(false);
                 CatchError(error);
             });
     };
    const isOkButtonProps = () => !details || !details.alert_provider_id || loading;
    return(
        <>
            <Modal
                title={id !== 'undefined'?t("contents.txalert_admin.form.view_alert_provider_login")+ ' id:'+id:t("contents.txalert_admin.form.add_alert_provider_login")}
                visible={isOpen}
                width={600}
                onCancel={handleCancel}
                onOk={handleOk}
                bodyStyle={{ paddingTop: '0px' }}
                footer={[
                    <Button key="back" onClick={() => handleCancel()}>
                        {t("contents.txalert_admin.form.cancel")}
                    </Button>,
                     GroupAccess("SYSADMIN") ? 
                    <Button key="submit" type="primary" loading={loading} disabled={isOkButtonProps()} onClick={() => handleOk()}>
                        {loading ? t("contents.txalert_admin.form.submitting") : t("contents.txalert_admin.form.submit")}
                    </Button>:'',
                ]}
            >
                <Form {...layout} form={form} name="detail" onValuesChange={formValChange}  >
                    <fieldset disabled={componentDisabled}>
                        <Form.Item name="alert_provider_id" label={t("contents.txalert_admin.form.provider_name")} rules={[{ required: true }]} style={{ marginBottom: ".5rem" }} >
                            <TxalertProviderListDropdown placeholder={t("contents.txalert_admin.form.provider_name")}/>
                        </Form.Item>
                        <Form.Item name="url" label={t("contents.txalert_admin.form.url")} rules={[{ required: true }]} style={{ marginBottom: ".5rem" }} >
                            <Input />
                        </Form.Item>
                        <Form.Item name="username" label={t("contents.txalert_admin.form.username")} style={{ marginBottom: ".5rem" }} >
                            <Input />
                        </Form.Item>
                        <Form.Item name="authentication1" label={t("contents.txalert_admin.form.authentication1")}  style={{ marginBottom: ".5rem" }} >
                            <Input />
                        </Form.Item>
                        <Form.Item name="authentication2" label={t("contents.txalert_admin.form.authentication2")}  style={{ marginBottom: ".5rem" }} >
                            <Input />
                        </Form.Item>
                        <Form.Item name="authentication3" label={t("contents.txalert_admin.form.authentication3")}  style={{ marginBottom: ".5rem" }} >
                            <Input />
                        </Form.Item>
                        <Form.Item name="path" label={t("contents.txalert_admin.form.path")}  style={{ marginBottom: ".5rem" }} >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            key="active"
                            name="active"
                            label={t("contents.txalert_admin.form.isactive")}
                            valuePropName="checked"
                            style={{ marginBottom: ".5rem" }}
                        >
                            <Switch />
                        </Form.Item>
                      
                    </fieldset>
                </Form>
            </Modal>
 
        </>
    );
};

export default Txalert_AlertProviderLogin;
