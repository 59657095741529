import React, { useState, useEffect } from "react";
import { Table, DatePicker,  Select } from "antd";
import { API_TXALERT2 } from "../../../config/config";
import TxalertEnrollmentStatusDropdown from "./EnrollmentStatusDropdown";
import axios from "axios";
import moment from "moment";
const { Option } = Select;

const DATA_URL = API_TXALERT2 + "/alert_provider/login";
/* this component can be used to add and update the alert provider enrollments using alertprovider login id, status and date */
/*accepts current enrollments as prop {alert_provider_id,alert_provider_login_id} */

const Txalert_EnrollmentInputTbl = (props) => {
   

    const [providers, setProviders] = useState([]);
    const [enrollrecords, setEnrollRecords] = useState([]);
    const [loginAccounts, setLoginAccounts] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const [enrollments, setEnrollments] = useState(false);
    const [columns, setColumns] = useState([]);

    useEffect(() => {
        if (enrollrecords.length > 0) {           
            props.onChange(enrollrecords);
        }
    }, [enrollrecords]);// eslint-disable-line react-hooks/exhaustive-deps



    useEffect(() => {

        if (props.id === 'undefined' || typeof props.id === 'undefined' || props.id === '') {
            setEnrollments([]);
        }
        else {
            getEnrollments();
        }
        setLoaded(true);

        //When you return a function from useEffect, that function will will be executed when the component unmounts. 
        return () => {
            setProviders([]);
            setEnrollRecords([]);
            setLoginAccounts([]);
            setColumns([]);
            setLoaded(false);
            setEnrollments([]);
        };

    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
       
        if (loaded === true) {

            getProviderLogins();
            //  getProviders();
        }
    }, [enrollments]);// eslint-disable-line react-hooks/exhaustive-deps

    const getEnrollments = async () => {

        let apiurl = '';
        if (props.type) {
            apiurl = API_TXALERT2 + '/' + props.type + '/' + props.id + '/enrollment';
        } else
            return false;

        await axios
            .get(
                apiurl,
                { headers: { Authorization: "Bearer " + localStorage.getItem("txalert_token") } }
            )
            .then(({ data }) => {

                const fData = data.list.map((d) => {
                    return {
                        alert_provider_id: d.alert_provider_id,
                        alert_provider_login_id: d.alert_provider_login_id,
                        enrollment_status_id: d.enrollment_status_id,
                        update_date: d.update_date,
                        status_text:d.status
                    };
                });
                if (fData) {
                    setEnrollments(fData);
                }

            })
            .catch(() => {
              
                setEnrollments([]);
            });
    }


    const getProviderLogins = async () => {

        let queryparam = '';
        if (props.type === 'descriptor')
            queryparam = queryparam + '?standard_alerts=1';
        else if (props.type === 'bincaid')
            queryparam = queryparam + '?rdr_alerts=1';

        await axios
            .get(
                DATA_URL + queryparam,
                { headers: { Authorization: "Bearer " + localStorage.getItem("txalert_token") } }
            )
            .then(({ data }) => {
                /* if updating the enrollments, only allow the existing login as the update option for 
                if not show all login options of the provider */


                if (enrollments) {
                    const fData = data.list.forEach((d) => {
                        //if the login record already exist in enrollment, add that to the loginaccounts
                        let existing = enrollments.filter(obj => obj.alert_provider_id === d.alert_provider_id && obj.alert_provider_login_id === d.alert_provider_login_id);
                        if (existing.length > 0) {
                            return d;
                        } else {
                            //provider not exists in current enrollment
                            let existingprovider = enrollments.filter(obj => obj.alert_provider_id === d.alert_provider_id);
                            if (existingprovider.length === 0) {
                                return d;
                            }


                        }
                        //if provider exists but not the same login we dont want that item
                    });

                    setLoginAccounts(fData.filter(obj => obj !== undefined));
                } else {
                    setLoginAccounts(data.list);
                }



            })
            .catch(() => false);
    }



    const getProviders = async () => {

        let queryparam = '';
        if (props.type === 'descriptor')
            queryparam = queryparam + '?standard_alerts=1';
        else if (props.type === 'bincaid')
            queryparam = queryparam + '?rdr_alerts=1';


        await axios
            .get(
                API_TXALERT2 + "/alert_provider" + queryparam,
                { headers: { Authorization: "Bearer " + localStorage.getItem("txalert_token") } }
            )
            .then(({ data }) => {
               
                //filter the providers that have login accounts
                var providerfilter = data.list.filter(function (cItem) {
                    return loginAccounts.find(function (aItem) {
                        return cItem.alert_provider_id === aItem.alert_provider_id
                    })
                });
                setProviders(providerfilter);



                if (enrollments) {
                    const fData = data.list.map((d) => {
                        let existing = enrollments.filter(obj => obj.alert_provider_id === d.alert_provider_id);
                        if (existing.length > 0) {
                            return {
                                alert_provider_id: d.alert_provider_id,
                                alert_provider_login_id: existing[0].alert_provider_login_id,
                                enrollment_status_id: existing[0].enrollment_status_id,
                                update_date: existing[0].update_date,
                                status_text:existing[0].status_text
                            }
                        } else {
                            return {
                                alert_provider_id: d.alert_provider_id,
                                alert_provider_login_id: '',
                                enrollment_status_id: '',
                                update_date: '',
                                status_text:''
                            }


                        }
                    });
                    setEnrollRecords(fData);
                } else {
                    const fData = data.list.map((d) => {
                        return {
                            alert_provider_id: d.alert_provider_id,
                            alert_provider_login_id: '',
                            enrollment_status_id: '',
                            update_date: '',
                            status_text:''
                        }
                    });
                    setEnrollRecords(fData);
                }

            })
            .catch((error) => {
                console.log('err', error);
            });
    }

    const handleInputChange = (e, providerid) => {

        let enrollcopy = [...enrollrecords];

        let { name, value } = e.target;
        let selvalue = '';
    //for status, value is passed as {"label": "enrolled", "value": 4,"key": "4"}

        if (value !== '') {
           
            if (name === 'update_date') {
                selvalue = moment(value).format('YYYY-MM-DD');
            }else if (name === 'enrollment_status_id') {
                selvalue = value.value;
            }else{
                selvalue = value;
            }

            const itemIndex = enrollcopy.findIndex(item => item.alert_provider_id === providerid);

            if (itemIndex !== -1) {
                // Update the desired property
                const updatedObject = { ...enrollcopy[itemIndex] };
           
                updatedObject[name] = selvalue;
                if(name === 'enrollment_status_id')
                {                   
                    updatedObject.status_text = value.label;
                }
             
                enrollcopy[itemIndex] = updatedObject;

                // Update the state
                setEnrollRecords(enrollcopy);
            }
        }



    };

    const getDefaultStatusDate = (record) => {
        if (enrollments.length === 0) {
            return null;
        }
        let existing = enrollments.filter(obj => obj.alert_provider_id === record.alert_provider_id);
        if (existing.length > 0) {
            if(existing[0].update_date !== '' && existing[0].update_date !== null)
                return moment(existing[0].update_date);
            else return null;
        } else {
            return null;
        }
    };

    const getDefaultProvider = (record) => {
        if (enrollments.length === 0) {
            return null;
        }
        let existing = enrollments.filter(obj => obj.alert_provider_id === record.alert_provider_id);
        if (existing.length > 0) {
            return existing[0].alert_provider_login_id;
        } else {
            return null;
        }
    };

    const getDefaultStatus = (record) => {
        if (enrollments.length === 0) {
            return null;
        }
        let existing = enrollments.filter(obj => obj.alert_provider_id === record.alert_provider_id);
        if (existing.length > 0) {
            return existing[0].enrollment_status_id;
        } else {
            return null;
        }
    };


    useEffect(() => {
      
        if (loginAccounts.length > 0 && providers.length === 0) {
            getProviders();
        }
        if (loginAccounts.length > 0) {

            setColumns([
                {
                    dataIndex: "provider_name",
                    title: "Provider",
                    render: (text, record, index) => (

                        <span>{record.provider_name}</span>

                    )
                },
                {
                    dataIndex: "alert_provider_login_id",
                    title: "Provider Account",
                    render: (text, record, index) => (

                        <Select key="alert_provider_login_id_{index}"
                            style={{ width: "200px" }}
                            name="alert_provider_login_id_{index}"
                            defaultValue={getDefaultProvider(record)}
                            onChange={val => handleInputChange({ target: { value: val, name: 'alert_provider_login_id' } }, record.alert_provider_id)}>
                            {loginAccounts.filter(obj => {
                                return obj.alert_provider_id === record.alert_provider_id;
                            }).map((providerlogin) => (
                                <Option key={providerlogin.alert_provider_login_id} value={providerlogin.alert_provider_login_id}>
                                    {providerlogin.username}
                                </Option>
                            ))}
                        </Select>
                    )
                },
                {
                    dataIndex: "enrollment_status_id",
                    title: "Status",
                    render: (text, record, index) => (

                        <TxalertEnrollmentStatusDropdown key="enroll_status_cmp_{index}"
                            name="enroll_status_cmp_{index}"
                            style={{ width: "200px" }}
                            selectedvalue={getDefaultStatus(record)}
                            labelInValue={true}
                            onChange={val => handleInputChange({ target: { value: val, name: 'enrollment_status_id' } }, record.alert_provider_id)} />
                    )
                },
                {
                    dataIndex: "update_date",
                    title: "Date",
                    render: (text, record, index) => (
                        <DatePicker
                            key="update_date_dp_{index}"
                            name="update_date_dp_{index}"
                            defaultValue={getDefaultStatusDate(record)}
                            format={'YYYY-MM-DD'}
                            onChange={date => handleInputChange({ target: { value: date, name: 'update_date' } }, record.alert_provider_id)} disabledDate={d => !d || d.isAfter(moment())} />

                    )
                }
            ]);
        }
    }, [loginAccounts, enrollrecords]);// eslint-disable-line react-hooks/exhaustive-deps


    return (
        <div>
            <Table
                columns={columns}
                rowKey="alert_provider_id"
                pagination={false}
                dataSource={providers}
            />
        </div>
    );
};


export default Txalert_EnrollmentInputTbl;