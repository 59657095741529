import React from "react";
import { Route, Redirect } from "react-router-dom";

const Logout = () => {

    // Removed by Jericho 2023-08-18 - Clear all localStorage items instead of removing one by one
    // localStorage.removeItem("loggedUser");
    // localStorage.removeItem("access_token");
    // localStorage.removeItem("aggregates");
    // localStorage.removeItem("aggregates_updated");
    // localStorage.removeItem("logo");
    // localStorage.removeItem("dashboardtheme");
    // localStorage.removeItem("cbm_merchant");
    // localStorage.removeItem("authuser");
    // localStorage.removeItem("expiry");
    // localStorage.removeItem("isIdle");

    // remove all item from localstorage
    localStorage.clear();
    return (
        <Route>
            <Redirect to="/login"/>
        </Route>
    );
};
export default Logout;
