import React, { useState } from "react";
import { CBM_URL } from "../../config/config";
import { Modal, Button, Form, Input, Select, DatePicker } from "antd";
import _ from "lodash";
import axios from "axios";
import Swal from "sweetalert2";
import { PlusOutlined } from "@ant-design/icons";

const { Option } = Select;

const SingleChargeback = (props) => {
    const { t,merchants, providers  } = props; //removed 'fetchChargeback' due to unused var
    const [activedata, setActivedata] = useState(null);
    const [loading, setLoading] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);


    const layout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };

    const [form] = Form.useForm();

    const initData = {
        mid: null,
        pid: null,
        merchant_transaction_id: "",
        card_number: "",
        payment_transaction_date: "",
        amount: "",
        currency: "",
        chargeback_reason: "",
        merchant_id: "",
        descriptor: "",
        arn: "",
        alert_id: "",
        apid: "",
        txid: "",
        pgid: "",
    };


    const handleOk = () => {
        setLoading(true);
        onFinish();
    };

    const showModal = () => {
        const mid = merchants ? merchants[0].mid : null;
        const pid = providers ? providers[0].pid : null;

        setActivedata({ ...initData, mid: mid, pid: pid });
        form.setFieldsValue({ ...initData, mid: mid, pid: pid });
        setIsModalVisible(true);
    };

    const handleCancel = () => {
        setLoading(false);
        setIsModalVisible(false);
    };

    const formValChange = (chgV, allV) => {
        if (chgV.transaction_date) {
            setActivedata({ ...allV, payment_transaction_date: chgV.payment_transaction_date.format("YYYY-MM-DD HH:mm:ss") });
        } else {
            setActivedata(allV);
        }
    };

 

    const onFinish =async () => {
        const apiUrl = CBM_URL + `/api/v1/chargebacks/merchant/${activedata.mid}/provider/${activedata.pid}/singleChargeback`;

        let requestData = _.clone(activedata);

        delete requestData.mid;
        delete requestData.pid;


        const merchant = merchants ? merchants.filter(({mid}) => mid === activedata.mid) : false;
        if(!merchant){
            Swal.fire({
                icon: "error",
                title: "Error",
                showConfirmButton: false,
                timer: 2000,
                onClose: () => {
                 //   fetchChargeback();
                },
            });
        }
      
        axios
            .post(apiUrl, activedata, { headers: { Authorization: "Bearer " + localStorage.getItem("cbm_token")  } })
            .then(({ data }) => {
                if(data.status === 'ok'){
                    handleCancel();
                    Swal.fire({
                        icon: "success",
                        title: t("contents.chargebacks.messages.chargeback_create_successful"),
                        showConfirmButton: false,
                        timer: 2000,
                        onClose: () => {
                          //  fetchChargeback();
                        },
                    });
                }else{
                    Swal.fire({
                        icon: "error",
                        title: t("login.dialogs.catch"),
                        text: data.error_msg ? data.error_msg : t("login.dialogs.catch"),
                        showConfirmButton: true,
                    });
                }
            })
            .catch((error) => {
                
                setLoading(false);
                Swal.fire({
                    icon: "error",
                    title: error.response ? error.response.data.error_msg : t("login.dialogs.catch"),
                    showConfirmButton: true,
                });
            });
        
    };

    const isOkButtonProps = () => !activedata || !activedata.card_number || !activedata.amount || !activedata.descriptor || loading;

    return (
        <>
            <Button type="link" onClick={showModal} style={{ marginLeft: "auto" }} disabled={!merchants || merchants.length === 0}>
                <PlusOutlined /> {t("contents.chargebacks.new_chargeback")}
            </Button>
            <Modal
                title={t("contents.chargebacks.new_chargeback")}
                width={850}
                visible={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={[
                    <Button key="back" onClick={() => handleCancel()}>
                        {t("contents.chargebacks.form.cancel")}
                    </Button>,
                    <Button key="submit" type="primary" loading={loading} disabled={isOkButtonProps()} onClick={() => handleOk()}>
                        {loading ? t("contents.chargebacks.form.submitting") : t("contents.chargebacks.form.submit")}
                    </Button>,
                ]}
            >
                <Form {...layout} form={form} name="new_chargeback" onValuesChange={formValChange}>
                    <Form.Item name="mid" label={t("contents.chargebacks.form.merchant")}>
                        <Select>
                            {merchants
                                ? merchants.map((merchant) => (
                                      <Option key={merchant.mid} value={merchant.mid}>
                                          {merchant.name}
                                      </Option>
                                  ))
                                : null}
                        </Select>
                    </Form.Item>
                    <Form.Item name="pid" label={t("contents.chargebacks.form.provider")}>
                        <Select>
                            {providers
                                ? providers.map((provider) => (
                                      <Option key={provider.pid} value={provider.pid}>
                                          {provider.name}
                                      </Option>
                                  ))
                                : null}
                        </Select>
                    </Form.Item>
                    <Form.Item name="merchant_transaction_id" label={t("contents.chargebacks.form.merchant_transaction_id")}>
                        <Input />
                    </Form.Item>
                    <Form.Item name="payment_transaction_date" label={t("contents.chargebacks.form.transaction_date")}  rules={[{ required: true }]}>
                        <DatePicker style={{ width: "100%" }} showTime />
                    </Form.Item>
                    <Form.Item name="card_number" label={t("contents.chargebacks.form.card_number")} rules={[{ required: true }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item name="descriptor" label={t("contents.chargebacks.form.descriptor")} rules={[{ required: true }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item name="amount" label={t("contents.chargebacks.form.amount")} rules={[{ required: true }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item name="currency" label={t("contents.chargebacks.form.currency")} rules={[{ required: true }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item name="merchant_id" label={t("contents.chargebacks.form.merchant_id")}>
                        <Input />
                    </Form.Item>
                    <Form.Item name="arn" label={t("contents.chargebacks.form.arn")}>
                        <Input />
                    </Form.Item>
                    <Form.Item name="chargeback_reason" label={t("contents.chargebacks.form.response_message")}>
                        <Input />
                    </Form.Item>
                    <Form.Item name="chargeback_date" label={t("contents.chargebacks.form.chargeback_date")}>
                        <Input />
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};

export default SingleChargeback;
