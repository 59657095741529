import React, { useState, useEffect } from "react";
import { Table } from "antd";
import axios from "axios";
import { API } from "../../config/config";
import moment from "moment";
import ColumnResizable from "../../helper/Table/ColumnResizable";

const DATA_URL = "/alert_credits";

const AlertCredits = (props) => {
    const { t, alertid, isRefresh } = props;
    const [data, setData] = useState([]);

    useEffect(() => {
        const fetchdata = async () => {
            const result = await axios
                .post(
                    API + DATA_URL,
                    { alertid: alertid },
                    { headers: { Authorization: "Bearer " + localStorage.getItem("access_token"), Authuser: localStorage.getItem("authuser") } }
                )
                .then((result) => result)
                .catch((error) => ({ data: false }));
            setData(result.data);
        };
        fetchdata();
    }, [isRefresh]); // eslint-disable-line react-hooks/exhaustive-deps

    const getFormatDate = (date) => (Date.parse(date) ? moment(date).format("lll") : "");

    const [columns, setColumns] = useState([
        {
            dataIndex: "datecreated",
            key: "datecreated",
            title: t("tables.fields.datecreated"),
            width: 150,
            ellipsis: true,
            render: (datecreated) => getFormatDate(datecreated),
        },
        {
            dataIndex: "comment",
            key: "comment",
            title: t("tables.fields.comment"),
            width: 180,
            ellipsis: true,
        },
        {
            dataIndex: "credit_requested_on",
            key: "credit_requested_on",
            title: t("tables.fields.requested_date"),
            width: 150,
            ellipsis: true,
            render: (credit_requested_on) => getFormatDate(credit_requested_on),
        },
        {
            dataIndex: "status_update_date",
            key: "status_update_date",
            title: t("tables.fields.status_update_date"),
            width: 150,
            ellipsis: true,
            render: (status_update_date) => getFormatDate(status_update_date),
        },
        {
            dataIndex: "credit_status",
            key: "credit_status",
            title: t("tables.fields.status"),
            width: 80,
            ellipsis: true,
        },
        {
            dataIndex: "credit_status_reason",
            key: "credit_status_reason",
            title: t("tables.fields.status_reason"),
            width: 100,
        },
    ]);

    const mergeColumns = columns.map((col, index) => ({
        ...col,
        onHeaderCell: (column) => ({
            width: column.width,
            onResize: (_, { size }) => {
                const newColumns = [...columns];
                newColumns[index] = { ...newColumns[index], width: size.width };
                setColumns(newColumns);
            },
        }),
    }));


    return (
        <div style={{ marginBottom: "1rem" }}>
            <Table size="middle" 
                components={{
                    header: {
                        cell: ColumnResizable,
                    },
                }}
                columns={mergeColumns}
                rowKey="alertcreditid" 
                pagination={false} dataSource={data ? data : []} />
        </div>
    );
};
export default AlertCredits;
