import React, { useState, useEffect } from "react";
import { Modal, Button } from "antd";
import axios from "axios";
import Swal from "sweetalert2";
import GroupAccess from "../../../helper/GroupAccess";
import TxalertEnrollmentInputTbl from "../Components/EnrollmentInputTbl";
// import { update } from "lodash";

const Txalert_UpdateEnrollmentModal = (props) => {
    const { t, type, isEnrollOpen, setIsEnrollOpen, enrollurl, title, fetchList, id } = props;
    const [loading, setLoading] = useState(false);
    const [enrollRecords, setEnrollRecords] = useState([]);
    const [updatemsg, setUpdateMsg] = useState([]);


    const handleOk = async () => {
        // Perform your validation here

        const validationPassed = await validateEnrollInput();

        if (validationPassed) {
            setLoading(true);
            // If validation is successful, close the modal
            setIsEnrollOpen(false);
            onFinish();
        } else {
            // If validation fails, do not close the modal
            //setIsEnrollOpen(true);
            alert("Invalid inputs rows in enrollment");
        }
    };


    const SaveEnrollRecords = (items) => {
        setEnrollRecords(items);
    };





    const handleCancel = () => {
        setLoading(false);
        setIsEnrollOpen(false);

    };

    const addMsg = (item) => {

        let itemError = '';

        if (item.msg) {
            itemError = (item.details) ? (item.msg + '-' + item.details) : item.msg;
        } else if (item.message) {

            itemError = (item.details) ? (item.message + '-' + item.details) : item.message;
        }

        return itemError;
    };

    const validateEnrollInput = async () => {
        let valid = true;
        enrollRecords.forEach((enroll) => {
            // Check if at least one element is not empty
            const elements = [enroll.alert_provider_login_id, enroll.enrollment_status_id, enroll.update_date];
            const atLeastOneNotEmpty = elements.some((element) => element !== "");

            if (atLeastOneNotEmpty) {
                // If at least one element is not empty, validate all elements
                const allNonEmpty = elements.every((element) => element !== "");

                if (!allNonEmpty) {
                    valid = false;
                }
            }
        });
        return valid;
    }

    const onFinish = async () => {

        const apiUrl = enrollurl;
        let updateresp = [];
        //make multiple post calls depending on the data in enrollment records
        await Promise.all(enrollRecords.map(async (enroll) => {

            if (enroll.enrollment_status_id !== '' && enroll.update_date !== '' && enroll.alert_provider_login_id !== '') {
                await axios
                    .post(apiUrl, enroll, { headers: { Authorization: "Bearer " + localStorage.getItem("txalert_token") } })
                    .then(({ data }) => {

                        let itemMsg = addMsg(data);
                        updateresp.push(itemMsg);

                        return data;
                    }

                    )
                    .catch((error) => {

                        let err = error.response.data;
                        let itemMsg = addMsg(err.error_msg);
                        updateresp.push(itemMsg);
                        return { 'status': 'EXC', 'error_msg': error.response }
                    })
            }

        }));



        if (updateresp.length > 0) {
            setUpdateMsg(updateresp);
        } else {
            setUpdateMsg("Nothing updated");
        }



    };




    useEffect(() => {
        if (loading === true) {
            handleResponses();
        }
    }, [updatemsg]); // eslint-disable-line


    const handleResponses = () => {
        setLoading(false);

        let arrCopy = [...updatemsg];
        if (arrCopy.length > 0) {


            Swal.fire({
                icon: "info",
                title: "Update status",
                text: JSON.stringify(updatemsg),
                showConfirmButton: true,
                onClose: () => {
                    fetchList();
                }
            });
            handleCancel();
        }

    };


    return (
        <>
            <Modal
                title={t("contents.txalert_admin.form.updateenrollment") + ' ' + title}
                visible={isEnrollOpen}
                width={800}
                onCancel={handleCancel}
                onOk={handleOk}
                destroyOnClose={true}
                bodyStyle={{ paddingTop: '0px' }}
                footer={[
                    <Button key="back" onClick={() => handleCancel()}>
                        {t("contents.txalert_admin.form.cancel")}
                    </Button>,
                    GroupAccess("SYSADMIN") ?
                        <Button key="submit" type="primary" loading={loading} onClick={() => handleOk()}>
                            {loading ? t("contents.bincaids.form.submitting") : t("contents.txalert_admin.form.submit")}
                        </Button> : '',
                ]}
            >

                <TxalertEnrollmentInputTbl type={type} id={id} style={{ width: "100%" }} onChange={SaveEnrollRecords} />

            </Modal>
        </>
    );
};

export default Txalert_UpdateEnrollmentModal;
