import React from "react";
import {Tag} from "antd";

const BreakdownByType = (props) => {
    const { t, statsData, detailsBoxTitle } = props; // eslint-disable-line

    const getDetails = () => (
        <div className="table-v3">
            <div className="ant-table ant-table-small">
                <div className="ant-table-title">
                    <div className="custom-table--header d-flex justify-content-between align-items-center">
                        <h3 className="table-title">{t(`contents.alerts.${detailsBoxTitle}`)}</h3>
                    </div>
                </div>
                <div className="ant-table-container">
                    <div className="ant-table-content">
                        <table style={{ tableLayout: "auto", width: "100%" }}>
                            <thead className="ant-table-thead">
                                <tr>
                                    <th className="ant-table-cell" width="30%">
                                        <div className="text-center">{t("contents.alerts.count")}</div>
                                    </th>
                                    <th className="ant-table-cell" width="70%">
                                        {t("contents.alerts.alert_type")}
                                    </th>
                                </tr>
                            </thead>
                            <tbody className="ant-table-tbody">
                                {statsData
                                    ? Object.keys(statsData.alert_type).map((item) => (
                                        <tr className="ant-table-row ant-table-row-level-0" key={item}>
                                            <td className="ant-table-cell"  align="center">{statsData.alert_type[item]?.count}</td>
                                            <td className="ant-table-cell" align="left">
                                                {statsData.alert_type[item]?.alert_type!=='' ? statsData.alert_type[item]?.alert_type : <Tag color="error">Empty</Tag>}
                                            </td>
                                        </tr>
                                      ))
                                    : null}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );

    return getDetails();
};

export default BreakdownByType;
