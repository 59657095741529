import React from "react";
import { Row, Col, Card } from "antd";
import FormatAmount from "../../../helper/FormatAmount";
import showCardInfo from "./showCardInfo";

const TxDetails = (props) => {
    const { t, loading, data } = props; // eslint-disable-line

    const getFormatNumber = (amount, currency) =>
        currency ? `${FormatAmount(amount ? amount : "0.00", currency)} ${currency}` : FormatAmount(amount ? amount : "0.00", "USD");

    const getCardDetails = () => {
        let CardDetails = data.CardDetails ? data.CardDetails : [];
        let fields = CardDetails.map(({ name, title, value }) => ({
            label: t(`contents.transactions.form.${title.split(" ").join("_").replace(".", "").toLowerCase()}`),
            name: name,
            value: value,
        }));

        return showCardInfo(fields);
    };

    const getSettlementDetails = () => {
        let SettlementDetails = data.SettlementDetails ? data.SettlementDetails : [];
        let fields = SettlementDetails.map(({ name, title, value, currency }) => ({
            label: t(`contents.transactions.form.${name}`),
            name: name,
            value: getFormatNumber(value, currency),
        }));

        return showCardInfo(fields, 16, 8);
    };

    const getCartAndProcessingDetails = () => {
        let CartAndProcessingDetails = data.CartAndProcessingDetails ? data.CartAndProcessingDetails : [];
        let fields = CartAndProcessingDetails.map(({ name, title, value, currency }) => ({
            label: t(`contents.transactions.form.${name}`),
            name: name,
            value: getFormatNumber(value, currency),
        }));

        return showCardInfo(fields, 16, 8);
    };

    const getTxDetails = () => (
        <Card className="details-info--card" loading={loading}>
            <Row gutter={0}>
                <Col span="8">
                    <h4 className="details-info--title">{t("contents.transactions.form.card_details")}</h4>
                    {getCardDetails()}
                </Col>
                <Col span="8">
                    <h4 className="details-info--title">{t("contents.transactions.form.settlement_details")}</h4>
                    {getSettlementDetails()}
                </Col>
                <Col span="8">
                    <h4 className="details-info--title">{t("contents.transactions.form.cart_and_processing_details")}</h4>
                    {getCartAndProcessingDetails()}
                </Col>
            </Row>
        </Card>
    );

    return getTxDetails();
};

export default TxDetails;
