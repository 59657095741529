import React, { useState, useEffect } from "react";
import axios from "axios";
import { Select } from "antd";
import { API_TXALERT2 } from "../../../config/config";

const { Option } = Select;
const DATA_ALERT_PROVIDER_URL = API_TXALERT2 + "/alert_provider";


const Txalert_ProviderListDropdown = (props) => {

    const [data, setData] = useState([]);

    const fetchdata = async () => {
        try {
            //get standard_alerts and rdr_alerts as prop values and conver to query params
            let queryparam = '';
            if(props.standard_alerts)
            queryparam =  queryparam+'?standard_alerts='+props.standard_alerts;

            if(props.rdr_alerts)
            queryparam = queryparam === '' ?'?rdr_alerts='+props.rdr_alerts:'&rdr_alerts='+props.rdr_alerts;
                
            const response = await axios.get(
                DATA_ALERT_PROVIDER_URL+queryparam,
                { headers: { Authorization: "Bearer " + localStorage.getItem("txalert_token") } }
            );
            setData(response.data.list);
        } catch (error) {
            return ({ data: false });
        }

    };

    useEffect(() => {
        if (data.length === 0) {
            fetchdata();
        }
    }, [data]);// eslint-disable-line react-hooks/exhaustive-deps



    const getProvider = () => {

        return data.map((item) => (
            <Option key={item.alert_provider_id} value={item.alert_provider_id} label={item.provider_name}>
                {item.provider_name}
            </Option>
        ))

    };

    return (

        <Select
            showSearch            
            showArrow="true"
            maxTagCount="responsive"
            optionFilterProp="children"
            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
            filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
            {...props}
        >    {data
            ? getProvider()
            : null}</Select>
    );
};

export default Txalert_ProviderListDropdown;