import React, { useState } from "react";
import { DatePicker } from "antd";
import moment from "moment";

const { RangePicker } = DatePicker;

const dateFormat = "ll";

const CustomDateRangeV3 = (props) => {
    const { t, state, unit, limit, setIsDateExceeded } = props;
    const { dates, setDates } = state;
    const [hackValue, setHackValue] = useState();
    const [option] = useState({ unit: unit, limit: limit });

    const disabledDate = (current) => {
        if (!dates || dates.length === 0) {
            return false;
        }
        const tooLate = dates[0] && current?.diff(dates[0], option.unit) >= option.limit;
        const tooEarly = dates[1] && dates[1]?.diff(current, option.unit) >= option.limit;
        return tooEarly || tooLate;
    };

    const onDatesChange = (val) => {
        if (setIsDateExceeded) {
            setIsDateExceeded(val[0] && val[1] && val[1].diff(val[0], option.unit) >= option.limit);
        }
        setDates(val);
    };

    const onOpenChange = (open) => {
        if (open) {
            setHackValue([]);
            setDates([]);
        } else {
            setHackValue(undefined);
        }
    };

    return (
        <RangePicker
            style={{ width: "100%" }}
            ranges={{
                [t('today')]: [moment(), moment()],
                [t('last_7_days')]: [moment().subtract(8, "days"), moment().subtract(1, "days")],
                [t('last_30_days')]: [moment().subtract(31, "days"), moment().subtract(1, "days")],
                [t('this_month')]: [moment().startOf("month"), moment().endOf("month")],
            }}
            dropdownClassName="custom-rangepicker--dropdown"
            format={dateFormat}
            allowClear={false}
            value={hackValue || dates}
            disabledDate={disabledDate}
            onCalendarChange={(val) => setDates(val)}
            onChange={onDatesChange}
            onOpenChange={onOpenChange}
        />
    );
};

export default CustomDateRangeV3;
