import React, { useState, useEffect } from "react";
import { API_TXALERT2 } from "../../../config/config";
import { Modal, Button, Form, Input, Switch } from "antd";
import axios from "axios";
import Swal from "sweetalert2";
import GroupAccess from "../../../helper/GroupAccess";
import TxalertMerchantListComp  from "../Components/MerchantListDropdown";
import TxalertEnrollmentInputTbl from "../Components/EnrollmentInputTbl";
import TxalertBankAccountDropdown from "../Components/BankAccountDropdown";
import CatchError from "../../../helper/CatchError";

const Txalert_DescriptorModal = (props) => {
    const { t, id,setId,isOpen,setIsOpen, fetchList } = props;
    const [details, setDetails] = useState(null);
    const [loading, setLoading] = useState(false);
    const [componentDisabled, setComponentDisabled] = useState(GroupAccess("SYSADMIN") ? false : true); //eslint-disable-line   
    const [enrollRecords, setEnrollRecords] = useState([]);
    const [errors,setErrors] = useState([]);

    const layout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };


    const initData = {
        descriptor_text: "",       
        merchant_id:'',
        acquiring_bank_account_id:'',
        active:true,
        status:0,
        enrollRecords:[]
    };

    const [form] = Form.useForm();

    const handleOk = async () => {
        // Perform your validation here

        const validationPassed = await validateEnrollInput();

        if (validationPassed) {
            setLoading(true);
            // If validation is successful, close the modal
            setIsOpen(false);
            onFinish();
        } else {
            // If validation fails, do not close the modal            
            alert("Invalid inputs rows in enrollment");
        }
    };


    const SaveEnrollRecords = (items) => {     
 //       console.log('items',items);   

        setEnrollRecords(items);       
      
    };


    const saveEnrollment = async(id,rec) => {
        const apiUrl = API_TXALERT2 + `/descriptor/${id}/enrollment`;
       
        await axios
            .post(apiUrl,rec,{ headers: { Authorization: "Bearer " + localStorage.getItem("txalert_token") } })
            .then(({ data }) => {
                if( data.status !== 'OK'){
                    setErrors(data.msg);
                    return data;
                }           
            })
            .catch((error) => {
             return {'status':'EXC','error_msg':error.response}
            }); 

            
    };

    const formValChange = (chgV, allV) => {
       
            setDetails({ ...allV, status: allV.status === true?1:0});
       
        
    };


    useEffect(() => {
  
        if(isOpen){
          
            if (typeof id != 'undefined') {
                
                getDetails();
            }else{
                setDetails(initData);
            }
        }

       
    
    }, [isOpen]); // eslint-disable-line

    const getDetails = () => {
        const apiUrl = API_TXALERT2 + `/descriptor/${id}`;
       
        axios
            .get(apiUrl,{ headers: { Authorization: "Bearer " + localStorage.getItem("txalert_token") } })
            .then(({ data }) => {
                let rec = {
                    descriptor_text: data.descriptor_text,       
                    merchant_id:data.merchant_id,
                    acquiring_bank_account_id:data.acquiring_bank_account_id,
                    status:data.status,
                };
                setDetails(rec);
               // form.setFieldsValue(rec);
                form.setFieldsValue({ ...rec, checked: rec.status=== 1?"true":"false"});
            })
            .catch((error) => {
               
               CatchError(error);
            });

            
    };


    const handleCancel = () => {             
        
        setId('');
        form.setFieldsValue(initData);
        setIsOpen(false);
        setLoading(false);
    };

    const validateEnrollInput = async () => {
        let valid = true;
        enrollRecords.forEach((enroll) => {
            // Check if at least one element is not empty
            const elements = [enroll.alert_provider_login_id, enroll.enrollment_status_id, enroll.update_date];
            const atLeastOneNotEmpty = elements.some((element) => element !== "");

            if (atLeastOneNotEmpty) {
                // If at least one element is not empty, validate all elements
                const allNonEmpty = elements.every((element) => element !== "");

                if (!allNonEmpty) {
                    valid = false;
                }
            }
        });
        return valid;
    }


    const onFinish = () => {
        let apiUrl = API_TXALERT2 ;
       
       if(id === 'undefined' || typeof id === 'undefined' || id === ''){
        apiUrl = apiUrl + "/descriptor";
       }else{
        apiUrl = apiUrl + "/descriptor/"+id;
       }
     
         axios
             .post(apiUrl, details,{ headers: { Authorization: "Bearer " + localStorage.getItem("txalert_token") }})
             .then(({ data }) => {
 //               console.log('data',data);
                 if (data.status === "OK") {
//                    console.log(id);
 //                   console.log('enrollrec',enrollRecords);
                    if(id === 'undefined' || typeof id === 'undefined' || id === ''){
                        //get the id and add enrollments;
                        let id = data.descriptor_id;
                        enrollRecords.map(async(enroll) => {
                            if(enroll.enrollment_status_id && enroll.update_date)
                            {
                              await  saveEnrollment(id,enroll);
                            }
                         });
                       
                       
                        }
                      
                        if(errors.length ===0){
                         Swal.fire({
                             icon: "success",
                             title: data.message,
                             showConfirmButton: false,
                             timer: 2000,
                             onClose: () => {
                                fetchList();
                             },
                         });
                         handleCancel();
                        }else{
                            Swal.fire({
                                icon: "error",
                                title: t("login.dialogs.catch"),
                                text: errors.toString(),
                                showConfirmButton: true,
                                onClose: () => {
                                    setErrors([]);
                                 }
                            });
                        }
                 } else {
                    
                     CatchError(data);
                 }
             })
             .catch((error) => {
            
                 setLoading(false);
                 CatchError(error);
             });
             setIsOpen(false);
             setLoading(false);
     };
    const isOkButtonProps = () => !details || !details.descriptor_text || loading;
  
    return (
        <>
           <Modal
                title={id !== undefined?t("contents.txalert_admin.form.view_descriptor")+ ' id:'+id:t("contents.txalert_admin.form.add_descriptor")}
                visible={isOpen}
                width={800}
                onCancel={handleCancel}
                onOk={handleOk}
                bodyStyle={{ paddingTop: '0px' }}
                destroyOnClose={true}
                afterClose={handleCancel}
                footer={[
                    <Button key="back" onClick={() => handleCancel()}>
                        {t("contents.descriptor.form.cancel")}
                    </Button>,
                     GroupAccess("SYSADMIN") ? 
                    <Button key="submit" type="primary" loading={loading} disabled={isOkButtonProps()} onClick={() => handleOk()}>
                        {loading ? t("contents.descriptor.form.submitting") : t("contents.descriptor.form.submit")}
                    </Button>:'',
                ]}
            >
                <Form {...layout} form={form} name="view_descriptor" onValuesChange={formValChange} preserve={false}>
                <fieldset disabled={componentDisabled}>
                      
                        <Form.Item name="descriptor_text" label={t("contents.descriptor.form.descriptor")} rules={[{ required: true }]}>
                            <Input  />
                        </Form.Item>
                       
                         <Form.Item name="merchant_id" label={t("contents.merchants.title")} rules={[{ required: true }]}>
                        <TxalertMerchantListComp placeholder={t("contents.merchants.title")}/>                        
                        </Form.Item>
                        
                        <Form.Item name="acquiring_bank_account_id" label={t("contents.txalert_admin.form.acquiring_bank")} >
                        <TxalertBankAccountDropdown placeholder={t("contents.txalert_admin.form.acquiring_bank")}/>
                        </Form.Item>

                        <Form.Item
                        key="status"
                        name="status"
                        label={t("contents.descriptors.form.active")}
                        valuePropName="checked"
                        style={{ marginBottom: ".5rem" }}
                        >
                        <Switch />
                    </Form.Item>  
                    {(id === 'undefined' || typeof id === 'undefined' || id === '')?  
                    <div style={{ width: "100%" }} >
                        <TxalertEnrollmentInputTbl key="enroll_input_cmp" type="descriptor"   onChange={SaveEnrollRecords}/>
                    </div> :''}
                    </fieldset>                   
                    </Form>
                    
                   
            </Modal>
        </>
    );
};

export default Txalert_DescriptorModal;