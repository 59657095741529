import React, { useState, useEffect } from "react";
import { PageHeader, Form, Tabs } from "antd";
import axios from "axios";
import Swal from "sweetalert2";
import { API } from "../../config/config";
import InformationForm from "./Form/Information";
import GroupsForm from "./Form/Groups";
import ContactForm from "./Form/Contact";
import ChangePasswordForm from "./Form/ChangePassword";
import TwoFA from "./Form/TwoFA";
import GroupAccess from "./../../helper/GroupAccess";

const { TabPane } = Tabs;

const DATA_URL = "/profile/";
const DATA_URL_UPDATEPROFILE = "/updateprofile/";
const DATA_URL_UPDATEGROUPS = "/updategroups/";
const DATA_URL_CHANGEPASSWORD = "/changepassword/";

const Profile = (props) => {
    const [data, setData] = useState(JSON.parse(localStorage.getItem("loggedUser")));
    const [groups, setGroups] = useState([]);
    const [selectedGroups, setSelectedGroups] = useState([]);
    const [loading, setLoading] = useState(true);
    const [form] = Form.useForm();
    const layout = {
        labelCol: { span: 4 },
        wrapperCol: { span: 14 },
        layout: "horizontal",
        size: "middle",
    };
    const tailLayout = {
        wrapperCol: { offset: 4, span: 14 },
    };

    const { t } = props;

    useEffect(() => {
        if (loading) {
            getProfile();
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const formValChange = (chgV, allV) => {
        setData({ ...data, ...allV });
    };

    const onCheckboxChange = (event) => {
        const { name, checked } = event.target;
        setData({ ...data, [`${name}`]: +checked });
    };

    const onGroupsCheckboxChange = (event) => {
        const { name } = event.target;
        const keys = name.split("|");
        const gid = groups[keys[0]][keys[1]];
        setSelectedGroups(
            selectedGroups.find((selected) => selected === gid) ? selectedGroups.filter((selected) => selected !== gid) : [...selectedGroups, gid]
        );
    };

    const fillValue = (Obj) => {
        form.setFieldsValue(Obj);
    };

    const getProfile = () => {
        const apiUrl = DATA_URL;

        try {
            axios
                .get(API + apiUrl, { headers: { Authorization: "Bearer " + localStorage.getItem("access_token") } })
                .then(({ data }) => {
                    setData(data.profile);
                    setGroups(data.optiongroups);
                    setSelectedGroups(data.selectedgroups);
                    fillValue(data.profile);
                    setLoading(false);
                })
                .catch((error) => error);
        } catch (error) {
            throw error;
        }
    };

    const onFinish = () => {
        const request = {
            ...data,
        };
        delete request.username;
        delete request.status;
        delete request.password;
        delete request.password1;
        delete request.password2;
        delete request.password3;
        delete request.password4;
        delete request.password_changed;
        delete request.locked;
        delete request.last_attempt;
        delete request.last_update;
        delete request.dashboard;

        const apiUrl = DATA_URL_UPDATEPROFILE;
        axios
            .post(API + apiUrl, request, { headers: { Authorization: "Bearer " + localStorage.getItem("access_token") } })
            .then((result) => {
                Swal.fire({
                    icon: "success",
                    title: t("login.dialogs.profile_changed"),
                    showConfirmButton: false,
                    timer: 2000,
                    onClose: () => {
                        props.history.push("/logout");
                    },
                });
            })
            .catch((error) => {
                console.log(error);
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: t("login.dialogs.catch"),
                    showConfirmButton: false,
                    timer: 2000,
                });
            });
    };

    const onFinishGroups = () => {
        const request = {
            uid: data.uid,
            groups: selectedGroups,
        };

        const apiUrl = DATA_URL_UPDATEGROUPS;
        axios
            .post(API + apiUrl, request, { headers: { Authorization: "Bearer " + localStorage.getItem("access_token") } })
            .then((result) => {
                Swal.fire({
                    icon: "success",
                    title: t("login.dialogs.groups_changed"),
                    showConfirmButton: false,
                    timer: 2000,
                    onClose: () => {
                        props.history.push("/logout");
                    },
                });
            })
            .catch((error) => {
                console.log(error);
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: t("login.dialogs.catch"),
                    showConfirmButton: false,
                    timer: 2000,
                });
            });
    };

    const onFinishChangePassword = () => {
        const apiUrl = DATA_URL_CHANGEPASSWORD;
        axios
            .post(
                API + apiUrl,
                { uid: data.uid, password: data.new_password },
                { headers: { Authorization: "Bearer " + localStorage.getItem("access_token") } }
            )
            .then((result) => {
                Swal.fire({
                    icon: "success",
                    title: t("login.dialogs.password_changed"),
                    showConfirmButton: false,
                    timer: 2000,
                    onClose: () => {
                        props.history.push("/logout");
                    },
                });
            })
            .catch((error) => {
                console.log(error);
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: t("login.dialogs.catch"),
                    showConfirmButton: false,
                    timer: 2000,
                });
            });
    };

    return (
        <div>
            <PageHeader className="site-page-header" title={t("contents.profile.title")}></PageHeader>
            <div className="site-layout-background" style={{ padding: "0 24px", minHeight: 360 }}>
                {!loading ? (
                    <Tabs defaultActiveKey="1">
                        <TabPane tab={t("contents.profile.tabs.general_information")} key="1">
                            <InformationForm
                                t={t}
                                data={data}
                                layout={layout}
                                tailLayout={tailLayout}
                                form={form}
                                onValuesChange={formValChange}
                                onSubmit={onFinish}
                                onCheckboxChange={onCheckboxChange}
                            />
                        </TabPane>
                        <TabPane tab={t("contents.profile.tabs.contact")} key="2">
                            <ContactForm
                                t={t}
                                data={data}
                                layout={layout}
                                tailLayout={tailLayout}
                                form={form}
                                onValuesChange={formValChange}
                                onSubmit={onFinish}
                            />
                        </TabPane>
                        {GroupAccess("SYSADMIN") ? (
                            <TabPane tab={t("contents.profile.tabs.group")} key="3">
                                <GroupsForm
                                    t={t}
                                    data={data}
                                    layout={layout}
                                    tailLayout={tailLayout}
                                    groups={groups}
                                    selectedGroups={selectedGroups}
                                    onCheckboxChange={onGroupsCheckboxChange}
                                    onSubmit={onFinishGroups}
                                />
                            </TabPane>
                        ) : null}
                        <TabPane tab={t("contents.profile.tabs.change_password")} key="4">
                            <ChangePasswordForm
                                t={t}
                                data={data}
                                layout={layout}
                                tailLayout={tailLayout}
                                onValuesChange={formValChange}
                                onSubmit={onFinishChangePassword}
                            />
                        </TabPane>
                        <TabPane tab={t("contents.profile.tabs.2fa")} key="5">
                            <TwoFA t={t} data={data} form={form} layout={layout} />
                        </TabPane>
                    </Tabs>
                ) : null}
            </div>
        </div>
    );
};

export default Profile;
