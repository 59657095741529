module.exports = {
    cn: {
        translation: {
            login: {
                email: "电子邮箱",
                username: "用户名",
                password: "密码",
                login: "登录",
                submit: "提交",
                continue: "继续",
                forgotpassword: "忘记密码了吗？",
                backtologin: "返回登录屏幕",
                verification: {
                    verify: "核实",
                    gotit: "知道了！",
                    verificationcode: "验证码",
                    entercode: "输入Google Authenticator代码。",
                    emailcode: 
                        "我们已将验证码发送到该用户帐户的注册电子邮件地址。 请检查您的电子邮件并提供以下代码。",
                    howtosetup: "如何设置两因素身份验证",
                    howtosetup_title: "两因素身份验证设置",
                    howtosetup_text: 
                        "您必须先安装Google身份验证器或FreeOTP之类的身份验证应用程序，然后才能继续。否则，您将无法登录。",
                    noqrcode_title: "设置身份验证应用程序",
                    noqrcode_text: "请与管理员联系。",
                    cantscan_click: "无法扫描？ 点击这里",
                    copied: "已复制",
                    copy_secretkey: "复制密钥",
                    scan_qrcode_below: "扫描下方二维码，完成后提交",
                },
                required: {
                    email: "请输入您的电子邮箱！",
                    username: "请输入您的用户名！",
                    password: "请输入您的密码！",
                },
                dialogs: {
                    login_error: "用户名或密码不正确。",
                    verify_error: "验证码不正确。",
                    login_success: "成功登录。.",
                    reset_password: "重置密码的请求发送到您的帐户电子邮件中。",
                    password_changed: "密码已更新。",
                    groups_changed: "小组已更新。",
                    profile_changed: "个人资料已更新。",
                    catch: "出了些问题！",
                },
                session: {
                    title: "会话到期",
                    desc_1: "您将被登出",
                    desc_2: "秒。",
                    desc_3: "单击确定继续。",
                    expired: "会话过期",
                },
            },
            resetpassword: {
                title: "重设密码",
            },
            header: {
                menu: {
                    profile: "个人资料",
                    logout: {
                        title: "退出",
                        question: "您确定要注销吗？",
                        yes: "是的",
                        no: "不",
                    },
                },
                clock: "系统时间",
            },
            sidebar: {
                dashboard: "主页面",
                merchants: "商户",
                merchant: "商户",
                txfind_bincaids: "TxFind BIN/CAID",
                bincaids: "BIN/CAID",
                descriptors: "描述符",
                descriptor: "描述符",
                alertdescriptors: "描述符",
                bincaiddescriptors: "BIN/CAID",
                payments: "付款",
                transactions: "交易",
                virtualterminal: "虚拟终端",
                'virtualterminal-cliq-cc': "虚拟终端 - Cliq CC",
                'virtualterminal-cliq-ach': "虚拟终端 - Cliq ACH",
                fraudchecks: "欺诈检查",
                refunds: "退款",
                "3dsecure": "3DS",
                "3dsecure_microservice": "3d安全",
                "3dsecure_overview": "概述",
                "3dsecure_merchant": '招商',
                "3ds_transaction": '3DS',
                alerts: "警示",
                alertstats: "警报统计",
                rdractivity: "RDR 活动",
                messages: "消息",
                userguide: "用户指南",
                chargebacks: "退款",
                alertsforreview: "提醒提醒",
                order_insight: "TxFind",
                carts: "推车",
                lookup: "查找历史",
                providers: "供应商",
                merchant_providers: "商户供应商",
                statistics: "统计数据",
                "3dsstats": "3D 安全统计",
                alert: "警报详情",
                chargeback: "退款",
                chargeback_merchants: "退款商户",
                chargeback_providers: "退款供应商",
                chargeback_merchant_providers: "退款商户供应商",
                oi_merchants: "TxFind商户",
                oi_carts: "TxFind推车",
                oi_carts_lookup: "TxFind查找历史",
                oi_cart: "OI购物车详情",
                txfind_merchants: "TxFind商户",
                txfind_descriptors: "TxFind 描述符",
                txfind_descriptor: "TxFind 描述符详细信息",
                txfind_carts: "TxFind推车",
                txfind_carts_lookup: "TxFind查找历史",
                txfind_cart: "TxFind购物车详情",
                profile: "轮廓",
                virtual_terminal: "虚拟终端",
                alertdescriptor:"警报描述符",
                binmid:"BIN / MID",
                "3dsbinmid":"BIN / MID",
                chargeback_merchant: "否認交易退款商户",
                alertproviders:"警报提供者",
                merchantalertproviders:"商户警报提供商",
                chargeback_alertprovider_logins:"商户警报提供商",
                chargeback_alertproviders:"警报提供者",
                txalert:"警报 2.0",
                alertlist:"警报列表",
                merchantlist:"商户列表",
                bincaidlist:"BIN/CAID 列表",
                enrollmentstatuslist:"注册状态列表",
                disputeconditioncodelist:"争议条件代码列表",
                alerttypelist:"警报类型",
                admin:"行政",
                descriptorlist:"描述符列表",
                creditreasonlist:"信用原因清单",
                creditstatuslist:"信用状况清单",
                mcctierlist:"MCC 等级列表",
                alertproviderlist:"警报提供者列表",
                alertproviderlogins:"警报提供者登录列表",
                erproviders:"ER 提供者",
                erproviderlogins:"ER 提供者登录",
                alertresponses:"警报响应",
                ertransactionmatches:"ER 匹配",
                alertdetail:"警报详情",
                generatealert:"生成警报",
                logs:"日志",
                posttoalertproviderlogs:"发布到警报提供者",
                bincaid:"BIN/CAID",
                acquiringbanklist:"收单银行名单",
                acquiringbankaccountlist:"收单银行账户列表",
                posttomerchantlogs:"传送至商户",
                posttoalertproviderretrylogs:"重试传输到警报提供者",
                posttomerchantretrylogs:"重试传送至商户",
                themelist:"主题",
                emailtemplatelist:"电子邮件模板",
                creditrequests:"信用请求",
                merchantdetails:"商户详情",
                alertproviderdetail:"警报提供者详情",
                erproviderlist:"ER 提供者",
                ertransactionmatch:"ER 交易匹配",
                issuerbinlist:"发行人 BIN 列表",
                emailsendlogs:"电子邮件发送日志",
                backgroundprocesslogs:"后台进程日志",
                expressresolvelogs:"快速解决网关日志",
                balance: "餘額",
                paymentlink: '支付連結',
            },
            contents: {
                dashboard: {
                    total: "总额",
                    today: "今日",
                    alerts: "警示",
                    alert_today: "今日警报",
                    alerts_today: "今天的警报",
                    alertsfor: "警报",
                    type: "警示类型",
                    title: "主页面",
                    "3dsecure": "3DS",
                    fraudchecks: "欺诈检查",
                    transactions: "交易",
                    alerts_chart2: "警示-每日警示总数",
                    transaction_count: "交易计数",
                    total_revenue_collected: "总收入",
                    total_counts_of_3dsecure: "3DS 总计数",
                    total_counts_of_fraudcheck: "总数",
                    avg_ticket_value_of_transaction: "平均票券价值",
                    avg_ticket_value_of_fraudcheck: "平均票券价值",
                    expiring: "今天到期",
                    action_required: "需要采取行动",
                    wip: "WIP",
                    alert_info: "警报信息",
                    transaction_info: "交易信息",
                },
                days: {
                    sun: "星期日",
                    mon: "周一",
                    tue: "周二",
                    wed: "周三",
                    thu: "周四",
                    fri: "星期五",
                    sat: "周六",
                },
                months: {
                    january: "一月",
                    february: "二月",
                    march: "三月",
                    april: "四月",
                    may: "五月",
                    june: "六月",
                    july: "七月",
                    august: "八月",
                    september: "九月",
                    october: "十月",
                    november: "十一月",
                    december: "十二月",
                },
                resellers: {
                    title: "经销商",
                },
                retailers: {
                    title: "零售商",
                },
                sites: {
                    title: "站点",
                },
                merchants: {
                    title: "商户",
                    merchant: "商人",
                    details: "商家",
                    retailer_details: "零售商详细信息",
                    payment: "付款",
                    "3ds": "3DS",
                    alerts: "警报",
                    settings: "设置",
                    access_records: "访问记录",
                    bincaid: "BIN/CAID",
                    descriptor:"描述符",
                    balance:"商户余额",
                    form: {
                        sid: "SID",
                        name: "名称",
                        view: "视图",
                        rcode: "Rcode",
                        theme: "主题",
                        status: "状态",
                        active: "活跃的",
                        remarks: "备注",
                        generate: "生成",
                        inactive: "非活动",
                        reseller: "经销商",
                        description: "描述",
                        create_reseller: "创建经销商",
                        txfind_merchant_id: "TxFind 商户 ID",
                        generate_new_rcode: "生成新的 Rcode",
                        please_select_retailer: "请选择零售商",
                        please_select_site: "请选择站点",
                        please_select: "请选择",
                        site_url: "网站 URL(http 地址)",
                        site_rcode: "站点 Rcode",
                        associated_users: "关联用户",
                        stop_send_email: "停止来自 SID 的电子邮件",
                        cbreportemail: "退款报告的电子邮件地址",
                        available_balance_for_withdraw: "提款可用余额",
                        category: "类别",
                        support_group: "支持组",
                        fraud_template: "欺诈模板",
                        skip_ccv: "跳过 CCV 检查",
                        bin_currency: "使用 BIN 选择支付货币",
                        settlement_currency: "结算货币",
                        allow_rebilling: "允许重新计费",
                        proceedeveniffail: "失败时继续处理",
                        margin_percentage: "结算保证金百分比(%)",
                        alert_on_next_approved_tranaction: "下一个批准交易的警报",
                        to_address: "收件人地址",
                        allow_refund: "允许退款",
                        allow_refund_time: "无限退款时间",
                        emaildomain: "无限退款时间未激活 Sendgrid 认证的电子邮件域（收据电子邮件，支持电子邮件）",
                        shippingntracking: "已启用运输和跟踪",
                        nerver: "从不",
                        perday: "每天",
                        unlimited: "无限",
                        notactive: "不活跃",
                        days: "天数",
                        app: "应用程序",
                        brw: "浏览器",
                        "3ri": "3DS 请求者发起 (3RI)",
                        yes: "是的",
                        no: "不",
                        payment_transaction: "支付交易",
                        recurring_transaction: "循环交易",
                        instalment_transaction: "分期交易",
                        add_card: "添加卡片",
                        maintain_card: "维护卡",
                        cardholder_verification: "持卡人验证作为 EMV 令牌 ID & V 的一部分",
                        not_applicable: "不适用",
                        credit: "信用",
                        debit: "借记",
                        gs: "商品/服务购买",
                        ca: "检查验收",
                        af: "账户资金",
                        qt: "准现金交易",
                        pal: "预付费激活和加载",
                        paay: "Paay",
                        gpay: "Gpay",
                        hitrust: "HiTrust",
                        verbose: "详细模式开启",
                        client_mode: "客户端模式",
                        enable_result: "启用结果",
                        disable_challenge: "禁用挑战",
                        enable3ds1_frictionless: "启用 3ds1 无摩擦",
                        enable3DS1_only: "仅启用 3DS 1.0",
                        requestor_name: "请求者姓名",
                        requestor_url: "请求者 URL",
                        authentication_indicator: "3DS 请求者身份验证指示符",
                        challenge_indicator: "3DS 请求者挑战指示器",
                        acct_type: "账户类型",
                        acquirer_bin: "收单银行 BIN",
                        acquirer_mid: "收单方商户 ID",
                        master_acquirer_bin: "万事达卡收单银行 BIN",
                        master_acquirer_mid: "万事达卡收单方商家 ID",
                        jcb_acquirer_bin: "JCB 收单银行 BIN",
                        jcb_acquirer_mid: "JCB 收单方商家 ID",
                        cup_acquirer_bin: "CUP 收单银行 BIN",
                        cup_acquirer_mid: "CUP 收单方商户 ID",
                        amex_acquirer_bin: "AMEX 收单银行 BIN",
                        amex_acquirer_mid: "AMEX 收单方商家 ID",
                        acquirer_mid_pwd: "收单方商户 ID 密码",
                        merchant_category_code: "商户类别代码",
                        merchant_country_code: "商户国家代码",
                        merchant_name: "商家名称",
                        message_category: "消息类别",
                        purchase_currency: "购买货币",
                        trans_type: "交易类型",
                        skip3d2: "跳过 3D2.0 (被网关设置覆盖)",
                        three_ds_provider: "3Ds 提供者",
                        threedsprotocolversion: "3DS 协议版本",
                        paayauth3d2: "Paay APIkey | 秘密",
                        gpay_merchant_token: "Gpay 商户 Token",
                        gpay_merchant_id: "Gpay 商户 ID",
                        gpay_base_url: "Gpay URL",
                        hitrust_account_id: "HiTrust 账户 ID",
                        hitrust_password: "HiTrust 密码",
                        hitrust_base_url: "HiTrust 基础网址",
                        hitrust_mastercard_prefix: "HiTrust Mastercard 请求者前缀",
                        alertapiurl: "警报 api url",
                        descriptorid: "描述符 ID",
                        username: "用户名",
                        token: "令牌",
                        default_dispute_action: "默认争议行动",
                        alert_triggeremail: "提醒邮件？",
                        alert_email: "警报通知邮件",
                        shieldrdr_review_email: "用于快速解决审查通知的电子邮件",
                        statusupdate_url: "警报状态更新 url",
                        match: "仅匹配",
                        match_and_refund: "匹配并退款",
                        api_username:"接口用户名",
                        api_password:"接口密码",
                        max_refund_amount:"最高退款金额",
                        eligible_for_refund:"符合退款资格",
                        created_date:"创建日期",
                        isactive:"活跃?",
                        submit:"提交",
                        new_merchant:"新商戶",
                        cancel: "取消",
                        add_merchant:"添加商户",
                        view_merchant:"查看商户",
                        enrolled_services:"注册服务"
                    },
                },
                descriptors: {
                    title: "警报描述符",
                    details: "描述符详细信息",
                    tabs: {
                        details: "细节",
                        enrollment: "注册",
                        enrollment_history: "招生历史",
                        expressresolve: "争议情报",
                        rdr:"RDR",
                        variations:"多样化",
                        table:{
                            status: "地位",
                            status_reason: "状态原因",
                            providername: "提供者",
                            requested_date: "申请日期",
                            enrollment_date: "注册日期",
                            update_date: "更新日期",
                            effective_date: "生效日期",
                            datetime: "约会时间",
                            date: "日期",
                            gatewayname: "网关",
                            gatewaydescriptor: "网关描述符",
                            terminalid: "终端 ID(MID)",
                            bin:"BIN",
                            caid:"CAID"
                        }
                    },
                    form: {
                        descriptor_id : "描述符 ID",
                        descriptor : "描述符",
                        active: "积极的",
                        site_name: "地点",
                    }
                },
                bincaids: {
                    title: "BIN/CAID",
                    view_bincaid: "查看 BIN/CAID",
                    details: "细节",
                    enrollment_history: "招生历史",
                    ruleset_history:"规则集历史",
                    tabs:{
                        enrollment:"注册"
                    },
                    form: {
                        bin: "Bin",
                        caid: "Caid",
                        mcc: "MCC",
                        tier:"等级",
                        vrol_profile: "VROL 简介",
                        descriptor: "描述符",
                        request_date: "申请日期",
                        update_date: "更新日期",
                        status: "地位",
                        status_reason: "状态原因",
                        reference_no: "参考编号",
                        new_bincaid: "新的 Bin Caid",
                        add_bincaid: "添加 Bin Caid",
                        view_bincaid: "查看 Bin Caid",
                        submit:"提交",
                        submitting:"正在提交",
                        cancel:"取消",
                        merchant:"商戶",
                        rule_set:"规则集",
                        comment:"评论",
                        id:"ID",
                        active:"活跃"
                    }
                },
                transactions: {
                    title: "交易",
                    details: "交易详情",
                    summary: "交易概要",
                    pending_refunds: "待处理退款",
                    approved_count: "批准计数",
                    approved_sum:"核准金额",
                    declined_count:"拒绝计数",
                    declined_sum:"拒绝的金额",
                    total:"总数",
                    action:"行动",
                    tabs: {
                        log: "交易日志",
                        tx_history: "TX 交易历史",
                        tx_details: "TX 详细信息",
                        payee_details: "收款人详细信息",
                        tx_items: "TX 物品",
                        fraudcheck_details: "欺诈检查详细信息",
                        communication_details: "通讯细节",
                        tracking_details: "追踪详情",
                    },
                    form: {
                        transaction_log: "交易日志",
                        card_details: "卡详情",
                        settlement_details: "结算详情",
                        cart_and_processing_details: "购物车和处理细节",
                        user_details: "用户详情",
                        shipping_details: "送货细节",
                        other: "其他",
                        fraudcheck_details: "欺诈检查详细信息",
                        communication_details: "通讯细节",
                        tracking_details: "追踪详情",
                        no_fraudcheck_information: "没有关于此交易的欺诈检查信息。",
                        no_communication_information: "没有关于本次交易的通讯信息。",
                        no_tracking_details: "没有关于此交易的跟踪详细信息。",
                        no_transaction_log: "此交易没有交易记录。",
                        card_type: "卡的种类",
                        card_name: "卡名",
                        card_no: "卡号",
                        card_expiry: "卡到期",
                        start_date: "开始日期",
                        email_address: "电子邮件地址",
                        ip_address: "IP地址",
                        auth_code: "授权码",
                        account_type: "帐户类型",
                        account_name: "帐户名称",
                        account_no: "帐号",
                        routing_no: "路由号码",
                        purse_no: "钱包号码",
                        arn: "收单行编号",
                        settlement_amount: "总结算金额",
                        reserve: "预订",
                        fees: "费用",
                        sitereceive: "应付给商户",
                        cartmargin: "购物车保证金",
                        margin: "利润",
                        currbalance: "当前余额",
                        origtotal: "总金额",
                        origpurch: "支付金额",
                        origship: "运费金额",
                        origtax: "税额",
                        origcoupon: "优惠券金额",
                        processedamount: "处理金额",
                        firstname: "名",
                        lastname: "姓",
                        phoneno: "电话号码",
                        mobileno: "手机号码",
                        address: "地址",
                        suburb: "城市/郊区",
                        state: "状态",
                        postcode: "邮政编码",
                        country: "国家",
                        tid: "商户参考 (TID)",
                        wid: "操作员 ID (WID)",
                        info: "附加信息",
                        ipay: "支付宝信息",
                        ref1: "参考 1",
                        ref2: "参考 2",
                        ref3: "参考 3",
                        ref4: "参考 4",
                        affiliateid: "会员编号",
                        threat_level_assessment: "威胁等级评估",
                        rule: "规则",
                        error: "错误",
                        response: "回复",
                        generates: "生成",
                    },
                },
                fraudchecks: { title: "欺诈检查" },
                refunds: { title: "退款" },
                "3dsecure": {
                    title: "3D安全 - 交易",
                    details: "3D安全 - 细节",
                    create_binmid: "创建新的 BIN / MID",
                    view_binmid: "查看 BIN / MID",
                    imports_binmid: "输入 BIN / MID",
                    tabs: {
                        "3DS_details": "3DS 详细信息",
                    },
                    binmid: {
                        title: "BIN / MID",
                        binmidlist: "BIN / MID 列表",
                        new_binmid: "新的 BinMid",
                        added: "BINMID 添加成功",
                        updated: "BINMID 已经更新"
                    },
                    merchant: {
                        title: '3DS 商家',
                        merchantlist: "3DS 商户名单",
                        new_merchant: '新 3DS 商家',
                    },
                    form: {
                        "3ds_authorization_details": "3DS 授权详情",
                        "3ds_log_details": "3DS 日志详细信息",
                        "3DS20": "3DS2.0",
                        acctNumber: "卡号",
                        purchaseAmount: "数量",
                        threeDSIntegratorOid: "ThreeDS 积分器 Oid",
                        threeDSRequestorOrderID: "ThreeDS 请求者 OrderID",
                        threeDSRequestorData: "ThreeDS 请求者数据",
                        threeDSRequestorID: "ThreeDS 请求者 ID",
                        threeDSServerTransID: "ThreeDS 服务器传输 ID",
                        acsTransID: "ACS 反式 ID",
                        dsTransID: "DS 反式 ID",
                        transStatus: "交易状态",
                        transStatusReason: "交易状态原因",
                        authenticationValue: "认证值",
                        authenticationAlgorithm: "认证算法",
                        eci: "ECI",
                        version: "版本",
                        errCode: "错误代码",
                        errMsg: "错误信息",
                        shield_threeds_ref_diaplay: "Paywiser 3DS 参考",
                        shield_threeds_merchant_ref_display: "商户参考",
                        shield_threeds_provider_ref_display: "3DS 提供程序参考",
                        shield_threeds_ptxid_display: "Paywiser 3DS PTXID",
                        shield_threeds_pay_ptxid_display: "Paywiser 支付 PTXID",
                        threeDSShieldInitLog: "发送日志数据",
                        threeDSShieldNotifyLog: "返回日志数据",
                        binmidID: "BINMID ID",
                        bin: "BIN",
                        mid: "MID",
                        mcc: "MCC",
                        card_type: "Card Type",
                        status: "状态",
                        date_added: "添加日期",
                        site_name: "网站名",
                        cancel: "取消",
                        submit: "提交",
                        submitting: "正在提交",
                        please_select: "请选择"
                    },
                },
                _3dsecure_overview: {
                    title: "3D安全概述",
                    filter_by_site: "按网站过滤",
                    filter_by_status: "按状态过滤",
                    pass: "经过",
                    fail_others: "失败（其他）",
                    no_data: "没有数据",
                    today: "今天",
                    last7DaysPerHour: "过去 7 天每小时",
                    last7DaysPerDay: "每天过去 7 天",
                    last30DaysPerDay: "每天过去 30 天",
                },
                _3dsecure_stats: {
                    title: "3D安全统计",
                    please_select_sid: "请选择网站",
                    please_select_report_type: "请选择报告类型",
                    short_status: "地位",
                    short_reason: "原因",
                    short_status_and_reason: "状态和原因",
                    count: "数数",
                    explanation: "解释",
                    status: "按状态细分",
                    reason: "按原因细分",
                    status_and_reason: "按状态和原因细分",
                    other: '其他',
                    status_total: '全部的',
                    status_explanation: {
                        y: "认证成功",
                        a: "未经过身份验证，但提供了尝试身份验证的证明",
                        c: "需要挑战； 需要额外的身份验证。 仅在禁用挑战时返回（无摩擦）",
                        u: "无法执行身份验证/帐户验证",
                        r: "身份验证被拒绝； 发行人拒绝身份验证并请求不尝试授权",
                        n: "未通过身份验证，交易被拒绝",
                        total: "所有状态的总交易量"
                    },
                    status_reason_explanation: {
                        "1": "卡认证失败",
                        "2": "未知设备",
                        "3": "不支持的设备",
                        "4": "超过认证频率限制",
                        "5": "过期卡",
                        "6": "无效卡号",
                        "7": "无效交易",
                        "8": "无卡记录",
                        "9": "安全故障",
                        "10": "被盗卡",
                        "11": "涉嫌欺诈",
                        "12": "不允许持卡人进行交易",
                        "13": "持卡人未注册服务",
                        "14": "ACS 事务超时",
                        "15": "信心不足",
                        "16": "中等信心",
                        "17": "高度自信",
                        "18": "非常高的信心",
                        "19": "超过 ACS 最大挑战",
                        "20": "不支持不付款交易",
                        "21": "不支持 3RI 交易",
                        other: "意外原因"
                    }
                },
                _3dsecure_merchant: {
                    new_merchant: '新 3DS 商家',
                    edit_merchant: '编辑 3DS 商家',
                    form: {
                        merchant_detail: "商户详情",
                        site_detail: "站点详情",
                        "3ds_setting": "3DS 设置",
                        name: "姓名",
                        description: "描述",
                        remarks: "评论",
                        contact: "接触",
                        status: '地位',
                        status_option_active: '积极的',
                        status_option_deactivated: '已停用',
                        provider: '提供者',
                        site_currency: '货币',
                        site_url: '网址',
                        verbose_mode: '详细模式开启',
                        client_mode: '客户端模式',
                        client_mode_option_app: '应用程序',
                        client_mode_option_brw: '浏览器',
                        client_mode_option_3ri: '3DS 请求者发起 (3RI)',
                        enable_result: '启用结果',
                        enable_result_option_y: "是的",
                        enable_result_option_n: "不",
                        disable_challenge: '禁用挑战',
                        disable_challenge_option_y: "是的",
                        disable_challenge_option_n: "不",
                        enable_3ds1_friction_less: "启用 3DS 1.0 无摩擦",
                        enable_3ds1_friction_less_option_y: "是的",
                        enable_3ds1_friction_less_option_n: "不",
                        enable_3ds1_only: "仅启用 3DS 1.0",
                        enable_3ds1_only_option_y: "是的",
                        enable_3ds1_only_option_n: "不",
                        requestor_name: '请求者姓名',
                        requestor_url: '请求者网址',
                        "3DS_requestor_authentication_indicator": "3DS 请求者身份验证指示器",
                        "3DS_requestor_authentication_indicator_option_payment_transaction": "支付交易",
                        "3DS_requestor_authentication_indicator_option_recurring_transaction": "经常性交易",
                        "3DS_requestor_authentication_indicator_option_installment_transaction": "分期交易",
                        "3DS_requestor_authentication_indicator_option_add_card": "添加卡",
                        "3DS_requestor_authentication_indicator_option_maintain_card": "维护卡",
                        "3DS_requestor_authentication_indicator_option_cardholder_verification": "持卡人验证作为 EMV 令牌 ID&V 的一部分",
                        "3DS_requestor_challenge_indicator": "3DS 请求的挑战指示器",
                        "3DS_requestor_challenge_indicator_option_payment_transaction": "支付交易",
                        "3DS_requestor_challenge_indicator_option_recurring_transaction": "经常性交易",
                        "3DS_requestor_challenge_indicator_option_installment_transaction": "分期交易",
                        "3DS_requestor_challenge_indicator_option_add_card": "添加卡",
                        acct_type: "账户类型",
                        acct_type_option_not_applicable: "不适用",
                        acct_type_option_credit: "信用",
                        acct_type_option_debit: "借方",
                        visa_acquiring_bank_bin: "签证受理银行Bin",
                        visa_acquirer_merchant_id: "Visa 收单机构商户 ID",
                        mastercard_acquiring_bank_bin: "万事达卡收单银行 BIN",
                        mastercard_acquirer_merchant_id: "万事达卡收单机构商户 ID",
                        jcb_acquiring_bank_bin: "JCB 收单银行 Bin",
                        jcb_acquirer_merchant_id: "JCB 收单机构商户 ID",
                        cup_acquiring_bank_bin: "银联收单银行BIN",
                        cup_acquirer_merchant_id: "银联收单机构商户ID",
                        amex_acquiring_bank_bin: "美国运通收单银行 Bin",
                        amex_acquirer_merchant_id: "AMEX 收单机构商户 ID",
                        acquirer_merchant_id_password: "收单机构商户 ID 密码",
                        merchant_category_code: "商户类别代码",
                        merchant_country_code: '商户国家代码',
                        merchant_name: '商户名称',
                        message_category: '留言类别',
                        message_category_option_pa: 'PA',
                        message_category_option_npa: 'NPA',
                        purchase_currency: '购买货币',
                        transaction_type: '交易类型',
                        transaction_type_option_gs: '商品/服务购买',
                        transaction_type_option_ca: '检查验收',
                        transaction_type_option_af: '账户资金',
                        transaction_type_option_qt: '准现金交易',
                        transaction_type_option_pal: '预付费激活和加载',
                        "3ds_provider": '3DS 提供商',
                        "3ds_provider_option_hitrust": 'HiTrust',
                        "3ds_protocol_version": "3DS协议版本",
                        hitrust_account_id: 'Hitrust 帐户 ID',
                        hitrust_password: '高信任密码',
                        hitrust_base_url: "HiTrust 基本 URL",
                        hitrust_mastercard_requestor_prefix: 'HiTrust 万事达卡请求者前缀'
                    }
                },
                messages: { title: "消息" },
                chargebacks: {
                    title: "退款",
                    chargeback: "退款",
                    merchants: "商户",
                    providers: "供应商",
                    merchantproviders: "商户供应商",
                    document_file: "文件档案",
                    new_chargeback: "新的退款",
                    chargeback_import: "拒付汇入",
                    chargeback_details: "退款详情",
                    create_merchant: "创建商家",
                    create_provider: "创建提供者",
                    create_merchant_provider: "创建商户提供商",
                    view_merchant: "查看商家",
                    view_provider: "查看提供者",
                    new_merchant: "新商人",
                    new_provider: "新供应商",
                    new_merchant_provider: "新商户供应商",
                    request_alert_credit: "请求警报信用",
                    chargeback_documents: "退单文件",
                    alertproviders:"警报提供者",
                    new_alertprovider: "新警报提供者",
                    create_alertprovider: "建立警报提供者",
                    view_alertprovider: "查看警报提供者",
                    new_merchant_alertprovider: "新商家警报提供者",
                    create_merchant_alertprovider: "建立商家警报提供者",
                    view_merchant_alertprovider: "查看商家警报提供者",
                    merchantalertproviders: "商家警报提供者",
                    form: {
                        cancel: "取消",
                        upload: "上载",
                        submit: "提交",
                        import: "进口",
                        importing: "输入",
                        uploading: "上载中",
                        submitting: "提交中",
                        select_file: "选择文件",
                        file_name: "文档名称",
                        csv_file: "CSV文件",
                        merchant: "商户名称",
                        provider: "提供者",
                        retailer: "零售商",
                        merchant_transaction_id: "商家交易编号",
                        transaction_date: "交易日期",
                        card_number: "卡号",
                        descriptor: "描述符",
                        amount: "数量",
                        merchant_id: "商户编号",
                        arn: "收单行编号",
                        response_message: "回应讯息",
                        download_template: "下载范本",
                        base64_cbdata: "原始数据",
                        created_date: "创建日期",
                        name: "姓名",
                        alerts: "警报",
                        transactionprocessing: "事务处理",
                        jwt_token: "JWT 令牌",
                        generate_jwt: "生成新的 JWT 令牌",
                        classfile: "类文件",
                        url: "Url",
                        apiurl: "Api Url",
                        autoimport: "自动导入",
                        isactive: "地位",
                        password1: "密码 1",
                        password2: "密码 2",
                        password3: "密码 3",
                        chargebackReference: "退款参考",
                        alert_id: "警示ID",
                        currency: "货币",
                        token: "代币",
                        notify_url:"通知网址",
                        chargeback_date:"退款日期",
                        alertprovider:"警报提供者",
                        auth1:"Auth1",
                        auth2:"Auth2",
                        auth3:"Auth3"
                    },
                    messages: {
                        upload_successful: "上传成功",
                        file_must_smaller: "档案必须小于700KB",
                        document_upload_successful: "文件上传成功",
                        chargeback_import_successful: "退款导入成功.",
                        chargeback_create_successful: "退款创建成功.",
                        notice_1: "请确保第一行包含标题而不是产品，并且字段分隔符设置为逗号",
                    },
                    tooltip: {
                        max_upload_file_size: "最大上传文件大小",
                        alert_matched: "警报匹配",
                        transaction_matched: "交易匹配",
                        required_documents_upload: "需要文件上传！",
                    },
                },
                alerts: {
                    title: "警示",
                    title_details: "警示",
                    title_expiry: "警示 - 今天到期",
                    status: "状态",
                    reason: "原因",
                    alert_id: "警示ID",
                    refunded: "已退款",
                    not_settled: "未解决",
                    not_refunded: "不退款",
                    update_status: "更新状态",
                    alert_details: "警示详细信息",
                    duplicate_alertid: "重复的警示ID",
                    update_status_successful: "更新状态成功",
                    receive_data: "接收数据",
                    alertdate: "提醒日期",
                    sitename: "网站名称",
                    alert_type: "警示类型",
                    transaction_date: "交易日期",
                    transaction_amount: "交易金额",
                    transaction_currency: "交易货币",
                    providername: "提供商",
                    retailername: "零售商名称",
                    provider: "提供商",
                    provider_caseid: "提供商参数",
                    cardnumber: "卡号",
                    cardtype: "Card Type",
                    merchantname: "商户",
                    descriptor: "描述符",
                    issuername: "发卡行名称",
                    source: "来源",
                    arn: "收单行编号",
                    authcode: "授权码",
                    outcome: "结果",
                    reasoncode: "Code",
                    outcome_updateon: "结果更新日期",
                    outcome_updated_on: "结果更新于",
                    merchant_request: "商户请求",
                    merchant_transaction_id: "商户交易ID",
                    merchant_updated_on: "商户更新",
                    belongtomerchant: "属于商户",
                    chargeback_proceeded: "发生了拒付",
                    is_duplicate: "是重复的",
                    alert_credits: "警报信用",
                    alert_credit_documents: "提醒信用文件",
                    merchant_updates: "商家更新",
                    repost_alert: "重新发布警报",
                    request_credit: "请求信用",
                    credit_reason: "信用原因",
                    add_to_batch: "添加到信用批次",
                    status_updates: "状态更新",
                    comment: "评论",
                    yes: "是的",
                    no: "No",
                    mcc: "MCC",
                    tier:"等级",
                    count: "Count",
                    stat_title: "Alert Statistics",
                    please_select_sid: "Please Select Site",
                    amount_refunded: "退款金额",
                    er_eligible:"ER 合格的",
                    er_enabled:"ER 已启用",
                    er_complete:"ER 完成",
                    bin:"Bin",
                    caid:"Caid",
                    tooltip: {
                        outcome: "来自商户系统的最后结果状态。",
                        outcome_updateon: "日期Paywiser更新了警示提供者。",
                        merchant_updated_on: "商户发送Paywiser的最新日期。",
                        receive_data: "这是显示原始数据的按钮。",
                        potential_transaction_matches: "潜在交易匹配",
                    },
                    required_messages: {
                        duplicate_alertid_required: "请输入重复警示ID。",
                        amount_refunded_required: "请输入退款金额。",
                        duplicate_alertid_not_valid: "重复的警示ID无效。",
                    },
                    dispute_condition_code:"争议条件代码",
                    update_warning:"更新将发送给发行人。但是，并非每个发行人都接受更新，有些发行人只会确认发送给他们的第一个状态"
                },
                rdractivity: {
                    title: "RDR 活动",
                },
                profile: {
                    title: "个人资料",
                    tabs: {
                        general_information: "一般信息",
                        contact: "联系",
                        group: "小组",
                        change_password: "更改密码",
                        "2fa": "2FA",
                    },
                    form: {
                        submit: "提交",
                        username: "用户名",
                        first_name: "名",
                        last_name: "姓",
                        email: "电子邮箱",
                        client_ip_restrictions: "客户端IP限制",
                        company: "公司",
                        position: "职位",
                        primary_contact: "主要联系人",
                        financial_contact: "财务联系人",
                        technical_contact: "技术联系人",
                        support_contact: "客服联系人",
                        comment: "注释",
                        phone: "电话",
                        mobile: "手机",
                        other: "其他",
                        address: "地址",
                        city: "城市",
                        state: "省份/州",
                        postcode: "邮编",
                        country: "国家",
                        old_password: "旧密码",
                        new_password: "新密码",
                        confirm_password: "确认密码",
                        "2fa_option": "2FA 选项",
                        secret_key: "密钥",
                        authenticator_app: "身份验证器应用程序",
                        user_theme: "主题皮肤",
                        required_messages: {
                            inform_fname_required: "请输入您的名字！",
                            inform_lname_required: "请输入您的姓氏！",
                            inform_email_required: "请输入您的电子邮箱！",
                            changepass_old_required: "请输入您的旧密码！",
                            changepass_new_required: "请输入您的新密码！",
                            changepass_same_current: "您的新密码不能与您当前的密码相同！",
                            changepass_confirm_required: "请输入您的确认密码！",
                            changepass_confirm_nomatch: "确认密码不正确！",
                            changepass_new_min8: "新密码必须至少包含8个字符。",
                            changepass_confirm_min8: "确认密码必须至少为8个字符。",
                        },
                    },
                },
                alertsforreview: {
                    title: "提醒提醒",
                },
                oi: {
                    merchants: "TxFind - 商家",
                    bincaids: "TxFind - BIN/CAID",
                    descriptors: "TxFind - 描述符",
                    descriptor_details: "TxFind - 描述符详细信息",
                    cart_list: "TxFind - 购物车",
                    cart_details: "TxFind - 购物车详情",
                    lookup_history: "TxFind - 查找历史",
                    new_merchant: "新商人",
                    create_merchant: "创建商家",
                    merchant_created: "商家创建",
                    merchant_updated: "商家更新",
                    view_merchant: "查看商家",
                    received_data: "接收数据",
                    return_data: "返回数据",
                    create_descriptor: "创建描述符",
                    descriptor_created: "已创建描述符",
                    descriptor_updated: "描述符已更新",
                    new_descriptor: "新描述符",
                    new_bincaid: "新的 BIN/CAID",
                    edit_bincaid: "编辑 BIN/CAID",
                    bincaid_added: "添加了 BIN/CAID",
                    bincaid_updated: "BIN/CAID 更新",
                    tabs: {
                        transaction_details: "交易明细",
                        customer_information: "客户资料",
                        past_transactions: "过往交易",
                        product_purchased: "购买的产品",
                        industry_details_flight_details: "行业详情 - 航班详情",
                        industry_details_hotel_details: "行业详情 - 酒店详情",
                        delivery_details: "交货详情",
                        physical_fulfillment: "物流履行",
                        digital_service_delivery: "数字服务交付",
                        crm_details: "客户关系管理详情",
                        lookup_history: "查找历史",
                        attachments: "附件"
                    },
                    form: {
                        name: "名称",
                        title: "标题",
                        arn: "ARN",
                        merchant_id: "商户编号",
                        jwt_token: "JWT 令牌",
                        generate_jwt: "生成新的 JWT 令牌",
                        txshieldoicartreference: "TxFind 购物车参考",
                        lookupreference: "查找参考",
                        lookupdate: "查阅日期",
                        receiveddata: "接收数据",
                        returndata: "返回数据",
                        merchantid: "商户编号",
                        merchantref: "商户参考",
                        paymentdescriptor: "描述符",
                        authorizationcode: "授权码",
                        authorizationdate: "授权日期",
                        authorizationamount: "授权金额",
                        settlementdate: "结算日期",
                        settlementamount: "结算金额",
                        transactiondate: "交易日期",
                        cardno: "信用卡号码",
                        alerthappened: "警报",
                        alertreference: "警报参考",
                        chargebackhappened: "退款",
                        chargebackreference: "退款参考",
                        datecreated: "日期",
                        attachmentsid: "附件 ID",
                        attachmentsfile: "附件文件",
                        taxamount: "税",
                        billingaddressdetails: "帐单地址",
                        shippingandhandlingamount: "运费和手续费",
                        shippingaddressdetails: "送货地址",
                        totalamount: "全部的",
                        dateofrefund: "退款日期",
                        refundamount: "退款",
                        refundprocessed: "退款已处理",
                        avsresultcode: "AVS结果",
                        avschecked: "AVS检查",
                        cvvresultcode: "CVV结果",
                        cvvchecked: "CVV检查",
                        threedsecicode: "3DS Eci 代码",
                        threedsauthenticationvalue: "3DS 认证值",
                        devicename: "设备名称",
                        deviceid: "设备编号",
                        devicelocation: "设备位置",
                        ipaddress: "IP地址",
                        refundpolicy: "退款政策",
                        termsandconditions: "条款和条件",
                        customername: "顾客姓名",
                        firsttransactiondate: "首次交易日期",
                        emailaddress: "电子邮件地址",
                        lengthofrelationship: "关系长度",
                        phonenumber: "电话号码",
                        provider: "提供者",
                        merchant: "商人",
                        active: "积极的",
                        inactive: "不活跃",
                        status: "地位",
                        updating: "进行中",
                        update: "更新",
                        bin: "BIN",
                        caid: "CAID",
                        referenceNumber: "参考编号。",
                        addbincaidwarning: "如果 BIN & CAID 已经存在，将改为执行更新"
                    },
                    advanced_search: {
                        value_field: "搜索价值",
                        txshieldoicartreference_field: "TxFind 购物车参考",
                        arn_field: "ARN",
                        cardnumber_field: "卡号",
                        descriptor_field: "描述符",
                        merchant: "商家",
                        had_lookup: "查找",
                        alert: "警报",
                        chargeback: "退款",
                        descriptor: "描述符",
                        search_btn: "搜索",
                        reset_btn: "重置",
                    },
                },
                virtual_terminal: {
                    title: "虚拟终端",
                    processor_details: "处理器详细信息",
                    checkout_details: "结帐详情",
                    additional_details: "额外细节",
                    billing_information: "账单信息",
                    order_information: "订单信息",
                    shipping_address: "收件地址",
                    merchant_defined_fields: "商家定义字段",
                    payment_related_info: "支付相关信息",
                    payment_successful: "支付成功",
                    payment_failed: "支付失败",
                    transaction_reference: "交易参考",
                    total_amount: "总金额",
                    process_button: "处理",
                    back_to_virtual_terminal_button: "返回虚拟终端",
                    form: {
                        process: "处理",
                        item_no: "项目编号",
                        quantity: "数量",
                        currency: "货币",
                        sales_tax: "销售税",
                        tx_action: "发送动作",
                        item_name: "项目名称",
                        description: "描述",
                        amount_unit: "金额/单位",
                        affiliate_id: "会员编号",
                        coupon_value: "优惠券价值",
                        shipping_cost: "运输费",
                        add_cart_item: "添加购物车项目",
                        additional_details: "额外细节",
                        add_processing_fees: "添加处理费",
                        please_select_site: "请选择网站",
                        please_select_currency: "请选择货币",
                        ref_1: "参考编号 1",
                        ref_2: "参考编号 2",
                        ref_3: "参考编号 3",
                        ref_4: "参考编号 4",
                        sid: "SID",
                        wid: "WID",
                        tid: "TID",
                        card_no: "信用卡号",
                        expiry: "到期",
                        cvvcid: "CVV/CID",
                        surcharge_type: "附加费类型",
                        amount: "金额",
                        surcharge: "附加费",
                        total: "全部的",
                        firstname: "名",
                        lastname: "姓",
                        country: "国家",
                        address1: "地址",
                        address2: "地址（续）",
                        city: "城市",
                        state_province: "州/省",
                        zip: "邮政编码",
                        phone: "电话号码",
                        email: "电子邮件地址",
                        orderid: "订单编号",
                        ponumber: "订单号",
                        orderdesc: "订单说明",
                        shipping: "船运",
                        tax_exempt: "免税",
                        sameBilling: "同开票",
                        merchant_defined_field: "商户定义字段",
                        routing_number: "路由号码",
                        account_number: "帐号",
                        amount_only_usd: "金额（USD）",
                        account_type: "帐户类型"
                    },
                },
                possibletransactionmatches: {
                    title: "可能的交易匹配",
                    title_matched: "争议情报交易匹配",
                    transaction_info: "交易信息",
                    confirm: {
                        q_1: "您确定要匹配吗？",
                        notice_1: "将发生以下变化：",
                        notice_11: "我们将尝试退款",
                        notice_12: "订单 ID",
                        notice_21: "如果成功，我们将更新警报",
                        notice_22: "有以下内容：",
                        outcome: "结果：已解决",
                        refunded: "退款状态：已退款",
                        reason: "原因：案例已解决，信用和取消已处理",
                    },
                },
                possiblerdrtransactionmatches: {
                    title: "可能的 RDR 交易匹配",
                    title_matched: "争议情报交易匹配",
                    transaction_info: "交易信息",
                    confirm: {
                        q_1: "您确定要匹配吗?",
                        notice_1: "将会发生以下变化:",
                        notice_11: "我们将尝试对此交易标记警报",
                        notice_12: "在网关中",
                        notice_21: "如果成功，我们将更新警报",
                        notice_22: "与警报的原始状态"
                    },
                },
                pagenotfound: {
                    title: "找不到网页",
                    text: "抱歉，您访问的页面不存在",
                    button: "返回首页",
                },
                txalert_admin:{
                    enrollment_statuses:"注册状态",
                    dispute_condition_code:"争议条件代码",
                    credit_reasons:"信用原因清单",
                    credit_statuses: "信用状况",
                    mcc_tiers:"MCC 层级",
                    alert_providers:"警报提供者",
                    alert_types:"警报类型",
                    alert_provider_logins:"警报提供商登录",
                    er_logins:"ER 网关登录",
                    providerpostlog:"传输到提供商日志",
                    posttomerchantlog:"传输至商户日志",
                    er_providers:"ER 供應商",
                    enrollmentstatus:"注册状况",
                    acquiringbanklists:"收单银行名单",
                    acquiringbankaccountlist:"收单银行账户列表",
                    providerpostretrylog:"传输到提供商重试日志",
                    posttomerchantretrylog:"传输至商户重试日志",
                    themelist:"主题列表",
                    emailtemplatelist:"电子邮件模板",
                    credit_requests:"信用请求",
                    new_credit_request:"新的信用请求",
                    add_credit_request:"添加信用请求",
                    ertransactionmatches:"快速解决匹配",
                    alert_provider:"警报提供者",
                    alertresponses:"警报响应",
                    ermatch_transaction:"警报 ER 交易匹配",
                    issuerbins:"发行人 Bin 列表",
                    import_issuerbin:"导入发行人 Bin 列表",
                    import_descriptor:"导入描述符",
                    import_bincaid:"导入 BIN/CAID",
                    emailsendlog:"电子邮件发送日志",
                    backgroundprocesslog:"后台进程日志",
                    expressresolvelogs:"快速解决网关日志",
                    tabs:{
                        details:"细节",
                        descriptors:"描述符",
                        bincaids:"BIN/CAID",
                        ergateways:"ER 网关",
                        posttomerchant:"传输至商户",
                        posttoprovider:"传输到提供商"
                    },
                    form:{
                        new_dispute_condition_code:"新争议条件代码",
                        add_dispute_condition_code:"添加争议条件代码",
                        view_dispute_condition_code:"查看争议条件代码",
                        condition_code:"条件代码",
                        network:"网络",
                        dispute_condition:"争议条件",
                        category:"类别",
                        new_enrollstatus:"新注册状态",
                        add_enrollstatus:"添加注册状态",
                        view_enrollstatus:"查看注册状态",
                        cancel: "取消",
                        submit:"提交",
                        submitting:"正在提交",
                        status:"状态",
                        isactive:"活跃的",
                        admin_only:"仅限管理员",
                        new_credit_reason:"新信用原因",
                        add_credit_reason:"添加信用原因",
                        view_credit_reason:"查看信用原因",
                        new_creditstatus:"新信用状态",
                        add_creditstatus:"添加信用状态",
                        view_creditstatus:"查看信用状态",
                        new_mcc:"新 MCC",
                        add_mcc:"添加 MCC",
                        view_mcc:"查看 MCC",
                        mcc:"MCC",
                        mcc_description:"MCC 描述",
                        tier:"等级",
                        new_alert_provider:"新警报提供者",
                        add_alert_provider:"添加警报提供者",
                        view_alert_provider:"查看警报提供者",
                        provider_name:"提供商名称",
                        class_file:"类文件",
                        email:"电子邮件",
                        response_expiry:"响应到期(小时)",
                        new_alert_type:"新警报类型",
                        add_alert_type:"添加警报类型",
                        view_alert_type:"查看警报类型",
                        alert_type:"警报类型",
                        alert_provider:"警报提供者",
                        new_alert_provider_login:"新警报提供商登录",
                        add_alert_provider_login:"添加警报提供商登录",
                        view_alert_provider_login:"查看警报提供商登录",
                        path:"文件夹路径",
                        url:"URL",
                        username:"用户名",
                        authentication1:"验证1",
                        authentication2:"验证2",
                        authentication3:"验证3",
                        gateway_name:"网关名称",
                        gateway_reference:"网关参考",
                        description:"描述",
                        new_er_provider:"新 ER 提供商",
                        add_er_provider:"添加 ER 提供商",
                        view_er_provider:"查看 ER 提供商",
                        new_er_login:"新 ER 提供商登录",
                        add_er_login:"添加 ER 提供商登录",
                        view_er_login:"查看 ER 提供商登录",
                        api_url:"Api URL",
                        currency:"货币",
                        new_alert_response:"新警报响应",
                        add_alert_response:"添加警报响应",
                        view_alert_response:"查看警报响应",
                        status_code:"状态码",
                        outcome:"结果",
                        refunded:"已退款",
                        not_refunded:"未退款",
                        not_settled:"未解决",
                        new_descriptor:"新描述符",
                        add_descriptor:"添加描述符",
                        view_descriptor:"查看描述符",
                        ertransactionmatches:"找到ER交易匹配",
                        descriptor:"描述符",
                        cardnumber:"卡号",
                        transaction_date:"交易日期",
                        transaction_currency:"交易币种",
                        transaction_amount:"交易金额",
                        arn:"ARN",
                        authcode:"授权码",
                        disputeconditioncode:"争议条件代码",
                        issuername:"发行人名称",
                        generatetestalert:"生成测试警报",
                        process:"过程",
                        sent_data:"发送数据",
                        response_data:"响应数据",
                        standard_alerts:"标准警报",
                        rdr_alerts:"RDR 警报",
                        updateenrollment:"更新注册",
                        acquiring_bank:"收单银行",
                        new_acquiring_bank:"新收单银行",
                        add_acquiring_bank:"添加收单银行",
                        view_acquiring_bank:"查看收单银行",
                        bank_name:"银行名",
                        state:"州",
                        country:"国家",
                        new_acquiring_bank_account:"新收单银行账户",
                        add_acquiring_bank_account:"添加收单银行账户",
                        view_acquiring_bank_account:"查看收单银行账户",
                        bin:"Bin",
                        mid:"MID",
                        new_theme:"新主题",
                        add_theme:"添加主题",
                        view_theme:"查看主题",
                        from_name:"来自名字",
                        from_email_address:"发件人电子邮件地址",
                        theme_name:"主题名称",
                        theme_id: "主题 ID",
                        template_id: "模板 ID",
                        template_code: "模板代码",
                        new_email_template:"新电子邮件模板",
                        add_email_template:"添加电子邮件模板",
                        view_email_template:"查看电子邮件模板",
                        dashboard_url:"仪表板 Url",
                        statement_id:"对帐单 Id",
                        provider_statement_id:"提供者对帐单 Id",
                        invoice_number:"发票编号",
                        credit_status:"信用状况",
                        received_date:"收到的日期",
                        requested_date:"申请日期",
                        status_update_date:"状态更新日期",
                        credit_reason:"信用原因",
                        status_reason:"状态原因",
                        comment:"评论",
                        merchant_id:"商家 Id",
                        merchant_name:"商家名称",
                        created_date:"创建日期",
                        alert_api_url:"警报 API 网址",
                        api_user_name:"API 用户名",
                        api_password:"API 密码",
                        default_dispute_action:"默认争议处理",
                        alert_email:"警报电子邮件",
                        alert_trigger_email:"警报触发电子邮件",
                        theme:"主题",
                        max_refund_amount:"快速解决 Tx 最大值",
                        eligible_for_refund:"有资格申请信用",
                        new_credit_request:"新的信用请求",
                        add_credit_request:"添加信用请求",
                        view_credit_request:"查看信用请求",
                        alert_provider_id:"警报提供者 Id",
                        reason:"原因",
                        action: "行动",
                        gateway_tx_date: "网关交易日期",
                        gateway_tx_id: "网关交易 ID",
                        gateway_tx_authcode:"网关授权码",
                        gateway_tx_status:"网关交易状态",
                        merchant_tx_id:"商户交易ID",
                        transaction_info:"交易信息",
                        match_info:"匹配分信息",
                        new_issuerbin:"新发行人Bin",
                        add_issuerbin:"添加发行人Bin",
                        view_issuerbin:"查看发行人Bin",
                        brand:"品牌",
                        type:"类型",
                        level:"等级",
                        isoa2:"ISO A2",
                        isoa3:"ISO A3",
                        isonumber:"ISO 编号",
                        www:"WWW",
                        phone:"电话",
                        delimiter:"分隔符",
                        header_row_present:"标题行存在?",
                        merchant:"商户",
                        provider:"提供者"
                    }
                },
                descriptor:{
                    details:"细节",
                    variations:"多样化",
                    descriptor:"描述符",
                    form:{
                        new_descriptor: "新描述符",
                        add_descriptor: "添加描述符",
                        view_descriptor: "查看描述符",
                        submit:"提交",
                        submitting:"正在提交",
                        cancel:"取消",
                        descriptor_id : "描述符 ID",
                        descriptor : "描述符",
                        active: "活跃",
                        merchant_name:"商家名称"
                    }
                },
                paymentlink: {
                    title: '支付連結',
                    title_not_found: '沒有與您的帳戶關聯的網站。',
                    new_paymentlink: '付款連結',
                    form: {
                        sid: "SID",
                        amount: "數量",
                        currency: '貨幣',
                        payment_description: '付款說明',
                        expiry_date: '到期日期',
                        first_name: '名',
                        last_name: '姓',
                        email: '電子郵件',
                        phone: '電話',
                        address:'地址',
                        city: '城市',
                        state: '狀態',
                        country: '國家',
                        please_select_site: "請選擇站點",
                        please_select_currency: "請選擇貨幣",
                        please_select_country: "請選擇國家",
                        cancel: '取消',
                        submit: '提交',
                    }
                }
            },
            tables: {
                fields: {
                    id: "身份证",
                    _id: "ID",
                    arn: "ARN",
                    txid: "交易ID",
                    name: "名字",
                    time: "时间",
                    fees: "费用",
                    files: "档案文件",
                    amount: "数量",
                    reason: "原因",
                    action: "行动",
                    status: "状态",
                    subject: "主题",
                    outcome: "结果",
                    reserve: "备用金",
                    message: "消息",
                    card_no: "卡号",
                    currency: "货币",
                    merchant: "商人",
                    reseller: "转销商",
                    detail_id: "详细ID",
                    sitename: "网站名称",
                    site_name: "网站名称",
                    rcode: "Rcode",
                    site_status: "网站状态",
                    retailer_name: "零售商名称",
                    timestamp: "时间",
                    cardnumber: "卡号",
                    alert_type: "警示类型",
                    alertdate: "警示日期",
                    descriptor: "描述符",
                    descriptor_text: "描述文本",
                    alert_descriptor: "警示描述符",
                    bank_mid: "银行 MID",
                    reason_code: "代码",
                    cardno: "卡号",
                    card_number: "卡号",
                    created_date: "创建日期",
                    reseller_name: "转销商",
                    site_receive: "站点接收",
                    merchantname: "商户名称",
                    merchant_name: "商户名称",
                    descriptor_title: "描述符标题",
                    pay_method: "支付方式",
                    payment_methods: "支付方式",
                    transactiondate: "交易日期",
                    transaction_date: "交易日期",
                    response_message: "回应讯息",
                    merchant_request: "商户请求",
                    merchant_response: "商户回应",
                    merchant_updatedate: "商户更新日期",
                    merchant_transaction_id: "商家交易编号",
                    matchid: "ID",
                    gwname: "闸道",
                    alertid: "警示ID",
                    orderid: "订单编号",
                    datecreated: "日期",
                    refundstatus: "退款状态",
                    gw_transaction_date: "GW 交易日期",
                    gw_transactionid: "GW交易编号",
                    retrieval_date:"检索日期",
                    provider_caseid: "提供者参考",
                    updated_by: "更新者",
                    txshieldoicartreference: "TxFind 购物车参考",
                    lookupreference: "查找参考",
                    lookupdate: "查阅日期",
                    receiveddata: "接收数据",
                    returndata: "返回数据",
                    merchantid: "商户编号",
                    merchantref: "商户参考",
                    paymentdescriptor: "描述符",
                    authorizationcode: "授权码",
                    authorizationdate: "授权日期",
                    authorizationamount: "授权金额",
                    settlementdate: "结算日期",
                    settlementamount: "结算金额",
                    dateofpurchase: "购买日期",
                    amountofpurchase: "购买金额",
                    creditcardnumber: "信用卡号码",
                    recurringtransaction: "经常性交易",
                    productname: "产品名称",
                    productdescription: "产品描述",
                    producturl: "产品网址",
                    unitpriceamount: "单价",
                    quantity: "数量",
                    memberrewardsnumber: "会员奖励号码",
                    manifest: "显现",
                    reservationnumber: "预约号码",
                    reservationdate: "预订日期",
                    checkindate: "登记日期",
                    checkoutdate: "离开日期",
                    shippingcarrierurl: "承运人网址",
                    trackingnumber: "追踪号码",
                    dateofshipment: "发货日期",
                    dateofdelivery: "送货日期",
                    signatureconfirmation: "签名确认",
                    proofofdelivery: "交货证明",
                    proofofusage: "使用证明",
                    locationofusage: "使用地点",
                    frequencyofusage: "使用频率",
                    dateofinteraction: "互动日期",
                    contactname: "联系人姓名",
                    notes: "笔记",
                    provider: "提供者",
                    providername: "提供者名称",
                    requested_date: "申请日期",
                    received_date: "收到的日期",
                    status_update_date:"状态更新日期",
                    comment: "评论",
                    status_reason: "状态原因",
                    to: "到",
                    type: "类型",
                    date: "日期",
                    keyword: "关键词",
                    field: "字段",
                    total: "全部的",
                    item_no: "编号",
                    tx_items: "TX 物品",
                    per_unit: "每单位",
                    description: "描述",
                    trackstatus: "追踪状态",
                    trackingstatus: "追踪状态",
                    comm_to: "通讯至",
                    comm_status: "通讯状态",
                    tx_action: "发送动作",
                    default_gateway: "默认网关",
                    testmethod: "测试方法",
                    mcc: "MCC",
                    bin: "Bin",
                    caid: "Caid",
                    ruleset: "Ruleset",
                    verifi: "Verifi CDRN",
                    ethoca: "Ethoca",
                    rdr: "RDR",
                    requestedDate: "申请日期",
                    updatedDate: "更新日期",
                    referenceNumber: "参考编号。",
                    expiry_date: "到期日",
                    request: "要求",
                    response: "回复",
                    datetime: "约会时间",
                    matchscore: "匹配分",
                    alertstatus:"警报状态",
                    card_type: "卡的种类",
                    mid: "MID",
                    reasoncode:"原因代码",
                    alerts:"警报",
                    transactionprocessing:"事务处理",
                    classfile:"类文件",
                    apiurl:"接口地址",
                    active:"积极的",
                    autoimport:"自动导入",
                    version:"版本",
                    token:"令牌",
                    issued_at_date:"发行日期",
                    alertprovider: "警报提供者",
                    tier:"等级",
                    alert_provider_name:"提供商名称",
                    email:"电子邮件",
                    response_expiry:"响应到期(小时)",
                    enrollment_date:"注册日期",
                    path:"资料夹路径",
                    url:"URL",
                    username:"用户名",
                    gateway_name:"网关名称",
                    gateway_reference:"网关参考",
                    status_code:"状态码",
                    admin_only:"限管理员",
                    refunded:"已退款",
                    send_data:"发送数据",
                    response_data:"响应数据",
                    post_url:"发送 Url",
                    bank_name:"银行名称",
                    state:"州",
                    country:"国家",
                    retry_date:"重试日期",
                    success:"成功",
                    from_name:"来自名字",
                    from_email_address:"发件人电子邮件地址",
                    theme_name:"主题名称",
                    theme_id: "主题 ID",
                    template_id: "模板 ID",
                    template_code: "模板代码",
                    credit_created_date:"信用创建日期",
                    actioned:"已采取行动",
                    not_actioned:"未采取行动",
                    new:"新的",
                    marked_alert:"标记警报",
                    refund_status:"退款状态",
                    match_score:"匹配分",
                    gateway_tx_status:"交易状态",
                    gateway_tx_id:"网关交易 ID",
                    brand:"品牌",
                    condition_code:"条件代码",
                    network:"网络",
                    dispute_condition:"争议情况",
                    category:"类别",
                    rdr_alerts:"RDR 警报",
                    templateid:"模板 Id",
                    email_subject:"电子邮件主题",
                    send_to:"发给",
                    result:"结果",
                    message_id:"信息 Id",
                    process_file_name:"文件名",
                    process_name:"进程名称",
                    data:"数据",
                    balance: "余额",
                    auth_code: "授权码",
                    caseid: "案例 ID",
                    alert_id: "警报 ID",
                    linked_provider_caseid: "提供商参考",
                    case_event: "案例事件",
                    file_download_date: "文件下载日期",
                    case_received_date: "案件受理日期",
                    payment_description: '付款說明',
                    link_text: '關聯',
                    first_name: '名',
                    last_name: '姓',
                    phone: '電話',
                    address: '地址',
                    city: '城市',
                    payment_status: '付款狀態',
                    show_delete_only: '僅顯示刪除',
                    delete_case_id: '刪除案例',
                    delete_received_date: '刪除收到日期',
                    contact: '接觸',
                    remarks: '評論',
                    verbose: '詳細模式'
                },
                actions: {
                    ok: "好的",
                    view: "查看",
                    date: "日期",
                    items: "项目",
                    reset: "重置",
                    match: "比赛",
                    update: "更新",
                    search: "查找",
                    export: "下载",
                    resolve: "解决",
                    download: "下载",
                    selected: "已选择",
                    clear_all: "全部清除",
                    date_range: "日期范围",
                    export_all: "全部导出",
                    more_details: "更多细节",
                    clear: "清除",
                    cancel: "取消",
                    clear_filters: "清除筛选器",
                    export_fields: "导出字段",
                    export_headers: "导出表头",
                    export_settings: "导出设置",
                    export_selected: "选择导出",
                    view_alert_data: "查看警示数据",
                    action_required: "需要采取行动",
                    wip: "WIP",
                    document_list: "文件清单",
                    document_upload: "文件上载",
                    clear_filters_sorters: "清除筛选和分类",
                    hide_column: "隐藏列",
                    advanced_search: "高级搜索",
                    export_error: "导出错误",
                    edit:"Edit",
                    mark:"Mark"
                },
                filters: "筛选器",
                sorters: "分类器",
                loading: "加载中",
                emptytext: "应用过滤器后，一些数据被隐藏",
                emptytext_email_to_3ds: "电子邮件支持开始使用3DS",
                display: "展示",
                show_total: "{{from}}-{{to}} 的 {{total}} 项目",
            },
            outcomes: {
                ok: "好的",
                wip: "工作正在进行中",
                other: "其他",
                error: "错误",
                cancel: "取消",
                pending: "待定",
                matched: "匹配的",
                declined: "已拒绝",
                resolved: "已解决",
                duplicate: "复制",
                processing: "加工中",
                "e-commerce": "电子商务",
                "manual entry": "手动输入",
                manual_receive: "手动接收",
                merchant_receive: "商户接收",
                cb_proceeded: "进行退款",
                unactioned: "未采取行动",
                shipper_contacted:"联系托运人",
                missed:"错过了",
                previously_credited:"先前已入账",
                previously_cancelled:"先前已取消",
                previously_refunded:"之前已退款",
                unresolved_dispute:"未解决的争议",
                notfound:"未找到",
                account_suspended:"账户被暂停",
                partially_stopped:"部分停止",
                stopped:"已停止",
                previously_received_chargeback:"之前收到的退款",
                account_closed:"帐户已关闭",
                descriptor_does_not_belong_to:"描述符不属于",
                refund_failed:"退款失败", 
                cancelled:"取消", 
            },
            specialset: {
                other: "其他",
                matched: "相符",
                pending: "待定",
                declined: "已拒绝",
                resolved: "已解决",
                processing: "进行中",
                update: "更新",
                "action required": "需要采取行动",
                refunded: "已退款",
                not_refunded: "不退款",
                refund_exists: "存在退款",
                cannot_be_refunded: "无法退款",
                partial_refund_exists: "存在部分退款",
                chargeback_exists: "退款存在",
                marked_alert:"标记警报",
                not_marked:"未标记",
                void_exists: "虚空存在"
            },
            refundstatus: {
                refunded: "已退款",
                not_refunded: "不退款",
                refund_exists: "存在退款",
                void_exists: "虚空存在"
            },
            reason_descriptions: {
                the_case_has_been_resolved_with_the_customer_no_chargeback_is_expected_refund_is_being_processed_or_no_refund_needed: "该案件已与客户解决。 预计不会出现退款情况。 正在处理退款，或无需退款。",
                case_resolved_credit__cancellation_processed: "案例已解决，信用额与取消已处理",
                case_resolved_partial_credit__cancellation_processed: "案例已解决，部分信用额和取消已处理",
                case_resolved_authorization_cancelled: "案例已解决，授权已取消",
                account_cancelledsuspended: "帐户已取消/暂停",
                account_cancelled__suspended: "帐户已取消/暂停",
                cancellation_processed: "取消已处理",
                transaction_previously_credited_for_case_amount_no_balance_remaining: "交易已在以前返还交易金额，没有余额",
                contacted_the_shipper_and_attempting_intercept_of_goods: "联系托运人并试图拦截货物",
                duplicate_request: "重复请求",
                merchant_account_closed_unable_to_process_credit: "商户帐户已关闭，无法处理信用额",
                transaction_previously_received_a_chargeback_no_balance_remaining: "交易以前已收到拒付，没有余额",
                could_not_honour_cancel_request: "无法接受取消请求",
                matched_unable_to_stop_order_fulfilment: "已匹配，无法停止订单履行",
                matched_proceed_with_chargeback_for_resolution: "已匹配，继续进行拒付以解决问题",
                unmatched_case__generalother: "无法匹配的案例-一般/其他",
                unmatched_case__unable_to_locate_original_transaction: "无法匹配的案例-无法找到原始交易",
                request_is_outside_of_eligibility_timeframe: "请求超出有效时间范围",
                transaction_3d_secure_authenticated_successfully_verified_by_visamastercard_secure_code:
                    "Transaction 3D Secure已成功通过身份验证（由Visa / MasterCard安全代码验证）",
                work_in_progress: "工作正在进行中",
                error_details: "错误详情",
                unable_to_process_refund: "无法处理退款",
                card_cancelled_ndash_unable_to_process_refund: "卡已取消 - 无法处理退款",
                error_transaction_declined_transaction_was_not_originally_billable: "错误交易被拒绝。 交易最初不是可计费的",
                unmatched_case__descriptor_does_not_belong_to_merchant: "大小写不匹配 - 描述符不属于商家",
                case_resolved:"Case Resolved",
                transaction_previously_credited_for_case_amount:"先前计入案件金额的交易"
            },
            alert_type: {
                "confirmed fraud": "确认欺诈",
                "customer dispute": "客户纠纷",
                dispute: "争议",
                dispute_notice: "争议通知",
                dispute_rdr: "争议_rdr",
                fraud_notice: "欺诈通知",
            },
            required_field: "这是一个必填字段",
            required_sid_field: "站点为必填字段",
            please_select: "请选择",
            min_1_day:"日期范围无效",
            max_3_months: "日期范围最长应为 3 个月",
            today: "今天",
            last_7_days: "过去 7 天",
            last_30_days: "过去 30 天",
            this_month: "这个月",
        },
    },
};
