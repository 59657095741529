import UserSettings from "../UserSettings";
import Multilingual from "../Multilingual";

const CustomPagination = (pageName) => {
    const [t] = Multilingual();
    const [updateSettings] = UserSettings();
    let initDefaultPageSize = 10;
    let loggedUserSettings = localStorage.getItem("loggedUserSettings") ? JSON.parse(localStorage.getItem("loggedUserSettings")) : null;

    if (loggedUserSettings) {
        if (loggedUserSettings.defaultTablePageSize) {
            if (loggedUserSettings.defaultTablePageSize[pageName]) {
                initDefaultPageSize = loggedUserSettings.defaultTablePageSize[pageName];
            }
        }
    }

    const onChange = (page, pageSize) => {
        let isExist = true;
        if (!loggedUserSettings) {
            loggedUserSettings = {};

            if (!loggedUserSettings.defaultTablePageSize) {
                loggedUserSettings.defaultTablePageSize = {};

                if (loggedUserSettings.defaultTablePageSize[pageName]) {
                    loggedUserSettings.defaultTablePageSize[pageName] = pageSize;
                }
            }
        }

        if (isExist) {
            loggedUserSettings.defaultTablePageSize = { ...loggedUserSettings.defaultTablePageSize, [pageName]: pageSize };
        }

        localStorage.setItem("loggedUserSettings", JSON.stringify(loggedUserSettings));
        updateSettings(loggedUserSettings);
    };

    const customShowTotal = () => {
        return `${t("tables.display")} `;
    };

    return {
        showTotal: () => customShowTotal(),
        position: ["topLeft", "bottomRight"],
        showTitle: true,
        defaultPageSize: initDefaultPageSize,
        showSizeChanger: true,
        pageSizeOptions: ["10", "20", "50", "100"],
        onChange: (page, pageSize) => onChange(page, pageSize),
    };
};

export default CustomPagination;
