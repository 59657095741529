import React, { useState, useEffect } from "react";
import axios from "axios";
import { Select, Checkbox, Space, Divider } from "antd";
import { API_TXALERT2 } from "../../../config/config";
import GroupAccess from "../../../helper/GroupAccess";

const { Option } = Select;
const MERCHANT_DATA_URL = API_TXALERT2 + "/merchant";


const Txalert_MerchantListDropdown = (props) => {

    const [data, setData] = useState([]);
    const [inactiveChecked, setInactiveChecked] = useState(false);


    const fetchdata = async () => {
        try {

            const response = await axios.get(
                MERCHANT_DATA_URL,
                { headers: { Authorization: "Bearer " + localStorage.getItem("txalert_token") } }
            );

            setData(response.data.list);
        } catch (error) {
            return ({ data: false });
        }

    };

    useEffect(() => {
        if (data.length === 0) {
            fetchdata();
        }
    }, [data]);


    const onChangeInactive = (e) => {
        setInactiveChecked(e.target.checked);
    };

    const getMerchant = () => {
       if (inactiveChecked) {
            return data.map((item) => (
                item.active === 1 ?
                    <Option key={item.merchant_id} value={item.merchant_id}>
                        {item.name + ` (merchant_id: ` + item.merchant_id + `)`}
                    </Option>
                    : <Option key={item.merchant_id} value={item.merchant_id} style={{ color: "#f5222d" }}>
                        {item.name + ` (merchant_id: ` + item.merchant_id + `)`}
                    </Option>
            ))
        } else {
            return data.map((item) => (
                item.active === 1
                    ?
                    <Option key={item.merchant_id} value={item.merchant_id}>
                        {item.name + ` (merchant_id: ` + item.merchant_id + `)`}
                    </Option>
                    :
                    null
            ))
        }
    };
    var {showInactiveOption, ...domprops} = props; //remove the showInactiveOption from DOM 
    return (

        <Select
            showSearch
            showArrow="true"
            maxTagCount="responsive"
            optionFilterProp="children"
            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
            filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
            dropdownRender={(menu) => GroupAccess("SYSADMIN") && props.showInactiveOption? (
                <>
                    {menu}
                   
                    <Divider
                        style={{
                            margin: '8px 0',
                        }}
                    />
                    <Space
                        style={{
                            padding: '0 8px 4px',
                        }}
                    >
                        <Checkbox onChange={onChangeInactive}>Show Inactive?</Checkbox>
                    </Space>
                </>
            ) : (
                <>
                    {menu}
                </>
            )}
            {...domprops}
        >    {data ? getMerchant(): null}
        </Select>
    );
};

export default Txalert_MerchantListDropdown;
