import React, { useState } from "react";
import axios from "axios";
import { Modal, Row, Col, Divider, Button, Form, Checkbox, Select, Spin } from "antd"; //removed 'Space' due to unused var
import { ExportOutlined } from "@ant-design/icons";
import CatchError from "./CatchError";

const { Option } = Select;

const ExportModal = (props) => {
    let { t, keyname, title, additional_options, export_option, data_url, data, headers, fieldHeadersOptional, filename, delimiter, style, txalert_token, customToken } = props;

    const [state, setState] = useState({ export_header: false, export_filetype: 'xlsx' });
    const [exportFields, setExportFields] = useState([]);
    const [customOptions, setCustomOptions] = useState([]);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isDownloadModalVisible, setIsDownloadModalVisible] = useState(false);
    const [downloadUrl, setDownloadUrl] = useState(null);

    const [form] = Form.useForm();

    const layout = {
        labelCol: { span: 7 },
        wrapperCol: { span: 17 },
    };

    const showModal = () => {
        formValInit();
        setIsModalVisible(true);
    };

    const handleCancel = () => {
        setCustomOptions([]);
        setExportFields([]);
        setIsModalVisible(false);
    };

    const formValInit = () => {
        if (fieldHeadersOptional && Array.isArray(fieldHeadersOptional)) {
            setExportFields(headers.map((header) => ({ ...header, checked: !fieldHeadersOptional.includes(header.key) })));
        } else {
            setExportFields(headers.map((header) => ({ ...header, checked: true })));
        }

        if (additional_options) {
            setCustomOptions(additional_options.map((option) => ({ ...option, checked: false })));
        }

        setState({ export_header: false, export_filetype: 'xlsx' });
        form.setFieldsValue({ filename, delimiter: delimiter ? delimiter : ",", export_header: false, export_filetype: 'xlsx' });
    };

    const formValChange = (chgV, allV) => {
        setState(allV);
    };

    const onExportFieldChange = (fieldIndex) => {
        setExportFields(exportFields.map((exportField) => (exportField.key === fieldIndex ? { ...exportField, checked: !exportField.checked } : exportField)));
    };

    const onAdditionalOptionChange = (optionIndex) => {

        setCustomOptions(customOptions.map((customOption) => (customOption.key === optionIndex ? { ...customOption, checked: !customOption.checked } : customOption)));
    }

    const onCheckAllChange = () => {
        let all_changed = null;
        if (getIndeterminate()) {
            all_changed = headers.map((header) => ({ ...header, checked: true }));
        } else if (getCheckAll()) {
            all_changed = headers.map((header) => ({ ...header, checked: false }));
        } else {
            all_changed = headers.map((header) => ({ ...header, checked: true }));
        }
        setExportFields(all_changed);
    };

    const getIndeterminate = () => {
        let length = exportFields.filter((exportField) => exportField.checked === true).length;
        return length !== 0 && length !== exportFields.length;
    };

    const getExportOptionsKeyVal = () => {
        let options = {};
        let obj = customOptions.map((option) => ({ ...options, [option.key]: option.checked }));
        return Object.assign({}, ...obj);
    };

    const getCheckAll = () => exportFields.filter((exportField) => exportField.checked === false).length === 0;

    const closeDownloadModal = () => {
        setIsDownloadModalVisible(false);
        setDownloadUrl(null);
        setIsModalVisible(false);
    };

    const onDownload = async () => {
        const date_format = "YYYY-MM-DD";

        let postData = data;
        if (export_option === true) {
            postData = {
                ...postData,
                export_filetype: state.export_filetype,
            }
        }
        getExportOptionsKeyVal();
        postData = {
            ...postData,
            export_header: state.export_header,
            export_fields: exportFields,
            export_options: getExportOptionsKeyVal(),
            date_from: data.startDate ? data.startDate.format(date_format) : null,
            date_to: data.endDate ? data.endDate.format(date_format) : null,
        }

        if (postData.startDate && postData.endDate && postData.page && postData.size) {
            delete postData.startDate;
            delete postData.endDate;
            delete postData.page;
            delete postData.size;
        }
        setIsDownloadModalVisible(true);
        setDownloadUrl(null);
        let headers;
        if(customToken && customToken.length > 0){
            headers = { Authorization: "Bearer " + customToken };
        }else{
            headers = { Authorization: "Bearer " + (txalert_token === true ?  localStorage.getItem("txalert_token") : localStorage.getItem("access_token")) };
        }
        axios({
            url: data_url,
            method: "POST",
            data: postData,
            headers: headers,
        }).then(({ data }) => {
            if (data.count === 0){
                CatchError(" No data to import");
                closeDownloadModal();
            }else{
                const url = data.data;
                setDownloadUrl(url);
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("target", "_blank");
                link.setAttribute("download", "");
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
            }
        }).catch((error) => {
            console.log(error);
        });
    };

    return (
        <>
            <Button key={`btn_${keyname}`} type="link" style={style ? {...style,...{zIndex:4}} : {zIndex:4}} disabled={data.length === 0} onClick={showModal}>
                <div className="d-flex align-items-center">
                    <ExportOutlined style={{ fontSize: "18px" }} /> <div style={{ marginLeft: "4px" }}>{title}</div>
                </div>
            </Button>
            <Modal
                key={`modal_${keyname}`}
                title={t('tables.actions.export_settings')}
                visible={isModalVisible}
                onCancel={handleCancel}
                width={680}
                footer={[
                    <Button key="back" onClick={handleCancel}>
                        {t('tables.actions.cancel')}
                    </Button>,
                    <Button key="export" type="primary" onClick={() => onDownload()}>
                        {title}
                    </Button>,
                ]}
            >
                <Form {...layout} form={form} onValuesChange={formValChange}>
                    {export_option === true ?
                        <Form.Item label="Export As" name="export_filetype" style={{ marginBottom: ".5rem" }}>
                            <Select
                                showArrow="true"
                                maxTagCount="responsive"
                                style={{ width: "20%" }}
                                placeholder={t("tables.fields.field")}>
                                <Option value="xlsx">xlsx</Option>
                                <Option value="csv">csv</Option>
                            </Select>
                        </Form.Item>
                        : null}
                    {additional_options ?
                        additional_options.map((option) => (
                            <Form.Item label={option.label} name={option.key} key={option.key} valuePropName="checked" style={{ marginBottom: ".5rem" }}>
                                <Checkbox
                                    checked={option.checked}
                                    defaultChecked={option.checked}
                                    onChange={() => onAdditionalOptionChange(option.key)} />
                            </Form.Item>
                        ))
                        : null}
                    <Form.Item label={t('tables.actions.export_headers')} name="export_header" valuePropName="checked" style={{ marginBottom: ".5rem" }}>
                        <Checkbox />
                    </Form.Item>
                    <Form.Item label={t('tables.actions.export_fields')} style={{ marginBottom: ".5rem" }}>
                        <Checkbox indeterminate={getIndeterminate()} checked={getCheckAll()} onChange={onCheckAllChange} style={{ marginTop: ".35rem" }}>
                            All
                        </Checkbox>
                        <Divider style={{ marginTop: ".5rem", marginBottom: ".5rem" }} />
                        <Row>
                            {exportFields
                                ? exportFields.map((exportField) => (
                                    <Col span={12} key={exportField.key}>
                                        <Checkbox
                                            checked={exportField.checked}
                                            defaultChecked={exportField.checked}
                                            onChange={() => onExportFieldChange(exportField.key)}
                                        >
                                            {exportField.label}
                                        </Checkbox>
                                    </Col>
                                ))
                                : null}
                        </Row>
                    </Form.Item>
                </Form>
            </Modal>
            <Modal
                key={`download_${keyname}`}
                title="Generating Report"
                visible={isDownloadModalVisible}
                width={680}
                onCancel={closeDownloadModal}
                footer={[
                    <Button key="back" onClick={closeDownloadModal}>
                        {t('tables.actions.cancel')}
                    </Button>
                ]}
            >
                <div id="abcd"></div>
                {!downloadUrl ?
                    <div style={{ width: '100%', padding: '1rem', flexDirection: 'column' }} align="center" className="d-flex justify-content-center">
                        <Spin size="large"></Spin>
                        <div className="d-flex justify-content-center" style={{ marginTop: '1rem' }}>
                            <div>Please wait while we generate your report</div>
                        </div>
                    </div>
                    :
                    <div>
                        <div className="d-flex justify-content-center">
                            If your download does not automatically start, <a href={downloadUrl} target="_blank" rel="noopener noreferrer" style={{ paddingLeft: '.25rem' }}> Click here</a>
                        </div>
                    </div>
                }
            </Modal>
        </>
    );
};

export default ExportModal;
