import React, { useState, useEffect } from "react";
import { CBM_URL } from "../../config/config";
import { Button, Form, Input, Switch } from "antd";
import axios from "axios";
import Swal from "sweetalert2";
import MerchantTokens from "./MerchantTokens";
import PermissionsRequired from "../../helper/PermissionsRequired";
import { PageHeader } from "antd";

const ChargebackMerchant = (props) => {
    const [merchant, setMerchant] = useState(null);
    const [loading, setLoading] = useState(false); // eslint-disable-line
    const [isReady, setIsReady] = useState(false);
    const [componentDisabled, setComponentDisabled] = useState(PermissionsRequired("SYSADMIN")?false:true); // eslint-disable-line
    const { t} = props;
    const mid = props.match.params.id;
   

    useEffect(() => {
        
        if (!isReady) {
            setIsReady(true);
            getMerchantDetail();            
        }
      
    }, []); // eslint-disable-line


    const layout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };

   

   
    const [form] = Form.useForm();

  

    const formValChange = (chgV, allV) => {
        setMerchant({ ...merchant, ...allV });
    };


    const getMerchantDetail = async () => {
        const apiUrl = CBM_URL + `/api/v1/merchant/${mid}/details/`;
        axios
            .get(apiUrl,{ headers: { Authorization: "Bearer " + localStorage.getItem("cbm_token") }})
            .then(({ data }) => {
                
                
                if(data.status === 'ok')
                {
                    
                    let merdata = data.details;
                    setMerchant(merdata);
               // setMerchant({ ...merdata, alerts: merdata.alerts === 1, transactionprocessing: merdata.transactionprocessing === 1, isactive: merdata.alerts === 1 });
                form.setFieldsValue({ ...merdata, alerts: merdata.alerts === 1, transactionprocessing: merdata.transactionprocessing === 1 });
                }else
                {
                    Swal.fire({
                        icon: "error",
                        title: data.msg,
                        showConfirmButton: true,
                    });
                }
            })
            .catch((error) => {
                Swal.fire({
                    icon: "error",
                    title: error.response ? error.response.data.msg : t("login.dialogs.catch"),
                    showConfirmButton: true,
                });
            });
    };
   /*
    const generateJWT = () => {
        const apiUrl = CBM_URL + `/api/v1/merchant/${merchant.mid}/generateJWTToken`;
        axios
            .post(apiUrl, "",{ headers: { Authorization: "Bearer " + localStorage.getItem("cbm_token") }})
            .then(({ data }) => {
                if (data.status === "ok") {
                    setMerchant({ ...merchant, jwt_token: data.token });
                    form.setFieldsValue({ ...merchant, jwt_token: data.jwt_token });
                  
                }
            })
            .catch((error) => {
                Swal.fire({
                    icon: "error",
                    title: error.response ? error.response.data.error_msg.msg : t("login.dialogs.catch"),
                    showConfirmButton: true,
                });
            });
    };
*/
    const submitButtonClickHandler = async () => {
        const apiUrl = CBM_URL + `/api/v1/merchant/${merchant.mid}/edit/`;

        await axios
            .post(apiUrl, merchant,{ headers: { Authorization: "Bearer " + localStorage.getItem("cbm_token") }})
            .then(({ data }) => {
                if (data.status === "ok") {
                    Swal.fire({
                        icon: "success",
                        title: "Merchant Updated",
                        showConfirmButton: false,
                        timer: 2000,
                       
                    });
                   
                } else {
                    Swal.fire({
                        icon: "error",
                        title: data.msg ? data.msg : "",
                        showConfirmButton: true,
                    });
                }
            })
            .catch((error) => {
                setLoading(false);
                Swal.fire({
                    icon: "error",
                    title: error.response ? error.response.data.msg : t("login.dialogs.catch"),
                    showConfirmButton: true,
                });
            });
        setLoading(false);
    };


    return (
        <>           
              <PageHeader className="site-page-header" title={`Chargeback Merchant -${merchant?merchant.name:''}`} ></PageHeader>
           
                <Form {...layout} form={form} name="new_chargeback" onValuesChange={formValChange}  >
                <fieldset disabled={componentDisabled}>
                    <Form.Item name="name" label={t("contents.chargebacks.form.name")} rules={[{ required: true }]} style={{ marginBottom: ".5rem" }} >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        key="alerts"
                        name="alerts"
                        label={t("contents.chargebacks.form.alerts")}
                        valuePropName="checked"
                        style={{ marginBottom: ".5rem" }}
                    >
                        <Switch />
                    </Form.Item>
                    <Form.Item
                        key="transactionprocessing"
                        name="transactionprocessing"
                        label={t("contents.chargebacks.form.transactionprocessing")}
                        valuePropName="checked"
                        style={{ marginBottom: ".5rem" }}
                    >
                        <Switch />
                    </Form.Item>
                    <Form.Item
                        key="isactive"
                        name="isactive"
                        label={t("contents.chargebacks.form.isactive")}
                        valuePropName="checked"
                        style={{ marginBottom: ".5rem" }}
                    >
                        <Switch />
                    </Form.Item>
                    <Form.Item name="notify_url" label={t("contents.chargebacks.form.notify_url")} style={{ marginBottom: ".5rem" }}>
                        <Input />
                    </Form.Item>
                    <Form.Item name="token" label={t("contents.chargebacks.form.token")} style={{ marginBottom: ".5rem" }}>
                        <Input />
                    </Form.Item>
                    {PermissionsRequired("SYSADMIN")?(
                    <Form.Item wrapperCol={{ offset: 8, span: 16 }} key="submitbtn">
                    <Button type="primary" key="btn" htmlType="submit" onClick={() => submitButtonClickHandler()} align="right">
                    {t('contents.chargebacks.form.submit')}
                    </Button>
                    </Form.Item>):''}
                    </fieldset>
                </Form>
                <MerchantTokens merchantid={mid} t={t} key="merchanttoken"/>
        </>
    );
};

export default ChargebackMerchant;
