import React, { useState, useEffect } from "react";
import { Table, PageHeader, Card, Form, Select, Row, Col, Button, Space, Input } from "antd";
import { SearchOutlined, FileSearchOutlined ,PlusOutlined} from "@ant-design/icons";
import CustomPaginationV2 from "../../../helper/Table/CustomPaginationV2";
import { API_TXALERT2 } from "../../../config/config";
import ColumnResizable from "../../../helper/Table/ColumnResizable";
import MergeColumns from "../../../helper/Table/MergeColumns";
import DataFetchHookTxAlert from "../../../helper/DataFetchHookTxAlert";
import TxalertIssuerBin from "./IssuerBin";
import TextSorter from "../../../helper/Table/TextSorter";
import TxalertIssuerBinImport from "./IssuerBinImport";

const DATA_URL = API_TXALERT2 + "/issuer_bin";
const { Option } = Select;

const Txalert_IssuerBinList = (props) => {
    const [{ data, totalResult, isLoading, isError, errorMsg, requestData, isUpdated }, setUrl, setRequestData] = DataFetchHookTxAlert(DATA_URL,"GET", localStorage.getItem("txalert_token")); // eslint-disable-line
    const [formData, setFormData] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    const [id, setId] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const { t } = props;

    const [isUploadOpen, setIsUploadOpen] = useState(false);

    const initFormData = {
        keyword: "",
        field: null,
        //  order: null,
        
    };

    const [form] = Form.useForm();

    useEffect(() => {
      
        formValInit();
      
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const getIssuerbinList = () => {
        
        let searchData ={};
        setRequestData({ ...requestData, ...searchData,  page: 1 });
      
    };

    const showModal = (selectedid) =>{
        setId(selectedid);
        setIsOpen(true);
        
      };
    
      const showUploadModal = (selectedid) =>{
     
        setIsUploadOpen(true);
        
      };

    const formValInit = () => {
        setFormData(initFormData);
        form.setFieldsValue(initFormData);
    };

    const clearAll = () => {
        setCurrentPage(1);
        setFormData(initFormData);
        setRequestData({});
        form.setFieldsValue(initFormData);
    };

    const formValChange = (chgV, allV) => {
        setFormData(allV);
    };

    

    const onFinish = () => {
        let searchData = {};
        if (formData) {
            let searchkeyword = formData.keyword ;
            searchkeyword = searchkeyword? searchkeyword.toString().trim() : null //convert to string, if dropdown
            searchData = {
                [formData.field]: searchkeyword
            };
        }
        
        // Reset Page Index to 1
        setCurrentPage(1);
        setRequestData({ ...requestData, ...searchData, page: 1 });
    };

    const columns = [
        {
            dataIndex: "bin",
            key: "bin",
            title: t("tables.fields.id"),
            width: 50,
            sorter: {
                compare: (a, b) => a.bin - b.bin
            }
        },
        {
            dataIndex: "type",
            key: "type",
            title: t("tables.fields.type"),
            width: 150,
            sorter: {
                compare: (a, b) => TextSorter(a.type,b.type)
            }
        },
        {
            dataIndex: "brand",
            key: "brand",
            title: t("tables.fields.brand"),
            width: 150,
            sorter: {
                compare: (a, b) => TextSorter(a.brand,b.brand)
            }
        }
        ,
        {
            dataIndex: "bank",
            key: "bank",
            title: t("tables.fields.bank_name"),
            width: 150,
            sorter: {
                compare: (a, b) => TextSorter(a.bank,b.bank)
            }
        }
        ,
        {
            dataIndex: "isocountry",
            key: "isocountry",
            title: t("tables.fields.country"),
            width: 150,
            sorter: {
                compare: (a, b) => TextSorter(a.isocountry,b.isocountry)
            }
        },
        {
            dataIndex: "action",
            title: t("tables.fields.action"),
            align: "left",
            width: 100,
            render: (text, record) => (
                <Space size="middle">
                    <Button type="link" onClick={() => showModal(record.bin)} >
                        <FileSearchOutlined style={{ color: "rgba(0, 0, 0, 0.85)", fontSize: "18px" }} />
                    </Button>
                </Space>
            ),
        }
    ];

    const getDataSource = (datalist) => (datalist && Array.isArray(datalist) ? datalist : []);

    const AdvancedSearchForm = () => (
        <div className="search-wrap">
            <Card title={t("tables.actions.search")} size="small">
                <Form form={form} size="large" name="advanced_search" className="ant-advanced-search-form" onValuesChange={formValChange} onFinish={onFinish}>
                    <Row gutter={[16, 16]}>
                    <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 6 }} xl={{ span: 5 }}>
                            <Form.Item name="field">
                                <Select style={{ width: "100%" }} placeholder={t("tables.fields.field")}>
                                    <Option value="bin">{t("tables.fields.bin")}</Option>
                                    <Option value="bank">{t("tables.fields.bank")}</Option>
                                    <Option value="brand">{t("tables.fields.brand")}</Option>
                                    <Option value="country">{t("tables.fields.country")}</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12}} lg={{ span: 6 }} xl={{ span: 8 }}>
                            <Form.Item name="keyword">
                                <Input placeholder={t("tables.fields.keyword")} />
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6, offset: 12 }} lg={{ span: 6 }} xl={{ span: 3, offset: 4 }}>
                            <Form.Item>
                                <Button type="secondary" onClick={() => clearAll()} block>
                                    {t("tables.actions.clear_all")}
                                </Button>
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 4 }}>
                            <Form.Item>
                                <Button type="primary" htmlType="submit" block>
                                    <SearchOutlined /> {t("tables.actions.search")}
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Card>
        </div>
    );

    const getHeaderTable = () => (
        <div className="custom-table--header d-flex justify-content-between align-items-center">
            <h3 className="table-title">{t("contents.txalert_admin.issuerbins")}</h3>
            <Button type="link" onClick={() => showModal()} style={{ marginLeft: "auto" }}>
                <PlusOutlined /> {t("contents.txalert_admin.form.new_issuerbin")}
            </Button>
            <Button type="link" onClick={() => showUploadModal()} style={{ marginLeft: "auto" }}>
                <PlusOutlined /> {t("contents.txalert_admin.import_issuerbin")}
            </Button>
        </div>
    );

    return (
        <div>
            <PageHeader title={t("contents.txalert_admin.issuerbins")}></PageHeader>
            {AdvancedSearchForm()}
            <Table
                size="small"
                loading={isLoading}
                components={{
                    header: {
                        cell: ColumnResizable,
                    },
                }}
                
                columns={MergeColumns(columns)}
                title={() => getHeaderTable()}
                rowKey="bin"
                className="table-v3 custom-pagination custom-pagination-v3"
                dataSource={getDataSource(data.datalist)}
                pagination={CustomPaginationV2({ totalResult, currentPage, requestData, setRequestData, setCurrentPage })}

            />
            <TxalertIssuerBin t={t} id={id} setId={setId} isOpen={isOpen} setIsOpen={setIsOpen} fetchList={getIssuerbinList} />
            <TxalertIssuerBinImport t={t} isUploadOpen={isUploadOpen} setIsUploadOpen={setIsUploadOpen} fetchList={getIssuerbinList} />
        </div>
    );
};


export default Txalert_IssuerBinList;
