import React from "react";
import { Form, Checkbox, Button } from "antd";

const GroupsForm = (props) => {
    const { t, layout, tailLayout, groups, selectedGroups, onCheckboxChange, onSubmit } = props;
    const [form] = Form.useForm();

    return (
        <Form {...layout} form={form} layout="horizontal" size="middle" onFinish={onSubmit} style={{ padding: "15px 0" }}>
            {Object.keys(groups).map((key_group) => (
                <Form.Item label={key_group} key={key_group}>
                    {Object.keys(groups[key_group]).map((key_name) => (
                        <div key={key_name} style={{ paddingTop: "6px" }}>
                            <Checkbox
                                name={`${key_group}|${key_name}`}
                                onChange={(event) => onCheckboxChange(event)}
                                defaultChecked={selectedGroups.find(selected => parseInt(selected) === parseInt(groups[key_group][key_name]))}
                            >
                                {key_name}
                            </Checkbox>
                        </div>
                    ))}
                </Form.Item>
            ))}
            <Form.Item {...tailLayout}>
                <Button type="primary" htmlType="submit">
                    {t("contents.profile.form.submit")}
                </Button>
            </Form.Item>
        </Form>
    );
};

export default GroupsForm;
