const ArrangeAlertResponses = (data) => {
    const sequence = ["resolved", "declined", "wip", "other"];

    const getResponses = (reasons) => {
        let new_array = [];
        if( Array.isArray(reasons) && reasons.length !== 0) {
            sequence.forEach((outcome) => {
                new_array = [...new_array, ...reasons.filter((reason) => reason.outcome.toLowerCase() === outcome)];
            });
        }
        return new_array;
    };

    return getResponses(data);
};

export default ArrangeAlertResponses;
