import React, { useState, useEffect } from "react";
import { Form, Input, Switch, Button,Modal, DatePicker,Select } from "antd";
import { API_TXALERT2 } from "../../../config/config";
import axios from "axios";
import Swal from "sweetalert2";
import GroupAccess from "../../../helper/GroupAccess";
import moment from "moment";
import TxalertThemeDropdown from "../Components/ThemeDropdown";
import CatchError from "../../../helper/CatchError";
const { Option } = Select;
const Txalert_Merchant = (props) => {
    const { t, mid,setMid,isOpen,setIsOpen, fetchMerchantList } = props;
    const [merchantDetails, setMerchantDetails] = useState(null);
    const [loading, setLoading] = useState(false);
    const [componentDisabled, setComponentDisabled] = useState(GroupAccess("SYSADMIN") ? false : true); //eslint-disable-line
    
    
    const layout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };

    const initData = {
        name: null,
        created_date: null,
        alert_api_url: null,
        api_user_name:null,
        api_password:null,
        default_dispute_action:null,
        alert_email:null,
        alert_trigger_email:null,
        max_refund_amount:null,
        eligible_for_refund:null,
        active: null,
        theme_id:null
    };

    const handleOk = () => {
        setLoading(true);
        onFinish();
    };

   

    const [form] = Form.useForm();

    const handleCancel = () => {             
        setLoading(false);
        setMid('');
        form.setFieldsValue(initData);
        setIsOpen(false);
        
    };

    const formValChange = (chgV, allV) => {
       
        setMerchantDetails({ ...allV, theme_id: allV.theme_id?parseInt(allV.theme_id):null,eligible_for_refund: allV.eligible_for_refund?(allV.eligible_for_refund === true?1:0):null,active: allV.active?parseInt(allV.active):null} );
      
    };

    useEffect(() => {
       
        if(isOpen){
            if (typeof mid != 'undefined') {
            
                getMerchantDetails();
            }else{
                setMerchantDetails(initData);
            }
        }
    }, [isOpen]); // eslint-disable-line

   
    const getDateFormat = date => {
        var d = moment(date);
        return date && d.isValid() ? d : '';
      };


    const getMerchantDetails = async () => {

        const api_url = API_TXALERT2 + `/merchant/${mid}`;
        await axios
            .get(api_url, { headers: { Authorization: localStorage.getItem("txalert_token") } })
            .then(({ data }) => {
                let merdata = {
                    merchant_id:data.merchant_id,
                    name:data.name,
                    active:data.active,
                    created_date:data.created_date,
                    alert_api_url:data.alert_api_url,
                    api_user_name:data.api_user_name,
                    api_password:data.api_password,
                    default_dispute_action:data.default_dispute_action,
                    alert_email:data.alert_email,
                    alert_trigger_email:data.alert_trigger_email,
                    theme_id:data.theme_id,
                    max_refund_amount:data.max_refund_amount,
                    eligible_for_refund:data.eligible_for_refund
                };
                setMerchantDetails(merdata);
                form.setFieldsValue({ ...data, eligible_for_refund: data.eligible_for_refund === 1});
            })
            .catch((error) => console.log(error));
    };

    const onFinish = () => {
        let apiUrl = API_TXALERT2 ;
      
       if(mid === 'undefined' || typeof mid === 'undefined' || mid === ''){
        apiUrl = apiUrl + "/merchant";
       }else{
        apiUrl = apiUrl + "/merchant/"+mid;
       }
       
       let postparams = {...merchantDetails,max_refund_amount:merchantDetails.max_refund_amount?parseInt(merchantDetails.max_refund_amount):null};
      
       if( postparams.eligible_for_refund === true || postparams.eligible_for_refund === false ) { 
        postparams.eligible_for_refund = postparams.eligible_for_refund=== true?1:0;
       }
     

       //remove null or undefined items
      Object.keys(postparams).forEach((item) => (postparams[item]=== null) && delete postparams[item]);

         axios
             .post(apiUrl, postparams,{ headers: { Authorization: "Bearer " + localStorage.getItem("txalert_token") }})
             .then(({ data }) => {
                 if (data.status === "OK") {
                     Swal.fire({
                         icon: "success",
                         title: data.msg,
                         showConfirmButton: false,
                         timer: 2000,
                         onClose: () => {
                             fetchMerchantList();
                         },
                     });
                     handleCancel();
                 } else {
                    
                   CatchError(data);
                 }
             })
             .catch((error) => {
                console.log(error.response);
                 setLoading(false);
                CatchError(error);
             });
     };
    const isOkButtonProps = () => !merchantDetails || !merchantDetails.name || loading;
    return(
        <>
            <Modal
                title={mid !== undefined?t("contents.merchants.form.view_merchant")+ ' id:'+mid:t("contents.merchants.form.add_merchant")}
                visible={isOpen}
                width={750}
                onCancel={handleCancel}
                onOk={handleOk}
                bodyStyle={{ paddingTop: '0px' }}
                footer={[
                    <Button key="back" onClick={() => handleCancel()}>
                        {t("contents.merchants.form.cancel")}
                    </Button>,
                     GroupAccess("SYSADMIN") ? 
                    <Button key="submit" type="primary" loading={loading} disabled={isOkButtonProps()} onClick={() => handleOk()}>
                        {loading ? t("contents.merchants.form.submitting") : t("contents.merchants.form.submit")}
                    </Button>:'',
                ]}
            >
                <Form {...layout} form={form} name="merchant_detail" onValuesChange={formValChange}  >
                    <fieldset disabled={componentDisabled}>
                        <Form.Item name="name" label={t("contents.txalert_admin.form.merchant_name")} rules={[{ required: true }]} style={{ marginBottom: ".5rem" }} >
                            <Input />
                        </Form.Item>
                        {(mid !== undefined)?
                        <Form.Item name="created_date" valuePropName="value" label={t("contents.txalert_admin.form.created_date")}  rules={[{ required: true }]} getValueProps={(value) => {
                            return { value: getDateFormat(value) };
                          }} >                          
                        <DatePicker format={"YYYY-MM-DD"} style={{ width: "100%" }} />
                        </Form.Item>:''}
                        <Form.Item name="alert_api_url" label={t("contents.txalert_admin.form.alert_api_url")} style={{ marginBottom: ".5rem" }}>
                            <Input />
                        </Form.Item>
                        <Form.Item name="api_user_name" label={t("contents.txalert_admin.form.api_user_name")} style={{ marginBottom: ".5rem" }}>
                            <Input />
                        </Form.Item>
                        <Form.Item name="api_password" label={t("contents.txalert_admin.form.api_password")} style={{ marginBottom: ".5rem" }}>
                            <Input />
                        </Form.Item>
                        <Form.Item name="default_dispute_action" label={t("contents.txalert_admin.form.default_dispute_action")} style={{ marginBottom: ".5rem" }}>
                                <Select style={{ width: "100%" }} placeholder="ER Action">
                                <Option value=""></Option>                                  
                                    <Option value="Match Only">MATCH ONLY</Option>
                                    <Option value="Match and Refund">MATCH AND REFUND</Option>
                                  
                                </Select>
                        </Form.Item>
                        <Form.Item name="alert_email" label={t("contents.txalert_admin.form.alert_email")} style={{ marginBottom: ".5rem" }}>
                            <Input />
                        </Form.Item>
                        <Form.Item name="alert_trigger_email" label={t("contents.txalert_admin.form.alert_trigger_email")} style={{ marginBottom: ".5rem" }}>
                            <Input />
                        </Form.Item>
                        <Form.Item name="max_refund_amount" label={t("contents.txalert_admin.form.max_refund_amount")} style={{ marginBottom: ".5rem" }}>
                            <Input />
                        </Form.Item>
                        <Form.Item
                            key="refund"
                            name="eligible_for_refund"
                            label={t("contents.txalert_admin.form.eligible_for_refund")}
                            valuePropName="checked"
                            style={{ marginBottom: ".5rem" }}
                        >
                            <Switch />
                        </Form.Item>
                        <Form.Item name="theme_id" label={t("contents.txalert_admin.form.theme_name")} style={{ marginBottom: ".5rem" }}>
                            <TxalertThemeDropdown placeholder="Theme"/>
                        </Form.Item>
                        <Form.Item
                            key="active"
                            name="active"
                            label={t("contents.txalert_admin.form.isactive")}
                            valuePropName="checked"
                            style={{ marginBottom: ".5rem" }}
                        >
                            <Switch />
                        </Form.Item>
                        

                      
                    </fieldset>
                </Form>
            </Modal>
 
        </>
    );
};

export default Txalert_Merchant;
