import React, { useState } from "react";
import { CBM_URL } from "../../config/config";
import { Modal, Button, Form, Input } from "antd";
import axios from "axios";
import Swal from "sweetalert2";
import { PlusOutlined } from "@ant-design/icons";

const DATA_URL = "/api/v1/alertprovider/create/";

const CreateAlertProvider = (props) => {
    const { t, fetchProviders } = props;
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);

    const layout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };

    const [form] = Form.useForm();

    const initData = {
        providername: "",
        classfile: "",
        apiurl: ""
    };

    const handleOk = () => {
        setLoading(true);
        onFinish();
    };

    const showModal = () => {
        form.setFieldsValue(initData);
        setIsModalVisible(true);
    };

    const handleCancel = () => {
        setLoading(false);
        setIsModalVisible(false);
    };

    const formValChange = (chgV, allV) => {
        setData(allV);
    };

    const onFinish = () => {
        const apiUrl = CBM_URL + DATA_URL;

        axios
            .post(apiUrl, data,{ headers: { Authorization: "Bearer " + localStorage.getItem("cbm_token") }})
            .then(({ data }) => {
                if (data.status === "ok") {
                    Swal.fire({
                        icon: "success",
                        title: "Provider Created",
                        showConfirmButton: false,
                        timer: 2000,
                        onClose: () => {
                            fetchProviders();
                        },
                    });
                    handleCancel();
                } else {
                    Swal.fire({
                        icon: "error",
                        title: t("login.dialogs.catch"),
                        text: data.msg ? data.msg : "",
                        showConfirmButton: true,
                    });
                }
            })
            .catch((error) => {
                setLoading(false);
                Swal.fire({
                    icon: "error",
                    title: t("login.dialogs.catch"),
                    text: error.response ? error.response.data.msg : "",
                    showConfirmButton: true,
                });
            });
    };

    const isOkButtonProps = () => !data || !data.providername || loading;

    return (
        <>
            <Button type="link" onClick={showModal} style={{ marginLeft: "auto" }}>
                <PlusOutlined /> {t("contents.chargebacks.new_alertprovider")}
            </Button>
            <Modal
                title={t("contents.chargebacks.create_alertprovider")}
                width={600}
                visible={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={[
                    <Button key="back" onClick={() => handleCancel()}>
                        {t("contents.chargebacks.form.cancel")}
                    </Button>,
                    <Button key="submit" type="primary" loading={loading} disabled={isOkButtonProps()} onClick={() => handleOk()}>
                        {loading ? t("contents.chargebacks.form.submitting") : t("contents.chargebacks.form.submit")}
                    </Button>,
                ]}
            >
                <Form {...layout} form={form} name="new_chargeback" onValuesChange={formValChange}>
                    <Form.Item name="providername" label={t("contents.chargebacks.form.name")} rules={[{ required: true }]} style={{ marginBottom: ".5rem" }}>
                        <Input />
                    </Form.Item>
                    <Form.Item name="classfile" label={t("contents.chargebacks.form.classfile")} rules={[{ required: true }]} style={{ marginBottom: ".5rem" }}>
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="apiurl"
                        label={t("contents.chargebacks.form.apiurl")}
                        rules={[{ type: "url", warningOnly: true }]}
                        style={{ marginBottom: ".5rem" }}
                    >
                        <Input />
                    </Form.Item>
                   
                </Form>
            </Modal>
        </>
    );
};

export default CreateAlertProvider;
