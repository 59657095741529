import React, { useState, useEffect } from "react";
import { Form, Switch, Button,Modal } from "antd";
import { API_TXALERT2 } from "../../../config/config";
import axios from "axios";
import Swal from "sweetalert2";
import GroupAccess from "../../../helper/GroupAccess";
import CatchError from "../../../helper/CatchError";

const Txalert_DescriptorVariation = (props) => {
    const { t, descriptorVariationId,setDescriptorVariationId,isOpen,setIsOpen, descriptorId, setDescriptorId, fetchVariationList } = props; //eslint-disable-line
    const [variationDetails, setVariationDetails] = useState(null);
    const [loading, setLoading] = useState(false);
    const [componentDisabled, setComponentDisabled] = useState(GroupAccess("SYSADMIN") ? false : true); //eslint-disable-line


    const layout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };

    const initData = {
        active: null,
    };

    const handleOk = () => {
        setLoading(true);
        onFinish();
    };



    const [form] = Form.useForm();

    const handleCancel = () => {
        setLoading(false);
        setDescriptorVariationId('');
        form.setFieldsValue(initData);
        setIsOpen(false);

    };

    const formValChange = (chgV, allV) => {
        setVariationDetails({ ...allV, active: allV.active} );
    };

    useEffect(() => {

        if(isOpen){
            if (typeof descriptorVariationId != 'undefined') {

                getVariationDetails();
            }else{
                setVariationDetails(initData);
            }
        }
    }, [isOpen]); // eslint-disable-line

    const getVariationDetails = async () => {

        const api_url = API_TXALERT2 + `/descriptor/${descriptorId}/variations/${descriptorVariationId}`;
        await axios
            .get(api_url, { headers: { Authorization: localStorage.getItem("txalert_token") } })
            .then(({ data }) => {
                let variation_data = {
                    active:data.active
                };
                setVariationDetails(variation_data);
                form.setFieldsValue({ ...data, active: data.active === 1});
            })
            .catch((error) => console.log(error));
    };

    const onFinish = () => {
        let apiUrl = API_TXALERT2 + `/descriptor/${descriptorId}/variations/${descriptorVariationId}`;

        let postparams = {...variationDetails};

        if( postparams.active === true || postparams.active === false ) {
            postparams.active = postparams.active=== true?1:0;
        }

        //remove null or undefined items
        Object.keys(postparams).forEach((item) => (postparams[item]=== null) && delete postparams[item]);

        axios
            .post(apiUrl, postparams,{ headers: { Authorization: "Bearer " + localStorage.getItem("txalert_token") }})
            .then(({ data }) => {
                if (data.status === "OK") {
                    Swal.fire({
                        icon: "success",
                        title: data.msg,
                        showConfirmButton: false,
                        timer: 2000,
                        onClose: () => {
                            fetchVariationList();
                        },
                    });
                    handleCancel();
                } else {

                  CatchError(data);
                }
            })
            .catch((error) => {
                console.log(error.response);
                setLoading(false);
                CatchError(error);
            });
    };
    const isOkButtonProps = () => !variationDetails || loading;
    return(
        <>
            <Modal
                title={t("contents.descriptors.tabs.variations")+ ' id:' + descriptorVariationId}
                visible={isOpen}
                width={750}
                onCancel={handleCancel}
                onOk={handleOk}
                bodyStyle={{ paddingTop: '0px' }}
                footer={[
                    <Button key="back" onClick={() => handleCancel()}>
                        {t("contents.descriptor.form.cancel")}
                    </Button>,
                    GroupAccess("SYSADMIN") ?
                        <Button key="submit" type="primary" loading={loading} disabled={isOkButtonProps()} onClick={() => handleOk()}>
                            {loading ? t("contents.descriptor.form.submitting") : t("contents.descriptor.form.submit")}
                        </Button>:'',
                ]}
            >
                <Form {...layout} form={form} name="variation_detail" onValuesChange={formValChange}  >
                    <fieldset disabled={componentDisabled}>

                        <Form.Item
                            key="active"
                            name="active"
                            label={t("contents.txalert_admin.form.isactive")}
                            valuePropName="checked"
                            style={{ marginBottom: ".5rem" }}
                        >
                            <Switch />
                        </Form.Item>

                    </fieldset>
                </Form>
            </Modal>

        </>
    );
};

export default Txalert_DescriptorVariation;
