import React, { useEffect } from "react";
import { ConfigProvider, Result, Button } from "antd";
import Multilingual from "./../helper/Multilingual";

const PageNotFound = (props) => {
    const [t, locale] = Multilingual();

    useEffect(() => {
        document.title = t(`contents.pagenotfound.title`);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <ConfigProvider locale={locale}>
            <Result
                status="404"
                title="404"
                subTitle={t("contents.pagenotfound.text")}
                extra={
                    <Button type="primary" onClick={() => props.history.push("/")}>
                        {t("contents.pagenotfound.button")}
                    </Button>
                }
            />
        </ConfigProvider>
    );
};
export default PageNotFound;
