import React, { useState, useEffect } from "react";
import { API_TXALERT2 } from "../../../config/config";
import { Button, Tabs, Row, Col, PageHeader, Card } from "antd";
import axios from "axios";
import Swal from "sweetalert2";
import TxalertEnrollmentTbl from "../Components/EnrollmentTbl";
import TxalertEnrollmentHistoryTbl from "../Components/EnrollmentHistoryTbl";
import TxalertDescriptorVariationTbl from "../Components/DescriptorVariationTbl";
import TxalertDescriptorModal from "./Descriptor";
import GroupAccess from "../../../helper/GroupAccess";
import { EditOutlined } from "@ant-design/icons";
import TxalertUpdateEnrollmentModal from "../Admin/UpdateEnrollment";

const Txalert_DescriptorDetail = (props) => {
    const descriptor_id = props.match.params.id;
    const [details, setDetails] = useState(null);
    const [loading, setLoading] = useState(true);
    const { t } = props;
    const [id, setId] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    
    const [enrollUrl, setEnrollUrl] = useState('');
    const [title, setTitle] = useState(''); //todisplay in enroll window
    const [isEnrollOpen, setIsEnrollOpen] = useState(false);
    const [refreshcount, setRefreshCount] = useState(0);



    const showModal = (selectedid) => {
        setId(selectedid);
        setIsOpen(true);

    };

    useEffect(() => {
        if(loading === true){
            getDetails();
            setLoading(false);
        }
    }, [loading]); // eslint-disable-line

    const getDetails = () => {
        const apiUrl = API_TXALERT2 + `/descriptor/${descriptor_id}`;

        axios
            .get(apiUrl, { headers: { Authorization: "Bearer " + localStorage.getItem("txalert_token") } })
            .then(({ data }) => {
                
                setDetails(data);
            })
            .catch((error) => {
                console.log(error.response);
                Swal.fire({
                    icon: "error",
                    title: error.response ? error.response.data.error_msg.msg : t("login.dialogs.catch"),
                    showConfirmButton: true,
                });
            });


    };

   
    const showEnrollModal = (id) =>{
        setEnrollUrl(API_TXALERT2 + '/descriptor/'+id+'/enrollment');
        setTitle('Descriptor:'+details.descriptor_text);
        setIsEnrollOpen(true);
      };

     const updateDetails = () => {
      setLoading(true);
    };


    const showInfo = (labelSpan = 10, valueSpan = 14) => details ? (
        <Card className="details-info--card" loading={loading}>

            <Row gutter={[0, 8]} key="descriptor_id">
                <Col key="label-descriptor_id" span={labelSpan}>
                    <span className="text">
                        <b>{t(`contents.descriptors.form.descriptor_id`)}</b>
                    </span>
                </Col>
                <Col key="value-descriptor_id" span={valueSpan}>
                    <span className="text">{details.descriptor_id}</span>
                </Col>
            </Row>
            <Row gutter={[0, 8]} key="descriptor">
                <Col key="label-descriptor" span={labelSpan}>
                    <span className="text">
                        <b>{t(`contents.descriptor.form.descriptor`)}</b>
                    </span>
                </Col>
                <Col key="value-descriptor" span={valueSpan}>
                    <span className="text">{details.descriptor_text}</span>
                </Col>
            </Row>
            <Row gutter={[0, 8]} key="merchant_name">
                <Col key="label-merchant_name" span={labelSpan}>
                    <span className="text">
                        <b>{t(`contents.descriptor.form.merchant_name`)}</b>
                    </span>
                </Col>
                <Col key="value-merchant_name" span={valueSpan}>
                    <span className="text"><a href={`/merchantdetails/${details.merchant_id}`} target="_blank" rel="noopener noreferrer">{details.merchant_name}</a></span>
                </Col>
            </Row>
            <Row gutter={[0, 8]} key="active">
                <Col key="label-active`" span={labelSpan}>
                    <span className="text">
                        <b>{t(`contents.descriptor.form.active`)}</b>
                    </span>
                </Col>
                <Col key="value-active" span={valueSpan}>
                    <span className="text">{details.status === 1 ? 'Yes' : 'No'}</span>
                </Col>
            </Row>
            <Row gutter={[0, 8]} key="acquiring_bank">
                <Col key="label-acquiring_bank`" span={labelSpan}>
                    <span className="text">
                        <b>{t(`contents.txalert_admin.form.acquiring_bank`)}</b>
                    </span>
                </Col>
                <Col key="value-acquiring_bank" span={valueSpan}>
                    <span className="text">{details.aquiring_bank_text}(Bin:{details.bin} Mid:{details.mid})</span>
                </Col>
            </Row>
            {GroupAccess("SYSADMIN")?
                     <Row>
                <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 4 }} xl={{ span: 4 }}>
                    <Button type="primary" onClick={() => showModal(descriptor_id)} >
                        <EditOutlined /> {t("tables.actions.edit")}
                    </Button>

                    <TxalertDescriptorModal t={t} id={id} setId={setId} isOpen={isOpen} setIsOpen={setIsOpen} fetchList={updateDetails} />

                </Col>
            </Row>:''}
        </Card>
    ) : null;

    const refresh = () => {
        //key value is changed to rerender the componnent
        setRefreshCount(refreshcount+1);
      
    };

    const showEnrollments = () => details ? (
        <div>
              <Button type="primary" onClick={() => showEnrollModal(descriptor_id)} >
                        <EditOutlined /> {t("contents.txalert_admin.form.updateenrollment")}
                    </Button>
                    <TxalertUpdateEnrollmentModal t={t} type="descriptor"  isEnrollOpen={isEnrollOpen} setIsEnrollOpen={setIsEnrollOpen} enrollurl={enrollUrl} title={title} fetchList={refresh} id={descriptor_id} />
                
            <TxalertEnrollmentTbl id={details.descriptor_id} t={t} url={API_TXALERT2 + "/descriptor/"+details.descriptor_id+"/enrollment"} rowkey="descriptor_provider_enrollment_id" key={'enrolltbl'+refreshcount}/>

            <TxalertEnrollmentHistoryTbl id={details.descriptor_id} t={t} url={API_TXALERT2 + "/descriptor/"+details.descriptor_id+"/enrollment_history"} rowkey="descriptor_enrollment_history_id" key={'enrollhistory'+refreshcount} />
        </div>

    ) : null;


    const variationsTbl = () => details ? (
        <div>
            <TxalertDescriptorVariationTbl id={details.descriptor_id} t={t} />
        </div>

    ) : null;


    return (
        <>
            <PageHeader className="site-page-header" title={t("contents.alerts.descriptor")} subTitle={`${details?details.descriptor_text:''}(${descriptor_id})`}></PageHeader>
            <Tabs defaultActiveKey="descriptor_detail">
            <Tabs.TabPane tab={t("contents.descriptor.details")} key='descriptor_detail'>
                {details ? showInfo() : ''}
                </Tabs.TabPane>  
                <Tabs.TabPane tab={t("contents.descriptor.variations")} key='variations'>
                {descriptor_id === undefined ? '' : variationsTbl()}
                </Tabs.TabPane>  
                <Tabs.TabPane tab={ t("contents.descriptors.tabs.enrollment")} key='enrollments'>
                {descriptor_id === undefined ? '' :showEnrollments()}
                </Tabs.TabPane>  
            </Tabs>
        </>
    );
};

export default Txalert_DescriptorDetail;
