import React, { useEffect, useState } from 'react'
import { PageHeader } from 'antd';
import { Table } from 'antd';
import { Button } from 'antd';
import { PlusOutlined, PlusCircleOutlined } from '@ant-design/icons';
import AddPaymentLinkModal from './AddPaymentLinkModal';
import { TXSHIELD_SECURE_BASE_URL, API_DATA_SOURCE, API_SITE, API_TX, DISPLAY_PAYMENT_LINK } from "../../config/config";
import axios from 'axios';
import SortOrder from "../../helper/Table/SortOrder";
import DataFetchHookV3 from "../../helper/DataFetchHookV3";
import CustomPaginationV2 from "../../helper/Table/CustomPaginationV2";
import CustomDateRangeV3 from "../../helper/Table/CustomDateRangeV3";
import { Typography } from 'antd';
import { Badge } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';
import { ArrowRightOutlined } from '@ant-design/icons';
import { CloseCircleOutlined } from '@ant-design/icons';
import { Card } from 'antd';
import { Form } from 'antd';
import { Row } from 'antd';
import { Col } from 'antd';
import moment from "moment";
import { SearchOutlined } from '@ant-design/icons';
import { Select } from 'antd';
import { Input } from 'antd';
import { FileSearchOutlined } from '@ant-design/icons';
import { Space } from 'antd';
import { Link } from 'react-router-dom';

const { Text } = Typography;
const { Option } = Select;

const MAX_DATERANGE = 3;
const DEFAULT_DATE_RANGE = 2;
const DEFAULT_DATE_PERIOD = "days";
const START_DATE = moment().subtract(DEFAULT_DATE_RANGE, DEFAULT_DATE_PERIOD);
const END_DATE = moment();


const DATA_URL = API_TX + '/paymentlink';

const initFormData = {
    dateRange: [START_DATE, END_DATE],
    payment_status: [],
    sites: [],
    txid: "",
    amountoperator: '',
    amountvalue: '',
    currency: [],
    payment_description: '',
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    address: '',
    city: '',
    state: '',
    country: [],
};

const PaymentLinkList = (props) => {
    const { t } = props;

    const dataFetchHookObj = DataFetchHookV3(DATA_URL, "POST", {
        startDate: START_DATE,
        endDate: END_DATE,
        size: 20,
        page: 1,
        order: "DESC"
    });

    const [{ data, totalResult, isLoading, requestData }] = dataFetchHookObj;
    const setRequestData = dataFetchHookObj[2];

    const [currentPage, setCurrentPage] = useState(1);
    const [siteList, setSiteList] = useState(null);
    const [isAddModalVisible, setIsAddModalVisible] = useState(false);
    const [paymentLink, setPaymentLink] = useState(false);
    const [currencyList, setCurrencyList] = useState(null);
    const [countryList, setCountryList] = useState(null);
    const [sortedInfo] = useState({});
    const [formData, setFormData] = useState();
    const [isDateExceeded, setIsDateExceeded] = useState(false);
    const [dates, setDates] = useState([START_DATE, END_DATE]);

    

    const [form] = Form.useForm();

    const getSiteList = async () => {
        const apiUrl = API_SITE + "/list";
        await axios
            .post(apiUrl, {}, { headers: { Authorization: "Bearer " + localStorage.getItem("access_token") } })
            .then(({ data }) => {
                setSiteList(data.list);
            })
            .catch((error) => console.log(error));
    };

    const getCurrencyList = async () => {
        const apiUrl = API_DATA_SOURCE + `/defaultcurrency`;
        await axios
            .post(apiUrl, {}, { headers: { Authorization: "Bearer " + localStorage.getItem("access_token") } })
            .then(({ data }) => {
                setCurrencyList(data);
            })
            .catch((error) => console.log(error));
    };

    const getCountryList = async () => {
        const api_url = API_DATA_SOURCE + `/country`;
        await axios
            .post(api_url, {}, { headers: { Authorization: "Bearer " + localStorage.getItem("access_token") } })
            .then(({ data }) => {
                setCountryList(data);
            })
            .catch((error) => console.log(error));
    };

    const clearAll = () => {
        setCurrentPage(1);
        setFormData(initFormData);
        setRequestData({ ...requestData, ...initFormData, startDate: START_DATE, endDate: END_DATE, page: 1 });
        form.setFieldsValue(initFormData);
    };

    const getDataSource = (datalist) => {
        return datalist && Array.isArray(datalist) ? datalist : []
    };

    useEffect(() => {
        setFormData(initFormData);

        if (!siteList) {
            getSiteList();
        }
        if (!currencyList) {
            getCurrencyList();
        }
        if (!countryList) {
            getCountryList();
        }
    }, [siteList, currencyList, countryList]);


    const formValChange = (chgV, allV) => {
        setFormData(allV);
    };

    const onFinish = () => {
        let startDate = null;
        let endDate = null;
        let searchData = {};


        if (formData) {
            if (dates) {
                startDate = dates[0] ? dates[0] : null;
                endDate = dates[1] ? dates[1] : null;
            }

            searchData = {
                payment_status: formData.payment_status,
                sites: formData.sites,
                txid: formData.txid,
                amountoperator: formData.amountoperator,
                amountvalue: formData.amountvalue,
                currency: formData.currency,
                payment_description: formData.payment_description,
                first_name: formData.first_name,
                last_name: formData.last_name,
                email: formData.email,
                phone: formData.phone,
                address: formData.address,
                city: formData.city,
                state: formData.state,
                country: formData.country,
            }
        }
        setCurrentPage(1);
        setRequestData({ ...requestData, ...searchData, startDate: startDate, endDate: endDate, page: 1 });
    }



    const AdvancedSearchForm = () => (
        <div className="search-wrap">
            <Card title={t("tables.actions.search")} size="small">
                <Form form={form} size="large" name="advanced_search" className="ant-advanced-search-form" onValuesChange={formValChange} onFinish={onFinish}>

                    <Row gutter={[16, 16]}>

                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }} xl={{ span: 6 }}>
                            <Form.Item name="dateRange" rules={[
                                {
                                    validator: () => {
                                        if (isDateExceeded) {
                                            return Promise.reject(t('max_3_months'));
                                        } else if ((dates[0] && !dates[1]) || (!dates[0] && dates[1])) {
                                            return Promise.reject(t('min_1_day'));
                                        } else {
                                            return Promise.resolve();
                                        }
                                    }
                                }
                            ]}>
                                <CustomDateRangeV3 t={t} setIsDateExceeded={setIsDateExceeded} state={{ dates, setDates }} unit="months" limit={MAX_DATERANGE} />
                            </Form.Item>
                        </Col>

                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }} xl={{ span: 5 }}>
                            <Form.Item name="payment_status">
                                <Select mode="multiple" maxTagCount="responsive" allowClear style={{ width: "100%" }} placeholder={t("tables.fields.payment_status")}>
                                    <Option value="COMPLETED">COMPLETED</Option>
                                    <Option value="FAILED">FAILED</Option>
                                    <Option value="EXPIRED">EXPIRED</Option>
                                    <Option value="ATTEMPTED">ATTEMPTED</Option>
                                    <Option value="NEW">NEW</Option>
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }} xl={{ span: 5 }}>
                            <Form.Item name="sites">
                                <Select
                                    mode="multiple"
                                    maxTagCount="responsive"
                                    allowClear
                                    style={{ width: "100%" }}
                                    optionFilterProp="children"
                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                    filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
                                    placeholder={t("contents.sites.title")}
                                >
                                    {siteList
                                        ? siteList.map((item) => (
                                            <Option key={item.sid} value={item.sid}>
                                                {item.name + ` (sid: ` + item.sid + `)`}
                                            </Option>
                                        ))
                                        : null}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col
                            className="gutter-row"
                            xs={{ span: 24 }}
                            sm={{ span: 24 }}
                            md={{ span: 12 }}
                            lg={{ span: 8 }}
                            xl={{ span: 6 }}
                        >
                            <Form.Item name="txid">
                                <Input placeholder={t("tables.fields.txid")} />
                            </Form.Item>
                        </Col>

                        <Col
                            className="gutter-row"
                            xs={{ span: 24 }}
                            sm={{ span: 24 }}
                            md={{ span: 12 }}
                            lg={{ span: 6 }}
                            xl={{ span: 6 }}
                        >
                            <Row>
                                <Col style={{ width: "30%", paddingRight: '5px' }}>
                                    <Form.Item name="amountoperator">
                                        <Select maxTagCount="responsive" >
                                            <Option value="=">{'='}</Option>
                                            <Option value=">">{'>'}</Option>
                                            <Option value="<">{'<'}</Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col style={{ width: "70%" }}>
                                    <Form.Item name="amountvalue">
                                        <Input placeholder={t("tables.fields.amount")} />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>

                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                            <Form.Item name="currency">
                                <Select mode="multiple" maxTagCount="responsive" style={{ width: "100%" }} placeholder={t("tables.fields.currency")}>
                                    {currencyList
                                        ?
                                        Object.keys(currencyList).map((item, index) => (
                                            <Option key={index} value={item}> {item} </Option>
                                        ))
                                        : null
                                    }
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col
                            className="gutter-row"
                            xs={{ span: 24 }}
                            sm={{ span: 24 }}
                            md={{ span: 12 }}
                            lg={{ span: 6 }}
                            xl={{ span: 6 }}
                        >
                            <Form.Item name="payment_description">
                                <Input placeholder={t("tables.fields.payment_description")} />
                            </Form.Item>
                        </Col>

                        <Col
                            className="gutter-row"
                            xs={{ span: 24 }}
                            sm={{ span: 24 }}
                            md={{ span: 12 }}
                            lg={{ span: 6 }}
                            xl={{ span: 6 }}
                        >
                            <Form.Item name="first_name">
                                <Input placeholder={t("tables.fields.first_name")} />
                            </Form.Item>
                        </Col>

                        <Col
                            className="gutter-row"
                            xs={{ span: 24 }}
                            sm={{ span: 24 }}
                            md={{ span: 12 }}
                            lg={{ span: 6 }}
                            xl={{ span: 6 }}
                        >
                            <Form.Item name="last_name">
                                <Input placeholder={t("tables.fields.last_name")} />
                            </Form.Item>
                        </Col>

                        <Col
                            className="gutter-row"
                            xs={{ span: 24 }}
                            sm={{ span: 24 }}
                            md={{ span: 12 }}
                            lg={{ span: 6 }}
                            xl={{ span: 6 }}
                        >
                            <Form.Item name="email">
                                <Input placeholder={t("tables.fields.email")} />
                            </Form.Item>
                        </Col>

                        <Col
                            className="gutter-row"
                            xs={{ span: 24 }}
                            sm={{ span: 24 }}
                            md={{ span: 12 }}
                            lg={{ span: 6 }}
                            xl={{ span: 6 }}
                        >
                            <Form.Item name="phone">
                                <Input placeholder={t("tables.fields.phone")} />
                            </Form.Item>
                        </Col>

                        <Col
                            className="gutter-row"
                            xs={{ span: 24 }}
                            sm={{ span: 24 }}
                            md={{ span: 12 }}
                            lg={{ span: 6 }}
                            xl={{ span: 6 }}
                        >
                            <Form.Item name="address">
                                <Input placeholder={t("tables.fields.address")} />
                            </Form.Item>
                        </Col>

                        <Col
                            className="gutter-row"
                            xs={{ span: 24 }}
                            sm={{ span: 24 }}
                            md={{ span: 12 }}
                            lg={{ span: 6 }}
                            xl={{ span: 6 }}
                        >
                            <Form.Item name="city">
                                <Input placeholder={t("tables.fields.city")} />
                            </Form.Item>
                        </Col>

                        <Col
                            className="gutter-row"
                            xs={{ span: 24 }}
                            sm={{ span: 24 }}
                            md={{ span: 12 }}
                            lg={{ span: 6 }}
                            xl={{ span: 6 }}
                        >
                            <Form.Item name="state">
                                <Input placeholder={t("tables.fields.state")} />
                            </Form.Item>
                        </Col>

                        <Col
                            className="gutter-row"
                            xs={{ span: 24 }}
                            sm={{ span: 24 }}
                            md={{ span: 12 }}
                            lg={{ span: 6 }}
                            xl={{ span: 6 }}
                        >
                            <Form.Item name="country">
                                <Select mode="multiple" maxTagCount="responsive" style={{ width: "100%" }} placeholder={t("tables.fields.country")}>
                                    {countryList
                                        ?
                                        countryList.map((item, index) => (
                                            <Option key={index} value={item.abrv_two}> {item.name} </Option>
                                        ))
                                        : null
                                    }
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col
                            className="gutter-row"
                            xs={{ span: 24 }}
                            sm={{ span: 12 }}
                            md={{ span: 6, offset: 12 }}
                            lg={{ span: 6, offset: 12 }}
                            xl={{ span: 3, offset: 17 }}
                        >
                            <Form.Item>
                                <Button type="secondary" onClick={() => clearAll()} block>
                                    {t("tables.actions.clear_all")}
                                </Button>
                            </Form.Item>
                        </Col>

                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 4 }}>
                            <Form.Item>
                                <Button type="primary" htmlType="submit" block>
                                    <SearchOutlined /> {t("tables.actions.search")}
                                </Button>
                            </Form.Item>
                        </Col>

                    </Row>
                </Form>
            </Card>
        </div>
    )

    const showPaymentLink = (record) => {
        setPaymentLink(record);
        setIsAddModalVisible(true);
    }

    const columns = [
        {
            dataIndex: 'expiry_date',
            key: 'expiry_date',
            title: t('tables.fields.expiry_date'),
            sorter: {
                compare: (a, b) => a.expiry_date.localeCompare(b.expiry_date)
            },
            SortOrder: SortOrder(sortedInfo, 'expiry_date'),
            width: 200,
            ellipsis: true,
            fixed: 'left',
            render: (text, record) => {
                let expiryDate = new Date(record.expiry_date).toLocaleString('default',
                    {
                        month: 'short',
                        day: '2-digit',
                        year: 'numeric'
                    }
                );
                return <Text style={{ color: "#1677ff" }}> {expiryDate} </Text>
            }
        },
        {
            dataIndex: 'link_text',
            key: 'link_text',
            title: t('tables.fields.link_text'),
            sorter: {
                compare: (a, b) => a.link_text.localeCompare(b.link_text)
            },
            SortOrder: SortOrder(sortedInfo, 'link_text'),
            width: 300,
            fixed: 'left',
            render: (text, record) => (
                <a href={TXSHIELD_SECURE_BASE_URL + '/makeapayment.php?link=' + record.link_text} target="_blank" rel='noopener noreferrer' style={{ color: "#1677ff" }}> {TXSHIELD_SECURE_BASE_URL + '/makeapayment.php?link=' + record.link_text}  </a>
            )
            // ellipsis: true,
        },
        {
            dataIndex: 'active',
            key: 'active',
            title: t('tables.fields.active'),
            sorter: {
                compare: (a, b) => String(a.active).localeCompare(String(b.active))
            },
            SortOrder: SortOrder(sortedInfo, 'active'),
            width: 100,
            ellipsis: true,
            render: (text, record) => {
                return (record.active) ? <Badge status="success" text="Active" /> : <Badge status="error" text="Inactive" />
            }
        },
        {
            dataIndex: 'sid',
            key: 'sid',
            title: t('tables.fields.sid'),
            sorter: {
                compare: (a, b) => String(a.sid).localeCompare(String(a.sid))
            },
            SortOrder: SortOrder(sortedInfo, 'sid'),
            width: 100,
            ellipsis: true,
            render: (text, record) => (
                <Text>{record.sid} </Text>
            )
        },
        {
            dataIndex: 'txid',
            key: 'txid',
            title: t('tables.fields.txid'),
            sorter: {
                compare: (a, b) => String(a.txid).localeCompare(String(b.txid))
            },
            SortOrder: SortOrder(sortedInfo, 'txid'),
            width: 200,
            ellipsis: true,
            render: (text, record) => (
                <Link to={"/transactions/" + record.txid} style={{ color: "#1677ff" }} target="_blank" >{record.txid} </Link>
            )
        },
        {
            dataIndex: 'amount',
            key: 'amount',
            title: t('tables.fields.amount'),
            sorter: {
                compare: (a, b) => a.amount.localeCompare(b.amount)
            },
            SortOrder: SortOrder(sortedInfo, 'amount'),
            width: 100,
            ellipsis: true,
            render: (text, record) => (
                <Text>{record.amount} {record.currency} </Text>
            )
        },
        {
            dataIndex: 'payment_description',
            key: 'payment_description',
            title: t('tables.fields.payment_description'),
            sorter: {
                compare: (a, b) => a.payment_description.localeCompare(b.payment_description)
            },
            SortOrder: SortOrder(sortedInfo, 'payment_description'),
            width: 200,
            ellipsis: true,

        },
        {
            dataIndex: 'first_name',
            key: 'first_name',
            title: t('tables.fields.first_name'),
            sorter: {
                compare: (a, b) => a.first_name.localeCompare(b.first_name)
            },
            SortOrder: SortOrder(sortedInfo, 'first_name'),
            width: 200,
            ellipsis: true,
        },
        {
            dataIndex: 'last_name',
            key: 'last_name',
            title: t('tables.fields.last_name'),
            sorter: {
                compare: (a, b) => a.last_name.localeCompare(b.last_name)
            },
            SortOrder: SortOrder(sortedInfo, 'last_name'),
            width: 200,
            ellipsis: true,
        },
        {
            dataIndex: 'email',
            key: 'email',
            title: t('tables.fields.email'),
            sorter: {
                compare: (a, b) => a.email.localeCompare(b.email)
            },
            SortOrder: SortOrder(sortedInfo, 'email'),
            width: 200,
            ellipsis: true,
        },
        {
            dataIndex: 'phone',
            key: 'phone',
            title: t('tables.fields.phone'),
            sorter: {
                compare: (a, b) => a.phone.localeCompare(b.phone)
            },
            SortOrder: SortOrder(sortedInfo, 'phone'),
            width: 200,
            ellipsis: true,
        },
        {
            dataIndex: 'address',
            key: 'address',
            title: t('tables.fields.address'),
            sorter: {
                compare: (a, b) => a.address.localeCompare(b.address)
            },
            SortOrder: SortOrder(sortedInfo, 'address'),
            width: 200,
            ellipsis: true,
        },
        {
            dataIndex: 'city',
            key: 'city',
            title: t('tables.fields.city'),
            sorter: {
                compare: (a, b) => a.city.localeCompare(b.city)
            },
            SortOrder: SortOrder(sortedInfo, 'city'),
            width: 200,
            ellipsis: true,
        },
        {
            dataIndex: 'state',
            key: 'state',
            title: t('tables.fields.state'),
            sorter: {
                compare: (a, b) => a.state.localeCompare(b.state)
            },
            SortOrder: SortOrder(sortedInfo, 'state'),
            width: 200,
            ellipsis: true,
        },
        {
            dataIndex: 'country',
            key: 'country',
            title: t('tables.fields.country'),
            sorter: {
                compare: (a, b) => a.country.localeCompare(b.country)
            },
            SortOrder: SortOrder(sortedInfo, 'country'),
            width: 200,
            ellipsis: true,
            render: (text , record) => {
                if(record.country !== '' && countryList){
                    let result = countryList.filter((country) => {
                        return country.abrv_two === record.country;
                    });
                    return result[0]?.name;
                }
                return record.country;
            }
        },
        {
            dataIndex: 'payment_status',
            key: 'payment_status',
            title: t('tables.fields.payment_status'),
            sorter: {
                compare: (a, b) => a.payment_status.localeCompare(b.payment_status)
            },
            SortOrder: SortOrder(sortedInfo, 'payment_status'),
            width: 150,
            fixed: 'right',
            ellipsis: true,
            render: (text, record) => {
                if (record.payment_status === 'COMPLETED') {
                    return <div className="d-flex align-items-center">
                        <CheckCircleOutlined style={{ color: "#52c41a", fontSize: "18px", marginRight: "4px" }} />
                        <span>{record.payment_status}</span>
                    </div>
                } else if (record.payment_status === 'EXPIRED' || record.payment_status === 'FAILED') {
                    return <div className="d-flex align-items-center">
                        <CloseCircleOutlined style={{ color: "#f5222d", fontSize: "18px", marginRight: "4px" }} />
                        <span>{record.payment_status}</span>
                    </div>
                } else if (record.payment_status === 'ATTEMPTED') {
                    return <div className="d-flex align-items-center">
                        <ArrowRightOutlined style={{ color: "#096dd9", fontSize: "18px", marginRight: "4px" }} />
                        <span>{record.payment_status}</span>
                    </div>
                } else if (record.payment_status === 'NEW') {
                    return <div className="d-flex align-items-center">
                        <PlusCircleOutlined style={{ color: "#096dd9", fontSize: "18px", marginRight: "4px" }} />
                        <span>{record.payment_status}</span>
                    </div>
                }

            }
        },
        {
            dataIndex: 'action',
            key: 'action',
            title: t('tables.fields.action'),
            width: 90,
            ellipsis: true,
            fixed: 'right',
            render: (text, record) => (
                // showBinCaid(record.bcdid)
                <Space size="middle">
                    <Button type="link" onClick={() => showPaymentLink(record)}>
                        <FileSearchOutlined style={{ color: "rgba(0, 0, 0, 0.85)", fontSize: "18px" }} />
                    </Button>
                </Space>
            ),

        },
    ];

    const handleChange = (pagination, filters, sorter, { currentDataSource }) => {
        // setSortedInfo(sorter);
        var orderBy = 'ASC';
        if (sorter.order === 'descend') {
            orderBy = 'DESC';
        }
        var sortField = 'timestamp';
        if (sorter.field !== undefined) {
            sortField = sorter.field;
        }
        var p = pagination.current;
        if (Object.keys(sorter).length > 0 && requestData.sort_field !== sortField) {
            p = 1;
            setCurrentPage(1);
        }

        setRequestData({ ...requestData, order: orderBy, sort_field: sortField, page: p, size: pagination.pageSize });
        // setIsSortUpdate(true);
    };

    const getHeaderTable = () => (
        <div className="custom-table--header d-flex justify-content-between align-items-center">
            <h3 className="table-title">{t("contents.paymentlink.title")}</h3>

            {/* {GroupAccess("SYSADMIN") ? ( */ } {/* Code commented due to removing exception for the create payment link */}
                <Button type="primary" onClick={() => { setIsAddModalVisible(true); setPaymentLink(false) }} style={{ zIndex: 99 }}>
                    <PlusOutlined /> {t("contents.paymentlink.new_paymentlink")}
                </Button>
            {/* ) : null
            } */}

        </div >
    )
    return (
        <>
            {DISPLAY_PAYMENT_LINK && data.datalist && Array.isArray(data.datalist) ? (
                <>
                    <PageHeader title={t("contents.paymentlink.title")}></PageHeader>
                    {AdvancedSearchForm()}
                    <AddPaymentLinkModal
                        t={t}
                        isAddPaymentLinkModalVisible={isAddModalVisible}
                        setIsAddPaymentLinkModalVisible={setIsAddModalVisible}
                        siteList={siteList}
                        currencyList={currencyList}
                        countryList={countryList}
                        reloadTable={clearAll}
                        paymentLink={paymentLink}
                    />
                    <Table
                        rowKey="payment_id"
                        scroll={{ x: '100%' }}
                        columns={columns}
                        className="tableOverflow table-v3 custom-pagination custom-pagination-v3"
                        size='small'
                        title={() => getHeaderTable()}
                        loading={isLoading}
                        onChange={handleChange}
                        sortDirections={["ascend", "descend", "ascend"]}
                        dataSource={data.datalist && Array.isArray(data.datalist) ? getDataSource(data.datalist) : []}
                        pagination={CustomPaginationV2({ totalResult, currentPage, requestData, setRequestData, setCurrentPage })}
                    />
                </>
            ) : <> <PageHeader title={t("contents.paymentlink.title_not_found")}></PageHeader> </>}


        </>
    )
}

export default PaymentLinkList