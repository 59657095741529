import { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";

import DefaultPageSize from "./Table/DefaultPageSize";

const DataFetchHookTxAlert = (
    initialurl,
    requestMethod = "POST",
    accessToken = null,
    defaultRequestData = { page: 1, size: DefaultPageSize(), order: "DESC" },
    firstTimeAutoLoad = true,
    maxRange = 3,
    rangeType = "months") => {
    const [data, setData] = useState({ datalist: [] });
    const [totalResult, setTotalResult] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState(null);
    const [isError, setIsError] = useState(false);
    const [url, setUrl] = useState(initialurl);
    const [requestData, setRequestData] = useState(defaultRequestData);
    const [method, setMethod] = useState(requestMethod);
    const [token, setToken] = useState(accessToken);
    const [maxDateRange] = useState(maxRange); // eslint-disable-line
    const [dateRangeType] = useState(rangeType);  // eslint-disable-line  
    const [isUpdated, setIsUpdated] = useState(false);
    const [isFirstTimeAutoLoad, setIsFirstTimeAutoLoad] = useState(firstTimeAutoLoad);


    const DATE_FORMAT = "YYYY-MM-DD"; // eslint-disable-line
    const defSize = DefaultPageSize();
    /*const getStartDate = () =>
        requestData && requestData.startDate ? requestData.startDate.format(DATE_FORMAT) : moment().subtract(maxDateRange, dateRangeType).format(DATE_FORMAT);

    const getEndDate = () => (requestData && requestData.endDate ? requestData.endDate.format(DATE_FORMAT) : moment().format(DATE_FORMAT));
*/
    const getPage = () => (requestData && requestData.page ? requestData.page : 1);
    const getSize = () => (requestData && requestData.size ? requestData.size :defSize);
    const getOrder = () => (requestData && requestData.order === "ASC" ? requestData.order : "DESC");
        
  

    useEffect(() => {
        
        const fetchdata = async () => {
            setIsLoading(true);
            setIsError(false);
            try {
                let result;
                let options = null;
                let param = null;
                if (token) {
                    options = { headers: { Authorization:  token } };
                }
              //  if(!requestData){
                  
                     param = { ...requestData,  page: getPage(), size: getSize(), order: getOrder() };
              //      setRequestData(param);
                    if (param.startDate && param.endDate) {
                        delete param.startDate;
                        delete param.endDate;
                    }
              //  }
              
                if (method === "POST") {

                    result = await axios
                        .post(url, param, options)
                        .then((result) => result)
                        .catch((error) => {                             
                            setData([]);
                            setErrorMsg(error.response.data.error_msg);
                            setIsError(true);
                        });
                } else {
                   
                   if(param){
                        options = {...options,params:{...param}} ;
                    }
                //    let data = ' {"field": "name","keyword": "test"}\r\n';
                    let config = {
                        method: 'get',
                        maxBodyLength: Infinity,
                        url: url,
                        headers: options.headers,
                        params : options.params
                      };
                     
                      
                      result = await axios
                        .request(config)
                        .then((result) => result)
                        .catch((error) => {                             
                            setData([]);
                            console.log(error);
                          //  setErrorMsg(error.response.data.error_msg);
                            setIsError(true);
                        });
                }
                
                if (result) {
                    if (result.data.list) {
                        setData({ datalist: result.data.list });
                    }
                    if (result.data.totalResult >= 0) {
                        setTotalResult(result.data.totalResult);
                        setIsUpdated(moment().unix());
                    }
                    if (result.data.msg) {
                        setErrorMsg(result.data.msg);
                        setIsError(true);
                    }
                } 
            } catch (err) {
                console.log(err);
                setData([]);
                setErrorMsg(err);
                setIsError(true);
            }
            setIsLoading(false);
        };
        if (isFirstTimeAutoLoad) {
            fetchdata();
        } else {
            setIsFirstTimeAutoLoad(true);
        }
    }, [url, requestData]); // eslint-disable-line react-hooks/exhaustive-deps

    return [{ data, totalResult, requestData, isLoading, isError, errorMsg, isUpdated  }, setUrl, setRequestData, setMethod, setToken];
};
export default DataFetchHookTxAlert;
