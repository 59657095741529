import React, { useState, useEffect } from "react";
import axios from "axios";
import { Select } from "antd";
import { API_TXALERT2 } from "../../../config/config";

const { Option } = Select;


// can accept alert_provider_id as a prop value to get the alert types of a provider

const Txalert_AlertTypeDropdown = (props) => {

    const [data, setData] = useState([]);
    
    const fetchdata = async () => {
        let  apiurl = API_TXALERT2 + "/alert/types";
        try {
            if(props.alert_provider_id)
            {
                apiurl = apiurl + "/alert_provider_id/"+props.alert_provider_id;
            }

            const response = await axios.get(
                apiurl,
                { headers: { Authorization: "Bearer " + localStorage.getItem("txalert_token") } }
            );
            setData(response.data.list);
        } catch (error) {
            return ({ data: false });
        }

    };

    useEffect(() => {
      
        if (data.length === 0) {
            fetchdata();
        }
    }, [props.alert_provider_id]); // eslint-disable-line react-hooks/exhaustive-deps



    const getProvider = () => {

        return data.map((item) => (
            <Option key={item.alert_type_id} value={item.alert_type_id} label={item.alert_type}>
                {item.alert_type }
            </Option>
        ))

    };

    return (

        <Select
            showSearch            
            showArrow="true"
            maxTagCount="responsive"
            optionFilterProp="children"
            key="alert_type_select"
            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
            filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
            {...props}
        >    {data
            ? getProvider()
            : null}</Select>
    );
};

export default Txalert_AlertTypeDropdown;