const MonoText = (text, space_replacement = "_", special_replacement = "") => {
    const symbols = [
        "!",
        "@",
        "#",
        "$",
        "&",
        "*",
        "^",
        "(",
        ")",
        "-",
        "+",
        "=",
        "{",
        "}",
        "[",
        "]",
        ":",
        ";",
        '"',
        "'",
        ",",
        ".",
        "<",
        ">",
        "/",
        "?",
        "|",
        "`",
        "~",
    ];

    if (typeof text !== "string") {
        return false;
    }
    text = text.trim();

    try {
        text = text.toLowerCase();
    } catch (error) {
        return false;
    }

    if (text.length === 0) {
        return false;
    }

    let str = "";
    for (let index = 0; index < text.length; index++) {
        const char = text[index];
        if (symbols.includes(char)) {
            str += special_replacement;
        } else if (char === " ") {
            str += space_replacement;
        } else {
            str += char;
        }
    }
    return str;
};
export default MonoText;
