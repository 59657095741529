import React, {useState,  useEffect }  from "react";
import { Modal, Button, List, Avatar, Card } from "antd";
import moment from "moment";
import axios from "axios";
import { CBM_URL } from "../../config/config";
import { DownloadOutlined } from "@ant-design/icons";

const DocumentList = (props) => {
    const { t, upload, documents, isModalVisible, setIsModalVisible, isUseModal,merchant } = props; // eslint-disable-line
    const [documentlist, setDocumentlist] = useState(documents);
    useEffect(() => {
        
        if(isModalVisible === true){
           
            getDocuments(upload.mid,upload.pid,upload.chargebackid);
           
        }
    }, [isModalVisible]); // eslint-disable-line

     
    const getDocuments = async (mid, pid, chargebackid) => {
        const apiUrl = `/api/v1/chargebacks/${chargebackid}/documents`;

        axios
            .get(CBM_URL + apiUrl, { headers: { Authorization: "Bearer " +  localStorage.getItem("cbm_token") } })
            .then(({ data }) => {
               
                if(data.list === false)
                {
                    setDocumentlist([]);
                }else{
                    setDocumentlist(data.list);
                }
            })
            .catch((error) => error);
    };
    const handleOk = () => {
      
        setDocumentlist([]);
        setIsModalVisible(false);
    };

    const getIconFile = (type) => {
        switch (type) {
            case "pdf":
                return require(`../../assets/images/files/pdf.svg`);
            case "doc":
                return require(`../../assets/images/files/doc.svg`);
            case "docx":
                return require(`../../assets/images/files/doc.svg`);
            case "png":
                return require(`../../assets/images/files/png.svg`);
            case "jpg":
                return require(`../../assets/images/files/jpg.svg`);
            case "jpeg":
                return require(`../../assets/images/files/jpg.svg`);
            default:
                return require(`../../assets/images/files/unknown.svg`);
        }
    };

    const getMimeType = (type) => {
        switch (type) {
            case "pdf":
                return "application/pdf";
            case "doc":
                return "application/msword";
            case "docx":
                return "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
            case "jpg":
                return "image/jpg";
            case "jpeg":
                return "image/jpeg";
            case "png":
                return "image/png";
            default:
                return false;
        }
    };

    const downloadFile = (item) => {
        const MimeType = getMimeType(item.filetype);
        if (!MimeType) {
            console.log("MIME type not found.");
            return false;
        }
        const linkSource = `data:${MimeType};base64,${item.document}`;
        const downloadLink = document.createElement("a");
        const fileName = `${item.filename}.${item.filetype}`;

        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
    };

    const getList = () => (
       
    <List
            size="small"
            itemLayout="horizontal"
            dataSource={documentlist}
            header={
                <div className="d-flex justify-content-between" style={{ padding: "0 16px" }}>
                    <div>
                        <strong>
                            {t("tables.fields.files")} ({documentlist.length})
                        </strong>
                    </div>
                    <div>
                        <strong>{t("tables.actions.download")}</strong>
                    </div>
                </div>
            }
            renderItem={(item) => (
                <List.Item>
                    <List.Item.Meta
                        avatar={<Avatar shape="square" src={getIconFile(item.filetype)} />}
                        title={`${item.filename}.${item.filetype}`}
                        description={<small>{moment(item.upload_date).format("DD/MM/YYYY HH:mm:ss")}</small>}
                    />
                    <Button shape="round" onClick={() => downloadFile(item)}>
                        <DownloadOutlined />
                    </Button>
                </List.Item>
            )}
        />
    );

    return isUseModal ? (
        <Modal
            title={`${t("tables.actions.document_list")} #${upload.merchant_transaction_id}`}
            width={600}
            visible={isModalVisible}
            onOk={handleOk}
            onCancel={handleOk}
            footer={[
                <Button key="submit" type="primary" onClick={() => handleOk()}>
                    {t("tables.actions.ok")}
                </Button>,
            ]}
            bodyStyle={{ padding: "0" }}
        >
            <div style={{ overflowY: "scroll", height: "324px", paddingRight: "8px" }}>{getList()}</div>
        </Modal>
    ) : (
        <Card size="small">{getList()}</Card>
    );
};

export default DocumentList;
