import React, { useState, useEffect } from "react";
import { Table, PageHeader, Card, Form, Select, Row, Col, Button, Space, Input } from "antd";
import { SearchOutlined, FileSearchOutlined ,PlusOutlined} from "@ant-design/icons";
import moment from "moment";
import CustomPaginationV2 from "../../../helper/Table/CustomPaginationV2";
import { API_TXALERT2 } from "../../../config/config";
import ColumnResizable from "../../../helper/Table/ColumnResizable";
import MergeColumns from "../../../helper/Table/MergeColumns";
import DataFetchHookTxAlert from "../../../helper/DataFetchHookTxAlert";
import TxalertMerchant from "./Merchant";
import TextSorter from "../../../helper/Table/TextSorter";
import ExportModalV3 from "../../../helper/ExportModalV3";

const DATA_URL = API_TXALERT2 + "/merchant";
const DATA_URL_EXPORT = API_TXALERT2 + "/merchant/reports";

const { Option } = Select;

const Txalert_Merchants = (props) => {
    const [{ data, totalResult, isLoading, isError, errorMsg, requestData, isUpdated }, setUrl, setRequestData] = DataFetchHookTxAlert(DATA_URL,"GET", localStorage.getItem("txalert_token")); // eslint-disable-line
    const [formData, setFormData] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    const [mid, setMid] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const { t } = props;

    const initFormData = {
        keyword: "",
        field: undefined,
        //  order: null,
        
    };

    const [form] = Form.useForm();

    useEffect(() => {
       
        formValInit();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const getMerchantList = () => {
        
        let searchData ={};
        setRequestData({ ...requestData, ...searchData,  page: 1 });
      
    };

    const showModal = (id) =>{
        setMid(id);
        setIsOpen(true);
        
      };
    

    const formValInit = () => {
        setFormData(initFormData);
        form.setFieldsValue(initFormData);

       
    };

    const clearAll = () => {
        setCurrentPage(1);
        setFormData(initFormData);
        setRequestData({});
        form.setFieldsValue(initFormData);
    };

    const formValChange = (chgV, allV) => {
        setFormData(allV);
    };

    

    const onFinish = () => {
        let searchData = {};
        if (formData) {
            let searchkeyword = formData.keyword ;
            searchkeyword = searchkeyword? searchkeyword.toString().trim() : null //convert to string, if dropdown
            searchData = {
                [formData.field]: searchkeyword
            };
        }
        
        // Reset Page Index to 1
        setCurrentPage(1);
        setRequestData(searchData);
    };

    const columns = [
        {
            dataIndex: "merchant_id",
            key: "merchant_id",
            title: t("tables.fields.id"),
            width: 50,
            render: (id, record) => (
                <a href={`/merchantdetails/${id}`} target="_blank" rel="noopener noreferrer">
                    {id}{" "}
                </a>
              
            ),
            sorter: {
                compare: (a, b) => a.merchant_id - b.merchant_id,
              
            },
        },
        {
            dataIndex: "name",
            key: "name",
            title: t("tables.fields.name"),
            width: 150,
            sorter: {
                compare: (a, b) => TextSorter(a.name,b.name)               
            }
        },
        {
            dataIndex: "default_dispute_action",
            key: "default_dispute_action",
            title: t("contents.merchants.form.default_dispute_action"),
            width: 150,
            sorter: {
                compare: (a, b) => TextSorter(a.default_dispute_action,b.default_dispute_action)               
            }
        },
        {
            dataIndex: "active",
            key: "active",
            align: "left",
            title: t("tables.fields.active"),
            width: 150,
            render: (active) => (active === 1)?"Yes":"No",
            sorter: {
                compare: (a, b) => a.active - b.active}
        },
        {
            dataIndex: "action",
            title: t("tables.fields.action"),
            align: "left",
            width: 100,
            render: (text, record) => (
                <Space size="middle">
                    <Button type="link" onClick={() => showModal(record.merchant_id)} >
                        <FileSearchOutlined style={{ color: "rgba(0, 0, 0, 0.85)", fontSize: "18px" }} />
                    </Button>
                </Space>
            ),
        }
    ];

    const getDataSource = (datalist) => (datalist && Array.isArray(datalist) ? datalist : []);

    const AdvancedSearchForm = () => (
        <div className="search-wrap">
            <Card title={t("tables.actions.search")} size="small">
                <Form form={form} size="large" name="advanced_search" className="ant-advanced-search-form" onValuesChange={formValChange} onFinish={onFinish}>
                    <Row gutter={[16, 16]}>
                    <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 6 }} xl={{ span: 5 }}>
                            <Form.Item name="field">
                                <Select style={{ width: "100%" }} placeholder={t("tables.fields.field")}>
                                    <Option value="name">{t("tables.fields.name")}</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12}} lg={{ span: 6 }} xl={{ span: 8 }}>
                            <Form.Item name="keyword">
                                <Input placeholder={t("tables.fields.keyword")} />
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6, offset: 12 }} lg={{ span: 6 }} xl={{ span: 3, offset: 4 }}>
                            <Form.Item>
                                <Button type="secondary" onClick={() => clearAll()} block>
                                    {t("tables.actions.clear_all")}
                                </Button>
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 4 }}>
                            <Form.Item>
                                <Button type="primary" htmlType="submit" block>
                                    <SearchOutlined /> {t("tables.actions.search")}
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Card>
        </div>
    );

    const getHeaderTable = () => (
        <div className="custom-table--header d-flex justify-content-between align-items-center">
            <h3 className="table-title">{t("contents.merchants.title")}</h3>
            <Button type="link" onClick={() => showModal()} style={{ marginLeft: "auto" }}>
                <PlusOutlined /> {t("contents.merchants.form.new_merchant")}
            </Button>
            <ExportModalV3
                t={t}
                keyname="export_all"
                title={t("tables.actions.export")}
                data={requestData}
                data_url={DATA_URL_EXPORT}
                headers={exportHeaders}
                fieldHeadersOptional={exportHeadersOptional}
                txalert_token = {true}
                filename={`merchant_${moment().format("YYMMDD_HHmmss_SSSS")}.csv`}
                style={{ marginLeft: 8 }}
            />
        </div>
    );

    const exportHeadersOptional = [];
    const exportHeaders = [
        { label: "ID", key: "m.merchant_id" },
        { label: "Merchant Name", key: "name" },
        { label: "Default Dispute Action", key: "default_dispute_action" },
        { label: "Status", key: "status" }
    ];

    return (
        <div>
            <PageHeader title={t("contents.merchants.title")}></PageHeader>
            {AdvancedSearchForm()}
            <Table
                size="small"
                loading={isLoading}
                components={{
                    header: {
                        cell: ColumnResizable,
                    },
                }}
                
                columns={MergeColumns(columns)}
                title={() => getHeaderTable()}
                rowKey="merchant_id"
                className="table-v3 custom-pagination custom-pagination-v3"
                dataSource={getDataSource(data.datalist)}
              pagination={CustomPaginationV2({ totalResult, currentPage, requestData, setRequestData, setCurrentPage })}

            />
            <TxalertMerchant t={t} mid={mid} setMid={setMid} isOpen={isOpen} setIsOpen={setIsOpen} fetchMerchantList={getMerchantList} />
        </div>
    );
};

export default Txalert_Merchants;
