import React, { useState, useEffect } from "react";
import { Form, Input, Button,Modal } from "antd";
import { API_TXALERT2 } from "../../../config/config";
import axios from "axios";
import Swal from "sweetalert2";
import GroupAccess from "../../../helper/GroupAccess";
import CatchError from "../../../helper/CatchError";

const Txalert_IssuerBin = (props) => {
    const { t, id,setId,isOpen,setIsOpen, fetchList } = props;
    const [details, setDetails] = useState(null);
    const [loading, setLoading] = useState(false);
    const [componentDisabled, setComponentDisabled] = useState(GroupAccess("SYSADMIN") ? false : true); //eslint-disable-line
    
   
    const layout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };

    const initData = {
        bin: "",
        brand: "",
        bank: "",
        type:"",
        level: "",
        isocountry: "",
        isoa2: "",
        isoa3:"",
        isonumber: "",
        www: "",
        phone: ""
    };

    const handleOk = () => {
        setLoading(true);
        onFinish();
    };

   

    const [form] = Form.useForm();

    const handleCancel = () => {             
        setLoading(false);
        setId('');
        form.setFieldsValue(initData);
        setIsOpen(false);
        
    };

    const formValChange = (chgV, allV) => {
               setDetails({ ...allV, active: chgV.active=== true?1:0, admin_only:chgV.admin_only === true?1:0});
    };

    useEffect(() => {
     
        if(isOpen){
            if (typeof id != 'undefined') {
            
                getDetails();
            }else{
                setDetails(initData);
            }
        }
    }, [isOpen]); // eslint-disable-line

   
    const getDetails = async () => {

        const api_url = API_TXALERT2 + `/issuer_bin/${id}`;
        await axios
            .get(api_url, { headers: { Authorization: localStorage.getItem("txalert_token") } })
            .then(({ data }) => {
                let recdata = data;
                setDetails(recdata);
                form.setFieldsValue(recdata);
            })
            .catch((error) => console.log(error));
    };

    const onFinish = () => {
        let apiUrl = API_TXALERT2 ;
      
       if(id === 'undefined' || typeof id === 'undefined' || id === ''){
        apiUrl = apiUrl + "/issuer_bin";
       }else{
        apiUrl = apiUrl + "/issuer_bin/"+id;
       }
         axios
             .post(apiUrl, details,{ headers: { Authorization: "Bearer " + localStorage.getItem("txalert_token") }})
             .then(({ data }) => {
                 if (data.status === "OK") {
                     Swal.fire({
                         icon: "success",
                         title: data.msg,
                         showConfirmButton: false,
                         timer: 2000,
                         onClose: () => {
                             fetchList();
                         },
                     });
                     handleCancel();
                 } else {
                    
                    CatchError(data);
                 }
             })
             .catch((error) => {
                console.log(error.response);
                 setLoading(false);
                CatchError(error);
             });
     };
    const isOkButtonProps = () => !details || !details.bin  || !details.bank|| loading;
    return(
        <>
            <Modal
                title={id !== undefined?t("contents.txalert_admin.form.view_issuerbin")+ ' id:'+id:t("contents.txalert_admin.form.add_issuerbin")}
                visible={isOpen}
                width={600}
                onCancel={handleCancel}
                onOk={handleOk}
                bodyStyle={{ paddingTop: '0px' }}
                footer={[
                    <Button key="back" onClick={() => handleCancel()}>
                        {t("contents.txalert_admin.form.cancel")}
                    </Button>,
                     GroupAccess("SYSADMIN") ? 
                    <Button key="submit" type="primary" loading={loading} disabled={isOkButtonProps()} onClick={() => handleOk()}>
                        {loading ? t("contents.txalert_admin.form.submitting") : t("contents.txalert_admin.form.submit")}
                    </Button>:'',
                ]}
            >
                <Form {...layout} form={form} name="merchant_detail" onValuesChange={formValChange}  >
                    <fieldset disabled={componentDisabled}>
                        <Form.Item name="bin" label={t("contents.txalert_admin.form.bin")} rules={[{ required: true }]} style={{ marginBottom: ".5rem" }} >
                            <Input />
                        </Form.Item>
                        <Form.Item name="brand" label={t("contents.txalert_admin.form.brand")} style={{ marginBottom: ".5rem" }} >
                            <Input />
                        </Form.Item>
                        <Form.Item name="bank" label={t("contents.txalert_admin.form.bank_name")} rules={[{ required: true }]} style={{ marginBottom: ".5rem" }} >
                            <Input />
                        </Form.Item>     
                        <Form.Item name="type" label={t("contents.txalert_admin.form.type")} style={{ marginBottom: ".5rem" }} >
                            <Input />
                        </Form.Item>
                        <Form.Item name="level" label={t("contents.txalert_admin.form.level")} style={{ marginBottom: ".5rem" }} >
                            <Input />
                        </Form.Item>
                        <Form.Item name="isocountry" label={t("contents.txalert_admin.form.country")}style={{ marginBottom: ".5rem" }} >
                            <Input />
                        </Form.Item>
                        <Form.Item name="isoa2" label={t("contents.txalert_admin.form.isoa2")} style={{ marginBottom: ".5rem" }} >
                            <Input />
                        </Form.Item>
                        <Form.Item name="isoa3" label={t("contents.txalert_admin.form.isoa3")}  style={{ marginBottom: ".5rem" }} >
                            <Input />
                        </Form.Item>
                        <Form.Item name="isonumber" label={t("contents.txalert_admin.form.isonumber")}  style={{ marginBottom: ".5rem" }} >
                            <Input />
                        </Form.Item>
                        <Form.Item name="www" label={t("contents.txalert_admin.form.www")}  style={{ marginBottom: ".5rem" }} >
                            <Input />
                        </Form.Item>
                        <Form.Item name="phone" label={t("contents.txalert_admin.form.phone")}  style={{ marginBottom: ".5rem" }} >
                            <Input />
                        </Form.Item>
                                    
                    </fieldset>
                </Form>
            </Modal>
 
        </>
    );
};

export default Txalert_IssuerBin;
