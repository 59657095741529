import React, { useState, useEffect } from "react";
import { Table, PageHeader, Card, Form, Select, Row, Col, Button, Input,Tooltip,Typography } from "antd";
import { SearchOutlined, FullscreenOutlined} from "@ant-design/icons";
import DraggableModal from "../../../helper/DraggableModal";
import CustomPaginationV2 from "../../../helper/Table/CustomPaginationV2";
import { API_TXALERT2 } from "../../../config/config";
import ColumnResizable from "../../../helper/Table/ColumnResizable";
import MergeColumns from "../../../helper/Table/MergeColumns";
import DataFetchHookTxAlert from "../../../helper/DataFetchHookTxAlert";
import TextSorter from "../../../helper/Table/TextSorter";
import moment from "moment";
import CustomDateRangeV3 from "../../../helper/Table/CustomDateRangeV3";

const { Paragraph } = Typography;
const DATA_URL = API_TXALERT2 + "/email_send_log";
const { Option } = Select;


const MAX_DATERANGE = 3;
const DEFAULT_DATE_RANGE = 7;
const DEFAULT_DATE_PERIOD = "days";
const START_DATE = moment().subtract(DEFAULT_DATE_RANGE, DEFAULT_DATE_PERIOD);
const END_DATE = moment();

const Txalert_EmailSendLogs = (props) => {
   // eslint-disable-next-line
    const [{ data, totalResult, isLoading, isError, errorMsg, requestData, isUpdated }, setUrl, setRequestData] = DataFetchHookTxAlert(DATA_URL,"GET", localStorage.getItem("txalert_token"),{
        date_from: START_DATE.format('YYYY-MM-DD'),
        date_to: END_DATE.format('YYYY-MM-DD'),
        page: 1,
        size: 20,
        order: "DESC",
        col_order:"emailsendid"
    }); // eslint-disable-line
    const [formData, setFormData] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    const { t } = props;
    const [modal, setModal] = useState({ title: "", content: "" });
    const [isModalVisible, setIsModalVisible] = useState(false);    
    const [isDateExceeded, setIsDateExceeded] = useState(false);
    const [dates, setDates] = useState([START_DATE, END_DATE]);

    const initFormData = {
        keyword: "",
        field: undefined,
        //  order: null,
        
    };

    const [form] = Form.useForm();

    useEffect(() => {
        formValInit();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps



    const formValInit = () => {
        setFormData(initFormData);
        form.setFieldsValue(initFormData);

       
    };

    const clearAll = () => {
        setCurrentPage(1);
        setFormData(initFormData);
        setRequestData({});
        form.setFieldsValue(initFormData);
    };

    const formValChange = (chgV, allV) => {
        setFormData(allV);
    };

    const showModal = (params) => {
        setModal(params);
        setIsModalVisible(true);
    };

    const IsJsonString = (str) => {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };
    const onFinish = () => {
        let startDate = null;
        let endDate = null;
        
        let searchData = {};
        if (formData) {
            if (dates) {
                startDate = dates[0] ? dates[0] : null;
                endDate = dates[1] ? dates[1] : null;
            }
           

        if (formData) {
            let searchkeyword = formData.keyword ;
            searchkeyword = searchkeyword? searchkeyword.toString().trim() : null //convert to string, if dropdown
            searchData = {
                [formData.field]: searchkeyword,
                date_from:startDate.format('YYYY-MM-DD'),
                date_to:endDate.format('YYYY-MM-DD')
            };
        }
     }
        // Reset Page Index to 1
        setCurrentPage(1);
        setRequestData({ ...requestData, ...searchData, page: 1 });
    };

    const columns = [
        {
            dataIndex: "date_sent",
            key: "date_sent",
            title: t("tables.fields.date"),
            width: 50,
            render: (date_sent) => moment(date_sent).format("YYYY-MM-DD HH:mm:ss"),
            sorter: {
                compare: (a, b) => moment(a.date_sent).diff(moment(b.date_sent))
            }
        },
        {
            dataIndex: "templateid",
            key: "templateid",
            title: t("tables.fields.templateid"),
            width: 50,
            sorter: {
                compare: (a, b) => TextSorter(a.templateid, b.templateid)
            },
           
        },
        {
            dataIndex: "email_subject",
            key: "email_subject",
            title: t("tables.fields.email_subject"),
            width: 50,
            sorter: {
                compare: (a, b) => TextSorter(a.email_subject, b.email_subject)
            }
        },
        {
            dataIndex: "send_to",
            key: "send_to",
            align: "left",
            title: t("tables.fields.send_to"),
            width: 150,
            sorter: {
                compare: (a, b) => TextSorter(a.send_to,b.send_to)
            }
        },
        {
            dataIndex: "dynamic_data",
            key: "dynamic_data",
            title: t("contents.txalert_admin.form.sent_data"),
            width: 100,
            render: (dynamic_data, record) => (
                <Tooltip title={dynamic_data}>
                    <Button
                        type="link"
                        size="small"
                        style={{whiteSpace: "normal",height:'auto',marginBottom:'10px',maxWidth:"100%"}}
                        onClick={() => showModal({ title: t("contents.txalert_admin.form.sent_data"), content: record.dynamic_data })}
                    >
                        <FullscreenOutlined />
                    </Button>
                </Tooltip>
            ),
        },
        {
            dataIndex: "result",
            key: "result",
            align: "left",
            title: t("tables.fields.result"),
            width: 150,
            sorter: {
                compare: (a, b) => TextSorter(a.result,b.result)
            }
        },
        {
            dataIndex: "message_id",
            key: "message_id",
            align: "left",
            title: t("tables.fields.message_id"),
            width: 150,
            sorter: {
                compare: (a, b) => TextSorter(a.message_id,b.message_id)
            }
        },
    ];

    const getDataSource = (datalist) => (datalist && Array.isArray(datalist) ? datalist : []);

    const AdvancedSearchForm = () => (
        <div className="search-wrap">
            <Card title={t("tables.actions.search")} size="small">
                <Form form={form} size="large" name="advanced_search" className="ant-advanced-search-form" onValuesChange={formValChange} onFinish={onFinish}>
                    <Row gutter={[16, 16]}>
                    <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                            <Form.Item name="dateRange"
                                rules={[
                                    {
                                        validator: () => {
                                            if(isDateExceeded){
                                                return Promise.reject(t('max_3_months'));
                                            }else if((dates[0] && !dates[1]) || (!dates[0] && dates[1])){
                                                return Promise.reject(t('min_1_day'));
                                            }else{
                                                return Promise.resolve();
                                            }
                                        }
                                    }
                                ]}
                            >
                                <CustomDateRangeV3 t={t} setIsDateExceeded={setIsDateExceeded} state={{ dates, setDates }} unit="months" limit={MAX_DATERANGE} />
                            </Form.Item>
                        </Col>
                    <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 6 }} xl={{ span: 5 }}>
                            <Form.Item name="field">
                                <Select style={{ width: "100%" }} placeholder={t("tables.fields.field")}>
                                    <Option value="templateid">{t("tables.fields.templateid")}</Option>
                                    <Option value="send_to">{t("tables.fields.send_to")}</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12}} lg={{ span: 6 }} xl={{ span: 8 }}>
                            <Form.Item name="keyword">
                                <Input placeholder={t("tables.fields.keyword")} />
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6, offset: 12 }} lg={{ span: 6 }} xl={{ span: 3, offset: 4 }}>
                            <Form.Item>
                                <Button type="secondary" onClick={() => clearAll()} block>
                                    {t("tables.actions.clear_all")}
                                </Button>
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 4 }}>
                            <Form.Item>
                                <Button type="primary" htmlType="submit" block>
                                    <SearchOutlined /> {t("tables.actions.search")}
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Card>
        </div>
    );

    const getModalComponent = () => (
        <DraggableModal
            name={modal.title}
            visible={isModalVisible}
            onOk={handleOk}
            onCancel={handleCancel}
            width={800}
            footer={[
                <Button key="submit" type="primary" onClick={handleOk}>
                    {t("tables.actions.ok")}
                </Button>,
            ]}
            context={
                <Typography>
                    <Paragraph className="well">
                        {IsJsonString(modal.content) ? JSON.stringify(JSON.parse(modal.content), null, "\t") : modal.content}
                    </Paragraph>
                </Typography>
            }
        />
    );
    const getHeaderTable = () => (
        <div className="custom-table--header d-flex justify-content-between align-items-center">
            <h3 className="table-title">{t("contents.txalert_admin.emailsendlog")}</h3>          
        </div>
    );

    return (
        <div>
            <PageHeader title={t("contents.txalert_admin.emailsendlog")}></PageHeader>
            {AdvancedSearchForm()}
            <Table
                size="small"
                loading={isLoading}
                components={{
                    header: {
                        cell: ColumnResizable,
                    },
                }}
                
                columns={MergeColumns(columns)}
                title={() => getHeaderTable()}
                rowKey="emailsendid"
                className="table-v3 custom-pagination custom-pagination-v3"
                dataSource={getDataSource(data.datalist)}
              pagination={CustomPaginationV2({ totalResult, currentPage, requestData, setRequestData, setCurrentPage })}

            />
            {getModalComponent()}
        </div>
    );
};

export default Txalert_EmailSendLogs;
