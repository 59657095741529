import React, { useState, useEffect } from "react";
import { OI_URL } from "../../config/config";
import { Modal, Button, Form, Input, Select } from "antd";
import axios from "axios";
import Swal from "sweetalert2";
import TxFindAccessToken from "../../helper/TxFindAccessToken";

const { Option } = Select;

const EditBinCaidModal = (props) => {
    const { t, fetchBinCaids, isEditModalVisible, setIsEditModalVisible, binCaid } = props;
    const [formData, setFormData] = useState(null); // eslint-disable-line
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [form] = Form.useForm();

    const layout = {
        labelCol: { span: 6 },
        wrapperCol: { span: 16 },
    };

    useEffect(() => {
        if (binCaid) {
            setFormData(binCaid);
            form.setFieldsValue(binCaid);
        }
    }, [binCaid, form]);

    const handleOk = () => {
        form.validateFields().then(values => {
            onFinish(values);
        }).catch(info => {
            console.log('Validate Failed:', info);
        });
    };

    const formValChange = (changedValues, allValues) => {
        setFormData(allValues);
    };

    const onFinish = async (values) => {
        setIsSubmitting(true);
        const apiUrl = OI_URL + `/api/v1/txshield/bincaid/${binCaid.bincaidId}`;

        try {
            const { data } = await axios.put(apiUrl, values, { headers: { Authorization: "Bearer " + await TxFindAccessToken() } });
            if (data.status === "ok") {
                Swal.fire({
                    icon: "success",
                    title: t("contents.oi.bincaid_updated"),
                    showConfirmButton: false,
                    timer: 2000,
                });
                fetchBinCaids();
                handleCancel();
            } else {
                Swal.fire({
                    icon: "error",
                    title: t("login.dialogs.catch"),
                    text: data.msg ? data.msg : "",
                    showConfirmButton: true,
                });
            }
        } catch (error) {
            Swal.fire({
                icon: "error",
                title: t("login.dialogs.catch"),
                text: error.response ? error.response.data.msg : "",
                showConfirmButton: true,
            });
        } finally {
            setIsSubmitting(false);
        }
    };

    const handleCancel = () => {
        form.resetFields();
        setIsEditModalVisible(false);
    };

    return (
        <Modal
            title={t("contents.oi.edit_bincaid")}
            width={600}
            visible={isEditModalVisible}
            onCancel={handleCancel}
            footer={[
                <Button key="back" onClick={handleCancel}>
                    {t("contents.chargebacks.form.cancel")}
                </Button>,
                <Button key="submit" type="primary" loading={isSubmitting} onClick={handleOk}>
                    {isSubmitting ? t("contents.oi.form.updating") : t("contents.oi.form.update")}
                </Button>,
            ]}
        >
            <Form form={form} {...layout} name="edit_bincaid" onValuesChange={formValChange}>
                <Form.Item name="bin" label={t("contents.oi.form.bin")} rules={[{ required: true }]}>
                    <Input />
                </Form.Item>
                <Form.Item name="caid" label={t("contents.oi.form.caid")} rules={[{ required: true }]}>
                    <Input />
                </Form.Item>
                <Form.Item name="mcc" label="MCC" rules={[{ required: true }]}>
                    <Input />
                </Form.Item>
                <Form.Item name="status" label={t("contents.oi.form.status")} rules={[{ required: true }]}>
                    <Select placeholder={t("contents.merchants.form.please_select")}>
                        <Option value="requested">REQUESTED</Option>
                        <Option value="pending">PENDING</Option>
                        <Option value="enrolled">ENROLLED</Option>
                    </Select>
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default EditBinCaidModal;