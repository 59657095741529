import { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";

import DefaultPageSize from "./Table/DefaultPageSize";

const DataFetchHookThreeDS = (
    initialurl,
    requestMethod = "POST",
    defaultRequestData = { page: 1, size: 20, order: "DESC" },
    firstTimeAutoLoad = true,
    maxRange = 3,
    rangeType = "months") => {
    const [data, setData] = useState({ datalist: [] });
    const [totalResult, setTotalResult] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState(null);
    const [isError, setIsError] = useState(false);
    const [url, setUrl] = useState(initialurl);
    const [requestData, setRequestData] = useState({ ...defaultRequestData, size: DefaultPageSize() });
    const [method, setMethod] = useState(requestMethod);
    const [token, setToken] = useState(localStorage.getItem("threeds_token"));
    const [maxDateRange] = useState(maxRange); // eslint-disable-line
    const [dateRangeType] = useState(rangeType);    
    const [isUpdated, setIsUpdated] = useState(false);
    const [isFirstTimeAutoLoad, setIsFirstTimeAutoLoad] = useState(firstTimeAutoLoad);


    const DATE_FORMAT = "YYYY-MM-DD";

    const getStartDate = () =>
        requestData && requestData.startDate ? requestData.startDate.format(DATE_FORMAT) : moment().subtract(maxDateRange, dateRangeType).format(DATE_FORMAT);

    const getEndDate = () => (requestData && requestData.endDate ? requestData.endDate.format(DATE_FORMAT) : moment().format(DATE_FORMAT));

    const getPage = () => (requestData && requestData.page ? requestData.page : 1);
    const getSize = () => (requestData && requestData.size ? requestData.size : 20);
    const getOrder = () => (requestData && requestData.order === "ASC" ? requestData.order : "DESC");
 

    useEffect(() => {
        const fetchdata = async () => {
            setIsLoading(true);
            setIsError(false);
            try {
                let result;
                let options = null;
                
                if (token) {
                    options = { headers: { Authorization: "Bearer " + token } };
                }
                let param = { ...requestData, date_from: getStartDate(), date_to: getEndDate(), page: getPage(), size: getSize(), order: getOrder() };
                
                if (param.startDate && param.endDate) {
                    delete param.startDate;
                    delete param.endDate;
                }

                
                if (method === "POST") {

                    result = await axios
                        .post(url, param, options)
                        .then((result) => result)
                        .catch((error) => {                             
                            setData([]);
                            setErrorMsg(error.response.data.error_msg);
                            setIsError(true);
                        });
                } else {
                    
                    result = await axios
                        .get(url, options)
                        .then((result) => result)
                        .catch((error) => {                             
                            setData([]);
                            setErrorMsg(error.response.data.error_msg);
                            setIsError(true);
                        });
                }
                
                if (result) {
                    if (result.data.list) {
                        setData({ datalist: result.data.list });
                    }
                    if (result.data.totalResult >= 0) {
                        setTotalResult(result.data.totalResult);
                        setIsUpdated(moment().unix());
                    }
                    if (result.data.msg) {
                        setErrorMsg(result.data.msg);
                        setIsError(true);
                    }
                } 
            } catch (err) {
                console.log(err);
                setData([]);
                setErrorMsg(err);
                setIsError(true);
            }
            setIsLoading(false);
        };
        if (isFirstTimeAutoLoad) {
            fetchdata();
        } else {
            setIsFirstTimeAutoLoad(true);
        }
    }, [url, requestData]); // eslint-disable-line react-hooks/exhaustive-deps

    return [{ data, totalResult, requestData, isLoading, isError, errorMsg, isUpdated  }, setUrl, setRequestData, setMethod, setToken];
};
export default DataFetchHookThreeDS;
