import React, { useState } from "react";
import { Table,Button,Tooltip,Typography} from "antd";
import CustomPaginationV2 from "../../../helper/Table/CustomPaginationV2";
import ColumnResizable from "../../../helper/Table/ColumnResizable";
import MergeColumns from "../../../helper/Table/MergeColumns";
import DataFetchHookTxAlert from "../../../helper/DataFetchHookTxAlert";
import moment from "moment";
import { API_TXALERT2 } from "../../../config/config";
import TextSorter from "../../../helper/Table/TextSorter";
import StatusText from "../../../helper/Table/StatusText";
import {FullscreenOutlined} from "@ant-design/icons";
import DraggableModal from "../../../helper/DraggableModal";
const { Paragraph } = Typography;

const Txalert_PosttoProviderLogTbl = (props) => {
    const DATA_URL = API_TXALERT2 + "/post_to_alert_provider_log?alert_id="+props.alertid
    const [{ data, totalResult, isLoading, isError, errorMsg, requestData, isUpdated }, setUrl, setRequestData] = DataFetchHookTxAlert(DATA_URL,"GET", localStorage.getItem("txalert_token")); // eslint-disable-line
    const [currentPage, setCurrentPage] = useState(1);
    const { t } = props;
    const [modal, setModal] = useState({ title: "", content: "" });
    const [isModalVisible, setIsModalVisible] = useState(false);
    
    const columns = [
        {
            dataIndex: "post_date",
            key: "post_date",
            title: t("tables.fields.date"),
            width: 50,
            render: (post_date) => moment(post_date).format("YYYY-MM-DD HH:mm:ss"),
            sorter: {
                compare: (a, b) => moment(a.post_date).diff(moment(b.post_date))
            }
        },
        {
            dataIndex: "provider_name",
            key: "provider_name",
            title: t("tables.fields.providername"),
            width: 50,
            sorter: {
                compare: (a, b) => TextSorter(a.provider_name , b.provider_name)
            }
        },
        {
            dataIndex: "alert_id",
            key: "alert_id",
            title: t("tables.fields.alertid"),
            width: 50,
            sorter: {
                compare: (a, b) => a.alert_id - b.alert_id
            },
            render: (id, record) => (
                <a href={`/alertdetail/${id}`} target="_blank" rel="noopener noreferrer">    
                       {id}
                </a>
            )
        },       
        {
            dataIndex: "status",
            key: "status",
            align: "left",
            title: t("tables.fields.status"),
            width: 150,
            render: (status) => StatusText(status),
            sorter: {
                compare: (a, b) => TextSorter(a.status,b.status),
            }
        },
        {
            dataIndex: "send_data",
            key: "send_data",
            title: t("contents.txalert_admin.form.sent_data"),
            width: 100,
            render: (send_data, record) => (
                <Tooltip title={send_data}>
                    <Button
                        type="link"
                        size="small"
                        style={{whiteSpace: "normal",height:'auto',marginBottom:'10px',maxWidth:"100%"}}
                        onClick={() => showModal({ title: t("contents.txalert_admin.form.sent_data"), content: record.send_data })}
                    >
                        <FullscreenOutlined />
                    </Button>
                </Tooltip>
            ),
        },
        {
            dataIndex: "response_data",
            key: "response_data",
            title: t("tables.fields.response_data"),
            width: 100,
            render: (response_data, record) => (
                <Tooltip title={response_data}>
                    <Button
                        type="link"
                        size="small"
                        style={{whiteSpace: "normal",height:'auto',marginBottom:'10px',maxWidth:"100%"}}
                        onClick={() => showModal({ title: t("tables.fields.response_data"), content: record.response_data })}
                    >
                        <span style={{overflow: "hidden",textOverflow: "ellipsis",whiteSpace: "nowrap",display: "block"}}><FullscreenOutlined /></span>
                    </Button>
                </Tooltip>
            ),
        }
        
    ];

    const getModalComponent = () => (
        <DraggableModal
            name={modal.title}
            visible={isModalVisible}
            onOk={handleOk}
            onCancel={handleCancel}
            width={800}
            footer={[
                <Button key="submit" type="primary" onClick={handleOk}>
                    {t("tables.actions.ok")}
                </Button>,
            ]}
            context={
                <Typography>
                    <Paragraph className="well">
                        {IsJsonString(modal.content) ? JSON.stringify(JSON.parse(modal.content), null, "\t") : modal.content}
                    </Paragraph>
                </Typography>
            }
        />
    );
    const IsJsonString = (str) => {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };
    const getDataSource = (datalist) => (datalist && Array.isArray(datalist) ? datalist : []);
   
    const getHeaderTable = () => (
        <div className="custom-table--header d-flex justify-content-between align-items-center">
            <h3 className="table-title">{t("contents.txalert_admin.providerpostlog")}</h3>          
        </div>
    );

   
    
    const showModal = (params) => {
        setModal(params);
        setIsModalVisible(true);
    };
    return (
        <div>            
             <Table
                size="small"
                loading={isLoading}
                components={{
                    header: {
                        cell: ColumnResizable,
                    },
                }}
                
                columns={MergeColumns(columns)}
                title={() => getHeaderTable()}
                rowKey="post_to_alert_provider_logs_id"
                className="table-v3 custom-pagination custom-pagination-v3"
                dataSource={getDataSource(data.datalist)}
              pagination={CustomPaginationV2({ totalResult, currentPage, requestData, setRequestData, setCurrentPage })}

            />
            {getModalComponent()}
        </div>
    );
};


export default Txalert_PosttoProviderLogTbl;
