import React, { useState} from "react";
import DataFetchHookV3 from "../../helper/DataFetchHookV3";
// import StatusText from "../../helper/Table/StatusText";
import { Table, PageHeader, Form } from "antd";
//import axios from "axios";
//import CustomPaginationV3 from "../../helper/Table/CustomPaginationV3";
import CustomPaginationV2 from "../../helper/Table/CustomPaginationV2";
import { API_MERCHANT } from "../../config/config";
import ColumnResizable from "../../helper/Table/ColumnResizable";
import MergeColumns from "../../helper/Table/MergeColumns";
import GroupAccess from "./../../helper/GroupAccess";
// import MenuAccess from "./../../helper/MenuAccess";

const DATA_URL = API_MERCHANT + "/balance";

const MerchantBalance = (props) => {
    //eslint-disable-next-line
    const [{ data, totalResult, isLoading, requestData, isUpdated }, doFetch, setRequestData] = DataFetchHookV3(DATA_URL, "POST", {
        page: 1,
        size: 20,
        order: "ASC",
        sort_field:"site_name", 
    }); // eslint-disable-line
    const [currentPage, setCurrentPage] = useState(1);
    const { t } = props;
    const [isSortUpdate, setIsSortUpdate] = useState(false);  //eslint-disable-line


    const [form] = Form.useForm();

    const handleChange = (pagination, filters, sorter, { currentDataSource }) => {       
        var orderBy = 'ASC';
        if(sorter.order === 'descend'){
            orderBy = 'DESC';
        }
        var sortField = 'site_name';
        if (sorter.field !== undefined){
            sortField = sorter.field;
        }
        var p = pagination.current;
        if (Object.keys(sorter).length >0 && requestData.sort_field !== sortField){
            p = 1;
            setCurrentPage(1);
        }

        setRequestData({ ...requestData, order: orderBy, sort_field: sortField, page: p, size: pagination.pageSize  });
        setIsSortUpdate(true);

    };

   

    const columns = [
        {
            dataIndex: "merchant_name",
            key: "merchant_name",
            title: t("tables.fields.merchant_name"),
            width: 50,
            sorter: {
                compare: (a, b) => a.merchant_name.localeCompare(b.merchant_name),
            },
        },
        {
            dataIndex: "site_name",
            key: "site_name",
            title: t("tables.fields.site_name"),
            width: 150,
            sorter: {
                compare: (a, b) => a.site_name.localeCompare(b.site_name)
            },
        },
        {
            dataIndex: "balance",
            key: "balance",
            align: "left",
            title: t("tables.fields.balance"),
            width: 150,
            sorter: {
                compare: (a, b) => a.balance - b.balance,
            },
        },
     
    ];

    const getDataSource = (datalist) => (datalist && Array.isArray(datalist) ? datalist : []);

   
    const getHeaderTable = () => (
        <div className="custom-table--header d-flex justify-content-between align-items-center">
            <h3 className="table-title">{t("contents.merchants.balance")}</h3>
        </div>
    );

    return (
        GroupAccess("SYSADMIN|RESELLER|MERCHANT")?(
        <div>
            <PageHeader title={t("contents.merchants.balance")}></PageHeader>
            
            <Table
                size="small"
                loading={isLoading}
                components={{
                    header: {
                        cell: ColumnResizable,
                    },
                }}
                columns={MergeColumns(columns)}
                title={() => getHeaderTable()}
                rowKey="sid"
                className="table-v3 custom-pagination custom-pagination-v3"
                dataSource={getDataSource(data.datalist)}
                onChange={handleChange}
                sortDirections={["ascend", "descend"]}
             
              pagination={CustomPaginationV2({ totalResult, currentPage, requestData, setRequestData, setCurrentPage })}
            />
        </div>):(<Form form={form} ><label>Unauthorized Access</label></Form>)
    );
};

export default MerchantBalance;
