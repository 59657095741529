import React, { useEffect } from "react";
import { Card, Form, Input, Row, Col} from "antd";
import { DISABLE_OI } from "../../../config/config";

import GroupAccess from "../../../helper/GroupAccess";

const MerchantDetails = (props) => {
    const { t, merchantDetails } = props; // eslint-disable-line

    const initFormData = {
        oi_merchant_id: merchantDetails.oi_merchant_id,
        themeid: merchantDetails.theme,
        status: merchantDetails.status
    }

    const itemLayout = {
        labelAlign: "left",
        labelCol: { span: 10, offset: 0 },
    };

    // const textareaLayout = {
    //     labelAlign: "left",
    //     labelCol: { span: 24, offset: 0 },
    // };

    const [form] = Form.useForm();

    useEffect(() => {
        formValInit();
        
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const formValInit = () => {
        form.setFieldsValue(initFormData);
    };


 

    const onFinish = () => {};

    return merchantDetails ? (
        <>
            <h3>{t("contents.merchants.details")}</h3>
            <Card className="custom-form--card">                
                <Form form={form} {...itemLayout} name="merchant_detail" onFinish={onFinish}>
                <Row>
                    <Col span={12}>
                    <Form.Item name="enrolled_services" label={t("contents.merchants.form.enrolled_services")}>
                           
                           
                          <label style={{textTransform: 'capitalize'}}>  { merchantDetails.enrolled_services ? merchantDetails.enrolled_services.map(item=>item).join(", ") : null}
                               </label>
                     </Form.Item>       
                     </Col>
                    </Row>
                    <Row gutter={[36, 16]}>
                        <Col span={12}>
                        {(GroupAccess("SYSADMIN") && !DISABLE_OI)? (
                            <Form.Item name="oi_merchant_id" label={t("contents.merchants.form.txfind_merchant_id")}>
                                <Input readOnly bordered={false}/>
                            </Form.Item>
                        ):null}
                             <Form.Item name="status" label={t("contents.merchants.form.status")}>
                            <Input readOnly bordered={false} />
                               </Form.Item>
                            
                        </Col>
                        <Col span={12}>
                        {GroupAccess("SYSADMIN") ? (
                                 <Form.Item name="theme" label={t("contents.merchants.form.theme")}>
                                 <Input readOnly bordered={false}/>
                             </Form.Item>

                           
                            ) : null }
                        </Col>
                    </Row>
                    
                </Form>
            </Card>
        </>
    ) : null;
};

export default MerchantDetails;
