import React, { useEffect} from "react";
import { Card, Row, Col, Form, Input } from "antd"; // eslint-disable-line
import GroupAccess from "../../../helper/GroupAccess";

const SiteDetailsTab = (props) => {
    const { t, siteDetails, isLoading } = props; // eslint-disable-line

    const [form] = Form.useForm();

    const initFormData = {
        name: siteDetails.name,
        description: siteDetails.payment.details.description,
        sid: siteDetails.payment.details.sid,
        reseller: siteDetails.reseller,
        url: siteDetails.payment.details.url,
    };

    const itemLayout = {
        labelAlign: "left",
        labelCol: { span: 10, offset: 0 },
    };

    const textareaLayout = {
        labelAlign: "left",
        labelCol: { span: 24, offset: 0 },
    };

    useEffect(() => {
     if(isLoading  === false){
        formValInit();
        }
    }, [form, isLoading]); // eslint-disable-line react-hooks/exhaustive-deps


    const formValInit = () => {
        form.setFieldsValue(initFormData); // eslint-disable-line react-hooks/exhaustive-deps
    };

   

    

    const getSiteDetails = () => (
        <Card className="custom-form--card" loading={isLoading}>
            <Form form={form} {...itemLayout} name="payment">
                <Row gutter={[36, 16]}>
                    <Col span={12}>
                        <Form.Item name="name" label={t("contents.merchants.form.name")}>
                            <Input readOnly bordered={false}/>
                        </Form.Item>
                        <Form.Item name="sid" label="SID">
                         <Input readOnly bordered={false}/>
                        </Form.Item>

                        {GroupAccess("SYSADMIN") ?(
                        <Form.Item name="reseller" label={t("contents.merchants.form.reseller")}>
                            <Input readOnly bordered={false}/>
                        </Form.Item>
                        ):null}
                        
                        <Form.Item name="url" label={t("contents.merchants.form.site_url")}>
                            <Input readOnly bordered={false}/>
                        </Form.Item>
                      
                        <Form.Item name="currency" label={t("contents.merchants.form.settlement_currency")}>
                            {siteDetails.payment.details.currency ? (
                                <span className="ant-form-text">{siteDetails.payment.details.currency}</span>
                            ) : null}
                        </Form.Item>
                    </Col>
                    <Col span={12}>                       
                        <Form.Item {...textareaLayout} name="description" label={t("contents.merchants.form.description")}>
                            <Input.TextArea readOnly rows={3} />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Card>
    );

    return getSiteDetails();
}
export default SiteDetailsTab;
