import React from "react";
import { Table, Card } from "antd";
import { CheckCircleOutlined, FieldTimeOutlined, CloseCircleOutlined } from "@ant-design/icons";
import FormatAmount from "../../../helper/FormatAmount";
import moment from "moment";

const TxHistory = (props) => {
    const { t, loading, data, currency } = props;

    const getFormatNumber = (amount) => `${FormatAmount(amount ? amount : "0.00", currency)} ${currency}`;

    const getStatus = (status) => {
        if (!status) return "";
        let statusText = status.charAt(0) + status.toLowerCase().slice(1);
        switch (status) {
            case "APPROVED":
                return (
                    <div className="d-flex align-items-center">
                        <CheckCircleOutlined style={{ color: "#52c41a", fontSize: "18px", marginRight: "4px" }} />
                        <span>{statusText}</span>
                    </div>
                );
            case "PENDING":
                return (
                    <div className="d-flex align-items-center">
                        <FieldTimeOutlined style={{ color: "#1890ff", fontSize: "18px", marginRight: "4px" }} />
                        <span>{statusText}</span>
                    </div>
                );
            case "DECLINED":
            case "PAYG_ERROR":
                return (
                    <div className="d-flex align-items-center">
                        <CloseCircleOutlined style={{ color: "#f5222d", fontSize: "18px", marginRight: "4px" }} />
                        <span>{statusText}</span>
                    </div>
                );
            default:
                return "";
        }
    };

    const columns = [
        {
            dataIndex: "txid",
            key: "txid",
            title: t('contents.transactions.tabs.tx_history'),
            render: (txid, record) => (
                <span>
                    {record.tx_action}
                    <br />
                    <small>{txid}</small>
                </span>
            ),
        },
        {
            dataIndex: "response",
            key: "response",
            title: t("tables.fields.status"),
            render: (response, record) => getStatus(response),
        },
        {
            dataIndex: "timestamp",
            key: "timestamp",
            render: (timestamp,record) => moment(timestamp).format("lll"),
            title: t("tables.fields.time"),
        },
        {
            dataIndex: "amount",
            key: "amount",
            title: t("tables.fields.amount"),
            render: (amount, record) => getFormatNumber(amount),
        },
        {
            dataIndex: "site_receive",
            key: "site_receive",
            title: t("tables.fields.site_receive"),
            render: (site_receive, record) => getFormatNumber(site_receive),
        },
        {
            dataIndex: "fees",
            key: "fees",
            title: t("tables.fields.fees"),
            render: (fees, record) => getFormatNumber(fees),
        },
        {
            dataIndex: "reserve",
            key: "reserve",
            title: t("tables.fields.reserve"),
            render: (reserve, record) => getFormatNumber(reserve),
        },
    ];

    return (
        <Card className="details-info--card" loading={loading}>
            <h4 className="details-info--title">{t('contents.transactions.tabs.tx_history')}</h4>
            <div className="details-info--table--wrap">
                <Table size="small" columns={columns} rowKey="txid" pagination={false} dataSource={data} />
            </div>
        </Card>
    );
};

export default TxHistory;
