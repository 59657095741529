import React, { useEffect } from "react";
import { Card, Form, Input, Row, Col, Button } from "antd"; // eslint-disable-line

const RetailerDetails = (props) => {
    const { t, retailerDetails } = props; // eslint-disable-line

    const itemLayout = {
        labelAlign: "left",
        labelCol: { span: 10, offset: 0 },
    };

    const textareaLayout = {
        labelAlign: "left",
        labelCol: { span: 24, offset: 0 },
    };

    const initFormData = {
        name: retailerDetails.name,
        rcode: retailerDetails.rcode ? retailerDetails.rcode : '',
        remarks: retailerDetails.remarks,
        description: retailerDetails.description
    }

    const [form] = Form.useForm();

    useEffect(() => {
        formValInit();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const formValInit = () => {
        form.setFieldsValue(initFormData);
    };

    const onFinish = () => {};

    return (
        <>
            <h3>{t('contents.merchants.retailer_details')} - {retailerDetails.name}</h3>
            <Card className="custom-form--card">
                <Form form={form} {...itemLayout} name="retailer_detail" onFinish={onFinish}>
                    <Row gutter={[36, 16]}>
                        <Col span={12}>
                            <Form.Item name="name" label={t('contents.merchants.form.name')}>
                                <Input readOnly bordered={false}/>
                            </Form.Item>
                            <Form.Item name="rcode"  label={t('contents.merchants.form.rcode')}>
                            <Input.TextArea rows={2} readOnly />
                            </Form.Item>
                            
                        </Col>
                        <Col span={12}>
                            {/* <Form.Item {...textareaLayout} name="remarks" label={t('contents.merchants.form.remarks')}>
                                <Input.TextArea rows={2} bordered={false}/>
                            </Form.Item> */}
                            <Form.Item {...textareaLayout} name="description" label={t('contents.merchants.form.description')}>
                                <Input.TextArea rows={2} readOnly />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Card>
        </>
    );
};

export default RetailerDetails;
