import React, { useState, useEffect, useCallback } from "react";
import DataFetchHookOI from "../../helper/DataFetchHookOI";
import DraggableModal from "../../helper/DraggableModal";
import PermissionsRequired from "../../helper/PermissionsRequired";
import moment from "moment";
import { Table, PageHeader, Button, Card, Form, Select, Row, Col, Input, Typography } from "antd";
import { SearchOutlined, FileSearchOutlined } from "@ant-design/icons";
import AssociationsChecker from "./../../helper/AssociationsChecker";
import axios from "axios";
import { OI_URL } from "../../config/config";
import ExportModalV3 from "../../helper/ExportModalV3";
import CustomPaginationV2 from "../../helper/Table/CustomPaginationV2";
import CustomDateRangeV3 from "../../helper/Table/CustomDateRangeV3";
import SortOrder from "../../helper/Table/SortOrder";
import TxFindAccessToken from "../../helper/TxFindAccessToken";
import ColumnResizable from "../../helper/Table/ColumnResizable";
import MergeColumns from "../../helper/Table/MergeColumns";

const { Paragraph } = Typography;
const { Option } = Select;

const DATA_URL = "/api/v1/txshield/lookupHistory/";
const DATA_URL_EXPORT = "/api/v1/txshield/lookupHistory/reports";
const MERCHANT_DATA_URL = "/api/v1/txshield/merchants/";
const MERCHANT_DESCRIPTOR_DATA_URL = "/api/v1/txshield/descriptors/";

const MAX_DATERANGE = 3;
const START_DATE = moment().subtract(MAX_DATERANGE, "months");
const END_DATE = moment();

const LookupHistory = (props) => {
    const [{ data, totalResult, isLoading, isError, errorMsg, requestData }, doFetch, setRequestData] = DataFetchHookOI(DATA_URL); // eslint-disable-line
    const [formData, setFormData] = useState({ hideColumn: [] });
    const [form] = Form.useForm();
    const [sortedInfo, setSortedInfo] = useState({});
    const [dates, setDates] = useState([START_DATE, END_DATE]);
    const [currentPage, setCurrentPage] = useState(1);
    const [merchantList, setMerchantList] = useState([]);
    const [descriptorList, setDescriptorList] = useState(null);
    const [selectedDescriptor, setSelectedDescriptor] = useState(null);
    const [modal, setModal] = useState({ title: "", content: "" });
    const [isModalVisible, setIsModalVisible] = useState(false);
    const oiAssocication = localStorage.getItem("oiAssocication") ? localStorage.getItem("oiAssocication").split(",") : []; 
    const { t } = props;
    const [isDateExceeded, setIsDateExceeded] = useState(false); 

    const initFormData = {
        keyword: null,
        field: "txShieldOICartReference",
        // order: null,
        associatedMerchantIds: [],
        descriptor: null,
        dateRange: [START_DATE, END_DATE],
    };

    useEffect(() => {
        formValInit();
        getMerchantList();
        getDescriptorList();
        // getExportData();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const getMerchantList = useCallback(async () => {
        try {
            const response = await axios.post(
                OI_URL + MERCHANT_DATA_URL,
                { associatedMerchantIds: oiAssocication },
                { headers: { Authorization: "Bearer " + await TxFindAccessToken() } }
            );
        
            if (response.data && Array.isArray(response.data.list)) {
                setMerchantList(response.data.list);
            } else {
                console.warn("No valid merchant list found in the response");
                setMerchantList([]);
            }
        } catch (error) {
            console.error("Error fetching merchant list:", error);
            setMerchantList([]);
        }
    }, [oiAssocication]);
    
    const getDescriptorList = useCallback(async () => {
        try {
            const response = await axios.post(
                OI_URL + MERCHANT_DESCRIPTOR_DATA_URL,
                { associatedMerchantIds: oiAssocication },
                { headers: { Authorization: "Bearer " + await TxFindAccessToken() } }
            );
        
            if (response.data && Array.isArray(response.data.list)) {
                setDescriptorList(response.data.list);
            } else {
                console.warn("No valid descriptor list found in the response");
                setDescriptorList([]);
            }
        } catch (error) {
            console.error("Error fetching descriptor list:", error);
            setDescriptorList([]);
        }
    }, [oiAssocication]);

    const handleChange = (pagination, filters, sorter, { currentDataSource }) => {
        setSortedInfo(sorter);
    };

    const formValInit = () => {
        setFormData(initFormData);
        form.setFieldsValue(initFormData);
    };

    const formValChange = (chgV, allV) => {
        setFormData(allV);
    };

    const clearAll = () => {
        setFormData(initFormData);
        setRequestData({ ...requestData, ...initFormData, startDate: null, endDate: null, page: 1 });
        form.setFieldsValue(initFormData);
    };

    const columns = [
        {
            dataIndex: "txShieldOICartReference",
            key: "txShieldOICartReference",
            title: t("tables.fields.txshieldoicartreference"),
            width: 250,
            render: (txShieldOICartReference) => (txShieldOICartReference ? txShieldOICartReference : ""),
            sorter: {
                compare: (a, b) => a.txShieldOICartReference.localeCompare(b.txShieldOICartReference),
            },
            sortOrder: SortOrder(sortedInfo, "txShieldOICartReference"),
        },
        {
            dataIndex: "lookupReference",
            key: "lookupReference",
            title: t("tables.fields.lookupreference"),
            width: 250,
            sorter: {
                compare: (a, b) => a.lookupReference.localeCompare(b.lookupReference),
            },
            sortOrder: SortOrder(sortedInfo, "lookupReference"),
        },
        {
            dataIndex: "lookupDate",
            key: "lookupDate",
            title: t("tables.fields.lookupdate"),
            width: 180,
            render: (lookupDate) => moment(lookupDate).format("YYYY-MM-DD HH:mm:ss"),
            sorter: {
                compare: (a, b) => moment(a.lookupDate).diff(moment(b.lookupDate)),
            },
            sortOrder: SortOrder(sortedInfo, "lookupDate"),
        },
        {
            dataIndex: "provider",
            key: "provider",
            title: t("tables.fields.provider"),
            width: 100,
            sorter: {
                compare: (a, b) => a.provider.localeCompare(b.provider),
            },
            sortOrder: SortOrder(sortedInfo, "provider"),
        },
        {
            dataIndex: "receivedData",
            key: "receivedData",
            align: "left",
            title: t("tables.fields.receiveddata"),
            width: 130,
            render: (receivedData, record) => (receivedData ? getButtonModal({ title: t("contents.oi.received_data"), content: receivedData }) : ""),
        },
        {
            dataIndex: "returnData",
            key: "returnData",
            align: "left",
            title: t("tables.fields.returndata"),
            width: 130,
            render: (returnData, record) => (returnData ? getButtonModal({ title: t("contents.oi.return_data"), content: returnData }) : ""),
        },
    ];

    const showModal = (params) => {
        setModal(params);
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const onDescriptorDropdownChange = async (value) => {
        setSelectedDescriptor(value);
    };

    const getButtonModal = (params, buttonText = "") => (
        <Button type="link" size="small" onClick={() => showModal(params)}>
            <FileSearchOutlined style={{ fontSize: "18px", color: "rgba(0, 0, 0, 0.85)" }} />
        </Button>
    );

    const IsJsonString = (str) => {
        try {
            return JSON.parse(str);
        } catch (e) {
            return false;
        }
    };

    const IsObject = (object) => {
        if (typeof object === "object") {
            return JSON.stringify(object);
        }
        return false;
    };

    const getDataSource = (datalist) =>
        datalist && Array.isArray(datalist)
            ? datalist.map((data) => ({
                  ...data,
                  txShieldOICartReference: data.txShieldOICartReference || "",
                  lookupReference: data.lookupReference || "",
                  provider: data.provider || "",
              }))
            : [];

    const onFinish = () => {
        let startDate = null;
        let endDate = null;
        let searchData = {};
        if (formData) {
            if (dates) {
                startDate = dates[0] ? dates[0] : null;
                endDate = dates[1] ? dates[1] : null;
            }
            searchData = {
                value: [formData.keyword ? formData.keyword.trim() : null],
                field: [formData.field],
                operator: [formData.field === "txShieldOICartReference" ? "=" : "LIKE"],
                order: formData.order,
                associatedMerchantIds: formData.associatedMerchantIds,
                descriptor: formData.descriptor,
            };
        }

        setCurrentPage(1);
        setRequestData({ ...requestData, ...searchData, startDate: startDate, endDate: endDate, page: 1 });
    };

    const getModalComponent = () => (
        <DraggableModal
            name={modal.title}
            visible={isModalVisible}
            onOk={handleOk}
            onCancel={handleCancel}
            width={800}
            footer={[
                <Button key="submit" type="primary" onClick={handleOk}>
                    {t("tables.actions.ok")}
                </Button>,
            ]}
            context={
                <Typography>
                    <Paragraph className="well">
                        {IsObject(modal.content)
                            ? JSON.stringify(modal.content, null, "\t")
                            : IsJsonString(modal.content)
                            ? JSON.stringify(JSON.parse(modal.content), null, "\t")
                            : modal.content}
                    </Paragraph>
                </Typography>
            }
        />
    );

    const AdvancedSearchForm = () => (
        <div className="search-wrap">
            <Card title={t("tables.actions.search")} size="small">
                <Form form={form} size="large" name="advanced_search" className="ant-advanced-search-form" onValuesChange={formValChange} onFinish={onFinish}>
                    <Row gutter={[16, 16]}>
                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }} xl={{ span: 6 }}>
                            <Form.Item 
                                name="dateRange" 
                                rules={[
                                    {
                                        validator: () => {
                                            if (isDateExceeded) {
                                                return Promise.reject(t('max_3_months'));
                                            } else if ((dates[0] && !dates[1]) || (!dates[0] && dates[1])) {
                                                return Promise.reject(t('min_1_day'));
                                            } else {
                                                return Promise.resolve();
                                            }
                                        },
                                    },
                                ]}
                            >
                                <CustomDateRangeV3 
                                    t={t} 
                                    setIsDateExceeded={setIsDateExceeded} 
                                    state={{ dates, setDates }} 
                                    unit="months" 
                                    limit={MAX_DATERANGE} 
                                />
                            </Form.Item>
                        </Col>
                        {merchantList ? (
                            <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }} xl={{ span: 6 }}>
                                <Form.Item name="associatedMerchantIds">
                                    <Select
                                        showSearch
                                        mode="multiple"
                                        showArrow="true"
                                        maxTagCount="responsive"
                                        style={{ width: "100%" }}
                                        optionFilterProp="children"
                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                        filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
                                        placeholder={t("contents.oi.advanced_search.merchant")}
                                    >
                                        {merchantList.map((item) => (
                                            <Option key={item.merchantId} value={item.merchantId}>
                                                {item.name}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                        ) : null}
                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }} xl={{ span: 5 }}>
                            <Form.Item name="field">
                                <Select 
                                    showSearch
                                    style={{ width: "100%" }} 
                                    placeholder={t("tables.fields.field")}>
                                    <Option value="txShieldOICartReference">{t("contents.oi.advanced_search.txshieldoicartreference_field")}</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }} xl={{ span: 7 }}>
                            <Form.Item name="keyword">
                                <Input placeholder={t("tables.fields.keyword")} />
                            </Form.Item>
                        </Col>
                        {descriptorList ? (
                            <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }} xl={{ span: 6 }}>
                                <Form.Item name="descriptor">
                                    <Select
                                        value={selectedDescriptor}
                                        onChange={onDescriptorDropdownChange}
                                        showSearch
                                        style={{ width: "100%" }}
                                        placeholder={t("contents.oi.advanced_search.descriptor")}
                                        optionFilterProp="children"
                                        filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                    >
                                        {descriptorList.map((item) => (
                                            <Option key={item.title} value={item.title}>
                                                {item.title}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                        ) : null}
                        {/*
                        <Col className="gutter-row" span={4}>
                            <Form.Item name="order">
                                <Select style={{ width: "100%" }} placeholder="Order">
                                    <Option value="ASC">ASC</Option>
                                    <Option value="DESC">DESC</Option>
                                </Select>
                            </Form.Item>
                        </Col> */}
                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 4, offset: 0 }} xl={{ span: 3, offset: 11 }}>
                            <Form.Item>
                                <Button type="secondary" onClick={() => clearAll()} block>
                                    {t("tables.actions.clear_all")}
                                </Button>
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 4 }} xl={{ span: 4 }}>
                            <Form.Item>
                                <Button type="primary" htmlType="submit" block>
                                    <SearchOutlined /> {t("tables.actions.search")}
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Card>
        </div>
    );

    const exportHeadersOptional = [];
    const exportHeaders = [
        { label: "ID", key: "_id" },
        { label: "TxFind Cart Reference", key: "txShieldOICartReference" },
        { label: "Lookup Reference", key: "lookupReference" },
        { label: "Lookup Date", key: "lookupDate" },
        { label: "Provider", key: "provider" },
    ];

    const getHeaderTable = () => (
        <div className="custom-table--header d-flex justify-content-between align-items-center">
            <h3 className="table-title">{t("contents.oi.lookup_history")}</h3>
            <ExportModalV3
                t={t}
                keyname="export_all"
                title={t("tables.actions.export")}
                data={requestData}
                data_url={DATA_URL_EXPORT}
                headers={exportHeaders}
                fieldHeadersOptional={exportHeadersOptional}
                filename={`txfind_cart_lookup_${moment().format("YYMMDD_HHmmss_SSSS")}.csv`}
                style={{ marginLeft: 8 }}
            />
        </div>
    );

    return PermissionsRequired("SYSADMIN") || AssociationsChecker("OI") ? (
        <div>
            <PageHeader title={t("contents.oi.lookup_history")}></PageHeader>
            {getModalComponent()}
            {AdvancedSearchForm()}
            <Table
                title={() => getHeaderTable()}
                loading={isLoading}
                components={{
                    header: {
                        cell: ColumnResizable,
                    },
                }}
                columns={MergeColumns(columns)}
                rowKey="lookupDate"
                scroll={{ x: 1200 }}
                className="table-v3 custom-pagination"
                size="small"
                dataSource={getDataSource(data.datalist)}
                onChange={handleChange}
                pagination={CustomPaginationV2({ totalResult, currentPage, requestData, setRequestData, setCurrentPage })}
            />
        </div>
    ) : null;
};

export default LookupHistory;