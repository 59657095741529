import React from "react";
import { 
    Chart, 
    Line, 
    Point, 
    Tooltip, 
    Legend,
    Axis
} from "bizcharts";

const Last30DaysPerDayChart = (props) => {
    const { t, data } = props;

    const getDataRange = (data, field) => {
        const values = data.reduce((pre, item) => {
            if (item[field]) {
                pre.push(item[field]);
            }
            return pre;
        }, []);

        const minValue = Math.min(...values);
        const maxValue = Math.max(...values);
        return [minValue, maxValue]
    }

    const scale = {
        total: { 
            min: 0,
            max: getDataRange(data, 'total')[1] > 25 ? getDataRange(data, 'total')[1] : 25
        },
        status: {
            formatter: v => {
                return {
                    pass: 'Pass',
                    fail: 'Fail',
                    all: 'All'
                }[v]
            }
        }
    }

    return (
        <>
            { data.length ? (
            <Chart scale={scale} padding={[30, 20, 60, 40]} autoFit height={250} data={data} interactions={['element-active']}>
                <Point 
                    position="date*total"
                    shape='circle'
                    color={['status', (xVal) => {
                        if (xVal === 'pass') {
                            return '#62DAAB';
                        } else if(xVal === 'fail') {
                            return '#f44336';
                        } else {
                            return '#6797F7';
                        }
                    }]} 
                />
                <Line 
                    shape="line" 
                    position="date*total" 
                    color={['status', (xVal) => {
                        if (xVal === 'pass') {
                            return '#62DAAB';
                        } else if(xVal === 'fail') {
                            return '#f44336';
                        } else {
                            return '#6797F7';
                        }
                    }]}
                />
                <Tooltip shared />            
                <Axis name="date" label={{ rotate: 0.5, autoRotate: true, style: {textAlign: 'start', fontSize: 11 } }} />
                <Legend position="top"/>
            </Chart>
            ) : <div className="text-center" style={{marginTop: 15}}>{t('contents._3dsecure_stats.no_data')}</div>}
        </>
    );
};

export default Last30DaysPerDayChart;