import React, { useState, useEffect } from "react";
import { PageHeader, Tabs, Row, Col, Card, Table } from "antd";
import { API_TXALERT } from "../../config/config";
import SortOrder from "../../helper/Table/SortOrder";
import axios from "axios";
import moment from "moment";
import ColumnResizable from "../../helper/Table/ColumnResizable";

const { TabPane } = Tabs;

const Descriptor = (props) => {
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(true);
    const { t } = props;
    const [sortedInfo] = useState({}); // Removed setSortedInfo by Jericho 2023/05/19 because it is not used
    const descriptorid = props.match.params.id;

    // Commented by Jericho 2023/05/19 because it is not used
    // const [adjustableColumns, setAdjustableColumns] = useState({}); 
    // const [adjustable, setAdjustable] = useState({ colw: 0, mouseDownX: 0, beginDrag: false, disableSorter: false });
    
    const showInfo = (detail, labelSpan = 10, valueSpan = 14) => (
        <div className="details-info--text">
            <Row gutter={[0, 8]} key="descriptor_id">
                <Col key="label-descriptor_id" span={labelSpan}>
                    <span className="text">
                        <b>{t(`contents.descriptors.form.descriptor_id`)}</b>
                    </span>
                </Col>
                <Col key="value-descriptor_id" span={valueSpan}>
                    <span className="text">{detail.descriptor_id}</span>
                </Col>
            </Row>
            <Row gutter={[0, 8]} key="descriptor">
                <Col key="label-descriptor" span={labelSpan}>
                    <span className="text">
                        <b>{t(`contents.descriptors.form.descriptor`)}</b>
                    </span>
                </Col>
                <Col key="value-descriptor" span={valueSpan}>
                    <span className="text">{detail.descriptor}</span>
                </Col>
            </Row>
            <Row gutter={[0, 8]} key="sitename">
                <Col key="label-sitename" span={labelSpan}>
                    <span className="text">
                        <b>{t(`contents.descriptors.form.site_name`)}</b>
                    </span>
                </Col>
                <Col key="value-sitename" span={valueSpan}>
                    <span className="text">{detail.site_name}</span>
                </Col>
            </Row>
            <Row gutter={[0, 8]} key="active">
                <Col key="label-active`" span={labelSpan}>
                    <span className="text">
                        <b>{t(`contents.descriptors.form.active`)}</b>
                    </span>
                </Col>
                <Col key="value-active" span={valueSpan}>
                    <span className="text">{detail.active === 1? 'Yes':'No'}</span>
                </Col>
            </Row>
        </div>
    );

    const getDataSource = (datalist) => (datalist && Array.isArray(datalist) ? datalist : []);

    const [providercolumns, setColumnsProvidercolumns] = useState([
        {
            dataIndex: "status",
            key: "status",
            title: t("contents.descriptors.tabs.table.status"),
            fixed: "left",
            width: 110,
            sorter: {
                compare: (a, b) => a.status - b.status,
            },
            sortOrder: SortOrder(sortedInfo, "status"),
        },
        {
            dataIndex: "status_reason",
            key: "status_reason",
            title: t("contents.descriptors.tabs.table.status_reason"),
            width: 200,
            ellipsis: true,
        },
        {
            dataIndex: "requested_date",
            key: "requested_date",
            title: t("contents.descriptors.tabs.table.requested_date"),
            width: 120,
            render: (requested_date) =>( requested_date == null ? '': moment(requested_date).format("ll")),
            sorter: {
                compare: (a, b) => moment(a.requested_date).diff(moment(b.requested_date)),
            },
            sortOrder: SortOrder(sortedInfo, "requested_date"),
        },
        {
            dataIndex: "providers",
            key: "providers",
            title: t("contents.descriptors.tabs.table.providername"),
            width: 130,
            sorter: {
                compare: (a, b) => a.providers.localeCompare(b.providers),
            },
            sortOrder: SortOrder(sortedInfo, "providers"),
        },
        {
            dataIndex: "enrollment_date",
            key: "enrollment_date",
            title: t("contents.descriptors.tabs.table.enrollment_date"),
            width: 120,
            render: (enrollment_date) => (enrollment_date == null ? '' : moment(enrollment_date).format("ll")),
            sorter: {
                compare: (a, b) => moment(a.enrollment_date).diff(moment(b.enrollment_date)),
            },
            sortOrder: SortOrder(sortedInfo, "enrollment_date"),
        }
    ]);

    const [providerHistorycolumns, setColumnsProviderHistorycolumns] = useState([
        {
            dataIndex: "status",
            key: "status",
            title: t("contents.descriptors.tabs.table.status"),
            fixed: "left",
            width: 110
        },
        {
            dataIndex: "providername",
            key: "providername",
            title: t("contents.descriptors.tabs.table.providername"),
            width: 130
        },
        {
            dataIndex: "date",
            key: "date",
            title: t("contents.descriptors.tabs.table.date"),
            width: 120,
            render: (date) => (date == null ? '' : moment(date).format("ll"))
        }
    ]);

    const [gatewaycolumns, setColumnsGatewaycolumns] = useState([
        {
            dataIndex: "name",
            key: "name",
            title: t("contents.descriptors.tabs.table.gatewayname"),
            fixed: "left",
            width: 110,
            sorter: {
                compare: (a, b) => a.name - b.name,
            },
            sortOrder: SortOrder(sortedInfo, "name"),
        },
        {
            dataIndex: "descriptor",
            key: "descriptor",
            title: t("contents.descriptors.tabs.table.gatewaydescriptor"),
            width: 200,
            sorter: {
                compare: (a, b) => a.descriptor - b.descriptor,
            },
            sortOrder: SortOrder(sortedInfo, "descriptor"),
        },
        {
            dataIndex: "terminalid",
            key: "terminalid",
            title: t("contents.descriptors.tabs.table.terminalid"),
            width: 200,
            sorter: {
                compare: (a, b) => a.terminalid - b.terminalid,
            },
            sortOrder: SortOrder(sortedInfo, "terminalid"),
        }       
    ]);

    const [bincaidcolumns, setColumnsBincaidcolumns] = useState([
        {
            dataIndex: "bin",
            key: "bin",
            title: t("contents.descriptors.tabs.table.bin"),
            fixed: "left",
            width: 110,
            sorter: {
                compare: (a, b) => a.bin - b.bin,
            },
            sortOrder: SortOrder(sortedInfo, "bin"),
        },
        {
            dataIndex: "caid",
            key: "caid",
            title: t("contents.descriptors.tabs.table.caid"),
            width: 200,
            sorter: {
                compare: (a, b) => a.caid - b.caid,
            },
            sortOrder: SortOrder(sortedInfo, "caid"),
        },
        {
            dataIndex: "status",
            key: "status",
            title: t("contents.descriptors.tabs.table.status"),
            width: 200,
            sorter: {
                compare: (a, b) => a.status - b.status,
            },
            sortOrder: SortOrder(sortedInfo, "status"),
        }
       
    ]);

    const getDetails = () => {
        let Details = data.detail ? data.detail : [];
        return showInfo(Details);
    }

    const mergeColumnsProvidercolumns = providercolumns.map((col, index) => ({
        ...col,
        onHeaderCell: (column) => ({
            width: column.width,
            onResize: (_, { size }) => {
                const newColumns = [...providercolumns];
                newColumns[index] = { ...newColumns[index], width: size.width };
                setColumnsProvidercolumns(newColumns);
            },
        }),
    }));

    const mergeColumnsProviderHistorycolumns = providerHistorycolumns.map((col, index) => ({
        ...col,
        onHeaderCell: (column) => ({
            width: column.width,
            onResize: (_, { size }) => {
                const newColumns = [...providerHistorycolumns];
                newColumns[index] = { ...newColumns[index], width: size.width };
                setColumnsProviderHistorycolumns(newColumns);
            },
        }),
    }));

    const mergeColumnsGatewaycolumns = gatewaycolumns.map((col, index) => ({
        ...col,
        onHeaderCell: (column) => ({
            width: column.width,
            onResize: (_, { size }) => {
                const newColumns = [...gatewaycolumns];
                newColumns[index] = { ...newColumns[index], width: size.width };
                setColumnsGatewaycolumns(newColumns);
            },
        }),
    }));

    const mergeColumnsBincaidcolumns = bincaidcolumns.map((col, index) => ({
        ...col,
        onHeaderCell: (column) => ({
            width: column.width,
            onResize: (_, { size }) => {
                const newColumns = [...bincaidcolumns];
                newColumns[index] = { ...newColumns[index], width: size.width };
                setColumnsBincaidcolumns(newColumns);
            },
        }),
    }));

    useEffect(() => {
        // Moved getDescriptorDetail function inside useEffect by Jericho 2023/05/19 to avoid ESLint warning of missing dependency and excessive hook calls
        const getDescriptorDetail = async () => {        
            const api_url = API_TXALERT + `/descriptor/${descriptorid}/details`;
    
            setLoading(true);
            await axios
                .post(api_url, {}, { headers: { Authorization: "Bearer " + localStorage.getItem("access_token") } })
                .then(({ data }) => {
                    setData(data);                
                    setLoading(false);
                })
                .catch((error) => console.log(error));
        };

        getDescriptorDetail();
    }, [descriptorid]);

    return (
        <>
            <PageHeader className="site-page-header" title={t("contents.alerts.descriptor")} subTitle={`(${descriptorid})`}></PageHeader>
            <Tabs type="card" defaultActiveKey="tab-details" className="custom-tabs-card">
                <TabPane tab={t("contents.descriptors.tabs.details")} key="tab-details">
                    <Card className="details-info--card" loading={loading}>
                        <Row gutter={0}>
                            <Col span="8">
                                 { getDetails() }
                            </Col>
                        </Row>
                    </Card>
                </TabPane>
                <TabPane tab={t(`contents.descriptors.tabs.enrollment`)} key="enrollment">
                    <Table key="table-provider" size="small" 
                        rowKey="enrollmentid" 
                        components={{
                            header: {
                                cell: ColumnResizable,
                            },
                        }}
                        columns={mergeColumnsProvidercolumns}
                        dataSource={getDataSource(data.providers)} 
                        pagination={false}></Table>
                    <h3 style={{ marginTop: 15 }}>{t(`contents.descriptors.tabs.enrollment_history`)}</h3>
                    <Table key="table-provider-history" 
                        size="small" 
                        rowKey="id" 
                        components={{
                            header: {
                                cell: ColumnResizable,
                            },
                        }}
                        columns={mergeColumnsProviderHistorycolumns}
                        dataSource={getDataSource(data.providersHistory)} 
                        pagination={false}></Table>
                </TabPane>
                { data.gateways ?
                <TabPane tab={t(`contents.descriptors.tabs.expressresolve`)} key="expressresolve">
                    <Table key="table-gw" 
                        size="small" 
                        rowKey="pgid" 
                        components={{
                            header: {
                                cell: ColumnResizable,
                            },
                        }}
                        columns={mergeColumnsGatewaycolumns}
                        dataSource={getDataSource(data.gateways)} 
                        pagination={false}></Table>
                </TabPane>
                    : ''
                }
                { data.bincaids ?
                <TabPane tab={t(`contents.descriptors.tabs.rdr`)} key="bincaid">
                    <Table key="table-bincaid" 
                        size="small" 
                        rowKey="bcdid" 
                        components={{
                            header: {
                                cell: ColumnResizable,
                            },
                        }}
                        columns={mergeColumnsBincaidcolumns} 
                        dataSource={getDataSource(data.bincaids)} 
                        pagination={false}></Table>
                </TabPane>
                    : ''
                }
            </Tabs>
        </>
    );
};

export default Descriptor;
