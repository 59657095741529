import React, { useEffect, useState } from 'react'
import { Modal } from 'antd';
import { Form } from 'antd';
import { Input } from 'antd';
import { Select } from 'antd';
import { InputNumber } from 'antd';
import { DatePicker } from 'antd';
import { Row } from 'antd';
import { Col } from 'antd';
import { Button } from 'antd';
import axios from 'axios';
import { API_TX, TXSHIELD_SECURE_BASE_URL } from '../../config/config';
import Swal from 'sweetalert2';
import CatchError from '../../helper/CatchError';
import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import localeData from 'dayjs/plugin/localeData'
import weekday from 'dayjs/plugin/weekday'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import weekYear from 'dayjs/plugin/weekYear'

dayjs.extend(customParseFormat)
dayjs.extend(advancedFormat)
dayjs.extend(weekday)
dayjs.extend(localeData)
dayjs.extend(weekOfYear)
dayjs.extend(weekYear)

const { Option } = Select;

const AddPaymentLinkModal = (props) => {

  const { t, isAddPaymentLinkModalVisible, setIsAddPaymentLinkModalVisible, siteList, currencyList, countryList, reloadTable, paymentLink } = props;
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState();
  
  useEffect(() => {
    form.setFieldsValue({
      sid: paymentLink?.sid ?? null,
      amount: paymentLink?.amount ?? null,
      currency: paymentLink?.currency ?? null,
      payment_description: paymentLink?.payment_description ?? null,
      expiry_date: paymentLink?.expiry_date ? dayjs(paymentLink?.expiry_date, 'YYYY-MM-DD') : dayjs(),
      first_name: paymentLink?.first_name ?? null,
      last_name: paymentLink?.last_name ?? null,
      email: paymentLink?.email ?? null,
      phone: paymentLink?.phone ?? null,
      address: paymentLink?.address ?? null,
      city: paymentLink?.city ?? null,
      state: paymentLink?.state ?? null,
      country: paymentLink?.country ?? null,
    });

    if(paymentLink) {
      form.setFieldsValue({
        status: paymentLink?.payment_status ,
        link: TXSHIELD_SECURE_BASE_URL + '/makeapayment.php?link=' + paymentLink.link_text,
        txid: paymentLink.txid
      })
    }
  }, [paymentLink, form])

  const handleCancel = () => {
    setIsAddPaymentLinkModalVisible(false);
  }

  const handleSubmit = () => {
    setIsLoading(true);
    form.validateFields().then((values) => {
      values.expiry_date = values.expiry_date.format('YYYY-MM-DD');
      const apiUrl = API_TX + '/paymentlink/create';
      axios
        .post(apiUrl, values, { headers: { Authorization: "Bearer " + localStorage.getItem("access_token") } })
        .then(({ data }) => {
          if (data?.link) {
            Swal.fire({
              icon: 'success',
              title: 'Link Created',
              text: data.link,
              showCancelButton: true,
              cancelButtonText: 'COPY URL',
              showConfirmButton: true,
              reverseButtons: true
            }).then((result) => {
              if (result.isDismissed) {
                navigator.clipboard.writeText(data.link);
              }
            });
            form.resetFields();
            form.setFieldsValue({
              expiry_date: dayjs(),
            });
            reloadTable();
            setIsLoading(false);
          } else {
            setIsLoading(false);
            CatchError(' Unable to create link. ')
          }
        }).catch((error) => {
          console.log(error);
          setIsLoading(false);
          CatchError(' Unable to create link. ')
        })
    }).catch((info) => {
      setIsLoading(false);
    })

  }

  return (
    <Modal
      forceRender={true}
      title={t("contents.paymentlink.new_paymentlink")}
      width="720"
      style={{
        maxWidth: "720px"
      }}
      visible={isAddPaymentLinkModalVisible}
      onOk={handleSubmit}
      okText={"Submit"}
      onCancel={handleCancel}
      footer={[
        <Button key="back" onClick={() => handleCancel()}>
          {t("contents.paymentlink.form.cancel")}
        </Button>,
        ...!paymentLink ? [<Button key="submit" type="primary" onClick={handleSubmit} htmlType="submit" loading={isLoading}>
          {t("contents.paymentlink.form.submit")}
        </Button>] : []
      ]}
    >

      <Form  {...{
        labelCol: { span: 5 },
        wrapperCol: { span: 19 },
        layout: "horizontal",
        size: "middle",
        labelAlign: "right",
        labelWrap: false,
        requiredMark: true,
        colon: false,
        style: { padding: "15px 0" }
      }}
        form={form}
      >

        <Row justify="space-around">
          <Col span={24}>


            {
              paymentLink ? (
                <>
                  <Form.Item name="status" label={t("tables.fields.payment_status")} >
                    <Input style={{ width: "100%" }} disabled={true}  />
                  </Form.Item>
                  <Form.Item name="link" label={t("tables.fields.link_text")} >
                    <Input style={{ width: "100%" }} disabled={true} />
                  </Form.Item>
                  <Form.Item name="txid" label={t("tables.fields.txid")} >
                    <Input style={{ width: "100%" }} disabled={true} />
                  </Form.Item>
                </>
              )
                : null
            }



            <Form.Item name="sid" label={t("contents.paymentlink.form.sid")} rules={[...!paymentLink ? [{ required: true, message: t('required_field') }] : []]}>

              <Select
                disabled={!paymentLink ? false : true}
                placeholder={t('contents.paymentlink.form.please_select_site')}
                showSearch
                optionFilterProp="children"
              >
                {
                  siteList
                    ? siteList.map((item) => (
                      <Option key={item.sid} value={item.sid}>
                        {item.name + ` (sid: ` + item.sid + `)`}
                      </Option>
                    ))
                    : null
                }
              </Select>
            </Form.Item>


            <Form.Item name="amount" label={t('contents.paymentlink.form.amount')} rules={[...!paymentLink ? [{ required: true, message: t('required_field') }] : []]}>

              <InputNumber step="0.01" stringMode style={{ width: "100%" }} disabled={!paymentLink ? false : true} />
            </Form.Item>

            <Form.Item name="currency" label={t('contents.paymentlink.form.currency')} rules={[...!paymentLink ? [{ required: true, message: t('required_field') }] : []]}>
              <Select
                showSearch
                disabled={!paymentLink ? false : true}
                optionFilterProp="children"
                placeholder={t('contents.paymentlink.form.please_select_currency')}
              >
                {currencyList
                  ? Object.keys(currencyList).map((key) => (
                    <Option key={key} value={key}>
                      {currencyList[key]}
                    </Option>
                  ))
                  : null}
              </Select>
            </Form.Item>

            <Form.Item name="payment_description" label={t('contents.paymentlink.form.payment_description')} rules={[...!paymentLink ? [{ required: true, message: t('required_field') }] : []]}>
              <Input disabled={!paymentLink ? false : true} />
            </Form.Item>

            <Form.Item name="expiry_date" label={t('contents.paymentlink.form.expiry_date')} rules={[...!paymentLink ? [{ required: true, message: t('required_field') }] : []]}>
              <DatePicker format={'YYYY-MM-DD'} style={{ width: "100%" }} disabled={!paymentLink ? false : true}></DatePicker>
            </Form.Item>

            <h4>Personal Details</h4>

            <Form.Item name="first_name" label={t('contents.paymentlink.form.first_name')}>
              <Input disabled={!paymentLink ? false : true} />
            </Form.Item>

            <Form.Item name="last_name" label={t('contents.paymentlink.form.last_name')}>
              <Input disabled={!paymentLink ? false : true} />
            </Form.Item>

            <Form.Item name="email" label={t('contents.paymentlink.form.email')} >
              <Input disabled={!paymentLink ? false : true} />
            </Form.Item>

            <Form.Item name="phone" label={t('contents.paymentlink.form.phone')} >
              <Input disabled={!paymentLink ? false : true} />
            </Form.Item>

            <Form.Item name="address" label={t('contents.paymentlink.form.address')} >
              <Input disabled={!paymentLink ? false : true} />
            </Form.Item>

            <Form.Item name="city" label={t('contents.paymentlink.form.city')} >
              <Input disabled={!paymentLink ? false : true} />
            </Form.Item>

            <Form.Item name="state" label={t('contents.paymentlink.form.state')} >
              <Input disabled={!paymentLink ? false : true} />
            </Form.Item>

            <Form.Item name="country" label={t('contents.paymentlink.form.country')} >
              <Select
                showSearch
                optionFilterProp="children"
                placeholder={t('contents.paymentlink.form.please_select_country')}
                disabled={!paymentLink ? false : true}
              >
                <Option key={'country-default'} value="" style={{ color: '#CDCDE0' }}>{t('contents.paymentlink.form.please_select_country')}</Option>
                {
                  countryList
                    ? countryList.map((item) => (
                      <Option key={item.name} value={item.abrv_two}>
                        {item.name}
                      </Option>
                    ))
                    : null
                }
              </Select>

            </Form.Item>

          </Col>
        </Row>


      </Form>


    </Modal>
  )
}

export default AddPaymentLinkModal