import React from "react";
import { Table, Card } from "antd";

const CommunicationDetails = (props) => {
    const { t, data } = props; // eslint-disable-line

    const columns = [
        {
            dataIndex: "init_date",
            key: "init_date",
            title: t('tables.fields.date'),
        },
        {
            dataIndex: "comm_type",
            key: "comm_type",
            title: t('tables.fields.type'),
        },
        {
            dataIndex: "comm_to",
            key: "comm_to",
            title: t('tables.fields.to'),
        },
        {
            dataIndex: "ack_date",
            key: "ack_date",
            title: `ACK ${t('tables.fields.date')}`,
        },
        {
            dataIndex: "message_action",
            key: "message_action",
            title: t('tables.fields.action'),
        },
        {
            dataIndex: "status",
            key: "status",
            title: t('tables.fields.status'),
        },
    ];

    const getList = () => (
        <div className="details-info--table--wrap">
            <Table size="small" columns={columns} rowKey="communicationid" pagination={false} dataSource={data} />
        </div>
    );

    const getMessage = () => (
        <div className="details-info--text">
            <p>{t("contents.transactions.form.no_communication_information")}</p>
        </div>
    );

    return (
        <Card className="details-info--card">
            <h4 className="details-info--title">{t("contents.transactions.form.communication_details")}</h4>
            {Array.isArray(data) ? getList() : getMessage()}
        </Card>
    );
};

export default CommunicationDetails;
