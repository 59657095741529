import React, { useState, useEffect } from "react";
import MerchantDetails from "./Details/MerchantDetails"
import RetailerDetails from "./Details/RetailerDetails"
import SiteDetails from "./Details/SiteDetails"
import { API_MERCHANT } from "../../config/config";
import axios from "axios";
import RetailerSiteSelector from "./Details/RetailerSiteSelector"
import { PageHeader } from "antd";
import OnBoardingDetails from "./Details/OnBoardingDetails";

const Merchant = (props) => {
    const { t } = props;    
    const mid = props.match.params.id;
    const [merchantDetails, setMerchantDetails] = useState(null);
    const [retailerDetails, setRetailerDetails] = useState(null);
    const [siteDetails, setSiteDetails] = useState(null);
    const [isLoading, setIsLoading] = useState(false);    
    const [error, setError] = useState(false);// eslint-disable-line

    useEffect(() => {
        if(!merchantDetails) {
            getMerchantDetails();
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const getMerchantDetails = async () => {
        const api_url = API_MERCHANT + `/${mid}/details`;
        await axios
            .post(api_url, {}, { headers: { Authorization: "Bearer " + localStorage.getItem("access_token") } })
            .then(({ data }) => {                
                if(data.status === 'EXC')
                {
                    setError(data.error_msg.details);
                }else{
                setMerchantDetails(data);
                }
            })
            .catch((error) => {
                console.log(error);
                setError(error.response?error.response.data.error_msg.details:error.message);
            });
    };

    return merchantDetails ? (
        <>
            <PageHeader className="site-page-header" title={`Merchant - ${merchantDetails.name}`}></PageHeader>
            {
                merchantDetails.status === 'onboarding' ? <>
                    <OnBoardingDetails typeforms={ merchantDetails.typeforms }/>
                </> :
                <>
                    <RetailerSiteSelector t={t} mid={mid} setRetailerDetails={setRetailerDetails} setSiteDetails={setSiteDetails} setIsLoading={setIsLoading}/>
                    <MerchantDetails t={t} merchantDetails={merchantDetails}/>
                    { retailerDetails ? <RetailerDetails t={t} retailerDetails={retailerDetails}/> : null }
                    {siteDetails ? <SiteDetails t={t} siteDetails={siteDetails} retailerDetails={retailerDetails} isLoading={isLoading} setIsLoading={setIsLoading} /> : null}
                </>
            }
        </>
    ) : (<>Error:{ error}</>);
};

export default Merchant;
