import React, { useState, useEffect } from "react";
import axios from "axios";
import { Select, Checkbox, Space, Divider } from "antd";
import { API_MERCHANT } from "../config/config";
import GroupAccess from "./../helper/GroupAccess";
import htmlParser from "html-react-parser";

const { Option } = Select;
const MERCHANT_DATA_URL = API_MERCHANT + "/list";


const MerchantList = (props) => {

    const [data, setData] = useState([]);
    const [inactiveChecked, setInactiveChecked] = useState(false);


    const fetchdata = async () => {
        try {

            const response = await axios.post(
                MERCHANT_DATA_URL,
                {getall:true},
                { headers: { Authorization: "Bearer " + localStorage.getItem("access_token"), Authuser: localStorage.getItem("authuser") } }
            );

            setData(response.data.list);
        } catch (error) {
            return ({ data: false });
        }

    };

    useEffect(() => {
        if (data.length === 0) {
            fetchdata();
        }
    }, [data]);


    const onChangeInactive = (e) => {
        setInactiveChecked(e.target.checked);
    };

    const getMerchant = () => {
        if (inactiveChecked) {
            return data.map((item) => (
                item.status === 'active' ?
                    <Option key={item.mid} value={item.mid}>
                        {htmlParser(item.name) + ` (mid: ` + item.mid + `)`}
                    </Option>
                    : <Option key={item.mid} value={item.mid} style={{ color: "#f5222d" }}>
                        {htmlParser(item.name) + ` (mid: ` + item.mid + `)`}
                    </Option>
            ))
        } else {
            return data.map((item) => (
                item.status === 'active'
                    ?
                    <Option key={item.mid} value={item.mid}>
                        {htmlParser(item.name) + ` (mid: ` + item.mid + `)`}
                    </Option>
                    :
                    null
            ))
        }
    };

    return (

        <Select
            showSearch
            mode="multiple"
            showArrow="true"
            maxTagCount="responsive"
            optionFilterProp="children"
            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
            filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
            dropdownRender={(menu) => GroupAccess("SYSADMIN") ? (
                <>
                    {menu}

                    <Divider
                        style={{
                            margin: '8px 0',
                        }}
                    />
                    <Space
                        style={{
                            padding: '0 8px 4px',
                        }}
                    >
                        <Checkbox onChange={onChangeInactive}>Show Inactive?</Checkbox>
                    </Space>
                </>
            ) : (
                <>
                    {menu}
                </>
            )}
            {...props}
        >    {data
            ? getMerchant()
            : null}</Select>
    );
};

export default MerchantList;
