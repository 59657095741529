import React from "react";
import { Form, Input } from "antd";
// import { Select } from "antd";
// const { Option } = Select;
const { TextArea } = Input;

const ContactForm = (props) => {
    const { t, form, layout, data } = props;

    return (
        <Form {...layout} form={form} style={{ padding: "15px 0" }}>
            <Form.Item label={t("contents.profile.form.2fa_option")}>
                {/* <Select>
                    <Option key="otp" value="otp">
                        {t("contents.profile.form.authenticator_app")}
                    </Option>
                    <Option key="email" value="email">
                        {t("contents.profile.form.email")}
                    </Option>
                </Select> */}
                <Input value={data["2fa_option"] === "otp" ? t("contents.profile.form.authenticator_app") : t("contents.profile.form.email")} readOnly />
            </Form.Item>
            {data["2fa_option"] === "otp" ? (
                <Form.Item name="2fa_secret" label={t("contents.profile.form.secret_key")}>
                    <TextArea autoSize={true} readOnly />
                </Form.Item>
            ) : null}
        </Form>
    );
};

export default ContactForm;
