import React from "react";

const StatusText = (status, t = null) => {
    const getColor = (status) => {
        switch (status) {
            case "active":
                return "#10B759";
            default:
                return "#ff4d4f";
        }
    };

    return status ? <span style={{ color: getColor(status) }}>{status.toUpperCase() === "DEACTIVATED" ? "INACTIVE" : status.toUpperCase()}</span> : null;
};

export default StatusText;
