const TextSorter = (a, b) => {
    // Check if 'a' or 'b' is null or undefined
    if (a === null || typeof a === 'undefined') {
      return -1; // 'a' is considered smaller, so it comes first
    }
    
    if (b === null || typeof b === 'undefined') {
      return 1; // 'b' is considered smaller, so it comes first
    }
  
    // Compare 'a' and 'b' using localeCompare
    return a.localeCompare(b);
  };

  export default TextSorter;