import React, { useState, useEffect } from "react";
import { API_TXALERT } from "../../config/config";
import { Modal, Button, Form, Input, Table, Tabs } from "antd";
import axios from "axios";
import Swal from "sweetalert2";
import moment from "moment";

const { TabPane } = Tabs;

const BinCaidModal = (props) => {
    const { t, bcdid, isModalVisible, handleCancel } = props;
    const [bincaid, setBincaid] = useState(null);
    const [loading, setLoading] = useState(false);

    const layout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };

    // const tailLayout = {
    //     wrapperCol: { offset: 8, span: 16 },
    // };

    const initData = {
        bin: "",
        caid: "",
        mcc: "",
        tier: "",
        vrol_profile: "",
        descriptor: "",
        request_date: "",
        update_date: "",
        status: "",
        status_reason: "",
        reference_no: "",
    };

    const [form] = Form.useForm();

    const handleOk = () => {
        setLoading(false);
        handleCancel();
    };

    const formValChange = (chgV, allV) => {
        setBincaid({ ...bincaid, ...allV });
    };

    useEffect(() => {
        if (bcdid) {
            setBincaid(initData);
            getBincaidDetail();
           
        }
    }, [bcdid]); // eslint-disable-line

    const getBincaidDetail = () => {
        const apiUrl = API_TXALERT + `/bincaid/${bcdid}/details/`;
        axios
            .post(apiUrl, {}, { headers: { Authorization: "Bearer " + localStorage.getItem("access_token") } })
            .then(({ data }) => {
                if (data.request_date) {
                    data.request_date = moment(data.request_date).format("ll");
                }
                if (data.update_date) {
                    data.update_date = moment(data.update_date).format("ll");
                }
                if (data.enrollment_history){
                    for (let i = 0; i < data.enrollment_history.length; i++) {
                        if (data.enrollment_history[i].date){
                            data.enrollment_history[i].date = moment(data.enrollment_history[i].date).format("ll");
                        }
                    }
                }

                setBincaid(data);
                form.setFieldsValue(data);
            })
            .catch((error) => {
                Swal.fire({
                    icon: "error",
                    title: error.response ? error.response.data.msg : t("login.dialogs.catch"),
                    showConfirmButton: true,
                });
            });

            
    };

    const historyColumns = [
        {
            dataIndex: "status",
            key: "status",
            title: t("tables.fields.status"),
        },
        {
            dataIndex: "date",
            key: "date",
            title: t("tables.fields.datetime"),
        }
    ];


    const rulesethistoryColumns = [
        {
            dataIndex: "ruleset",
            key: "ruleset",
            title: t("tables.fields.ruleset"),
        },
        {
            dataIndex: "date",
            key: "date",
            title: t("tables.fields.datetime"),
        }
    ];

    return (
        <>
            <Modal
                title={t("contents.bincaids.view_bincaid")}
                visible={isModalVisible}
                width={600}
                onCancel={handleCancel}
                onOk={handleOk}
                bodyStyle={{ paddingTop: '0px' }}
                footer={[
                    <Button key="submit" type="primary" loading={loading} onClick={() => handleOk()}>
                        {t("tables.actions.ok")}
                    </Button>,
                ]}
            >
                <Tabs>
                    <TabPane tab={t("contents.bincaids.details")} key="bincaid_detail">
                        <Form {...layout} form={form} name="view_bincaid" onValuesChange={formValChange}>
                            <Form.Item name="bin" label={t("contents.bincaids.form.bin")}>
                                <Input readOnly />
                            </Form.Item>
                            <Form.Item name="caid" label={t("contents.bincaids.form.caid")}>
                                <Input readOnly />
                            </Form.Item>
                            <Form.Item name="mcc" label={t("contents.bincaids.form.mcc")}>
                                <Input readOnly />
                            </Form.Item>
                            <Form.Item name="tier" label={t("contents.bincaids.form.tier")}>
                                <Input readOnly />
                            </Form.Item>
                            <Form.Item name="vrol_profile" label={t("contents.bincaids.form.vrol_profile")}>
                                <Input readOnly />
                            </Form.Item>
                            <Form.Item name="descriptor" label={t("contents.bincaids.form.descriptor")}>
                                <Input readOnly />
                            </Form.Item>
                            <Form.Item name="request_date" label={t("contents.bincaids.form.request_date")}>
                                <Input readOnly />
                            </Form.Item>
                            <Form.Item name="update_date" label={t("contents.bincaids.form.update_date")}>
                                <Input readOnly />
                            </Form.Item>
                            <Form.Item name="status" label={t("contents.bincaids.form.status")}>
                                <Input readOnly />
                            </Form.Item>
                            <Form.Item name="status_reason" label={t("contents.bincaids.form.status_reason")}>
                                <Input readOnly />
                            </Form.Item>
                            <Form.Item name="reference_no" label={t("contents.bincaids.form.reference_no")}>
                                <Input readOnly />
                            </Form.Item>
                        </Form>
                    </TabPane>
                    <TabPane tab={t("contents.bincaids.enrollment_history")} key="enrollment_history">
                        <Table
                            rowKey="id"
                            size="small"
                            bordered={false}
                            columns={historyColumns}
                            dataSource={bincaid ? bincaid.enrollment_history ? bincaid.enrollment_history : [] : []}
                            pagination={false}
                        />
                    </TabPane>
                   {bincaid && bincaid.ruleset_history && bincaid.ruleset_history.length > 0 ?(
                    <TabPane tab={t("contents.bincaids.ruleset_history")} key="ruleset_history">
                        <Table
                            rowKey="id"
                            size="small"
                            bordered={false}
                            columns={rulesethistoryColumns}
                            dataSource={bincaid ? bincaid.ruleset_history ? bincaid.ruleset_history : [] : []}
                            pagination={false}
                        />
                    </TabPane>):null
                    }
                </Tabs>
            </Modal>
        </>
    );
};

export default BinCaidModal;
