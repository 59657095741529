import React, { useState, useEffect} from "react";
import DataFetchHookV3 from "../../helper/DataFetchHookV3";
import { Table, PageHeader, Card, Form, Row, Button, Col, Input, Select, Space } from "antd";
import { SearchOutlined, FileSearchOutlined } from "@ant-design/icons";
import GroupAccess from "../../helper/GroupAccess";
import ExportModalV3 from "../../helper/ExportModalV3";
import CustomPaginationV2 from "../../helper/Table/CustomPaginationV2";
import SortOrder from "../../helper/Table/SortOrder";
import { API_TXALERT} from "../../config/config";
import moment from "moment";
import BinCaidModal from "./BinCaid";
import ColumnResizable from "../../helper/Table/ColumnResizable";
import MerchantList from "./../../components/MerchantList";
import RetailerList from "./../../components/RetailerList";
import SiteList from "./../../components/SiteList";
import ResellerList from "./../../components/ResellerList";

const DATA_URL = API_TXALERT + "/bincaid";
const DATA_URL_EXPORT = API_TXALERT + "/bincaid/reports";

const { Option } = Select;

const BinCaidDescriptors = (props) => {
    // eslint-disable-next-line
    const [{ data, totalResult, isLoading, requestData,isUpdated }, doFetch, setRequestData] = DataFetchHookV3(DATA_URL,"POST", {
        size: 20,
        page: 1,
        order: "DESC"
    });


    const [midSelect, setMidSelect] = useState('');
    const [ridSelect, setRidSelect] = useState('');
    const [formData, setFormData] = useState();
    const [bincaid, setBincaid] = useState(null);
    const [sortedInfo, setSortedInfo] = useState({});
    const [currentPage, setCurrentPage] = useState(1);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const { t } = props;

    const initFormData = {
        keyword: "",
        field: null,
        order: null,
        merchant: [],
        retailer: [],
        site: [],
        reseller: [],
        provider: [],
        bank_mid:"",
        status:['enrolled','new','requested','pending','disabled','blank']
    };

    const [form] = Form.useForm();

    useEffect(() => {
        formValInit();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const handleChange = (pagination, filters, sorter, { currentDataSource }) => {
        setSortedInfo(sorter);
    };

    const onChangeRetailer = async (value) => {
        await setRidSelect(value)
    }

    const onChangeMerchant = async (value) => {
        await setMidSelect(value)
    }

    const clearAll = () => {
        setRidSelect('');
        setMidSelect('');
        setCurrentPage(1);
        setFormData(initFormData);
        setRequestData({ ...requestData, ...initFormData, startDate: null, endDate: null, page: 1 });
        form.setFieldsValue(initFormData);
    };

    const formValInit = () => {
        setFormData(initFormData);
        form.setFieldsValue(initFormData);
        
    };

    const formValChange = (chgV, allV) => {
        setFormData(allV);
    };

    const onFinish = () => {
        let searchData = {};

        if (formData) {
            searchData = {
                field: formData.field,
                keyword: formData.keyword ? formData.keyword.trim() : null,
                order: formData.order,
                mid: formData.merchant,
                rid: formData.retailer,
                sid: formData.site,
                reseller:formData.reseller,
                status:formData.status
            };
        }
        // Reset Page Index to 1
        setCurrentPage(1);
        setRequestData({ ...requestData, ...searchData, page: 1 });
    };

    const showBinCaid = (bcdid) => {
        setBincaid(bcdid);
        setIsModalVisible(true);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };


    const exportHeadersOptional = [];
    const exportHeaders = [
        { label: "Bank MID", key: "ad.bank_mid" },
        { label: "BIN", key: "bin" },
        { label: "CAID", key: "caid" },
        { label: "Descriptor", key: "ad.descriptor" },
        { label: "Descriptor ID", key: "ad.descriptor_id" },
        { label: "ID", key: "bcdid" },
        { label: "MCC", key: "bcd.mcc" },
        { label: "Reference Number", key: "reference_no" },
        { label: "Requested Date", key: "DATE_FORMAT(request_date, '%b %d, %Y')" },
        { label: "Ruleset", key: "bcd.ruleset" },
        { label: "SID", key: "rs.sid" },
        { label: "Site Name", key: "rs.name" },
        { label: "Status", key: "bcd.status" },
        { label: "Status Reason", key: "status_reason" },
        { label: "Tier", key: "mt.tier" },
        { label: "Updated Date", key: "DATE_FORMAT(update_date, '%b %d, %Y')" },
        { label: "VROL Profile", key: "vrol_profile" },
    ];
    
    const additional_options = [
        { label: "Show BLANK status", key: "show_blank" }
    ];

    const columns = [
        {
            dataIndex: "bin",
            key: "bin",
            title: t("tables.fields.bin"),
            width: 120,
            ellipsis: true,
            sorter: {
                compare: (a, b) => a.bin.localeCompare(b.bin),
            },
            sortOrder: SortOrder(sortedInfo, "bin"),
        },
        {
            dataIndex: "caid",
            key: "caid",
            title: t("tables.fields.caid"),
            width: 180,
            ellipsis: true,
            sorter: {
                compare: (a, b) => a.caid.localeCompare(b.caid),
            },
            sortOrder: SortOrder(sortedInfo, "caid"),
        },
        {
            dataIndex: "mcc",
            key: "mcc",
            title: t("tables.fields.mcc"),
            width: 100,
            ellipsis: true,
            sorter: {
                compare: (a, b) => (a.mcc || "").localeCompare(b.mcc || ""),
            },
            sortOrder: SortOrder(sortedInfo, "mcc"),
        },
        {
            dataIndex: "tier",
            key: "tier",
            title: t("tables.fields.tier"),
            width: 100,
            ellipsis: true,
            sorter: {
                compare: (a, b) => (a.tier || "").localeCompare(b.tier || ""),
            },
            sortOrder: SortOrder(sortedInfo, "tier"),
        },
        {
            dataIndex: "descriptor",
            key: "descriptor",
            title: t("tables.fields.descriptor"),
            width: 250,
            ellipsis: true,
            sorter: {
                compare: (a, b) => a.descriptor.localeCompare(b.descriptor),
            },
            sortOrder: SortOrder(sortedInfo, "descriptor"),
        },
        {
            dataIndex: "bank_mid",
            key: "bank_mid",
            title: t("tables.fields.bank_mid"),
            width: 100,
            ellipsis: true,
            sorter: {
                compare: (a, b) => (a.bank_mid || "").localeCompare(b.bank_mid || ""),
            },
            sortOrder: SortOrder(sortedInfo, "bank_mid"),
        },
        {
            dataIndex: "status",
            key: "status",
            title: t("tables.fields.status"),
            width: 100,
            ellipsis: true,
            sorter: {
                compare: (a, b) => (a.status || "").localeCompare(b.status || ""),
            },
            sortOrder: SortOrder(sortedInfo, "status"),
        },
        {
            dataIndex: "ruleset",
            key: "ruleset",
            title: t("tables.fields.ruleset"),
            width: 100,
            ellipsis: true,
            sorter: {
                compare: (a, b) => (a.ruleset || "").localeCompare(b.ruleset || ""),
            },
            sortOrder: SortOrder(sortedInfo, "ruleset"),
        },
        {
            dataIndex: "action",
            title: t("tables.fields.action"),
            align: "left",
            width: 100,
            render: (text, record) => (
                <Space size="middle">
                    <Button type="link" onClick={() => showBinCaid(record.bcdid)}>
                        <FileSearchOutlined style={{ color: "rgba(0, 0, 0, 0.85)", fontSize: "18px" }} />
                    </Button>
                </Space>
            ),
        },
    ];

    const AdvancedSearchForm = () => (
        <div className="search-wrap">
            <Card title={t("tables.actions.search")} size="small">
                <Form form={form} size="large" name="advanced_search" className="ant-advanced-search-form" onValuesChange={formValChange} onFinish={onFinish}>
                    <Row gutter={[16, 16]}>
                      
                       <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }} xl={{ span: 5 }}>
                                <Form.Item name="merchant">
                                 <MerchantList placeholder={t("contents.merchants.title")} onChange={onChangeMerchant}
                                    style={{ width: "100%" }} />
                                </Form.Item>
                       </Col>
                       <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                            <Form.Item name="retailer">
                                <RetailerList placeholder={t("contents.retailers.title")} mid={midSelect} onChange={onChangeRetailer}
                                    style={{ width: "100%" }} />
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                            <Form.Item name="site">
                                <SiteList placeholder={t("contents.sites.title")} mid={midSelect} rid={ridSelect}
                                    style={{ width: "100%" }} />
                            </Form.Item>
                        </Col>
                        {GroupAccess("SYSADMIN") ? (
                            <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                                <Form.Item name="reseller">
                                    <ResellerList placeholder={t("contents.resellers.title")}
                                        style={{ width: "100%" }} />
                                </Form.Item>
                            </Col>
                        ) : (
                            ""
                        )}
                        
                        </Row>
                        <Row gutter={[16, 16]}>
                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }} xl={{ span: 5 }}>

                            <Form.Item name="status">
                                <Select placeholder={t("contents.merchants.form.status")}
                                        maxTagCount={1}
                                        showSearch
                                        mode="multiple" 
                                        showArrow="true">
                                    <Option value="enrolled">ENROLLED</Option>
                                    <Option value="new">NEW</Option>
                                    <Option value="requested">REQUESTED</Option>
                                    <Option value="pending">PENDING</Option>
                                    <Option value="disabled">DISABLED</Option>
                                    <Option value="duplicate">DUPLICATE</Option>
                                    <Option value="blank">BLANK</Option>
                                </Select>

                            </Form.Item>
                        </Col>
                       
                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }} xl={{ span: 6 }}>
                            <Form.Item name="field">
                                <Select 
                                    showSearch
                                    style={{ width: "100%" }} 
                                    placeholder={t("tables.fields.field")}
                                    >
                                    <Option value="bin">{t("tables.fields.bin")}</Option>
                                    <Option value="caid">{t("tables.fields.caid")}</Option>
                                    <Option value="mcc">{t("tables.fields.mcc")}</Option>
                                    <Option value="tier">{t("tables.fields.tier")}</Option>
                                    <Option value="descriptor">{t("tables.fields.descriptor")}</Option>
                                    <Option value="bank_mid">{t("tables.fields.bank_mid")}</Option>
                                   </Select>
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }} xl={{ span: 6 }}>
                            <Form.Item name="keyword">
                                <Input placeholder={t("tables.fields.keyword")} />
                            </Form.Item>
                        </Col>
                        </Row>
                        <Row gutter={[16,16]}>
                        <Col flex="auto"></Col>
                        <Col
                            className="gutter-row"
                            xs={{ span: 24 }}
                            sm={{ span: 12 }}
                            md={{ span: 6, offset: 0 }}
                            lg={{ span: 4, offset: 16 }}
                            xl={{ span: 3, offset: GroupAccess("SYSADMIN|Merchant") ? 0 : 5 }}
                        >
                            <Form.Item>
                                <Button type="secondary" onClick={() => clearAll()} block>
                                    {t("tables.actions.clear_all")}
                                </Button>
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 4 }} xl={{ span: 4 }}>
                            <Form.Item>
                                <Button type="primary" htmlType="submit" block>
                                    <SearchOutlined /> {t("tables.actions.search")}
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Card>
        </div>
    );

    const getDataSource = (datalist) => (datalist && Array.isArray(datalist) ? datalist : []);

    const getHeaderTable = () => (
        <div className="custom-table--header d-flex justify-content-between align-items-center">
            <h3 className="table-title">{t("contents.bincaids.title")}</h3>
            <ExportModalV3
                t={t}
                keyname="export_all"
                title={t("tables.actions.export")}
                data={requestData}
                data_url={DATA_URL_EXPORT}
                headers={exportHeaders}
                fieldHeadersOptional={exportHeadersOptional}
                additional_options={GroupAccess("SYSADMIN") ? additional_options : []}
                filename={`bincaid_${moment().format("YYMMDD_HHmmss_SSSS")}.csv`}
                style={{ marginLeft: 8 }}
            />
        </div>
    );

    return (
        <>
            <PageHeader title={t("contents.bincaids.title")}></PageHeader>
            {AdvancedSearchForm()}
            <BinCaidModal t={t} bcdid={bincaid} isModalVisible={isModalVisible} handleCancel={handleCancel} />
            <Table
                scroll={{x:'100%'}}
                rowKey="bcdid"
                size="small"
                title={() => getHeaderTable()}
                loading={isLoading}
                className="tableOverflow table-v3 custom-pagination custom-pagination-v3"
                components={{
                    header: {
                        cell: ColumnResizable,
                    },
                }}
                columns={columns}
                dataSource={data.datalist && Array.isArray(data.datalist) ? getDataSource(data.datalist) : []}
                onChange={handleChange}
                pagination={CustomPaginationV2({ totalResult, currentPage, requestData, setRequestData, setCurrentPage })}
            />
        </>
    );
};

export default BinCaidDescriptors;
