import React from "react";

const BreakdownByStatus = (props) => {
    const { t, statsData, detailsBoxTitle } = props; // eslint-disable-line

    const getDetails = () => (
        <div className="table-v3">
            <div className="ant-table ant-table-small">
                <div className="ant-table-title">
                    <div className="custom-table--header d-flex justify-content-between align-items-center">
                        <h3 className="table-title">{t(`contents._3dsecure_stats.${detailsBoxTitle}`)}</h3>
                    </div>
                </div>
                <div className="ant-table-container">
                    <div className="ant-table-content">
                        <table style={{ tableLayout: "auto", width: "100%" }}>
                            <thead className="ant-table-thead">
                                <tr>
                                    <th className="ant-table-cell" width="20%">
                                        <div className="text-center">{t("contents._3dsecure_stats.short_status")}</div>
                                    </th>
                                    <th className="ant-table-cell" width="20%">
                                        <div className="text-center">{t("contents._3dsecure_stats.count")}</div>
                                    </th>
                                    <th className="ant-table-cell" width="60%">
                                        {t("contents._3dsecure_stats.explanation")}
                                    </th>
                                </tr>
                            </thead>
                            <tbody className="ant-table-tbody">
                                <tr className="ant-table-row ant-table-row-level-0">
                                    <td className="ant-table-cell" align="center">
                                        Y
                                    </td>
                                    <td className="ant-table-cell" align="center">
                                        {statsData.breakdownByStatus.y}
                                    </td>
                                    <td className="ant-table-cell">{t("contents._3dsecure_stats.status_explanation.y")}</td>
                                </tr>
                                <tr className="ant-table-row ant-table-row-level-0">
                                    <td className="ant-table-cell" align="center">
                                        A
                                    </td>
                                    <td className="ant-table-cell" align="center">
                                        {statsData.breakdownByStatus.a}
                                    </td>
                                    <td className="ant-table-cell">{t("contents._3dsecure_stats.status_explanation.a")}</td>
                                </tr>
                                <tr className="ant-table-row ant-table-row-level-0">
                                    <td className="ant-table-cell" align="center">
                                        C
                                    </td>
                                    <td className="ant-table-cell" align="center">
                                        {statsData.breakdownByStatus.c}
                                    </td>
                                    <td className="ant-table-cell">{t("contents._3dsecure_stats.status_explanation.c")}</td>
                                </tr>
                                <tr className="ant-table-row ant-table-row-level-0">
                                    <td className="ant-table-cell" align="center">
                                        U
                                    </td>
                                    <td className="ant-table-cell" align="center">
                                        {statsData.breakdownByStatus.u}
                                    </td>
                                    <td className="ant-table-cell">{t("contents._3dsecure_stats.status_explanation.u")}</td>
                                </tr>
                                <tr className="ant-table-row ant-table-row-level-0">
                                    <td className="ant-table-cell" align="center">
                                        R
                                    </td>
                                    <td className="ant-table-cell" align="center">
                                        {statsData.breakdownByStatus.r}
                                    </td>
                                    <td className="ant-table-cell">{t("contents._3dsecure_stats.status_explanation.r")}</td>
                                </tr>
                                <tr className="ant-table-row ant-table-row-level-0">
                                    <td className="ant-table-cell" align="center">
                                        N
                                    </td>
                                    <td className="ant-table-cell" align="center">
                                        {statsData.breakdownByStatus.n}
                                    </td>
                                    <td className="ant-table-cell">{t("contents._3dsecure_stats.status_explanation.n")}</td>
                                </tr>
                                <tr className="ant-table-row ant-table-row-level-0">
                                    <td className="ant-table-cell" align="center">
                                        {t("contents._3dsecure_stats.status_total")}
                                    </td>
                                    <td className="ant-table-cell" align="center">
                                        {statsData.breakdownByStatus.total}
                                    </td>
                                    <td className="ant-table-cell">{t("contents._3dsecure_stats.status_explanation.total")}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );

    return getDetails();
};

export default BreakdownByStatus;
