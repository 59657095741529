import React, { useState, useEffect } from "react";
import { OI_URL } from "../../config/config";
import { Modal, Button, Form, Input, Select, Table, Tag, Space, Alert } from "antd";
import { PlusOutlined } from '@ant-design/icons';
import axios from "axios";
import Swal from "sweetalert2";
import TxFindAccessToken from "../../helper/TxFindAccessToken";

const { TextArea } = Input;
const { Option } = Select;

const MerchantModal = (props) => {
    const { t, merchantId, isModalVisible, handleCancel, fetchMerchants } = props;
    const [merchant, setMerchant] = useState(null);
    const [loading, setLoading] = useState(false);
    const [providerList, setProviderList] = useState([]);
    const [selectedProvider, setSelectedProvider] = useState(null);
    const [selectedService, setSelectedService] = useState(null);
    const [serviceNames, setServiceNames] = useState({}); 

    const [form] = Form.useForm();

    useEffect(() => {
        if (merchantId) {
            getMerchantDetail();
        }
        if (!providerList.length) {
            getProviderList();
        }
    }, [merchantId]);

    const getMerchantDetail = async () => {
        setLoading(true);
        const apiUrl = OI_URL + `/api/v1/txshield/merchant/${merchantId}/details/`;
        try {
            const { data } = await axios.post(apiUrl, "", { 
                headers: { Authorization: "Bearer " + await TxFindAccessToken() } 
            });
            setMerchant(data);
            form.setFieldsValue({
                merchantId: data.merchantId,
                name: data.name,
                jwtToken: data.jwtToken
            });
        } catch (error) {
            Swal.fire({
                icon: "error",
                title: t("login.dialogs.catch"),
                text: error.response ? error.response.data.msg : "",
                showConfirmButton: true,
            });
        }
        setLoading(false);
    };

    const getProviderList = async () => {
        const apiUrl = OI_URL + `/api/v1/txshield/providers`;
        try {
            const { data } = await axios.post(apiUrl, "", { 
                headers: { Authorization: "Bearer " + await TxFindAccessToken() } 
            });
            setProviderList(data);
        } catch (error) {
            Swal.fire({
                icon: "error",
                title: t("login.dialogs.catch"),
                text: error.response ? error.response.data.msg : "",
            });
        }
    };

    const generateJWTToken = async () => {
        const apiUrl = OI_URL + `/api/v1/txshield/merchant/${merchantId}/generateJWTToken`;
        try {
            const { data } = await axios.post(apiUrl, "", { 
                headers: { Authorization: "Bearer " + await TxFindAccessToken() } 
            });
            if (data.status === "ok") {
                setMerchant({ ...merchant, jwtToken: data.token });
                form.setFieldsValue({ ...merchant, jwtToken: data.token });
            }
        } catch (error) {
            Swal.fire({
                icon: "error",
                title: t("login.dialogs.catch"),
                text: error.response ? error.response.data.msg : "",
                showConfirmButton: true,
            });
        }
    };

    const handleOk = () => {
        setLoading(true);
        form.submit();
    };

    const onFinish = async (values) => {
        const apiUrl = OI_URL + `/api/v1/txshield/merchant/${merchantId}/edit/`;
        try {
            const { data } = await axios.post(apiUrl, values, { 
                headers: { Authorization: "Bearer " + await TxFindAccessToken() } 
            });
            if (data.status === "ok") {
                Swal.fire({
                    icon: "success",
                    title: t("contents.oi.merchant_updated"),
                    showConfirmButton: false,
                    timer: 2000,
                    onClose: () => {
                        fetchMerchants();
                    },
                });
                handleCancel();
            } else {
                Swal.fire({
                    icon: "error",
                    title: t("login.dialogs.catch"),
                    text: data.msg ? data.msg : "",
                    showConfirmButton: true,
                });
            }
        } catch (error) {
            Swal.fire({
                icon: "error",
                title: t("login.dialogs.catch"),
                text: error.response ? error.response.data.msg : "",
                showConfirmButton: true,
            });
        }
        setLoading(false);
    };

    const getServiceName = async (serviceId) => {
        try {
            const { data } = await axios.get(
                `${OI_URL}/api/v1/txshield/provider_service/${serviceId}`,
                { headers: { Authorization: "Bearer " + await TxFindAccessToken() } }
            );
            setServiceNames(prev => ({
                ...prev,
                [serviceId]: data.serviceName
            }));
        } catch (error) {
            console.error('Error getting service name:', error);
        }
    };

    useEffect(() => {
        if (merchant?.enrollments) {
            merchant.enrollments.forEach(enrollment => {
                if (enrollment.serviceId) {
                    getServiceName(enrollment.serviceId);
                }
            });
        }
    }, [merchant]);

    const enrollmentsColumns = [
        {
            title: "Provider",
            dataIndex: "name",
            key: "name",
            width: '30%'
        },
        {
            title: "Services",
            dataIndex: "service",
            key: "service",
            render: (_, record) => {
                if (record.name === 'ems') {
                    return <Tag color="blue">EMS</Tag>;
                }
    
                const provider = providerList.find(p => p.providerId === record.providerId);
                if (!provider?.services) return null;
    
                const serviceName = serviceNames[record.serviceId];
                const serviceInfo = provider.services.find(s => s.serviceId === serviceName);
                
                return serviceInfo && <Tag color="blue">{serviceInfo.serviceName}</Tag>;
            }
        },
        {
            title: "Action",
            key: "action",
            width: '20%',
            render: (_, record) => {
                return (
                    <Button
                        type="link"
                        style={{ color: record.active ? '#ff4d4f' : '#52c41a' }}
                        onClick={() => record.active ? handleUnenroll(record) : handleEnroll(record)}
                    >
                        {record.active ? 'Unenroll' : 'Enroll'}
                    </Button>
                );
            }
            
        }
    ];

    const handleEnroll = async (record) => {
        try {
            const apiUrl = `${OI_URL}/api/v1/txshield/merchant/${merchantId}/enroll`;
            const response = await axios.post(
                apiUrl,
                {
                    providerId: record.providerId,
                    serviceId: record.serviceId,
                },
                { headers: { Authorization: "Bearer " + await TxFindAccessToken() } }
            );
    
            if (response.data.status === "ok") {
                Swal.fire({
                    icon: "success",
                    title: "Successfully enrolled",
                    timer: 1500,
                });
                getMerchantDetail();
            } else {
                Swal.fire({
                    icon: "error",
                    title: "Error",
                    text: response.data.msg || "Failed to enroll",
                });
            }
        } catch (error) {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: error.response?.data?.msg || "Failed to enroll",
            });
        }
    };
    
    const handleUnenroll = async (record) => {
        try {
            const apiUrl = `${OI_URL}/api/v1/txshield/merchant/${merchantId}/${record.serviceId}/unenroll`;
            const response = await axios.post(
                apiUrl,
                {},
                { headers: { Authorization: "Bearer " + await TxFindAccessToken() } }
            );
    
            if (response.data.status === "ok") {
                Swal.fire({
                    icon: "success",
                    title: "Successfully unenrolled",
                    timer: 1500,
                });
                getMerchantDetail();
            } else {
                Swal.fire({
                    icon: "error",
                    title: "Error",
                    text: response.data.msg || "Failed to unenroll",
                });
            }
        } catch (error) {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: error.response?.data?.msg || "Failed to unenroll",
            });
        }
    };

    const handleProviderChange = (value) => {
        setSelectedProvider(value);
        setSelectedService(null);
    };

    const getAvailableServices = () => {
        const provider = providerList.find(p => p.providerId === selectedProvider);
        return provider?.services || [];
    };

    const handleNewEnrollment = async () => {
        if (!selectedProvider || !selectedService) {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "Please select both a provider and a service",
            });
            return;
        }
    
        try {
            const apiUrl = `${OI_URL}/api/v1/txshield/merchant/${merchantId}/enroll`;
            const response = await axios.post(
                apiUrl,
                {
                    providerId: selectedProvider,
                    serviceId: selectedService,
                },
                { headers: { Authorization: "Bearer " + await TxFindAccessToken() } }
            );
    
            if (response.data.status === "ok") {
                Swal.fire({
                    icon: "success",
                    title: "Successfully added new enrollment",
                    timer: 1500,
                });
                getMerchantDetail();
            } else {
                Swal.fire({
                    icon: "error",
                    title: "Error",
                    text: response.data.msg || "Failed to add enrollment",
                });
            }
        } catch (error) {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: error.response?.data?.msg || "Failed to add enrollment",
            });
        }
    };
    

    return (
        <Modal
            title="View Merchant"
                visible={isModalVisible}
            width={800}
            onOk={handleOk}
            onCancel={handleCancel}
            footer={[
                <Button key="back" onClick={handleCancel}>
                    {t("contents.chargebacks.form.cancel")}
                </Button>,
                <Button key="submit" type="primary" loading={loading} onClick={handleOk}>
                    {loading ? t("contents.chargebacks.form.submitting") : t("contents.chargebacks.form.submit")}
                </Button>,
            ]}
        >
            <Form form={form} layout="vertical" onFinish={onFinish}>
                <Form.Item name="merchantId" label="Merchant ID">                    
                    <Input disabled />
                </Form.Item>
                
                <Form.Item 
                    name="name" 
                    label={t("contents.oi.form.name")} 
                    rules={[{ required: true }]}
                >
                    <Input />
                </Form.Item>
                
                <Form.Item 
                    name="jwtToken" 
                    label={t("contents.oi.form.jwt_token")} 
                    rules={[{ required: true }]}
                >
                    <TextArea rows={3} readOnly />
                </Form.Item>
                
                <Form.Item>
                    <Button onClick={generateJWTToken}>
                        {t("contents.oi.form.generate_jwt")}
                    </Button>
                </Form.Item>

                <div className="mb-4">
                    <div className="flex justify-between items-center mb-4">
                        <h4 className="mb-0">Enrollments</h4>
                        <Space>
                            <Select
                                placeholder="Select Provider"
                                style={{ width: 180 }}
                                value={selectedProvider}
                                onChange={handleProviderChange}
                            >
                                {providerList.map(provider => (
                                    <Option key={provider.providerId} value={provider.providerId}>
                                        {provider.name}
                                    </Option>
                                ))}
                            </Select>
                            <Select
                                placeholder="Select Service"
                                style={{ width: 180 }}
                                value={selectedService}
                                onChange={value => setSelectedService(value)}
                                disabled={!selectedProvider}
                            >
                                {getAvailableServices().map(service => (
                                    <Option key={service.serviceId} value={service.serviceId}>
                                        {service.serviceName}
                                    </Option>
                                ))}
                            </Select>
                            <Space>
                                <Button
                                    type="primary"
                                    icon={<PlusOutlined />}
                                    disabled={!selectedProvider || !selectedService} 
                                    onClick={handleNewEnrollment} 
                                >
                                    Enroll
                                </Button>
                            </Space>
                        </Space>
                    </div>
                    
                    {merchant?.enrollments?.length > 0 ? (
                        <Table
                            columns={enrollmentsColumns}
                            dataSource={merchant.enrollments}
                            rowKey={record => `${record.providerId}-${record.serviceId}`}
                            pagination={false}
                            size="small"
                        />
                    ) : (
                        <Alert
                            message="No enrollments found"
                            type="info"
                            showIcon
                        />
                    )}
                </div>
            </Form>
        </Modal>
    );
};

export default MerchantModal;