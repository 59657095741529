import React from "react";
import { List, Card, Typography } from "antd";

const { Paragraph } = Typography;

const FraudcheckDetails = (props) => {
    const { t, data } = props; // eslint-disable-line

    const getThreatLevel = (threatlevel) => (threatlevel >= 0 ? <div>{t("contents.transactions.form.threat_level_assessment")} : {threatlevel}</div> : "");

    const getThreatError = (errors) => (errors ? <div>Error: {Array.isArray(errors) ? <pre>{JSON.stringify(errors)}</pre> : errors}</div> : "");

    const getResponse = (response) =>
        response ? <div>{t("contents.transactions.form.response")}: <br/>{Array.isArray(response) || typeof response === "object" ? <pre>{JSON.stringify(response)}</pre> : response}</div> : "";

    const getThreatCheck = (checks) =>
        checks ? (
            checks.threatcheck && Array.isArray(checks.threatcheck) && checks.threatcheck.length > 0 ? (
                <blockquote>
                    {checks.threatcheck.map(({ name, type, value, level },idx) => (
                        <div style={{ fontSize: "12px" }} key={idx}>
                            {t("contents.transactions.form.rule")} : {name} {type} {value} {t("contents.transactions.form.generates")} {level}
                        </div>
                    ))}
                </blockquote>
            ) : (
                ""
            )
        ) : (
            ""
        );

    const getProcess = (process) => (
        <>
            {getThreatLevel(process.threatlevel)}
            {getThreatError(process.errors)}
            {getThreatCheck(process.checks)}
            {getResponse(process.response)}
        </>
    );

    const getList = () => (
        <div className="details-info--text">
            <List
                className="fraudcheck-list"
                itemLayout="horizontal"
                dataSource={data}
                renderItem={(item) => (
                    <List.Item className="fraudcheck-list--item justify-content-start align-items-start">
                        <h4 className="list-item--title">{item.title}</h4>
                        <Paragraph className="list-item--content">{getProcess(item.value)}</Paragraph>
                    </List.Item>
                )}
            />
        </div>
    );

    const getMessage = () => (
        <div className="details-info--text">
            <p>{t("contents.transactions.form.no_fraudcheck_information")}</p>
        </div>
    );

    return (
        <Card className="details-info--card">
            <h4 className="details-info--title">{t("contents.transactions.form.fraudcheck_details")}</h4>
            {Array.isArray(data) ? getList() : getMessage()}
        </Card>
    );
};

export default FraudcheckDetails;
