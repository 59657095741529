import UserSettings from "../UserSettings";
import Multilingual from "../Multilingual";

import { useLocation } from "react-router-dom";

const CustomPaginationV2 = ({ totalResult, currentPage, requestData, setRequestData, setCurrentPage }) => {
    const [t] = Multilingual(); // eslint-disable-line
    const location = useLocation();
    const { pathname } = location;
    const keys = pathname.substring(1).split("/");
    const pageName = keys[0];
    const [updateSettings] = UserSettings();
    let initDefaultPageSize = 20;
    let loggedUserSettings = localStorage.getItem("loggedUserSettings") ? JSON.parse(localStorage.getItem("loggedUserSettings")) : {};
    let loggedPageSize = 0;
    if (loggedUserSettings) {
        if (loggedUserSettings.defaultTablePageSize) {
            if (loggedUserSettings.defaultTablePageSize[pageName]) {
                initDefaultPageSize = loggedUserSettings.defaultTablePageSize[pageName];
                loggedPageSize = loggedUserSettings.defaultTablePageSize[pageName];
            }
        }
    }

    const onPaginationChange = (page, pageSize) => {

         let isExist = true;
        if (!loggedUserSettings) {
            loggedUserSettings = {};

            if (!loggedUserSettings.defaultTablePageSize) {
                loggedUserSettings.defaultTablePageSize = {};

                if (loggedUserSettings.defaultTablePageSize[pageName]) {
                    loggedUserSettings.defaultTablePageSize[pageName] = pageSize;
                }
            }
        }

        if (isExist) {
            loggedUserSettings.defaultTablePageSize = { ...loggedUserSettings.defaultTablePageSize, [pageName]: pageSize };
        }

        if(loggedPageSize !== pageSize){
            updateSettings(loggedUserSettings);
        }
        localStorage.setItem("loggedUserSettings", JSON.stringify(loggedUserSettings));


        setCurrentPage(page);
        setRequestData({ ...requestData, page: page, size: pageSize });
    };
    return totalResult ? {
        current: currentPage,
        total: totalResult,
        position: ["topRight", "bottomRight"],
        pageSize: (loggedUserSettings.defaultTablePageSize && loggedUserSettings.defaultTablePageSize[pageName])?loggedUserSettings.defaultTablePageSize[pageName]:initDefaultPageSize,
        showSizeChanger: true,
        pageSizeOptions: ["10", "20", "50", "100"],
        showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
        onChange: (page, pageSize) => onPaginationChange(page, pageSize),
    } : false;
};

export default CustomPaginationV2;