import React, { useState, useEffect } from "react";
import parse from "html-react-parser";
import DataFetchHookV3 from "../../helper/DataFetchHookV3";
import { Table, PageHeader, Card, Form, Select, Row, Col, Input, Button } from "antd";
import { InfoCircleTwoTone, WarningTwoTone, SearchOutlined } from "@ant-design/icons";
import moment from "moment";
import { API_TX } from "../../config/config";
import CustomPaginationV2 from "../../helper/Table/CustomPaginationV2";
import CustomDateRangeV3 from "../../helper/Table/CustomDateRangeV3";
import SortOrder from "../../helper/Table/SortOrder";
import ColumnResizable from "../../helper/Table/ColumnResizable";
import MergeColumns from "../../helper/Table/MergeColumns";

const { Option } = Select;
const DATA_URL = API_TX + "/messages";

const MAX_DATERANGE = 3;
const START_DATE = moment().subtract(MAX_DATERANGE, "months");
const END_DATE = moment();

const MessageList = (props) => {
    const [{ data, totalResult, isLoading, isError, errorMsg, requestData }, doFetch, setRequestData] = DataFetchHookV3(DATA_URL); // eslint-disable-line
    const [formData, setFormData] = useState();
    const [sortedInfo, setSortedInfo] = useState({});
    const [dates, setDates] = useState([START_DATE, END_DATE]);
    const [currentPage, setCurrentPage] = useState(1);
    const { t } = props;

    const initFormData = {
        subject: "",
        message: "",
        dateRange: [START_DATE, END_DATE],
        action: [],
        order: null,
    };

    const [form] = Form.useForm();

    useEffect(() => {
        formValInit();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const handleChange = (pagination, filters, sorter, { currentDataSource }) => {
        setSortedInfo(sorter);
    };

    const getTypeIcon = (type) => {
        switch (type.toLowerCase()) {
            case "notice":
                return <InfoCircleTwoTone style={{ fontSize: "1.5rem" }} />;
            case "warn":
                return <WarningTwoTone style={{ fontSize: "1.5rem" }} twoToneColor="#faad14" />;
            default:
                return type.toUpperCase();
        }
    };

    const columns = [
        {
            dataIndex: "created",
            key: "created",
            title: t("tables.fields.datecreated"),
            width: 200,
            render: (created) => moment(created).format("YYYY-MM-DD HH:mm:ss"),
            sorter: {
                compare: (a, b) => moment(a.created).diff(moment(b.created)),
                multiple: 1,
            },
            sortOrder: SortOrder(sortedInfo, "created"),
        },
        {
            dataIndex: "type",
            key: "type",
            title: t("tables.fields.type"),
            width: 100,
            render: (type) => getTypeIcon(type),
        },
        {
            dataIndex: "subject",
            key: "subject",
            title: t("tables.fields.subject"),
            width: 200,
        },
        {
            dataIndex: "message",
            key: "message",
            width: 200,
            title: t("tables.fields.message"),
            render: (message) => parse(message),
        },
    ];
    const formValInit = () => {
        setFormData(initFormData);
        form.setFieldsValue(initFormData);
    };

    const clearAll = () => {
        setFormData(initFormData);
        setRequestData({ ...requestData, ...initFormData, startDate: null, endDate: null, page: 1 });
        form.setFieldsValue(initFormData);
    };

    const formValChange = (chgV, allV) => {
        setFormData(allV);
    };

    const onFinish = () => {
        let startDate = null;
        let endDate = null;
        let searchData = {};
        if (formData) {
            if (dates) {
                startDate = dates[0] ? dates[0] : null;
                endDate = dates[1] ? dates[1] : null;
            }
            searchData = {
                type: formData.type,
                subject: formData.subject.trim(),
                message: formData.message.trim(),
                order: formData.order,
            };
        }
        // Reset Page Index to 1
        setCurrentPage(1);
        setRequestData({ ...requestData, ...searchData, startDate: startDate, endDate: endDate, page: 1 });
    };

    const AdvancedSearchForm = () => (
        <div className="search-wrap">
            <Card title={t("tables.actions.search")} size="small">
                <Form form={form} size="large" name="advanced_search" className="ant-advanced-search-form" onValuesChange={formValChange} onFinish={onFinish}>
                    <Row gutter={[16, 16]}>
                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                            <Form.Item name="dateRange">
                                <CustomDateRangeV3 t={t} state={{ dates, setDates }} unit="months" limit={MAX_DATERANGE} />
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                            <Form.Item name="type">
                                <Select mode="multiple" maxTagCount="responsive" allowClear style={{ width: "100%" }} placeholder={t("tables.fields.type")}>
                                    <Option value="WARN">WARN</Option>
                                    <Option value="NOTICE">NOTICE</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 6 }} xl={{ span: 5 }}>
                            <Form.Item name="subject">
                                <Input placeholder={t("tables.fields.subject")} />
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 6 }} xl={{ span: 7 }}>
                            <Form.Item name="message">
                                <Input placeholder={t("tables.fields.message")} />
                            </Form.Item>
                        </Col>
                        <Col
                            className="gutter-row"
                            xs={{ span: 24 }}
                            sm={{ span: 12 }}
                            md={{ span: 6, offset: 4 }}
                            lg={{ span: 6, offset: 12 }}
                            xl={{ span: 3, offset: 17 }}
                        >
                            <Form.Item>
                                <Button type="secondary" onClick={() => clearAll()} block>
                                    {t("tables.actions.clear_all")}
                                </Button>
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 4 }}>
                            <Form.Item>
                                <Button type="primary" htmlType="submit" block>
                                    <SearchOutlined /> {t("tables.actions.search")}
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Card>
        </div>
    );

    const getDataSource = (datalist) => (datalist && Array.isArray(datalist) ? datalist : []);

    const getHeaderTable = () => (
        <div className="custom-table--header d-flex justify-content-between align-items-center">
            <h3 className="table-title">{t("contents.messages.title")}</h3>
        </div>
    );

    return (
        <>
            <PageHeader title={t("contents.messages.title")}></PageHeader>
            {AdvancedSearchForm()}
            <Table
                size="small"
                title={() => getHeaderTable()}
                loading={isLoading}
                components={{
                    header: {
                        cell: ColumnResizable,
                    },
                }}
                columns={MergeColumns(columns)}
                rowKey="amid"
                className="table-v3 custom-pagination"
                dataSource={getDataSource(data.datalist)}
                onChange={handleChange}
                pagination={CustomPaginationV2({ totalResult, currentPage, requestData, setRequestData, setCurrentPage })}
            />
        </>
    );
};

export default MessageList;
