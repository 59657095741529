import React, { useState, } from "react";
import { Table } from "antd";
import CustomPaginationV2 from "../../../helper/Table/CustomPaginationV2";
import ColumnResizable from "../../../helper/Table/ColumnResizable";
import MergeColumns from "../../../helper/Table/MergeColumns";
import DataFetchHookTxAlert from "../../../helper/DataFetchHookTxAlert";
import TextSorter from "../../../helper/Table/TextSorter";
import moment from "moment";


const Txalert_EnrollmentTbl = (props) => {
    const [{ data, totalResult, isLoading, isError, errorMsg, requestData, isUpdated }, setUrl, setRequestData] = DataFetchHookTxAlert(props.url,"GET", localStorage.getItem("txalert_token")); // eslint-disable-line
    const [currentPage, setCurrentPage] = useState(1);
    const { t } = props;


    const columns = [
        {
            dataIndex: "provider_name",
            key: "provider_name",
            align: "left",
            title: t("tables.fields.alert_provider_name"),
            width: 150,           
            sorter: {
                compare: (a, b) => TextSorter(a.provider_name,b.provider_name)
            },
            render: (text, record, index) => (
                text +'('+ record.username+')'
            )
        },
        {
            dataIndex: "update_date",
            key: "update_date",
            align: "left",
            title: t("tables.fields.updatedDate"),
            width: 150,           
            sorter: {
                compare: (a, b) => moment(a.update_date).diff(moment(b.update_date))
            }
        },
        {
            dataIndex: "status",
            key: "status",
            title: t("tables.fields.status"),
            width: 50,
            sorter: {
                compare: (a, b) => TextSorter(a.status ,b.status)
            }
        },
        {
            dataIndex: "status_reason",
            key: "status_reason",
            title: t("tables.fields.status_reason"),
            width: 150,
            sorter: {
                compare: (a, b) => TextSorter(a.status_reason,b.status_reason)
            }
        }        
    ];

    const getDataSource = (datalist) => (datalist && Array.isArray(datalist) ? datalist : []);
    const getHeaderTable = () => (
        <div className="custom-table--header d-flex justify-content-between align-items-center">
            <h3 className="table-title">{t("contents.descriptors.tabs.enrollment")}</h3>
        </div>
    );
   
    return (
        <div>            
            <Table
                size="small"
                loading={isLoading}
                components={{
                    header: {
                        cell: ColumnResizable,
                    },
                }}
                title={() => getHeaderTable()}
                columns={MergeColumns(columns)}
                rowKey={props.rowkey}
                className="table-v3 custom-pagination custom-pagination-v3"
                dataSource={getDataSource(data.datalist)}
                pagination={CustomPaginationV2({ totalResult, currentPage, requestData, setRequestData, setCurrentPage })}

            />
        </div>
    );
};


export default Txalert_EnrollmentTbl;
