const CCMask = (cardno) => {
    if (!cardno) {
        return false;
    }
    if (cardno.length !== 16) {
        return false;
    }

    const bin = cardno.substr(0, 6);
    const lastfour = cardno.substr(12, 16);

    return bin + "******" + lastfour;
};
export default CCMask;
