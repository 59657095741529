import React, { useState, useEffect } from "react";
import DataFetchHook from "../../helper/DataFetchHook";
import DraggableModal from "../../helper/DraggableModal";
import { Table, Button, Modal, Typography, Tooltip } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import moment from "moment";
import axios from "axios";
import Swal from "sweetalert2";
import { SOCKET_URL, REALTIME_DASHBOARD } from "../../config/config";
import { API, DISABLE_MATCH_REFUND } from "../../config/config";

const { confirm } = Modal;
const { Paragraph } = Typography;

const DATA_URL = "/alerttransactionmatches";
const DATA_URL_REFUND = "/processtransactionrefund";

const AlertsForReviewList = (props) => {
    const { t, socket, alertid, isresolved } = props;
    const [{ data }, doFetch] = DataFetchHook(`${DATA_URL}?alertid=${alertid}`);
    const [alert, setAlert] = useState(null);
    const [modal, setModal] = useState({ title: "", content: "" });
    const [isModalVisible, setIsModalVisible] = useState(false);

    useEffect(() => {
        if (alert && alert.alertid) {
            doFetch(DATA_URL + `?alertid=${alertid}&${moment().unix()}`);
        }
    }, [alert, alertid, doFetch]);

    if (REALTIME_DASHBOARD && socket) {
        socket.on("requestAlertUpdate", async (receive) => {
            if (receive && receive.alertid && data.datalist) {
                if (parseInt(alertid) === receive.alertid) {
                    setAlert(receive);
                }
            }
        });
    }

    const showModal = (params) => {
        setModal(params);
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const IsJsonString = (str) => {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    };

    function findArrayElementByMatchid(array, matchid) {
        return array.find((element) => {
          return element.matchid === matchid;
        })
      }
    const processMatch = (matchid) => {
      
       
        const { alertid, orderid, transaction_amount,transaction_info } = data.datalist ? findArrayElementByMatchid( data.datalist,matchid) : null;
        var trx_info = transaction_info === null? null:JSON.parse(transaction_info);
        
        var refundedamount = Object.keys(trx_info).length === 0 ? 0: trx_info.refundedamount?trx_info.refundedamount:0;
        
        confirm({
            title: t("contents.possibletransactionmatches.confirm.q_1"),
            content: (
                <div>
                    <p>
                        {t("contents.possibletransactionmatches.confirm.notice_1")}
                        <br />
                        {t("contents.possibletransactionmatches.confirm.notice_11")} <strong>${transaction_amount - refundedamount }</strong>{" "}
                        {t("contents.possibletransactionmatches.confirm.notice_12")} <strong>{orderid}</strong>.
                    </p>
                    <p>
                        {t("contents.possibletransactionmatches.confirm.notice_21")} <strong>{alertid}</strong>{" "}
                        {t("contents.possibletransactionmatches.confirm.notice_22")} <br />
                        {t("contents.possibletransactionmatches.confirm.outcome")} <br />
                        {t("contents.possibletransactionmatches.confirm.refunded")} <br />
                        {t("contents.possibletransactionmatches.confirm.reason")}
                    </p>
                </div>
            ),
            icon: <ExclamationCircleOutlined />,
            okText: t("header.menu.logout.yes"),
            okType: "danger",
            cancelText: t("header.menu.logout.no"),
            width: "650px",
            onOk() {
                return new Promise((resolve, reject) => {
                    axios
                        .post(
                            API + DATA_URL_REFUND,
                            { matchid: matchid },
                            { headers: { "content-type": "application/json", Authorization: "Bearer " + localStorage.getItem("access_token") } }
                        )
                        .then(({ data }) => {
                            if (data.status === "OK") {
                                resolve();
                                Swal.fire({
                                    icon: "success",
                                    title: "Refund successful.",
                                    showConfirmButton: false,
                                    timer: 2000,
                                    onClose: () => {
                                        doFetch(DATA_URL + `?alertid=${alertid}&${moment().unix()}`);
                                    },
                                });
                            } else {
                                reject();
                                Swal.fire({
                                    icon: "error",
                                    title: "Could not refund.",
                                    text: data.msg ? data.msg : data,
                                    showConfirmButton: true,
                                });
                            }
                        })
                        .catch((error) => {
                            reject();
                            Swal.fire({
                                icon: "error",
                                title: error,
                                showConfirmButton: false,
                                timer: 2000,
                                onClose: () => {
                                    if (REALTIME_DASHBOARD && socket && SOCKET_URL) {
                                        axios
                                            .post(
                                                SOCKET_URL + "/alert",
                                                { alertid: alertid },
                                                { headers: { Authorization: "Bearer " + localStorage.getItem("access_token") } }
                                            )
                                            .then((result) => {})
                                            .catch((error) => console.log(error));
                                    } else {
                                        doFetch(DATA_URL + `?alertid=${alertid}&${moment().unix()}`);
                                    }
                                },
                            });
                        });
                });
            },
            onCancel() {
                return false;
            },
        });
    };

    const columns = [
        {
            dataIndex: "datecreated",
            key: "datecreated",
            width: 80,
            title: t("tables.fields.retrieval_date"),
            render: (datecreated) => moment(datecreated).format("DD-MMM"),
        },
        //Comment out by Trang - May 14, 2024 - https://app.clickup.com/t/86cvbrbdg
        // {
        //     dataIndex: "cardnumber",
        //     key: "cardnumber",
        //     title: t("tables.fields.cardnumber"),
        //     width: 140,
        // },
        // {
        //     dataIndex: "transaction_amount",
        //     key: "transaction_amount",
        //     title: t("tables.fields.amount"),
        //     align: "left",
        //     width: 80,
        //     ellipsis: true,
        // },
        {
            dataIndex: "gwname",
            key: "gwname",
            title: t("tables.fields.gwname"),
            width: 130,
        },
        {
            dataIndex: "orderid",
            key: "orderid",
            title: t("tables.fields.orderid"),
            width: 100,
            render: (orderid, record) => (
                <Tooltip title={orderid}>
                    <Button
                        type="link"
                        size="small"
                        style={{whiteSpace: "normal",height:'auto',marginBottom:'10px',maxWidth:"100%"}}
                        onClick={() => showModal({ title: t("contents.possibletransactionmatches.transaction_info"), content: record.transaction_info })}
                    >
                        <span style={{overflow: "hidden",textOverflow: "ellipsis",whiteSpace: "nowrap",display: "block"}}>{orderid}</span>
                    </Button>
                </Tooltip>
            ),
        },
        {
            dataIndex: "gw_transaction_date",
            key: "gw_transaction_date",
            title: t("tables.fields.gw_transaction_date"),
            align: "center",
            width: 130,
            render: (gw_transaction_date) => moment(gw_transaction_date).format("lll"),
        },
        {
            dataIndex: "gw_transactionid",
            key: "gw_transactionid",
            title: t("tables.fields.gw_transactionid"),
            align: "center",
            width: 130,
        },
        {
            dataIndex: "matchscore",
            key: "matchscore",
            title: t("tables.fields.matchscore"),
            align: "center",
            width: 90,
        },
        {
            dataIndex: "refundstatus",
            key: "refundstatus",
            title: t("tables.fields.refundstatus"),
            fixed: "right",
            width: 120,
            render: (refundstatus, record) => t(`specialset.${refundstatus}`),
        },
        {
            dataIndex: "alertstatus",
            key: "alertstatus",
            title: t("tables.fields.alertstatus"),
            fixed: "right",
            width: 120,
            render: (alertstatus, record) => t(`specialset.${alertstatus}`),
        },
        {
            dataIndex: "action",
            key: "action",
            title: t("tables.fields.action"),
            align: "left",
            fixed: "right",
            width: 90,
            render: (text, record) => ([-1, 0].includes(record.refundedcode) ? getActionButton(record.matchid) : ""),
        },
    ];

    const getActionButton = (matchid) => (
        <Button size="small" onClick={() => processMatch(matchid)}>
            {t("tables.actions.resolve")}
        </Button>
    );

    const getDataSource = (datalist) =>
    datalist && Array.isArray(datalist)
    ? datalist.map((data) => ({ ...data, refundstatus: data.refundstatus ? data.refundstatus.replaceAll(" ", "_").toLowerCase() : "",alertstatus: data.alertstatus ? data.alertstatus.replaceAll(" ", "_").toLowerCase() : ""  }))
    : [];

    const getModalComponent = () => (
        <DraggableModal
            name={modal.title}
            visible={isModalVisible}
            onOk={handleOk}
            onCancel={handleCancel}
            width={800}
            footer={[
                <Button key="submit" type="primary" onClick={handleOk}>
                    {t("tables.actions.ok")}
                </Button>,
            ]}
            context={
                <Typography>
                    <Paragraph className="well">
                        {IsJsonString(modal.content) ? JSON.stringify(JSON.parse(modal.content), null, "\t") : modal.content}
                    </Paragraph>
                </Typography>
            }
        />
    );

    const getColumns = () => {
        if (!DISABLE_MATCH_REFUND && !isresolved) {
            return columns;
        } else {
            return columns.filter((column) => column.dataIndex !== "action");
        }
    };

    const isTransactionMatched = () => (data ? (data.datalist ? !!data.datalist.filter((row) => row.refundedcode === 1) : false) : false);


    return (
        <>
            <h2 style={{ marginBottom: "1.5rem" }}>
                {isTransactionMatched() ? t("contents.possibletransactionmatches.title_matched") : t("contents.possibletransactionmatches.title")}
            </h2>
            {getModalComponent()}
            <Table
                size="middle"
                columns={getColumns()}
                rowKey="matchid"
                scroll={{ x: 1200 }}
                dataSource={getDataSource(data.datalist)}
                pagination={false}
            />
        </>
    );
};

export default AlertsForReviewList;
