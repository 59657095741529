
import React, { useState } from "react";
import { Table,  Button} from "antd";
import CustomPaginationV2 from "../../../helper/Table/CustomPaginationV2";
import { API_TXALERT2 } from "../../../config/config";
import ColumnResizable from "../../../helper/Table/ColumnResizable";
import MergeColumns from "../../../helper/Table/MergeColumns";
import DataFetchHookTxAlert from "../../../helper/DataFetchHookTxAlert";




const Txalert_StatusUpdatesTbl = (props) => {
   const DATA_URL = API_TXALERT2 + "/alert/alert_update_history?alert_id="+props.id ;
    const [{ data, totalResult, isLoading, isError, errorMsg, requestData, isUpdated }, setUrl, setRequestData] = DataFetchHookTxAlert(DATA_URL,"GET", localStorage.getItem("txalert_token")); // eslint-disable-line
  
    const [currentPage, setCurrentPage] = useState(1);
    const { t } = props;

  
    const getButtonModal = (params, buttonText = "") => (
      <div className="ant-form--button-modal">
         <Button size="small" onClick={() => props.showModal(params)}>
            {buttonText ? buttonText : t("tables.actions.view")}
         </Button>
      </div>
   );

   const getOutcomeRender = (outcome) => {
      if (!outcome) {
         return <div className="ant-form--text">{outcome}</div>;
      }
      try {
         if (outcome[0].toUpperCase() !== outcome[0].toLowerCase()) {
            return <div className="ant-form--text">{t(`outcomes.${outcome.toLowerCase()}`)}</div>;
         } else {
            return <div className="ant-form--text">{outcome}</div>;
         }
      } catch (error) {
         return <div className="ant-form--text">{outcome}</div>;
      }
   };
const columns = [    
    {
       dataIndex: "update_date",
       key: "update_date",
       title: t("tables.fields.date"),
       width: 100,
    },
    {
       dataIndex: "action",
       key: "request_data",
       title: t("tables.fields.action"),
       width: 100,
       align: "left",
      
    },
    {
       dataIndex: "outcome",
       key: "outcome",
       title: t("tables.fields.outcome"),
       width: 100,
       render: (outcome) => getOutcomeRender(outcome)    
    },
    {
      dataIndex: "data",
      key: "data",
      title: t("tables.fields.data"),
      width: 100,
      render: (data, record) =>
      data
         ? getButtonModal({ title: t("tables.fields.data"), content: data })
         :  "",  
   },
   {
      dataIndex: "action_status",
      key: "action_status",
      title: t("tables.fields.status"),
      width: 100
   }
 ];

 const getDataSource = (datalist) => (datalist && Array.isArray(datalist) ? datalist : []);
 const getHeaderTable = () => (
     <div className="custom-table--header d-flex justify-content-between align-items-center">
         <h3 className="table-title">{t("contents.descriptors.tabs.variations")}</h3>
     </div>
 );

 return (
     <div>            
         <Table
             size="small"
             loading={isLoading}
             components={{
                 header: {
                     cell: ColumnResizable,
                 },
             }}
             title={() => getHeaderTable()}
             columns={MergeColumns(columns)}
             rowKey="alert_update_history_id"
             className="table-v3 custom-pagination custom-pagination-v3"
             dataSource={getDataSource(data.datalist)}
             pagination={CustomPaginationV2({ totalResult, currentPage, requestData, setRequestData, setCurrentPage })}

         />
     </div>
 );

}
export default Txalert_StatusUpdatesTbl