import React, { useState } from "react";
import { CBM_URL } from "../../config/config";
import { Modal, Button, Form, Upload, message } from "antd";
import ToBase64 from "../../helper/ToBase64";
import axios from "axios";
import Swal from "sweetalert2";
import { UploadOutlined } from "@ant-design/icons";

const DocumentUpload = (props) => {
    const { t, upload, isModalVisible, setIsModalVisible, fetchChargeback = () => {} } = props; // removed unused var 'merchant'
    const [data, setData] = useState([]);
    const [showFileList, setShowFileList] = useState([]);
    const [loading, setLoading] = useState(false);

    const layout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };

    const handleOk = () => {
        setLoading(true);
        onFinish();
    };

    const handleCancel = () => {
        setData([]);
        setShowFileList([]);
        setLoading(false);
        setIsModalVisible(false);
    };


    const uploadFile = async ({ mid, pid, chargebackid, filename, filetype, document }) => {
   
       // let jwt_token =  await CBM_MerchantProviderToken(merchant.jwt_token,mid,pid);  //get merchant provider token with merchant and provider chosen
        return await axios
            .post(
                CBM_URL + `/api/v1/chargebacks/${chargebackid}/uploadDocument`,
                { filename, filetype, document },
                { headers: { Authorization: "Bearer " + localStorage.getItem("cbm_token") }}
            )
            .then(({ data }) => {
                if (!data.status) {
                    message.error(`${filename}.${filetype} ${data.errorMessage}`);
                } else {
                    message.success(`${filename}.${filetype} ${t("contents.chargebacks.messages.upload_successful")}`);
                }
                return { ...data, filetype };
            })
            .catch((error) => {
                const errorMessage = error.response ? error.response.data.error_msg : "";
                message.error(`${filename}.${filetype} ${errorMessage}`);
                return { status: false, errorFile: filename, filetype, errorMessage };
            });
    };

    const onFinish = async () => {
        const { mid, pid, chargebackid } = upload;

        setShowFileList(showFileList.map((file) => ({ uid: file.uid, name: file.name, size: file.size, type: file.type, status: "uploading" })));

        const results = await data.map(async (file) => {
            const base64 = await ToBase64(file);
            const { filename, filetype, mimetype } = getFileNameAndType(file);
            const document = base64.replace(`data:${mimetype};base64,`, "");

            return uploadFile({
                mid,
                pid,
                chargebackid,
                filename,
                filetype,
                document,
            });
        });

        Promise.all(results).then((responses) => {
            if (responses.length === responses.filter((response) => response.status === true).length) {
                handleCancel();
                Swal.fire({
                    icon: "success",
                    title: t("contents.chargebacks.messages.document_upload_successful"),
                    showConfirmButton: false,
                    timer: 2000,
                });
            } else {
                setLoading(false);
                setData(data.filter((file, index) => !responses[index].status));
                setShowFileList(
                    showFileList
                        .filter((file, index) => !responses[index].status)
                        .map((file, index) => {
                            return {
                                uid: file.uid,
                                name: file.name,
                                size: file.size,
                                type: file.type,
                                status: "error",
                            };
                        })
                );
            }
            fetchChargeback();
        });
    };

    const getFileNameAndType = (file) => {
        try {
            const i = file.name.lastIndexOf(".");
            const filetype = i < 0 ? "" : file.name.substr(i).replace(".", "");
            if (!filetype) return null;
            const filename = file.name.replace("." + filetype, "");
            return { filename, filetype, mimetype: file.type };
        } catch (error) {
            return null;
        }
    };

    const uploadProps = {
        accept: ".pdf, .doc, .docx, .png, .jpg",
        multiple: true,
        onRemove: (file) => {
            const index = data.indexOf(file);
            const newFileList = data.slice();
            newFileList.splice(index, 1);
            setData(newFileList);
            setShowFileList(newFileList);
        },
        beforeUpload: (file, fileList) => {
            const isLimit = file.size / 1024 < 700;
            if (!isLimit) {
                message.error(`${file.name} ${t("contents.chargebacks.messages.file_must_smaller")}`, 5);
                return false;
            }
            setData([...data, ...fileList.filter((file) => file.size / 1024 < 700)]);
            setShowFileList([...data, ...fileList.filter((file) => file.size / 1024 < 700)]);
            return false;
        },
        fileList: showFileList,
    };

    const isOkButtonProps = () => !data || data.length === 0 || loading;

    return (
        <Modal
            title={`${t("tables.actions.document_upload")} #${upload.merchant_transaction_id}`}
            width={600}
            visible={isModalVisible}
            onOk={handleOk}
            onCancel={handleCancel}
            footer={[
                <Button key="back" onClick={() => handleCancel()}>
                    {t("contents.chargebacks.form.cancel")}
                </Button>,
                <Button key="submit" type="primary" loading={loading} disabled={isOkButtonProps()} onClick={() => handleOk()}>
                    {loading ? t("contents.chargebacks.form.uploading") : t("contents.chargebacks.form.upload")}
                </Button>,
            ]}
        >
            <Form {...layout} name="upload_document">
                <Form.Item label={t("contents.chargebacks.document_file")} extra={`${t("contents.chargebacks.tooltip.max_upload_file_size")}: 700KB`}>
                    <Upload {...uploadProps}>
                        <Button icon={<UploadOutlined />}>{t("contents.chargebacks.form.select_file")}</Button>
                    </Upload>
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default DocumentUpload;
