import React, { useState, useEffect } from "react";
import { API_TXALERT2 } from "../../../config/config";
import { Modal, Button, Form, Input, Switch} from "antd";
import axios from "axios";
import Swal from "sweetalert2";
import GroupAccess from "../../../helper/GroupAccess";
import TxalertMerchantListComp from "../Components/MerchantListDropdown";
import TxalertEnrollmentInputTbl from "../Components/EnrollmentInputTbl";
import TextArea from "antd/lib/input/TextArea";
import CatchError from "../../../helper/CatchError";

const Txalert_BinCaidModal = (props) => {
    const { t, bincaidid, setId,isOpen,setIsOpen, fetchBincaidList } = props; //eslint-disable-line
    const [bincaidrec, setBincaidRec] = useState(null);
    const [loading, setLoading] = useState(false);
    const [enrollRecords, setEnrollRecords] = useState([]);
    const [errors,setErrors] = useState([]);

    const layout = {
        labelCol: { span: 8 }
    };

    // const tailLayout = {
    //     wrapperCol: { offset: 8, span: 16 },
    // };

    const initData = {
        bin: "",
        caid: "",
        mcc: "",
        vrol_profile: "",
        status: 0,
        reference_no: "",
        comment:'',
        merchant_id:''
    };

    const [form] = Form.useForm();

    const handleOk = () => {
        setLoading(false);
      //  handleCancel();
      onFinish();
    };

    const formValChange = (chgV, allV) => {      

            setBincaidRec( { ...allV, status: allV.status=== true?1:0} );

    };

    const SaveEnrollRecords = (items) => {  
        setEnrollRecords(items);       
     
    };


    
    useEffect(() => {
   
        if(isOpen){
           
            if (typeof bincaidid != 'undefined') {
              
                getBincaidDetail();
            }else{
                setBincaidRec(initData);
            }
        }
    }, [isOpen]); // eslint-disable-line

    const getBincaidDetail = () => {
        const apiUrl = API_TXALERT2 + `/bincaid/${bincaidid}`;
       
        axios
            .get(apiUrl,{ headers: { Authorization: "Bearer " + localStorage.getItem("txalert_token") } })
            .then(({ data }) => {
                let rec = data;
               
                setBincaidRec(rec);
                form.setFieldsValue({ ...rec, checked: rec.status=== 1?"true":"false"});
                
            })
            .catch((error) => {
                CatchError(error);
            });

            
    };



    const handleCancel = () => {             
        setLoading(false);
        setBincaidRec(null);
        form.setFieldsValue(initData);
        setIsOpen(false);
        
    };

    const saveEnrollment = async(id,rec) => {
       

        const apiUrl = API_TXALERT2 + `/bincaid/${id}/enrollment`;
       
        await axios
            .post(apiUrl,rec,{ headers: { Authorization: "Bearer " + localStorage.getItem("txalert_token") } })
            .then(({ data }) => {
                if( data.status !== 'OK'){
                    setErrors(data.msg);
                    return data;
                }           
            })
            .catch((error) => {
             return {'status':'EXC','error_msg':error.response}
            }); 

            
    };

    const onFinish = () => {
        let apiUrl = API_TXALERT2 ;
       if(bincaidid === 'undefined' || typeof bincaidid === 'undefined' || bincaidid === ''){
        apiUrl = apiUrl + "/bincaid";
       }else{
        apiUrl = apiUrl + "/bincaid/"+bincaidid;
       }
       let postparams = {...bincaidrec};
     
       //remove null or undefined items
       Object.keys(postparams).forEach((item) => !postparams[item] && item!=='status' && delete postparams[item]);
      
       
         axios
             .post(apiUrl, postparams,{ headers: { Authorization: "Bearer " + localStorage.getItem("txalert_token") }})
             .then(({ data }) => {
              
                 if (data.status === "OK") {
                    
                    if(bincaidid === 'undefined' || typeof bincaidid === 'undefined' || bincaidid === ''){
                    //get the bincaid id and add enrollments;
                    let id = data.bincaid_id;
                    enrollRecords.map(async(enroll) => {
                        if(enroll.enrollment_status_id && enroll.update_date)
                        {
                          await  saveEnrollment(id,enroll);
                        }
                     });
                   
                   
                    }
                  
                    if(errors.length ===0){
                     Swal.fire({
                         icon: "success",
                         title: data.msg,
                         showConfirmButton: false,
                         timer: 2000,
                         onClose: () => {
                            fetchBincaidList();
                         },
                     });
                     handleCancel();
                    }else{
                        Swal.fire({
                            icon: "error",
                            title: t("login.dialogs.catch"),
                            text: errors.toString(),
                            showConfirmButton: true,
                        });
                    }
                     
                 } else {
                    
                     CatchError(data);
                 }
             })
             .catch((error) => {
            
                 setLoading(false);
                 CatchError(error);
             });
             setIsOpen(false);
     };
    const isOkButtonProps = () => !bincaidrec || !bincaidrec.bin || loading;
   

    return (
        <>
           <Modal
                title={bincaidid !== undefined?t("contents.bincaids.form.view_bincaid")+ ' id:'+bincaidid:t("contents.bincaids.form.add_bincaid")}
                visible={isOpen}
                width={800}
                onCancel={handleCancel}
                onOk={handleOk}
                bodyStyle={{ paddingTop: '0px' }}
                footer={[
                    <Button key="back" onClick={() => handleCancel()}>
                        {t("contents.bincaids.form.cancel")}
                    </Button>,
                     GroupAccess("SYSADMIN") ? 
                    <Button key="submit" type="primary" loading={loading} disabled={isOkButtonProps()} onClick={() => handleOk()}>
                        {loading ? t("contents.bincaids.form.submitting") : t("contents.bincaids.form.submit")}
                    </Button>:'',
                ]}
            >
               <Form {...layout} form={form} name="view_bincaid" onValuesChange={formValChange}>
                        
                        <Form.Item name="bin" label={t("contents.bincaids.form.bin")} rules={[{ required: true }]}>
                            <Input  />
                        </Form.Item>
                        <Form.Item name="caid" label={t("contents.bincaids.form.caid")} rules={[{ required: true }]}>
                            <Input  />
                        </Form.Item>
                         <Form.Item name="merchant_id" label={t("contents.bincaids.form.merchant")} rules={[{ required: true }]}>
                        <TxalertMerchantListComp placeholder={t("contents.merchants.title")}/>
                        
                        </Form.Item>
                        <Form.Item name="mcc" label={t("contents.bincaids.form.mcc")}>
                            <Input  />
                        </Form.Item>
                        <Form.Item name="vrol_profile" label={t("contents.bincaids.form.vrol_profile")}>
                            <Input  />
                        </Form.Item>
                        <Form.Item name="ruleset" label={t("contents.bincaids.form.rule_set")}>
                            <Input  />
                        </Form.Item>
                        <Form.Item name="reference_no" label={t("contents.bincaids.form.reference_no")}>
                            <Input  />
                        </Form.Item>
                        <Form.Item name="comment" label={t("contents.bincaids.form.comment")}>
                            <TextArea  />
                        </Form.Item>
                        <Form.Item
                        key="status"
                        name="status"
                        label={t("contents.bincaids.form.status")}
                        valuePropName="checked"
                        style={{ marginBottom: ".5rem" }}
                    >
                        <Switch />
                    </Form.Item>
                  
                        
                    </Form>
                     
                    {(bincaidid === 'undefined' || typeof bincaidid === 'undefined' || bincaidid === '')?
                    <div style={{ width: "100%" }}>
                            <TxalertEnrollmentInputTbl type="bincaid" id=''  style={{ width: "100%" }} onChange={SaveEnrollRecords} />
                        </div>:''}
            </Modal>
        </>
    );
};

export default Txalert_BinCaidModal;
