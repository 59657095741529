import React, { useState } from "react";
import { DatePicker, Button, Space } from "antd";
import { CalendarOutlined, SearchOutlined } from "@ant-design/icons";
import moment from "moment";

const { RangePicker } = DatePicker;

const ColumnFilterDateProps = (dataIndex, t = null) => {
    const [search, setSearch] = useState([null, null]);

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        if (selectedKeys.length > 0)
            if (moment.isMoment(selectedKeys[0])) setSearch(selectedKeys);
            else setSearch([moment(new Date(selectedKeys[0])), moment(new Date(selectedKeys[1]))]);
        else setSearch([null, null]);
    };

    const handleReset = (clearFilters) => {
        clearFilters();
        setSearch([null, null]);
    };

    return {
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <div style={{ width: 250, marginBottom: 8 }}>
                    <RangePicker
                        value={
                            selectedKeys.length > 0
                                ? moment.isMoment(selectedKeys[0])
                                    ? selectedKeys
                                    : [moment(new Date(selectedKeys[0])), moment(new Date(selectedKeys[1]))]
                                : []
                        }
                        onChange={(e) => setSelectedKeys(e)}
                        showTime
                    />
                </div>
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 121 }}
                    >
                        {t ? t("tables.actions.search") : "Search"}
                    </Button>
                    <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 121 }}>
                        {t ? t("tables.actions.reset") : "Reset"}
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => <CalendarOutlined style={{ color: filtered ? "#1890ff" : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex]
                ? moment(record[dataIndex]).isBetween(
                      moment.isMoment(search[0]) ? moment(search[0]).format() : null,
                      moment.isMoment(search[1]) ? moment(search[1]).format() : null
                  )
                : null,
    };
};

export default ColumnFilterDateProps;
