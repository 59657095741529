const SortOrder = (sortedInfo, dataIndex) => {
    if (sortedInfo) {
        if (Array.isArray(sortedInfo)) {
            let sortedFound = sortedInfo.find((column) => column.columnKey === dataIndex);
            return sortedFound ? sortedFound.order : null;
        } else {
            return (sortedInfo.columnKey === dataIndex && sortedInfo.order) || null;
        }
    }
    return null;
};

export default SortOrder;