import React, { useState, useEffect} from "react";
import { Table, PageHeader, Card, Form, Select, Row, Col, Button, Space, Input,Typography, Tooltip } from "antd";
import { SearchOutlined} from "@ant-design/icons";
import CustomPaginationV2 from "../../../helper/Table/CustomPaginationV2";
import { API_TXALERT2 } from "../../../config/config";
import ColumnResizable from "../../../helper/Table/ColumnResizable";
import MergeColumns from "../../../helper/Table/MergeColumns";
import DataFetchHookTxAlert from "../../../helper/DataFetchHookTxAlert";
import TxalertERMatchProcess from "../Components/ERMatchProcess";
import CustomDateRangeV3 from "../../../helper/Table/CustomDateRangeV3";
import moment from "moment";
import TxalertMerchantListDropdown from "../Components/MerchantListDropdown";
import TextSorter from "../../../helper/Table/TextSorter";
import DraggableModal from "../../../helper/DraggableModal";
import ExportModalV3 from "../../../helper/ExportModalV3";

const { Paragraph } = Typography;

const DATA_URL = API_TXALERT2 + "/express_resolve_tx_matches";
const DATA_URL_EXPORT = API_TXALERT2 + "/express_resolve_tx_matches/reports";
const { Option } = Select;

const MAX_DATERANGE = 3;
const DEFAULT_DATE_RANGE = 7;
const DEFAULT_DATE_PERIOD = "days";
const START_DATE = moment().subtract(DEFAULT_DATE_RANGE, DEFAULT_DATE_PERIOD);
const END_DATE = moment();

const Txalert_ERTransactionMatches= (props) => {
    const [{ data, totalResult, isLoading, isError, errorMsg, requestData, isUpdated }, setUrl, setRequestData] = DataFetchHookTxAlert(DATA_URL,"GET", localStorage.getItem("txalert_token")); // eslint-disable-line
    const [formData, setFormData] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    const [id, setId] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const { t } = props;
    const [dates, setDates] = useState([START_DATE, END_DATE]);
    
    const [isDateExceeded, setIsDateExceeded] = useState(false);
    const [contentmodal, setContentModal] = useState({ title: "", content: "" });
    const [isContentModalVisible, setIsContentModalVisible] = useState(false);


    const initFormData = {
        dateRange: [START_DATE, END_DATE],
        keyword: "",
        field: null,
        status:null,
        merchant:undefined
        
    };

    const [form] = Form.useForm();

    useEffect(() => {
       
        formValInit();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const getList = () => {
        
        let searchData ={};
        setRequestData({ ...requestData, ...searchData,  page: 1 });
      
    };

    const showModal = (selectedid) =>{
        setId(selectedid);
        setIsOpen(true);
        
      };
    

    const formValInit = () => {
        setFormData(initFormData);
        form.setFieldsValue(initFormData);
    };

    const clearAll = () => {
        setCurrentPage(1);
        setDates([START_DATE, END_DATE]);
        setFormData(initFormData);
        setRequestData({});
        form.setFieldsValue(initFormData);
    };

    const formValChange = (chgV, allV) => {
        setFormData(allV);
    };

    

    const onFinish = () => {
        let startDate = null;
        let endDate = null;
        let searchData = {};
        let searchkeyword = formData.keyword ;
        searchkeyword = searchkeyword? searchkeyword.toString().trim() : null
        if (formData) {
            if (dates) {
                startDate = dates[0] ? dates[0] : null;
                endDate = dates[1] ? dates[1] : null;
            }
            searchData = {                              
                date_from:startDate.format('YYYY-MM-DD'),
                date_to:endDate.format('YYYY-MM-DD')
            };
            if(formData.field)
            {
                searchData={...searchData, [formData.field]: searchkeyword};
            }
            if(formData.merchant)
            {
                searchData={...searchData,merchant_id:formData.merchant};
            }
            if(formData.status)
            {
                searchData={...searchData,status:formData.status};
            }
        }
        
        // Reset Page Index to 1
        setCurrentPage(1);
        setRequestData(searchData);
    };

    const columns = [
        {
            dataIndex: "tx_match_id",
            key: "tx_match_id",
            title: t("tables.fields.id"),
            width: 50,
            sorter: {
                compare: (a, b) => a.tx_match_id - b.tx_match_id,
            },
            render: (id, record) => (
                <a href={`/ertransactionmatch/${id}`} target="_blank" rel="noopener noreferrer">
                    {id}
                </a>
            ),
        },
        {
            dataIndex: "alert_id",
            key: "alert_id",
            title: t("tables.fields.alertid"),
            width: 150,
            sorter: {
                compare: (a, b) => a.alert_id-b.alert_id
            },
            render: (id, record) => (
                <a href={`/alertdetail/${id}`} target="_blank" rel="noopener noreferrer">
                    {id}
                </a>
            ),
        },
        {
            dataIndex: "gateway_tx_status",
            key: "gateway_tx_status",
            title: t("tables.fields.gateway_tx_status"),
            width: 150,
            sorter: {
                compare: (a, b) => TextSorter(a.gateway_tx_status,b.gateway_tx_status)
            },
        },
        {
            dataIndex: "gateway_tx_id",
            key: "gateway_tx_id",
            title: t("tables.fields.gateway_tx_id"),
            width: 150,
            render: (gateway_tx_id, record) => (
                <Tooltip title={gateway_tx_id}>
                    <Button
                        type="link"
                        size="small"
                        style={{whiteSpace: "normal",height:'auto',marginBottom:'10px',maxWidth:"100%"}}
                        onClick={() => showContentModal({ title: t("contents.possibletransactionmatches.transaction_info"), content: record.transaction_info })}
                    >
                        <span style={{overflow: "hidden",textOverflow: "ellipsis",whiteSpace: "nowrap",display: "block"}}>{gateway_tx_id}</span>
                    </Button>
                </Tooltip>
            ),
            sorter: {
                compare: (a, b) => TextSorter(a.gateway_tx_id,b.gateway_tx_id)
            }
        },
        {
            dataIndex: "match_score",
            key: "match_score",
            title: t("tables.fields.match_score"),
            width: 150,
            sorter: {
                compare: (a, b) => a.match_score - b.match_score
            },
            render: (match_score, record) => (
                <Tooltip title={match_score}>
                    <Button
                        type="link"
                        size="small"
                        style={{whiteSpace: "normal",height:'auto',marginBottom:'10px',maxWidth:"100%"}}
                        onClick={() => showContentModal({ title: t("tables.fields.match_score"), content: record.match_info })}
                    >
                        <span style={{overflow: "hidden",textOverflow: "ellipsis",whiteSpace: "nowrap",display: "block"}}>{match_score}</span>
                    </Button>
                </Tooltip>
            )
        },
        {
            dataIndex: "outcome",
            key: "outcome",
            title: t("tables.fields.outcome"),
            width: 150,
            sorter: {
                compare: (a, b) => TextSorter(a.outcome,b.outcome)
            }
        },
        {
            dataIndex: "marked_alert",
            key: "marked_alert",
            align: "left",
            title: t("tables.fields.marked_alert"),
            width: 150,
            render: (text, record) => (
                record.marked_alert === 1?'Yes':'No'
            ) ,
            sorter: {
                compare: (a, b) => a.marked_alert-b.marked_alert
            }
        },
        {
            dataIndex: "action",
            title: t("tables.fields.action"),
            align: "left",
            width: 100,
            render: (text, record) => (
                <Space size="middle">
                    <Button type="link" onClick={() => showModal(record.tx_match_id)} >
                        Process this match
                    </Button>
                </Space>
            ),
        }
    ];

    const getModalComponent = () => (
        <DraggableModal
            name={contentmodal.title}
            visible={isContentModalVisible}
            onOk={handleOk}
            onCancel={handleCancel}
            width={800}
            footer={[
                <Button key="submit" type="primary" onClick={handleOk}>
                    {t("tables.actions.ok")}
                </Button>,
            ]}
            context={
                <Typography>
                    <Paragraph className="well">
                        {IsJsonString(contentmodal.content) ? JSON.stringify(JSON.parse(contentmodal.content), null, "\t") : contentmodal.content}
                    </Paragraph>
                </Typography>
            }
        />
    );

    
   
    const showContentModal = (params) => {
        setContentModal(params);
        setIsContentModalVisible(true);
    };

    const handleOk = () => {
        setIsContentModalVisible(false);
    };

    const handleCancel = () => {
        setIsContentModalVisible(false);
    };

    const IsJsonString = (str) => {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    };

    const getDataSource = (datalist) => (datalist && Array.isArray(datalist) ? datalist : []);

    const AdvancedSearchForm = () => (
        <div className="search-wrap">
            <Card title={t("tables.actions.search")} size="small">
                <Form form={form} size="large" name="advanced_search" className="ant-advanced-search-form" onValuesChange={formValChange} onFinish={onFinish}>
                    <Row gutter={[16, 16]}>
                    <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                            <Form.Item name="dateRange"
                                rules={[
                                    {
                                        validator: () => {
                                            if(isDateExceeded){
                                                return Promise.reject(t('max_3_months'));
                                            }else if((dates[0] && !dates[1]) || (!dates[0] && dates[1])){
                                                return Promise.reject(t('min_1_day'));
                                            }else{
                                                return Promise.resolve();
                                            }
                                        }
                                    }
                                ]}
                            >
                                <CustomDateRangeV3 t={t} setIsDateExceeded={setIsDateExceeded} state={{ dates, setDates }} unit="months" limit={MAX_DATERANGE} />
                            </Form.Item>
                        </Col>
                    <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 6 }} xl={{ span: 5 }}>
                            <Form.Item name="field">
                                <Select style={{ width: "100%" }} placeholder={t("tables.fields.field")}>
                                    <Option value="provider_case_id">{t("tables.fields.provider_caseid")}</Option>
                                    <Option value="card_number">{t("tables.fields.card_number")}</Option>
                                    <Option value="alert_id">{t("tables.fields.alertid")}</Option>

                                </Select>
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12}} lg={{ span: 6 }} xl={{ span: 8 }}>
                            <Form.Item name="keyword">
                                <Input placeholder={t("tables.fields.keyword")} />
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                            <Form.Item name="merchant">
                                <TxalertMerchantListDropdown mode="multiple" placeholder="Merchants" showInactiveOption={true}/>
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 6 }} xl={{ span: 5 }}>
                            <Form.Item name="status">
                                <Select style={{ width: "100%" }} placeholder={t("tables.fields.status")}>
                                    <Option value="processed">{t("tables.fields.actioned")}</Option>
                                    <Option value="matched">{t("tables.fields.not_actioned")}</Option>
                                    <Option value="new">{t("tables.fields.new")}</Option>

                                </Select>
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6, offset: 12 }} lg={{ span: 6 }} xl={{ span: 3, offset: 4 }}>
                            <Form.Item>
                                <Button type="secondary" onClick={() => clearAll()} block>
                                    {t("tables.actions.clear_all")}
                                </Button>
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 4 }}>
                            <Form.Item>
                                <Button type="primary" htmlType="submit" block>
                                    <SearchOutlined /> {t("tables.actions.search")}
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Card>
        </div>
    );

    const getHeaderTable = () => (
        <div className="custom-table--header d-flex justify-content-between align-items-center">
            <h3 className="table-title">{t("contents.txalert_admin.ertransactionmatches")}</h3>

            <ExportModalV3
                t={t}
                keyname="export_all"
                title={t("tables.actions.export")}
                data={requestData}
                data_url={DATA_URL_EXPORT}
                headers={exportHeaders}
                fieldHeadersOptional={exportHeadersOptional}
                txalert_token = {true}
                filename={`ermatches_${moment().format("YYMMDD_HHmmss_SSSS")}.csv`}
                style={{ marginLeft: 8 }}
            />
        </div>
    );

    const exportHeadersOptional = [];
    const exportHeaders = [
        { label: "ID", key: "ER_TM.tx_match_id" },
        { label: "Alert ID", key: "ER_TM.alert_id" },
        { label: "Transaction Status", key: "ER_TM.gateway_tx_status" },
        { label: "Gateway Transaction ID", key: "ER_TM.gateway_tx_id" },
        { label: "Match Score", key: "ER_TM.match_score" },
        { label: "Outcome", key: "al.outcome" },
        { label: "Marked Alert", key: "ER_TM.marked_alert" }
    ];

    return (
        <div>
            <PageHeader title={t("contents.txalert_admin.ertransactionmatches")}></PageHeader>
            {AdvancedSearchForm()}
            <Table
                size="small"
                loading={isLoading}
                components={{
                    header: {
                        cell: ColumnResizable,
                    },
                }}
                
                columns={MergeColumns(columns)}
                title={() => getHeaderTable()}
                rowKey="tx_match_id"
                className="table-v3 custom-pagination custom-pagination-v3"
                dataSource={getDataSource(data.datalist)}
                pagination={CustomPaginationV2({ totalResult, currentPage, requestData, setRequestData, setCurrentPage })}

            />
             {getModalComponent()}
            <TxalertERMatchProcess t={t} id={id} setId={setId} isOpen={isOpen} setIsOpen={setIsOpen} fetchList={getList} />
        </div>
    );
};


export default Txalert_ERTransactionMatches;
