import React, { useState, useEffect } from "react";
import { Form, Input, Switch, Button,Modal} from "antd";
import { API_TXALERT2 } from "../../../config/config";
import axios from "axios";
import Swal from "sweetalert2";
import GroupAccess from "../../../helper/GroupAccess";
import CatchError from "../../../helper/CatchError";

const Txalert_AlertProvider = (props) => {
    const { t, id,setId,isOpen,setIsOpen, fetchList } = props;
    const [details, setDetails] = useState(null);
    const [loading, setLoading] = useState(false);
    const [componentDisabled, setComponentDisabled] = useState(GroupAccess("SYSADMIN") ? false : true); // eslint-disable-line
    
   
    const layout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };

    const initData = {
        provider_name: "",
        provider_class_file: "",
        provider_email: "",
        response_expiry:0,
        standard_alerts:0,
        rdr_alerts:0
    };

    const handleOk = () => {
        setLoading(true);
        onFinish();
    };

   

    const [form] = Form.useForm();

    const handleCancel = () => {             
        setLoading(false);
        setId('');
        form.setFieldsValue(initData);
        setIsOpen(false);
        
    };

    const formValChange = (chgV, allV) => {
       if(chgV.standard_alerts){
            setDetails({...allV,standard_alerts: chgV.standard_alerts=== true?1:0});
       }else if(chgV.rdr_alerts){
            setDetails({...allV,rdr_alerts: chgV.rdr_alerts=== true?1:0});
       }else
       {
            setDetails({...allV,rdr_alerts: allV.rdr_alerts=== true?1:0,standard_alerts: allV.standard_alerts=== true?1:0});
       }
    };

    useEffect(() => {
     
        if(isOpen){
            if (typeof id != 'undefined') {
            
                getDetails();
            }else{
                setDetails(initData);
            }
        }
    }, [isOpen]); // eslint-disable-line

   
    const getDetails = async () => {

        const api_url = API_TXALERT2 + `/alert_provider/${id}`;
        await axios
            .get(api_url, { headers: { Authorization: localStorage.getItem("txalert_token") } })
            .then(({ data }) => {
                let recdata = data;
                setDetails(recdata);              
                form.setFieldsValue({...recdata,stdalerts:recdata.standard_alerts === 1,rdralerts:recdata.rdr_alerts === 1});
            })
            .catch((error) => console.log(error));
    };

    const onFinish = () => {
        let apiUrl = API_TXALERT2 ;
      
       if(id === 'undefined' || typeof id === 'undefined' || id === ''){
        apiUrl = apiUrl + "/alert_provider";
       }else{
        apiUrl = apiUrl + "/alert_provider/"+id;
       }
         axios
             .post(apiUrl, details,{ headers: { Authorization: "Bearer " + localStorage.getItem("txalert_token") }})
             .then(({ data }) => {
                 if (data.status === "OK") {
                     Swal.fire({
                         icon: "success",
                         title: data.msg,
                         showConfirmButton: false,
                         timer: 2000,
                         onClose: () => {
                             fetchList();
                         },
                     });
                     handleCancel();
                 } else {
                    
                     CatchError(data);
                 }
             })
             .catch((error) => {
                console.log(error.response);
                 setLoading(false);
                 CatchError(error);
             });
     };
    const isOkButtonProps = () => !details || !details.provider_name || loading;
    return(
        <>
            <Modal
                title={id !== undefined?t("contents.txalert_admin.form.view_alert_provider")+ ' id:'+id:t("contents.txalert_admin.form.add_alert_provider")}
                visible={isOpen}
                width={600}
                onCancel={handleCancel}
                onOk={handleOk}
                bodyStyle={{ paddingTop: '0px' }}
                footer={[
                    <Button key="back" onClick={() => handleCancel()}>
                        {t("contents.txalert_admin.form.cancel")}
                    </Button>,
                     GroupAccess("SYSADMIN") ? 
                    <Button key="submit" type="primary" loading={loading} disabled={isOkButtonProps()} onClick={() => handleOk()}>
                        {loading ? t("contents.txalert_admin.form.submitting") : t("contents.txalert_admin.form.submit")}
                    </Button>:'',
                ]}
            >
                <Form {...layout} form={form} name="detail" onValuesChange={formValChange}  >
                    <fieldset disabled={componentDisabled}>
                        <Form.Item name="provider_name" label={t("contents.txalert_admin.form.provider_name")} rules={[{ required: true }]} style={{ marginBottom: ".5rem" }} >
                            <Input />
                        </Form.Item>
                        <Form.Item name="provider_class_file" label={t("contents.txalert_admin.form.class_file")} rules={[{ required: true }]} style={{ marginBottom: ".5rem" }} >
                            <Input />
                        </Form.Item>
                        <Form.Item name="provider_email" label={t("contents.txalert_admin.form.email")} style={{ marginBottom: ".5rem" }} >
                            <Input />
                        </Form.Item>
                        <Form.Item name="response_expiry" label={t("contents.txalert_admin.form.response_expiry")}  style={{ marginBottom: ".5rem" }} >
                            <Input />
                        </Form.Item>
                        <Form.Item
                        key="standard_alerts"
                        name="standard_alerts"
                        label={t("contents.txalert_admin.form.standard_alerts")}
                        valuePropName="checked"
                        style={{ marginBottom: ".5rem" }}
                    >
                        <Switch />
                    </Form.Item>  
                    <Form.Item
                        key="rdr_alerts"
                        name="rdr_alerts"
                        label={t("contents.txalert_admin.form.rdr_alerts")}
                        valuePropName="checked"
                        style={{ marginBottom: ".5rem" }}
                    >
                        <Switch />
                    </Form.Item>
                        <Form.Item
                            key="active"
                            name="active"
                            label={t("contents.txalert_admin.form.isactive")}
                            valuePropName="checked"
                            style={{ marginBottom: ".5rem" }}
                        >
                            <Switch />
                        </Form.Item>
                    </fieldset>
                </Form>
            </Modal>
 
        </>
    );
};

export default Txalert_AlertProvider;
