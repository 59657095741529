import React, { useState, useEffect, useCallback } from "react";
import { OI_URL } from "../../config/config";
import { Modal, Button, Form, Input, Select } from "antd";
import axios from "axios";
import Swal from "sweetalert2";
import { PlusOutlined } from "@ant-design/icons";
import TxFindAccessToken from "../../helper/TxFindAccessToken";

const { Option } = Select;
const DATA_URL = "/api/v1/txshield/merchant/create/";
const PROVIDERS_URL = "/api/v1/txshield/providers";

const CreateMerchant = (props) => {
    const { t, fetchMerchants } = props;
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [providerList, setProviderList] = useState([]);
    const [availableServices, setAvailableServices] = useState([]);
    // eslint-disable-next-line no-unused-vars
    const [selectedProviders, setSelectedProviders] = useState([]);
    const [isModalVisible, setIsModalVisible] = useState(false);

    const getProviderList = useCallback(async () => {
        const apiUrl = OI_URL + PROVIDERS_URL;
        try {
            const response = await axios.post(apiUrl, "", {
                headers: { Authorization: "Bearer " + await TxFindAccessToken() }
            });
            const fetchedData = response.data;
            setProviderList(fetchedData || []); 
        } catch (error) {
            Swal.fire({
                icon: "error",
                title: t("login.dialogs.catch"),
                text: error.response ? error.response.data.msg : "An error occurred",
                showConfirmButton: true,
            });
        }
    }, [t]);

    useEffect(() => {
        if (!providerList.length) {
            getProviderList();
        }
    }, [getProviderList, providerList.length]);

    const layout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };

    const [form] = Form.useForm();

    const initData = {
        name: "",
        providerId: [],
        serviceId: []
    };

    const handleOk = () => {
        setLoading(true);
        onFinish();
    };

    const showModal = () => {
        form.setFieldsValue(initData);
        setIsModalVisible(true);
    };

    const handleCancel = () => {
        setLoading(false);
        setIsModalVisible(false);
        setSelectedProviders([]);
        setAvailableServices([]);
    };

    const formValChange = (changedValues, allValues) => {
        setData(allValues);

        if (changedValues.providerId) {
            setSelectedProviders(changedValues.providerId);

            // Filter services based on selected providers only
            const validServices = providerList
                .filter(provider => changedValues.providerId.includes(provider.providerId))
                .flatMap(provider => provider.services);

            // Map and display only services that belong to the selected providers
            const serviceOptions = validServices.map(service => ({
                serviceId: service.serviceName,
                serviceName: service.serviceName
            }));

            setAvailableServices(serviceOptions);
            form.setFieldsValue({ serviceId: [] }); // Reset service selection whenever providers change
        }
    };

    const onFinish = async () => {
        const apiUrl = OI_URL + DATA_URL;

        try {
            const response = await axios.post(apiUrl, data, {
                headers: { Authorization: "Bearer " + await TxFindAccessToken() }
            });
            const responseData = response.data;

            if (responseData.status === "ok") {
                Swal.fire({
                    icon: "success",
                    title: t("contents.oi.merchant_created"),
                    showConfirmButton: false,
                    timer: 2000,
                    onClose: () => {
                        fetchMerchants();
                    },
                });
                handleCancel();
            } else {
                Swal.fire({
                    icon: "error",
                    title: t("login.dialogs.catch"),
                    text: responseData.msg ? responseData.msg : "Error creating merchant",
                    showConfirmButton: true,
                });
            }
        } catch (error) {
            setLoading(false);
            Swal.fire({
                icon: "error",
                title: t("login.dialogs.catch"),
                text: error.response ? error.response.data.msg : "An error occurred",
                showConfirmButton: true,
            });
        }
    };

    const isOkButtonProps = () => !data || !data.name || loading;

    return (
        <>
            <Button type="link" onClick={showModal} style={{ marginLeft: "auto" }}>
                <PlusOutlined /> {t("contents.oi.new_merchant")}
            </Button>
            <Modal
                title={t("contents.oi.create_merchant")}
                width={600}
                visible={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={[
                    <Button key="back" onClick={() => handleCancel()}>
                        {t("contents.chargebacks.form.cancel")}
                    </Button>,
                    <Button key="submit" type="primary" loading={loading} disabled={isOkButtonProps()} onClick={() => handleOk()}>
                        {loading ? t("contents.chargebacks.form.submitting") : t("contents.chargebacks.form.submit")}
                    </Button>,
                ]}
            >
                <Form {...layout} form={form} name="new_merchant" onValuesChange={formValChange}>
                    <Form.Item name="name" label={t("contents.oi.form.name")} rules={[{ required: true }]}>
                        <Input />
                    </Form.Item>
                    <Form.Item name="providerId" label={t("contents.oi.form.provider")} rules={[{ required: true }]}>
                        <Select
                            mode="multiple"
                            placeholder={t("contents.merchants.form.please_select")}
                        >
                            {providerList.map((item) => (
                                <Option key={item.providerId} value={item.providerId}>
                                    {item.name}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>

                    {availableServices.length > 0 && (
                        <Form.Item name="serviceId" label="Service" rules={[{ required: true }]}>
                            <Select placeholder="Select Service" mode="multiple">
                                {availableServices.map(service => (
                                    <Option key={service.serviceId} value={service.serviceId}>
                                        {service.serviceName}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    )}
                </Form>
            </Modal>
        </>
    );
};

export default CreateMerchant;
