import React, { useState, useEffect } from "react";
import moment from "moment";
import { ClockCircleOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";

const Clock = (props) => {
    let lang = localStorage.getItem("lang") ? localStorage.getItem("lang") : "en";
    let clockTextToInit = "";
    if (lang === "en") {
        clockTextToInit = moment().utc().format("ddd, lll (z, Z)");
    } else if (lang === "jp") {
        clockTextToInit = moment().utc().locale("ja").format("ddd, lll") + " " + moment().utc().format(" (z, Z)");
    } else if (lang === "cn"){
        clockTextToInit = moment().utc().locale("zh_cn").format("ddd, lll (z, Z)");
    }else if (lang === "tw"){
        clockTextToInit = moment().utc().locale("zh_tw").format("ddd, lll (z, Z)");
    }

    const { t } = props;
    const [clockText, setClockText] = useState(clockTextToInit);

    const setClock = () => {
        setInterval(() => {
            let lang = localStorage.getItem("lang") ? localStorage.getItem("lang") : "en";
            if (lang === "en") {
                setClockText(moment().utc().format("ddd, lll (z, Z)"));
            } else if (lang === "jp") {
                setClockText(moment().utc().locale("ja").format("ddd, lll") + " " + moment().utc().format(" (z, Z)"));
            } else if (lang === "cn") {
                setClockText(moment().utc().locale("zh_cn").format("ddd, lll (z, Z)"));
            } else if (lang === "tw"){
                setClockText(moment().utc().locale("zh_tw").format("ddd, lll (z, Z)"));
            }
        
        }, 1000);
    };

    useEffect(() => {
        setClock();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <>
            <Tooltip className="d-block d-lg-none" placement="bottom" title={`${t("header.clock")}: ${clockText}`}>
                <ClockCircleOutlined />
            </Tooltip>
            <span className="d-none d-lg-block">
                {t("header.clock")}: {clockText}
            </span>
        </>
    );
};

export default Clock;
