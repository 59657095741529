import React from "react";
import { Row, Col, Card } from "antd";
import showCardInfo from "./showCardInfo";

const PayeeDetails = (props) => {
    const { t, data } = props; // eslint-disable-line

    const getUserDetails = () => {
        let UserDetails = data.UserDetails ? data.UserDetails : [];
        let fields = UserDetails.map(({ name, title, value }) => ({
            label: t(`contents.transactions.form.${name}`),
            name: name,
            value: value,
        }));

        return showCardInfo(fields);
    };

    const getShippingDetails = () => {
        let ShippingDetails = data.ShippingDetails ? data.ShippingDetails : [];
        let fields = ShippingDetails.map(({ name, title, value }) => ({
            label: t(`contents.transactions.form.${name}`),
            name: name,
            value: value,
        }));

        return showCardInfo(fields);
    };

    const getOther = () => {
        let OtherDetails = data.OtherDetails ? data.OtherDetails : [];
        let fields = OtherDetails.map(({ name, title, value }) => ({
            label: t(`contents.transactions.form.${name}`),
            name: name,
            value: value,
        }));

        return showCardInfo(fields, 16, 8);
    };

    const getPayeeDetails = () => (
        <Card className="details-info--card">
            <Row gutter={0}>
                <Col span="8">
                    <h4 className="details-info--title">{t('contents.transactions.form.user_details')}</h4>
                    {getUserDetails()}
                </Col>
                <Col span="8">
                    <h4 className="details-info--title">{t('contents.transactions.form.shipping_details')}</h4>
                    {getShippingDetails()}
                </Col>
                <Col span="8">
                    <h4 className="details-info--title">{t('contents.transactions.form.other')}</h4>
                    {getOther()}
                </Col>
            </Row>
        </Card>
    );

    return getPayeeDetails();
};

export default PayeeDetails;
