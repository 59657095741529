const FormatNumber = (num, toFixed = 2) => {
    if (!num) {
        return num;
    }
    let numeric = num;
    if (toFixed && Number.isInteger(toFixed)) {
        numeric = parseFloat(num).toFixed(toFixed);
    }
    let num_parts = numeric.toString().split(".");
    num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return num_parts.join(".");
};

export default FormatNumber;
