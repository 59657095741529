
import React, { useState, useEffect } from "react";
import axios from "axios";
import { Select} from "antd";
import { API_TXALERT2 } from "../../../config/config";
import GroupAccess from "../../../helper/GroupAccess";

const { Option } = Select;
const DATA_URL = API_TXALERT2 + "/credit_request/reasons";


const Txalert_CreditStatusReasonDropdown = (props) => {

    const [data, setData] = useState([]);

    const fetchdata = async () => {
        try {

            const response = await axios.get(
                DATA_URL,
                { headers: { Authorization: "Bearer " + localStorage.getItem("txalert_token") } }
            );

            setData(response.data.list);
        } catch (error) {
            return ({ data: false });
        }

    };

    useEffect(() => {
        if (data.length === 0) {
            fetchdata();
        }
    }, [data]);

    

    const getReasons = () => {
        if(GroupAccess("SYSADMIN")) {
        return data.map((item) => (            
            <Option key={item.credit_reason_id} value={parseInt(item.credit_reason_id)}>
                {item.reason}
            </Option>
        ))
        }else{
            
            return data.map((item) => (    
                (item.admin_only !== 1)?
                <Option key={item.credit_reason_id} value={parseInt(item.credit_reason_id)}>
                {item.reason}
                </Option>:null
            ))
        }
    };

   /* const handleSelect = (event) => {
        const value = event.target.value;
        setSelectedOption(value);
        props.onChange(event); // Pass the selected value to the parent component
      };*/

    return (

        <Select
            showSearch
            showArrow="true"
            maxTagCount="responsive"
            optionFilterProp="children"
            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
            filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
            {...props}
        >    {data
            ? getReasons()
            : null}</Select>
    );
};

export default Txalert_CreditStatusReasonDropdown;
