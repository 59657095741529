import React, { useState, useEffect, useCallback } from "react";
import { PageHeader, Card, Form, Select, Row, Col, Button } from "antd";
import { THREEDS_URL } from "../../config/config";
import { RedoOutlined } from "@ant-design/icons";
import TodayChart from "./OverviewCharts/TodayChart";
import Last7DaysPerHourChart from "./OverviewCharts/Last7DaysPerHourChart";
import Last7DaysPerDayChart from "./OverviewCharts/Last7DaysPerDayChart";
import Last30DaysPerDayChart from "./OverviewCharts/Last30DaysPerDayChart";
import axios from "axios";

const { Option } = Select;
const DATA_URL = THREEDS_URL + "/api/v1/3ds/overview";

const ThreeDSecureOverview = (props) => {
    const [threedsMerchantList , setThreedsMerchantList] = useState(null);
    const [selectedSID, setSelectedSID] = useState(null);
    const [isInitData, setIsInitData] = useState(false);
    const [isDataSet, setIsDataSet] = useState(false);
    const [fetching, setFetching] = useState(false);
    const [dataToday, setDataToday] = useState(null);
    const [dataLast7DaysPerHour, setDataLast7DaysPerHour] = useState(null);
    const [dataLast7DaysPerDay, setDataLast7DaysPerDay] = useState(null);
    const [dataLast30Days, setDataLast30Days] = useState(null);
    const [todaySuccesRate, setTodaySuccesRate] = useState(0);
    const [todayFailedRate, setTodayFailedRate] = useState(0);
    const [last7daysSuccesRate, setLast7daysSuccesRate] = useState(0);
    const [last7daysFailedRate, setLast7daysFailedRate] = useState(0);
    const [last30daysSuccesRate, setLast30daysSuccesRate] = useState(0);
    const [last30daysFailedRate, setLast30daysFailedRate] = useState(0);
    const { t } = props;

    const onChange = async (value) => {
        await setSelectedSID(value);
    };

    const clearFilters = async () => {
        await setSelectedSID(null);
    };

    const setDataList = (data) => {
        let dataTodayPrepare = [];
        if (data.today.per_hour) {
            for (const [period, statusList] of Object.entries(data.today.per_hour)) {
                for (const [statusKey] of Object.entries(statusList)) {
                    let dataIndex = dataTodayPrepare.findIndex((x) => x.hour === period && x.status === statusKey);
                    if (dataIndex > -1) {
                        dataTodayPrepare[dataIndex].total += statusList[statusKey];
                    } else {
                        let summary = {
                            hour: period,
                            status: statusKey,
                            total: statusList[statusKey],
                        };
                        dataTodayPrepare.push(summary);
                    }
                }
            }
        }

        let dataLast7DaysPerHourPrepare = [];
        if (data.last7days.per_hour) {
            for (const [period, statusList] of Object.entries(data.last7days.per_hour)) {
                for (const [statusKey] of Object.entries(statusList)) {
                    let dataIndex = dataLast7DaysPerHourPrepare.findIndex((x) => x.dateAndHour === period && x.status === statusKey);
                    if (dataIndex > -1) {
                        dataLast7DaysPerHourPrepare[dataIndex].total += statusList[statusKey];
                    } else {
                        let summary = {
                            dateAndHour: period,
                            status: statusKey,
                            total: statusList[statusKey],
                        };
                        dataLast7DaysPerHourPrepare.push(summary);
                    }
                }
            }
        }

        let dataLast7DaysPerDayPrepare = [];
        if (data.last7days.per_day) {
            for (const [period, statusList] of Object.entries(data.last7days.per_day)) {
                for (const [statusKey] of Object.entries(statusList)) {
                    let dataIndex = dataLast7DaysPerDayPrepare.findIndex((x) => x.date === period && x.status === statusKey);
                    if (dataIndex > -1) {
                        dataLast7DaysPerDayPrepare[dataIndex].total += statusList[statusKey];
                    } else {
                        let summary = {
                            date: period,
                            status: statusKey,
                            total: statusList[statusKey],
                        };
                        dataLast7DaysPerDayPrepare.push(summary);
                    }
                }
            }
        }

        let dataLast30DaysPerDayPrepare = [];
        if (data.last30days.per_day) {
            for (const [period, statusList] of Object.entries(data.last30days.per_day)) {
                for (const [statusKey] of Object.entries(statusList)) {
                    let dataIndex = dataLast30DaysPerDayPrepare.findIndex((x) => x.date === period && x.status === statusKey);
                    if (dataIndex > -1) {
                        dataLast30DaysPerDayPrepare[dataIndex].total += statusList[statusKey];
                    } else {
                        let summary = {
                            date: period,
                            status: statusKey,
                            total: statusList[statusKey],
                        };
                        dataLast30DaysPerDayPrepare.push(summary);
                    }
                }
            }
        }

        setDataToday(dataTodayPrepare);
        setDataLast7DaysPerHour(dataLast7DaysPerHourPrepare);
        setDataLast7DaysPerDay(dataLast7DaysPerDayPrepare);
        setDataLast30Days(dataLast30DaysPerDayPrepare);

        setTodaySuccesRate(data.today.success_rate);
        setTodayFailedRate(data.today.failed_rate);

        setLast7daysSuccesRate(data.last7days.success_rate);
        setLast7daysFailedRate(data.last7days.failed_rate);

        setLast30daysSuccesRate(data.last30days.success_rate);
        setLast30daysFailedRate(data.last30days.failed_rate);
    };

    // Fetch 3DS MICROSERVICE Merchants
    const getThreeDSMerchantList = async () => {
        const apiUrl = THREEDS_URL + "/api/v1/merchants";
        await axios
            .post(apiUrl, {}, { headers: { Authorization: "Bearer " + localStorage.getItem("threeds_token") } })
            .then(({ data }) => {
                setThreedsMerchantList(data.list);
            })
            .catch((error) => console.log(error));
    };

    //init
    const getInitialStats = useCallback(() => {
        setIsDataSet(false);
        axios
            .post(DATA_URL, {}, { headers: { Authorization: "Bearer " + localStorage.getItem("threeds_token"), Authuser: localStorage.getItem("authuser") } })
            .then(async ({ data }) => {
                await setDataList(data);
                setIsInitData(true);
                setIsDataSet(true);
            })
            .catch(() => false);
    }, []);

    //when select sid, status, etc.
    const getStats = () => {
        setFetching(true);

        let postData = {};
        if (selectedSID) {
            postData.sid = selectedSID;
        }

        axios
            .post(DATA_URL, postData, {
                headers: { Authorization: "Bearer " + localStorage.getItem("threeds_token"), Authuser: localStorage.getItem("authuser") },
            })
            .then(async ({ data }) => {
                await setDataList(data);
                setFetching(false);
            })
            .catch(() => false);
    };

    //manual reload stats
    const fetchData = () => {
        getStats();
    };

    useEffect(() => {
        if (!isInitData || !selectedSID) {
            getInitialStats();
        }

        if (selectedSID) {
            getStats();
        }

        if(!threedsMerchantList){
            getThreeDSMerchantList();
        }

    }, [isInitData, selectedSID, threedsMerchantList]); // eslint-disable-line react-hooks/exhaustive-deps

    const AdvancedSearchForm = () => (
        <div className="search-wrap">
            <Card title={t("tables.actions.search")} size="small">
                <Form size="large" name="advanced_search" className="ant-advanced-search-form">
                    <Row gutter={[16, 16]}>
                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }}>
                            <Select
                                value={selectedSID}
                                onChange={onChange}
                                disabled={!isDataSet || fetching}
                                showSearch
                                style={{ width: "100%" }}
                                placeholder={t("contents._3dsecure_overview.filter_by_site")}
                                optionFilterProp="children"
                                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                            >
                                { threedsMerchantList ? threedsMerchantList.map((item) => (
                                    <Option key={item.sid} value={item.sid}>
                                        {item.name + ` (sid: ` + item.sid + `)`}
                                    </Option>
                                )) : null}
                            </Select>
                        </Col>
                        <Col
                            className="gutter-row"
                            xs={{ span: 24 }}
                            sm={{ span: 12 }}
                            md={{ span: 6 }}
                            lg={{ span: 5, offset: 2 }}
                            xl={{ span: 4, offset: 4 }}
                        >
                            <Button type="secondary" block onClick={clearFilters} disabled={!selectedSID || fetching}>
                                {t("tables.actions.clear")}
                            </Button>
                        </Col>
                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 5 }} xl={{ span: 4 }}>
                            <Button type="primary" block onClick={fetchData} disabled={!isDataSet || fetching}>
                                <RedoOutlined /> {t("tables.actions.update")}
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </Card>
        </div>
    );

    return (
        <div>
            <PageHeader title={t("contents._3dsecure_overview.title")}></PageHeader>
            {AdvancedSearchForm()}
            <div style={{ marginBottom: 16 }}>
                {isDataSet ? (
                    <div>
                        <Row gutter={[8, 8]} style={{ marginTop: 8 }}>
                            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                                <Row gutter={[8, 8]}>
                                    <Col span={24}>
                                        <Card size="small" title={t("contents._3dsecure_overview.today")}>
                                            {dataToday ? <TodayChart t={t} data={dataToday ? dataToday : []} /> : null}
                                            <br />
                                            {dataToday.length > 0
                                                ? t("contents._3dsecure_overview.pass") +
                                                  " (Y,A) " +
                                                  todaySuccesRate +
                                                  "% : " +
                                                  todayFailedRate +
                                                  "% " +
                                                  t("contents._3dsecure_overview.fail_others")
                                                : null}
                                        </Card>
                                    </Col>
                                    <Col span={24}>
                                        <Card size="small" title={t("contents._3dsecure_overview.last30DaysPerDay")}>
                                            {dataLast30Days ? <Last30DaysPerDayChart t={t} data={dataLast30Days ? dataLast30Days : []} /> : null}
                                            <br />
                                            {dataLast30Days.length > 0
                                                ? t("contents._3dsecure_overview.pass") +
                                                  " (Y,A) " +
                                                  last30daysSuccesRate +
                                                  "% : " +
                                                  last30daysFailedRate +
                                                  "% " +
                                                  t("contents._3dsecure_overview.fail_others")
                                                : null}
                                        </Card>
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                                <Row gutter={[8, 8]}>
                                    <Col span={24}>
                                        <Card size="small" title={t("contents._3dsecure_overview.last7DaysPerHour")}>
                                            {dataLast7DaysPerHour ? (
                                                <Last7DaysPerHourChart t={t} data={dataLast7DaysPerHour ? dataLast7DaysPerHour : []} />
                                            ) : null}
                                        </Card>
                                    </Col>
                                    <Col span={24}>
                                        <Card size="small" title={t("contents._3dsecure_overview.last7DaysPerDay")}>
                                            {dataLast7DaysPerDay ? <Last7DaysPerDayChart t={t} data={dataLast7DaysPerDay ? dataLast7DaysPerDay : []} /> : null}
                                            <br />
                                            {dataLast7DaysPerDay.length > 0
                                                ? t("contents._3dsecure_overview.pass") +
                                                  " (Y,A) " +
                                                  last7daysSuccesRate +
                                                  "% : " +
                                                  last7daysFailedRate +
                                                  "% " +
                                                  t("contents._3dsecure_overview.fail_others")
                                                : null}
                                        </Card>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>
                ) : null}
            </div>
        </div>
    );
};
export default ThreeDSecureOverview;
