import React, { useState, useEffect } from "react";
import { CBM_URL } from "../../config/config";
import { Modal, Button, Form, Input, Switch } from "antd";
import axios from "axios";
import Swal from "sweetalert2";

const ProviderModal = (props) => {
    const { t, pid, isModalVisible, handleCancel, fetchProviders } = props;
    const [provider, setProvider] = useState(null);
    const [loading, setLoading] = useState(false);

    const layout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };

    const initData = {
        name: "",
        classfile: "",
        apiurl: "",
        autoimport: false,
        isactive: true,
    };

    const [form] = Form.useForm();

    const handleOk = () => {
        setLoading(true);
        onFinish();
    };

    const formValChange = (chgV, allV) => {
        setProvider({ ...provider, ...allV });
    };

    useEffect(() => {
        if (pid) {
            setProvider(initData);
            getProviderDetail();
        }
    }, [pid]); // eslint-disable-line

    const getProviderDetail = () => {
        const apiUrl = CBM_URL + `/api/v1/provider/${pid}/details/`;
        axios
            .get(apiUrl, { headers: { Authorization: "Bearer " + localStorage.getItem("cbm_token") }})
            .then(({ data }) => {
                if(data.status === 'ok')
                {
                    let providerdata = data.details;
                    setProvider({ ...providerdata, autoimport: providerdata.autoimport === 1, isactive: providerdata.isactive === 1 });
                    form.setFieldsValue({ ...providerdata, autoimport: providerdata.autoimport === 1, isactive: providerdata.isactive === 1 });
                }else{
                    Swal.fire({
                        icon: "error",
                        title: data.msg,
                        showConfirmButton: true,
                    });
                }
            })
            .catch((error) => {
                Swal.fire({
                    icon: "error",
                    title: error.response ? error.response.data.msg : t("login.dialogs.catch"),
                    showConfirmButton: true,
                });
            });
    };

    const onFinish = async () => {
        const apiUrl = CBM_URL + `/api/v1/provider/${pid}/edit/`;

        await axios
            .post(apiUrl, provider,{ headers: { Authorization: "Bearer " + localStorage.getItem("cbm_token") }})
            .then(({ data }) => {
                if (data.status === "ok") {
                    Swal.fire({
                        icon: "success",
                        title: "Provider Updated",
                        showConfirmButton: false,
                        timer: 2000,
                        onClose: () => {
                            fetchProviders();
                        },
                    });
                    handleCancel();
                } else {
                    Swal.fire({
                        icon: "error",
                        title: data.msg ? data.msg : "",
                        showConfirmButton: true,
                    });
                }
            })
            .catch((error) => {
                setLoading(false);
                Swal.fire({
                    icon: "error",
                    title: error.response ? error.response.data.msg : t("login.dialogs.catch"),
                    showConfirmButton: true,
                });
            });
        setLoading(false);
    };

    const isOkButtonProps = () => !provider || !provider.name || !provider.classfile || loading;

    return (
        <>
            <Modal
                title={t("contents.chargebacks.view_provider")}
                visible={isModalVisible}
                width={600}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={[
                    <Button key="back" onClick={() => handleCancel()}>
                        {t("contents.chargebacks.form.cancel")}
                    </Button>,
                    <Button key="submit" type="primary" loading={loading} disabled={isOkButtonProps()} onClick={() => handleOk()}>
                        {loading ? t("contents.chargebacks.form.submitting") : t("contents.chargebacks.form.submit")}
                    </Button>,
                ]}
            >
                <Form {...layout} form={form} name="new_chargeback" onValuesChange={formValChange}>
                    <Form.Item name="name" label={t("contents.chargebacks.form.name")} rules={[{ required: true }]} style={{ marginBottom: ".5rem" }}>
                        <Input />
                    </Form.Item>
                    <Form.Item name="classfile" label={t("contents.chargebacks.form.classfile")} rules={[{ required: true }]} style={{ marginBottom: ".5rem" }}>
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="apiurl"
                        label={t("contents.chargebacks.form.apiurl")}
                        rules={[{ type: "url", warningOnly: true }]}
                        style={{ marginBottom: ".5rem" }}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        key="autoimport"
                        name="autoimport"
                        label={t("contents.chargebacks.form.autoimport")}
                        valuePropName="checked"
                        style={{ marginBottom: ".5rem" }}
                    >
                        <Switch />
                    </Form.Item>
                    <Form.Item
                        key="isactive"
                        name="isactive"
                        label={t("contents.chargebacks.form.isactive")}
                        valuePropName="checked"
                        style={{ marginBottom: ".5rem" }}
                    >
                        <Switch />
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};

export default ProviderModal;
