import React, { useState } from "react";
import DataFetchHook from "../../helper/DataFetchHook";
import ColumnSearchProps from "../../helper/Table/ColumnSearchProps";
import FilterSorterList from "../../helper/Table/FilterSorterList";
import CustomPagination from "../../helper/Table/CustomPagination";
import ColumnFilterDateProps from "../../helper/Table/ColumnFilterDateProps";
import { Table, PageHeader, Button } from "antd";
import moment from "moment";

const DATA_URL = "/refunds";

const RefundList = (props) => {
    const [{ data }] = DataFetchHook(DATA_URL);
    const [filteredInfo, setFilteredInfo] = useState({});
    const [sortedInfo, setSortedInfo] = useState({});
    const { t } = props;

    const specificFilters = {
        timestamp: "moment",
    };

    const handleChange = (pagination, filters, sorter, { currentDataSource }) => {
        setFilteredInfo(filters);
        setSortedInfo(sorter);
    };

    const clearFilters = () => {
        setFilteredInfo(null);
    };

    const clearAll = () => {
        setFilteredInfo(null);
        setSortedInfo(null);
    };

    const getFilteredValue = (dataIndex) => (filteredInfo ? filteredInfo[dataIndex] || null : null);

    const getSortOrder = (dataIndex) => {
        if (sortedInfo) {
            if (Array.isArray(sortedInfo)) {
                let sortedFound = sortedInfo.find((column) => column.columnKey === dataIndex);
                return sortedFound ? sortedFound.order : null;
            } else {
                return (sortedInfo.columnKey === dataIndex && sortedInfo.order) || null;
            }
        }
        return null;
    };

    const columns = [
        {
            dataIndex: "txid",
            key: "txid",
            title: t("tables.fields.txid"),
            filteredValue: getFilteredValue("txid"),
            ...ColumnSearchProps("txid", !!getFilteredValue("txid"), t),
            sorter: {
                compare: (a, b) => a.txid - b.txid,
                multiple: 1,
            },
            sortOrder: getSortOrder("txid"),
        },
        {
            dataIndex: "tx_action",
            key: "tx_action",
            title: t("tables.fields.action"),
            sorter: {
                compare: (a, b) => a.tx_action.localeCompare(b.tx_action),
                multiple: 2,
            },
            sortOrder: getSortOrder("tx_action"),
        },
        {
            dataIndex: "response",
            key: "response",
            title: t("tables.fields.status"),
            sorter: {
                compare: (a, b) => a.response.localeCompare(b.response),
                multiple: 3,
            },
            sortOrder: getSortOrder("response"),
        },
        {
            dataIndex: "timestamp",
            key: "timestamp",
            title: t("tables.fields.time"),
            render: (timestamp) => moment(timestamp).format("DD-MMM"),
            sorter: {
                compare: (a, b) => moment(a.timestamp).diff(moment(b.timestamp)),
                multiple: 4,
            },
            sortOrder: getSortOrder("timestamp"),
            filteredValue: getFilteredValue("timestamp"),
            ...ColumnFilterDateProps("timestamp", t),
        },
        {
            dataIndex: "fees",
            key: "fees",
            title: t("tables.fields.fees"),
            sorter: {
                compare: (a, b) => a.fees - b.fees,
                multiple: 5,
            },
            sortOrder: getSortOrder("fees"),
        },
    ];

    const getDataSource = (datalist) => (datalist && Array.isArray(datalist) ? datalist : []);

    return (
        <div>
            <PageHeader title={t("contents.refunds.title")}></PageHeader>
            {FilterSorterList(filteredInfo, sortedInfo, specificFilters, t)}
            <div className="d-flex align-items-center" style={{ marginBottom: 16 }}>
                <Button style={{ marginRight: 8 }} onClick={clearFilters}>
                    {t("tables.actions.clear_filters")}
                </Button>
                <Button onClick={clearAll}>{t("tables.actions.clear_filters_sorters")}</Button>
            </div>
            <Table columns={columns} rowKey="txid"  dataSource={getDataSource(data.datalist)} onChange={handleChange} pagination={CustomPagination('refunds')}/>
        </div>
    );
};

export default RefundList;
