import React, { useState } from "react";
import { CBM_URL } from "../../config/config";
import { Modal, Button, Form, Input, Switch } from "antd";
import axios from "axios";
import Swal from "sweetalert2";
import { PlusOutlined } from "@ant-design/icons";

const DATA_URL = "/api/v1/merchant/create/";

const CreateMerchant = (props) => {
    const { t, fetchMerchants } = props;
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);

    const layout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };

    const [form] = Form.useForm();

    const initData = {
        name: "",
        alerts: false,
        transactionprocessing: false
    };

    const handleOk = () => {
        setLoading(true);
        onFinish();
    };

    const showModal = () => {
        form.setFieldsValue(initData);
        setIsModalVisible(true);
    };

    const handleCancel = () => {
        setLoading(false);
        setIsModalVisible(false);
    };

    const formValChange = (chgV, allV) => {
        setData(allV);
    };

    const onFinish = () => {
        const apiUrl = CBM_URL + DATA_URL;

        axios
            .post(apiUrl, data,{ headers: { Authorization: "Bearer " + localStorage.getItem("cbm_token") }})
            .then(({ data }) => {
                if (data.status === "ok") {
                    Swal.fire({
                        icon: "success",
                        title: "Merchant Created",
                        showConfirmButton: false,
                        timer: 2000,
                        onClose: () => {
                            fetchMerchants();
                        },
                    });
                    handleCancel();
                } else {
                    Swal.fire({
                        icon: "error",
                        title: t("login.dialogs.catch"),
                        text: data.msg ? data.msg : "",
                        showConfirmButton: true,
                    });
                }
            })
            .catch((error) => {
                setLoading(false);
                Swal.fire({
                    icon: "error",
                    title: t("login.dialogs.catch"),
                    text: error.response ? error.response.data.msg : "",
                    showConfirmButton: true,
                });
            });
    };

    const isOkButtonProps = () => !data || !data.name || loading;

    return (
        <>
            <Button type="link" onClick={showModal} style={{ marginLeft: "auto" }}>
                <PlusOutlined /> {t("contents.chargebacks.new_merchant")}
            </Button>
            <Modal
            
                title={t("contents.chargebacks.create_merchant")}
                width={600}
                visible={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={[
                    <Button key="back" onClick={() => handleCancel()}>
                        {t("contents.chargebacks.form.cancel")}
                    </Button>,
                    <Button key="submit" type="primary" loading={loading} disabled={isOkButtonProps()} onClick={() => handleOk()}>
                        {loading ? t("contents.chargebacks.form.submitting") : t("contents.chargebacks.form.submit")}
                    </Button>,
                ]}
            >
                <Form {...layout} form={form} name="new_chargeback" onValuesChange={formValChange}>
                    <Form.Item name="name" label={t("contents.chargebacks.form.name")} rules={[{ required: true }]} style={{ marginBottom: ".5rem" }}>
                        <Input />
                    </Form.Item>
                    <Form.Item key="alerts" name="alerts" label={t("contents.chargebacks.form.alerts")} valuePropName="checked" style={{ marginBottom: ".5rem" }}>
                        <Switch />
                    </Form.Item>
                    <Form.Item key="transactionprocessing"  name="transactionprocessing" label={t("contents.chargebacks.form.transactionprocessing")} valuePropName="checked" style={{ marginBottom: ".5rem" }}>
                        <Switch />
                    </Form.Item>
                    <Form.Item name="notify_url" label={t("contents.chargebacks.form.notify_url")}  style={{ marginBottom: ".5rem" }}>
                        <Input />
                    </Form.Item>
                    <Form.Item name="token" label={t("contents.chargebacks.form.token")} style={{ marginBottom: ".5rem" }}>
                        <Input />
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};

export default CreateMerchant;
