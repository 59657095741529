import React, { useState, useEffect } from "react";
import { Route, Redirect, useLocation } from "react-router-dom";

import TopbarComponent from "./Topbar";
import SidebarComponent from "./Sidebar";
import FooterComponent from "./Footer";

import Multilingual from "./../helper/Multilingual";
import SessionExpirationModal from "./../helper/SessionExpirationModal";

import { ConfigProvider, Layout, Row, Col, Button } from "antd";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";

import moment from "moment";
import md5 from "md5";

import "moment/locale/ja";
import "moment/locale/zh-cn";

import { DEV_MODE, THEME, SESSION_TIMEOUT } from "../config/config";

// import io from "socket.io-client";

const socket= null;
/*const socket = SOCKET_URL ? io(SOCKET_URL, { path: "/socket.io/", transports: ["websocket"] }) : null;

if (REALTIME_DASHBOARD) {
    let checkInterval = setInterval(() => {
        if (!SOCKET_URL) {
            clearInterval(checkInterval);
        }
        if (localStorage.loggedUser && SOCKET_URL) {
            socket.emit("client_join", { token: localStorage.access_token });
            clearInterval(checkInterval);
        }
    }, 1000);
} */

const { Header, Footer, Sider, Content } = Layout;

let location;

const DEFAULT_COLOR = "#1890ff";

const PrivateRoute = ({ component: Component, ...rest }) => {
    const [collapsed, setCollapsed] = useState(localStorage.getItem("collapsed") === "true" ? true : false);
    // eslint-disable-next-line
    const [theme, setTheme] = useState();

    const [isReadyCheckIdle, setIsReadyCheckIdle] = useState(false);
    const [isSessionExpirationModal, setIsSessionExpirationModal] = useState(false);

    const [t, locale, getDropdownSelectLanguage] = Multilingual();
    // const [theme, setTheme] = useState("#4A63FD");

    const timeoutAfter = SESSION_TIMEOUT && Number.isInteger(SESSION_TIMEOUT) && SESSION_TIMEOUT > 1 ? SESSION_TIMEOUT : 15;

    useEffect(() => {
        if (DEV_MODE) {
            // console.log("process:", process);
        }
    }, [process]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (isReadyCheckIdle) {
            // console.log('checkIdle');
            checkIdle();
        }
    }, [isReadyCheckIdle]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        dynamicHTMLTitle();
        handleCustomizeTheme();
        if (!DEV_MODE) {
            checkExpirySession();
            setIsReadyCheckIdle(true);
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const toggle = () => {
        setCollapsed(!collapsed);
        localStorage.setItem("collapsed", !collapsed);
    };

    moment.locale("en");

    location = useLocation();

    const dynamicHTMLTitle = () => {
        const { pathname } = location;
        const keys = pathname.substring(1).split("/");
        document.title = keys[0] ? t(`sidebar.${keys[0]}`) : t(`sidebar.dashboard`);
    };

    const handleCustomizeTheme = () => {
        let vars = {};
        const favicon = document.getElementById("favicon");
        favicon.href = `../themes/${THEME ? THEME : "default"}/favicon.ico`;
        // let vars = { "@primary-color": theme, "@link-color": "@primary-color" };
        if (localStorage.getItem("dashboardtheme")) {
            const dashboardtheme = JSON.parse(localStorage.getItem("dashboardtheme"));
            setTheme(dashboardtheme["@primary-color"]);
            vars = {
                ...vars,
                ...dashboardtheme,
                ...{
                    "@link-color": "@primary-color",
                    "@layout-sider-background": "@primary-color",
                    "@menu-dark-bg": "@primary-color",
                    "@menu-dark-inline-submenu-bg": "@primary-color",
                    "@menu-dark-item-active-bg":  "darken(@primary-color, 5%) !important",
                },
            };
        }
        
        // Remove by Jericho 2023-08-23 - Removing the txshield themes/reseller logo
        // if (localStorage.getItem("logo") && localStorage.getItem("logo") !== "null") {
        //     favicon.href = `data:image/png;base64,${localStorage.getItem("logo")}`;
        // }

        window.less
            .modifyVars(vars)
            .then(() => null)
            .catch((error) => {
                console.error(error);
            });
    };

    const checkIdle = () => {
        setInterval(() => {
            if (localStorage.loggedUser && localStorage.isIdle === "false") {
                let userdata = JSON.parse(localStorage.loggedUser);
                const expiry = userdata.expiry;
                if (expiry && moment(expiry).diff(moment()) < 0) {
                    setIsSessionExpirationModal(true);
                    localStorage.setItem("isIdle", true);
                }
            }
        }, 1000);
    };

    const checkExpirySession = () => {

        if (!localStorage.loggedUser) {
            localStorage.setItem("redirectLocation", location.pathname + location.search);

            removeSession();
            return false;
        }

        const usergroups = JSON.parse(localStorage.getItem("loggedUser")).groups;
        if (!usergroups) {
            removeSession();
            return false;
        }

        let userdata = JSON.parse(localStorage.loggedUser);
        const validate = userdata.validate;
        const expiry = userdata.expiry;

        delete userdata.validate;
        delete userdata.expiry;
        const checkValidate = md5(JSON.stringify(userdata));
        if (validate !== checkValidate) {
            removeSession();
            return false;
        }
        if (expiry) {
            if (moment(expiry).diff(moment().subtract(5, "minutes")) < 0) {
                removeSession();
                return false;
            } else if (moment(expiry).diff(moment()) < 0) {
                setIsSessionExpirationModal(true);
                localStorage.setItem("isIdle", true);
            } else {
                updateSession();
            }
        }
    };

    const updateSession = () => {
        let userdata = JSON.parse(localStorage.loggedUser);
        userdata = { ...userdata, expiry: moment().add(timeoutAfter, "minutes").format() };
        localStorage.setItem("loggedUser", JSON.stringify(userdata));
        localStorage.setItem("isIdle", false);
        setIsSessionExpirationModal(false);
    };

    const removeSession = () => {
        // Remove by Jericho 2023-08-23 - Clear all localStorage items instead of removing one by one
        // localStorage.removeItem("loggedUser");
        // localStorage.removeItem("access_token");
        // localStorage.removeItem("aggregates");
        // localStorage.removeItem("aggregates_updated");
        // localStorage.removeItem("logo");
        // localStorage.removeItem("dashboardtheme");
        // localStorage.removeItem("cbm_merchant");
        // localStorage.removeItem("authuser");
        // localStorage.removeItem("expiry");
        // localStorage.removeItem("isIdle");

        localStorage.clear();

        window.location.href = "/login";
    };

    const getResellerLogo = () => {
        // Remove by Jericho 2023-08-23 - Removing the txshield themes/reseller logo
        // if (localStorage.getItem("logo") && localStorage.getItem("logo") !== "null") {
        //     return <div className="logo logo-reseller" style={{ backgroundImage: `url(data:image/png;base64,${localStorage.getItem("logo")})` }}></div>;
        // }
        return (
            <div
                className="logo"
                style={{ backgroundImage: `url(../themes/${THEME ? THEME : "default"}/${collapsed ? "logo" : "payshield"}-w.png)` }}
            ></div>
        );
    };

    return (
        <ConfigProvider locale={locale}>
            <Layout className={locale.locale}>
                <Sider trigger={null} collapsible collapsed={collapsed} width={227} style={{ backgroundColor: theme }}>
                    {/* <Sider trigger={null} collapsible collapsed={collapsed} width={205} style={{ backgroundColor: "white" }}> */}
                    <div style={{padding: '8px 0'}}>
                        {getResellerLogo()}
                    </div>
                    {localStorage.loggedUser ? <SidebarComponent t={t} theme={theme} pathname={location.pathname} /> : null}
                </Sider>
                <Layout className="site-layout" onClick={(e) => (!DEV_MODE ? checkExpirySession() : null)}>
                    <Header style={{ padding: 0 }}>
                        <Row type="flex" justify="space-between">
                            <Col>
                                <Button className="trigger-menu" onClick={toggle}>
                                    {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                                </Button>
                            </Col>
                            <Col>
                                <TopbarComponent t={t} socket={socket} changeLanguage={getDropdownSelectLanguage} />
                            </Col>
                        </Row>
                    </Header>
                    <Content className="content-warp">
                        <Route
                            {...rest}
                            render={(props) =>
                                localStorage.loggedUser ? (
                                    <Component t={t} theme={theme ? theme : DEFAULT_COLOR} socket={socket} {...props} />
                                ) : (
                                    <Redirect
                                        to={{
                                            pathname: "/login",
                                            state: { from: props.location },
                                        }}
                                    />
                                )
                            }
                        />
                    </Content>
                    <Footer>
                        <FooterComponent />
                    </Footer>
                </Layout>
            </Layout>
            <SessionExpirationModal t={t} isModalVisible={isSessionExpirationModal} handleCancel={removeSession} updateSession={updateSession} />
        </ConfigProvider>
    );
};
export default PrivateRoute;
