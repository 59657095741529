import React, { useState } from "react";
import { Row, Col, Card, Typography, Button } from "antd";
// import FormatAmount from "../../../helper/FormatAmount";
// import CCMask from "../../../helper/CCMask";
import DraggableModal from "../../../helper/DraggableModal";
import { FileSearchOutlined } from "@ant-design/icons";
import { WHITELABEL_DISPLAY_NAME } from "../../../config/config";
const { Paragraph } = Typography;

const ThreeDSDetails = (props) => {
    const { t, loading, data } = props;
    const [modal, setModal] = useState({ title: "", content: "" });
    const [isModalVisible, setIsModalVisible] = useState(false);

    // const formItemLayout = {
    //     labelCol: { span: 8 },
    //     wrapperCol: { span: 16 },
    // };

    const showModal = (params) => {
        setModal(params);
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const IsJsonString = (str) => {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    };

    const getButtonModal = (params, buttonText = "") => (
        <Button type="link" size="small" onClick={() => showModal(params)}>
            <FileSearchOutlined style={{ fontSize: '18px !important', color: 'rgba(0, 0, 0, 0.85)' }}/>
        </Button>
    );

    const getModalComponent = () => (
        <DraggableModal
            name={modal.title}
            visible={isModalVisible}
            onOk={handleOk}
            onCancel={handleCancel}
            width={800}
            footer={[
                <Button key="submit" type="primary" onClick={handleOk}>
                    {t("tables.actions.ok")}
                </Button>,
            ]}
            context={
                <Typography>
                    <Paragraph className="well">
                        {IsJsonString(modal.content) ? JSON.stringify(JSON.parse(modal.content), null, "\t") : modal.content}
                    </Paragraph>
                </Typography>
            }
        />
    );

    const getThreeDSeureAuthorizationDetails = () => {
        const { txThreeDSDetails } = data;
        let fields = [
            {
                label: t("contents.3dsecure.form.threeDSIntegratorOid"),
                name: "threeDSIntegratorOid",
                value: txThreeDSDetails.authorizationDetails.threeDSIntegratorOid
            },
            {
                label: t("contents.3dsecure.form.threeDSRequestorOrderID"),
                name: "threeDSRequestorOrderID",
                value: txThreeDSDetails.authorizationDetails.threeDSRequestorOrderID
            },
            {
                label: t("contents.3dsecure.form.threeDSRequestorData"),
                name: "threeDSRequestorData",
                value: txThreeDSDetails.authorizationDetails.threeDSRequestorData
            },
            {
                label: t("contents.3dsecure.form.threeDSRequestorID"),
                name: "threeDSRequestorID",
                value: txThreeDSDetails.authorizationDetails.threeDSRequestorID
            },
            {
                label: t("contents.3dsecure.form.threeDSServerTransID"),
                name: "threeDSServerTransID",
                value: txThreeDSDetails.authorizationDetails.threeDSServerTransID
            },
            {
                label: t("contents.3dsecure.form.acsTransID"),
                name: "acsTransID",
                value: txThreeDSDetails.authorizationDetails.acsTransID
            },
            {
                label: t("contents.3dsecure.form.dsTransID"),
                name: "dsTransID",
                value: txThreeDSDetails.authorizationDetails.dsTransID
            },
            {
                label: t("contents.3dsecure.form.transStatus"),
                name: "transStatus",
                value: txThreeDSDetails.authorizationDetails.transStatus
            },
            {
                label: t("contents.3dsecure.form.transStatusReason"),
                name: "transStatusReason",
                value: txThreeDSDetails.authorizationDetails.transStatusReason
            },
            {
                label: t("contents.3dsecure.form.authenticationValue"),
                name: "authenticationValue",
                value: txThreeDSDetails.authorizationDetails.authenticationValue
            },
            {
                label: t("contents.3dsecure.form.authenticationAlgorithm"),
                name: "authenticationAlgorithm",
                value: txThreeDSDetails.authorizationDetails.authenticationAlgorithm
            },
            {
                label: t("contents.3dsecure.form.eci"),
                name: "eci",
                value: txThreeDSDetails.authorizationDetails.eci
            },
            {
                label: t("contents.3dsecure.form.version"),
                name: "version",
                value: txThreeDSDetails.authorizationDetails.version
            },
            {
                label: t("contents.3dsecure.form.errCode"),
                name: "errCode",
                value: txThreeDSDetails.authorizationDetails.errCode
            },
            {
                label: t("contents.3dsecure.form.errMsg"),
                name: "errMsg",
                value: txThreeDSDetails.authorizationDetails.errMsg
            }
        ];

        return showAuthorizationInfo(fields);
    };

    const getThreeDSeureLogDetails = () => {
        const { txThreeDSDetails } = data;
        let fields = [
            {
                label: t("contents.3dsecure.form.shield_threeds_ref_diaplay").replace('TxShield',WHITELABEL_DISPLAY_NAME),
                name: "shield_threeds_ref_diaplay",
                value: txThreeDSDetails.shield3dsTracking.shield_threeds_ref_diaplay,
            },
            {
                label: t("contents.3dsecure.form.shield_threeds_merchant_ref_display"),
                name: "shield_threeds_merchant_ref_display",
                value: txThreeDSDetails.shield3dsTracking.shield_threeds_merchant_ref_display,
            },
            {
                label: t("contents.3dsecure.form.shield_threeds_provider_ref_display"),
                name: "shield_threeds_provider_ref_display",
                value: txThreeDSDetails.shield3dsTracking.shield_threeds_provider_ref_display,
            },
            {
                label: t("contents.3dsecure.form.shield_threeds_ptxid_display").replace('TxShield',WHITELABEL_DISPLAY_NAME),
                name: "shield_threeds_ptxid_display",
                value: txThreeDSDetails.shield3dsTracking.shield_threeds_ptxid_display,
            },
            {
                label: t("contents.3dsecure.form.shield_threeds_pay_ptxid_display").replace('TxShield',WHITELABEL_DISPLAY_NAME),
                name: "shield_threeds_pay_ptxid_display",
                value: txThreeDSDetails.shield3dsTracking.shield_threeds_pay_ptxid_display,
            },
            {
                label: t("contents.3dsecure.form.threeDSShieldInitLog"),
                name: "threeDSShieldInitLog",
                value: txThreeDSDetails.shield3dsTracking.threeDSShieldInitLog
                    ? getButtonModal({ title: t("contents.3dsecure.form.threeDSShieldInitLog"), content: txThreeDSDetails.shield3dsTracking.threeDSShieldInitLog })
                    : null,
            },
            {
                offset: 12,
                label: t("contents.3dsecure.form.threeDSShieldNotifyLog"),
                name: "threeDSShieldNotifyLog",
                value: txThreeDSDetails.shield3dsTracking.threeDSShieldNotifyLog
                    ? getButtonModal({ title: t("contents.3dsecure.form.threeDSShieldNotifyLog"), content: txThreeDSDetails.shield3dsTracking.threeDSShieldNotifyLog })
                    : null,
            }
        ];

        return showLogInfo(fields);
    };

    const showLogInfo = (fields, labelSpan = 10, valueSpan = 14) => (
        <div className="details-info--text">
            {fields.map(({ name, label, value }) => (
                <Row gutter={[0, 8]} key={name}>
                    <Col key={`label-${name}`} span={labelSpan}>
                        <span className="text">
                            <b>{label}</b>
                        </span>
                    </Col>
                    <Col key={`value-${name}`} span={valueSpan}>
                        <span className="text">{value}</span>
                    </Col>
                </Row>
            ))}
        </div>
    );

    const showAuthorizationInfo = (fields, labelSpan = 10, valueSpan = 14) => (
        <div className="details-info--text">
            {fields.map(({ name, label, value }) => (
                <Row gutter={[0, 8]} key={name}>
                    <Col key={`label-${name}`} span={labelSpan}>
                        <span className="text">
                            <b>{label}</b>
                        </span>
                    </Col>
                    <Col key={`value-${name}`} span={valueSpan}>
                        <span className="text">{value}</span>
                    </Col>
                </Row>
            ))}
        </div>
    );

    const getTxDetails = () => (
        <>
            {getModalComponent()}
            <Card className="details-info--card" loading={loading}>
                <Row gutter={0}>
                    <Col span={data.tx ? 14 : 24}>
                        { data.tx ? <h4 className="details-info--title">{t("contents.3dsecure.form.3ds_authorization_details")}</h4> : null }
                        { data.tx ? ( getThreeDSeureAuthorizationDetails() ) : (<div className="text-center" style={{marginTop: 15, marginBottom: 15}}>{t('contents._3dsecure_stats.no_data')}</div>) }
                    </Col>
                    { data.tx ?(
                    <Col span="10">
                        <h4 className="details-info--title">{t("contents.3dsecure.form.3ds_log_details")}</h4>
                        {getThreeDSeureLogDetails()}
                    </Col>
                    ) : null }
                </Row>
            </Card>
        </>
    );

    return getTxDetails();
};

export default ThreeDSDetails;
