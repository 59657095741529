import React from "react";
import { Tag } from "antd";
import { SortAscendingOutlined, SortDescendingOutlined } from "@ant-design/icons";
import moment from "moment";

const FilterSorterList = (filteredInfo = null, sortedInfo = null, specificFilters = null, t = null, setFilteredInfo = null, setSortedInfo = null) => {

    const filterTagClosable = !setFilteredInfo ? false : true;
    const sorterTagClosable = !setSortedInfo ? false : true;
    
    const getTranslation = (keyword) => {
        try {
            return t(`specialset.${keyword.toLowerCase()}`);
        } catch (error) {
            return keyword;
        }
    };

    const getDatesFormat = (dates) => dates.map((date) => moment(date).format("DD MMM YYYY HH:mm:ss")).join(" ⇀ ");
    const getTextFormat = (texts) => texts.map((text) => getTranslation(text)).join(", ");

    const getFilterInfo = (filteredInfo, dataIndex) => {
        if (specificFilters) {
            switch (specificFilters[dataIndex]) {
                case "moment":
                    return getDatesFormat(filteredInfo);
                case "search":
                    return filteredInfo;
                case "join":
                    return filteredInfo.join(", ");
                default:
                    return getTextFormat(filteredInfo);
            }
        }
        return filteredInfo.join(", ");
    };

    const getFilterList = () => {

        let isFilterSelected = false;
        if(filteredInfo) {
            if(Object.keys(filteredInfo).length > 0)  {
                if(!Object.values(filteredInfo).every(x => x === null)) {
                    isFilterSelected = true;
                }
            }
        }

        return filteredInfo && Object.keys(filteredInfo).length && isFilterSelected ? (
            <div style={{ marginBottom: "1rem" }}>
                <span style={{ marginRight: 8 }}>{t("tables.filters")}:</span>
                {Object.keys(filteredInfo).map((dataIndex) =>
                    filteredInfo[dataIndex] ? (
                        <Tag key={dataIndex} closable={filterTagClosable} onClose={() => refreshFilter(dataIndex)}>
                            <span className="text-capital">{t(`tables.fields.${dataIndex.toLowerCase()}`)}:</span>{" "}
                            {getFilterInfo(filteredInfo[dataIndex], dataIndex)}
                        </Tag>
                    ) : null
                )}
            </div>
        ) : null;
    };

    const getSorterList = () => {

        let isSortActive = true;
        if(sortedInfo) {
            if(!Array.isArray(sortedInfo)) {
                if(sortedInfo.column === undefined) {
                    isSortActive = false;
                }
            }
        } else {
            isSortActive = false;
        }

        return sortedInfo && Object.keys(sortedInfo).length && isSortActive ? (
            <div style={{ marginBottom: "1rem" }}>
                <span style={{ marginRight: 8 }}>{t("tables.sorters")}:</span>
                {sortedInfo.column ? (
                    <Tag closable={sorterTagClosable} onClose={() => refreshSorter(null)}>
                        <span className="text-capital">{sortedInfo.column.title}:</span>
                        {sortedInfo.order === "ascend" ? <SortAscendingOutlined /> : <SortDescendingOutlined />}
                    </Tag>
                ) : Array.isArray(sortedInfo) ? (
                    sortedInfo.map((sort, index) => (
                        <Tag key={sort.columnKey} closable={sorterTagClosable} onClose={() => refreshSorter(index)}>
                            <span className="text-capital">{sort.column.title}:</span>
                            {sort.order === "ascend" ? <SortAscendingOutlined /> : <SortDescendingOutlined />}
                        </Tag>
                    ))
                ) : null}
            </div>
        ) : null;
    };

    const refreshFilter = (dataIndex) => {
        filteredInfo = { ...filteredInfo, [dataIndex]: null }
        if(Object.values(filteredInfo).every(x => x === null)) {
            setFilteredInfo(null);
        } else {
            setFilteredInfo(filteredInfo);
        }
    }

    const refreshSorter = (dataIndex) => {
        if(dataIndex > -1 && dataIndex !== null) {
            sortedInfo = sortedInfo.filter(function(item, index){ return index !== dataIndex })
            setSortedInfo(sortedInfo);
        } else {
            setSortedInfo(null);
        }        
    }

    return (
        <div className="d-flex flex-wrap">
            {getFilterList()}
            {getSorterList()}
        </div>
    );
};

export default FilterSorterList;
