import React, { Component } from "react";
import { REALTIME_DASHBOARD } from "../../config/config";
import { Menu, Dropdown, Row, Col, Modal, Button } from "antd";
import { ExclamationCircleOutlined, PoweroffOutlined, UserOutlined, DownOutlined } from "@ant-design/icons";
import Clock from "./Clock";

const { confirm } = Modal;

class Topbar extends Component {
    showLogoutConfirm = (question, yes, no, socket) => {
        confirm({
            title: question,
            icon: <ExclamationCircleOutlined />,
            okText: yes,
            okType: "danger",
            cancelText: no,
            onOk() {
                if (REALTIME_DASHBOARD && socket) {
                    socket.emit("client_leave", { token: localStorage.access_token });
                }
                window.location.href = "/logout";
            },
            onCancel() {
                return false;
            },
        });
    };

    render() {
        const { t, socket, changeLanguage } = this.props;
        const c = {
            qestion: t("header.menu.logout.question"),
            yes: t("header.menu.logout.yes"),
            no: t("header.menu.logout.no"),
        };

        let menuItems = [
            {
                key: "profile",
                icon: <UserOutlined />,
                label: (
                    <a className="nav-link" href="/profile">
                        <span>{ t("header.menu.profile")}</span>
                    </a>
                )
            },
            {
                key: "logout",
                icon: <PoweroffOutlined />,
                label:t("header.menu.logout.title"),
                onClick:() => this.showLogoutConfirm(c.qestion, c.yes, c.no, socket)
            }];

        const menu = (
            <Menu theme="light" items={menuItems}>                
            </Menu>
        );

        return (
            <div style={{ padding: "0 15px" }}>
                <Row type="flex" gutter={[8, 0]} justify="start" align="middle">
                    <Col>
                        <Clock t={t} />
                    </Col>
                    <Col>
                        <Dropdown overlay={menu} placement="bottomRight">
                            <Button type="text" style={{ padding: "4px 4px 4px 10px" }}>
                                <span className="d-none d-md-inline-block">
                                    {localStorage.getItem("loggedUser") ? JSON.parse(localStorage.getItem("loggedUser")).fullName : ""}{" "}
                                </span>
                                <span className="d-inline-block d-md-none">
                                    <UserOutlined />
                                </span>
                                <DownOutlined />
                            </Button>
                        </Dropdown>
                    </Col>
                    <Col>{changeLanguage({ display: "inline-block" })}</Col>
                </Row>
            </div>
        );
    }
}
export default Topbar;
