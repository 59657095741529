import Swal from "sweetalert2";
const CatchError = (error) => {
  let error_msg = '';
  let error_details = '';
  if (error.response) {
    //console.error('Server Error:', error.response.status);
    let error_data = error.response.data;
    error_msg = error_data.error_msg.msg;
    error_details = error_data.error_msg.details;
  } else if (error.error_msg) {
    let error_data = error.error_msg;
    error_msg = error_data.msg;
    error_details = error_data.details;
  }
  else if (error.message) {
    // console.error('Error:', error.message);
    error_msg = 'Error:' + error.message;
  } else if(typeof error === "object" && error !== null) {
    error_msg = JSON.stringify(error);
  } else if (typeof error === 'string' || error instanceof String){
    error_msg = 'Error:' + error;
  }

  Swal.fire({
    icon: "error",
    title: error_msg,
    text: error_details,
    showConfirmButton: true,
  });
}
export default CatchError;