import React, { useState, useEffect } from "react";
import { Form, Input, Button, DatePicker, Select, PageHeader, Card, Row, Col,Spin } from "antd";
import { API_TXALERT2 } from "../../config/config";
import axios from "axios";
import uniqueId from 'lodash/uniqueId';
import moment from 'moment';

import Swal from "sweetalert2";
import TxalertAlertProviderLoginDropdown from "./Components/AlertProviderLoginDropdown";
import TxalertAlertTypeDropdown from "./Components/AlertTypeDropdown";
import CatchError from "../../helper/CatchError";


const { Option } = Select;
const Txalert_GenerateAlert = (props) => {
    const { t } = props;
    const [form] = Form.useForm();
    const [formData, setFormData] = useState();
    const [alertproviderloginid, setAlertproviderloginid] = useState("");
    const [alertprovider, setAlertprovider] = useState(null);
    const [alerttype, setAlertType] = useState("");
    const [loading, setLoading] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [isrdr, setIsrdr] = useState(false);


    const formLayout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };

    useEffect(() => {
        form.setFieldsValue(initData);
            setFormData(initData);
    },[]);// eslint-disable-line 



    const initData = {
        alert_provider_login_id: "",
        alert_provider:"",
        descriptor: "",
        cardnumber: "4111111111111111",
        // transaction_date: null, //moment(new Date).format('DD-MM-YYYY'),
        transaction_date: moment() && moment(moment()).isValid() ? moment(moment()) : '',
        transaction_amount:'' ,
        transaction_currency: "USD",
        alerttype: "",
        arn: "",
        authcode: "",
        disputeconditioncode: "",
        issuername: "National Bank"
    };

    const getAlertProviderLoginDetails = async (alert_provider_login_id) => {

        const api_url = API_TXALERT2 + `/alert_provider/login/${alert_provider_login_id}`;
        return await axios
            .get(api_url, { headers: { Authorization: localStorage.getItem("txalert_token") } })
            .then(({ data }) => {
               // console.log('providerlogin-apicall',data);
                return data;
            })
            .catch((error) => {
                console.log(error);
                return null;
            });
    };

    const formValChange = async (chgV, allV) => {
      
        if(chgV.alert_provider_login_id)
        {
            setAlertType(null);
            setAlertproviderloginid(chgV.alert_provider_login_id);
            
            let ap_logindata = await  getAlertProviderLoginDetails(chgV.alert_provider_login_id);
            
            if(ap_logindata)
            {
                let recdata = {'alert_provider_id':ap_logindata.alert_provider_id,
                                'provider_name':ap_logindata.provider_name};
                               
                setAlertprovider(recdata);
            }
            form.setFieldsValue({alert_type:null});  //this is to reset alert type for the new provider choice
            setFormData(allV);
        }
        else if(chgV.alert_type)
        {
            setAlertType(chgV.alert_type.label);
            setFormData({...allV,alert_type:chgV.alert_type.value});
            if(chgV.alert_type.label.toUpperCase() === 'DISPUTE RDR')
            {
                setIsrdr(true);
            }else if(chgV.alert_type.label.toUpperCase() !== 'DISPUTE RDR')
            {
                setIsrdr(false);
            }
        }else{
            setFormData(allV);
        }
        

    };
    const camelize = (str) =>{
        return str.replace(/\W+(.)/g, function(match, chr)
         {
              return chr.toUpperCase();
          });
      }

    const toXml = () => {
        const xmlData = `<?xml version="1.0" encoding="UTF-8"?>
        <EthocaAlertNotification>
        <Username>API_username_given_to_Ethoca</Username>
        <Password>password_given_to_Ethoca</Password>
        <${camelize(alerttype)}Alerts>
            <Alert>
                <EthocaID>${uniqueId()}</EthocaID>
                <AlertTimestamp>${moment().format('DD-MM-YYYY')}</AlertTimestamp>
                <Age>0</Age>
                <Issuer>CARD_ISSUER</Issuer>
                <CardNumber>${formData.cardnumber}</CardNumber>
                <TransactionTimestamp>${formData.transaction_date}</TransactionTimestamp>
                <MerchantDescriptor>${formData.descriptor}</MerchantDescriptor>
                <EthocaMerchantID>98765</EthocaMerchantID>
                <Amount>${formData.transaction_amount}</Amount>
                <Currency>${formData.transaction_currency}</Currency>
                <TransactionType>eCommerce</TransactionType>
                <InitiatedBy>not_available</InitiatedBy>
                <Source>${formData.issuername}</Source>
                <Liability>no</Liability>
                <authCode>${formData.authcode}</authCode>
                <MerchantName>ABC ONLINE</MerchantName>
                <PartnerMerchantID>456789</PartnerMerchantID>
            </Alert>
        </${camelize(alerttype)}Alerts>
    </EthocaAlertNotification>`; 
         return xmlData;
      }

      const toJson_txshield = () => {
       
      let jsondata = {             
            'provider_case_id':Date.now().toString(36),
            'card':formData.cardnumber,
            'transaction_timestamp' : formData.transaction_date,
            'descriptor' : formData.descriptor,
            'amount' : formData.transaction_amount,
            'currency' : formData.transaction_currency,
            'alert_type' : alerttype,
            'arn' : formData.arn,
            'auth_code' : formData.authcode,
            'issuername' : formData.issuername,
            'dispute_condition_code' : formData.disputeconditioncode,
            'alert_age':3,
            'customer_email':'',
            'merchant_name':'Test Merchant',
            'merchant_tx_id':Date.now(),
            'source':formData.source,
            'partner_id':'1',
            'alert_date': moment().format('YYYY-MM-DD')
      };
        return jsondata;
      }

      const toJson_rdr= () => {
    //    console.log('status',formData.status)
        let jsondata = {
            "Case ID": Date.now().toString(36),
            "Case Received Date": moment().format('YYYY-MM-DD'),
            "Case Type": "DISPUTE",
            "Case Source": formData.source,
            "Partner Name": "Payshield Pte LTD",
            "Partner ID": "10538",
            "Client Name": "86-SEA",
            "Client ID": "125578",
            "Merchant Name": formData.descriptor+" - "+formData.acquirer_bin+" - "+formData.caid,
            "Merchant ID": "953450",
            "Merchant Category Code": "1000",
            "Case - Acquirer BIN": formData.acquirer_bin,
            "Case - CAID": formData.caid,
            "Case - Descriptor Name": formData.descriptor,
            "Case - Descriptor Contact": "",
            "Transaction Date": formData.transaction_date,
            "Transaction Amount": formData.transaction_amount,
            "Transaction Currency Code": formData.transaction_currency,
            "Case Authorization Code": formData.authcode,
            "Merchant Order ID": Date.now(),
            "Acquirer Reference Number": formData.arn,
            "Card BIN": formData.cardnumber.substr(0,6),
            "Card Last Four": formData.cardnumber.substr(-4),
            "Case Amount": formData.transaction_amount,
            "Case Currency": formData.transaction_currency,
            "Payment Type": "Visa",
            "Reason Code": formData.disputeconditioncode,
            "Status": formData.status.label,
            "Status Code": formData.status.value,
            "Rule Type": "",
            "Rule Name": "",
            "Pricing Tier": formData.tier            
        };
        return jsondata;
      }

      const handleSubmit = async() => {
       
        let postparams = '';
        let header = '';
        setLoading(true);
        setDisabled(true);
        if(alertprovider.provider_name === 'ethoca')
        {
            postparams = toXml();
            header = {
                'Content-Type': 'application/xml;charset=UTF-8'
            };
        }else if(alertprovider.provider_name === 'txshield_dummy')
        {
            postparams = toJson_txshield();
            header = {
                'Content-Type': 'application/json;charset=UTF-8'
            };
        }else if(isrdr === true)
        {
            postparams = toJson_rdr();
            header = {
                'Content-Type': 'application/json;charset=UTF-8'
            };
        }else{
            Swal.fire({
                icon: "info",
                title: 'No testing available',
                showConfirmButton: true,
                onClose: () => {                            
                    return false;                           
                }                      
            });
            return false;
        }

        try {

           await axios
            .post(API_TXALERT2+'/newAlertEndpoint/'+alertproviderloginid, postparams, {
                headers: header
            })
            .then(({ data }) => {
               
                if (data.status === "OK") {
                    setLoading(false);
                    Swal.fire({
                        icon: "success",
                        title: data.msg,
                        showConfirmButton: false,
                        timer: 2000                       
                    });
                    setDisabled(false);
                } else {
                    setLoading(false);
                    CatchError(data);                    
                    setDisabled(false);
                }
            })
            .catch((error) => {
                setLoading(false);
                console.log(error.response);
                CatchError(error);
                setDisabled(false);
            });



        } catch (error) {
            console.error('Error posting data:', error);
        }
        setLoading(false);
    };
    const getDateFormat = date => {
       
        if(date === undefined)
        {
            date =moment();
        }
        var d = moment(date);;
        return date && d.isValid() ? d : '';
      };

    return (
        <>
            <PageHeader title={t('contents.txalert_admin.form.generatetestalert')}></PageHeader>
            <Card>
                <div className="info">
                    <p><strong>Info!</strong> if you want to generate duplicate alerts, use same card number, amount, arn and authcode from a previous alert<br />
                        verifi alerts cannot be tested using this test page</p>
                </div>
            </Card>

            <Form {...formLayout} form={form} name="detail" onValuesChange={formValChange}  onFinish={handleSubmit}>

                <Card title={t('contents.txalert_admin.form.generatetestalert')} className="custom-form--card">
                    <Row gutter={[36]}>
                        <Col span={12}>
                            <Form.Item name="alert_provider_login_id" key="alert_provider_login_id"  label={t("contents.txalert_admin.form.provider_name")} rules={[{ required: true }]} style={{ marginBottom: ".5rem" }} >
                                <TxalertAlertProviderLoginDropdown  placeholder={t("contents.txalert_admin.form.provider_name")} />
                            </Form.Item>
                            <Form.Item name="descriptor" key="descriptor" label={t("contents.txalert_admin.form.descriptor")} rules={[{ required: true }]} style={{ marginBottom: ".5rem" }} >
                                <Input />
                            </Form.Item>
                            {isrdr?(
                                <>
                            <Form.Item name="acquirer_bin" key="acquirer_bin" label={t("contents.txalert_admin.form.bin")} rules={[{ required:isrdr }]} style={{ marginBottom: ".5rem"}} >
                                <Input />
                            </Form.Item>
                            <Form.Item name="caid" key="caid" label={t("contents.bincaids.form.caid")} rules={[{ required: isrdr }]} style={{ marginBottom: ".5rem" }} >
                                <Input />
                            </Form.Item>
                            <Form.Item name="status">
                                <Select showArrow="true" maxTagCount="responsive" labelInValue="true" style={{ width: "100%" }} placeholder="Status">
                                    <Option value="103">Accepted (103)</Option>
                                    <Option value="900">Declined (900)</Option>
                                    <Option value="901">Declined (901)</Option>
                                    <Option value="957">Declined (957)</Option>
                                    <Option value="940">Declined (940)</Option>
                                </Select>
                            </Form.Item>
                            <Form.Item name="tier">
                                <Select showArrow="true" maxTagCount="responsive"  style={{ width: "100%" }} placeholder="Tier">
                                    <Option value="Tier 1">Tier 1</Option>
                                    <Option value="Tier 2">Tier 2</Option>
                                </Select>
                            </Form.Item>
                            </>
                            )
                            :null}
                            <Form.Item name="cardnumber" key="cardnumber" label={t("contents.txalert_admin.form.cardnumber")} rules={[{ required: true }]} style={{ marginBottom: ".5rem" }} >
                                <Input />
                            </Form.Item>
                            <Form.Item  key="transaction_date"  name="transaction_date" label={t("contents.txalert_admin.form.transaction_date")}  style={{ marginBottom: ".5rem" }} valuePropName="value" getValueProps={(value) => {
                            return { value: getDateFormat(value) };
                          }}>
                            <DatePicker format="DD-MM-YYYY"/>
                        </Form.Item>

                    
                            <Form.Item name="transaction_amount" key="transaction_amount" label={t("contents.txalert_admin.form.transaction_amount")} rules={[{ required: true }]} style={{ marginBottom: ".5rem" }} >
                                <Input />
                            </Form.Item>
                            <Form.Item name="transaction_currency" key="transaction_currency" label={t("contents.txalert_admin.form.transaction_currency")} rules={[{ required: true }]} style={{ marginBottom: ".5rem" }} >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item  key="alert_type" name="alert_type" rules={[{ required: true }]} label={t("contents.txalert_admin.form.alert_type")} style={{ marginBottom: ".5rem" }} valuePropName="value" >
                              <TxalertAlertTypeDropdown  labelInValue={true} key={alertprovider?alertprovider.alert_provider_id:''} alert_provider_id={alertprovider?alertprovider.alert_provider_id:''} placeholder="Choose Alert Type" /> 
                            </Form.Item>
                            <Form.Item name="arn" key="arn" label={t("contents.txalert_admin.form.arn")} style={{ marginBottom: ".5rem" }} >
                                <Input />
                            </Form.Item>
                            <Form.Item name="authcode" key="authcode" label={t("contents.txalert_admin.form.authcode")} style={{ marginBottom: ".5rem" }} >
                                <Input />
                            </Form.Item>
                            <Form.Item name="disputeconditioncode" key="disputeconditioncode" label={t("contents.txalert_admin.form.disputeconditioncode")} style={{ marginBottom: ".5rem" }} >
                                <Input />
                            </Form.Item>
                            <Form.Item name="issuername" key="issuername" label={t("contents.txalert_admin.form.issuername")} style={{ marginBottom: ".5rem" }} >
                                <Input />
                            </Form.Item>
                            <Button type="primary" disabled={disabled} htmlType="submit"  >
                                {t('contents.txalert_admin.form.process')}
                            </Button>
                            <Spin spinning={loading} />
                        
                        </Col>
                    </Row>
                </Card>
            </Form>

        </>
    );
};

export default Txalert_GenerateAlert;
