import React, { useState} from "react";
import DraggableModal from "../../../helper/DraggableModal";
import { Table, Button, Modal, Typography, Tooltip } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";

import axios from "axios";
import Swal from "sweetalert2";
import { API_TXALERT2, DISABLE_MATCH_REFUND } from "../../../config/config";
import DataFetchHookTxAlert from "../../../helper/DataFetchHookTxAlert";
import CatchError from "../../../helper/CatchError";

const { confirm } = Modal;
const { Paragraph } = Typography;

const DATA_URL = API_TXALERT2+"/express_resolve_tx_matches";

const Txalert_TransactionMatchesTbl = (props) => {
    const { t, alertid, alert_type, isresolved } = props;
    const [{ data, totalResult, isLoading, isError, errorMsg, requestData, isUpdated }, setUrl, setRequestData] = DataFetchHookTxAlert(`${DATA_URL}?alert_id=${alertid}`,"GET", localStorage.getItem("txalert_token"));  //eslint-disable-line
    const [modal, setModal] = useState({ title: "", content: "" });
    const [isModalVisible, setIsModalVisible] = useState(false);

   


    const showModal = (params) => {
        setModal(params);
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const IsJsonString = (str) => {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    };

    function findArrayElementByMatchid(array, matchid) {
      
        return array.find((element) => {
          return element.tx_match_id === matchid;
        })
      }
    const processMatch = (matchid) => {
       
        const datalistitem = data.datalist ? findArrayElementByMatchid( data.datalist,matchid) : null;
        let alertid = datalistitem === null? null:datalistitem.alert_id;
        let orderid = datalistitem === null? null:datalistitem.gateway_tx_id;
        let transaction_amount = datalistitem === null? null:datalistitem.transaction_amount;
        let transaction_info  = datalistitem === null? null:datalistitem.transaction_info;
        let trx_info = transaction_info === null? null:JSON.parse(transaction_info);
       
        let refundedamount = Object.keys(trx_info).length === 0 ? 0: trx_info.refundedamount?trx_info.refundedamount:0;
        
        let confirm_content_nonrdr = 
        (<div>
        <p>
            {t("contents.possibletransactionmatches.confirm.notice_1")}
            <br />
            {t("contents.possibletransactionmatches.confirm.notice_11")} <strong>${transaction_amount - refundedamount }</strong>{" "}
            {t("contents.possibletransactionmatches.confirm.notice_12")} <strong>{orderid}</strong>.
        </p>
        <p>
            {t("contents.possibletransactionmatches.confirm.notice_21")} <strong>{alertid}</strong>{" "}
            {t("contents.possibletransactionmatches.confirm.notice_22")} <br />
            {t("contents.possibletransactionmatches.confirm.outcome")} <br />
            {t("contents.possibletransactionmatches.confirm.refunded")} <br />
            {t("contents.possibletransactionmatches.confirm.reason")}
        </p>
    </div>);
     let confirm_content_rdr = 
     (
        <div>
            <p>
                {t("contents.possiblerdrtransactionmatches.confirm.notice_1")}
                <br />
                {t("contents.possiblerdrtransactionmatches.confirm.notice_11")} of order <strong>{orderid}</strong>{" "}
                {t("contents.possiblerdrtransactionmatches.confirm.notice_12")} .
            </p>
            
        </div>
    );
        confirm({
            title: t("contents.possibletransactionmatches.confirm.q_1"),
            content: alert_type.match(' rdr')?confirm_content_rdr:confirm_content_nonrdr,
            icon: <ExclamationCircleOutlined />,
            okText: t("header.menu.logout.yes"),
            okType: "danger",
            cancelText: t("header.menu.logout.no"),
            width: "650px",
            onOk() {
                return new Promise((resolve, reject) => {
                    let refundtxt = props.alert_type.match(' rdr')? 'mark':'refund';
                    axios
                        .post(
                            DATA_URL+'/'+matchid+'/'+refundtxt,
                            {},
                            { headers: { "content-type": "application/json", Authorization: "Bearer " + localStorage.getItem("txalert_token") } }
                        )
                        .then(({ data }) => {
                            if (data.status === "OK") {
                                resolve();
                                Swal.fire({
                                    icon: "success",
                                    title: (alert_type.match(' rdr'))?"Mark successful.":"Refund successful.",
                                    showConfirmButton: false,
                                    timer: 2000,
                                    onClose: () => {
                                       //refresh table
                                    },
                                });
                            } else {
                                reject();
                                CatchError(data);
                            }
                        })
                        .catch((error) => {
                            reject();
                            CatchError(error);
                        });
                });
            },
            onCancel() {
                return false;
            },
        });
    };

    const columns = [
        /*{
            dataIndex: "datecreated",
            key: "datecreated",
            width: 80,
            title: t("tables.fields.datecreated"),
            render: (datecreated) => moment(datecreated).format("DD-MMM"),
        },*/
        {
            dataIndex: "card_number",
            key: "card_number",
            title: t("tables.fields.cardnumber"),
            width: 140,
        },
        {
            dataIndex: "transaction_amount",
            key: "transaction_amount",
            title: t("tables.fields.amount"),
            align: "left",
            width: 80,
            ellipsis: true,
        },
        {
            dataIndex: "gateway_name",
            key: "gateway_name",
            title: t("tables.fields.gwname"),
            width: 130,
        },
        {
            dataIndex: "gateway_tx_id",
            key: "gateway_tx_id",
            title: t("tables.fields.gateway_tx_id"),
            width: 100,
            render: (gateway_tx_id, record) => (
                <Tooltip title={gateway_tx_id}>
                    <Button
                        type="link"
                        size="small"
                        style={{whiteSpace: "normal",height:'auto',marginBottom:'10px',maxWidth:"100%"}}
                        onClick={() => showModal({ title: t("contents.possibletransactionmatches.transaction_info"), content: record.transaction_info })}
                    >
                        <span style={{overflow: "hidden",textOverflow: "ellipsis",whiteSpace: "nowrap",display: "block"}}>{gateway_tx_id}</span>
                    </Button>
                </Tooltip>
            ),
        },
        {
            dataIndex: "match_score",
            key: "match_score",
            title: t("tables.fields.matchscore"),
            align: "center",
            width: 90,
        },
        {
            dataIndex: "gateway_tx_status",
            key: "gateway_tx_status",
            title: t("tables.fields.gateway_tx_status"),
            fixed: "right",
            width: 120
        },
        {
            dataIndex: "action",
            key: "action",
            title: t("tables.fields.action"),
            align: "left",
            fixed: "right",
            width: 90,
            render: (text, record) => ([-1, 0].includes(record.refunded) ? getActionButton(record.tx_match_id) : ""),
        },
    ];

    const getActionButton = (matchid) => (
       
        <Button size="small" onClick={() => processMatch(matchid)}>
           {(alert_type.match(" rdr")) ? t("tables.actions.mark"):t("tables.actions.resolve")}
        </Button>
    );

    const getDataSource = (datalist) =>
    datalist && Array.isArray(datalist)
    ? datalist.map((data) => ({ ...data, refundstatus: data.refundstatus ? data.refundstatus.replaceAll(" ", "_").toLowerCase() : "",alertstatus: data.alertstatus ? data.alertstatus.replaceAll(" ", "_").toLowerCase() : ""  }))
    : [];

    const getModalComponent = () => (
        <DraggableModal
            name={modal.title}
            visible={isModalVisible}
            onOk={handleOk}
            onCancel={handleCancel}
            width={800}
            footer={[
                <Button key="submit" type="primary" onClick={handleOk}>
                    {t("tables.actions.ok")}
                </Button>,
            ]}
            context={
                <Typography>
                    <Paragraph className="well">
                        {IsJsonString(modal.content) ? JSON.stringify(JSON.parse(modal.content), null, "\t") : modal.content}
                    </Paragraph>
                </Typography>
            }
        />
    );

    const getColumns = () => {
        if (!DISABLE_MATCH_REFUND && !isresolved) {
            return columns;
        } else {
            return columns.filter((column) => column.dataIndex !== "action");
        }
    };

    const isTransactionMatched = () => (data ? (data.datalist ? !!data.datalist.filter((row) => row.refundedcode === 1) : false) : false);


    return (
        <>
            <h2 style={{ marginBottom: "1.5rem" }}>
                {isTransactionMatched() ? t("contents.possibletransactionmatches.title_matched") : t("contents.possibletransactionmatches.title")}
            </h2>
            {getModalComponent()}
            <Table
                size="middle"
                columns={getColumns()}
                rowKey="tx_match_id"
                scroll={{ x: 1200 }}
                dataSource={getDataSource(data.datalist)}
                pagination={false}
            />
        </>
    );
};

export default Txalert_TransactionMatchesTbl;
