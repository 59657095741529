import React from "react";

const BreakdownByReason = (props) => {
    const { t, statsData, detailsBoxTitle } = props; // eslint-disable-line

    const getDetails = () => (
        <div className="table-v3">
            <div className="ant-table ant-table-small">
                <div className="ant-table-title">
                    <div className="custom-table--header d-flex justify-content-between align-items-center">
                        <h3 className="table-title">{t(`contents._3dsecure_stats.${detailsBoxTitle}`)}</h3>
                    </div>
                </div>
                <div className="ant-table-container">
                    <div className="ant-table-content">
                        <table style={{ tableLayout: "auto", width: "100%" }}>
                            <thead className="ant-table-thead">
                                <tr>
                                    <th className="ant-table-cell" width="20%">
                                        <div className="text-center">{t("contents._3dsecure_stats.short_reason")}</div>
                                    </th>
                                    <th className="ant-table-cell" width="20%">
                                        <div className="text-center">{t("contents._3dsecure_stats.count")}</div>
                                    </th>
                                    <th className="ant-table-cell" width="60%">
                                        {t("contents._3dsecure_stats.explanation")}
                                    </th>
                                </tr>
                            </thead>
                            <tbody className="ant-table-tbody">
                                {statsData
                                    ? Object.keys(statsData.breakdownByReason).map((item) => (
                                          <tr className="ant-table-row ant-table-row-level-0" key={item}>
                                              <td className="ant-table-cell" align="center">
                                                  {item !== "other" ? (item.length === 1 ? `0${item}` : item) : t(`contents._3dsecure_stats.other`)}
                                              </td>
                                              <td className="ant-table-cell" align="center">
                                                  {statsData.breakdownByReason[item]}
                                              </td>
                                              <td className="ant-table-cell">{t(`contents._3dsecure_stats.status_reason_explanation.${item}`)}</td>
                                          </tr>
                                      ))
                                    : null}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );

    return getDetails();
};

export default BreakdownByReason;
