import React, { useState, useEffect } from "react";
import { CBM_URL } from "../../config/config";
import { Modal, Button, Form, Input, Select,Switch } from "antd";
import axios from "axios";
import Swal from "sweetalert2";

const MerchantAlertProviderModal = (props) => {
    const { t, aploginid, merchants, alertproviders, isModalVisible, handleCancel, fetchMerchantAlertProviders } = props;
    const [merchantAlertProvider, setMerchantAlertProvider] = useState(null);
    const [loading, setLoading] = useState(false);

    const layout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };

    const initData = {
        mid: null,
        apid: null,
        auth1: "",
        auth2: "",
        auth3: "",
        token: "",
        isactive:0
    };

    const [form] = Form.useForm();

    const handleOk = () => {
        setLoading(true);
        onFinish();
    };

    const formValChange = (chgV, allV) => {
        setMerchantAlertProvider({ ...merchantAlertProvider, ...allV });
    };

    useEffect(() => {
        if (aploginid) {
            setMerchantAlertProvider(initData);
            getMerchantAlertProviderDetail();
        }
    }, [aploginid]); // eslint-disable-line

    const getMerchantAlertProviderDetail = () => {
        const apiUrl = CBM_URL + `/api/v1/alertprovider/login/${aploginid}/details/`;
        axios
            .get(apiUrl, { headers: { Authorization: "Bearer " + localStorage.getItem("cbm_token") }})
            .then(({ data }) => {
                if(data.status === 'ok')
                {
                    setMerchantAlertProvider(data.details);
                    form.setFieldsValue(data.details);
                }else
                {
                    Swal.fire({
                        icon: "error",
                        title: data.msg,
                        showConfirmButton: true,
                    });
                }
            })
            .catch((error) => {
                Swal.fire({
                    icon: "error",
                    title: error.response ? error.response.data.msg : t("login.dialogs.catch"),
                    showConfirmButton: true,
                });
            });
    };

    const onFinish = async () => {
        const apiUrl = CBM_URL + `/api/v1/alertprovider/login/${aploginid}/edit/`;

        await axios
            .post(apiUrl, merchantAlertProvider,{ headers: { Authorization: "Bearer " + localStorage.getItem("cbm_token") }})
            .then(({ data }) => {
                if (data.status === "ok") {
                    Swal.fire({
                        icon: "success",
                        title: "Merchant Alert Provider Updated",
                        showConfirmButton: false,
                        timer: 2000,
                        onClose: () => {
                            fetchMerchantAlertProviders();
                        },
                    });
                    handleCancel();
                } else {
                    Swal.fire({
                        icon: "error",
                        title: data.msg ? data.msg : "",
                        showConfirmButton: true,
                    });
                }
            })
            .catch((error) => {
               
                setLoading(false);
                Swal.fire({
                    icon: "error",
                    title: error.response ? error.response.data.error_msg.msg : t("login.dialogs.catch"),
                    showConfirmButton: true,
                });
            });
        setLoading(false);
    };

    const isOkButtonProps = () => !merchantAlertProvider || !merchantAlertProvider.mid || !merchantAlertProvider.apid || loading;

    return (
        <>
            <Modal
                title={t("contents.chargebacks.view_merchant_alertprovider")}
                visible={isModalVisible}
                width={600}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={[
                    <Button key="back" onClick={() => handleCancel()}>
                        {t("contents.chargebacks.form.cancel")}
                    </Button>,
                    <Button key="submit" type="primary" loading={loading} disabled={isOkButtonProps()} onClick={() => handleOk()}>
                        {loading ? t("contents.chargebacks.form.submitting") : t("contents.chargebacks.form.submit")}
                    </Button>,
                ]}
            >
                <Form {...layout} form={form} name="new_chargeback" onValuesChange={formValChange}>
                    <Form.Item name="mid" label={t("contents.chargebacks.form.merchant")} rules={[{ required: true }]} style={{ marginBottom: ".5rem" }} disabled={true}>
                        <Select name="mid" disabled={true}>
                            {merchants
                                ? merchants.map((merchant) => (
                                      <Select.Option key={merchant.mid} value={merchant.mid}>
                                          {merchant.name}
                                      </Select.Option>
                                  ))
                                : null}
                        </Select>
                    </Form.Item>
                    <Form.Item name="apid" label={t("contents.chargebacks.form.alertprovider")} rules={[{ required: true }]} style={{ marginBottom: ".5rem" }}  disabled ={true}>
                        <Select name="apid" disabled={true}>
                            {alertproviders
                                ? alertproviders.map((alertprovider) => (
                                      <Select.Option key={alertprovider.apid} value={alertprovider.apid}>
                                          {alertprovider.providername}
                                      </Select.Option>
                                  ))
                                : null}
                        </Select>
                    </Form.Item>
                    <Form.Item name="auth1" label={t("contents.chargebacks.form.auth1")} style={{ marginBottom: ".5rem" }}>
                        <Input />
                    </Form.Item>
                    <Form.Item name="auth2" label={t("contents.chargebacks.form.auth2")} style={{ marginBottom: ".5rem" }}>
                        <Input />
                    </Form.Item>
                    <Form.Item name="auth3" label={t("contents.chargebacks.form.auth3")} style={{ marginBottom: ".5rem" }}>
                        <Input />
                    </Form.Item>
                    <Form.Item name="token" label={t("contents.chargebacks.form.token")} style={{ marginBottom: ".5rem" }}>
                        <Input />
                    </Form.Item>
                    <Form.Item
                        key="isactive"
                        name="isactive"
                        label={t("contents.chargebacks.form.isactive")}
                        valuePropName="checked"
                        style={{ marginBottom: ".5rem" }}
                    >
                        <Switch />
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};

export default MerchantAlertProviderModal;
