import React from "react";
import { Form, Input, Button } from "antd";

const InformationForm = (props) => {
    const { t, layout, tailLayout, onValuesChange, onSubmit } = props;
    const [form] = Form.useForm();

    return (
        <Form {...layout} form={form} onValuesChange={onValuesChange} onFinish={onSubmit} style={{ padding: "15px 0" }}>
            <Form.Item name="old_password" label={t('contents.profile.form.old_password')} rules={[{ required: true, message: t('contents.profile.form.required_messages.changepass_old_required') }]}>
                <Input.Password />
            </Form.Item>
            <Form.Item
                name="new_password"
                label={t('contents.profile.form.new_password')}
                dependencies={["old_password"]}
                rules={[
                    { required: true, message: t('contents.profile.form.required_messages.changepass_new_required') },
                    ({ getFieldValue }) => ({
                        validator(rule, value) {
                            if (!value || getFieldValue("old_password") !== value) {
                                return Promise.resolve();
                            }
                            return Promise.reject(t('contents.profile.form.required_messages.changepass_same_current'));
                        },
                    }),
                ]}
            >
                <Input.Password />
            </Form.Item>
            <Form.Item
                name="confirm_password"
                label={t('contents.profile.form.confirm_password')}
                dependencies={["new_password"]}
                rules={[
                    { required: true, message: t('contents.profile.form.required_messages.changepass_confirm_required') },
                    ({ getFieldValue }) => ({
                        validator(rule, value) {
                            if (!value || getFieldValue("new_password") === value) {
                                return Promise.resolve();
                            }
                            return Promise.reject(t('contents.profile.form.required_messages.changepass_confirm_nomatch'));
                        },
                    }),
                ]}
            >
                <Input.Password />
            </Form.Item>
            <Form.Item {...tailLayout}>
                <Button type="primary" htmlType="submit">
                    {t("contents.profile.form.submit")}
                </Button>
            </Form.Item>
        </Form>
    );
};

export default InformationForm;
