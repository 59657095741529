import React, { useState } from "react";
import { Modal, Button, Tooltip } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { API_TXALERT2 } from "../../../config/config";
import axios from "axios";
import Swal from "sweetalert2";
import CatchError from "../../../helper/CatchError";

const { confirm } = Modal;

const Txalert_RepostAlert = (props) => {
    const { t, row, posturl, doFetch } = props;
    const [loading, setLoading] = useState(false);

    const showRepostConfirm = (question, yes, no) => {
        let data = {
            alert_id: row.id,
        };

        const options = {
            method: "POST",
            headers: { "content-type": "application/json", Authorization: "Bearer " + localStorage.getItem("txalert_token") },
            data: JSON.stringify(data),
            url: API_TXALERT2 + '/alert/'+row.alert_id+'/post_to_merchant_retry',
        };

        confirm({
            title: question,
            icon: <ExclamationCircleOutlined />,
            okText: yes,
            cancelText: no,
            onOk() {
                setLoading(true);
                axios(options)
                    .then(({ data }) => {
                        if (data.status === "OK") {
                            Swal.fire({
                                icon: "success",
                                title: "Repost Alert Successful",
                                text: data.msg,
                                showConfirmButton: false,
                                timer: 2000,
                                onClose: () => {
                                    doFetch();
                                },
                            });
                        } else {
                           CatchError(data);
                        }
                        setLoading(false);
                    })
                    .catch((error) => {
                        setLoading(false);
                        CatchError(error);
                    });
            },
            onCancel() {
                return false;
            },
        });
    };

    return (
        <Tooltip placement="topLeft" title={posturl ? posturl : null}>
            <Button
                key="repostAlert"
                loading={loading}
                style={{ marginRight: "0.5rem" }}
                onClick={() => showRepostConfirm("Are you sure to repost alert?", t("header.menu.logout.yes"), t("header.menu.logout.no"))}
            >
                {t("contents.alerts.repost_alert")}
            </Button>
        </Tooltip>
    );
};

export default Txalert_RepostAlert;
