import React, { useState, useEffect, useCallback} from "react";
import DataFetchHookV3 from "../../helper/DataFetchHookV3";
import { Table, PageHeader, Card, Form, Select, Row, Col, Input, Button, Cascader,Modal,Typography } from "antd";
import { SearchOutlined, CheckCircleOutlined, FieldTimeOutlined, CloseCircleOutlined } from "@ant-design/icons";
import FormatAmount from "../../helper/FormatAmount";
import FormatNumber from "../../helper/FormatNumber";
import ExportModalV3 from "../../helper/ExportModalV3";
import CustomPaginationV2 from "../../helper/Table/CustomPaginationV2";
import CustomDateRangeV3 from "../../helper/Table/CustomDateRangeV3";
// import SortOrder from "../../helper/Table/SortOrder";
import moment from "moment";
import axios from "axios";
import { API_TX, API_MERCHANT, API_DATA_SOURCE, API_RETAILER, API_SITE } from "../../config/config";
import GroupAccess from "./../../helper/GroupAccess";
import { Resizable } from "react-resizable";
import CatchError from "../../helper/CatchError";
const { Text } = Typography;

const { Option } = Select;

const DATA_URL = API_TX + "/transaction";
const DATA_URL_EXPORT = API_TX + "/reports";
const MERCHANT_DATA_URL = API_MERCHANT + "/list";
const RETAILER_DATA_URL = API_RETAILER + "/list";
const SITE_DATA_URL = API_SITE + "/list";
const PAYMENT_METHOD_URL = API_DATA_SOURCE + "/paymentmethods";
const PLID_URL = API_DATA_SOURCE + "/plids";
const SUMMARY_URL = API_MERCHANT + "/transactionsummary";

const MAX_DATERANGE = 3;
// const START_DATE = moment().subtract(MAX_DATERANGE, "months");
const DEFAULT_DATE_RANGE = 2;
const DEFAULT_DATE_PERIOD = "days";
const START_DATE = moment().subtract(DEFAULT_DATE_RANGE, DEFAULT_DATE_PERIOD);
const END_DATE = moment();

const ResizableTitle = (props) => {
    const { onResize, width, ...restProps } = props;

    if (!width) {
        return <th {...restProps} />;
    }

    return (
        <Resizable
            width={width}
            height={0}
            handle={
                <span
                    className="react-resizable-handle"
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                />
            }
            onResize={onResize}
            draggableOpts={{ enableUserSelectHack: false }}
        >
            <th {...restProps} />
        </Resizable>
    );
};

const { SHOW_CHILD } = Cascader;

const TransactionList = (props) => {
    //eslint-disable-next-line
    const [{ data, totalResult, isLoading, requestData, isUpdated }, doFetch,  setRequestData] = DataFetchHookV3(DATA_URL, "POST", {
        startDate: START_DATE,
        endDate: END_DATE,
        page: 1,
        size: 20,
        order: "DESC",
        sort_field: "timestamp"
    }); // eslint-disable-line
    const [formData, setFormData] = useState();
    // const [sortedInfo, setSortedInfo] = useState({});
    const [dates, setDates] = useState([START_DATE, END_DATE]);
    const [currentPage, setCurrentPage] = useState(1);
    // const [latestTotal, setLatestTotal] = useState(null);
    const [merchantList, setMerchantList] = useState([]);
    const [paymentMethodList, setPaymentMethodList] = useState([]);
    const [plidList, setPlidList] = useState([]);
    const [categoryList, setCategoryList] = useState([]);
    const [isSortUpdate, setIsSortUpdate] = useState(false);
    const [summary, setSummary] = useState([]);
    const { t } = props;
    const [isDateExceeded, setIsDateExceeded] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [siteList, setSiteList] = useState([]);
    const [retailerList, setRetailerList] = useState([]);
    let mid_select = null;
    let rid_select = null;

    const getmerchantList = useCallback(() => {
        axios
            .post(
                MERCHANT_DATA_URL,
                {getall:true},
                { headers: { Authorization: "Bearer " + localStorage.getItem("access_token"), Authuser: localStorage.getItem("authuser") } }
            )
            .then(async ({ data }) => {
                await setMerchantList(data.list);
            })
            .catch(() => false);
    }, []);

    const getPaymentMethodList = useCallback(() => {
        axios
            .post(
                PAYMENT_METHOD_URL,
                {},
                { headers: { Authorization: "Bearer " + localStorage.getItem("access_token"), Authuser: localStorage.getItem("authuser") } }
            )
            .then(async ({ data }) => {
                await setPaymentMethodList(data);
            })
            .catch(() => false);
    }, []);

    const getPlidList = useCallback(() => {
        axios
            .post(
                PLID_URL,
                {},
                { headers: { Authorization: "Bearer " + localStorage.getItem("access_token"), Authuser: localStorage.getItem("authuser") } }
            )
            .then(async ({ data }) => {
                let plid_array = [];
                if( Array.isArray(data) && data.length !== 0) {
                    data.forEach((item) => {
                        let existing = plid_array.filter(obj => obj.value === item.gwname);
                        if (existing.length > 0) {
                            let index = plid_array.indexOf(existing[0]);
                            let tmp = {
                                    label: item.macname+' ('+item.status+')',
                                    value: item.plid,
                                };
                            existing[0].children.push(tmp);
                            plid_array[index] = existing[0];
                        } else {
                            let newitem = {
                                label: item.gwname,
                                value: item.gwname,
                                children: [
                                    {
                                    label: item.macname+' ('+item.status+')',
                                    value: item.plid,
                                    }
                                ],
                            };
                            plid_array = [...plid_array, newitem];
                        }

                        
                    });
                }
                setPlidList(plid_array);
            })
            .catch(() => false);
    }, []);

    const getCategoryList = async () => {
        const api_url = API_DATA_SOURCE + `/sitecategory`;
        await axios
            .post(api_url, {}, { headers: { Authorization: "Bearer " + localStorage.getItem("access_token") } })
            .then(({ data }) => {
                setCategoryList(data);
            })
            .catch((error) => console.log(error));
    };

    const getRetailerList = useCallback((mid_change) => {
        axios
            .post(
                RETAILER_DATA_URL,
                { mid: mid_change },
                { headers: { Authorization: "Bearer " + localStorage.getItem("access_token"), Authuser: localStorage.getItem("authuser") } }
            )
            .then(async ({ data }) => {
                await setRetailerList(data.list);
            })
            .catch(() => false);
    }, []);

    const getSiteList = useCallback((mid_change, rid_change) => {
        axios
            .post(
                SITE_DATA_URL,
                { mid: mid_change, rid: rid_change },
                { headers: { Authorization: "Bearer " + localStorage.getItem("access_token"), Authuser: localStorage.getItem("authuser") } }
            )
            .then(async ({ data }) => {
                await setSiteList(data.list);
            })
            .catch(() => false);
    }, []);

    const onChangeMid = (mid_change) => {
        mid_select = mid_change
        getRetailerList(mid_select);
        getSiteList(mid_select, rid_select);
    }

    const onChangeRid = (rid_change) => {
        rid_select = rid_change
        getSiteList(mid_select, rid_select);
    }

    const initFormData = {
        txid: "",
        dateRange: [START_DATE, END_DATE],
        action: [],
        status: [],
        currency: [],
        order: "DESC",
        merchant: [],
        retailer: [],
        site: [],
        paymentmethod: [],
        plid: [],
        cat_id: [],
        amountoperator: '',
        amountvalue: '',
        sort_field: "timestamp",
    };

    const [form] = Form.useForm();

    useEffect(() => {
        formValInit();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        formValInit();
    }, [isSortUpdate]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleChange = (pagination, filters, sorter, { currentDataSource }) => {
        // setSortedInfo(sorter);
        var orderBy = 'ASC';
        if(sorter.order === 'descend'){
            orderBy = 'DESC';
        }
        var sortField = 'timestamp';
        if (sorter.field !== undefined){
            sortField = sorter.field;
        }
        var p = pagination.current;
        if (Object.keys(sorter).length >0 && requestData.sort_field !== sortField){
            p = 1;
            setCurrentPage(1);
        }

        setRequestData({ ...requestData, order: orderBy, sort_field: sortField, page: p, size: pagination.pageSize  });
        setIsSortUpdate(true);
    };

    const getStatus = (status) => {
        if (!status) return "";
        let statusText = status.charAt(0) + status.toLowerCase().slice(1);
        switch (status) {
            case "APPROVED":
                return (
                    <div className="d-flex align-items-center">
                        <CheckCircleOutlined style={{ color: "#52c41a", fontSize: "18px", marginRight: "4px" }} />
                        <span>{statusText}</span>
                    </div>
                );
            case "PENDING":
                return (
                    <div className="d-flex align-items-center">
                        <FieldTimeOutlined style={{ color: "#1890ff", fontSize: "18px", marginRight: "4px" }} />
                        <span>{statusText}</span>
                    </div>
                );
            case "DECLINED":
            case "PAYG_ERROR":
                return (
                    <div className="d-flex align-items-center">
                        <CloseCircleOutlined style={{ color: "#f5222d", fontSize: "18px", marginRight: "4px" }} />
                        <span>{statusText}</span>
                    </div>
                );
            default:
                return "";
        }
    };

    const exportHeaders = [
        { label: "Action", key: "txh.tx_action" },
        { label: "TXID", key: "txh.txid" },
        { label: "Amount", key: "txh.amount" },
        { label: "Currency", key: "txd.currency_code" },
        { label: "P.Amount", key: "(txd.amount_purchase_posted + COALESCE(txd.amount_shipping_posted,0) + COALESCE(txd.amount_tax_posted,0) - COALESCE(txd.amount_coupon_posted,0))" },
        { label: "Timestamp", key: "txh.timestamp" },
        { label: "pay_method", key: "pm.pay_method" },
        { label: "Payee Name", key: "COUNT(txcount.txid) AS total, CONCAT_WS(' ',txd.firstname,txd.lastname)" },
        { label: "Card No.", key: "txd.card_no" },
        { label: "Card Name", key: "txd.card_name" },
        { label: "TID", key: "txd.tid" },
        { label: "Gateway", key: "g.name" },
        { label: "Descriptor", key: "gl.descriptor" },
        { label: "Merchant", key: "m.name" },
        { label: "Site", key: "rs.name" },
        { label: "SID", key: "txd.sid" },
        // { label: "Site Receive", key: "txh.site_receive" },
        // { label: "Fees", key: "txh.fees" },
        { label: "Status", key: "txh.response" },
    ];

    const exportHeadersOptional = [];

    const [columns, setColumns] = useState([
        {
            dataIndex: "tx_action",
            key: "tx_action",
            fixed: "left",
            title: t("tables.fields.type"),
            width: 150,
            sorter: {
                compare: (a, b) => a.tx_action.localeCompare(b.tx_action),
                //     multiple: 8,
            },
            // sortOrder: SortOrder(sortedInfo, "tx_action"),
            render: (tx_action, record) => (tx_action ? tx_action.charAt(0) + tx_action.toLowerCase().slice(1) : ""),
        },        
        {
            dataIndex: "txid",
            key: "txid",
            fixed: "left",
            title: t("tables.fields.txid"),
            ellipsis: true,
            width: 150,
            sorter: {
                compare: (a, b) => a.txid - b.txid,
                //       multiple: 2,
            },
            // sortOrder: SortOrder(sortedInfo, "txid"),
            render: (id, record) => (
                <a href={`/transactions/${id}`} target="_blank" rel="noopener noreferrer">
                    {record.txid}
                </a>
            ),
        },
        {
            dataIndex: "amount",
            key: "amount",
            title: t("tables.fields.amount"),
            width: 100,
            sorter: {
                compare: (a, b) => a.amount - b.amount,
                //    multiple: 5,
            },
            // sortOrder: SortOrder(sortedInfo, "amount"),
            render: (amount, record) => FormatAmount(amount, record.currency),
        },
        {
            dataIndex: "currency_code",
            key: "currency_code",
            ellipsis: true,
            title: t("tables.fields.currency"),
            width: 100,
            sorter: {
                compare: (a, b) => a.currency_code.localeCompare(b.currency_code),
                // multiple: 4,
            },
            // sortOrder: SortOrder(sortedInfo, "currency"),
        },
        {
            dataIndex: "amount_posted",
            key: "amount_posted",
            ellipsis: true,
            title: t("tables.fields.amount_posted"),
            width: 100,
            sorter: {
                compare: (a, b) => a.amount_posted - b.amount_posted,
                // multiple: 4,
            },
            render: (amount_posted, record) => ((amount_posted && record.currency_posted !== record.currency_code) ? FormatNumber(amount_posted) + " " + record.currency_posted : ''),
        },
        {
            dataIndex: "timestamp",
            key: "timestamp",
            ellipsis: true,
            title: t("tables.fields.timestamp"),
            width: 150,
            render: (timestamp) => moment(timestamp).format("lll"),
            sorter: {
                compare: (a, b) => moment(a.timestamp).diff(moment(b.timestamp)),
                //    multiple: 4,
            },
            // sortOrder: SortOrder(sortedInfo, "timestamp"),
        },
        {
            dataIndex: "pay_method",
            key: "pay_method",
            ellipsis: true,
            title: t("tables.fields.pay_method"),
            width: 150,
            sorter: {
                compare: (a, b) => a.pay_method.localeCompare(b.pay_method),
                //       multiple: 3,
            },
            // sortOrder: SortOrder(sortedInfo, "paymentmethod"),
        },
        {
            dataIndex: "Name",
            key: "Name",
            ellipsis: true,
            title: t("tables.fields.payeeName"),
            width: 150,
            sorter: {
                compare: (a, b) => a.Name.localeCompare(b.Name),
                //       multiple: 3,
            },
            // sortOrder: SortOrder(sortedInfo, "paymentmethod"),
        },
        {
            dataIndex: "card_no",
            key: "card_no",
            title: t("tables.fields.card_no"),
            ellipsis: true,
            width: 150,
            sorter: {
                compare: (a, b) => a.card_no.localeCompare(b.card_no),
            },
        },
        {
            dataIndex: "card_name",
            key: "card_name",
            title: t("tables.fields.card_name"),
            ellipsis: true,
            width: 200,
            sorter: {
                compare: (a, b) => a.card_name.localeCompare(b.card_name),
            },
        },
        {
            dataIndex: "tid",
            key: "tid",
            title: t("tables.fields.tid"),
            ellipsis: true,
            width: 120,
            sorter: {
                compare: (a, b) => a.tid.localeCompare(b.tid),
            },
        },
        ...(GroupAccess("SYSADMIN|MERCHANT") ?
            [
                {
                    dataIndex: "gateway",
                    key: "gateway",
                    title: t("tables.fields.gateway"),
                    width: 150,
                    ellipsis: true,
                    sorter: {
                        compare: (a, b) => a.gateway.localeCompare(b.gateway),
                    },
                },
                {
                    dataIndex: "descriptor",
                    key: "descriptor",
                    title: t("tables.fields.descriptor"),
                    width: 150,
                    sorter: {
                        compare: (a, b) => a.descriptor.localeCompare(b.descriptor),
                        //    multiple: 5,
                    },
                },
                {
                    dataIndex: "merchant",
                    key: "merchant",
                    title: t("tables.fields.merchant"),
                    width: 150,
                    sorter: {
                        compare: (a, b) => a.merchant.localeCompare(b.merchant),
                        //    multiple: 5,
                    },
                },
                {
                    dataIndex: "site",
                    key: "site",
                    title: t("tables.fields.site"),
                    width: 150,
                    sorter: {
                        compare: (a, b) => a.site.localeCompare(b.site),
                        //    multiple: 5,
                    },
                },
                {
                    dataIndex: "sid",
                    key: "sid",
                    title: t("tables.fields.sid"),
                    width: 150,
                    sorter: {
                        compare: (a, b) => a.sid - b.sid,
                        //    multiple: 5,
                    },
                }
            ]
            :
            []
        ),
        {
            dataIndex: "response",
            key: "response",
            fixed: "right",
            ellipsis: true,
            title: t("tables.fields.status"),
            width: 100,
            sorter: {
                compare: (a, b) => a.response.localeCompare(b.response),
                //    multiple: 9,
            },
            // sortOrder: SortOrder(sortedInfo, "response"),
            render: (response, record) => getStatus(response),
        },
        
        // {
        //     dataIndex: "site_receive",
        //     key: "site_receive",
        //     ellipsis: true,
        //     title: t("tables.fields.site_receive"),
        //     width: 100,
        //     sorter: {
        //         compare: (a, b) => a.site_receive - b.site_receive,
        //         //    multiple: 6,
        //     },
        //     // sortOrder: SortOrder(sortedInfo, "site_receive"),
        //     render: (site_receive, record) => FormatAmount(site_receive, record.currency),
        // },
        // {
        //     dataIndex: "fees",
        //     key: "fees",
        //     ellipsis: true,
        //     title: t("tables.fields.fees"),
        //     width: 100,
        //     sorter: {
        //         compare: (a, b) => a.fees - b.fees,
        //         //     multiple: 7,
        //     },
        //     // sortOrder: SortOrder(sortedInfo, "fees"),
        //     render: (fees, record) => `$${fees}`,
        // },
    ]);

    const formValInit = () => {
        setFormData(initFormData);
        form.setFieldsValue(initFormData);
        if (merchantList.length === 0) {
            getmerchantList();
        }

        if (retailerList.length === 0) {
            getRetailerList();
        }
        if (siteList.length === 0) {
            getSiteList();
        }

        if (paymentMethodList.length === 0) {
            getPaymentMethodList();
        }

        if (plidList.length === 0) {
            getPlidList();
        }

        if (categoryList.length === 0) {
            getCategoryList();
        }
    };

    const clearAll = () => {
        setCurrentPage(1);
        setFormData(initFormData);
        setRequestData({ ...requestData, ...initFormData, startDate: null, endDate: null, page: 1 });
        form.setFieldsValue(initFormData);
    };

    const formValChange = (chgV, allV) => {
        setFormData(allV);
    };

    const onFinish = () => {
        let startDate = null;
        let endDate = null;
        let searchData = {};
      
        if (formData) {
            if (dates) {
                startDate = dates[0] ? dates[0] : null;
                endDate = dates[1] ? dates[1] : null;
            }

            searchData = {
                txid: formData.txid ? formData.txid.trim() : formData.txid,
                action: formData.action,
                status: formData.status,
                currency: formData.currency,
                order: formData.order,
                mid: formData.merchant,
                rid: formData.retailer,
                sid: formData.site,
                paymentmethod: formData.paymentmethod,
                plid: formData.plid ? formData.plid.map(v => v[1]) : formData.plid,
                cat_id: formData.cat_id,
                amountoperator: formData.amountoperator,
                amountvalue: formData.amountvalue ? formData.amountvalue.trim() : formData.amountvalue,
            };
        }
        // Reset Page Index to 1
        setCurrentPage(1);
        setRequestData({ ...requestData, ...searchData, startDate: startDate, endDate: endDate, page: 1 });
    };

    const AdvancedSearchForm = () => (
        <div className="search-wrap">
            <Card title={t("tables.actions.search")} size="small">
                <Form form={form} size="large" name="advanced_search" className="ant-advanced-search-form" onValuesChange={formValChange} onFinish={onFinish}>
                    <Row gutter={[16, 16]}>
                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }} xl={{ span: 6 }}>
                            <Form.Item name="dateRange" rules={[
                                    {
                                        validator: () => {
                                            if(isDateExceeded){
                                                return Promise.reject(t('max_3_months'));
                                            }else if((dates[0] && !dates[1]) || (!dates[0] && dates[1])){
                                                return Promise.reject(t('min_1_day'));
                                            }else{
                                                return Promise.resolve();
                                            }
                                        }
                                    }
                                ]}>
                                <CustomDateRangeV3 t={t} setIsDateExceeded={setIsDateExceeded} state={{ dates, setDates }} unit="months" limit={MAX_DATERANGE} />
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }} xl={{ span: 6 }}>
                            <Form.Item name="action">
                                <Select mode="multiple" maxTagCount="responsive" allowClear style={{ width: "100%" }} placeholder={t("tables.fields.action")}>
                                    <Option value="AUTH">AUTH</Option>
                                    <Option value="CAPTURE">CAPTURE</Option>
                                    <Option value="PAYMENT">PAYMENT</Option>
                                    <Option value="PREAUTH">PREAUTH</Option>
                                    <Option value="SETTLEMENT">SETTLEMENT</Option>
                                    <Option value="REFUND">REFUND</Option>
                                    <Option value="CHARGEBACK">CHARGEBACK</Option>
                                    <Option value="PREVOID">PREVOID</Option>
                                    <Option value="VOID">VOID</Option>
                                    <Option value="RETRIEVAL">RETRIEVAL</Option>
                                    <Option value="REVERSAL">REVERSAL</Option>
                                    <Option value="3DSECURE">3DSECURE</Option>
                                    <Option value="CREDIT">CREDIT</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }} xl={{ span: 5 }}>
                            <Form.Item name="status">
                                <Select mode="multiple" maxTagCount="responsive" allowClear style={{ width: "100%" }} placeholder={t("tables.fields.status")}>
                                    <Option value="APPROVED">APPROVED</Option>
                                    <Option value="DECLINED">DECLINED</Option>
                                    <Option value="PAYG_ERROR">PAYG_ERROR</Option>
                                    <Option value="PENDING">PENDING</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }} xl={{ span: 7 }}>
                            <Form.Item name="currency">
                                <Select mode="multiple" maxTagCount="responsive" style={{ width: "100%" }} placeholder={t("tables.fields.currency")}>
                                    <Option value="AUD">AUD</Option>
                                    <Option value="EUR">EUR</Option>
                                    <Option value="USD">USD</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        {GroupAccess("SYSADMIN") ? (
                            <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }} xl={{ span: 6 }}>
                                <Form.Item name="merchant">
                                    <Select
                                        showSearch
                                        mode="multiple"
                                        maxTagCount="responsive"
                                        style={{ width: "100%" }}
                                        optionFilterProp="children"
                                        onChange={onChangeMid}
                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                        filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
                                        placeholder={t("contents.merchants.title")}
                                    >
                                        {merchantList
                                            ? merchantList.map((item) => (
                                                  <Option key={item.mid} value={item.mid}>
                                                      {item.name + ` (mid: ` + item.mid + `)`}
                                                  </Option>
                                              ))
                                            : null}
                                    </Select>
                                </Form.Item>
                            </Col>
                        ) : (
                            ""
                        )}
                        {GroupAccess("SYSADMIN") ? (
                            <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }} xl={{ span: 6 }}>
                                <Form.Item name="retailer">
                                    <Select
                                        showSearch
                                        mode="multiple"
                                        maxTagCount="responsive"
                                        style={{ width: "100%" }}
                                        optionFilterProp="children"
                                        onChange={onChangeRid}
                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                        filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
                                        placeholder={t("contents.retailers.title")}
                                    >
                                        {retailerList
                                            ? retailerList.map((item) => (
                                                <Option key={item.rid} value={item.rid}>
                                                    {item.name + ` (rid: ` + item.rid + `)`}
                                                </Option>
                                            ))
                                            : null}
                                    </Select>
                                </Form.Item>
                            </Col>
                        ) : (
                            ""
                        )}
                        {GroupAccess("SYSADMIN") ? (
                            <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }} xl={{ span: 6 }}>
                                <Form.Item name="site">
                                    <Select
                                        showSearch
                                        mode="multiple"
                                        maxTagCount="responsive"
                                        style={{ width: "100%" }}
                                        optionFilterProp="children"
                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                        filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
                                        placeholder={t("contents.sites.title")}
                                    >
                                        {siteList
                                            ? siteList.map((item) => (
                                                <Option key={item.sid} value={item.sid}>
                                                    {item.name + ` (sid: ` + item.sid + `)`}
                                                </Option>
                                            ))
                                            : null}
                                    </Select>
                                </Form.Item>
                            </Col>
                        ) : (
                            ""
                        )}

                        {/*
                        <Col className="gutter-row" span={4}>
                            <Form.Item name="order">
                                <Select style={{ width: "100%" }} placeholder="Order">
                                    <Option value="ASC">ASC</Option>
                                    <Option value="DESC">DESC</Option>
                                </Select>
                            </Form.Item>
                        </Col>  */}
                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }} xl={{ span: 6 }}>
                            <Form.Item name="paymentmethod">
                                <Select
                                    showSearch
                                    mode="multiple"
                                    maxTagCount="responsive"
                                    style={{ width: "100%" }}
                                    optionFilterProp="children"
                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                    filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
                                    placeholder={t("tables.fields.pay_method")}
                                >
                                    {paymentMethodList
                                        ? paymentMethodList.map((item) => (
                                              <Option key={item.pmid} value={item.pmid}>
                                                  {item.display}
                                              </Option>
                                          ))
                                        : null}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col
                            className="gutter-row"
                            xs={{ span: 24 }}
                            sm={{ span: 24 }}
                            md={{ span: 12 }}
                            lg={{ span: 8 }}
                            xl={{ span: GroupAccess("SYSADMIN") ? 5 : 6 }}
                        >
                            <Form.Item name="txid">
                                <Input placeholder={t("tables.fields.txid")} />
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }} xl={{ span: 7 }}>
                            <Form.Item name="plid">
                                <Cascader
                                    style={{
                                    width: '100%',
                                    }}
                                    options={plidList}
                                    multiple
                                    maxTagCount="responsive"
                                    showCheckedStrategy={SHOW_CHILD}
                                    placeholder={t("tables.fields.descriptor")}
                                    showSearch={{
                                        filter,
                                      }}
                                />
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }} xl={{ span: 6 }}>
                            <Form.Item name="cat_id">
                                <Select
                                    showSearch
                                    mode="multiple"
                                    maxTagCount="responsive"
                                    style={{ width: "100%" }}
                                    optionFilterProp="children"
                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                    filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
                                    placeholder={t("tables.fields.category")}
                                >
                                    {categoryList
                                        ? categoryList.map((item) => (
                                                <Option key={item.cat_id} value={item.cat_id}>
                                                    {item.category}
                                                </Option>
                                            ))
                                        : null}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col
                            className="gutter-row"
                            xs={{ span: 24 }}
                            sm={{ span: 24 }}
                            md={{ span: 12 }}
                            lg={{ span: 8 }}
                            xl={{ span: 6 }}
                        >
                            <Row>
                                <Col style={{ width: "30%", paddingRight: '5px' }}>
                                    <Form.Item name="amountoperator">
                                        <Select maxTagCount="responsive" >
                                            <Option value="=">{'='}</Option>
                                            <Option value=">">{'>'}</Option>
                                            <Option value="<">{'<'}</Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col style={{ width: "70%" }}>
                                    <Form.Item name="amountvalue">
                                        <Input placeholder={t("tables.fields.amount")} />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                        <Col
                            className="gutter-row"
                            xs={{ span: 24 }}
                            sm={{ span: 12 }}
                            md={{ span: 6, offset: 12 }}
                            lg={{ span: 6, offset: 12 }}
                            xl={{ span: 3, offset: 17 }}
                        >
                            <Form.Item>
                                <Button type="secondary" onClick={() => clearAll()} block>
                                    {t("tables.actions.clear_all")}
                                </Button>
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 4 }}>
                            <Form.Item>
                                <Button type="primary" htmlType="submit" block>
                                    <SearchOutlined /> {t("tables.actions.search")}
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Card>
        </div>
    );

    const getHeaderTable = () => 
        (        
        <div className="custom-table--header d-flex justify-content-between align-items-center">
            <h3 className="table-title">{t("contents.transactions.title")}</h3>
            {data.datalist.length>0 ?(<>
            <Button key="btn_summary" type="default" onClick={() => generateSummary()} style={{ marginRight: 200 }}>{t("contents.transactions.summary")}</Button>
            <ExportModalV3
                t={t}
                keyname="export_all"
                title={t("tables.actions.export")}
                export_option={true}
                data={requestData}
                data_url={DATA_URL_EXPORT}
                headers={exportHeaders}
                fieldHeadersOptional={exportHeadersOptional}
                filename={`tx_${moment().format("YYMMDD_HHmmss_SSSS")}.csv`}
                style={{ marginLeft: 8 }}
            /></>):null}
        </div>
    );

    const getDataSource = (datalist) => (datalist && Array.isArray(datalist) ? datalist : []);

    const mergeColumns = columns.map((col, index) => ({
        ...col,
        onHeaderCell: (column) => ({
            width: column.width,
            onResize: (_, { size }) => {
                const newColumns = [...columns];
                newColumns[index] = { ...newColumns[index], width: size.width };
                setColumns(newColumns);
            },
        }),
    }));
/* --------------------------------summmary table ------------------- */
    function ParentChildTable({ data }) {
        const columns = [
          {
            title: t("contents.transactions.action"),
            dataIndex: 'action',
          },
          {
            title:  t("contents.transactions.approved_count"),
            dataIndex: 'approved_count',
          },
          {
            title:  t("contents.transactions.approved_sum"),
            dataIndex: 'approved_sum',
          },
          {
            title:  t("contents.transactions.declined_count"),
            dataIndex: 'decline_count',
          },
          {
            title:  t("contents.transactions.declined_sum"),
            dataIndex: 'decline_sum',
          },
        ];
      
        const dataSource = [];
      
        Object.keys(data.list).forEach(currencyKey => {
          const currencyData = data.list[currencyKey];
          dataSource.push(...currencyData.children.map(child => ({
            ...child,
            key: `${currencyKey}_${child.action}`, // Unique key for each row
          })));
        });
      
        return (
          <div>
            {Object.keys(data.list).map((parentKey, index) => (
              <div key={index}>
                <h3>{data.list[parentKey].name}</h3>
                <Table columns={columns} className="tableOverflow table-v" size="small" dataSource={dataSource.filter(row => row.key.startsWith(`${parentKey}_`))} 
                 pagination={false}
                 bordered
                 summary={(pageData) => {
                   
                   let app_count = 0;
                   let app_sum = 0;
                   let dec_count = 0;
                   let dec_sum = 0;
                   let precision = data.list[parentKey].currency.fraction_digits;
                   pageData.forEach(({ approved_count, approved_sum, decline_count,decline_sum }) => {
                 
                    app_count += approved_count;
                     app_sum += parseFloat(approved_sum,precision);
                     dec_count += decline_count;
                     dec_sum += parseFloat(decline_sum,precision);
                   });
                   return (
                     <>
                       <Table.Summary.Row>
                         <Table.Summary.Cell index={0}>{t("contents.transactions.total")}</Table.Summary.Cell>
                         <Table.Summary.Cell index={1}>                            
                           <Text>{app_count}</Text>
                         </Table.Summary.Cell>
                         <Table.Summary.Cell index={2}>
                           <Text type={app_sum < 0? "danger":"success"}>{app_sum.toFixed(precision)}</Text>
                         </Table.Summary.Cell>
                         <Table.Summary.Cell index={3}>
                           <Text>{dec_count}</Text>
                         </Table.Summary.Cell>
                         <Table.Summary.Cell index={2}>
                         <Text type={dec_sum > 0? "danger":"success"}>{dec_sum.toFixed(precision)}</Text>
                         </Table.Summary.Cell>
                       </Table.Summary.Row>
                      
                     </>
                   );
                 }}
                 />
              </div>
            ))}
          </div>
        );
      }
      
    
    const handleOk = () => {
        setIsModalVisible(false);
    };

    

    const generateSummary = async () => {
        if (!dates) {
            setDates ([START_DATE,END_DATE]);
        }
        const params = {
            date_from: dates[0],
            date_to: dates[1],
            action:formData.action
        };

        axios
            .post(
                SUMMARY_URL,
                params,
                { headers: { Authorization: "Bearer " + localStorage.getItem("access_token"), Authuser: localStorage.getItem("authuser") } }
            )
            .then(async ({ data }) => {
                await setSummary(data);
                setIsModalVisible(true);
            })
            .catch(() => {
                CatchError('Error Occured');
            });
    }; //react-hooks/exhaustive-deps



    const filter = (inputValue, path) =>
      path.some((option) => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);

    return (
        <div>
            <PageHeader title={t("contents.transactions.title")}></PageHeader>
            {AdvancedSearchForm()}
            <Modal
                title={t("contents.transactions.summary")}
                visible={isModalVisible}
                width={560}
                onOk={handleOk}
                onCancel={handleOk}
                footer={[                   
                    <Button key="submit" size="default" type="primary" onClick={handleOk}>
                        {t("tables.actions.ok")}
                    </Button>,
                ]}
            >
                {summary && summary.list?<ParentChildTable data={summary} />:null}

            </Modal>

            <Table
                // id="paymentTransactionTable"
                size="small"
                loading={isLoading}
                title={() => getHeaderTable()}
                rowKey="txid"
                className="tableOverflow table-v3 custom-pagination custom-pagination-v3"
                components={{
                    header: {
                        cell: ResizableTitle,
                    },
                }}
                columns={mergeColumns}
                scroll={{ x: 1500 }}
                dataSource={getDataSource(data.datalist)}
                onChange={handleChange}
                sortDirections={["ascend", "descend", "ascend"]}
                pagination={CustomPaginationV2({ totalResult, currentPage, requestData, setRequestData, setCurrentPage })}
            />
        </div>
    );
};

export default TransactionList;
