import React, { useState } from "react";
import DataFetchHook from "../../helper/DataFetchHook";
import ColumnSearchProps from "../../helper/Table/ColumnSearchProps";
import FilterSorterList from "../../helper/Table/FilterSorterList";
import CustomPagination from "../../helper/Table/CustomPagination";
// import StatusText from "../../helper/Table/StatusText";
import { Table, PageHeader, Button } from "antd";

const DATA_URL = "/descriptors";

const DescriptorList = (props) => {
    const [{ data }] = DataFetchHook(DATA_URL);
    const [filteredInfo, setFilteredInfo] = useState({});
    const [sortedInfo, setSortedInfo] = useState({});
    const { t } = props;

    const handleChange = (pagination, filters, sorter, { currentDataSource }) => {
        setFilteredInfo(filters);
        setSortedInfo(sorter);
    };

    const clearFilters = () => {
        setFilteredInfo(null);
    };

    const clearAll = () => {
        setFilteredInfo(null);
        setSortedInfo(null);
    };

    const getFilteredValue = (dataIndex) => (filteredInfo ? filteredInfo[dataIndex] || null : null);

    const getSortOrder = (dataIndex) => {
        if (sortedInfo) {
            if (Array.isArray(sortedInfo)) {
                let sortedFound = sortedInfo.find((column) => column.columnKey === dataIndex);
                return sortedFound ? sortedFound.order : null;
            } else {
                return (sortedInfo.columnKey === dataIndex && sortedInfo.order) || null;
            }
        }
        return null;
    };

    const columns = [
        {
            dataIndex: "descriptor_id",
            key: "descriptor_id",
            title: t("tables.fields.id"),
            sorter: {
                compare: (a, b) => a.descriptor_id - b.descriptor_id,
                multiple: 1,
            },
            sortOrder: getSortOrder("descriptor_id"),
        },
        // {
        //     dataIndex: "plid",
        //     key: "plid",
        //     title: t("tables.fields.id"),
        //     sorter: {
        //         compare: (a, b) => a.plid - b.plid,
        //         multiple: 1,
        //     },
        //     sortOrder: getSortOrder("plid"),
        // },
        {
            dataIndex: "descriptor",
            key: "descriptor",
            title: t("tables.fields.descriptor"),
            filteredValue: getFilteredValue("descriptor"),
            ...ColumnSearchProps("descriptor", !!getFilteredValue("descriptor"), t),
            sorter: {
                compare: (a, b) => a.descriptor.localeCompare(b.descriptor),
                multiple: 2,
            },
            sortOrder: getSortOrder("descriptor"),
        },
        // {
        //     dataIndex: "site_name",
        //     key: "site_name",
        //     title: t("tables.fields.site_name"),
        //     filteredValue: getFilteredValue("site_name"),
        //     ...ColumnSearchProps("site_name", !!getFilteredValue("site_name"), t),
        //     sorter: {
        //         compare: (a, b) => a.site_name.localeCompare(b.site_name),
        //         multiple: 3,
        //     },
        //     sortOrder: getSortOrder("site_name"),
        // },
        // {
        //     dataIndex: "descriptors",
        //     key: "descriptors",
        //     title: "GW Descriptors",
        //     render: (descriptors, record) => (descriptors ? getGWDescriptors(descriptors) : ""),
        // },
        // {
        //     dataIndex: "currency_code",
        //     key: "currency_code",
        //     title: t("tables.fields.currency"),
        //     sorter: {
        //         compare: (a, b) => a.currency_code.localeCompare(b.currency_code),
        //         multiple: 2,
        //     },
        //     sortOrder: getSortOrder("currency_code"),
        // },
        // {
        //     dataIndex: "status",
        //     key: "status",
        //     align: "left",
        //     title: t("tables.fields.status"),
        //     render: (status) => StatusText(status),
        //     sorter: {
        //         compare: (a, b) => a.status.localeCompare(b.status),
        //         multiple: 2,
        //     },
        //     sortOrder: getSortOrder("status"),
        // },
        // {
        //     dataIndex: "action",
        //     title: t("tables.fields.action"),
        //     render: (text, record) => (
        //         <Space size="middle">
        //             <a href={`/descriptor/${record.plid}`}>{t("tables.actions.view")}</a>
        //         </Space>
        //     ),
        // },
    ];

    // const getGWDescriptors = (descriptors) => {
    //     try {
    //         let array_descriptors = descriptors.split(",");
    //         if (Array.isArray(array_descriptors) && array_descriptors.length > 0) {
    //             return array_descriptors.map((descriptor) => <Tag key={descriptor}>{descriptor}</Tag>);
    //         } else {
    //             return "";
    //         }
    //     } catch (error) {
    //         return "";
    //     }
    // };

    const getDataSource = (datalist) => (datalist && Array.isArray(datalist) ? datalist : []);

    return (
        <div>
            <PageHeader title={t("contents.descriptors.title")}></PageHeader>
            {FilterSorterList(filteredInfo, sortedInfo, null, t)}
            <div className="d-flex align-items-center" style={{ marginBottom: 16 }}>
                <Button style={{ marginRight: 8 }} onClick={clearFilters}>
                    {t("tables.actions.clear_filters")}
                </Button>
                <Button onClick={clearAll}>{t("tables.actions.clear_filters_sorters")}</Button>
            </div>
            <Table columns={columns} rowKey="descriptor_id"  dataSource={getDataSource(data.datalist)} onChange={handleChange} pagination={CustomPagination('descriptors')}/>
        </div>
    );
};

export default DescriptorList;