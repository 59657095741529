import React, { useState } from "react";
import { Menu, Dropdown, Button } from "antd";
import { DownOutlined, GlobalOutlined } from "@ant-design/icons";

import i18n from "i18next";
import { useTranslation, initReactI18next } from "react-i18next";
import Languages from "./Languages";
import enUS from "antd/lib/locale/en_US";
import zhCN from "antd/lib/locale/zh_CN";
import jaJP from "antd/lib/locale/ja_JP";
import zhTW from "antd/lib/locale/zh_TW";

import enLogo from "../assets/images/flags/united-kingdom.svg";
import jpLogo from "../assets/images/flags/japan.svg";
import cnLogo from "../assets/images/flags/china.svg";
import twLogo from "../assets/images/flags/taiwan.svg";

i18n.use(initReactI18next).init({
    resources: Languages,
    lng: localStorage.getItem("lang") ? localStorage.getItem("lang") : "en",
    fallbackLng: "en",

    interpolation: {
        escapeValue: false,
    },
});

const Multilingual = () => {
    const { t } = useTranslation();

    const checkLocale = (key) => {
        switch (key) {
            case "jp":
                return jaJP;
            case "cn":
                return zhCN;
            case "tw":
                    return zhTW;
            default:
                return enUS;
        }
    };

    const [locale, setLocale] = useState(localStorage.getItem("lang") ? checkLocale(localStorage.getItem("lang")) : enUS);

    const changeLanguage = (event) => {
        setLocale(checkLocale(event.key));
        i18n.changeLanguage(event.key);
        localStorage.setItem("lang", event.key);
    };

    const langCurrent = localStorage.getItem("lang") ? localStorage.getItem("lang") : "en";

    const langOptions = [
        { key: "en", name: "EN", flag: enLogo},
        { key: "cn", name: "CN", flag: cnLogo },
        { key: "jp", name: "JP", flag: jpLogo },
        { key: "tw", name: "TW", flag: twLogo },
    ];

    let menuItems = langOptions.map((lang) => ({
            key: lang.key,
            icon: (<img src={lang.flag} alt={lang.name} width={18} />),
            label: (
                <span style={{ marginLeft: "8px" }}>{lang.name}</span>
            )
        }));
    

    const menuLang = (
        <Menu onClick={changeLanguage} items={menuItems}>            
        </Menu>
    );

    const getDropdownSelectLanguage = (style = {}) => {
        const lang = langOptions.find((lang) => lang.key === langCurrent);
        return (
            <Dropdown overlay={menuLang} placement="bottomRight">
                <Button type="text" className="DropdownSelectLanguage" style={{ ...style }}>
                    <GlobalOutlined style={{ fontSize: "18px", height: "18px" }} />
                    {/* <img src={require(`../assets/images/flags/${lang.flag}`)} alt={lang.name} width={22} /> */}
                    <span style={{ marginLeft: "6px", lineHeight: "18px" }}>{lang.name}</span> <DownOutlined />
                </Button>
            </Dropdown>
        );
    };

    return [t, locale, getDropdownSelectLanguage];
};
export default Multilingual;
