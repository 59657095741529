import React, { useState, useEffect } from "react";
import axios from "axios";
import { Select} from "antd";
import { API_DATA_SOURCE} from "../config/config";

const { Option } = Select;
const RESELLER_DATA_URL = API_DATA_SOURCE + "/reseller";


const ResellerList = (props) => {

    const [data, setData] = useState([]);

    const fetchdata = async() => {
        try{
        
            const response = await axios.post(
                RESELLER_DATA_URL,
                {},
                { headers: { Authorization: "Bearer " + localStorage.getItem("access_token"), Authuser: localStorage.getItem("authuser") } }
            );
            
            setData(response.data);
        } catch (error) {
          return ( { data: false });
        }      

    };

    useEffect(() => {
        if (data.length === 0) {
            fetchdata();
        }
    }, [data]);



    const getReseller = () => {       
        
            return data.map((item) => (               
                    <Option key={item.reseller_id} value={item.reseller_id}>
                        {item.name + ` (currency: ` + item.currency + `)`}
                    </Option>                    
            ))
        
    };
    
    return (
        
        <Select 
            showSearch
            mode="multiple"
            showArrow="true"
            maxTagCount="responsive"
            optionFilterProp="children"
            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
            filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
            {...props}
            >    {data
            ? getReseller()
            : null}</Select>
    );
};

export default ResellerList;