import React, { useState } from "react";
import { CBM_URL } from "../../config/config";
import { Modal, Button, Form, Upload, Select, message } from "antd";
import { CSVLink } from "react-csv";
import ToBase64 from "./../../helper/ToBase64";
import axios from "axios";
import Swal from "sweetalert2";
import { DownloadOutlined, UploadOutlined } from "@ant-design/icons";

const ImportChargeback = (props) => {
    const { t, merchants, providers, fetchChargeback } = props;
    const [activedata, setActivedata] = useState(null);
    const [fileList, setFileList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);

    const { Option } = Select;

    const layout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };

    const tailLayout = {
        wrapperCol: { offset: 8, span: 16 },
    };

    const initData = {
        mid: null,
        pid: null,
    };

    const chargebackTemplate = [
        "transaction_id",
        "amount",
        "chargeback_reason",
        "cb_date",
        "original_transaction_date",
        "card_mask",
        "merchant_id",
        "descriptor",
        "currency"
    ];

    const handleOk = () => {
        setLoading(true);
        onFinish();
    };

    const showModal = () => {
        const mid = merchants ? merchants[0].mid : null;
        const pid = providers ? providers[0].pid : null;

        setActivedata({ ...initData, mid: mid, pid: pid });
        setIsModalVisible(true);
    };

    const handleCancel = () => {
        setFileList([]);
        setLoading(false);
        setIsModalVisible(false);
    };

    const onSelectChange = (e, option) => {
        setActivedata({ ...activedata, [`${option}`]: e });
    };


    const onFinish = async () => {
        setLoading(true);
        const apiUrl = CBM_URL + `/api/v1/chargebacks/merchant/${activedata.mid}/provider/${activedata.pid}/batchCSV`;
        let csv = await ToBase64(fileList[0]);
        csv = csv.replace("data:text/csv;base64,", "");

        const merchant = merchants ? merchants.filter(({mid}) => mid === activedata.mid) : false;
        if(!merchant){
            Swal.fire({
                icon: "error",
                title: "Error",
                showConfirmButton: false,
                timer: 2000,
                onClose: () => {
                    fetchChargeback();
                },
            });
        }
        // let merchant_token = merchant[0] ? merchant[0].jwt_token : null;
       
      //  let mptoken =  await CBM_MerchantProviderToken(merchant_token,activedata.mid,activedata.pid);  //get merchant provider token with merchant and provider chosen
       
        axios
            .post(
                apiUrl,
                { csv },
                {
                     headers: { Authorization: "Bearer " + localStorage.getItem("cbm_token") },
                }
            )
            .then(({ data }) => {
                if (data.status === 'ok') {
                    handleCancel();
                    Swal.fire({
                        icon: "success",
                        title: t("contents.chargebacks.messages.chargeback_import_successful"),
                        showConfirmButton: false,
                        timer: 2000,
                        onClose: () => {
                            fetchChargeback();
                        },
                    });
                }else{
                  
                    Swal.fire({
                        icon: "error",
                        title: "Error"+data.msg,                        
                        showConfirmButton: true,
                    });
                }
            })
            .catch((error) => {
                setLoading(false);
                console.log(error.response);
                Swal.fire({
                    icon: "error",
                    title: error.response ? error.response.data.error_msg  : t("login.dialogs.catch"),
                    html: error.response ? error.response.data.error_msg: "",
                    showConfirmButton: true,
                });
            });
    };

    const uploadProps = {
        accept: "text/csv",
        onRemove: (file) => {
            const index = fileList.indexOf(file);
            const newFileList = fileList.slice();
            newFileList.splice(index, 1);
            setFileList(newFileList);
        },
        beforeUpload: (file) => {
            const isLimit = file.size / 1024 < 700;
            if (!isLimit) {
                message.error(t("contents.chargebacks.messages.file_must_smaller"));
                return false;
            }
            setFileList([file]);
            return false;
        },
        fileList,
    };

    const isOkButtonProps = () => !activedata || !activedata.mid || !activedata.pid || fileList.length === 0 || loading;

    return (
        <>
            <Button
                type="link"
                onClick={showModal}
                style={{ marginLeft: "8px" }}
                disabled={!merchants || merchants.length === 0 || !providers || providers.length === 0}
            >
                <DownloadOutlined /> {t("contents.chargebacks.chargeback_import")}
            </Button>
            <Modal
                title={t("contents.chargebacks.chargeback_import")}
                width={600}
                visible={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={[
                    <Button key="back" onClick={() => handleCancel()}>
                        {t("contents.chargebacks.form.cancel")}
                    </Button>,
                    <Button key="submit" type="primary" loading={loading} disabled={isOkButtonProps()} onClick={() => handleOk()}>
                        {loading ? t("contents.chargebacks.form.importing") : t("contents.chargebacks.form.import")}
                    </Button>,
                ]}
            >
                <Form {...layout} name="import_chargeback">
                    <Form.Item label={t("contents.chargebacks.form.merchant")}>
                        {activedata ? (
                            <Select defaultValue={activedata.mid} value={activedata.mid} onChange={(e) => onSelectChange(e, "mid")}>
                                {merchants
                                    ? merchants.map((merchant) => (
                                          <Option key={merchant.mid} value={merchant.mid}>
                                              {merchant.name}
                                          </Option>
                                      ))
                                    : null}
                            </Select>
                        ) : null}
                    </Form.Item>
                    <Form.Item label={t("contents.chargebacks.form.provider")}>
                        {activedata ? (
                            <Select defaultValue={activedata.pid} value={activedata.pid} onChange={(e) => onSelectChange(e, "pid")}>
                                {providers
                                    ? providers.map((provider) => (
                                          <Option key={provider.pid} value={provider.pid}>
                                              {provider.name}
                                          </Option>
                                      ))
                                    : null}
                            </Select>
                        ) : null}
                    </Form.Item>
                    <Form.Item
                        style={{ marginBottom: "0" }}
                        label={t("contents.chargebacks.form.csv_file")}
                        extra={`${t("contents.chargebacks.tooltip.max_upload_file_size")}: 700KB`}
                    >
                        <Upload {...uploadProps}>
                            <Button icon={<UploadOutlined />}>{t("contents.chargebacks.form.select_file")}</Button>
                        </Upload>
                    </Form.Item>
                    <Form.Item style={{ marginBottom: "0.5rem" }} {...tailLayout}>
                        <CSVLink
                            key={"chargeback_template"}
                            data={[]}
                            headers={chargebackTemplate}
                            filename={"chargeback_template.csv"}
                            separator={","}
                            style={{ padding: "0 0" }}
                            className="ant-btn ant-btn-link"
                        >
                            {t("contents.chargebacks.form.download_template")}
                        </CSVLink>
                    </Form.Item>
                </Form>
                <p style={{ marginBottom: "0" }}>
                    <small>{t("contents.chargebacks.messages.notice_1")}</small>
                </p>
            </Modal>
        </>
    );
};

export default ImportChargeback;
