import React, { useState, useEffect } from "react";
import { Row, Col, PageHeader, Card ,Typography} from "antd";
import { API_TXALERT2 } from "../../../config/config";
import axios from "axios";
const { Paragraph } = Typography;

const Txalert_ERTransactionMatch = (props) => {
    const match_id = props.match.params.id;
    const [details, setDetails] = useState(null);
    const [loading, setLoading] = useState(true);
    const { t } = props;

    useEffect(() => {
        if (loading === true) {
            getDetails();
            setLoading(false);
        }
    }, [loading]); // eslint-disable-line



    const getDetails = async () => {

        const api_url = API_TXALERT2 + `/express_resolve_tx_matches/${match_id}`;
        await axios
            .get(api_url, { headers: { Authorization: localStorage.getItem("txalert_token") } })
            .then(({ data }) => {

                let recdata = data;
                setDetails(recdata);

            })
            .catch((error) => console.log(error));
    };

    if (details) {
        return (

            <>
                <PageHeader className="site-page-header" title={t("contents.alerts.alert_id")} subTitle={`(${details.alert_id})`}></PageHeader>
                <Card>
                    <Row gutter="[0,8]" key="transaction_date">
                        <Col key="label-transaction_date" span="10">
                            <span className="text">
                                <b>{t(`contents.txalert_admin.form.transaction_date`)}</b>
                            </span>
                        </Col>
                        <Col key="value-transaction_date" span="14">
                            <span className="text">{details.transaction_date}</span>
                        </Col>
                    </Row>
                    <Row gutter="[0,8]" key="transaction_amount">
                        <Col key="label-transaction_amount" span="10">
                            <span className="text">
                                <b>{t(`contents.txalert_admin.form.transaction_amount`)}</b>
                            </span>
                        </Col>
                        <Col key="value-transaction_amount" span="14">
                            <span className="text">{details.transaction_amount}</span>
                        </Col>
                    </Row>
                    <Row gutter="[0,8]" key="transaction_currency">
                        <Col key="label-transaction_currency" span="10">
                            <span className="text">
                                <b>{t(`contents.txalert_admin.form.transaction_currency`)}</b>
                            </span>
                        </Col>
                        <Col key="value-transaction_currency" span="14">
                            <span className="text">{details.transaction_currency}</span>
                        </Col>
                    </Row>
                    <Row gutter="[0,8]" key="card_number">
                        <Col key="label-card_number" span="10">
                            <span className="text">
                                <b>{t(`contents.txalert_admin.form.cardnumber`)}</b>
                            </span>
                        </Col>
                        <Col key="value-card_number" span="14">
                            <span className="text">{details.theme_name}</span>
                        </Col>
                    </Row>
                    <Row gutter="[0,8]" key="merchant_tx_id">
                        <Col key="label-merchant_tx_id" span="10">
                            <span className="text">
                                <b>{t(`contents.txalert_admin.form.merchant_tx_id`)}</b>
                            </span>
                        </Col>
                        <Col key="value-merchant_tx_id" span="14">
                            <span className="text">{details.merchant_tx_id}</span>
                        </Col>
                    </Row>

                    <Row gutter="[0,8]" key="arn">
                        <Col key="label-arn" span="10">
                            <span className="text">
                                <b>{t(`contents.txalert_admin.form.arn`)}</b>
                            </span>
                        </Col>
                        <Col key="value-arn" span="14">
                            <span className="text">{details.arn}</span>
                        </Col>
                    </Row>

                    <Row gutter="[0,8]" key="authcode">
                        <Col key="label-authcode" span="10">
                            <span className="text">
                                <b>{t(`contents.txalert_admin.form.authcode`)}</b>
                            </span>
                        </Col>
                        <Col key="value-authcode" span="14">
                            <span className="text">{details.authcode}</span>
                        </Col>
                    </Row>

                </Card>
                <PageHeader className="site-page-header" title={t("contents.txalert_admin.ermatch_transaction")} subTitle={`(${match_id})`}></PageHeader>
                <Card className="details-info--card" loading={loading}>

                    <Row gutter="[0, 8]" key="alert_id">
                        <Col key="label-alert_id" span="10">
                            <span className="text">
                                <b>{t(`contents.alerts.alert_id`)}</b>
                            </span>
                        </Col>
                        <Col key="value-alert_id" span="14">
                            <span className="text">{details.alert_id}</span>
                        </Col>
                    </Row>
                    <Row gutter="[0,8]" key="action">
                        <Col key="label-action" span="10">
                            <span className="text">
                                <b>{t(`contents.txalert_admin.form.action`)}</b>
                            </span>
                        </Col>
                        <Col key="value-action" span="14">
                            <span className="text">{details.action}</span>
                        </Col>
                    </Row>
                    <Row gutter="[0,8]" key="gateway_tx_date">
                        <Col key="label-gateway_tx_date" span="10">
                            <span className="text">
                                <b>{t(`contents.txalert_admin.form.gateway_tx_date`)}</b>
                            </span>
                        </Col>
                        <Col key="value-gateway_tx_date" span="14">
                            <span className="text">{details.gateway_tx_date}</span>
                        </Col>
                    </Row>
                    <Row gutter="[0,8]" key="gateway_tx_id">
                        <Col key="label-merchant_alert_api_url" span="10">
                            <span className="text">
                                <b>{t(`contents.txalert_admin.form.gateway_tx_id`)}</b>
                            </span>
                        </Col>
                        <Col key="value-merchant_alert_api_url" span="14">
                            <span className="text">{details.gateway_tx_id}</span>
                        </Col>
                    </Row>
                    <Row gutter="[0,8]" key="gateway_tx_authcode">
                        <Col key="label-gateway_tx_authcode" span="10">
                            <span className="text">
                                <b>{t(`contents.txalert_admin.form.gateway_tx_authcode`)}</b>
                            </span>
                        </Col>
                        <Col key="value-gateway_tx_authcode" span="14">
                            <span className="text">{details.gateway_tx_authcode}</span>
                        </Col>
                    </Row>
                    <Row gutter="[0,8]" key="gateway_tx_status">
                        <Col key="label-gateway_tx_status" span="10">
                            <span className="text">
                                <b>{t(`contents.txalert_admin.form.gateway_tx_status`)}</b>
                            </span>
                        </Col>
                        <Col key="value-gateway_tx_status" span="14">
                            <span className="text">{details.gateway_tx_status}</span>
                        </Col>
                    </Row>
                    <Row gutter="[0,8]" key="transaction_info">
                        <Col key="label-transaction_info" span="10">
                            <span className="text">
                                <b>{t(`contents.txalert_admin.form.transaction_info`)}</b>
                            </span>
                        </Col>
                        <Col key="value-transaction_info" span="14">
                            <Typography>
                                <Paragraph className="well">
                                    {JSON.stringify(JSON.parse(details.transaction_info))}
                                </Paragraph>
                            </Typography>
                        </Col>
                    </Row>
                    <Row gutter="[0,8]" key="match_info">
                        <Col key="label-match_info" span="10">
                            <span className="text">
                                <b>{t(`contents.txalert_admin.form.match_info`)}</b>
                            </span>
                        </Col>
                        <Col key="value-match_info" span="14">
                            <Typography>
                                <Paragraph className="well">
                                    {JSON.stringify(JSON.parse(details.match_info))}
                                </Paragraph>
                            </Typography>
                        </Col>
                    </Row>
                    
                </Card>

            </>
        );
    } else {
        return (<></>);
    }
}
export default Txalert_ERTransactionMatch;
