
import React, { useState, useEffect } from "react";
import axios from "axios";
import { Select} from "antd";
import { API_TXALERT2 } from "../../../config/config";

const { Option } = Select;
const DATA_URL = API_TXALERT2 + "/acquiring_bank_account";


const Txalert_BankAccountDropdown = (props) => {

    const [data, setData] = useState([]);

    const fetchdata = async () => {
        try {

            const response = await axios.get(
                DATA_URL,
                { headers: { Authorization: "Bearer " + localStorage.getItem("txalert_token") } }
            );

            setData(response.data.list);
        } catch (error) {
            return ({ data: false });
        }

    };

    useEffect(() => {
        if (data.length === 0) {
            fetchdata();
        }
    }, [data]);

    

    const getStatus = () => {
        
        return data.map((item) => (            
            <Option key={item.acquiring_bank_account_id} value={item.acquiring_bank_account_id}>
                {item.name +'('+ item.bin+'-' + item.mid+')'}
            </Option>
        ))
       
    };


    return (

        <Select
        key="bank_acc_drpdwn"
            showSearch
            showArrow="true"
            maxTagCount="responsive"
            optionFilterProp="children"
            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
            filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
            {...props}
        >    {data
            ? getStatus()
            : null}</Select>
    );
};

export default Txalert_BankAccountDropdown;
