import React from "react";
import { Table, Card } from "antd";
import FormatAmount from "../../../helper/FormatAmount";

const TxItems = (props) => {
    const { t, data, currency } = props; // eslint-disable-line

    const getFormatNumber = (amount) => `${FormatAmount(amount ? amount : "0.00", currency)} ${currency}`;

    const columns = [
        {
            dataIndex: "trackingnumber",
            key: "trackingnumber",
            title: t('tables.fields.trackingnumber'),
        },
        {
            dataIndex: "track_status",
            key: "track_status",
            title: t('tables.fields.trackingstatus'),
        },
        {
            dataIndex: "name",
            key: "name",
            title: t('tables.fields.tx_items'),
        },
        {
            dataIndex: "item_desc",
            key: "item_desc",
            title: t('tables.fields.description'),
        },
        {
            dataIndex: "item_no",
            key: "item_no",
            title: t('tables.fields.item_no'),
        },
        {
            dataIndex: "quantity",
            key: "quantity",
            title: t('tables.fields.quantity'),
        },
        {
            dataIndex: "amount_unit",
            key: "amount_unit",
            title: t('tables.fields.per_unit'),
            render: (amount_unit, record) => getFormatNumber(amount_unit),
        },
        {
            dataIndex: "amount_total",
            key: "amount_total",
            title: t('tables.fields.total'),
            render: (amount_total, record) => getFormatNumber(amount_total),
        },
    ];

    return (
        <Card className="details-info--card">
            <h4 className="details-info--title">{t("contents.transactions.tabs.tx_items")}</h4>
            <div className="details-info--table--wrap">
                <Table size="small" columns={columns} rowKey="txid" pagination={false} dataSource={data} />
            </div>
        </Card>
    );
};

export default TxItems;
