import React, { useState, useEffect } from "react";
import { Table, Button } from "antd";
import axios from "axios";
import { CBM_URL } from "../../config/config";
import moment from "moment";
import ColumnResizable from "../../helper/Table/ColumnResizable";
import Swal from "sweetalert2";
import {CopyOutlined  } from '@ant-design/icons';

const MerchantTokens = (props) => {
    const { t, merchantid, isRefresh } = props;
    const [data, setData] = useState([]);

    useEffect(() => {
        const fetchdata = async () => {
            
            await axios
                .get(
                    CBM_URL + `/api/v1/merchant/${merchantid}/tokens/`,
                    { headers: { Authorization: "Bearer " + localStorage.getItem("cbm_token") } }
                )
                .then((result) => {
                    setData(result.data.list);
                })
                .catch((error) => ({ data: [] }));
            
        };
        fetchdata();
    }, [isRefresh]); // eslint-disable-line react-hooks/exhaustive-deps

    const getFormatDate = (date) => (Date.parse(date) ? moment(date).format("DD-MM-YYYY HH:mm:ss") : "");

    const copyToken=(token) =>{
        navigator.clipboard.writeText(token);
        Swal.fire({
            icon: "success",
            title: "Token copied",
            showConfirmButton: true,
        });
    }

    const [columns, setColumns] = useState([
        {
            dataIndex: "issued_at_time",
            key: "issued_at_time",
            title: t("tables.fields.issued_at_date"),
            width: 80,
            ellipsis: true,
            render: (issued_at_time) => getFormatDate(issued_at_time),
        },
        {
            dataIndex: "token",
            key: "token",
            title: t("tables.fields.token"),
            width: 200,
            ellipsis: true,
        },
        {
            dataIndex: "active",
            key: "active",
            title: t("tables.fields.active"),
            width: 30,
            ellipsis: true,
        },
        {
            dataIndex: "version",
            key: "version",
            title: t("tables.fields.version"),
            width: 30,
            ellipsis: true
            
        },
        {
            dataIndex: "expiry",
            key: "expiry",
            title: t("tables.fields.expiry_date"),
            width: 80,
            ellipsis: true,
            render: (expiry) => getFormatDate(expiry),
        },{
            dataIndex: "action",
            title: t("tables.fields.action"),
            align: "left",
            fixed: "right",
            width: 80,
         
            render: (text, record) => (
                <Button type="link" size="small" onClick={() => copyToken(record.token)} style={{ marginLeft: "auto" }}>
                   <CopyOutlined style={{ color: "rgba(0, 0, 0, 0.85)", fontSize: "18px" }} />
                </Button>
            ),
        }
    ]);

    const mergeColumns = columns.map((col, index) => ({
        ...col,
        onHeaderCell: (column) => ({
            width: column.width,
            onResize: (_, { size }) => {
                const newColumns = [...columns];
                newColumns[index] = { ...newColumns[index], width: size.width };
                setColumns(newColumns);
            },
        }),
    }));


    return (
        <div style={{ marginBottom: "1rem" }}>
            <Table size="middle" 
                components={{
                    header: {
                        cell: ColumnResizable,
                    },
                }}
                columns={mergeColumns}
                rowKey="token_id" 
                pagination={false} dataSource={data ? data : []} />
        </div>
    );
};
export default MerchantTokens;
