import React, { useState, useEffect } from "react";
import axios from "axios";
import { Select } from "antd";
import { API_TXALERT2 } from "../../../config/config";
const { Option } = Select;
const DATA_URL = API_TXALERT2 + "/express_resolve_gateway";


const Txalert_ERProviderDropdown = (props) => {

    const [data, setData] = useState([]);

    const fetchdata = async () => {
        try {

            const response = await axios.get(
                DATA_URL,
                { headers: { Authorization: "Bearer " + localStorage.getItem("txalert_token") } }
            );

            setData(response.data.list);
        } catch (error) {
            return ({ data: false });
        }

    };

    useEffect(() => {
        if (data.length === 0) {
            fetchdata();
        }
    }, [data]);



    const getERProvider = () => {
                   return data.map((item) => (
               
                    <Option key={item.express_resolve_gateway_id} value={item.express_resolve_gateway_id}>
                        {item.gateway_name + ` (id: ` + item.express_resolve_gateway_id + `)`}
                    </Option>
                    
            ))
      
    };

    return (

        <Select
        showSearch            
        showArrow="true"
        maxTagCount="responsive"
        optionFilterProp="children"
        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
        filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
        {...props}
    >    {data
        ? getERProvider()
        : null}</Select>
    );
};

export default Txalert_ERProviderDropdown;
