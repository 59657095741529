import React, { useState, useEffect, useCallback} from "react";
import DataFetchHookV3 from "../../helper/DataFetchHookV3";
import StatusText from "../../helper/Table/StatusText";
import { Table, PageHeader, Card, Form, Select, Row, Col, Button, Divider, Space, Checkbox } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import axios from "axios";
import moment from "moment";
//import CustomPaginationV3 from "../../helper/Table/CustomPaginationV3";
import CustomPaginationV2 from "../../helper/Table/CustomPaginationV2";
import { API_MERCHANT, API_TXALERT } from "../../config/config";
import ColumnResizable from "../../helper/Table/ColumnResizable";
import MergeColumns from "../../helper/Table/MergeColumns";
import GroupAccess from "./../../helper/GroupAccess";
import MenuAccess from "./../../helper/MenuAccess";
import FormatAmount from "../../helper/FormatAmount";
import RetailerList from "../../components/RetailerList";
import ExportModalV3 from "../../helper/ExportModalV3";
import TextSorter from "../../helper/Table/TextSorter";

const DATA_URL = API_MERCHANT + "/datatable";
const MERCHANT_DATA_URL = API_MERCHANT + "/list";
const DATA_URL_EXPORT = API_TXALERT + "/merchant/reports";
const { Option } = Select;

const MerchantList = (props) => {
    //eslint-disable-next-line
    const [{ data, totalResult, isLoading, requestData, isUpdated }, doFetch, setRequestData] = DataFetchHookV3(DATA_URL, "POST", {
        page: 1,
        size: 20,
        order: "ASC",
        sort_field:"mid",
        getall:false
    }); // eslint-disable-line
    const [formData, setFormData] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    const { t } = props;

    const [inactiveChecked, setInactiveChecked] = useState(false);
    const [merchantList, setMerchantList] = useState([]); //eslint-disable-line
    const [isSortUpdate, setIsSortUpdate] = useState(false);
    const [midSelect, setMidSelect] = useState('');

    const initFormData = {
        // field: "merchant",
        // keyword: null,
        mids: [],
        rids: [],
        sort_field: "mid",
        order: "ASC"
    };

    const [form] = Form.useForm();

    const handleChange = (pagination, filters, sorter, { currentDataSource }) => {       
        var orderBy = 'ASC';
        if(sorter.order === 'descend'){
            orderBy = 'DESC';
        }
        var sortField = 'mid';
        if (sorter.field !== undefined){
            sortField = sorter.field;
        }
        var p = pagination.current;
        if (Object.keys(sorter).length >0 && requestData.sort_field !== sortField){
            p = 1;
            setCurrentPage(1);
        }

        setRequestData({ ...requestData, order: orderBy, sort_field: sortField, page: p, size: pagination.pageSize  });
        setIsSortUpdate(true);

      //  setSortedInfo(sorter);
    };

    useEffect(() => {

        // if (merchantList.length === 0 && data.datalist.length !==0) {
        //     setMerchantList(data.datalist);
        //  }

        if (merchantList.length === 0) {
            getMerchantList()
        }

    }, [isLoading]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        formValInit();        
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        formValInit();
       
    }, [isSortUpdate]); // eslint-disable-line react-hooks/exhaustive-deps

  

    const getMerchantList = useCallback(() => {
        axios
            .post(
                MERCHANT_DATA_URL,
                { getall:true },
                { headers: { Authorization: "Bearer " + localStorage.getItem("access_token"), Authuser: localStorage.getItem("authuser") } }
            )
            .then(async ({ data }) => {
                await setMerchantList(data.list);
            })
            .catch(() => false);
    }, []);

    const formValInit = () => {
        setFormData(initFormData);
        form.setFieldsValue(initFormData);

       
    };

    const clearAll = () => {
        setCurrentPage(1);
        setFormData(initFormData);
        setRequestData({ ...requestData, ...initFormData, startDate: null, endDate: null, page: 1 });
        form.setFieldsValue(initFormData);
    };

    const formValChange = (chgV, allV) => {
        setFormData(allV);
    };

    const onFinish = () => {
        let searchData = {};
        if (formData) {
            searchData = {
                // keyword: formData.keyword ? formData.keyword.trim() : null,
                // field: formData.field,
                mids: formData.mids,
                rids: formData.rids,
            };
        }

        setCurrentPage(1);
        setRequestData({ ...requestData, ...searchData, startDate: null, endDate: null, page: 1 });
    };

    const exportHeadersOptional = [];
    const exportHeaders = [
        { label: "MID", key: "m.mid" },
        { label: "SID", key: "rs.sid" },
        { label: "Merchant Name", key: "m.name" },
        { label: "Retailer Name", key: "r.name" },
        { label: "Site Name", key: "rs.name" },
        { label: "Rcode", key: "r.rcode" },
        { label: "Site Status", key: "rs.status" },
        { label: "Balance", key: "balance" }
    ];

    const decodeHtml = (html) => new DOMParser().parseFromString(html, 'text/html').body.textContent;
    const columns = [
        {
            dataIndex: "merchant_name",
            key: "merchant_name",
            title: t("tables.fields.merchant_name"),
            width: 150,
            render: (merchant_name) => merchant_name ? decodeHtml(merchant_name) : '',
            sorter: {
                compare: (a, b) => TextSorter(a.merchant_name,b.merchant_name)
            },
        },
        {
            dataIndex: "retailer_name",
            key: "retailer_name",
            title: t("tables.fields.retailer_name"),
            width: 150,
            render: (retailer_name) => retailer_name ? decodeHtml(retailer_name) : '',
            sorter: {
                compare: (a, b) => TextSorter(a.retailer_name,b.retailer_name)
            },
        },
        {
            dataIndex: "sid",
            key: "sid",
            title: t("tables.fields.sid"),
            width: 50,
            sorter: {
                compare: (a, b) => a.sid - b.sid,
            },
        },
        {
            dataIndex: "site_name",
            key: "site_name",
            title: t("tables.fields.site_name"),
            width: 180,
            render: (site_name) => site_name ? decodeHtml(site_name) : '',
            sorter: {
                compare: (a, b) => TextSorter(a.site_name,b.site_name)
            },
        },
        {
            dataIndex: "status",
            key: "status",
            align: "left",
            title: t("tables.fields.site_status"),
            width: 100,
            render: (status) => StatusText(status),
            sorter: {
                compare: (a, b) => TextSorter(a.status,b.status)
            },
        },
        {
            dataIndex: "rcode",
            key: "rcode",
            align: "left",
            title: t("tables.fields.rcode"),
            width: 380,
            render: (rcode) => <span style={{color: "#ff4d4f"}}>{rcode}</span> ,
            sorter: {
                compare: (a, b) => TextSorter(a.rcode,b.rcode)
            },
        },
        {
            dataIndex: "balance",
            key: "balance",
            align: "left",
            title: t("tables.fields.balance"),
            width: 100,
            render: (balance, record) => { 
                let _balance = balance ? balance : "0";
                let _currency = record.currency ? record.currency : "USD";
                return record.payments === 1 ? FormatAmount(_balance,_currency) + ' ' + _currency : "N/A";
            },
        },
        // {
        //     dataIndex: "details",
        //     title: t("contents.merchants.details"),
        //     align: "left",
        //     width: 100,
        //     render: (text, record) => (
        //         <Space size="middle">
        //             <Button type="link" href={`/merchant/${record.mid}`} target="_blank">
        //                 <FileSearchOutlined style={{ color: "rgba(0, 0, 0, 0.85)", fontSize: "18px" }} />
        //             </Button>
        //         </Space>
        //     ),
        // },
    ];

    const getDataSource = (datalist) => (datalist && Array.isArray(datalist) ? datalist : []);

    const onChangeMerchant = async (value) => {
        await setMidSelect(value)
    }

    const onChangeInactive = (e) => {
        setInactiveChecked(e.target.checked);
    };

    const getMerchant = () => {
        if (inactiveChecked) {
            return merchantList.map((item) => (
                item.status === 'active' ?
                    <Option key={item.mid} value={item.mid}>
                        {decodeHtml(item.name) + ` (mid: ` + item.mid + `)`}
                    </Option>
                    : <Option key={item.mid} value={item.mid} style={{ color: "#f5222d" }}>
                        {decodeHtml(item.name) + ` (mid: ` + item.mid + `)`}
                    </Option>
            ))
        } else {
            return merchantList.map((item) => (
                item.status === 'active'
                    ?
                    <Option key={item.mid} value={item.mid}>
                        {decodeHtml(item.name) + ` (mid: ` + item.mid + `)`}
                    </Option>
                    :
                    null
            ))
        }
    };

    const AdvancedSearchForm = () => (
        <div className="search-wrap">
            <Card title={t("tables.actions.search")} size="small">
                <Form form={form} size="large" name="advanced_search" className="ant-advanced-search-form" onValuesChange={formValChange} onFinish={onFinish}>
                    <Row gutter={[16, 16]}>
                    <Col className="gutter-row" xs={{ span: 24 }} md={{ span: 8 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                            <Form.Item name="mids">
                                <Select
                                    showSearch
                                    mode="multiple"
                                    showArrow="true"
                                    maxTagCount="responsive"
                                    style={{ width: "100%" }}
                                    optionFilterProp="children"
                                    onChange={onChangeMerchant}
                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                    filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
                                    placeholder={t("contents.merchants.title")}
                                    dropdownRender={(menu) => GroupAccess("SYSADMIN") ? (
                                        <>
                                            {menu}

                                            <Divider
                                                style={{
                                                    margin: '8px 0',
                                                }}
                                            />
                                            <Space
                                                style={{
                                                    padding: '0 8px 4px',
                                                }}
                                            >
                                                <Checkbox onChange={onChangeInactive}>Show Inactive?</Checkbox>
                                            </Space>
                                        </>
                                    ) : (
                                        <>
                                            {menu}
                                        </>
                                    )}
                                >
                                    {merchantList
                                        ? getMerchant()
                                        : null}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" xs={{ span: 24 }} md={{ span: 8 }} lg={{ span: 6 }} xl={{ span: 6 }}>
                            <Form.Item name="rids">
                                <RetailerList placeholder={t("contents.retailers.title")} mid={midSelect}
                                              style={{ width: "100%" }} />
                            </Form.Item>
                        </Col>
                        {/* <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }} xl={{ span: 6 }}>
                            <Form.Item name="field">
                                <Select style={{ width: "100%" }}>
                                    <Option value="merchant">Merchant Name / ID</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }} xl={{ span: 6 }}>
                            <Form.Item name="keyword">
                                <Input placeholder="Keyword" />
                            </Form.Item>
                        </Col> */}
                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6, offset: 0 }} lg={{ span: 4, offset: 16 }} xl={{ span: 3, offset: 5 }}>
                            <Form.Item>
                                <Button type="secondary" onClick={() => clearAll()} block>
                                    Clear All
                                </Button>
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 4 }} xl={{ span: 4 }}>
                            <Form.Item>
                                <Button type="primary" htmlType="submit" block>
                                    <SearchOutlined /> Search
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Card>
        </div>
    );

    const getHeaderTable = () => (
        <div className="custom-table--header d-flex justify-content-between align-items-center">
            <h3 className="table-title">{t("contents.merchants.title")}</h3>
            <ExportModalV3
                t={t}
                keyname="export_all"
                title={t("tables.actions.export")}
                data={requestData}
                data_url={DATA_URL_EXPORT}
                headers={exportHeaders}
                fieldHeadersOptional={exportHeadersOptional}
                filename={`merchants_${moment().format("YYMMDD_HHmmss_SSSS")}.csv`}
                style={{ marginLeft: 8 }}
            />
        </div>
    );

    return (
        GroupAccess("SYSADMIN|RESELLER|MERCHANT") && MenuAccess('merchants')?(
        <div>
            <PageHeader title={t("contents.merchants.title")}></PageHeader>
            {AdvancedSearchForm()}
            <Table
                size="small"
                loading={isLoading}
                components={{
                    header: {
                        cell: ColumnResizable,
                    },
                }}
                columns={MergeColumns(columns)}
                title={() => getHeaderTable()}
                rowKey="rowKey"
                className="table-v3 custom-pagination custom-pagination-v3"
                dataSource={getDataSource(data.datalist)}
                onChange={handleChange}
                sortDirections={["ascend", "descend"]}
              //  pagination={CustomPaginationV3(
              //      { totalResult, currentPage, requestData, setRequestData, setCurrentPage, latestTotal, setLatestTotal },
              //      isUpdated
              //  )}
              pagination={CustomPaginationV2({ totalResult, currentPage, requestData, setRequestData, setCurrentPage })}
            />
        </div>):(<Form form={form} ><label>Unauthorized Access</label></Form>)
    );
};

export default MerchantList;
