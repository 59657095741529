import React, { useState, useEffect } from "react";
import DataFetchHookCB from "../../helper/DataFetchHookCB";
import PermissionsRequired from "../../helper/PermissionsRequired";
import CreateMerchantProvider from "./CreateMerchantProvider";
import MerchantProviderModal from "./MerchantProvider";
import StatusText from "../../helper/Table/StatusText";
import moment from "moment";
import axios from "axios";
import Swal from "sweetalert2";
import { Table, PageHeader, Button, Card, Form, Row, Col, Select, Input } from "antd";
import { CBM_URL } from "../../config/config";
import { SearchOutlined, FileSearchOutlined } from "@ant-design/icons";
import CustomPaginationV2 from "../../helper/Table/CustomPaginationV2";
import SortOrder from "../../helper/Table/SortOrder";


const { Option } = Select;

const DATA_URL = CBM_URL + "/api/v1/merchantProviders";

const ChargebackMerchantProviderList = (props) => {
    const [{ data, totalResult, requestData, isLoading, isError, errorMsg }, doFetch, setRequestData, setMethod, setToken] = DataFetchHookCB(DATA_URL,"POST",localStorage.getItem("cbm_token"),{page:1,size:20,order:'ASC',orderVar:'merchantname'}); // eslint-disable-line
    const [formData, setFormData] = useState();
    const [merchantProvider, setMerchantProvider] = useState(null);
    const [merchants, setMerchants] = useState([]);
    const [providers, setProviders] = useState([]);
    const [sortedInfo, setSortedInfo] = useState({});
    const [currentPage, setCurrentPage] = useState(1);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const { t } = props;

    const initFormData = {
        keyword: "",
        field: null,
        // order: null,
    };

    const [form] = Form.useForm();

    useEffect(() => {
        if(isLoading === false)
        {             
            if(errorMsg)
            {
                Swal.fire({
                    icon: "error",
                    title: errorMsg,
                    showConfirmButton: true,
                });
            }
        formValInit();
        getMerchants();
        getProviders();
        }
    }, [isLoading]); // eslint-disable-line react-hooks/exhaustive-deps

    const showMerchantProvider = (mpid) => {
        setMerchantProvider(mpid);
        setIsModalVisible(true);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const handleChange = (pagination, filters, sorter, { currentDataSource }) => {
        setSortedInfo(sorter);
    };

    const formValInit = () => {
        setFormData(initFormData);
        form.setFieldsValue(initFormData);
    };

    const clearAll = () => {
        setFormData(initFormData);
        setRequestData({ ...requestData, ...initFormData, page: 1 });
        form.setFieldsValue(initFormData);
    };

    const formValChange = (chgV, allV) => {
        setFormData(allV);
    };

    const onFinish = () => {
        let searchData = {};
        if (formData) {
            searchData = {
                field: formData.field,
                keyword: formData.keyword ? formData.keyword.trim() : null,
                // order: formData.order,
            };
        }
        // Reset Page Index to 1
        setCurrentPage(1);
        setRequestData({ ...requestData, ...searchData, page: 1 });
    };

    const AdvancedSearchForm = () => (
        <div className="search-wrap">
            <Card title={t("tables.actions.search")} size="small">
                <Form form={form} size="large" name="advanced_search" className="ant-advanced-search-form" onValuesChange={formValChange} onFinish={onFinish}>
                    <Row gutter={[16, 16]}>
                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 6 }} xl={{ span: 5 }}>
                            <Form.Item name="field">
                                <Select style={{ width: "100%" }} placeholder={t("tables.fields.field")}>
                                    <Option value="m.name">{t("tables.fields.merchant")}</Option>
                                    <Option value="p.name">{t("tables.fields.provider")}</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 6 }} xl={{ span: 8 }}>
                            <Form.Item name="keyword">
                                <Input placeholder={t("tables.fields.keyword")} />
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6, offset: 12 }} lg={{ span: 6 }} xl={{ span: 3, offset: 4 }}>
                            <Form.Item>
                                <Button type="secondary" onClick={() => clearAll()} block>
                                    {t("tables.actions.clear_all")}
                                </Button>
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 6 }} xl={{ span: 4 }}>
                            <Form.Item>
                                <Button type="primary" htmlType="submit" block>
                                    <SearchOutlined /> {t("tables.actions.search")}
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Card>
        </div>
    );

    const columns = [
        {
            dataIndex: "merchantname",
            key: "merchantname",
            title: t("tables.fields.merchantname"),
            sorter: {
                compare: (a, b) => a.merchantname.localeCompare(b.merchantname),
                //    multiple: 1,
            },
            sortOrder: SortOrder(sortedInfo, "merchantname"),
        },
        {
            dataIndex: "providername",
            key: "providername",
            title: t("tables.fields.providername"),
            sorter: {
                compare: (a, b) => a.providername.localeCompare(b.providername),
                //    multiple: 2,
            },
            sortOrder: SortOrder(sortedInfo, "providername"),
        },{
            dataIndex: "isactive",
            key: "isactive",
            title: t("tables.fields.status"),
            render: (isactive) => StatusText(isactive === 1?'ACTIVE':'INACTIVE'),
            sorter: {
                compare: (a, b) => a.isactive -b.isactive,
                //    multiple: 2,
            },
            sortOrder: SortOrder(sortedInfo, "isactive"),
        },
        {
            dataIndex: "action",
            title: t("tables.fields.action"),
            align: "left",
            fixed: "right",
            width: 120,
            render: (text, record) => (
                <Button type="link" size="small" onClick={() => showMerchantProvider(record.mpid)} style={{ marginLeft: "auto" }}>
                    <FileSearchOutlined style={{ color: "rgba(0, 0, 0, 0.85)", fontSize: "18px" }} />
                </Button>
            ),
        },
    ];

    const getMerchants = () => {
        const apiUrl = CBM_URL + `/api/v1/merchants`;
        const query = {"isactive": 1};
        axios
            .post(apiUrl,query,{ headers: { Authorization: "Bearer " + localStorage.getItem("cbm_token") }})
            .then(({ data }) => {
                if (data.list) {
                    setMerchants(data.list);
                }
            })
            .catch((error) => {
                Swal.fire({
                    icon: "error",
                    title: error.response ? error.response.data.msg : t("login.dialogs.catch"),
                    showConfirmButton: true,
                });
            });
    };

    const getProviders = () => {
        const apiUrl = CBM_URL + `/api/v1/providers`;
        const query = {"isactive":1};
        axios
            .post(apiUrl, query,{ headers: { Authorization: "Bearer " + localStorage.getItem("cbm_token") }})
            .then(({ data }) => {
                if (data.list) {
                    setProviders(data.list);
                }
            })
            .catch((error) => {
                Swal.fire({
                    icon: "error",
                    title: error.response ? error.response.data.msg : t("login.dialogs.catch"),
                    showConfirmButton: true,
                });
            });
    };

    const fetchMerchantProviders = () => doFetch(DATA_URL + `?` + moment().unix());

    const getDataSource = (datalist) => (datalist && Array.isArray(datalist) ? datalist : []);

    const getHeaderTable = () => (
        <div className="custom-table--header d-flex justify-content-between align-items-center">
            <h3 className="table-title">{t("contents.chargebacks.merchantproviders")}</h3>
            <CreateMerchantProvider t={t} merchants={merchants} providers={providers} fetchMerchantProviders={fetchMerchantProviders} />
        </div>
    );

    return PermissionsRequired("SYSADMIN") ? (
        <div>
            <PageHeader title={t("contents.chargebacks.merchantproviders")}></PageHeader>
            {AdvancedSearchForm()}
            <MerchantProviderModal
                t={t}
                merchants={merchants}
                providers={providers}
                mpid={merchantProvider}
                isModalVisible={isModalVisible}
                handleCancel={handleCancel}
                fetchMerchantProviders={fetchMerchantProviders}
            />
            <Table
                size="small"
                loading={isLoading}
                columns={columns}
                title={() => getHeaderTable()}
                rowKey="mpid"
                className="table-v3 custom-pagination"
                dataSource={getDataSource(data.datalist)}
                onChange={handleChange}
                sortDirections={["ascend", "descend", "ascend"]}
                pagination={CustomPaginationV2({ totalResult, currentPage, requestData, setRequestData, setCurrentPage })}
            />
        </div>
    ) : null;
};

export default ChargebackMerchantProviderList;
