import React, { useState, useEffect } from "react";
import { PageHeader, Tabs, Card, Form, Row, Col, Button, Typography } from "antd";
import FormatNumber from "../../../helper/FormatNumber";
// import AssociationsChecker from "../../../helper/AssociationsChecker";
import ArrangeAlertResponses from "../../../helper/ArrangeAlertResponses";
import DraggableModal from "../../../helper/DraggableModal";
import { API_TXALERT2 } from "../../../config/config";
import ChangeAlertStatus from "./ChangeAlertStatus";
import axios from "axios";
import moment from "moment";
// import _ from "lodash";
// import { WHITELABEL_DISPLAY_NAME } from "../../../config/config";
import TxalertStatusUpdatesTbl from "./StatusUpdatesTbl";
import TxalertAlertCreditsTbl from "./AlertCreditsTbl";
import TxalertRepostAlert from "../Components/RepostAlert";
import TxalertTransactionMatchesTbl from "./TransactionMatchesTbl";
import TxalertPosttoMerchantLogTbl from "../Components/PosttoMerchantLogTbl";
import TxalertPosttoProviderLogTbl from "../Components/PosttoProviderLogTbl";

const { Paragraph } = Typography;

const Txalert_AlertDetail = (props) => {
   const [data, setData] = useState(null);
   const [alert, setAlert] = useState(null);
   const [responses, setResponses] = useState([]);
   const [outcome, setOutcome] = useState(null);
   const [loading, setLoading] = useState(true);
   const [modal, setModal] = useState({ title: "", content: "" });
   const [isModalVisible, setIsModalVisible] = useState(false);
   const [isRefresh, setIsRefresh] = useState(false); //eslint-disable-line
   // const [requestCreditDisabled, disableRequestCredit] = useState(true);

   const { t, socket } = props;

   useEffect(() => {

      if (!responses) {

         getResponses();
      }

   }, []); // eslint-disable-line

   useEffect(() => {
      if (typeof props.match.params.id != "undefined" && loading && !data) {
         getAlert(props.match.params.id);
      }

   }, [alert, setAlert]); // eslint-disable-line react-hooks/exhaustive-deps

   useEffect(() => {
      if (alert && alert.alertid) {
         getAlert(alert.alertid);
      }
   }, [alert]); // eslint-disable-line react-hooks/exhaustive-deps

   useEffect(() => {
      if (isRefresh) {
         // console.log(isRefresh);
         getAlert(props.match.params.id);
      }

   }, [isRefresh]); // eslint-disable-line react-hooks/exhaustive-deps



   const formItemLayout = {
      labelCol: { span: 9 },
      wrapperCol: { span: 15 },
   };


   const showModal = (params) => {
      setModal(params);
      setIsModalVisible(true);
   };

   const handleOk = () => {
      setIsModalVisible(false);
   };

   const handleCancel = () => {
      setIsModalVisible(false);
   };

   const getAlert = (id) => {
      const apiUrl = API_TXALERT2 + `/alert/${id}`;
      axios
         .get(apiUrl, { headers: { Authorization: "Bearer " + localStorage.getItem("txalert_token") } })
         .then(({ data }) => {
            setData(data);
            try {

               setOutcome(data.outcome ? data.outcome.toLowerCase() : '');
            } catch (error) {
               console.log(error);
            }
            setLoading(false);
         })
         .catch((error) => error);
   };



   const getResponses = () => {
      const apiUrl = API_TXALERT2 + `/alert/responses`;

      axios
         .get(apiUrl, { headers: { Authorization: "Bearer " + localStorage.getItem("txalert_token") } })
         .then(({ data }) => {
            if (data.length !== 0) {
               setResponses(ArrangeAlertResponses(data));
            }

         })
         .catch((error) => {
            console.log("Alert Responses", error);
         });
   };
   const IsJsonString = (str) => {
      try {
         JSON.parse(str);
      } catch (e) {
         return false;
      }
      return true;
   };

   // const getButtonModal = (params, buttonText = "") => (
   //    <div className="ant-form--button-modal">
   //       <Button size="small" onClick={() => showModal(params)}>
   //          {buttonText ? buttonText : t("tables.actions.view")}
   //       </Button>
   //    </div>
   // );
   const getOutcomeRender = (outcome) => {
      if (!outcome) {
         return <div className="ant-form--text">{outcome}</div>;
      }
      try {
         if (outcome[0].toUpperCase() !== outcome[0].toLowerCase()) {
            return <div className="ant-form--text">{t(`outcomes.${outcome.toLowerCase()}`)}</div>;
         } else {
            return <div className="ant-form--text">{outcome}</div>;
         }
      } catch (error) {
         return <div className="ant-form--text">{outcome}</div>;
      }
   };

   // const checkIsAlertReasonsAllowCreditRequest = () => {
   //    if (
   //       !loading &&
   //       data &&
   //       data.details &&
   //       responses &&
   //       Array.isArray(data.details) &&
   //       Array.isArray(responses) &&
   //       data.details.length > 0 &&
   //       responses.length > 0
   //    ) {
   //       let alertDetails = data.details;
   //       if (alertDetails && alertDetails.length > 0 && alertDetails[alertDetails.length - 1] && alertDetails[alertDetails.length - 1]["reason_code"]) {
   //          const current_reason_code = alertDetails[alertDetails.length - 1]["reason_code"];
   //          if (!current_reason_code) {
   //             return false;
   //          }
   //          const alertResponses = responses.filter(({ reason_code }) => reason_code === current_reason_code);
   //          if (alertResponses && alertResponses.length > 0) {
   //             return !!alertResponses[0]["allow_credit_request"];
   //          }
   //       }
   //    }
   //    return false;
   // };

   const getPlainText = (text) => <div className="ant-form--text">{text}</div>;

   const getFormatDate = (date) => <div className="ant-form--text">{Date.parse(date) ? moment(date).format("YYYY-MM-DD HH:mm:ss") : ""}</div>;

   const getAlertDetailForm = () => {
      const alert = data;
      const outcome = alert.outcome ? alert.outcome.toLowerCase() : alert.outcome;
      
      let fields = [
         {
            label: t("contents.alerts.alert_type"),
            name: "Alert Type",
            value: getPlainText(alert.alert_type_text),
         },
         {
            label: t("contents.alerts.provider_caseid"),
            name: "alert_data_provider_case_id",
            value: getPlainText(alert.alert_data_provider_case_id),
         },
         {
            label: t("contents.alerts.transaction_date"),
            name: "alert_data_transaction_date",
            value: getFormatDate(alert.alert_data_transaction_date),
         },
         {
            label: t("contents.alerts.providername"),
            name: "provider_name",
            value: getPlainText(alert.provider_name),
         },
         {
            label: t("contents.alerts.transaction_amount"),
            name: "alert_data_transaction_amount",
            value: getPlainText(FormatNumber(alert.alert_data_transaction_amount) + " " + alert.alert_data_transaction_currency),
         },
         {
            label: t("contents.alerts.alertdate"),
            name: "alert_data_provider_received_alert_date",
            value: getFormatDate(alert.alert_data_provider_received_alert_date),
         },
         {
            label: t("contents.alerts.cardnumber"),
            name: "alert_data_card_number",
            value: getPlainText(alert.alert_data_card_number),
         },
         {
            label: t("contents.alerts.issuername"),
            name: "alert_data_issuername",
            value: getPlainText(alert.alert_data_issuername),
         },
         {
            label: t("contents.alerts.descriptor"),
            name: "alert_data_descriptor",
            value: getPlainText(alert.alert_data_descriptor),
         },
         {
            label: t("contents.alerts.bin"),
            name: "alert_data_bin",
            value: getPlainText(alert.alert_data_bin),
         }, 
         {
            label: t("contents.alerts.caid"),
            name: "alert_data_caid",
            value: getPlainText(alert.alert_data_caid),
         },
         {
            label: t("contents.alerts.outcome"),
            name: "outcome",
            value: getOutcomeRender(alert.outcome),
            tooltip: t("contents.alerts.tooltip.outcome"),
         },
         {
            label: t("contents.alerts.merchant_transaction_id"),
            name: "alert_data_merchant_tx_id",
            value: getPlainText(alert.alert_data_merchant_tx_id),
         },
         {
            label: t("contents.alerts.arn"),
            name: "alert_data_arn",
            value: getPlainText(alert.alert_data_arn),
         },
         {
            label: t("contents.alerts.authcode"),
            name: "alert_data_authcode",
            value: getPlainText(alert.alert_data_authcode),
         },
         {
            label: t("contents.alerts.is_duplicate"),
            name: "is_duplicate",
            value: getPlainText(alert.is_duplicate ? t("contents.alerts.yes") : t("contents.alerts.no")),
         },
         {
            label: t("contents.alerts.source"),
            name: "alert_data_source",
            value: getPlainText(alert.alert_data_source),
         },
         {
            label: t("contents.alerts.dispute_condition_code"),
            name: "Dispute condition code",
            value: getPlainText(alert?.dispute_condition_code ? alert.dispute_condition_code + "(" + alert?.dispute_condition_text + ", " + alert?.dispute_condition_category + ")" : alert.dispute_condition_code),
         },
         {
            label: t("contents.alerts.er_eligible"),
            name: "ER eligible",
            value: getPlainText(alert.er_eligible === 1?'Yes':'No'),
         },
         {
            label: t("contents.alerts.er_enabled"),
            name: "ER enabled",
            value: getPlainText(alert.er_enabled === 1?'Yes':'No'),
         },
         {
            label: t("contents.alerts.er_complete"),
            name: "ER complete",
            value: getPlainText(alert.er_complete === 1?'Yes':'No'),
         }
      ];
      if (alert.is_duplicate) {
         fields = [
            ...fields,
            {
               label: t("contents.alerts.duplicate_alertid"),
               name: "duplicate_alertid",
               value: getPlainText(
                  <a href={`/alert/${alert.duplicate_alertid}`} target="_blank" rel="noopener noreferrer">
                     {alert.duplicate_alertid}
                  </a>
               ),
            },
         ];
      }
      fields = [
         ...fields,
         {
            label: t("contents.alerts.chargeback_proceeded"),
            name: "chargeback_proceeded",
            value: getPlainText(alert.chargeback_proceeded ? t("contents.alerts.yes") : t("contents.alerts.no")),
         },
      ];
      //alet data is not stored atm. will add it later
      /*    if (!AssociationsChecker("Merchant|Retailer")) {
             fields = [
                ...fields,
                {
                   label: t("contents.alerts.receive_data"),
                   name: "alert_data",
                   value: getButtonModal({ title: t("contents.alerts.receive_data"), content: alert.alert_data }, t("tables.actions.view_alert_data")),
                   offset: alert.is_duplicate ? null : 12,
                   tooltip: t("contents.alerts.tooltip.receive_data"),
                },
             ];
          } */
      if (outcome !== "resolved" && alert.post_url) {
         fields = [
            ...fields,
            {
               label: t("contents.alerts.repost_alert"),
               name: "repost_alert",
               value: (
                  <div className="ant-form--button-modal">{alert.post_url}
                     <TxalertRepostAlert key="RepostAlert" t={t} row={alert} posturl={data.post_url} doFetch={() => getAlert(props.match.params.id)} />
                  </div>
               ),
            },
         ];
      }
      fields = [
         ...fields,
         {
            label: t("contents.alerts.request_credit"),
            name: "request_credit",
            /*  value: checkIsAlertReasonsAllowCreditRequest() ? (
                 <div className="ant-form--button-modal">
                    <RequestCredit disabled={requestCreditDisabled} t={t} alertid={props.match.params.id} setIsRefresh={setIsRefresh} />
                 </div>
              ) : null, */
         }
      ];
      return (
         <Card
            key="AlertDetail"
            title={t("contents.alerts.alert_details")}
            style={{ marginBottom: "1rem" }}
            extra={[
               //  outcome !== "resolved" ? (
               <ChangeAlertStatus
                  key="ChangeAlertStatus"
                  t={t}
                  socket={socket}
                  outcome={outcome}
                  doFetch={() => getAlert(props.match.params.id)}
                  row={alert}
               />
               //  ) : null,
            ]}
         >
            <Form size="small" {...formItemLayout}>
               <Row gutter={24}>
                  {fields.map((field) => (
                     <Col key={field.name} xs={24} md={12} offset={field.offset}>
                        <Form.Item className="ant-form--item" label={<label className="ant-form--label">{field.label}</label>} tooltip={field.tooltip}>
                           {field.value}
                        </Form.Item>
                     </Col>
                  ))}
               </Row>
            </Form>
         </Card>
      );
   };

   const getModalComponent = () => (
      <DraggableModal
         name={modal.title}
         visible={isModalVisible}
         onOk={handleOk}
         onCancel={handleCancel}
         width={800}
         footer={[
            <Button key="submit" type="primary" onClick={handleOk}>
               {t("tables.actions.ok")}
            </Button>,
         ]}
         context={
            <Typography>
               <Paragraph className="well">
                  {IsJsonString(modal.content) ? JSON.stringify(JSON.parse(modal.content), null, "\t") : modal.content}
               </Paragraph>
            </Typography>
         }
      />
   );


   const statusupdates = () => (
      <div>
         <TxalertStatusUpdatesTbl id={props.match.params.id} t={t} showModal={showModal} />
      </div>

   );

   const creditrequests = () => (
      <div>
         <TxalertAlertCreditsTbl id={props.match.params.id} t={t} />
      </div>
   );
   
   const alertforreview = () => 
       (
      //if data.er_eligible
   
      <Tabs.TabPane tab={t("contents.possibletransactionmatches.title_matched")} key='alertsForReview'>
         <TxalertTransactionMatchesTbl t={t} alertid={props.match.params.id} alert_type={data.alert_type_text} isresolved={outcome === "resolved"} />
      </Tabs.TabPane>

   );

   return (
      <div>
         <PageHeader title={`${t("contents.alerts.title_details")} #${props.match.params.id}`}></PageHeader>
         {!loading ? (
            <>
               {getModalComponent()}
               {getAlertDetailForm()}
               <Tabs defaultActiveKey={data.er_enabled && data.er_action_text === 'match' ? "alertsForReview" : ""} type="card" >
                  <Tabs.TabPane tab={t("contents.alerts.status_updates")} key='statusupdates'>
                     {statusupdates()}
                  </Tabs.TabPane>

                  <Tabs.TabPane tab={t("contents.alerts.alert_credits")} key='alertCredits'>
                     {creditrequests()}
                  </Tabs.TabPane>

                  {alertforreview()}
                  <Tabs.TabPane tab={t("contents.txalert_admin.tabs.posttomerchant")} key='posttomerchantlogs'>
                 <TxalertPosttoMerchantLogTbl alertid={props.match.params.id} t={t} />
                </Tabs.TabPane>

                <Tabs.TabPane tab={t("contents.txalert_admin.tabs.posttoprovider")} key='posttoproviderlogs'>
                 <TxalertPosttoProviderLogTbl alertid={props.match.params.id} t={t} />
                </Tabs.TabPane>
               </Tabs>

            </>
         ) : null}
      </div>
   );
};

export default Txalert_AlertDetail;