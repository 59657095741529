import React, { useState, useEffect } from "react";
import { PageHeader, Tabs } from "antd";
import { API_TX } from "../../config/config";
import axios from "axios";
import TxItems from "./Details/TxItems";
import TxHistory from "./Details/TxHistory";
import TxDetails from "./Details/TxDetails";
import PayeeDetails from "./Details/PayeeDetails";
import FraudcheckDetails from "./Details/FraudcheckDetails";
import CommunicationDetails from "./Details/CommunicationDetails";
import TrackingDetails from "./Details/TrackingDetails";
import TransactionLog from "./Details/TransactionLog";
import GroupAccess from "./../../helper/GroupAccess";

const { TabPane } = Tabs;

const Transaction = (props) => {
    const [data, setData] = useState({});
    const [currency, setCurrency] = useState(null);
    const [loading, setLoading] = useState(true);
    const { t } = props;
    const txid = props.match.params.id;

    useEffect(() => {
        getTransactionDetail();
    }, []); // eslint-disable-line

    const getTransactionDetail = async () => {        
        const api_url = API_TX + `/transaction/${txid}/details`;

        setLoading(true);

        await axios
            .post(api_url, {}, { headers: { Authorization: "Bearer " + localStorage.getItem("access_token") } })
            .then(({ data }) => {
                setData(data);
                setCurrency(data ? data.details ? data.details.currency_code : null : null);
                setLoading(false);
            })
            .catch((error) => console.log(error));
    };

    return (
       
        <>
            <PageHeader className="site-page-header" title={t("contents.transactions.details")} subTitle={`(${txid})`}></PageHeader>
            <Tabs type="card" defaultActiveKey="tx_details" className="custom-tabs-card">
                <TabPane tab={t("contents.transactions.tabs.tx_details")} key="tx_details">
                    <TxDetails t={t} loading={loading} data={data ? data : null} />
                </TabPane>
                <TabPane tab={t("contents.transactions.tabs.payee_details")} key="payee_details">
                    <PayeeDetails t={t} data={data ? data : null} />
                </TabPane>
                <TabPane tab={t("contents.transactions.tabs.tx_items")} key="tx_items">
                    <TxItems t={t} data={data ? data.items : []} currency={currency} />
                </TabPane>
                <TabPane tab={t("contents.transactions.tabs.fraudcheck_details")} key="fraudcheck_details">
                    <FraudcheckDetails t={t} data={data ? data.FraudcheckDetails : []} />
                </TabPane>
                <TabPane tab={t("contents.transactions.tabs.communication_details")} key="communication_details">
                    <CommunicationDetails t={t} data={data ? data.CCSDetails : []} />
                </TabPane>
                <TabPane tab={t("contents.transactions.tabs.tracking_details")} key="tracking_details">
                    <TrackingDetails t={t} data={data ? data.TrackingDetails : []} />
                </TabPane>
                {GroupAccess("SYSADMIN") ? (
                <TabPane tab={t("contents.transactions.tabs.log")} key="log">
                    <TransactionLog t={t} data={data ? data.log : null} />
                </TabPane>
                ) : (
                    ""
                )}
            </Tabs>
            <TxHistory t={t} loading={loading} data={data ? data.tx : []} currency={currency} />
        </>
    );
};

export default Transaction;
