import React, { useEffect, useState} from "react";
import { Card, Row, Col, Form, Input } from "antd";
import { API_DATA_SOURCE } from "../../../config/config";
import axios from "axios";
import GroupAccess from "../../../helper/GroupAccess";

const ThreeDSTab = (props) => {
    const { t, siteDetails, isLoading } = props; // eslint-disable-line
    const [countryList, setCountryList] = useState(null);
    const [form] = Form.useForm();

    const getClientMode = (clientMode) => {
        switch (clientMode) {
            case '2':
                return 'brw';
            case '3':
                return '3ri';
            default:
                return 'app';
        }
    }

    const getTransType = (transType) => {
        switch (transType) {
            case '03':
                return 'ca';
            case '10':
                return 'af';
            case '11':
                return 'qt';
            case '28':
                return 'pal';
            default:
                return 'gs';
        }
    }

    const initFormData = {
        clientMode: getClientMode(siteDetails.threeDS.settings.clientMode),
        enableResult: siteDetails.threeDS.settings.enableResult,
        disableChallenge: siteDetails.threeDS.settings.disableChallenge,
        enable3ds1Frictionless: siteDetails.threeDS.settings.enable3ds1Frictionless,
        enable3DS1Only: siteDetails.threeDS.settings.enable3DS1Only,
        requestorName: siteDetails.threeDS.settings.requestorName,
        requestorURL: siteDetails.threeDS.settings.requestorURL,
        authenticationInd: siteDetails.threeDS.settings.authenticationInd,
        challengeInd: siteDetails.threeDS.settings.challengeInd,
        acctType: siteDetails.threeDS.settings.acctType,
        acquirerBin: siteDetails.threeDS.settings.acquirerBin,
        acquirerMid: siteDetails.threeDS.settings.acquirerMid,
        acquirerMerchantPwd: siteDetails.threeDS.settings.acquirerMerchantPwd,
        mcc: siteDetails.threeDS.settings.mcc,
        merchantCountryCode: siteDetails.threeDS.settings.merchantCountryCode,
        merchantName: siteDetails.threeDS.settings.merchantName,
        messageCategory: siteDetails.threeDS.settings.messageCategory,
        purchaseCurrency: siteDetails.threeDS.settings.purchaseCurrency,
        transType: getTransType(siteDetails.threeDS.settings.transType),
        verbose: siteDetails.threeDS.settings.verbose === 0?'No':'Yes',
        masterAcquirerBin: siteDetails.threeDS.settings.masterAcquirerBin,
        masterAcquirerMid: siteDetails.threeDS.settings.masterAcquirerMid,
        JCBAcquirerBin: siteDetails.threeDS.settings.JCBAcquirerBin,
        JCBAcquirerMid: siteDetails.threeDS.settings.JCBAcquirerMid,
        CUPAcquirerBin: siteDetails.threeDS.settings.CUPAcquirerBin,
        CUPAcquirerMid: siteDetails.threeDS.settings.CUPAcquirerMid,
        AMEXAcquirerBin: siteDetails.threeDS.settings.AMEXAcquirerBin,
        AMEXAcquirerMid: siteDetails.threeDS.settings.AMEXAcquirerMid,
        skip3d2: siteDetails.threeDS.shieldSettings.skip3d2===0?'No':'Yes',
        three_ds_provider: siteDetails.threeDS.shieldSettings.three_ds_provider,
        threedsprotocolversion: siteDetails.threeDS.shieldSettings.threedsprotocolversion,
        paayauth3d2: siteDetails.threeDS.shieldSettings.paayauth3d2,
        gpay_merchant_token: siteDetails.threeDS.shieldSettings.gpay_merchant_token,
        gpay_merchant_id: siteDetails.threeDS.shieldSettings.gpay_merchant_id,
        gpay_base_url: siteDetails.threeDS.shieldSettings.gpay_base_url,
        hitrust_account_id: siteDetails.threeDS.shieldSettings.hitrust_account_id,
        hitrust_password: siteDetails.threeDS.shieldSettings.hitrust_password,
        hitrust_base_url: siteDetails.threeDS.shieldSettings.hitrust_base_url,
        hitrust_mastercard_prefix: siteDetails.threeDS.shieldSettings.hitrust_mastercard_prefix
    }

    const itemLayout = {
        labelAlign: "left",
        labelCol: { span: 10, offset: 0 },
    };

    // const textareaLayout = {
    //     labelAlign: "left",
    //     labelCol: { span: 24, offset: 0 },
    // };

    useEffect(() => {
        if(!countryList) {
            getCountryList();
        }
        formValInit();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const formValInit = () => {
        form.setFieldsValue(initFormData);
    };

    const getCountryList = async () => {        
        const api_url = API_DATA_SOURCE + `/country`;
        await axios
            .post(api_url, {}, { headers: { Authorization: "Bearer " + localStorage.getItem("access_token") } })
            .then(({ data }) => {
                setCountryList(data);
            })
            .catch((error) => console.log(error));
    };

    const get3dsDetails = () => (
        <Card className="custom-form--card" loading={isLoading}>
            <h3>{t("contents.merchants.settings")}</h3>
            <hr />
            <Form form={form} {...itemLayout} name="3ds">
                <Row gutter={[36, 16]}>
                    <Col span={8}>
                        <Form.Item name="verbose" label={t("contents.merchants.form.verbose")}>
                        <Input readOnly bordered={false} />
                        </Form.Item>
                        <Form.Item name="clientMode" label={t("contents.merchants.form.client_mode")}>
                        <Input readOnly bordered={false} />
                        </Form.Item>
                        <Form.Item name="enableResult" label={t("contents.merchants.form.enable_result")}>
                             <Input readOnly bordered={false} />
                        </Form.Item>
                        <Form.Item name="disableChallenge" label={t("contents.merchants.form.enable_result")}>
                        <Input readOnly bordered={false} />
                        </Form.Item>
                        <Form.Item name="enable3ds1Frictionless" label={t("contents.merchants.form.enable3ds1_frictionless")}>
                        <Input readOnly bordered={false} />
                        </Form.Item>
                        <Form.Item name="enable3DS1Only" label={t("contents.merchants.form.enable3DS1_only")}>
                        <Input readOnly bordered={false} />
                        </Form.Item>
                        <Form.Item name="requestorName" label={t("contents.merchants.form.requestor_name")}>
                            <Input readOnly bordered={false}  />
                        </Form.Item>
                        <Form.Item name="requestorURL" label={t("contents.merchants.form.requestor_url")}>
                            <Input readOnly bordered={false} />
                        </Form.Item>
                        <Form.Item name="authenticationInd" label={t("contents.merchants.form.authentication_indicator")} className="fixed-label">
                        <Input readOnly bordered={false} />
                        </Form.Item>
                        <Form.Item name="challengeInd" label={t("contents.merchants.form.challenge_indicator")} className="fixed-label">
                        <Input readOnly bordered={false} />
                        </Form.Item>
                        <Form.Item name="acctType" label={t("contents.merchants.form.acct_type")}>
                        <Input readOnly bordered={false} />
                        </Form.Item>
                        <Form.Item name="acquirerBin" label={t("contents.merchants.form.acquirer_bin")}>
                             <Input readOnly bordered={false} />
                        </Form.Item>
                        <Form.Item name="acquirerMid" label={t("contents.merchants.form.acquirer_mid")}>
                             <Input readOnly bordered={false} />
                        </Form.Item>
                        <Form.Item name="masterAcquirerBin" label={t("contents.merchants.form.master_acquirer_bin")} className="fixed-label">
                             <Input readOnly bordered={false} />
                        </Form.Item>
                        <Form.Item name="masterAcquirerMid" label={t("contents.merchants.form.master_acquirer_mid")} className="fixed-label">
                             <Input readOnly bordered={false} />
                        </Form.Item>
                        <Form.Item name="JCBAcquirerBin" label={t("contents.merchants.form.jcb_acquirer_bin")} className="fixed-label">
                             <Input readOnly bordered={false} />
                        </Form.Item>
                        <Form.Item name="JCBAcquirerMid" label={t("contents.merchants.form.jcb_acquirer_mid")} className="fixed-label">
                             <Input readOnly bordered={false} />
                        </Form.Item>
                        <Form.Item name="CUPAcquirerBin" label={t("contents.merchants.form.cup_acquirer_bin")} className="fixed-label">
                             <Input readOnly bordered={false} />
                        </Form.Item>
                        <Form.Item name="CUPAcquirerMid" label={t("contents.merchants.form.cup_acquirer_mid")} className="fixed-label">
                             <Input readOnly bordered={false} />
                        </Form.Item>
                    </Col>
                    <Col span={16}>
                        <Form.Item name="AMEXAcquirerBin" label={t("contents.merchants.form.amex_acquirer_bin")} className="fixed-label">
                             <Input readOnly bordered={false} />
                        </Form.Item>
                        <Form.Item name="AMEXAcquirerMid" label={t("contents.merchants.form.amex_acquirer_mid")} className="fixed-label">
                             <Input readOnly bordered={false} />
                        </Form.Item>
                        <Form.Item name="acquirerMerchantPwd" label={t("contents.merchants.form.acquirer_mid_pwd")} className="fixed-label">
                             <Input readOnly bordered={false} />
                        </Form.Item>
                        <Form.Item name="mcc" label={t("contents.merchants.form.merchant_category_code")} className="fixed-label">
                             <Input readOnly bordered={false} />
                        </Form.Item>
                        <Form.Item name="merchantCountryCode" label={t("contents.merchants.form.merchant_country_code")}>
                        <Input readOnly bordered={false} />
                        </Form.Item>
                        <Form.Item name="merchantName" label={t("contents.merchants.form.merchant_name")}>
                             <Input readOnly bordered={false} />
                        </Form.Item>
                        <Form.Item name="messageCategory" label={t("contents.merchants.form.message_category")}>
                        <Input readOnly bordered={false} />
                        </Form.Item>
                        <Form.Item name="purchaseCurrency" label={t("contents.merchants.form.purchase_currency")}>
                        <Input readOnly bordered={false} />
                        </Form.Item>
                        <Form.Item name="transType" label={t("contents.merchants.form.trans_type")}>
                        <Input readOnly bordered={false} />
                        </Form.Item>
                        {GroupAccess("SYSADMIN") ? (
                        <>
                            <Form.Item name="skip3d2" label={t("contents.merchants.form.skip3d2")} className="fixed-label">
                            <Input readOnly bordered={false} />
                            </Form.Item>
                            <Form.Item name="three_ds_provider" label={t("contents.merchants.form.three_ds_provider")}>
                            <Input readOnly bordered={false} />
                            </Form.Item>
                            <Form.Item name="threedsprotocolversion" label={t("contents.merchants.form.threedsprotocolversion")}>
                                 <Input readOnly bordered={false} />
                            </Form.Item>
                            <Form.Item name="paayauth3d2" label={t("contents.merchants.form.paayauth3d2")}>
                                 <Input readOnly bordered={false} />
                            </Form.Item>
                            <Form.Item name="gpay_merchant_token" label={t("contents.merchants.form.gpay_merchant_token")}>
                                 <Input readOnly bordered={false} />
                            </Form.Item>
                            <Form.Item name="gpay_merchant_id" label={t("contents.merchants.form.gpay_merchant_id")}>
                                 <Input readOnly bordered={false} />
                            </Form.Item>
                            <Form.Item name="gpay_base_url" label={t("contents.merchants.form.gpay_base_url")}>
                                 <Input readOnly bordered={false} />
                            </Form.Item>
                            <Form.Item name="hitrust_account_id" label={t("contents.merchants.form.hitrust_account_id")}>
                                 <Input readOnly bordered={false} />
                            </Form.Item>
                            <Form.Item name="hitrust_password" label={t("contents.merchants.form.hitrust_password")}>
                                 <Input readOnly bordered={false} />
                            </Form.Item>
                            <Form.Item name="hitrust_base_url" label={t("contents.merchants.form.hitrust_base_url")}>
                                 <Input readOnly bordered={false} />
                            </Form.Item>
                            <Form.Item name="hitrust_mastercard_prefix" label={t("contents.merchants.form.hitrust_mastercard_prefix")} className="fixed-label">
                                 <Input readOnly bordered={false} />
                            </Form.Item>
                        </>
                        ) : null }
                    </Col>
                </Row>
            </Form>
        </Card>
    );

    return get3dsDetails();
};

export default ThreeDSTab;
