import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Select, Input, List } from "antd";
// import { UploadOutlined } from '@ant-design/icons';
import { API } from "../../config/config";
import axios from "axios";
import moment from "moment";
import Swal from "sweetalert2";

const DATA_URL = "/requestcredit/";
const DATA_ALERT_URL = "/alert/";

const delay = ms => new Promise(
    resolve => setTimeout(resolve, ms)
);

const RequestCredit = (props) => {
    const { t, row, alertid, setIsRefresh } = props;
    const [data, setData] = useState(false);
    const [isValidAlertID, setIsValidAlertID] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isModalReminderVisible, setIsModalReminderVisible] = useState(false);
    // const [useUploadDocument, setUseUploadDocument] = useState(false);
    const [isLoadingButton, setIsLoadingButton] = useState(false);
    const [isDisabledOkButton, setIsDisabledOkButton] = useState(true);

    const [form] = Form.useForm();

    const creditReasons = [
        {reason: "chargeback", value: "chargeback received after alert"},
        {reason: "duplicate", value: "duplicate"}
    ];

    const formItemLayout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };

    const formValInit = () => {
        setIsValidAlertID(false);
        setData({ alertid: alertid, reason_option: 'duplicate',credit_reason: 'duplicate', duplicate_alertid: "", comment: "", document: {} });
        form.setFieldsValue({ alertid: alertid, reason_option: 'duplicate', credit_reason: 'duplicate', duplicate_alertid: "", comment: "", document: {} });
    };

    const showModal = () => {
        setIsModalVisible(true);
        formValInit();
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const isAlertIdExist = async (id) => {
        const apiUrl = DATA_ALERT_URL + id;
        setIsLoadingButton(true);
        if (!id) {
            setIsLoadingButton(false);
            return false;
        }
        try {
            return await axios
                .get(API + apiUrl, { headers: { Authorization: "Bearer " + localStorage.getItem("access_token") } })
                .then(({ data }) => {
                    if(data.parent[0])
                    {
                        return true;
                    }else{
                        return false;
                    }
                })
                .catch((err) => {
                    setIsLoadingButton(false);
                    return false;
                });
        } catch (error) {
            setIsLoadingButton(false);
            return false;
        }
    };

    

    const handleOk = async () => {
        setIsLoadingButton(true);
        await delay(2000);
        if (data.reason_option !== "duplicate") {
            delete data.duplicate_alertid;
        }
        

        axios
            .post(API + DATA_URL, { ...data }, { headers: { Authorization: "Bearer " + localStorage.getItem("access_token") } })
            .then(({ data }) => {
                setIsModalVisible(false);
                if (data.status === "OK") {
                    Swal.fire({
                        icon: "success",
                        title: "Request credit successful",
                        text: data.msg,
                        showConfirmButton: false,
                        timer: 2000,
                        onClose: () => {
                            setIsRefresh(moment().unix());
                        },
                    });
                } else {
                    Swal.fire({
                        icon: "error",
                        title: "Request credit Failure",
                        text: data.error_msg,
                        showConfirmButton: true,
                    });
                }
                setIsLoadingButton(false);
            })
            .catch((error) => {
                Swal.fire({
                    icon: "error",
                    title: error,
                    showConfirmButton: true,
                });
            });
        // setIsModalVisible(false);
    };

    const formValChange = (chgV, allV) => {
        // setData(allV);

        // if (chgV.credit_reason === "chargeback") {
        //     setIsModalReminderVisible(true);
        // }
        const filtered = creditReasons.filter(v =>  v.value === allV.reason_option);
        setData({...data, reason_option: allV.reason_option, credit_reason: filtered[0].reason, comment: filtered[0].value + (allV.comment !== '' ? '. '+allV.comment : ''), duplicate_alertid:allV.duplicate_alertid  });
      
        if (
            chgV?.reason_option &&
            [
                "chargeback received after alert"
            ].includes(chgV.reason_option)
        ) {
            setIsDisabledOkButton(false);
            setIsModalReminderVisible(true);
        } else if ([
            "chargeback received after alert"
        ].includes(allV.reason_option))
        {
            setIsDisabledOkButton(false);
        }
        else
        {
          setIsDisabledOkButton(true);
        }
        


    };

    useEffect(() => {
        
        setIsDisabledOkButton(!isValidAlertID);
        if(!isValidAlertID)            
            {
                setData({...data, duplicate_alertid:""});
            }
     }, [isValidAlertID]); // eslint-disable-line react-hooks/exhaustive-deps


 

    const remindersData = {
        "chargeback received after alert": [
            {
                title: "1. Chargeback document or CRM screenshot that shows reasonable proof Chargeback was received.\nChargeback Document should include:",
                contents: [
                    "a. BIN / Last 4 numbers on credit card",
                    "b. ARN (acquirer reference number) -if available",
                    "c. Dispute date & dispute amount",
                    "d. Original transaction date & amount"
                ]
            },
            {
                title: "2. Screenshot of refund (preferably refund settlement but refund processed accepted)\nScreenshot of refund should include:",
                contents: [
                    "a. First 6 Last 4 of the credit card",
                    "b. Refund Date and Refund Amount",
                ]

            }
        ]
        
    }

    const handleReminderOk = () => {
        setIsModalReminderVisible(false);
    };
    const getReasonOptions = (row) => {
        return creditReasons.map((reason) => (
            <Select.Option key={reason.value} value={reason.value} hidden={(row.reasoncode !== null && row.reasoncode.match(".002") && reason.reason === 'chargeback')} className="text-capital">
                {reason.value}
            </Select.Option>
        ));
    };

    return (
        <>
            <Button disabled={props.disabled} onClick={showModal} style={{ marginRight: "0.5rem" }}>
                {t("contents.alerts.request_credit")}
            </Button>
            <Modal
                title={t("contents.alerts.request_credit")}
                visible={isModalVisible}
                width={560}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={[
                    <Button key="back" size="default" onClick={() => handleCancel()}>
                        {t("contents.chargebacks.form.cancel")}
                    </Button>,
                    <Button key="submit" size="default" type="primary" onClick={() => handleOk()} disabled={isDisabledOkButton} loading={isLoadingButton}>
                        {t("tables.actions.ok")}
                    </Button>,
                ]}
            >
                <Form size="default" {...formItemLayout} form={form} onValuesChange={formValChange}>
                    <Form.Item label={t("contents.alerts.alert_id")} className="mb-075">
                        <span className="ant-form-text">{alertid}</span>
                    </Form.Item>
                    <Form.Item name="alertid" hidden={true}>
                        <Input />
                    </Form.Item>
                    <Form.Item label={t("contents.alerts.credit_reason")} name="reason_option" className="mb-075">
                        <Select className="text-capital" >
                            {getReasonOptions(row)}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        hasFeedback
                        name="duplicate_alertid"
                        label={t("contents.alerts.duplicate_alertid")}
                        className="mb-075"
                        hidden={data && data.reason_option !== "duplicate"}
                        validateTrigger="onBlur"
                        rules={[
                            ({ getFieldValue }) => ({
                                async validator(_, value) {
                                    try {
                                        if (!value) {
                                            throw new Error("Please enter duplicate alert ID.");
                                        } else{
                                            const valid = await isAlertIdExist(value);
                                            
                                            if (!valid) {
                                                throw new Error(t("contents.alerts.required_messages.duplicate_alertid_not_valid")+value);
                                            }else if (parseInt(value) === parseInt(data.alertid)) {
                                                throw new Error("Duplicate Alert ID couldn't be the same as Alert ID" +value);
                                            }
                                        }
                                    } catch (error) {
                                        setIsValidAlertID(false);
                                        setIsLoadingButton(false);
                                        return Promise.reject(error.message);
                                    }
                                    setIsValidAlertID(true);
                                    setIsLoadingButton(false);
                                    return Promise.resolve();
                                },
                            }),
                        ]}
                    > 
                  
                        <Input  type="number"/>
                    </Form.Item>
                    <Form.Item name="comment" label={t("contents.alerts.comment")} className="mb-075">
                        <Input.TextArea rows={2} />
                    </Form.Item>
                   
                </Form>
            </Modal>

            <Modal
                title={String(data?.reason_option).replace(/(^|\W)(\w)/g, (match) => match.toUpperCase())}
                visible={isModalReminderVisible}
                width={800}
                onOk={handleReminderOk}
                onCancel={handleReminderOk}
                footer={[
                    <Button key="submit" size="default" type="primary" onClick={() => handleReminderOk()}>
                        {t("tables.actions.ok")}
                    </Button>
                ]}
            >
                <p>To be able to successfully claim a credit request because of a {data?.reason_option}, you will need to be able to provide all of the documentation requirements below.</p>
                <p>Please forward the supporting documents to alertcreditdocuments@payshield.ai</p>
                <List
                    itemLayout="horizontal"
                    dataSource={remindersData[data?.reason_option]}
                    renderItem={item => (
                        <List.Item>
                            <List.Item.Meta
                                // title={<p>{String(item.title).split(/\n/).map((e, i) => (<span key={`${data?.credit_reason}_${e}_${i}`}>{e}<br /></span>))}</p>}
                                title={<p>{item.title}</p>}
                                description={item.contents.map((e, i) => (
                                    <p key={`${data?.reason_option}_${e}_${i}`} style={{ color: "#3e3e3e" }}>{e}</p>
                                ))}
                            />
                        </List.Item>
                    )}
                >
                </List>
            </Modal>
        </>
    );
};

export default RequestCredit;
