import React, { useState, useEffect } from "react";
import { CBM_URL } from "../../config/config";
import { Modal, Button, Form, Input, Select,Switch } from "antd";
import axios from "axios";
import Swal from "sweetalert2";

const MerchantProviderModal = (props) => {
    const { t, mpid, merchants, providers, isModalVisible, handleCancel, fetchMerchantProviders } = props;
    const [merchantProvider, setMerchantProvider] = useState(null);
    const [loading, setLoading] = useState(false);

    const layout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 16 },
    };

    const initData = {
        mid: null,
        pid: null,
        password1: "",
        password2: "",
        password3: "",
        url: "",
    };

    const [form] = Form.useForm();

    const handleOk = () => {
        setLoading(true);
        onFinish();
    };

    const formValChange = (chgV, allV) => {
        setMerchantProvider({ ...merchantProvider, ...allV });
    };

    useEffect(() => {
        if (mpid) {
            setMerchantProvider(initData);
            getMerchantProviderDetail();
        }
    }, [mpid]); // eslint-disable-line

    const getMerchantProviderDetail = () => {
        const apiUrl = CBM_URL + `/api/v1/merchantProvider/${mpid}/details/`;
        axios
            .get(apiUrl, { headers: { Authorization: "Bearer " + localStorage.getItem("cbm_token") }})
            .then(({ data }) => {
                if(data.status === 'ok')
                {
                    setMerchantProvider(data.details);
                    form.setFieldsValue(data.details);
                }else
                {
                    Swal.fire({
                        icon: "error",
                        title: data.msg,
                        showConfirmButton: true,
                    });
                }
            })
            .catch((error) => {
                Swal.fire({
                    icon: "error",
                    title: error.response ? error.response.data.msg : t("login.dialogs.catch"),
                    showConfirmButton: true,
                });
            });
    };

    const onFinish = async () => {
        const apiUrl = CBM_URL + `/api/v1/merchantProvider/${mpid}/edit/`;

        await axios
            .post(apiUrl, merchantProvider,{ headers: { Authorization: "Bearer " + localStorage.getItem("cbm_token") }})
            .then(({ data }) => {
                if (data.status === "ok") {
                    Swal.fire({
                        icon: "success",
                        title: "Merchant Provider Updated",
                        showConfirmButton: false,
                        timer: 2000,
                        onClose: () => {
                            fetchMerchantProviders();
                        },
                    });
                    handleCancel();
                } else {
                    Swal.fire({
                        icon: "error",
                        title: data.msg ? data.msg : "",
                        showConfirmButton: true,
                    });
                }
            })
            .catch((error) => {
               
                setLoading(false);
                Swal.fire({
                    icon: "error",
                    title: error.response ? error.response.data.error_msg.msg : t("login.dialogs.catch"),
                    showConfirmButton: true,
                });
            });
        setLoading(false);
    };

    const isOkButtonProps = () => !merchantProvider || !merchantProvider.mid || !merchantProvider.pid || loading;

    return (
        <>
            <Modal
                title={t("contents.chargebacks.view_merchant")}
                visible={isModalVisible}
                width={600}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={[
                    <Button key="back" onClick={() => handleCancel()}>
                        {t("contents.chargebacks.form.cancel")}
                    </Button>,
                    <Button key="submit" type="primary" loading={loading} disabled={isOkButtonProps()} onClick={() => handleOk()}>
                        {loading ? t("contents.chargebacks.form.submitting") : t("contents.chargebacks.form.submit")}
                    </Button>,
                ]}
            >
                <Form {...layout} form={form} name="new_chargeback" onValuesChange={formValChange}>
                    <Form.Item name="mid" label={t("contents.chargebacks.form.merchant")} rules={[{ required: true }]} style={{ marginBottom: ".5rem" }}>
                        <Select>
                            {merchants
                                ? merchants.map((merchant) => (
                                      <Select.Option key={merchant.mid} value={merchant.mid}>
                                          {merchant.name}
                                      </Select.Option>
                                  ))
                                : null}
                        </Select>
                    </Form.Item>
                    <Form.Item name="pid" label={t("contents.chargebacks.form.provider")} rules={[{ required: true }]} style={{ marginBottom: ".5rem" }}>
                        <Select>
                            {providers
                                ? providers.map((provider) => (
                                      <Select.Option key={provider.pid} value={provider.pid}>
                                          {provider.name}
                                      </Select.Option>
                                  ))
                                : null}
                        </Select>
                    </Form.Item>
                    <Form.Item name="password1" label={t("contents.chargebacks.form.password1")} style={{ marginBottom: ".5rem" }}>
                        <Input />
                    </Form.Item>
                    <Form.Item name="password2" label={t("contents.chargebacks.form.password2")} style={{ marginBottom: ".5rem" }}>
                        <Input />
                    </Form.Item>
                    <Form.Item name="password3" label={t("contents.chargebacks.form.password3")} style={{ marginBottom: ".5rem" }}>
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="url"
                        label={t("contents.chargebacks.form.url")}
                        rules={[{ type: "url", warningOnly: true }]}
                        style={{ marginBottom: ".5rem" }}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        key="isactive"
                        name="isactive"
                        label={t("contents.chargebacks.form.isactive")}
                        valuePropName="checked"
                        style={{ marginBottom: ".5rem" }}
                    >
                        <Switch />
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};

export default MerchantProviderModal;
