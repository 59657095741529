import React, { useState, useEffect } from "react";
import axios from "axios";
import { Select} from "antd";
import { API_SITE} from "../config/config";
import htmlParser from "html-react-parser";

const { Option } = Select;
const SITE_DATA_URL = API_SITE + "/list";


const SiteList = (props) => {

    const {mid, rid} = props;
    const [data, setData] = useState([]);

    const fetchdata = async() => {
        try{
        
            const response = await axios.post(
                SITE_DATA_URL,
                { mid: mid, rid: rid },
                { headers: { Authorization: "Bearer " + localStorage.getItem("access_token"), Authuser: localStorage.getItem("authuser") } }
            );
            setData(response.data.list);
        } catch (error) {
          return ( { data: false });
        }      

    };

    // useEffect(() => {
    //     if (data.length === 0) {
    //         fetchdata();
    //     }
    // }, [data]);

    useEffect(() => {
        fetchdata();
    }, [mid, rid]);  // eslint-disable-line react-hooks/exhaustive-deps

    const getSite = () => {       
        
            return data.map((item) => (               
                    <Option key={item.sid} value={item.sid}>
                        {htmlParser(item.name) + ` (sid: ` + item.sid + `)`}
                    </Option>                    
            ))
        
    };
    
    return (
        
        <Select 
            showSearch
            mode="multiple"
            showArrow="true"
            maxTagCount="responsive"
            optionFilterProp="children"
            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
            filterSort={(optionA, optionB) => optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())}
            {...props}
            >    {data
            ? getSite()
            : null}</Select>
    );
};

export default SiteList;