import React, { useState, useEffect } from "react";
import DataFetchHookV3 from "../../helper/DataFetchHookV3";
import CustomPaginationV2 from "../../helper/Table/CustomPaginationV2";
import CustomDateRangeV3 from "../../helper/Table/CustomDateRangeV3";
import SortOrder from "../../helper/Table/SortOrder";
import { Table, PageHeader, Card, Form, Select, Row, Col, Input, Button } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import moment from "moment";
import { API_TXFRAUD } from "../../config/config";
import ColumnResizable from "../../helper/Table/ColumnResizable";
import MergeColumns from "../../helper/Table/MergeColumns";

const { Option } = Select;
const DATA_URL = API_TXFRAUD + "/fraudchecks";

const MAX_DATERANGE = 3;
const START_DATE = moment().subtract(MAX_DATERANGE, "months");
const END_DATE = moment();

const FraudcheckList = (props) => {
    const [{ data, totalResult, isLoading, requestData }, doFetch, setRequestData] = DataFetchHookV3(DATA_URL, "POST", { size: 20, page: 1 }); // eslint-disable-line
    const [formData, setFormData] = useState();
    const [sortedInfo, setSortedInfo] = useState({});
    const [dates, setDates] = useState([START_DATE, END_DATE]);
    const [currentPage, setCurrentPage] = useState(1);
    const { t } = props;
    const [isDateExceeded, setIsDateExceeded] = useState(false);
    const initFormData = {
        txid: "",
        dateRange: [START_DATE, END_DATE],
        status: [],
        order: null,
    };

    const [form] = Form.useForm();

    useEffect(() => {
        formValInit();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const handleChange = (pagination, filters, sorter, { currentDataSource }) => {
        setSortedInfo(sorter);
    };

    const columns = [
        {
            dataIndex: "txid",
            key: "txid",
            title: t("tables.fields.txid"),
            width: 200,
            ellipsis: true,
            sorter: {
                compare: (a, b) => a.txid - b.txid,
                //    multiple: 1,
            },
            render: (id, record) => (
                <a href={`/transactions/${id}`} target="_blank" rel="noopener noreferrer">
                    {record.txid}
                </a>
            ),
            sortOrder: SortOrder(sortedInfo, "txid"),
        },
        {
            dataIndex: "response",
            key: "response",
            title: t("tables.fields.status"),
            width: 120,
            ellipsis: true,
            sorter: {
                compare: (a, b) => a.response.localeCompare(b.response),
                //    multiple: 3,
            },
            sortOrder: SortOrder(sortedInfo, "response"),
        },
        {
            dataIndex: "timestamp",
            key: "timestamp",
            title: t("tables.fields.date"),
            width: 220,
            ellipsis: true,
            render: (timestamp) => moment(timestamp).format("lll"),
            sorter: {
                compare: (a, b) => moment(a.timestamp).diff(moment(b.timestamp)),
                //    multiple: 4,
            },
            sortOrder: SortOrder(sortedInfo, "timestamp"),
        },
        {
            dataIndex: "card_no",
            key: "card_no",
            title: t("tables.fields.card_no"),
            width: 200,
            ellipsis: true,
            sorter: {
                compare: (a, b) => a.card_no.localeCompare(b.card_no),
                //    multiple: 5,
            },
            sortOrder: SortOrder(sortedInfo, "card_no"),
        },
        {
            dataIndex: "sitename",
            key: "sitename",
            title: t("tables.fields.sitename"),
            width: 200,
            ellipsis: true,
            sorter: {
                compare: (a, b) => a.sitename.localeCompare(b.sitename),
                //    multiple: 6,
            },
            sortOrder: SortOrder(sortedInfo, "sitename"),
        },
    ];

    // const exportHeaders = [
    //     { label: "Txid", key: "txid" },
    //     { label: "Status", key: "response" },
    //     { label: "Time", key: "timestamp" },
    //     { label: "Card No.", key: "card_no" },
    //     { label: "Site Name", key: "sitename" },
    // ];

    const formValInit = () => {
        setFormData(initFormData);
        form.setFieldsValue(initFormData);
    };

    const clearAll = () => {
        setFormData(initFormData);
        setRequestData({ ...requestData, ...initFormData, startDate: null, endDate: null, page: 1 });
        form.setFieldsValue(initFormData);
    };

    const formValChange = (chgV, allV) => {
        setFormData(allV);
    };

    const onFinish = () => {
        let startDate = null;
        let endDate = null;
        let searchData = {};
        if (formData) {
            if (dates) {
                startDate = dates[0] ? dates[0] : null;
                endDate = dates[1] ? dates[1] : null;
            }
            searchData = {
                txid: formData.txid ? formData.txid.trim() : formData.txid,
                status: formData.status,
                cardno: formData.card_no ? formData.card_no.trim() : formData.card_no,
            };
        }
        // Reset Page Index to 1
        setCurrentPage(1);
        setRequestData({ ...requestData, ...searchData, startDate: startDate, endDate: endDate, page: 1 });
    };

    const AdvancedSearchForm = () => (
        <div className="search-wrap">
            <Card title={t("tables.actions.search")} size="small">
                <Form form={form} size="large" name="advanced_search" className="ant-advanced-search-form" onValuesChange={formValChange} onFinish={onFinish}>
                    <Row gutter={[16, 16]}>
                    <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }} xl={{ span: 6 }}>
                            <Form.Item name="dateRange" rules={[
                                    {
                                        validator: () => {
                                            if(isDateExceeded){
                                                return Promise.reject(t('max_3_months'));
                                            }else if((dates[0] && !dates[1]) || (!dates[0] && dates[1])){
                                                return Promise.reject(t('min_1_day'));
                                            }else{
                                                return Promise.resolve();
                                            }
                                        }
                                    }
                                ]}>
                                <CustomDateRangeV3 t={t} setIsDateExceeded={setIsDateExceeded} state={{ dates, setDates }} unit="months" limit={MAX_DATERANGE} />
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }} xl={{ span: 9 }}>
                            <Form.Item name="txid">
                                <Input placeholder={t("tables.fields.txid")} />
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }} xl={{ span: 9 }}>
                            <Form.Item name="card_no">
                                <Input placeholder={t("tables.fields.card_no")} />
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }} xl={{ span: 6 }}>
                            <Form.Item name="status">
                                <Select mode="multiple" maxTagCount="responsive" allowClear style={{ width: "100%" }} placeholder={t("tables.fields.status")}>
                                    <Option value="APPROVED">APPROVED</Option>
                                    <Option value="DECLINED">DECLINED</Option>
                                    <Option value="PAYG_ERROR">PAYG_ERROR</Option>
                                    <Option value="PENDING">PENDING</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6, offset: 12 }} lg={{ span: 4, offset: 8}} xl={{ span: 3, offset: 11 }}>
                            <Form.Item>
                                <Button type="secondary" onClick={() => clearAll()} block>
                                    {t("tables.actions.clear_all")}
                                </Button>
                            </Form.Item>
                        </Col>
                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 4 }}>
                            <Form.Item>
                                <Button type="primary" htmlType="submit" block>
                                    <SearchOutlined /> {t("tables.actions.search")}
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Card>
        </div>
    );

    const getDataSource = (datalist) => (datalist && Array.isArray(datalist) ? datalist : []);

    const getHeaderTable = () => (
        <div className="custom-table--header d-flex justify-content-between align-items-center">
            <h3 className="table-title">{t("contents.fraudchecks.title")}</h3>
        </div>
    );

    return (
        <div>
            <PageHeader title={t("contents.fraudchecks.title")}></PageHeader>
            {AdvancedSearchForm()}
            <Table
                loading={isLoading}
                rowKey="txid"
                title={() => getHeaderTable()}
                components={{
                    header: {
                        cell: ColumnResizable,
                    },
                }}
                columns={MergeColumns(columns)}
                size="small"
                className="table-v3 custom-pagination"
                dataSource={getDataSource(data.datalist)}
                onChange={handleChange}
                sortDirections={["ascend", "descend", "ascend"]}
                pagination={CustomPaginationV2({ totalResult, currentPage, requestData, setRequestData, setCurrentPage })}
            />
        </div>
    );
};

export default FraudcheckList;
