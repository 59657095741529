import { API_BASE_URL } from "../config/config";
import axios from "axios";
import GroupAccess from "./GroupAccess";

const CBMToken = async (uid) => {
    //if user is sysadmin user use the cbm_token

    if(GroupAccess("SYSADMIN"))
            {
                return localStorage.getItem("cbm_token");
            }else{
                //ask txshield to get the token for the user from cbm
                
               let options = { headers: { Authorization: "Bearer " + localStorage.getItem("access_token")} };         
              
                    const cbmtoken = await axios
                        .get(API_BASE_URL   + "/v3/user/"+uid+"/getCBMToken", options)
                        .then(({ data }) => {

                            if(data.token) return data.token.token;
                            else console.log(data);
                        })
                        .catch((error) =>{
                            console.log(error);
                            return false;
                        });
                       if(cbmtoken)
                        {
                        localStorage.setItem("cbm_token", cbmtoken);
                        }
                        return cbmtoken;
                }
            
};
export default CBMToken;